export const RENDER_NEWSLETTER = 'render_newsletter';
export const RENDER_STORE_SWITCHER = 'render_store_switcher';
export const RENDER_FOOTER_LINKS = 'render_footer_links';

export const COLUMN_MAP = {
    footerReinsurance: {
        items: {
            block: 'footer_reinsurance',
            addition: ''
        }
    },
    footerSocial: {
        items: {
            block: 'footer_nl_social',
            addition: RENDER_NEWSLETTER
        }
    },
    footerLinks: {
        items: {
            block: '',
            addition: RENDER_FOOTER_LINKS
        }
    },
    footerMisc: {
        items: {
            block: 'footer_misc_links',
            addition: RENDER_STORE_SWITCHER
        }
    }
};

export const FOOTER_LINKS_MAP = {
    footerInfo: {
        items: {
            block: 'footer_info',
            collapsable: false,
            heading: ''
        }
    },
    footerLinksAbout: {
        items: {
            block: 'footer_links_about',
            collapsable: true,
            heading: 'Avril'
        }
    },
    footerLinksService: {
        items: {
            block: 'footer_links_service_links',
            collapsable: true,
            heading: __('Customer services')
        }
    },
    footerLinksNav: {
        items: {
            block: 'footer_links_nav',
            collapsable: true,
            heading: __('Our world')
        }
    }
};
