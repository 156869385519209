import { PureComponent } from 'react';
import Loader from 'Component/Loader';

import './GoogleMap.style';

export class GoogleMap extends PureComponent {
    render() {
        const {
            storeData: {
                embedded_map
            } = {},
            language,
            setIsMapLoading,
            isMapLoading
        } = this.props;

        return (
            <div
              block="GoogleMap"
              elem="Wrapper"
            >
                <Loader isLoading={ isMapLoading } />
                <iframe
                  block="GoogleMap"
                  elem="Iframe"
                  src={ `https://www.google.com/maps/embed/v1/place?key=AIzaSyAKCcuUU-UmThs6xPxNf5-zO7AHyuCR2Ew&q=${ embedded_map }&language=${ language }` }
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                  aria-hidden="false"
                  tabindex="0"
                  onLoad={ () => setIsMapLoading(false) }
                />
            </div>
        );
    }
}

export default GoogleMap;
