/* eslint-disable react/no-unused-state */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


import { Draggable as SourceDraggable } from 'SourceComponent/Draggable/Draggable.component';

import './Draggable.style';

/** @namespace Component/Draggable/Component */
export class Draggable extends SourceDraggable {
    prevClientX = 0;
    prevClientY = 0;

    _handleDragStart({
        clientX,
        clientY
    }) {
        const { onDragStart } = this.props;

        if (onDragStart) {
            onDragStart({ ...this.state });
        }

        this.setState({
            originalX: clientX,
            originalY: clientY,
            isDragging: true
        });
    }

    handleMouseMove = ({ clientX, clientY }) => {
        const { isDragging } = this.state;
        const { shiftX, shiftY } = this.props;

        if (
            !isDragging
            || this.prevClientX === clientX
            && this.prevClientY === clientY
        ) {
            return;
        }

        this.prevClientX = clientX;
        this.prevClientY = clientY;

        this.setState(({
            originalX,
            originalY
        }) => ({
            translateX: clientX - originalX + shiftX,
            translateY: clientY - originalY + shiftY
        }), () => {
            const { onDrag } = this.props;
            if (onDrag) {
                onDrag({ ...this.state, clientX, clientY });
            }
        });
    };
}

export default Draggable;
