import { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { updateMeta } from "Store/Meta/Meta.action";

import { updateIsRecipeBannerVisible } from "Store/Recipe/Recipe.action";
import { getCurrentPage, getQueryParam } from "Util/Url";
import { setMainMarginTop } from "Util/Window";

import { RecipeCategoryPage } from "./RecipeCategoryPage.component";

export const BreadcrumbsDispatcher = import(
	/* webpackMode: "lazy", webpackChunkName: "dispatchers" */
	"Store/Breadcrumbs/Breadcrumbs.dispatcher"
);

export const RecipeDispatcher = import(
	/* webpackMode: "lazy", webpackChunkName: "dispatchers" */
	"Store/Recipe/Recipe.dispatcher"
);

export const mapStateToProps = (state) => ({
	device: state.ConfigReducer.device,
	recipesPerPage:
		state.ConfigReducer.mageside_recipe_recipe_list_recipe_per_page,
	mageside_recipe_seo_route: state.ConfigReducer.mageside_recipe_seo_route,
	mageside_recipe_seo_title: state.ConfigReducer.mageside_recipe_seo_title,
	recipes: state.RecipeReducer.recipes,
	currentCategoryData: state.RecipeReducer.currentCategoryData,
	recipeFilters: state.RecipeReducer.recipeFilters,
	isRecipesLoading: state.RecipeReducer.isRecipesLoading,
	isCurrentCategoryLoading: state.RecipeReducer.isCurrentCategoryLoading,
});

export const mapDispatchToProps = (dispatch) => ({
	getRecipesList: (recipesPerPage, currentPage, filter) =>
		RecipeDispatcher.then(({ default: dispatcher }) =>
			dispatcher.getRecipesList(
				dispatch,
				recipesPerPage,
				currentPage,
				filter
			)
		),
	getSpecificRecipesCategory: (category_id) =>
		RecipeDispatcher.then(({ default: dispatcher }) =>
			dispatcher.getSpecificRecipesCategory(dispatch, category_id)
		),
	getRecipesCategoriesList: () =>
		RecipeDispatcher.then(({ default: dispatcher }) =>
			dispatcher.getRecipesCategoriesList(dispatch)
		),
	updateBreadcrumbs: (breadcrumbs) =>
		BreadcrumbsDispatcher.then(({ default: dispatcher }) =>
			dispatcher.update(breadcrumbs, dispatch)
		),
	updateMeta: (meta) => dispatch(updateMeta(meta)),
	updateIsRecipeBannerVisible: (isRecipeBannerVisible) =>
		dispatch(updateIsRecipeBannerVisible(isRecipeBannerVisible)),
});

/** @namespace Route/RecipeCategoryPage/Container */
export class RecipeCategoryPageContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isBreadSet: false,
		};
	}

	componentDidMount() {
		const {
			updateIsRecipeBannerVisible,
			getSpecificRecipesCategory,
			getRecipesCategoriesList,
			category_id,
            updateBreadcrumbs
		} = this.props;

		getSpecificRecipesCategory(category_id);
		getRecipesCategoriesList();
        this.updateRecipeList();
		updateIsRecipeBannerVisible(true);
        updateBreadcrumbs([]);
	}

	componentDidUpdate(prevProps) {
		const {
            category_id: urlResolverCategoryId,
            getSpecificRecipesCategory,
			currentCategoryData: { entity_id },
			location: { pathname, search },
		} = this.props;
		const {
            category_id: prevUrlResolvedCategoryId,
			currentCategoryData: { entity_id: prevEntityId },
			location: { pathname: prevPathname, search: prevSearch },
		} = prevProps;

        setMainMarginTop();
		this.setBreadcrumbs();
		this.setMetaTags(prevProps);

		if (entity_id !== prevEntityId) {
			this.setState({ isBreadSet: false });
        }

        if (urlResolverCategoryId !== prevUrlResolvedCategoryId) {
            getSpecificRecipesCategory(category_id);
		}

        if (pathname !== prevPathname || search !== prevSearch) {
            this.updateRecipeList();
        }
	}

	componentWillUnmount() {
		const { updateIsRecipeBannerVisible } = this.props;
		updateIsRecipeBannerVisible(false);
	}

	containerProps = () => {
		return {
			...this.props,
		};
	};

	containerFunctions = {};

	updateRecipeList() {
		const {
			location,
			location: { search },
			getRecipesList,
			recipesPerPage,
            category_id
		} = this.props;

		const selectedFiltersString = (
			getQueryParam("customFilters", location) || ""
		).split(";");
		const currentPage = getCurrentPage(search, "page");
		const filters_string = {};

		for (const filter of selectedFiltersString) {
			const [key, value] = filter.split(":");
			filters_string[key] = value;
		}

        const filters = { category_id };

        if (Object.keys(filters_string).length) {
            filters.filters_string = JSON.stringify(filters_string);
        }

		getRecipesList(recipesPerPage, currentPage, filters);
	}

	setMetaTags(prevProps) {
		const {
			updateMeta,
			currentCategoryData,
			currentCategoryData: {
				entity_id,
				meta_title,
				meta_tags,
				meta_description,
			},
		} = this.props;
		const {
			currentCategoryData: { entity_id: prevEntityId },
		} = prevProps;

		if (
			Object.keys(currentCategoryData).length &&
			JSON.stringify(entity_id) !== JSON.stringify(prevEntityId)
		) {
			updateMeta({
				title: meta_title,
				description: meta_description,
				keywords: meta_tags,
			});
		}
	}

	setBreadcrumbs() {
		const { isBreadSet } = this.state;
		const {
			currentCategoryData: { name },
			mageside_recipe_seo_route,
			mageside_recipe_seo_title,
			updateBreadcrumbs,
            isCurrentCategoryLoading
		} = this.props;

		if (
            isBreadSet ||
            !mageside_recipe_seo_route ||
            !name ||
            isCurrentCategoryLoading
        ) {
			return;
		}

		const breadcrumbs = [
			{
				url: "#",
				name: name,
			},
			{
				url: `/${mageside_recipe_seo_route}`,
				name: mageside_recipe_seo_title,
			},
		];

		this.setState({ isBreadSet: true });
		updateBreadcrumbs(breadcrumbs);
	}

	render() {
		return (
			<RecipeCategoryPage
				{...this.state}
				{...this.containerFunctions}
				{...this.containerProps()}
			/>
		);
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(RecipeCategoryPageContainer)
);
