import { throttle } from 'Util/Window';

import AmastyQuery from '../../query/Amasty.query';
import { fetchMutation } from 'Util/Request';

function amastyCollector() {
    const dataCollectorPool = [];
    let dataForSend = [];

    const options = {
        baseUrl: '',
        backendUrl: 'amasty_xsearch/analytics/collect',
        searchPopupSelector: '.SearchWindow',
        throttleTime: 500,
        isProductViewEnabled: false,
        searchClickSelectors: {
            selectorPopupLink: '.SearchWindow-ProductCard .ProductCard-Link',
            selectorPopupCategories: '.SearchWindow-ProductCard .SearchWindow-CategoriesNavItem',
            selectorPopupCMS: '.SearchSuggestionRow .SearchSuggestionRow_isDepartment',
            selectorPopupProductLink: '.SearchSuggestionRow .SearchSuggestionRow_isProduct',
        },
        popupProductSelector: '.SearchWindow-ProductCard .ProductCard-Link'
    };

    async function _sendData(dataJSON) {
        if (dataJSON !== '[]') {
            await fetchMutation(
                AmastyQuery.getAmastyAnalyticstColletField(dataJSON)
            );

            dataForSend = [];
        }
    }

    const sendData = throttle(_sendData, options.throttleTime);

    function init(baseUrl, isProductViewEnabled) {
        options.baseUrl = baseUrl;

        if (isProductViewEnabled == 1) {
            options.isProductViewEnabled = true;
        }
    }

    function addListeners() {
        const searchWindow = document.querySelector(options.searchPopupSelector);
        searchWindow.addEventListener('click', handleClick);

        initDataCollectors();
    }

    function onPDPEnter(product_id) {
        const data = [{
            type: 'product_view',
            product_id
        }];

        const dataJSON = JSON.stringify(data);

        sendData(dataJSON);
    }

    function _addDataCollector(collector) {
        if (false === collector instanceof Function) {
            throw new Error($.mage.__('The argument must be a function'))
        }

        dataCollectorPool.push(collector);
    }

    function initDataCollectors() {
        _addDataCollector(_handleSearchClick);
    }

    function handleClick(event) {
        const clickedElement = event.target;

        dataCollectorPool.forEach((dataCollector) => {
            const result = dataCollector(clickedElement);

            if (result !== false) {
                dataForSend.push(result);
            }
            const data = JSON.stringify(dataForSend);

            sendData(data);
        });
    }

    function _handleSearchClick(element) {
        let result = false;
        const acceptableElementSelector = Object.values(options.searchClickSelectors).join(', ');
        let isAcceptableElemClicked;

        const selectorsArray = Object.values(options.searchClickSelectors)
            .reduce((acc, selectors) => {
                acc.push(...selectors.split(' '));
                return acc;
            },
            []);

        if (element.className) {
            isAcceptableElemClicked = Array.prototype
                .some.call(
                    element.classList,
                    (className) => selectorsArray.includes(`.${ className }`)
                );
        }

        if (
            element.closest(acceptableElementSelector)
            || isAcceptableElemClicked
        ) {
            result = _getTelemetryObject('search_click', {});
        }

        return result;
    }

    function _getTelemetryObject(type, additionalData) {
        return Object.assign(
            { type },
            additionalData
        );
    }

    return {
        init,
        addListeners,
        onPDPEnter
    };
};

export default new amastyCollector();
