import { SET_PROMOTION } from './Promotion.action';

/** @namespace Store/Promotion/Reducer/getInitialState */
export const getInitialState = () => {
	return {
		promotion: null,
	};
};

export const PromotionReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case SET_PROMOTION:
            return {
                ...state,
                promotion: action.payload,
            };
        default:
            return state;
    }
};

export default PromotionReducer;