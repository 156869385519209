/* eslint-disable quote-props */
/* eslint-disable simple-import-sort/sort */
import { store } from '@scandipwa/scandipwa/src/store';
import Event, {
    EVENT_GTM_CHECKOUT_ADDPAYMENTINFO
} from '../../util/Event';

const sendOnCheckoutButtonClick = (args, callback, instance) => {
    const [orderID] = args;
    const {
        totals: { items = [], coupon_code }
    } = instance.props;
    const { paymentTotals: totals } = instance.state;
    const chosenTimeslot = store.getState().CartReducer.chosenTimeslot || [];

    Event.dispatch(
        EVENT_GTM_CHECKOUT_ADDPAYMENTINFO,
        { orderID, totals: { ...totals, items, coupon_code, chosenTimeslot } }
    );

    return callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: sendOnCheckoutButtonClick
        }
    }
};
