/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

import OrderQuery from 'Query/Order.query';
import { ConfigQuery as SourceConfigQuery } from 'SourceQuery/Config.query';

/** @namespace Query/Config */
export class ConfigQuery extends SourceConfigQuery {
    getPickupLocations() {
        return new Field('pickupLocations')
            .addFieldList([
                'total_count',
                this._getPickupItems()
            ]);
    }

    resolveClosestStore(storeCode = "default") {
        return new Field('resolveClosestStore')
            .addArgument('store_code', 'String', storeCode)
            .addFieldList(this._getPickupItemsFields())

    }

    _getPickupItems() {
        return new Field('items')
            .addFieldList(this._getPickupItemsFields());
    }

    _getPickupItemsFields() {
        return [
            'name',
            'city',
            'distance_value',
            'street',
            'country_id',
            'description',
            'latitude',
            'longitude',
            'postcode',
            'pickup_location_code',
            'region_id',
            'cms_page_url_en',
            'cms_page_url_fr',
            'place_id',
            'embedded_map',
            'phone',
            'is_pickup_location_active'
        ];
    }

    _getStoreConfigFields() {
        return [
            'code',
            'access_token_lifetime',
            'is_active',
            'cms_home_page',
            'cms_no_route',
            'copyright',
            'timezone',
            'header_logo_src',
            'timezone',
            'title_prefix',
            'title_suffix',
            'default_display_currency_code',
            'default_keywords',
            'default_title',
            'default_country',
            'secure_base_media_url',
            'paypal_sandbox_flag',
            'paypal_client_id',
            'paypal_payment_action',
            'logo_alt',
            'logo_height',
            'logo_width',
            'cookie_text',
            'cookie_link',
            'terms_are_enabled',
            'address_lines_quantity',
            'base_url',
            'pagination_frame',
            'pagination_frame_skip',
            'anchor_text_for_previous',
            'anchor_text_for_next',
            'reviews_are_enabled',
            'reviews_allow_guest',
            'demo_notice',
            'guest_checkout',
            'is_email_confirmation_required',
            'base_link_url',
            'minimum_password_length',
            'head_includes',
            'newsletterFormUrl',
            'actitoThankCmsBlockIdentifier',
            'actitoPageIdForCustomerDashboard',
            'avril_loyalty_app_google_api_key',
            ...this.getRecipesConfigFields(),
            this.getRealexPaymentsFields(),
            this.getMinOrderAmountFields()
        ];
    }

    getRecipesConfigFields() {
        return [
            'mageside_recipe_seo_route',
            'mageside_recipe_seo_title',
            'mageside_recipe_seo_route_cms_id',
            'mageside_recipe_seo_url_postfix',
            'mageside_recipe_recipe_list_recipe_per_page',
            'mageside_recipe_product_page_block_title',
        ];
    }

    getRealexPaymentsFields() {
        return new Field('realexpayments_hpp')
            .addFieldList([
                'merchant_id',
                'merchant_account',
                'shared_secret',
                'iframe_mode',
                'language',
                'payment_btn_text',
                'payment_url',
                'settle_mode'
            ]);
    }

    getMinOrderAmountFields() {
        return new Field('min_order_amount')
            .addFieldList([
                'active',
                'include_discount',
                'include_tax',
                'description',
                'error_message',
                this.getAmountField()
            ]);
    }

    getAmountField() {
        return new Field('amount')
            .addFieldList(OrderQuery._getMoneyFields());
    }

    _getContactFormSubjectFields() {
        return [
            'label',
            'value'
        ]
    }

    _getContactFormSubject() {
        return new Field('avril_loyalty_app_contactform_subject')
        .addFieldList(this._getContactFormSubjectFields())
    }

    getStoreConfigQuery() {
        return new Field('storeConfig')
            .addField(this._getContactFormSubject())
    }
    
}

export default new ConfigQuery();
