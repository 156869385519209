import { PureComponent } from "react";

import "./BlogWidget.style.scss";

export class BlogWidget extends PureComponent {
    renderTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h3 block="BlogWidget" elem="Title">
                {title}
            </h3>
        );
    }

    render() {
        const { children, mix } = this.props;

        return (
            <section block="BlogWidget" mix={mix}>
                {this.renderTitle()}
                <div block="BlogWidget" elem="Content">
                    {children}
                </div>
            </section>
        );
    }
}

export default BlogWidget;
