import { createRef } from 'react';
import { connect } from 'react-redux';
import { updateRewardBonusCart } from 'Store/Cart/Cart.action';
import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';
import MenuQuery from 'Query/Menu.query';
import MenuHelper from 'Util/Menu';
import { appendWithStoreCode } from 'Util/Url';
import CSS from 'Util/CSS';

import { DOWN, NEUTRAL, UP } from './Menu.config';
import Menu from './Menu.component';

import { CUSTOMER_ACCOUNT } from 'Component/Header/Header.config';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isDisclaimerHidden: state.ConfigReducer.isDisclaimerHidden,
});

export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch),
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(null, dispatch)
    ),
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key)),
    updateRewardBonusCart: () => dispatch(updateRewardBonusCart({}))
});

export class MenuContainer extends SourceMenuContainer {
    menuRef = createRef();

    state = {
        activeMenuItemsStack: [],
        menu: {},
        currentActiveParentMenuItem: null,
        currentActiveSubMenuItem: null,
        scrollDirection: NEUTRAL,
        subActiveMenu: null,
        allMenu: [],
        isSigned: false
    };

    containerFunctions = {
        handleSubcategoryClick: this.handleSubcategoryClick.bind(this),
        closeMenu: this.closeMenu.bind(this),
        onCategoryHover: this.onCategoryHover.bind(this),
        onActiveSubcategoryClick: this.onActiveSubcategoryClick.bind(this),
        onViewAllClick: this.onViewAllClick.bind(this),
        onSubCategoryHover: this.onSubCategoryHover.bind(this),
        handleSubCategoryOut: this.handleSubCategoryOut.bind(this),
        handleMobileBackClick: this.handleMobileBackClick.bind(this),
        onSignOut: this.onSignOut.bind(this)
    };

    componentDidMount() {
        this._getMenu();
        this.setWindowHeightInCss();
        this.scrollStop(() => this.setState({ scrollDirection: NEUTRAL }));

        window.addEventListener('resize', this.setWindowHeightInCss.bind(this));
        window.addEventListener('scroll', this.onScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll.bind(this));
    }

    onScroll() {
        let scrollPos = window.pageYOffset;

        if (document.body.getBoundingClientRect().top < scrollPos) {
            this.setState({ scrollDirection: DOWN, });
        } else {
            this.setState({ scrollDirection: UP, });
        }
        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    setWindowHeightInCss() {
        const windowHeight = window.innerHeight;
        CSS.setVariable(this.menuRef, 'window-inner-height', `${ windowHeight }px`);
    }

    handleLogout() {
        const { logout } = this.props;
        logout();
        this.onSignOut();
        updateRewardBonusCart({});
    }

    onSignOut() {
        const { toggleOverlayByKey } = this.props;
        toggleOverlayByKey(CUSTOMER_ACCOUNT);
    }

    createMenuData(allMenu) {
        if (allMenu.length) {
            const parentId = allMenu.find(e => e.title === "Parent").item_id
            const mainElements = [];
            const subElements = []
            allMenu.forEach(e => {
                if (e.parent_id === Number(parentId)) {
                    e.children = []
                    mainElements.push(e)
                } else {
                    subElements.push(e)
                }
            })
            if (subElements.length) {
                subElements.forEach(e => {
                    mainElements.find(parentEle => {
                        if (parentEle.item_id == e.parent_id) {
                            parentEle.children.push(e)
                            return true
                        }
                        return false
                    })
                })
            }
            return mainElements
        }
        return []
    }

    onCategoryHover(activeSubcategory) {
        const { device } = this.props;
        if (device.isMobile) {
            return;
        }

        const { activeMenuItemsStack } = this.state;
        const { item_id } = activeSubcategory;

        if (!activeMenuItemsStack.includes(item_id)) {
            this.setState({ activeMenuItemsStack: [item_id] });
        }
    }

    onSubCategoryHover(active) {
        const { device } = this.props;

        if (device.isMobile) {
            return;
        }

        this.setState({ subActiveMenu: active });
    }

    handleSubCategoryOut() {
        this.setState({ subActiveMenu: null });
    }

    onActiveSubcategoryClick(active) {
        const { parent_id, item_id } = active;

        this.setState({ currentActiveParentMenuItem: parent_id, currentActiveSubMenuItem: item_id });
    }

    onViewAllClick(active) {
        const { item_id } = active;

        this.setState({ currentActiveParentMenuItem: item_id, currentActiveSubMenuItem: null });
    }

    scrollStop(callback) {

        // Make sure a valid callback was provided
        if (!callback || typeof callback !== 'function') return;

        // Setup scrolling variable
        let isScrolling;

        // Listen for scroll events
        window.addEventListener('scroll', function (event) {

            // Clear our timeout throughout the scroll
            window.clearTimeout(isScrolling);

            // Set a timeout to run after scrolling ends
            isScrolling = setTimeout(function () {

                // Run the callback
                callback();

            }, 66);

        }, false);

    };

    _getMenu() {
        this.fetchData(
            [MenuQuery.getQuery(this._getMenuOptions())],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu), allMenu: this.createMenuData(menu.items)
            })
        );
    }

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;
        return {
            identifier: header_menu || 'main'
        };
    }

    handleMobileBackClick() {
        const { goToPreviousHeaderState } = this.props;

        this.setState(({ activeMenuItemsStack }) => (
            { activeMenuItemsStack: activeMenuItemsStack.slice(1) }
        ));
        goToPreviousHeaderState();
    }

    render() {
        return (
            <Menu
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              menuRef={ this.menuRef }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
