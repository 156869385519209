import { Field } from 'SourceUtil/Query';

export class CmsBlockQuery {
    getQuery({ identifiers }) {
        if (!identifiers) {
            throw new Error('Missing argument `options`');
        }

        return new Field('cmsBlocks')
            .addArgument('identifiers', '[String]', identifiers)
            .addField(this._getItemsField())
            .setAlias('cmsBlocks');
    }

    _getItemFields() {
        return [
            'title',
            'content',
            'identifier',
            'disabled'
        ];
    }

    _getItemsField() {
        return new Field('items')
            .addFieldList(this._getItemFields());
    }
}

export default new CmsBlockQuery();
