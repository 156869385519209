const _sortBySortOrder = (a, b) => a.sort_order - b.sort_order;

function _composeChildren(categoryId, level = 999, categories) {
    const children = [],
        levelNum = Number(level);

    if (!categories[levelNum + 1] || !categories[levelNum + 1][categoryId]) {
        return children;
    }

    categories[levelNum + 1][categoryId].forEach((child) => {
        const { category_id, level } = child,
            childrenOfChild = _composeChildren(category_id, level, categories);

        const newChild = {
            ...child,
            children: childrenOfChild,
        };

        children.push(newChild);
    });

    children.sort(_sortBySortOrder);

    return children;
}

function _transformToObjByLevel(acc, category) {
    const { level, parent_id } = category;

    if (!acc[level]) {
        acc[level] = {
            [parent_id]: [category],
        };
    } else {
        acc[level][parent_id].push(category);
    }

    return acc;
}

export const getBlogCategoriesTree = (categoryItems) => {
    if (!categoryItems.length) {
        return categoryItems;
    }

    const categories = Array.from(categoryItems),
        categoriesByLevel = categories.reduce(_transformToObjByLevel, {}),
        firstLevel = Object.values(categoriesByLevel)[0],
        firstItem = Object.values(firstLevel)[0];

    return firstItem.sort(_sortBySortOrder).map((firstLvlCategory) => {
        const { category_id, level } = firstLvlCategory;
        const children = _composeChildren(
            category_id,
            level,
            categoriesByLevel
        );

        return {
            ...firstLvlCategory,
            children,
        };
    });
};

export const composeBlogPostData = (post, tags, authors, categoriesItems) => {
    if (!Object.keys(post).length) {
        return {};
    }

    const { tag_ids, author_id, categories } = post;

    const categoriesIdArr = (categories ?? "")
        .split(",")
        .map((numStr) => Number(numStr));

    return {
        ...post,
        tags:
            tag_ids && tags
                ? tags.filter(({ tag_id }) => tag_ids.includes(tag_id))
                : [],
        author:
            author_id && authors
                ? authors.filter(
                      ({ author_id: authorId }) => author_id == authorId
                  )[0]
                : [],
        categories:
            categoriesItems &&
            categoriesItems.filter(({ category_id }) =>
                categoriesIdArr.includes(category_id)
            ),
    };
};

export const getPagesCount = (postsCount, postsPerPage) =>
    Math.ceil(postsCount / postsPerPage);

export const getTwoLastPosts = (posts = []) => {
    const sortedPosts = Array.from(posts).sort(
        ({ published_at: a }, { published_at: b }) => {
            if (a > b) {
                return -1;
            }

            return 0;
        }
    );

    return sortedPosts.slice(0, 2);
};

export const addPostItemsData = (posts, tags, authors, categoriesItems) => {
    if (!posts || !posts.length) {
        return [];
    }

    return posts.map((post) => {
        const { tag_ids, author_id, categories } = post;
        const categoriesIds =
            typeof categories === "string" ? categories.split(", ") : null;

        post.tags = (tags || []).filter(({ tag_id }) => tag_ids.includes(tag_id));
        post.author = (authors || []).filter(
            ({ author_id: authorId }) => author_id == authorId
        )[0];
        post.categoriesData = (categoriesItems || []).filter(({ category_id }) =>
            categoriesIds.includes(String(category_id))
        );
        post.category = (post.categories || '').split(',')[0];

        return post;
    });
};

export const getCurrentEntity = (list = [], key = "", entityId = 1) => {
    if (!list?.length) {
        return {};
    }

    return list.filter((item) => item[key] === entityId)[0] || {};
};

export const ogTagsModule = (() => {
    let tags = [];

    function setOgTags(tagsData) {
        const properties = [
            "og:title",
            "og:type",
            "og:image",
            "og:url",
            "og:description"
        ];

        removeOgTags();

        properties.forEach((property) => {
            if (!tagsData[property]) {
                return;
            }

            const template = document.createElement('template');
            template.innerHTML = `<meta property="${property}" content="${tagsData[property]}" >`;
            const meta = template.content.childNodes[0];

            document.head.appendChild(meta);
            tags.push(meta);
        });
    }

    function removeOgTags() {
        tags.forEach((tag) => {
            tag.parentNode.removeChild(tag);
        });
        tags = [];
    }

    return {
        setOgTags,
        removeOgTags
    }
})();
