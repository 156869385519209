/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import CartItem from 'Component/CartItem';
import { DEFAULT_LOCATION_CODE } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';
import { CART_OVERLAY } from 'Component/Header/Header.config';
import Overlay from 'Component/Overlay';
import { OVERLAY_PLACEHOLDER } from 'Component/PopupSuspense/PopupSuspense.config';
import { PICKUP_OVERLAY_KEY } from 'Component/PickupOverlay/PickupOverlay.config';
import { DELIVERY_FLOW } from 'Route/Checkout/Checkout.config';
import { DeviceType } from 'Type/Device';
import { TotalsType } from 'Type/MiniCart';
import { formatPrice } from 'Util/Price';
import { ReactComponent as CloseIcon } from '../Header/icons/close.svg';
import './CartOverlay.style';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/***/
export class CartOverlay extends PureComponent {
    static propTypes = {
        totals: TotalsType.isRequired,
        device: DeviceType.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        isEditing: PropTypes.bool.isRequired,
        handleCheckoutClick: PropTypes.func.isRequired,
        currencyCode: PropTypes.string.isRequired,
        showOverlay: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        hasOutOfStockProductsInCart: PropTypes.bool
    };

    static defaultProps = {
        hasOutOfStockProductsInCart: false
    };

    componentDidMount() {
        const { showOverlay, device, activeOverlay } = this.props;

        if (!device.isMobile && activeOverlay === OVERLAY_PLACEHOLDER) {
            showOverlay(CART_OVERLAY);
        }

        if (device.isMobile) {
            showOverlay(CART_OVERLAY);
        }
    }

    renderPriceLine(price) {
        const { storeCode } = this.props;
        return formatPrice(price, storeCode);
    }

    renderCartItems() {
        const {
            categories,
            totals: { items = [], quote_currency_code },
            closeMiniCart,
            hasOutOfStockProductsInCart: OutOfStock
        } = this.props;

        if (!items || items.length < 1) {
            return this.renderNoCartItems();
        }

        if(!!!categories){
            return null
        }

        return (
            <ul block="CartOverlay" elem="Items" mods={ { OutOfStock } } aria-label="List of items in CartOverlay">
                { Object.entries(categories).map(category => (
                    <>
                        <div block="CartOverlay" elem="ItemsGroup">
                            <p>{category[0]}</p>
                        </div>
                        { category[1].items.map(categoryItem => (
                            items.map(item => (
                                categoryItem.product.sku === item.product.sku
                                    ? <CartItem
                                        key={item.item_id}
                                        item={item}
                                        currency_code={quote_currency_code}
                                        isEditing
                                        isLikeTable
                                        closeMiniCart={closeMiniCart}
                                    />
                                    : null
                            ))
                        ))}
                    </>
                ))}
            </ul>
        );
    }


    renderNoCartItems() {
        return (
            <p block="CartOverlay" elem="Items" mix={{ block: "CartOverlay", elem: "Empty" }}>
                { __('There are no products in cart.')}
            </p>
        );
    }

    renderTotals() {
        const {
            selectedShippingAmount,
            totals: {
                subtotal_with_discount = 0,
                tax_amount = 0,
                fee: { value: feeVal = 0 } = {}
            }
        } = this.props;

        const total = subtotal_with_discount + tax_amount + feeVal + selectedShippingAmount;

        return (
            <dl
                block="CartOverlay"
                elem="Total"
            >
                <dt>{__('Estimated total :')}</dt>
                <dd>{this.renderPriceLine(total)}</dd>
            </dl>
        );
    }

    renderTax() {
        const { totals: { tax_amount = 0 } } = this.props;

        return (
            <dl
                block="CartOverlay"
                elem="Tax"
            >
                <dt>{__('Tax total:')}</dt>
                <dd>{this.renderPriceLine(tax_amount || 0)}</dd>
            </dl>
        );
    }

    renderDiscount() {
        const {
            totals: {
                applied_rule_ids,
                discount_amount,
                coupon_code
            }
        } = this.props;

        // if (!applied_rule_ids) {
        //     return null;
        // }

        if (!coupon_code) {
            return (
                <dl
                    block="CartOverlay"
                    elem="Discount"
                >
                    <dt>
                        {__('Discount: ')}
                    </dt>
                    <dd>{`-${this.renderPriceLine(Math.abs(discount_amount))}`}</dd>
                </dl>
            );
        }

        return (
            <dl
                block="CartOverlay"
                elem="Discount"
            >
                <dt>
                    {__('Discount/Coupon ')}
                    <strong block="CartOverlay" elem="DiscountCoupon">{coupon_code.toUpperCase()}</strong>
                </dt>
                <dd>{`-${this.renderPriceLine(Math.abs(discount_amount))}`}</dd>
            </dl>
        );
    }

    renderSecureCheckoutButton() {
        const { totals: { items }, handleCheckoutClick, hasOutOfStockProductsInCart } = this.props;

        const options = !items || items.length < 1 || hasOutOfStockProductsInCart
            ? {
                onClick: (e) => e.preventDefault(),
                disabled: true
            }
            : {};

        return (
            <button
                block="CartOverlay"
                elem="CheckoutButton"
                mix={{ block: 'Button' }}
                onClick={handleCheckoutClick}
                {...options}
            >
                <span />
                { __('Secure checkout')}
            </button>
        );
    }

    cartButtonHandler() {
        const {
            closeMiniCart,
            showPopup,
            checkoutFlow,
            updateCheckoutFlow,
            closestStore: {
                pickup_location_code
            } = {}
        } = this.props;

        const isHomeDeliverySet = pickup_location_code === DEFAULT_LOCATION_CODE;

        closeMiniCart();

        // redirect to the cart page if checkoutFlow is selected, otherwise, show pickup overlay.
        if (isHomeDeliverySet) {
            updateCheckoutFlow(DELIVERY_FLOW);
            history.push({
                pathname: appendWithStoreCode('/cart')
            });
        }

        if (!!checkoutFlow) {
            history.push({
                pathname: appendWithStoreCode('/cart')
            });
        } else  {
            showPopup(PICKUP_OVERLAY_KEY, {});
        }

        setTimeout(() => window.scrollTo(0,0),10)
    }

    renderActions() {
        const {
            device,
            totals: {
                subtotal_incl_tax = 0,
                items = []
            }
        } = this.props

        const disabled = items.length === 0;

        return (
            <div block="CartOverlay" elem="Actions">
                <button
                  block="CartOverlay"
                  elem="CartButton"
                  onClick={ this.cartButtonHandler.bind(this) }
                  disabled={ disabled }
                >
                    {__('See cart')}
                    <span>
                        {device.isMobile ? __('(Estimated total : %s)', this.renderPriceLine(subtotal_incl_tax)) : null}
                    </span>
                </button>
            </div>
        );
    }

    renderPromo() {
        const { totals: { items_qty }, closeMiniCart } = this.props
        const qty = !!items_qty ? `(${items_qty})` : ""
        return (
            <div
                block="CartOverlay"
                elem="Heading"
            >
                <p
                    block="CartOverlay"
                    elem="Promo"
                >
                    { __('My cart preview %s', qty) }

                </p>
                <CloseIcon onClick={() => closeMiniCart()} />
            </div>
        );
    }

    renderOutOfStockProductsWarning() {
        const { hasOutOfStockProductsInCart } = this.props;

        if (!hasOutOfStockProductsInCart) {
            return null;
        }

        return (
            <div block="CartOverlay" elem="OutOfStockProductsWarning">
                { __('Remove out of stock products from cart')}
            </div>
        );
    }

    render() {
        const { changeHeaderState, shouldRenderCartOverlay, device } = this.props;

        return (
            <Overlay
                id={CART_OVERLAY}
                onVisible={changeHeaderState}
                mix={{ block: 'CartOverlay', mods: { isFirstLoad: !!!shouldRenderCartOverlay } }}
            >
                { this.renderPromo()}
                { this.renderOutOfStockProductsWarning()}
                { this.renderCartItems()}
                <div block="CartOverlay" elem="TotalActions">
                    {device.isMobile ? null : this.renderTotals()}
                    {this.renderActions()}
                </div>
            </Overlay>
        );
    }
}

export default CartOverlay;
