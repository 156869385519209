/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/* eslint-disable brace-style */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEventEnabled } from '../EventConfig';

/**
 * Event utility
 *
 * Should be used only for data collecting or 3'rd service integration.
 * For React internal data flows use react specified data flows architecture
 */
export class Event {
    /**
     * Dispatch global event
     *
     * @param name Event name
     * @param data Object of passed data to event observer
     *
     * @return {boolean}
     */
    static dispatch(name, data = {}) {
        if (isEventEnabled(name) || name === "gtm_checkout_begin" || name === "gtm_product_add_to_wishlist" || name === "gtm_checkout_addpaymentinfo") {
            window.dispatchEvent(new CustomEvent(name, { detail: data }));
            return true;
        }
        return false;
    }


    /**
     * Event observer
     * Returns callback wrapper as observer identity
     *
     * @param name Event name
     * @param callback Observer callback
     *
     * @return {function|boolean}
     */
    static observer(name, callback) {
        if (callback && typeof callback === 'function' && (isEventEnabled(name) || name === 'gtm_checkout_begin' || name === 'gtm_product_add_to_wishlist' || name === 'gtm_checkout_addpaymentinfo')) {
            const callbackWrapper = ({ detail: data }) => { callback.call(this, data); };

            window.addEventListener(name, callbackWrapper, false);

            return callbackWrapper;
        }

        return false;
    }

    /**
     * Remove event observer for defined listener
     *
     * @param name Event name
     * @param listener Callback used for observer (function)
     *
     * @return {boolean}
     */
    static removeObserver(name, listener) {
        if (isEventEnabled(name) || name === "gtm_checkout_begin" || name === "gtm_product_add_to_wishlist" || name === 'gtm_checkout_addpaymentinfo') {
            window.removeEventListener(name, listener, false);
            return true;
        }
        return false;
    }
}

export default Event;
