/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from "react";

import ContentWrapper from "Component/ContentWrapper";
import BlogSimilarPostCard from "Component/BlogSimilarPostCard";
import Html from "Component/Html";
import BlogTag from "Component/BlogTag";
import BlogCategoriesWidget from "Component/BlogCategoriesWidget";
import BlogMobileMenuButtonRow from "Component/BlogMobileMenuButtonRow";
import BlogMobileNav from "Component/BlogMobileNav";
import Image from "Component/Image";
import ProductList from "Component/ProductListPage";
import ProductSlider from "Component/ProductSliders";

import "Route/CmsPage/CmsPage.style";
import { isMobile as isMobileUtil } from "Util/Mobile";

import "./BlogPostPage.style";
import TextPlaceholder from "Component/TextPlaceholder/TextPlaceholder.component";

/** @namespace Route/BlogPostPage/Component */
export class BlogPostPage extends PureComponent {
    renderPostContent() {
        const {
            isPostLoading,
            post: { full_content = '' },
        } = this.props;

        const postContent = full_content || '';

        if (isPostLoading) {
            return (
                <section block="BlogPostPage" elem="PostContentPlaceholders">
                    {new Array(3).fill(null).map(() => (
                        <div
                            mix={{
                                block: "CmsPage",
                                elem: "SectionPlaceholder",
                            }}
                        />
                    ))}
                </section>
            );
        }

        return (
            <section block="BlogPostPage" elem="MainBlock">
                <div block="BlogPostPage" elem="PostContent">
                    <Html content={postContent} />
                </div>
            </section>
        );
    }

    renderNavigation() {
        const {
            device: { isMobile },
        } = this.props;

        const isNavRendered = !(isMobileUtil.tablet() || isMobile);

        return (
            <nav block="BlogPostPage" elem="Nav">
                {isNavRendered ? (
                    <BlogCategoriesWidget isLandingLinkVisible />
                ) : null}
                {this.renderSimilarPostsWrapper()}
            </nav>
        );
    }

    renderSimilarPost(post) {
        return (
            <BlogSimilarPostCard
                post={post}
                mix={{ block: "BlogPostPage", elem: "RelatedWidgetPost" }}
            />
        );
    }

    renderSimilarPostsWrapper() {
        const { similarPosts, isSimilarPostsLoading } = this.props;

        if (!similarPosts?.length && !isSimilarPostsLoading) {
            return null;
        }

        return (
            <div block="BlogPostPage" elem="RelatedWidgetWrapper">
                <h3 block="BlogPostPage" elem="RelatedWidgetTitle">
                    {__("Related posts")}
                </h3>
                {this.renderSimilarPosts()}
            </div>
        );
    }

    renderSimilarPostsMobileSlider(postsToRender) {
        const {
            similarPostsActiveImg,
            updateSimilarPostsActiveImg,
            searchProductsScrollableRef,
            device: { isMobile },
        } = this.props;

        const isSinglePost = postsToRender?.length === 1;

        return (
            <ProductSlider
                activeImage={similarPostsActiveImg}
                onActiveImageChange={updateSimilarPostsActiveImg}
                isSliderExist={true}
                containerRef={searchProductsScrollableRef}
                showCrumbs={isMobile}
                mix={{
                    block: "BlogPostPage",
                    elem: "MobilePostsSlider",
                    mods: { isSinglePost },
                }}
            >
                {postsToRender}
            </ProductSlider>
        );
    }

    renderSimilarPosts() {
        const {
            similarPosts,
            device: { isMobile },
        } = this.props;

        const posts = similarPosts?.length
            ? similarPosts
            : new Array(4).fill(null);

        const postsToRender = posts.map(this.renderSimilarPost.bind(this));

        if (isMobileUtil.tablet() || isMobile) {
            return this.renderSimilarPostsMobileSlider(postsToRender);
        }

        return <>{postsToRender}</>;
    }

    renderRecommendedProducts() {
        const {
            postRelatedProducts,
            areRelatedProductsLoading,
            blogSettings: { post_related_products_block_title },
        } = this.props;

        if (!postRelatedProducts?.length && !areRelatedProductsLoading) {
            return null;
        }

        return (
            <div
                block="BlogPostPage"
                elem="RelatedWidgetWrapper"
                mix={{ block: "BlogPostPage", elem: "ProductsSliderWrapper" }}
            >
                <h3 block="BlogPostPage" elem="RelatedWidgetTitle">
                    {post_related_products_block_title}
                </h3>
                <ProductList
                    items={postRelatedProducts}
                    isLoading={areRelatedProductsLoading}
                />
            </div>
        );
    }

    renderPostTitleBlock() {
        const {
            post: { title, published_at_text },
        } = this.props;

        return (
            <div block="BlogPostPage" elem="TitleBlock">
                <h1 block="BlogPostPage" elem="Title">
                    {title}
                </h1>
                <span block="BlogPostPage" elem="PublishedAt">
                    {published_at_text}
                </span>
                {this.renderPostTags()}
            </div>
        );
    }

    renderPostHeader() {
        const {
            isPostLoading,
            post: { title, published_at_text },
        } = this.props;

        if (isPostLoading) {
            return (
                <>
                    <TextPlaceholder
                        mix={{ block: "BlogPostPage", elem: "Title" }}
                        length="long"
                    />
                    <TextPlaceholder
                        mix={{ block: "BlogPostPage", elem: "PublishedAt" }}
                        length="short"
                    />
                </>
            );
        }

        return (
            <>
                <h1 block="BlogPostPage" elem="Title">
                    {title}
                </h1>
                <span block="BlogPostPage" elem="PublishedAt">
                    {published_at_text}
                </span>
            </>
        );
    }

    renderPostImg() {
        const {
            device: { isMobile },
            post: {
                post_thumbnail,
                post_thumbnail_alt,
                post_thumbnail_mobile,
                post_thumbnail_mobile_alt,
            } = {},
        } = this.props;

        if (!post_thumbnail_mobile && !post_thumbnail) {
            return null;
        }

        return (
            <Image
                mix={{ block: "BlogPostPage", elem: "MainPostImg" }}
                src={
                    isMobile && post_thumbnail_mobile
                        ? post_thumbnail_mobile
                        : post_thumbnail
                }
                alt={
                    isMobile && post_thumbnail_mobile_alt
                        ? post_thumbnail_mobile_alt
                        : post_thumbnail_alt
                }
            />
        );
    }

    renderPostTitleBlock() {
        return (
            <div block="BlogPostPage" elem="TitleBlock">
                {this.renderPostHeader()}
                {this.renderPostTags()}
                {this.renderPostImg()}
            </div>
        );
    }

    renderPostTags() {
        const {
            post: { tags },
        } = this.props;

        if (!tags) {
            return null;
        }

        return (
            <div block="BlogPostPage" elem="Tags">
                {tags.map((tag) => (
                    <BlogTag tag={tag} />
                ))}
            </div>
        );
    }

    renderPostHeading() {
        return (
            <section block="BlogPostPage" elem="Heading">
                {this.renderMobileNav()}
                {this.renderPostTitleBlock()}
            </section>
        );
    }

    renderContent() {
        const {
            device: { isMobile },
        } = this.props;

        return (
            <ContentWrapper
                wrapperMix={{ block: "BlogPostPage", elem: "Wrapper" }}
                label="Blog post page details"
            >
                <BlogMobileNav isLandingLinkVisible />
                <div block="BlogPostPage" elem="Content">
                    {this.renderPostHeading()}
                    {this.renderPostContent()}
                    {!isMobile && this.renderNavigation()}
                </div>
                <div>
                    {this.renderRecommendedProducts()}
                    {isMobile && this.renderNavigation()}
                </div>
            </ContentWrapper>
        );
    }

    renderMobileNav() {
        const { all_post_size } = this.props;

        if (!(isMobileUtil.tablet() || isMobileUtil.any())) {
            return null;
        }

        return (
            <BlogMobileMenuButtonRow
                mix={{ block: "BlogPostPage", elem: "MenuBtnRow" }}
                isPostPage
                postsCount={all_post_size}
            />
        );
    }

    render() {
        return (
            <main block="BlogPostPage" aria-label="Blog Post Page">
                {this.renderContent()}
            </main>
        );
    }
}

export default BlogPostPage;
