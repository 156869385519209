/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { cloneElement } from 'react';

import { Form as SourceForm } from 'SourceComponent/Form/Form.component';


/** @namespace Component/Form/Component */
export class Form extends SourceForm {
    static cloneAndValidateChildren(propsChildren, refMap) {
        const invalidFields = [];
        const messagesMap = {};

        const children = Form.cloneChildren(
            propsChildren,
            (child) => {
                const { props: { id, name } } = child;
                const { message } = Form.validateField(child, refMap);

                if (message) {
                    messagesMap[id] = message;
                    invalidFields.push(id);
                    return cloneElement(child, { message, formRef: refMap[name] });
                }

                return cloneElement(child, { formRef: refMap[name] });
            }
        );

        return { children, fieldsAreValid: !invalidFields.length, invalidFields, messagesMap };
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            messagesMap: {}
        };
    }

    collectFieldsInformation = () => {
        const { refMap } = this.state;
        const { children: propsChildren, disableErrorScroll } = this.props;

        const {
            children,
            fieldsAreValid,
            invalidFields,
            messagesMap
        } = Form.cloneAndValidateChildren(propsChildren, refMap);

        this.setState({ children, fieldsAreValid, messagesMap });

        const inputValues = Object.values(refMap).reduce((inputValues, inputElem) => {
            const { current } = inputElem;
            // current may be null.
            const { input } = {} = current || {};

            const currentElem = input ? input : current;

            if (currentElem && currentElem.id && currentElem.value) {
                const { name, value, checked } = currentElem;

                if (currentElem.dataset.skipValue === 'true') {
                    return inputValues;
                }

                if (currentElem.type === 'checkbox') {
                    const boolValue = checked;
                    return { ...inputValues, [name]: boolValue };
                }

                return { ...inputValues, [name]: value };
            }

            return inputValues;
        }, {});

        if (invalidFields.length) {
            const { current, current: { input } = {} } = refMap[invalidFields[0]];

            const currentElem = input ? input : current;

            if (!disableErrorScroll) {
                currentElem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }

        return {
            inputValues,
            invalidFields,
            messagesMap
        };
    };

    handleFormSubmit = async (e) => {
        const {
            onSubmitSuccess,
            onSubmitError,
            onSubmit,
            id,
            handleFormSubmit
        } = this.props;

        e.preventDefault();

        // custom submit function from props
        if (handleFormSubmit) {
            const { messagesMap } = this.collectFieldsInformation();
            handleFormSubmit(e, this, messagesMap);
            return;
        }

        onSubmit();

        const portalData = id ? await window.formPortalCollector.collect(id) : [];

        const {
            invalidFields,
            inputValues
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = [],
                inputValues = {}
            } = portalData;

            const {
                invalidFields: initialInvalidFields,
                inputValues: initialInputValues
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields],
                inputValues: { ...initialInputValues, ...inputValues }
            });
        }, this.collectFieldsInformation());

        const asyncData = Promise.all(portalData.reduce((acc, { asyncData }) => {
            if (!asyncData) {
                return acc;
            }

            return [...acc, asyncData];
        }, []));

        asyncData.then(
            /** @namespace Component/Form/Component/handleFormSubmitAsyncDataThen */
            (asyncDataList) => {
                if (!invalidFields.length) {
                    onSubmitSuccess(inputValues, asyncDataList);
                    return;
                }

                onSubmitError(inputValues, invalidFields);
            },
            /** @namespace Component/Form/Component/handleFormSubmitAsyncDataCatch */
            (e) => onSubmitError(inputValues, invalidFields, e)
        );
    };
}

export default Form;
