import { PureComponent } from 'react';
import { connect } from 'react-redux';

import EcomMenu from './EcomMenu.component';

export const mapStateToProps = (state) => ({});

export class EcomMenuContainer extends PureComponent {
    render() {
        return (
            <EcomMenu
                {...this.props}
            />
        );
    }
}

export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EcomMenuContainer);
