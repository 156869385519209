import { Children, createRef } from 'react';
import { DeviceType } from 'SourceType/Device';
import Draggable from 'SourceComponent/Draggable';
import CSS from 'SourceUtil/CSS';
import { Slider as SourceSlider } from 'SourceComponent/Slider/Slider.component';
import isMobile from 'SourceUtil/Mobile';

import './Slider.style';
import {
    ACTIVE_SLIDE_PERCENT,
    ANIMATION_DURATION,
    HEIGHT_TRANSITION_SPEED_ON_MOUNT
} from 'SourceComponent/Slider/Slider.config';

export class Slider extends SourceSlider {
    static defaultProps = {
        activeImage: 0,
        onActiveImageChange: () => {},
        showCrumbs: false,
        isInteractionDisabled: false,
        mix: {},
        onClick: null,
        isVertical: false,
        isHeightTransitionDisabledOnMount: false,
        sliderHeight: null,
        sliderRef: null,
        device: DeviceType
    };

    sliderWidth = 0;

    prevPosition = 0;

    draggableRef = createRef();

    sliderRef = createRef();

    handleDragStart = this.handleInteraction.bind(this, this.handleDragStart);

    handleDrag = this.handleInteraction.bind(this, this.handleDrag);

    handleDragEnd = this.handleInteraction.bind(this, this.handleDragEnd);

    renderCrumb = this.renderCrumb.bind(this);

    __construct(props) {
        super.__construct(props);

        const { activeImage } = this.props;

        this.state = {
            prevActiveImage: activeImage
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { activeImage, children } = props;
        const { prevActiveImage } = state;

        if (prevActiveImage !== activeImage && children.length !== 1) {
            return { prevActiveImage: activeImage };
        }

        return null;
    }

    componentDidMount() {
        this.addWindowResizeWatcher();

        if (!this.getIsSlider()) {
            return;
        }

        const sliderChildren = this.draggableRef.current.children;
        const sliderWidth = this.draggableRef.current.offsetWidth;
        this.sliderWidth = sliderWidth;

        if (!sliderChildren || !sliderChildren[0]) {
            return;
        }

        this.setStyleVariablesOnMount();

        const sliderRef = this.getSliderRef();
        const sliderHeight = `${ sliderChildren[0].offsetHeight }px`;

        sliderChildren[0].onload = () => {
            CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
        };

        setTimeout(() => {
            CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
        }, ANIMATION_DURATION);
    }

    componentDidUpdate(prevProps) {
        const { activeImage: prevActiveImage } = prevProps;
        const { activeImage } = this.props;

        if (activeImage !== prevActiveImage && this.getIsSlider()) {
            const newTranslate = -activeImage * this.getSlideWidth();

            this.setAnimationSpeedStyle(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION));
            this.setTranlateXStyle(newTranslate);
        }
    }

    addWindowResizeWatcher() {
        window.addEventListener('resize', () => {
            const { activeImage } = this.props;
            const newTranslate = -activeImage * this.getSlideWidth();

            this.setTranlateXStyle(newTranslate);

            // Removed animation to avoid image movement while changing window width.
            this.setAnimationSpeedStyle(0);

            const delay = 500;
            setTimeout(() => {
                this.setAnimationSpeedStyle();
            }, delay);
        });
    }

    setStyleVariablesOnMount() {
        const { sliderHeight, isHeightTransitionDisabledOnMount, activeImage } = this.props;

        const sliderRef = this.getSliderRef();

        if (isHeightTransitionDisabledOnMount) {
            const transitionSpeed = isHeightTransitionDisabledOnMount
                ? 0
                : `${ HEIGHT_TRANSITION_SPEED_ON_MOUNT }ms`;

            CSS.setVariable(
                sliderRef,
                'height-transition-speed',
                transitionSpeed
            );
        }

        if (sliderHeight) {
            CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
        }

        const newTranslate = -activeImage * this.getSlideWidth();
        this.setTranlateXStyle(newTranslate);
    }

    setTranlateXStyle(translate) {
        const { isVertical } = this.props;

        CSS.setVariable(this.draggableRef, isVertical ? 'translateY' : 'translateX', `${ translate }px`);
    }

    setAnimationSpeedStyle(animationDuration = ANIMATION_DURATION) {
        CSS.setVariable(this.draggableRef, 'animation-speed', `${ animationDuration }ms`);
    }

    getIsSlider() {
        const { children ,isProductGallery} = this.props;
         if(isProductGallery){
             return true
         }
        return children.length !== 1;
    }

    getSlideWidth() {
        const { isVertical } = this.props;
        const { offsetWidth = 0, offsetHeight = 0 } = this.draggableRef.current || {};

        return isVertical ? offsetHeight : offsetWidth;
    }

    getSliderRef() {
        const { sliderRef } = this.props;

        return sliderRef || this.sliderRef;
    }

    onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize) {
        const { originalX } = state;
        const { prevActiveImage: prevActiveSlider } = this.state;
        const { onActiveImageChange, device, onClick } = this.props;

        if (onClick) {
            onClick();

            return -prevActiveSlider;
        }

        const fullSliderPoss = Math.round(fullSliderSize / slideSize);
        const elementPossitionInDOM = this.draggableRef.current.getBoundingClientRect().x;

        const sliderPossition = -prevActiveSlider;
        const realElementPossitionInDOM = elementPossitionInDOM - lastTranslate;
        const mousePossitionInElement = originalX - realElementPossitionInDOM;

        if (device.isMobile) {
            return sliderPossition;
        }

        if (slideSize / 2 < mousePossitionInElement && -fullSliderPoss < sliderPossition) {
            const activeSlide = sliderPossition - 1;
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        if (slideSize / 2 > mousePossitionInElement && lastTranslate) {
            const activeSlide = sliderPossition + 1;
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        return sliderPossition;
    }

    getFullSliderWidth() {
        const { isVertical } = this.props;
        const { scrollWidth: fullSliderWidth, scrollHeight } = this.draggableRef.current;

        const width = isVertical ? scrollHeight : fullSliderWidth;

        return width - this.getSlideWidth();
    }

    calculateNextSlide(state) {
        const { isVertical } = this.props;
        const {
            translateX,
            translateY,
            lastTranslateX,
            lastTranslateY
        } = state;

        const lastTranslate = isVertical ? lastTranslateY : lastTranslateX;
        const translate = isVertical ? translateY : translateX;

        const { onActiveImageChange } = this.props;

        const slideSize = this.getSlideWidth();

        const fullSliderSize = this.getFullSliderWidth();

        const activeSlidePosition = translate / slideSize;
        const activeSlidePercent = Math.abs(activeSlidePosition % 1);
        const isSlideBack = translate > lastTranslate;

        if (!translate) {
            return this.onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize);
        }

        if (translate >= 0) {
            onActiveImageChange(0);
            return 0;
        }

        if (translate < -fullSliderSize) {
            const activeSlide = Math.round(fullSliderSize / -slideSize);
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.ceil(activeSlidePosition);
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.floor(activeSlidePosition);
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);
        onActiveImageChange(-activeSlide);
        return activeSlide;
    }

    handleDragStart() {
        this.setAnimationSpeedStyle(0);
    }

    handleDrag(state) {
        const { isVertical } = this.props;
        const { translateX, translateY } = state;

        const translate = isVertical ? translateY : translateX;

        const fullSliderSize = this.getFullSliderWidth();

        if (translate < 0 && translate > -fullSliderSize) {
            this.setTranlateXStyle(translate);
        }
    }

    handleDragEnd(state, callback) {
        const { isVertical } = this.props;
        let  activeSlide = this.calculateNextSlide(state);
        activeSlide = -this.props.activeImage;
        const slideSize = this.getSlideWidth();
        const newTranslate = activeSlide * slideSize;

        this.setAnimationSpeedStyle();
        this.setTranlateXStyle(newTranslate);

        if (isVertical) {
            callback({
                originalY: newTranslate,
                lastTranslateY: newTranslate
            });

            return;
        }

        callback({
            originalX: newTranslate,
            lastTranslateX: newTranslate
        });
    }

    handleClick = (state, callback, e) => {
        if (e.type === 'contextmenu') {
            this.handleDragEnd(state, callback);
        }
    };

    handleInteraction(callback, ...args) {
        const { isInteractionDisabled } = this.props;

        if (isInteractionDisabled || !callback) {
            return;
        }

        callback.call(this, ...args);
    }

    changeActiveImage(activeImage) {
        const { onActiveImageChange } = this.props;
        onActiveImageChange(activeImage);
    }

    renderCrumbs() {
        const { children } = this.props;
        if (children.length <= 1) {
            return null;
        }

        return (
            <div
                block="Slider"
                elem="Crumbs"
            >
                { Children.map(children, this.renderCrumb) }
            </div>
        );
    }

    renderCrumb(_, i) {
        const { activeImage } = this.props;
        const isActive = i === Math.abs(-activeImage);

        return (
            <button
                block="Slider"
                elem="Image"
                mods={ { type: 'single' } }
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => this.changeActiveImage(i) }
            >
                <div
                    block="Slider"
                    elem="Crumb"
                    mods={ { isActive } }
                />
            </button>
        );
    }

    renderArrowButtons() {
        return (
            <div block="Slider"
                 elem="Arrows">
                { this.renderArrowRight() }
                { this.renderArrowLeft() }
            </div>
        )
    }

    renderArrowLeft() {
        const { prevActiveImage } = this.state;
        const { children } = this.props;

        const nextActiveImage = prevActiveImage === 0 ? prevActiveImage : prevActiveImage - 1;

        if (children.length === 1) {
            return
        }

        return (
            <div block="Slider-Arrow Slider-Arrow-Left">
                <button
                    block="Slider"
                    elem="Image"
                    mods={{ type: 'single' }}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => this.changeActiveImage(nextActiveImage)}
                >
                    <div
                        block="Slider-Button Slider-Button-Left"
                    />
                </button>
            </div>
        )
    }

    renderArrowRight() {
        const { prevActiveImage } = this.state;
        const { children } = this.props;
        const nextActiveImage = prevActiveImage + 1 > children.length - 1  ? prevActiveImage : prevActiveImage + 1;

        if (children.length === 1) {
            return
        }

        return (
            <div block="Slider-Arrow Slider-Arrow-Right">
                <button
                    block="Slider"
                    elem="Image"
                    mods={ { type: 'single' } }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ () => this.changeActiveImage(nextActiveImage)}
                >
                    <div
                        block="Slider-Button Slider-Button-Right"
                    />
                </button>
            </div>
        )
    }

    renderSliderContent() {
        const { activeImage, children, isVertical } = this.props;

        if (!this.getIsSlider()) {
            return children;
        }

        return (
            <Draggable
                mix={ { block: 'Slider', elem: 'Wrapper', mods: { isVertical } } }
                draggableRef={ this.draggableRef }
                onDragStart={ this.handleDragStart }
                onDragEnd={ this.handleDragEnd }
                onDrag={ this.handleDrag }
                onClick={ this.handleClick }
                shiftX={ -activeImage * this.getSlideWidth() }
                shiftY={ -activeImage * this.getSlideWidth() }
            >
                { children }
            </Draggable>
        );
    }

    render() {
        const {
            showCrumbs,
            mix
        } = this.props;

        return (
            <div
                block="Slider"
                mix={ mix }
                ref={ this.getSliderRef() }
            >
                { this.renderSliderContent() }
                { showCrumbs && this.renderCrumbs() }
                { !isMobile.any() && this.renderArrowButtons() }
            </div>
        );
    }
}

export default Slider;
