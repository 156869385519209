import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductAvailability from './ProductAvailability.component';
import { updateProductAvailabilityActivePopupId } from 'Store/Product/Product.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import {
    PRODUCT_AVAILABILITY_POPUP_ID
} from 'Component/ProductAvailabilityPopup/ProductAvailabilityPopup.config';
import {
    PICKUP_INFO_CMS
} from './ProductAvailability.config';

export const mapStateToProps = (state) => ({
    isAppInitialized: !state.ConfigReducer.isLoading,
    isProductLoading: state.ProductReducer.isProductLoading,
});

export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(PRODUCT_AVAILABILITY_POPUP_ID, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    updateProductAvailabilityActivePopupId: (productAvailabilityPopupId) => dispatch(updateProductAvailabilityActivePopupId(productAvailabilityPopupId))
});

export class ProductAvailabilityContainer extends PureComponent {
    containerProps = () => {
        const {
            product,
            isProductPage,
            isQueryFetching,
            isAppInitialized,
            isProductLoading
        } = this.props;

        return {
            product,
            isProductPage,
            isQueryFetching,
            isAppInitialized,
            isProductLoading
        };
    }

    containerFunctions = {
        onIconClick: this.onIconClick.bind(this)
    }

    onIconClick(e, cmsPopupID, isAvailable) {
        const {
            showPopup,
            isProductCard,
            updateProductAvailabilityActivePopupId
        } = this.props;

        e.preventDefault();
        e.stopPropagation();

        if (!isAvailable || isProductCard) {
            return;
        }

        updateProductAvailabilityActivePopupId(cmsPopupID);
        showPopup({});
    }

    render() {
        return <ProductAvailability
            { ...this.containerFunctions }
            { ...this.containerProps() }
        />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAvailabilityContainer);
