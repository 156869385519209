import { createRef } from 'react';
import { connect } from 'react-redux';

import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';
import { updateIsPickupPopupCloseVisible } from 'Store/Cart/Cart.action';
import { CLOSEST_STORE } from 'Store/Config/Config.dispatcher';

import BrowserDatabase from 'Util/BrowserDatabase';
import { throttle } from 'Util/Window';
import { urlHistory } from 'Util/Url';
import {
    isMobile,
    isMobileClientHints,
    isUsingClientHints
} from 'Util/Mobile';

import {
    RouterContainer as SourceRouterContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Router/Router.container'

import Router from './Router.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const BlogDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Blog/Blog.dispatcher'
);
export const RecipeDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Recipe/Recipe.dispatcher'
);

/** @namespace Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateIsPickupPopupCloseVisible: (isVisible) => dispatch(updateIsPickupPopupCloseVisible(isVisible)),
    updateInitialCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    distributeCart: (storeCode) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.distributeCart(dispatch, storeCode)
    ),
    init: () => {
        sourceMapDispatchToProps(dispatch).init();
        BlogDispatcher.then(
            ({ default: dispatcher }) => dispatcher.initializeBlog(dispatch)
        );
        RecipeDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getRecipesCategoriesList(dispatch)
        );
    }
});

/** @namespace Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    description: state.MetaReducer.description,
    keywords: state.MetaReducer.keywords
});

export class RouterContainer extends SourceRouterContainer {
    mainRef = createRef();

    state = { isContentLoading: true }

    componentDidMount() {
        super.componentDidMount();
        this.routerObserve()
        this.setViewHeightCssVar();
        this.zeroScrollOnMount();
        window.addEventListener('resize', throttle(this.setViewHeightCssVar.bind(this), 200));
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;

        urlHistory.pushUrl(window.location.pathname);

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                description,
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                keywords
            } = this.props;

            updateMeta({
                default_title,
                title: meta_title || default_title,
                description: description || default_description,
                keywords: default_keywords,
                default_title,
                default_description,
                default_keywords: keywords || default_keywords,
                title_prefix,
                title_suffix,
                status_code
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', throttle(this.setViewHeightCssVar.bind(this), 200));
    }

    handleResize = async () => {
        const { updateConfigDevice } = this.props;
        if (isUsingClientHints) {
            const { platform, model } = await isMobileClientHints.getDeviceData();
            updateConfigDevice({
                isMobile: isMobile.any(),
                isTablet: isMobile.tablet(),
                android: isMobile.android(platform),
                ios: isMobile.iOS(platform),
                blackberry: isMobile.blackBerry(model),
                opera: isMobile.opera(model),
                windows: isMobile.windows(model)
            });
        } else {
            updateConfigDevice({
                isMobile: isMobile.any(),
                isTablet: isMobile.tablet(),
                android: isMobile.android(),
                ios: isMobile.iOS(),
                blackberry: isMobile.blackBerry(),
                opera: isMobile.opera(),
                windows: isMobile.windows()
            });
        }
    };

    setViewHeightCssVar() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    containerProps = () => {
        const { isBigOffline, isLoading } = this.props;

        return {
            mainRef: this.mainRef,
            isBigOffline,
            isLoading
        };
    };

    zeroScrollOnMount() {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 600);
    }

    initializeApplication() {
        const { init, distributeCart, updateIsPickupPopupCloseVisible } = this.props;
        const { pathname } = location;

        const { pickup_location_code } = BrowserDatabase.getItem(CLOSEST_STORE) || {};

        if (pathname.includes('cart')) {
            updateIsPickupPopupCloseVisible(false);
        }

        init();

        if (pickup_location_code) {
            distributeCart(pickup_location_code);
        }
    }

    routerObserve() {
        const observer = new ResizeObserver((e) => this.setRootMinHeight(e))
        const router = document.getElementById('Router');

        // checks if router already mounted, as on live website the error is thrown sometimes.
        if (router) {
            observer.observe(router);
        } else {
            setTimeout(this.routerObserve.bind(this), 1000);
        }
    }

    setRootMinHeight(e) {
        const footer = document.getElementById('FooterWrapper');
        const routerHeight = e[0].contentRect.height;
        const vpHeight = window.innerHeight;
        this.isRouterContentLoading(routerHeight)
        if (footer) {
            if (routerHeight + footer.offsetHeight < vpHeight) {
                footer.style.position = "absolute"
            } else {
                footer.style.position = "relative";
            }
        }
    }

    isRouterContentLoading(routerHeight) {
        const { device } = this.props
        const availableContentLimitHeight = device.isMobile ? 100 : 400;
        if (routerHeight < availableContentLimitHeight) {
            this.setState({ isContentLoading: true })
        } else {
            this.setState({ isContentLoading: false })
        }
    }

    redirectFromPartialUrl() {
        const { base_link_url } = this.props;
        const { pathname: storePrefix } = new URL(base_link_url || window.location.origin);
        const { pathname } = location;

        if (window.localStorage) {
            window.localStorage.setItem('testRouter', pathname);
        }

        if (storePrefix === '/') {
            return;
        }

        if (storePrefix.slice(0, -1) === pathname) {
            history.replace(storePrefix);
        }
    }

    render() {
        return (
            <Router
                {...this.containerProps()}
                {...this.state}
            />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
