import PropTypes from 'prop-types';
import { ProductPrice as SourceProductPrice } from "SourceComponent/ProductPrice/ProductPrice.component"
import { formatPrice, transformToGrams, getIsCurrentDateInRange, getisOldPriceRendered } from 'Util/Price';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import { MixType } from 'SourceType/Common';
import { PriceType } from 'SourceType/ProductList';

import { PRICE_TYPE_KG } from './ProductPrice.config';

import './ProductPrice.style';


export class ProductPrice extends SourceProductPrice {
    static propTypes = {
        isSchemaRequired: PropTypes.bool,
        roundedRegularPrice: PropTypes.string,
        priceCurrency: PropTypes.string,
        discountPercentage: PropTypes.number,
        formattedFinalPrice: PropTypes.string,
        variantsCount: PropTypes.number,
        price: PriceType,
        mix: MixType
    };

    static defaultProps = {
        isSchemaRequired: false,
        roundedRegularPrice: '0',
        priceCurrency: 'USD',
        discountPercentage: 0,
        formattedFinalPrice: '0',
        variantsCount: 0,
        mix: {},
        price: {}
    };

    renderPlaceholder() {
        const { mix } = this.props;

        return (
            <p block="ProductPrice" aria-label="Product Price" mix={ mix }>
                <TextPlaceholder mix={ { block: 'ProductPrice', elem: 'Placeholder' } } length="custom" />
            </p>
        );
    }

    getCurrencySchema() {
        const { isSchemaRequired, priceCurrency } = this.props;
        return isSchemaRequired ? { itemProp: 'priceCurrency', content: priceCurrency } : {};
    }

    getCurrentPriceSchema() {
        const { isSchemaRequired, variantsCount, price } = this.props;
        const content_price = price.minimum_price.final_price
            ? price.minimum_price.final_price.value : price.minimum_price.regular_price.value;

        if (variantsCount > 1) {
            return isSchemaRequired ? { itemProp: 'lowPrice', content: content_price } : {};
        }

        return isSchemaRequired ? { itemProp: 'price', content: content_price } : {};
    }

    renderOldPrice(oldPrice, isVisible, isWeightType) {
        const { storeCode } = this.props;

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              mods={ { isVisible, isWeightType } }
              aria-label={ __('Old product price') }
            >
                {
                    isWeightType
                        ? `${ formatPrice(oldPrice, storeCode) } /kg`
                        : formatPrice(oldPrice, storeCode)
                }
            </del>
        );
    }

    renderWeight() {
        const {
            isProductPage,
            product: { weight }
        } = this.props;

        if (!weight) {
            return null;
        }

        const weightInGr = transformToGrams(weight);

        return (
            <span block="ProductPrice" elem="Weight" mods={ { isProductPage } }>
                { `${ __('Approximately') } ${ weightInGr } g` }
            </span>
        );
    }

    renderCurrentPricePerKgs(isCurrentDateInRange) {
        const {
            product: {
                price_per_kgs,
                special_price_per_kgs
            },
            storeCode,
            isProductActions
        } = this.props;

        if (!price_per_kgs) {
            return null;
        }

        const currentPrice = special_price_per_kgs || price_per_kgs;
        const formattedPricePerKgs = formatPrice(currentPrice, storeCode);
        const isSpecialPrice = !!special_price_per_kgs && isCurrentDateInRange;
        const PriceSemanticElementName = isSpecialPrice ? 'ins' : 'span';

        return (
            <div block="ProductPrice" elem="CurrentPricePerKgs" mods={ { isProductActions, isProductActionsSpecial: isProductActions && isSpecialPrice } }>
                <PriceSemanticElementName>
                    <span>{ formattedPricePerKgs } /kg</span>
                </PriceSemanticElementName>
            </div>
        );
    }

    renderPriceWithWeight(isOldPriceRendered, isCurrentDateInRange) {
        const {
            isProductCard,
            roundedRegularPrice,
            isSearchProductSlider,
            product: {
                special_price_per_kgs,
                price_per_kgs
            }
        } = this.props;

        if (isSearchProductSlider) {
            return (
                <>
                    <div block="ProductPrice" elem="CurrentPricesWrapper" mods={ { isRegularPrice: !isOldPriceRendered } }>
                        { this.renderCurrentPrice(isOldPriceRendered) }
                        { this.renderCurrentPricePerKgs(isCurrentDateInRange) }
                    </div>
                    { this.renderOldPrice(roundedRegularPrice, isOldPriceRendered) }
                </>
            );
        }

        if (isProductCard) {
            return (
                <>
                    { this.renderCurrentPrice(isOldPriceRendered) }
                    { this.renderCurrentPricePerKgs(isCurrentDateInRange) }
                    { this.renderOldPrice(roundedRegularPrice, isOldPriceRendered) }
                </>
            );
        }

        return (
            <>
                { this.renderCurrentPrice(isOldPriceRendered) }
                { this.renderCurrentPricePerKgs(isCurrentDateInRange) }
                { this.renderOldPrice(roundedRegularPrice, isOldPriceRendered) }
                { this.renderOldPrice(price_per_kgs, !!special_price_per_kgs && isCurrentDateInRange, true) }
                { this.renderWeight() }
            </>
        );
    }

    renderCurrentPrice(isOldPriceRendered) {
        const {
            isProductPage,
            price,
            roundedRegularPrice,
            storeCode,
            isPerUnit,
            qty = 1
        } = this.props;

        let displayedPrice = isOldPriceRendered
            ? price.minimum_price.final_price.value
            : roundedRegularPrice;

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        if( typeof displayedPrice === "string" ){
            displayedPrice = parseFloat(displayedPrice.replace(/,/, "."));
        }

        const PriceSemanticElementName = isOldPriceRendered ? 'ins' : 'span';
        const finalPrice = displayedPrice * qty;
        const priceInFormat = formatPrice(finalPrice, storeCode);

        const priceForRender = !isPerUnit
            ? priceInFormat
            : <span>
                {priceInFormat}&nbsp;
                <span block="ProductPrice" elem="UnitText">
                    /{__("unit")}
                </span>
            </span>;

        return (
            <PriceSemanticElementName block="ProductPrice" elem="CurrentPriceWrapper" mods={ { isProductPage, isOldPriceRendered } }>
                <span>{ priceForRender }</span>
            </PriceSemanticElementName>
        );
    }

    renderDefaultPrice(isOldPriceRendered) {
        const { roundedRegularPrice } = this.props;

        return (
            <>
                { this.renderCurrentPrice(isOldPriceRendered) }
                { this.renderOldPrice(roundedRegularPrice, isOldPriceRendered) }
            </>
        );
    }

    render() {
        const {
            discountPercentage,
            specialFromDate,
            specialToDate,
            isProductCard,
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {}
            } = {},
            product: { price_type_kg } = {},
            formattedFinalPrice,
            mix,
            isProductPage
        } = this.props;

        const isWeightType = price_type_kg === PRICE_TYPE_KG && (isProductPage || isProductCard);
        const isCurrentDateInRange = getIsCurrentDateInRange(specialFromDate, specialToDate);
        const isOldPriceRendered = getisOldPriceRendered(specialFromDate, specialToDate, discountPercentage);

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mods={ { isWeightType, isProductPage } }
              mix={ mix }
              aria-label={ `Product price: ${ formattedFinalPrice }` }
            >
                { isWeightType ? this.renderPriceWithWeight(isOldPriceRendered, isCurrentDateInRange) : this.renderDefaultPrice(isOldPriceRendered) }
            </p>
        );
    }
}

export default ProductPrice;
