/** * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export {
    UPDATE_SHIPPING_FIELDS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    updateShippingFields,
    updateEmail,
    updateEmailAvailable
} from 'SourceStore/Checkout/Checkout.action';

export const UPDATE_CHECKOUT_FLOW = 'UPDATE_CHECKOUT_FLOW';
export const UPDATE_SHIPPING_LOCATION = 'UPDATE_SHIPPING_LOCATION';
export const UPDATE_IS_TOO_FAR_FLOW = 'UPDATE_IS_TOO_FAR_FLOW';
export const UPDATE_IS_EMAIL_AVAILABILITY_LOADING = 'UPDATE_IS_EMAIL_AVAILABILITY_LOADING';
export const UPDATE_LAST_ESTIMATE_SHIPPING_ADDRESS = 'UPDATE_LAST_ESTIMATE_SHIPPING_ADDRESS';
export const UPDATE_ESTIMATE_SHIPPING_FIELDS = 'UPDATE_ESTIMATE_SHIPPING_FIELDS';

/** @namespace Store/Checkout/Action/updateEmailAvailable */
export const updateCheckoutFlow = (checkoutFlow) => ({
    type: UPDATE_CHECKOUT_FLOW,
    checkoutFlow
});

/** @namespace Store/Checkout/Action/updateIsEmailAvailabilityLoading */
export const updateIsEmailAvailabilityLoading = (isEmailAvailabilityLoading) => ({
    type: UPDATE_IS_EMAIL_AVAILABILITY_LOADING,
    isEmailAvailabilityLoading
});

/** @namespace Store/Checkout/Action/updateEmailAvailable */
export const updateShippingLocation = (shippingLocation) => ({
    type: UPDATE_SHIPPING_LOCATION,
    shippingLocation
});

/** @namespace Store/Checkout/Action/updateEmailAvailable */
export const updateIsTooFarFlow = (isTooFarFlow) => ({
    type: UPDATE_IS_TOO_FAR_FLOW,
    isTooFarFlow
});

/** @namespace Store/Checkout/Action/updateLastEstimateShippingAddress */
export const updateLastEstimateShippingAddress = (lastShippingAddress) => ({
    type: UPDATE_LAST_ESTIMATE_SHIPPING_ADDRESS,
    lastShippingAddress
});

/** @namespace Store/Checkout/Action/updateEstimateShippingCountryId */
export const updateEstimateShippingFields = (estimateShippingFields) => ({
    type: UPDATE_ESTIMATE_SHIPPING_FIELDS,
    estimateShippingFields
});
