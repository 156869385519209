/* eslint-disable quote-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, {
    EVENT_GTM_PRODUCT_ADD_TO_WISHLIST
} from '../../util/Event';

import ProductListQuery from 'Query/ProductList.query';
import { fetchQuery } from 'Util/Request';

export const sendAddToWishlistEvent = (args, callback) => {
    const { sku } = args[1];
    if (sku) {
        const query = ProductListQuery.getGtmProductData(sku);
        fetchQuery(query).then(
            (product) => {
                const item = product?.products?.items[0];
                if(item) {
                    Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_WISHLIST, {
                        product: { ...item },
                    });
                }
            },
            (err) => console.log('err: ', err)
        );
    }
    return callback(...args);
};

export default {
    'Store/Wishlist/Dispatcher': {
        'member-function': {
            addItemToWishlist: sendAddToWishlistEvent
        }
    }
};
