/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_CURRENT_CATEGORY,
    UPDATE_ALL_CATEGORY,
    UPDATE_IS_NAVIGATION_IN_FILTER,
    UPDATE_CURRENT_CATEGORY_ALLEYS
} from './Category.action';

/** */
export const getInitialState = () => ({
    category: {},
    allCategory:[],
    currentCategoryAlleys: {},
    isNavigationInFilter: false
});

/** @namespace Store/Category/Reducer */
export const CategoryReducer = (
    state = getInitialState(),
    {
        type,
        category,
        isNavigationInFilter,
        currentCategoryAlleys
    }
) => {
    switch (type) {
    case UPDATE_CURRENT_CATEGORY:
        return {
            ...state,
            category: { ...category }
        };
    case UPDATE_ALL_CATEGORY:
    return {
        ...state,
        allCategory: { ...category }
    };

    case UPDATE_IS_NAVIGATION_IN_FILTER:
    return {
        ...state,
        isNavigationInFilter
    };

    case UPDATE_CURRENT_CATEGORY_ALLEYS:
    return {
        ...state,
        currentCategoryAlleys
    };

    default:
        return state;
    }
};

export default CategoryReducer;
