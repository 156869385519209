import { MenuItem as SourceMenuItem } from 'SourceComponent/MenuItem/MenuItem.component';
import Link from 'Component/Link';
import media from 'Util/Media';
import Image from 'Component/Image';
export class MenuItem extends SourceMenuItem {

    __construct(props) {
        super.__construct(props);

        this.handleCategoryClick = this.handleCategoryClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.device.isMobile) {
            if (!props.showMobileMenu) {
                if (props.isEcom) {
                    return {
                        showSub: false,
                        isFirstSubLevelActive: false,
                        isSecondSubLevelActive: false,
                        activeCategory: "",
                        activeSubCategory: "",
                        isEcomPage: true,
                        showEcomMenu: true
                    }
                }
                return {
                    showSub: false,
                    isFirstSubLevelActive: false,
                    isSecondSubLevelActive: false,
                    activeCategory: "",
                    activeSubCategory: "",
                    isEcomPage: false,
                    showEcomMenu: false
                }
            }
            return null
        }
    }

    state = {
        showSub: false,
        locations: "",
        showMainMenu: true,
        isFirstSubLevelActive: false,
        isSecondSubLevelActive: false,
        activeCategory: "",
        activeSubCategory: "",
        isEcomPage: false,
        activeField: "",
        showEcomMenu: false
    }

    componentDidUpdate() {
        const { item, activeLink, device } = this.props
        const { locations } = this.state
        if (!device.isMobile) {
            if (item.title === activeLink) {
                this.setState({ showSub: true })
            } else {
                setTimeout(() => this.setState({ showSub: false }), 100)
            }
        } else {
            if (locations !== location.pathname) {
                this.setState({
                    showSub: false,
                    locations: location.pathname,
                    isFirstSubLevelActive: false,
                    isSecondSubLevelActive: false,
                })
            }
        }
    }

    handleCategoryClick() {
        const { onCategoryClick, item } = this.props;

        if (onCategoryClick) {
            onCategoryClick(item);
        }
    }

    renderItemContentImage(icon, itemMods, icon_active_state) {
        if (!icon && !icon_active_state) {
            return null;
        }


        if (!!icon.$$typeof) {
            return <div mix={{ block: 'Menu', elem: 'isComponent' }}>{icon}</div>
        }

        return (
            <>
            <Image
                mix={{ block: 'Menu', elem: 'Image', mods: itemMods }}
                src={icon && media(icon)}
                ratio="custom"
            />
             <Image
                mix={{ block: 'Menu', elem: 'Image', mods: {itemMods,isActive:true} }}
                src={icon_active_state && media(icon_active_state)}
                ratio="custom"
            />
            </>
        );
    }


    renderBackButton(title, isCategory, isFirstSubLevel = false, isSecondSubLevel = false) {
        const { isEcom } = this.props
        if (isFirstSubLevel) {
            return (
                <div
                    block="backButton"
                    onClick={() => this.setState({ isFirstSubLevelActive: false })}>
                    {title}
                </div>
            )
        }
        if (isSecondSubLevel) {
            return (
                <div
                    block="backButton"
                    onClick={() => this.setState({ isSecondSubLevelActive: false })}>
                    {title}
                </div>
            )
        }
        if (!!isCategory && isEcom) {
            return (
                <div
                    block="backButton"
                    onClick={() => this.setState({ showSub: false, showEcomMenu: false })}>
                    { __('back to main menu')}
                </div>
            )
        }
        return (
            <div
                block="backButton"
                onClick={() => this.setState({ showSub: false })}>
                {title}
            </div>
        )
    }

    mobileLinkHandler(event, element) {
        const { logout, closeMobileMenu, device } = this.props
        if (!!element.isLogout) {
            logout()
        }
        if (device.isMobile) {
            closeMobileMenu()
        }
    }

    renderViewAllLink(e, url_type) {
        return (
            <Link
              to={e.url}
              block="Menu"
              onClick={(event) => this.mobileLinkHandler(event, e)}
              elem="SubCategoriesViewAllLink"
              mix={{ block: "inSubCategory" }}
              target={url_type === 0 ? '_blank' : ""}
            >
                {__('View all')}
            </Link>
        );
    }

    renderSubLink(subEl) {
        return (
            <Link
              to={subEl.url}
              block="Menu"
              elem="firstSubLevel"
              key={subEl.name}
            >
                {subEl.name}
            </Link>
        );
    }

    renderSubElem(el, isFirstLevelLink) {

        if (isFirstLevelLink) {
            return this.renderSubLink(el);
        }

        return (
            <p
              block="Menu"
              elem="firstSubLevel"
              mods={{ haveSubLevel: true }}
              key={el.name}
              onClick={() => this.setState({ activeSubCategory: el.name, isSecondSubLevelActive: true })}
            >
                {el.name}
            </p>
        )
    }

    renderSubItem({ name: activeCategory, children, url }) {
        if (!children?.length) {
            return (
                <Link
                  to={url}
                  mix={ { block: "Menu", elem: "SubItemLink" } }
                >
                    {activeCategory}
                </Link>
            );
        }


        return (
            <p
              onClick={() => this.setState({ activeCategory, isFirstSubLevelActive: true })}
            >
                {activeCategory}
            </p>
        );
    }

    renderSubCategory(children, url, url_type, title, isAccount, isCategory) {
        const {
            showSub,
            isFirstSubLevelActive,
            activeCategory,
            activeSubCategory,
            isSecondSubLevelActive,
            showEcomMenu
        } = this.state

        const {
            device,
            shopOnlineSubCategory,
            renderShippingCms
        } = this.props

        if (!children.length) {
            return null
        }

        let subItem = [];

        if (!!isCategory) {
            subItem = Object.values(shopOnlineSubCategory).map(e => {
                if (e.include_in_menu === 0) {
                    return null
                }
                return (
                    <>
                        <li
                            block="Menu"
                            elem="subItem"
                            key={e.name}
                            mods={{ isActive: activeCategory == e.name }}
                        >
                            {this.renderSubItem(e)}
                            <div
                                block="Menu"
                                elem="firstSubLevelList"
                                mods={{ isFirstSubLevelActive: isFirstSubLevelActive && activeCategory == e.name }}
                            >
                                {this.renderBackButton(e.name, false, true)}
                                <div
                                    block="Menu"
                                    elem="firstSubLevelWrapper"
                                >
                                    { this.renderViewAllLink(e, url_type) }
                                    {e.children.sort((a, b) => a.id - b.id).map(el => {
                                        if (el.include_in_menu === 0) {
                                                return null
                                        }

                                        const isFirstLevelLink = el.children.every(({ include_in_menu }) => include_in_menu === 0);

                                        if (!!el.children.length) {
                                            return (
                                                <>
                                                    { this.renderSubElem(el, isFirstLevelLink) }
                                                    <div
                                                        block="Menu"
                                                        elem="secondSubLevelList"
                                                        mods={{ isSecondSubLevelActive: isSecondSubLevelActive && activeSubCategory == el.name }}
                                                    >
                                                        {this.renderBackButton(el.name, false, false, true)}
                                                        { this.renderViewAllLink(el, url_type) }
                                                        <div>
                                                            {el.children.sort((a, b) => a.id - b.id).map(subEl => {
                                                                if (subEl.include_in_menu === 0) {
                                                                    return null;
                                                                }

                                                                return this.renderSubLink(subEl);
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        return (
                                            <Link
                                                to={el.url}
                                                block="Menu"
                                                elem="firstSubLevel"
                                                key={el.name}
                                            >
                                                {el.name}
                                            </Link>
                                        )

                                    })}
                                </div>
                            </div>
                        </li>

                    </>
                )
            })
        } else {
            subItem = children.map(e => {
                if (!e.url) {
                    return null
                }
                if (e.include_in_menu === 0) {
                    return null
            }
                return (
                    <li
                        block="Menu"
                        elem="subItem"
                        key={e.title}
                    >
                        <Link to={e.url}
                            onClick={(event) => this.mobileLinkHandler(event, e)}
                            target={e.url_type === 0 ? '_blank' : ""}>
                            {e.title}
                        </Link>
                    </li>
                )
            })
        }

        if (device.isMobile) {
            return (
                <div
                    block="Menu"
                    elem="subCategoryWrapper"
                    mix={{ block: "Menu", elem: "subCategoryMobile", mods: { showSub, showEcomMenu: isCategory && showEcomMenu } }}
                >
                    {this.renderBackButton(title, isCategory)}
                    <div
                        block="Menu"
                        elem="subCategory"
                    >
                        <ul
                            block="Menu"
                            elem="subItemList"
                        >
                            {subItem}
                        </ul>
                        {isCategory ? renderShippingCms() : null}
                    </div>
                </div>
            )
        }

        return (
            <div block="Menu"
                elem="subCategoryWrapper"
                mods={{ showSub }}
            >
                <div
                    block="Menu"
                    elem="subCategory"
                >
                    <ul
                        block="Menu"
                        elem="subItemList">
                        {subItem}
                    </ul>
                </div>
            </div>
        )
    }

    renderItemLinkContent() {
        const {
            item,
            itemMods,
            handleCategoryHover,
            disableClick,
            handleSubCategoryOut,
        } = this.props;

        const {
            url,
            item_id,
            children,
            url_type
        } = item;

        if (disableClick) {
            return (
                <Link
                    type="button"
                    block="Menu"
                    elem="Link"
                    id={item_id}
                    onMouseEnter={handleCategoryHover}
                    onMouseLeave={handleSubCategoryOut ? handleSubCategoryOut : null}
                    to={url}
                    target={url_type === 0 ? '_blank' : ""}
                    onClick={this.handleCategoryClick}
                >
                    { this.renderItemContent(item, itemMods)}
                </Link>
            );
        }

        return (
            <>
                { this.renderSubCategory(children, url, url_type)}
                <Link
                    to={url}
                    block="Menu"
                    elem="Link"
                    id={item_id}
                    target={url_type === 0 ? '_blank' : ""}
                    onClick={(e) => this.linkHandler(e)}
                >
                    {this.renderItemContent(item, itemMods)}
                </Link>
            </>
        );
    }

    renderItemContent(item, itemMods = {}) {
        const { title, icon, children, url, url_type, isCategory, isAccount, icon_active_state } = item;
        const { isBanner } = itemMods;
        return (
            <>
                <figure
                    block="Menu"
                    elem="ItemFigure"
                    mods={itemMods}
                    mix={{ block: "Menu", elem: "ItemFigure", mods: { isCategory: !!isCategory, isAccount: !!isAccount } }}
                    onClick={() => this.setState({ showSub: true })}
                >
                    {this.renderItemContentImage(icon, itemMods, icon_active_state)}
                    <figcaption
                        block="Menu"
                        elem="ItemCaption"
                        mods={itemMods}
                    >
                        {this.renderItemContentTitle(isBanner, title)}
                    </figcaption>
                </figure>
                {this.renderSubCategory(children, url, url_type, title, isAccount, isCategory)}
            </>
        );
    }


    linkHandler(e) {
        const { item } = this.props
        if (item.children.length) {
            e.preventDefault()
        }
        this.setState({ showSub: false })
    }

    render() {
        const { item, itemMods, isLink } = this.props;
        if (isLink) {
            return this.renderItemLinkContent();
        }

        return this.renderItemContent(item, itemMods);
    }

}

export default MenuItem;
