import { connect } from 'react-redux';

import {
    MyAccountAddressTableContainer as SourceMyAccountAddressTableContainer,
    mapStateToProps,
    mapDispatchToProps,
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

export class MyAccountAddressTableContainer extends SourceMyAccountAddressTableContainer {
    // TODO implement logic
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressTableContainer);
