/* eslint-disable react/require-render-return */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from "react-redux";

import {
    NavigationAbstractContainer as SourceNavigationAbstractContainer,
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps,
} from "SourceComponent/NavigationAbstract/NavigationAbstract.container";

import { CATEGORY_FILTER_OVERLAY_ID } from "Component/CategoryFilterOverlay/CategoryFilterOverlay.config";

import { DEFAULT_STATE_NAME } from "./NavigationAbstract.config";

import { isSignedIn } from 'Util/Auth';

export const DEFAULT_STATE = { name: DEFAULT_STATE_NAME };

/** @namespace Component/NavigationAbstract/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Component/NavigationAbstract/Container */
export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    handleDesktopRouteChange() {
        const { setNavigationState } = this.props;

        setNavigationState(this.routeMap["/"]);
        this.hideOverlay();

        return {};
    }

    onRouteChanged(history) {
        const { device } = this.props;

        // check if token is expired and logout
        isSignedIn();

        if (!device.isMobile) {
            return this.handleDesktopRouteChange(history);
        }

        return this.handleMobileUrlChange(history);
    }

    hideOverlay() {
        const { activeOverlay, hideActiveOverlay } = this.props;

        switch (activeOverlay) {
            // keeping overlay opened for category filters
            case CATEGORY_FILTER_OVERLAY_ID:
                return;
            default:
                hideActiveOverlay();
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationAbstractContainer);
