/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { FR_STORE, EN_STORE } from "Util/Price/Price.config";

/** @namespace Util/StoreSwitcher/getCurrentUrl */
export const getCurrentUrl = (storeCodes, pathname) => {
    return storeCodes.reduce(
        (acc, storeCode) => {
            if (pathname.includes(`/${ storeCode }/`)) {
                acc = pathname.substr(4);
            } else if (acc === '') {
                acc = pathname;
            }

            return acc;
        },
        ''
    );
}

/** @namespace Util/StoreSwitcher/getAllStoreCodes */
export const getAllStoreCodes = () => [FR_STORE, EN_STORE];

/** @namespace Util/StoreSwitcher/getPathnameWithoutStoreCode */
export const getPathnameWithoutStoreCode = (pathname) => {
    return getAllStoreCodes().some(
        (code) => pathname.substring(0, 4) === `/${code}/`
    )
        ? pathname.substring(3)
        : pathname;
}

/** @namespace Util/StoreSwitcher/composeFullUrl */
export const composeFullUrl = (store_code, pathname) => {
	const { origin, search } = location;
	let url = `${origin}/${store_code}`;

    if (pathname) {
        url += `${getPathnameWithoutStoreCode(pathname)}`;
    }

    if (search) {
        url += search;
    }

    return url;
};

export const getHreflangPropValue = (storeCode) => `${storeCode}-ca`;

export const updateHrefLangTags = (() => {
    let tags = [];

	/** @namespace Util/StoreSwitcher/createTags */
    function createTags(otherStoreUrls, lang) {
        removeTags();
        const allStoresCodes = getAllStoreCodes();
		const { pathname } = location;
		const { store_code, url_key } = otherStoreUrls[0] || {};
		const otherLang = allStoresCodes.filter((code) => code !== lang)[0];
		const tagsData = [];

        // 1) another store url
		tagsData.push({
			hreflang: getHreflangPropValue(store_code || otherLang),
			href: store_code
				? composeFullUrl(store_code, `/${url_key}`)
				: composeFullUrl(otherLang, pathname),
		});

        // 2) current store url
		tagsData.push({
			hreflang: getHreflangPropValue(lang),
			href: composeFullUrl(lang, pathname),
		});

        // creating hreflang link tags for all urls
		tagsData.forEach(({ hreflang, href }) => {
			const link = document.createElement('link');
            link.rel = "alternate";
            link.hreflang = hreflang;
            link.href = href;
            tags.push(link);

            document.head.appendChild(link);
		});
	};

  	/** @namespace Util/StoreSwitcher/removeTags */
    function removeTags() {
        tags.forEach((tag) => {
			tag.parentNode.removeChild(tag);
		});
        tags = [];
    }

    return {
        createTags,
        removeTags
    };
})();
