import loadTranslation from './loadTranslation';
import localeMap from './localeMap';

class I18n {
    constructor() {
        if (window.defaultLocale) {
            this.setLocale(window.defaultLocale);
        } else {

            /**
             * This one is used to map the locale with language package,
             * use the part of the url after first "/" to determine the language
             * 
             * Everything that won't match - will get "default one"
             */

             // TODO add API to read this array from package.json
            let storeMap = {
                "fr": "fr_CA",
                "en" : "en_CA",
                "default": "fr_CA"
                },
                pathName = window.location.pathname,
                keys = Object.keys(storeMap);

            this.processStoreMap(keys, storeMap, pathName);

            // If still is undefined - then fallsback to the default one
            if (typeof(this.getCurrentLocale()) === "undefined") {
                this.setLocale(storeMap.default);
            }
        }
    }

    currentTranslation = {};

    /**
     * @param {array} keys 
     * @param {object} storeMap 
     * @param {string} pathName 
     */
    processStoreMap(keys, storeMap, pathName) {
        keys.forEach ((value, key, arr) => {
            if (this.isLocalMatchUrl(value, pathName)) {
                this.setLocale(storeMap[value]);

                /**
                 * Breaking of forEach
                 */
                arr.length = key + 1;
            }
        })
    }

    isDefaultLocale (locale) {
        return locale === "default";
    }

    /**
     * 
     * Business logic for defining the translation
     * 
     * @param {string} locale 
     * @param {string} pathName 
     */
    isLocalMatchUrl(locale, pathName) {

        /**
         * Early return for "defaul" case
         */
        if (this.isDefaultLocale(locale)) {
            return false;
        }

        /**
         * sizeWithOneForwardSlash - "e.g. '/en'",
         * sizeWithTwoForwardSlashes - "e.g. '/en/'"
         */
        let sizeWithOneForwardSlash =   locale.length + 1,
            sizeWithTwoForwardSlashes = locale.length + 2;

        /**
         * Will first check if substring of sizeWithTwoForwardSlashes size equals e.g. /en/
         * Then will check if substring of sizeWithOneForwardSlash and empty character next equals /en
         * Empty character check is requried to exclude test.com/enabled page which will mistakenly
         * return true for /en
         */
        return (
            pathName.substring(0, sizeWithTwoForwardSlashes).includes("/" + locale + "/") ||
            (pathName.substring(0, sizeWithOneForwardSlash).includes("/" + locale) && 
            pathName.substring(sizeWithOneForwardSlash, sizeWithTwoForwardSlashes) === "")
        );
    }

    getLocaleList() {
        return Object.keys(localeMap);
    }

    getCurrentLocale() {
        return this.currentLocale;
    }

    setLocale = async (locale) => {
        // Ignore same locale
        if (locale === this.currentLocale) {
            return;
        }

        // Set the new locale code
        this.currentLocale = locale;

        // Update the current translation map
        this.isLoading = true;
        this.currentTranslation = await loadTranslation(locale);
        this.isLoading = false;

        // Rerender the app for changes to get applied
        this.rerenderApplication();
    };

    /**
     * This method should be called on app init
     * @param {function} rerenderApplication
     */
    init(rerenderApplication) {
        if (typeof rerenderApplication !== 'function') {
            throw new Error("The root component's forceUpdate should be supplied to the i18n init sequence");
        }

        this.rerenderApplication = rerenderApplication;
    }
}

export default new I18n();
