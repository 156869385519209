/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_TOP_CATEGORIES = 'UPDATE_TOP_CATEGORIES';
export const UPDATE_TOP_PRODUCTS = 'UPDATE_TOP_PRODUCTS';
export const UPDATE_TOP_CATEGORY_FOR_PRODUCT = 'UPDATE_TOP_CATEGORY_FOR_PRODUCT';
export const UPDATE_IS_TOP_CATEGORIES_LOADING = 'UPDATE_IS_TOP_CATEGORIES_LOADING';
export const UPDATE_IS_TOP_PRODUCTS_LOADING = 'UPDATE_IS_TOP_PRODUCTS_LOADING';
export const UPDATE_IS_TOP_CATEGORY_FOR_PRODUCT_LOADING = 'UPDATE_IS_TOP_CATEGORY_FOR_PRODUCT_LOADING';
export const UPDATE_X_SEARCH_SUGGESTIONS = 'UPDATE_X_SEARCH_SUGGESTIONS';
export const UPDATE_IS_X_SEARCH_LOADING = 'UPDATE_IS_X_SEARCH_LOADING';
export const UPDATE_IS_SEARCH_INPUT_CLICKED = 'UPDATE_IS_SEARCH_INPUT_CLICKED';
export const UPDATE_IS_SEARCH_PRODUCTS_OPENING = 'UPDATE_IS_SEARCH_PRODUCTS_OPENING';
export const UPDATE_BLOG_POSTS_SEARCH_RESULT = 'UPDATE_BLOG_POSTS_SEARCH_RESULT';
export const UPDATE_LAST_BLOG_POSTS = 'UPDATE_LAST_BLOG_POSTS';
export const UPDATE_LAST_RECIPES = 'UPDATE_LAST_RECIPES';
export const UPDATE_RECIPES_SEARCH_RESULT = 'UPDATE_RECIPES_SEARCH_RESULT';

export const updateTopCategories = (topCategories) => ({
    type: UPDATE_TOP_CATEGORIES,
    topCategories
});

export const updateLastBlogPosts = (lastBlogPosts) => ({
    type: UPDATE_LAST_BLOG_POSTS,
    lastBlogPosts
});

export const updateLastRecipes = (lastRecipes) => ({
    type: UPDATE_LAST_RECIPES,
    lastRecipes
});

export const updateBlogPostsSearchResult = (blogPostsSearchResult) => ({
    type: UPDATE_BLOG_POSTS_SEARCH_RESULT,
    blogPostsSearchResult
});

export const updateRecipesSearchResult = (recipesSearchResult) => ({
    type: UPDATE_RECIPES_SEARCH_RESULT,
    recipesSearchResult
});

export const updateTopProducts = (topProducts) => ({
    type: UPDATE_TOP_PRODUCTS,
    topProducts
});

export const updateTopCategoryForTopProducts = (topCategoryForTopProducts) => ({
    type: UPDATE_TOP_CATEGORY_FOR_PRODUCT,
    topCategoryForTopProducts
});

export const updateIsTopCategoriesLoading = (isTopCategoriesLoading) => ({
    type: UPDATE_IS_TOP_CATEGORIES_LOADING,
    isTopCategoriesLoading
});

export const updateIsTopProductsLoading = (isTopProductsLoading) => ({
    type: UPDATE_IS_TOP_PRODUCTS_LOADING,
    isTopProductsLoading
});

export const updateIsTopCategoryForTopProductsLoading = (isTopCategoryForTopProductsLoading) => ({
    type: UPDATE_IS_TOP_CATEGORY_FOR_PRODUCT_LOADING,
    isTopCategoryForTopProductsLoading
});

export const updateXSearchSuggestions = (xSearchSuggestions) => ({
    type: UPDATE_X_SEARCH_SUGGESTIONS,
    xSearchSuggestions
});

export const updateIsSearchProductsOpening = (isSearchProductsOpening) => ({
    type: UPDATE_IS_SEARCH_PRODUCTS_OPENING,
    isSearchProductsOpening
});

export const updateIsXSearchLoading = (isXSearchLoading) => ({
    type: UPDATE_IS_X_SEARCH_LOADING,
    isXSearchLoading
});

export const updateIsSearchInputClicked = (isSearchInputClicked) => ({
    type: UPDATE_IS_SEARCH_INPUT_CLICKED,
    isSearchInputClicked
});


