/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';

import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { fetchMutation } from 'Util/Request';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer,
    mapDispatchToProps as SourceMapDispatchToProps
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import MyAccountCreateAccount from './MyAccountCreateAccount.component';
import BrowserDatabase from 'Util/BrowserDatabase';
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.MyAccountReducer.isLoading,
    countries: state.ConfigReducer.countries,
    default_country: state.ConfigReducer.default_country
});

/** @namespace Component/MyAccountCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    )
});

/** @namespace Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    state = {
        isLoadingForm: false,
        isLoyaltyFieldShow: undefined,
        cardNumberValue: ''
    }

    containerFunctions = {
        ...this.containerFunctions,
        onCartFieldChange: this.onCartFieldChange.bind(this),
        changeLoyaltyShowState: this.changeLoyaltyShowState.bind(this)
    };

    componentDidUpdate() {
        const { isLoyaltyFieldShow, cardNumberValue } = this.state;

        if (!isLoyaltyFieldShow && cardNumberValue.length !== 0) {
            this.onCartFieldChange('');
        }
    }

    onCreateAccountAttempt(_, invalidFields) {
        const { showNotification, setLoadingState } = this.props;

        if (invalidFields) {
            showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
        }

        this.setState({isLoadingForm:!invalidFields})
        setLoadingState(!invalidFields);
    }

    onCartFieldChange(value) {
        this.setState({ cardNumberValue: value });
    }

    changeLoyaltyShowState(isLoyaltyFieldShow) {
        this.setState({ isLoyaltyFieldShow });
    }

    async onCreateAccountSuccess(fields) {
        fields.datacandy_card_number = fields.datacandy_card_number ? fields.datacandy_card_number.replace(/ /g,'') : fields.datacandy_card_number;

        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            requestCustomerData
        } = this.props;

        const {
            country_id,
            datacandy_card_number: dataCandyCardNumber,
            email,
            city,
            firstname,
            is_subscribed,
            lastname,
            password,
            postcode,
            region_id,
            street0,
            street1,
            phone,
            date_of_birth,
            loyalty_newsletter,
            avril_newsletter,
        } = fields;

        // removes spaces from the card number
        const datacandy_card_number = dataCandyCardNumber
            ? dataCandyCardNumber.split(' ').join('')
            : undefined;

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                datacandy_card_number,
                date_of_birth,
                loyalty_newsletter,
                avril_newsletter,
                created_from: 'web',
                popup_newsletter: true
            },
            password
        };

        const customerAddress = {
            region_id,
            country_id,
            telephone: phone,
            postcode,
            street: [street0, street1 || ''],
            lastname,
            city,
            firstname,
            default_shipping: true,
            default_billing: true
        }

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData);
            // await this.setShippingAddress(customerAddress);

            requestCustomerData();
            this.setState({isLoadingForm:false})
            // if user needs confirmation
            if (code === 2) {
                setSignInState(STATE_CONFIRM_EMAIL);
            } else {
                onSignIn();
            }
        } finally {
            BrowserDatabase.setItem('new', 'newAccount');
            this.setState({isLoadingForm:false})
            setLoadingState(false);
        }
    }

    setShippingAddress = async (customerAddress) => {
        const {region_id, ...address } = customerAddress;
        const mutation = MyAccountQuery.getCreateAddressMutation({
            ...address, region: { region_id }
        });

        await fetchMutation(mutation);

        return true;
    };

    render() {
        return (
            <MyAccountCreateAccount
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
