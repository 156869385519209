/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ClickOutside from "Component/ClickOutside";

import { FieldSelect as SourceFieldSelect } from "SourceComponent/FieldSelect/FieldSelect.component";

import "./FieldSelect.style";

export class FieldSelect extends SourceFieldSelect {
    state = { selectedOptions: "" };

    componentDidMount() {
        const { value } = this.props;
        this.setState({ selectedOptions: value });
    }

    renderOptions() {
        const {
            selectOptions,
            isContentVisible,
            optionsMix,
            isSelectExpanded: isExpanded,
        } = this.props;

        return (
            <ul
                block="FieldSelect"
                elem="Options"
                role="menu"
                mix={optionsMix}
                mods={{ isExpanded: isExpanded || isContentVisible }}
                onMouseEnter={() => this.mouseEnterHandler()}
                onMouseLeave={() => this.mouseLeaveHandler()}
            >
                {selectOptions.map(this.renderOption)}
            </ul>
        );
    }
    mouseLeaveHandler() {
        const { value } = this.props;
        this.setState({ selectedOptions: value });
    }
    mouseEnterHandler() {
        this.setState({ selectedOptions: "" });
    }

    renderOption = (option) => {
        const { id, label } = option;

        const { selectedOptions } = this.state;
        const {
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick,
            value,
            optionMix,
        } = this.props;
        let isSelected = false;
        let isFill = false;

        if (value === id) {
            isSelected = true;
        }

        if (selectedOptions === id) {
            isFill = true;
        }

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mix={optionMix}
                mods={{ isExpanded, isSelected, isFill }}
                key={id}
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
                id={`o${id}`}
                role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? "0" : "-1"}
            >
                {label}
            </li>
        );
    };

    render() {
        const {
            isSelectExpanded: isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isOptionsComponent,
        } = this.props;

        if (isOptionsComponent) {
            return <>{this.renderOptions()}</>;
        }

        return (
            <ClickOutside onClick={handleSelectExpandedExpand}>
                <div
                    block="FieldSelect"
                    onClick={handleSelectExpand}
                    onKeyPress={handleSelectListKeyPress}
                    role="button"
                    tabIndex="0"
                    aria-label="Select drop-down"
                    aria-expanded={isExpanded}
                >
                    {this.renderNativeSelect()}
                    {this.renderOptions()}
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelect;
