/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import {
    getIsPickupOverlayRequired,
    normalizeCartData,
    getCommentValue
} from 'Util/Cart';
import { CART_TOTALS } from 'SourceStore/Cart/Cart.reducer';
import { TIMESLOT } from 'Component/PickupOverlay/PickupOverlay.config';
import {
    UPDATE_TOTALS,
    UPDATE_CART_CATEGORIES,
    UPDATE_CHEAPEST_SHIPPING_AMOUNT,
    UPDATE_SELECTED_SHIPPING_AMOUNT,
    UPDATE_REWARD_BONUS_CART,
    UPDATE_APPLIED_REWARD_BONUS,
    UPDATE_APPLIED_TAXES_DATA,
    UPDATE_IS_TOTALS_LOADING,
    UPDATE_ADDITIONAL_CART_DATA_LOADING,
    UPDATE_IS_CART_PRODUCTS_ADDING,
    UPDATE_IS_CART_RESETING,
    UPDATE_SELECTED_SHIPPING_METHOD,
    UPDATE_STORE_TIMESLOTS,
    UPDATE_IS_PICKUP_OVERLAY_REQUIRED,
    UPDATE_CHOSEN_TIMESLOT,
    UPDATE_IS_PICKUP_POPUP_CLOSE_VISIBLE,
    UPDATE_SUBSTITUTABLE_PRODUCTS,
    UPDATE_EXCLUDED_ITEMS,
    UPDATE_IS_SUBSTITUTION_CALL_ME_ACTIVE,
    UPDATE_PICKUP_METHODS,
    UPDATE_UNREMOVABLE_CART_CATEGORIES,
    UPDATE_IS_CART_DISTRIBUTED,
    UPDATE_COMMENT_VALUE,
    UPDATE_UNREMOVABLE_CHOSEN_TIMESLOT,
    UPDATE_ITEMS_QTY,
    UPDATE_FETCHING_ADD_PRODUCTS_SKUS,
    UPDATE_PRODUCTS_WITH_CHANGED_QTY,
    CLEAR_PRODUCTS_WITH_CHANGED_QTY,
    UPDATE_SINGLE_PRODUCT_DATA,
    UPDATE_DISCOUNT_DATA
} from './Cart.action';

export {
    CART_TOTALS
};

export const CART_ITEMS_CATEGORIES = 'cart_item_categories';
export const CART_FEES = 'cart_fees';

/** @namespace Store/Cart/Reducer/updateItemsQty */
export const updateItemsQty = (action, state) => {
    const { cartTotals: prevTotals } = state;
    const { sku, qty } = action;

    const cartTotals = { ...prevTotals };
    const items = [ ...cartTotals.items ].map((item) => {
        if (item.sku === sku) {
            item.qty = qty;
        }

        return item;
    });

    cartTotals.items = items;

    return {
        ...state,
        cartTotals
    };
}

/** @namespace Store/Cart/Reducer/updateFetchingAddProductsSkus */
export const updateFetchingAddProductsSkus = (action, state) => {
    const { sku } = action;
    const { fetchingAddProductsSkus: prevFetchingAddProductsSkus } = state;

    let fetchingAddProductsSkus = [ ...prevFetchingAddProductsSkus ];

    if (fetchingAddProductsSkus.includes(sku)) {
        fetchingAddProductsSkus = fetchingAddProductsSkus.filter((loadingSku) => sku !== loadingSku)
    } else {
        fetchingAddProductsSkus.push(sku);
    }

    return {
        ...state,
        fetchingAddProductsSkus
    }
}

/** @namespace Store/Cart/Reducer/clearProductWithChangedQty */
export const clearProductWithChangedQty = (action, state) => {
    const { productsWithChangedQty: prevProductsWithChangedQty } = state;
    const { sku } = action;

    const productsWithChangedQty = prevProductsWithChangedQty
        .filter(({ sku: skuInState }) => skuInState !== sku);

    return {
        ...state,
        productsWithChangedQty
    }
}

/** @namespace Store/Cart/Reducer/updateSingleProductData */
export const updateSingleProductData = (action, state) => {
    const {
        cartTotals: prevCartTotals,
        cartTotals: {
            items: prevItems = []
        }
    } = state;
    const { sku, cartData } = action;

    if (!prevItems.length) {
        return state;
    }

    const isItemInNextCartData = cartData.items.some(isSkuEqual);
    let items = [ ...prevItems ].filter(({ sku: itemSku }) => sku !== itemSku);;

    if (isItemInNextCartData) {
        const itemToUpdate = cartData.items.filter(isSkuEqual)[0];
        items.push(itemToUpdate);
    }


    const cartTotals = { ...prevCartTotals };
    cartTotals.items = items;

    return {
        ...state,
        cartTotals
    }

    function isSkuEqual({ sku: itemSku }) {
        return sku === itemSku;
    }
}

/** @namespace Store/Cart/Reducer/updateProductsWithChangedQty */
export const updateProductsWithChangedQty = (action, state) => {
    const {
        sku,
        isRemoving
    } = action;
    const {
        cartTotals: prevCartTotals,
        productsWithChangedQty: prevProductsWithChangedQty
    } = state;
    const isProductExist = prevProductsWithChangedQty.some(({ sku: skuInState }) => skuInState === sku);

    if (isProductExist && !isRemoving) {
        return state;
    }

    if (isRemoving) {
        let nextQty;
        const productsWithChangedQty = prevProductsWithChangedQty.filter(({ sku: skuInState, qty }) => {
            if (skuInState === sku) {
                nextQty = qty;
            }

            return skuInState !== sku
        });
        const cartTotals = { ...prevCartTotals };
        const items = cartTotals.items.map((item) => {
            if (item.sku === sku) {
                item.qty = nextQty;
            }

            return item;
        });

        cartTotals.items = items;

        return {
            ...state,
            productsWithChangedQty,
            cartTotals
        }
    }

    const qty = prevCartTotals.items.filter(({ sku: skuInState }) => skuInState === sku)[0]?.qty;

    const productsWithChangedQty = [
        ...prevProductsWithChangedQty,
        {
            sku,
            qty
        }
    ];

    return {
        ...state,
        productsWithChangedQty
    }
}


/** @namespace Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action, state) => {
    const { cartData } = action;

    const cartTotals = normalizeCartData(cartData);

    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS
    );

    return {
        ...state,
        cartTotals
    };
};

/** @namespace Store/Cart/Reducer/updatePickupMethods */
export const updatePickupMethods = (action, state) => {
    const { pickupMethods } = action;

    return {
        ...state,
        pickupMethods
    }
}

/** @namespace Store/Cart/Reducer/updateCartCategories */
export const updateCartCategories = (action, state) => {
    const { categories: categoriesToRearrange } = action;

    const categories = reconstructCategoriesObj(categoriesToRearrange);

    BrowserDatabase.setItem(
        categories,
        CART_ITEMS_CATEGORIES
    );

    return {
        ...state,
        categories
    };
};

/** @namespace Store/Cart/Reducer/updateUnremovableCartCategories */
export const updateUnremovableCartCategories = (action, state) => {
    const {
        unremovableCategories: categoriesToRearrange,
        cartData
    } = action;

    const { cartData: normalizedCartData = {} } = normalizeCartData(cartData);
    const unremovableCategories = reconstructCategoriesObj(categoriesToRearrange);

    return {
        ...state,
        unremovableTotals: {
            unremovableCategories,
            unremovableCartData: normalizedCartData
        }
    };
};

/** @namespace Store/Cart/Reducer/updateCheapestShippingAmount */
export const updateCheapestShippingAmount = (action, state) => {
    const { cheapestShippingAmount } = action;

    return {
        ...state,
        cheapestShippingAmount
    };
};

/** @namespace Store/Cart/Reducer/updateSelectedShippingAmount */
export const updateSelectedShippingAmount = (action, state) => {
    const { selectedShippingAmount } = action;

    return {
        ...state,
        selectedShippingAmount
    };
};

/** @namespace Store/Cart/Reducer/updateRewardBonusCart */
export const updateBonusObj = (action, state, key) => {
    const {
        [key]: {
            points = null,
            money: {
                value = 0,
                currency = ''
            } = {}
        }
    } = action;

    if (typeof points === 'object' && !points) {
        return {
            ...state,
            [key]: {}
        }
    }

    return {
        ...state,
        [key]: {
            points,
            money: {
                value,
                currency
            }
        }
    };
};

/** @namespace Store/Cart/Reducer/updateAppliedTaxesData */
export const updateAppliedTaxesData = (action, state) => {
    const { appliedTaxes } = action;

    return {
        ...state,
        appliedTaxes
    }
};

/** @namespace Store/Cart/Reducer/updateIsTotalsLoading */
export const updateIsTotalsLoading = (action, state) => {
    const { isTotalsLoading } = action;

    return {
        ...state,
        isTotalsLoading
    }
}

/** @namespace Store/Cart/Reducer/updateIsAdditionalCartDataLoading */
export const updateIsAdditionalCartDataLoading = (action, state) => {
    const { isAdditionalCartDataLoading } = action;

    return {
        ...state,
        isAdditionalCartDataLoading
    }
}

/** @namespace Store/Cart/Reducer/updateIsCartReseting */
export const updateIsCartReseting = (action, state) => {
    const { isCartReseting } = action;

    return {
        ...state,
        isCartReseting
    }
}

/** @namespace Store/Cart/Reducer/updateIsCartProductsAdding */
export const updateIsCartProductsAdding = (action, state) => {
    const { isCartProductsAdding } = action;

    return {
        ...state,
        isCartProductsAdding
    }
}

/** @namespace Store/Cart/Reducer/updateSelectedShippingMethod */
export const updateSelectedShippingMethod = (action, state) => {
    const { selectedShippingMethod } = action;

    return {
        ...state,
        selectedShippingMethod
    }
}

/** @namespace Store/Cart/Reducer/updateStoreTimeslots */
export const updateStoreTimeslots = (action, state) => {
    const { storeTimeslots } = action;

    return {
        ...state,
        storeTimeslots
    }
}

/** @namespace Store/Cart/Reducer/updateChosenTimeslot */
export const updateChosenTimeslot = (action, state) => {
    const { chosenTimeslot } = action;

    return {
        ...state,
        chosenTimeslot
    }
}

/** @namespace Store/Cart/Reducer/updateUnremovableChosenTimeslot */
export const updateUnremovableChosenTimeslot = (action, state) => {
    const { unremovableChosenTimeslot } = action;

    return {
        ...state,
        unremovableChosenTimeslot
    }
}

/** @namespace Store/Cart/Reducer/updateIsPickupOverlayRequired */
export const updateIsPickupOverlayRequired = (action, state) => {
    const { isPickupOverlayRequired } = action;

    return {
        ...state,
        isPickupOverlayRequired
    }
}

/** @namespace Store/Cart/Reducer/updateIsPickupOverlayRequired */
export const updateIsPickupPopupCloseVisible = (action, state) => {
    const { isPickupPopupCloseVisible } = action;

    return {
        ...state,
        isPickupPopupCloseVisible
    }
}

/** @namespace Store/Cart/Reducer/updateSubstitutableProducts */
export const updateSubstitutableProducts = (action, state) => {
    const { substitutableProducts } = action;

    return {
        ...state,
        substitutableProducts
    }
}

export const updateExcludedItems = (action, state) => {
    const { excludedItems } = action;

    return {
        ...state,
        excludedItems
    }
}

export const updateIsSubstitutionCallMeActive = (action, state) => {
    const { isSubstitutionCallMeActive } = action;

    return {
        ...state,
        isSubstitutionCallMeActive
    }
}

export const updateIsCartDistributed = (action, state) => {
    const { isCartDistributed } = action;

    return {
        ...state,
        isCartDistributed
    }
}

export const updateCommentValue = (action, state) => {
    const { commentValue } = action;

    return {
        ...state,
        commentValue
    }
}

export const updateDiscountData = (action, state) => {
    const { value, label } = action.payload;

    if (value === 0) {
        return {
            ...state,
            discountData: [],
        };
    }

    // Caso contrário, adiciona o novo desconto
    return {
        ...state,
        discountData: [
            ...state.discountData,
            {
                value,
                label,
            },
        ],
    };
};
     
/** @namespace Store/Cart/Reducer/reconstructCategoriesObj */
export const reconstructCategoriesObj = (categoriesObject) => {
    return Object.values(categoriesObject)
      .reduce(
        (acc, { alley, items, items_count }) => {
            acc[alley] = {
                items,
                items_count
            }

            return acc;
    },
    {});
};

/** @namespace Store/Cart/Reducer/getInitialState */
export const getInitialState = () => {
    return {
        cartTotals: BrowserDatabase.getItem(CART_TOTALS) || {},
        categories: BrowserDatabase.getItem(CART_ITEMS_CATEGORIES),
        // unremovable data is cleared only after it's set in success page's state
        unremovableTotals: {
            unremovableCategories: BrowserDatabase.getItem(CART_ITEMS_CATEGORIES),
            unremovableCartData: BrowserDatabase.getItem(CART_TOTALS) || {}
        },
        bonus: {},
        appliedBonus: {},
        selectedShippingMethod: {},
        chosenTimeslot: BrowserDatabase.getItem(TIMESLOT) || {},
        unremovableChosenTimeslot: BrowserDatabase.getItem(TIMESLOT) || {},
        appliedTaxes: [],
        storeTimeslots: [],
        excludedItems: [],
        isCartDistributed: false,
        substitutableProducts: [],
        isSubstitutionCallMeActive: false,
        freeShippingTotalAfter: 75,
        cheapestShippingAmount: 0,
        selectedShippingAmount: 0,
        isTotalsLoading: false,
        isAdditionalCartDataLoading: false,
        isCartReseting: false,
        isCartProductsAdding: false,
        isPickupOverlayRequired: getIsPickupOverlayRequired(),
        isPickupPopupCloseVisible: true,
        pickupMethods: [],
        fetchingAddProductsSkus: [],
        productsWithChangedQty: [],
        commentValue: getCommentValue(),
        discountData: []
    }
};

export const CartReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_PICKUP_METHODS:
        return updatePickupMethods(action, state);
    case UPDATE_TOTALS:
        return updateCartTotals(action, state);

    case UPDATE_CART_CATEGORIES:
        return updateCartCategories(action, state);

    case UPDATE_UNREMOVABLE_CART_CATEGORIES:
        return updateUnremovableCartCategories(action, state);

    case UPDATE_CHEAPEST_SHIPPING_AMOUNT:
        return updateCheapestShippingAmount(action, state);

    case UPDATE_SELECTED_SHIPPING_AMOUNT:
        return updateSelectedShippingAmount(action, state);

    case UPDATE_REWARD_BONUS_CART:
        return updateBonusObj(action, state, 'bonus');

    case UPDATE_APPLIED_REWARD_BONUS:
        return updateBonusObj(action, state, 'appliedBonus');

    case UPDATE_APPLIED_TAXES_DATA:
        return updateAppliedTaxesData(action, state);

    case UPDATE_IS_TOTALS_LOADING:
        return updateIsTotalsLoading(action, state);

    case UPDATE_ADDITIONAL_CART_DATA_LOADING:
        return updateIsAdditionalCartDataLoading(action, state);

    case UPDATE_IS_CART_RESETING:
        return updateIsCartReseting(action, state);

    case UPDATE_IS_CART_PRODUCTS_ADDING:
        return updateIsCartProductsAdding(action, state);

    case UPDATE_SELECTED_SHIPPING_METHOD:
        return updateSelectedShippingMethod(action, state);

    case UPDATE_STORE_TIMESLOTS:
        return updateStoreTimeslots(action, state);

    case UPDATE_IS_PICKUP_OVERLAY_REQUIRED:
        return updateIsPickupOverlayRequired(action, state);

    case UPDATE_CHOSEN_TIMESLOT:
        return updateChosenTimeslot(action, state);

    case UPDATE_UNREMOVABLE_CHOSEN_TIMESLOT:
        return updateUnremovableChosenTimeslot(action, state);

    case UPDATE_IS_PICKUP_POPUP_CLOSE_VISIBLE:
        return updateIsPickupPopupCloseVisible(action, state);

    case UPDATE_SUBSTITUTABLE_PRODUCTS:
        return updateSubstitutableProducts(action, state);

    case UPDATE_EXCLUDED_ITEMS:
        return updateExcludedItems(action, state);

    case UPDATE_IS_SUBSTITUTION_CALL_ME_ACTIVE:
        return updateIsSubstitutionCallMeActive(action, state);

    case UPDATE_IS_CART_DISTRIBUTED:
        return updateIsCartDistributed(action, state);

    case UPDATE_COMMENT_VALUE:
        return updateCommentValue(action, state);

    case UPDATE_ITEMS_QTY:
        return updateItemsQty(action, state);

    case UPDATE_FETCHING_ADD_PRODUCTS_SKUS:
        return updateFetchingAddProductsSkus(action, state);

    case UPDATE_PRODUCTS_WITH_CHANGED_QTY:
        return updateProductsWithChangedQty(action, state);

    case CLEAR_PRODUCTS_WITH_CHANGED_QTY:
        return clearProductWithChangedQty(action, state);

    case UPDATE_SINGLE_PRODUCT_DATA:
        return updateSingleProductData(action, state);

    case UPDATE_DISCOUNT_DATA:
        return updateDiscountData(action, state);

    default:
        return state;
    }
};

export default CartReducer;
