/* eslint-disable react/no-unused-state */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    getIsDragOnRight,
    getOrderedChildrenIds,
    getNewTranslateAfterDOMChange,
    getIsLastSlideCurrently,
    getSlidePositionIndexConditionally,
    prependLastArrayChild,
    getFirstRefChild,
    getLastRefChild
} from 'Util/Slider';

import {
    ANIMATION_DURATION,
    RIGHT,
    LEFT
} from './InfiniteSlider.config';
import CSS from 'Util/CSS';

import { Slider as SourceSlider } from 'Component/Slider/Slider.component';

/**
 * Slider component
 * @class Slider
 * @namespace Component/Slider/Component
 */
export class InfiniteSlider extends SourceSlider {
    // needed for infinite slider behavior
    isSlidesMovedForIninity = false;

    isDragOnRight = false;

    __construct(props) {
        super.__construct(props);

        const { activeImage } = this.props;

        this.getIsDragOnRight = getIsDragOnRight(0);

        this.state = {
            prevActiveImage: activeImage,
            childrenKeysMap: [],
            imageToShow: 0
        };
    }

    componentDidMount() {
        super.componentDidMount();

        const { children, slideSpeed } = this.props;

        if (slideSpeed && children.length) {
            this.startCarousel(slideSpeed);
        }

        if (children.length) {
            this.setChildrenKeysMapFromNodes(children);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            activeImage: prevActiveImage,
            children: prevChildren,
            slideSpeed: prevSlideSpeed
        } = prevProps;
        const {
            activeImage,
            slideSpeed,
            children
        } = this.props;
        const { childrenKeysMap } = this.state;

        if (
            children.length
            && children.length !== prevChildren.length
        ) {
            this.setChildrenKeysMapFromNodes(children);
        }

        if (
            activeImage !== prevActiveImage
            && this.getIsSlider()
        ) {
            // For arrows fix, need to rework slider a bit

            // const activeImgPosIdx = childrenKeysMap.reduce((acc, imgIdx, i) => {
            //     if (imgIdx === activeImage) {
            //         acc = i;
            //     }

            //     return acc;
            // }, 0)

            // const newTranslate = -activeImgPosIdx * this.getSlideWidth();
            const newTranslate = -activeImage * this.getSlideWidth();

            if (!this.isSlidesMovedForIninity) {
                this.setAnimationSpeedStyle(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION));
                this.setTranlateXStyle(newTranslate);
            }

        }

        if (slideSpeed !== prevSlideSpeed && children.length !== 1) {
            this.startCarousel(slideSpeed);
        }
    }


    сomponentWillUnmount() {
        clearInterval(this.carouselInterval);
    }

    setTranlateXStyle(translate) {
        const { isVertical } = this.props;
        CSS.setVariable(this.draggableRef, isVertical ? 'translateY' : 'translateX', `${ translate }px`);
    }

    getImageToShow() {
        const {
            activeImage: childrenKeyIndex,
            children
        } = this.props;
        const { childrenKeysMap } = this.state;

        const activeImage = childrenKeysMap[childrenKeyIndex];
        const newActiveImg = activeImage === children.length - 1
            ? 0
            : activeImage + 1;

        return newActiveImg;
    }

    startCarousel(interval) {
        const { slideSpeed } = this.props;

        if (!slideSpeed) {
            return;
        }

        this.carouselInterval = setInterval(() => {
            const imageToShow = this.getImageToShow();
            this.onSlideAutoChange(imageToShow);
        }, interval);
    };

    clearCarouselInterval() {
        clearInterval(this.carouselInterval);
    }

    resetCarouselInterval() {
        const { slideSpeed } = this.props;

        this.clearCarouselInterval();
        this.startCarousel(slideSpeed);
    }

    async onSlideAutoChange(imageToShow) {
        const {
            onActiveImageChange,
            onActiveBreadCrumbChange,
            children
        } = this.props;
        const {
            childrenKeysMap
        } = this.state;

        if (imageToShow !== 0) {
            this.onCrumbClick(imageToShow);
        } else {
            const isOnLastSlideCurrently = getIsLastSlideCurrently(children, childrenKeysMap),
                lastSlidePositionIndex = getSlidePositionIndexConditionally(childrenKeysMap, children.length - 1),
                firstSlidePositionIndex = getSlidePositionIndexConditionally(childrenKeysMap, 0),
                slideWidth = -this.getSlideWidth();

            if (!isOnLastSlideCurrently) {
                this.setTranlateXStyle(slideWidth * (lastSlidePositionIndex + 1));
                onActiveImageChange(firstSlidePositionIndex);
                onActiveBreadCrumbChange(imageToShow);
            } else {
                if (!this.draggableRef.current) {
                    return;
                }

                const childToSet = getLastRefChild(this.draggableRef, children);

                this.isSlidesMovedForIninity = true;
                this.setAnimationSpeedStyle(0);

                this.draggableRef.current.prepend(childToSet);

                this.setChildrenKeysMap(prependLastArrayChild(childrenKeysMap));
                this.setTranlateXStyle(0);

                setImmediate(() => {
                    this.setAnimationSpeedStyle();
                    this.setTranlateXStyle(slideWidth);
                    this.isSlidesMovedForIninity = false;
                });

                onActiveImageChange(1);
                onActiveBreadCrumbChange(imageToShow);
            }
        }
    }


    setChildrenKeysMapFromNodes = (children) => {
        const childrenKeysMap = [ ...children ].map(({ key }) => Number(key));
        this.setChildrenKeysMap(childrenKeysMap);
    }

    setChildrenKeysMap = (childrenKeysMap) => {
        this.setState({ childrenKeysMap });
    }

    // TODO: REWRITE METHOD FOR INFINNITE SLIDER
    onClick() {
        const { prevActiveImage: prevActiveSlider } = this.state;
        const { onClick } = this.props;

        if (onClick) {
            onClick();

            return -prevActiveSlider;
        }

        const sliderPossition = -prevActiveSlider;

        return sliderPossition;

    }

    calculateNextSlide(state) {
        const { isVertical, children, onActiveImageChange } = this.props;
        const {
            translateX,
            translateY,
            lastTranslateX,
            lastTranslateY
        } = state;

        const lastTranslate = isVertical ? lastTranslateY : lastTranslateX,
            translate = isVertical ? translateY : translateX,
            slideSize = this.getSlideWidth(),
            fullSliderSize = this.getFullSliderWidth(),
            activeSlidePosition = translate / slideSize,
            isSlideBack = translate > lastTranslate;

        if (!translate) {
            return this.onClick();
        }

        if (translate >= 0) {
            onActiveImageChange(0);
            return 0;
        }

        if (translate < -fullSliderSize) {
            const activeSlide = Math.round(fullSliderSize / -slideSize);
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }


        if (isSlideBack) {
            const activeSlide = this.isSlidesMovedForIninity
                ? -(children.length - 2)
                : Math.ceil(activeSlidePosition);

            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        if (!isSlideBack) {
            const activeSlide = this.isSlidesMovedForIninity
                ? -1
                : Math.floor(activeSlidePosition);

                onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);
        onActiveImageChange(-activeSlide);
        return activeSlide;
    }

    handleDragStart({ lastTranslateX }) {
        this.clearCarouselInterval();
        this.getIsDragOnRight = getIsDragOnRight(lastTranslateX);
        this.setAnimationSpeedStyle(0);
    }

    handleDrag(state) {
        const {
            isVertical,
            isDragged,
            setIsDragged
        } = this.props;
        const {
            translateX,
            translateY
        } = state;

        if (!isDragged) {
            setIsDragged(true);
        }

        const translate = isVertical ? translateY : translateX,
            fullSliderSize = this.getFullSliderWidth();

        this.moveSlidesForInfinity(translate);

        if (translate < 0 && translate > -fullSliderSize) {
            this.setTranlateXStyle(translate);
        }
    }

    handleDragEnd(state, callback) {
        const {
            isVertical,
            onActiveBreadCrumbChange,
            setIsDragged,
            slideSpeed
        } = this.props;
        const { childrenKeysMap } = this.state;

        this.startCarousel(slideSpeed);
        setImmediate(() => setIsDragged(false));

        if (this.isSlidesMovedForIninity) {
            this.setAnimationSpeedStyle();
        }

        const activeSlide = this.calculateNextSlide(state),
            slideSize = this.getSlideWidth(),
            newTranslate = activeSlide * slideSize,
            newActiveBreadcrumb = childrenKeysMap[Math.abs(activeSlide)];

        this.isSlidesMovedForIninity = false;
        onActiveBreadCrumbChange(newActiveBreadcrumb);
        this.setTranlateXStyle(newTranslate);

        if (isVertical) {
            callback({
                originalY: newTranslate,
                lastTranslateY: newTranslate
            });

            return;
        }

        callback({
            originalX: newTranslate,
            lastTranslateX: newTranslate
        });
    }

    moveSlidesForInfinity(translateX) {
        const { activeImage, children } = this.props;
        const { childrenKeysMap } = this.state;

        const isDragOnRight = this.getIsDragOnRight(translateX);

        if (isDragOnRight !== this.isDragOnRight) {
            this.isDragOnRight = isDragOnRight;
            this.isSlidesMovedForIninity = false;
        }

        if (
            this.draggableRef.current
            && this.isSlidesMovedForIninity
            || isDragOnRight === null
        ) {
            return;
        }

        const lastSlideCondition = isDragOnRight && activeImage === children.length - 1,
            firstSlideCondition = !isDragOnRight && activeImage === 0;

        if (lastSlideCondition) {
            const childToSet = getLastRefChild(this.draggableRef, children),
            nextActiveImage = 0,
            newChildrenKeysMap = getOrderedChildrenIds(childrenKeysMap, RIGHT),
            refManipulation = () => this.draggableRef.current.prepend(childToSet);

            this.resetSlides(refManipulation, newChildrenKeysMap, nextActiveImage, translateX);
        } else if (firstSlideCondition) {
            const childToSet = getFirstRefChild(this.draggableRef),
            nextActiveImage = children.length - 1,
            newChildrenKeysMap = getOrderedChildrenIds(childrenKeysMap, LEFT),
            refManipulation = () => this.draggableRef.current.appendChild(childToSet);

            this.resetSlides(refManipulation, newChildrenKeysMap, nextActiveImage, translateX);
        }

    }

    resetSlides(refDOMManipulation, childrenKeysMap, nextActiveImage, translateX) {
        const { onActiveImageChange, activeBreadCrumb } = this.props;

        this.setTranlateXStyle(translateX);
        refDOMManipulation();
        this.setChildrenKeysMap(childrenKeysMap);

        const slideSize = this.getSlideWidth(),
            newTranslateAfterDOMChange = getNewTranslateAfterDOMChange(childrenKeysMap, activeBreadCrumb, slideSize);

        this.getIsDragOnRight = getIsDragOnRight(newTranslateAfterDOMChange);
        onActiveImageChange(nextActiveImage);
        this.isSlidesMovedForIninity = true;
    }

    // get slider with slides in initial positions
    getDefaultSliderNode() {
        const { childrenKeysMap } = this.state;

        if (childrenKeysMap[0] === 0 || !this.draggableRef.current) {
            return {};
        }

        const clonnedNode = this.draggableRef.current.cloneNode(true),
            newChildrenKeysMap = [ ...childrenKeysMap ];

        (function appendFirstChildren() {
            const childToSet = clonnedNode.children[0];
                clonnedNode.appendChild(childToSet);

            const firstChildrenKey = newChildrenKeysMap.shift();
                newChildrenKeysMap.push(firstChildrenKey);

            if (newChildrenKeysMap[0] !== 0) {
                appendFirstChildren();
            }
        })();

        return {
            clonnedNode,
            newChildrenKeysMap
        };
    }

    setDefaultSlidesPosition() {
        const {
            clonnedNode,
            newChildrenKeysMap
        } = this.getDefaultSliderNode();

        if (clonnedNode && this.draggableRef.current) {
            this.draggableRef.current.replaceChildren(...clonnedNode.children);
            this.setChildrenKeysMap(newChildrenKeysMap);
        }
    }

    onCrumbClick(key) {
        const {
            onActiveBreadCrumbChange,
            onActiveImageChange,
            activeBreadCrumb,
            children
        } = this.props;

        this.resetCarouselInterval();

        const slideSize = this.getSlideWidth(),
            childrenKeysMap = children.map((_, i) => i),
            prevTranslateAfterDOMChange = getNewTranslateAfterDOMChange(childrenKeysMap, activeBreadCrumb, slideSize),
            newTranslateAfterDOMChange = getNewTranslateAfterDOMChange(childrenKeysMap, key, slideSize);

        this.setAnimationSpeedStyle(0);
        this.setDefaultSlidesPosition();
        this.setTranlateXStyle(prevTranslateAfterDOMChange);

        setImmediate(() => {
            this.setAnimationSpeedStyle();
            this.setTranlateXStyle(newTranslateAfterDOMChange);
        });

        onActiveBreadCrumbChange(key);
        onActiveImageChange(key);
    }

    // Infinite slider arrows behavior, need to improve it.

    // onArrowClick(nextImage) {
    //     const {
    //         children,
    //         activeImage,
    //         onActiveBreadCrumbChange
    //     } = this.props;
    //     const { childrenKeysMap } = this.state;

    //     const isToLastSlide = nextImage === children.length - 1 && activeImage === 0;
    //     const isToFirstSlide = nextImage === 0 && activeImage !== 1;

    //     if (!(isToLastSlide || isToFirstSlide)) {
    //         this.onCrumbClick(nextImage);
    //         this.prevNextImg = null;
    //         return;
    //     }

    //     let childToSet = null,
    //         nextActiveImage = null,
    //         nextActiveBreadCrumb = null,
    //         newChildrenKeysMap = null,
    //         refManipulation = () => null,
    //         translateXBefore = 0,
    //         translateXAfter = null;

    //     const lastIdx = children.length - 1;

    //     if (isToFirstSlide) {
    //         childToSet = getLastRefChild(this.draggableRef, children),
    //         nextActiveImage = 0,
    //         nextActiveBreadCrumb = 0,
    //         newChildrenKeysMap = getOrderedChildrenIds(childrenKeysMap, RIGHT),
    //         refManipulation = () => this.draggableRef.current.prepend(childToSet),
    //         translateXBefore = 0,
    //         translateXAfter = -this.getSlideWidth();
    //     } else if (isToLastSlide) {
    //         childToSet = getFirstRefChild(this.draggableRef),
    //         nextActiveImage = lastIdx,
    //         nextActiveBreadCrumb = lastIdx,
    //         newChildrenKeysMap = getOrderedChildrenIds(childrenKeysMap, LEFT),
    //         refManipulation = () => this.draggableRef.current.appendChild(childToSet),
    //         translateXBefore = -((lastIdx) * this.getSlideWidth()),
    //         translateXAfter = -((lastIdx - 1) * this.getSlideWidth());
    //     }

    //     this.setAnimationSpeedStyle(0);
    //     this.resetSlides(refManipulation, newChildrenKeysMap, nextActiveImage, translateXBefore);
    //     this.setAnimationSpeedStyle();
    //     this.setTranlateXStyle(translateXAfter);
    //     onActiveBreadCrumbChange(nextActiveBreadCrumb);
    //     this.isSlidesMovedForIninity = false;
    // }

    renderCrumb({ key }) {
        const { activeBreadCrumb } = this.props;
        const isActive = Number(key) === activeBreadCrumb;

        return (
            <button
              block="Slider"
              elem="Image"
              mods={ { type: 'single' } }
              onClick={ () => this.onCrumbClick(Number(key)) }
            >
                <div
                  block="Slider"
                  elem="Crumb"
                  mods={ { isActive } }
                />
            </button>
        );
    }

    renderArrowButtons() {
        const { areArrowsRendered } = this.props;

        if (!areArrowsRendered) {
            return null;
        }

        return (
            <div block="Slider"
                 elem="Arrows">
                { this.renderArrowRight() }
                { this.renderArrowLeft() }
            </div>
        )
    }

    renderArrowLeft() {
        const {
            isInfinite,
            children,
            activeBreadCrumb
        } = this.props;

        const nextSlideOnLast = isInfinite ? children.length - 1 : 0;
        const nextActiveImage = activeBreadCrumb === 0 ? nextSlideOnLast : activeBreadCrumb - 1;

        if (children.length === 1) {
            return
        }

        return (
            <div block="Slider-Arrow Slider-Arrow-Left">
                <button
                    block="Slider"
                    elem="Image"
                    mods={{ type: 'single' }}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => this.onCrumbClick(nextActiveImage)}
                >
                    <div
                        block="Slider-Button Slider-Button-Left"
                    />
                </button>
            </div>
        )
    }

    renderArrowRight() {
        const { isInfinite, children, activeBreadCrumb } = this.props;

        const nextSlideOnLast = isInfinite ? 0 : activeBreadCrumb;
        const nextActiveImage = activeBreadCrumb + 1 > children.length - 1  ? nextSlideOnLast : activeBreadCrumb + 1;

        if (children.length === 1) {
            return
        }

        return (
            <div block="Slider-Arrow Slider-Arrow-Right">
                <button
                    block="Slider"
                    elem="Image"
                    mods={ { type: 'single' } }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ () => this.onCrumbClick(nextActiveImage)}
                >
                    <div
                        block="Slider-Button Slider-Button-Right"
                    />
                </button>
            </div>
        )
    }

    render() {
        const {
            showCrumbs,
            mix,
            device: { isMobile }
        } = this.props;

        return (
            <div
                block="Slider"
                mix={ mix }
                ref={ this.getSliderRef() }
            >
                { this.renderSliderContent() }
                { showCrumbs && this.renderCrumbs() }
                { !isMobile && this.renderArrowButtons() }
            </div>
        );
    }
}

export default InfiniteSlider;
