/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { store } from 'Store';

import { TIMESLOT } from 'Component/PickupOverlay/PickupOverlay.config';
import { DEFAULT_LOCATION_CODE } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';
import {
    CartDispatcher as SourceCartDispatcher
} from 'SourceStore/Cart/Cart.dispatcher';
import CartQuery from 'Query/Cart.query';
import CheckoutQuery from 'Query/Checkout.query';
import { DELIVERY_FLOW } from 'Route/Checkout/Checkout.config';
import {
    updateTotals,
    updateCartCategories,
    updateRewardBonusCart,
    updateAppliedRewardBonus,
    updateAppliedTaxesData,
    updateIsTotalsLoading,
    updateIsCartProductsAdding,
    updateIsCartReseting,
    updateChosenTimeslot,
    updateUnremovableChosenTimeslot,
    updateExcludedItems,
    updatePickupMethods,
    updateStoreTimeslots,
    updateUnremovableCartCategories,
    updateIsCartDistributed,
    updateIsAdditionalCartDataLoading,
    updateItemsQty,
    updateFetchingAddProductsSkus,
    updateProductsWithChangedQty,
    clearProductWithChangedQty,
    updateSingleProductData,
    updateSelectedShippingMethod,
    updateDiscountData
} from 'Store/Cart/Cart.action';
import { updateCheckoutFlow } from 'Store/Checkout/Checkout.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn, isSessionOver } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getExtensionAttributes } from 'Util/Product';
import { addProductDebounce } from 'Util/Request';

import { getAuthorizationToken } from 'SourceUtil/Auth/Token';
import {
    fetchMutation,
    fetchQuery
} from 'Util/Request';
import {
    getFirstDefaultCountryRegionId,
    getCheapestShippingPrice
} from 'Util/Cart';
import {
    handleError
} from 'Util/Customer';
import { CLOSEST_STORE } from 'Store/Config/Config.dispatcher';
import { logToLoggly } from 'Util/Loggly';
import { PICKUP_FLOW } from 'Route/Checkout/Checkout.config';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const GUEST_QUOTE_ID = 'guest_quote_id';
export const CUSTOMER_QUOTE_ID = 'customer_quote_id';
export const TIME_SLOT_BUSY = 'TIME_SLOT_BUSY';

export class CartDispatcher extends SourceCartDispatcher {
    creatingCartPromise = null;
    isUpdateInitialCartDataCalled = false;
    changingQtySkus = [];

    setNewInChangingQtySkus(sku) {
        if (!this.changingQtySkus.includes(sku)) {
            this.changingQtySkus.push(sku);
        }
    }

    deleteFromChangingQtySkus(sku) {
        this.changingQtySkus = this.changingQtySkus
            .filter((updatedQtySku) => updatedQtySku !== sku);
    }

    async updateInitialCartData(dispatch, isAddCartItemsRequired = false, products, isCreateEmptyCartRequired = false) {
        const quoteID = await this._getAppropriateQuoteId();

        try {
            if (!quoteID) {
                /**
                 * newQuoteID is undefined if updateInitialCartData
                 * is called again during the new cart creation
                 */
                const newQuoteID = await this.createAndUpdateEmptyCart(dispatch);

                if (!newQuoteID) {
                    /**
                     * will call updateInitialCartData after empty cart creation,
                     * if execution got to this line
                     */
                    this.isUpdateInitialCartDataCalled = true;
                    return;
                }
            }

            await this._syncCartWithBE(dispatch, isAddCartItemsRequired, isCreateEmptyCartRequired);
            await this.addProductsToCart(dispatch, isAddCartItemsRequired, products);
        } catch(e) {
            handleError(e, dispatch);
        }
    }

    runDelayedCartUpdate(dispatch) {
        if (this.isUpdateInitialCartDataCalled) {
            this.updateInitialCartData(dispatch);
            this.isUpdateInitialCartDataCalled = false;
        }
    }

    _createEmptyCart(dispatch) {
        if (this.creatingCartPromise) {
            return this.creatingCartPromise;
        }

        this.creatingCartPromise = fetchMutation(
            CartQuery.getCreateEmptyCartMutation()
        ).then(
            ({ createEmptyCart }) => {
                this.creatingCartPromise = null;
                return Promise.resolve(createEmptyCart);
            },
            (err) => {
                const objToLog = {
                    err,
                    message: 'Error on quote creation',
                    ticket: 'AVR-992'
                };

                if (err?.message) {
                    objToLog.errMsg = err.message;
                }

                this.creatingCartPromise = null;
                logToLoggly(objToLog);
                handleError(err, dispatch, true);
            }
        );

        return this.creatingCartPromise;
    }

    async createGuestEmptyCart(dispatch) {
        try {
            const quoteID = await this._createEmptyCart(dispatch);

            BrowserDatabase.setItem(quoteID, GUEST_QUOTE_ID);
            this.runDelayedCartUpdate(dispatch);

            // making sure we're logged out
            this._updateCartData({}, dispatch);
            MyAccountDispatcher.then(
                ({ default: dispatcher }) => dispatcher.logout(false, dispatch, false)
            );
        } catch(e) {}
    }

    async createAndUpdateEmptyCart(
        dispatch,
        isUpdateGuestQuoteNeeded = false,
        isOnAbandonedOrder = false
    ) {
        try {
            const guestQuoteId = BrowserDatabase.getItem(GUEST_QUOTE_ID);
            const quoteID = await this._createEmptyCart(dispatch);

            // need to keep the timeslot if the order is abandoned
            if (!isOnAbandonedOrder) {
                this.clearTimeslot(dispatch);
            }

            if (isSignedIn()) {
                BrowserDatabase.setItem(quoteID, CUSTOMER_QUOTE_ID);
            }

            if (
                isUpdateGuestQuoteNeeded
                || !isSignedIn() && !guestQuoteId
            ) {
                BrowserDatabase.setItem(quoteID, GUEST_QUOTE_ID);
            }

            this.runDelayedCartUpdate(dispatch);
            return quoteID;
        } catch(e) {}
    }

    async addProductsToCart(dispatch, isAddCartItemsRequired, products) {
        if (!isAddCartItemsRequired || !products) {
            return;
        }

        try {
            const quoteID = await this._getAppropriateQuoteId();

            dispatch(updateIsCartProductsAdding(true));
            // add product to cart, if order is placed but customer left the checkout without payment
            await this.validateCartBeforeAction();
            await fetchMutation(CartQuery.getAddProductsToCartMutation(products, quoteID));
            await this._syncCartWithBE(dispatch);
            dispatch(updateIsCartProductsAdding(false));
        } catch (e) {
            console.error(e);
        }
    }

    getSyncCartQuery(quoteID) {
        if (isSignedIn()) {
            return [CartQuery.getCartQuery(quoteID), CartQuery._getCustomerCartId()];
        } else {
            return [CartQuery.getCartQuery(quoteID)];
        }
    }

    async checkIfPickupSlotEmpty() {
        const quoteID = await this._getAppropriateQuoteId();

        try {
            const { checkIfPickupSlotEmpty } = await fetchMutation(CartQuery.getCheckIfPickupSlotEmptyQuery(quoteID));
            return checkIfPickupSlotEmpty;
        } catch(err) {
            handleError(err);
        }
    }

    async saveComment(dispatch, comment) {
        try {
            const quoteID = await this._getAppropriateQuoteId();
            await fetchMutation(
                CartQuery.getSaveCommentMutation(
                    comment,
                    quoteID
                )
            );
        } catch(err) {
            handleError(err, dispatch, true);
        }
    }

    async getStoreTimeSlots(dispatch, storeCode) {
        try {
            const {
                getDeliveryTimeSlots
            } = await fetchQuery(CartQuery.getDeliveryTimeSlots(storeCode));
            dispatch(updateStoreTimeslots(getDeliveryTimeSlots));

            return true;
        } catch (err) {
            // TODO: handle exceptions
            return false;
        }
    }

    async setDeliveryTimeslotToCart(dispatch, slotData, isDeliveryFlow = false) {
        const {
            day,
            ...validSlotData
        } = slotData || {};
        const quoteID = await this._getAppropriateQuoteId();

        try {
            const {
                date,
                slot_id,
                store_code,
                time_value
            } = validSlotData;

            const timeslot = {
                date,
                slot_id,
                store_code,
                time: time_value
            };


            if (isDeliveryFlow) {
                await fetchMutation(CartQuery.setDeliveryTimeslotToCart(quoteID, null));
                BrowserDatabase.setItem(null, TIMESLOT);
                dispatch(updateChosenTimeslot({}));
                dispatch(updateUnremovableChosenTimeslot({}));
                return true
            }

            const {
                setDeliverySlotToCart
            } = await fetchMutation(CartQuery.setDeliveryTimeslotToCart(quoteID, timeslot));

            if (setDeliverySlotToCart) {
                BrowserDatabase.setItem({
                    ...timeslot,
                    time: slotData.time,
                    day
                },
                TIMESLOT);

                dispatch(updateChosenTimeslot(slotData));
                dispatch(updateUnremovableChosenTimeslot(slotData));
                const pickupMethods = await this.updatePickupMethods(dispatch);

                return pickupMethods;
            }

            return true;
        } catch (err) {
            const {
                code
            } = err[0];

            logToLoggly({
                err,
                message: 'Error while setting the delivery timeslot',
                ticket: 'AVR-910',
                quoteID,
                slotData,
                isDeliveryFlow
            });

            if (code === TIME_SLOT_BUSY) {
                dispatch(showNotification('error', __('Emplacement sélectionné plein ou non disponible, veuillez sélectionner un autre emplacement disponible.')));
            } else {
                dispatch(showNotification('error', err[0].message));
            }

            return false;
        }
    }

    // isPickupConfirm is needed for the Loggly logger to catch a bug AVR-910
    async distributeCart(dispatch, storeCode, isPickupConfirm = false) {
        const quoteID = await this._getAppropriateQuoteId();

        try {
            const distributedCart = await fetchMutation(CartQuery.getDistributeCartMutation(storeCode, quoteID));
            const excluded_items = distributedCart?.distributeCart?.excluded_items || [];

            const {
                cartData: {
                    items_qty
                } = {}
            } = await this._syncCartWithBE(dispatch);

            dispatch(updateExcludedItems(excluded_items));
            dispatch(updateIsCartDistributed(true));

            return {
                excluded_items,
                items_qty
            };
        } catch (err) {
            const objToLog = {
                err,
                message: 'Error from cart distribution',
                storeCode,
                quoteID,
                ticket: 'AVR-910',
                isPickupConfirm,
                customerQuote: this._getCustomerQuoteId() || 'null',
                guestQuote: this._getGuestQuoteId() || 'null',
                authToken: getAuthorizationToken() || 'null'
            };

            if (err?.message) {
                objToLog.errMsg = err.message;
            }

            logToLoggly({
                ...objToLog,
                objJSON: JSON.stringify(objToLog)
            });

            handleError(err, dispatch, true);
            return false;
        }
    }

    async resetCartOnReload(dispatch, isAddCartItemsRequired) {
        try {
            await this.createAndUpdateEmptyCart(dispatch, true, isAddCartItemsRequired);
            this._updateCartData({}, dispatch);
            dispatch(updateIsCartReseting(false));
        } catch (err) {
            dispatch(updateIsCartReseting(false));
            handleError(err);
        }
    }

    async _syncCartWithBE(
        dispatch,
        isAddCartItemsRequired,
        isCreateEmptyCartRequired,
        addingProductSku = false
    ) {
        dispatch(updateIsTotalsLoading(true));
        // Need to get current cart from BE, update cart
        try {
            if (isAddCartItemsRequired || isCreateEmptyCartRequired) {
                const {
                    CartReducer: {
                        chosenTimeslot,
                        chosenTimeslot: {
                            store_code
                        }
                    },
                    CheckoutReducer: {
                        checkoutFlow
                    }
                } = store.getState();

                await this.resetCartOnReload(dispatch, isAddCartItemsRequired);

                if (Object.keys(chosenTimeslot).length && checkoutFlow === PICKUP_FLOW) {
                    const pickupMethods = await this.setDeliveryTimeslotToCart(dispatch, chosenTimeslot);

                    if (!pickupMethods) {
                        await getStoreTimeSlots(store_code);
                    }
                }

                dispatch(updateUnremovableChosenTimeslot({}));
            }

            const quoteID = await this._getAppropriateQuoteId();
            const result = await fetchQuery(this.getSyncCartQuery(quoteID));

            this.handle_syncCartWithBESuccess(dispatch, result);
            this.getCartAdditionalData(dispatch, result);

            dispatch(updateIsTotalsLoading(false));

            if (addingProductSku) {
                dispatch(updateFetchingAddProductsSkus(sku));
            }

            return result;
        } catch (error) {
            await this.handle_syncCartWithBEError(dispatch, error)
        }
    }

    async validateCart() {
        const quoteID = await this._getAppropriateQuoteId();

        try {
            return await fetchQuery(CartQuery.validateCart(quoteID));
        } catch (e) {
            console.error(e);
        }
    }

    async renewCart() {
        const quoteID = await this._getAppropriateQuoteId();

        try {
            await fetchMutation(CartQuery.renewCart(quoteID));
        } catch (e) {
            console.error(e);
        }
    }

    async validateCartBeforeAction() {
        const isCartActive = await this.validateCart();

        if (!isCartActive) {
            await this.renewCart();
        }
    }

    handle_syncCartWithBESuccess(dispatch, {
        cartData,
        customerCart
    }) {
        const id = customerCart ? customerCart.id : null;

        this._updateCartData(cartData, dispatch, undefined, id);
    }

    async handle_syncCartWithBEError(dispatch) {
        try {
            await this.createAndUpdateEmptyCart(dispatch, true);
            this._updateCartData({}, dispatch);

            if (isSignedIn()) {
                this.getCartAdditionalData(dispatch, cartData);
            }
            dispatch(updateIsTotalsLoading(false));
        } catch (err) {
            dispatch(updateIsTotalsLoading(false));
            console.error(err);
        }
    }

    clearTimeslot(dispatch) {
        const { pickup_location_code } = BrowserDatabase.getItem(CLOSEST_STORE) || {};

        const checkoutFlow = pickup_location_code === DEFAULT_LOCATION_CODE
            ? DELIVERY_FLOW
            : '';

        BrowserDatabase.deleteItem(TIMESLOT);
        dispatch(updateChosenTimeslot({}));
        dispatch(updateCheckoutFlow(checkoutFlow));
    }

    _updateCartData(
        cartData,
        dispatch,
        categories = [],
        customerCartId
    ) {
        /**
         * Not updating the cart data if the product qty in cart is currently changing.
         * It will be updated after the qty is changed.
         */
        if (this.changingQtySkus.length) {
            return;
        }

        dispatch(updateTotals(cartData));

        const shouldCategoriesBeUpdated = categories.length ||
            !Object.keys(cartData).length ||
            !cartData.items.length;

        if (shouldCategoriesBeUpdated) {
            dispatch(updateCartCategories(categories));

            // only setting, but not removing unremovable categories
            if (Array.isArray(categories) && categories.length) {
                dispatch(updateUnremovableCartCategories(categories, { cartData }));
            }
        }

        if (customerCartId) {
            BrowserDatabase.setItem(customerCartId, CUSTOMER_QUOTE_ID);
        }

        const {
            items = []
        } = cartData;

        if (items.length > 0) {
            const product_links = items.reduce((links, product) => {
                const {
                    product: {
                        product_links,
                        variants = []
                    },
                    sku: variantSku
                } = product;

                const {
                    product_links: childProductLinks
                } = variants.find(({
                    sku
                }) => sku === variantSku) || {};

                if (childProductLinks) {
                    Object.values(childProductLinks).filter(({
                            link_type
                        }) => link_type === 'crosssell')
                        .map((item) => links.push(item));
                }

                if (product_links) {
                    Object.values(product_links).filter(({
                            link_type
                        }) => link_type === 'crosssell')
                        .map((item) => links.push(item));
                }

                return links;
            }, []);

            if (product_links.length !== 0) {
                LinkedProductsDispatcher.then(
                    ({
                        default: dispatcher
                    }) => dispatcher.handleData(dispatch, product_links)
                );
            }
        }
    }

    async updatePickupMethods(dispatch) {
        const quoteID = await this._getAppropriateQuoteId();

        if (!quoteID) {
            return null;
        }

        try {
            const { getPickupMethods } = await fetchQuery(CartQuery.getPickupMethods(quoteID));

            dispatch(updatePickupMethods(getPickupMethods));
            return getPickupMethods;
        } catch(e) {
            handleError(e, dispatch);
        }
    }

    async getCartAdditionalData(dispatch, cartData) {
        const quoteID = await this._getAppropriateQuoteId();

        const query = isSignedIn() ?
            [CartQuery._getCartAdditionalDataForItems(quoteID), CartQuery._getCustomerRewardCart()] :
            [CartQuery._getCartAdditionalDataForItems(quoteID)];

        dispatch(updateIsAdditionalCartDataLoading(true));

        return fetchQuery(query).then(({
            cart: {
                applied_reward_points,
                items: categories,
                prices: {
                    applied_taxes,
                    discounts
                }
            },
            customer: {
                reward_points: {
                    balance
                } = {}
            } = {}
        }) => {
            dispatch(updateCartCategories(categories));
            dispatch(updateAppliedTaxesData(applied_taxes));
            dispatch(updateAppliedRewardBonus(applied_reward_points || {}));
            dispatch(updateRewardBonusCart(balance || {}));
            dispatch(updateIsAdditionalCartDataLoading(false));

            // Clean discounts before updating
            dispatch(updateDiscountData(0, ''));
            discounts?.forEach(discount => {
                const { amount: { value }, label } = discount;
                dispatch(updateDiscountData(value, label));
            });

            // only setting, but not removing unremovable categories
            if (Array.isArray(categories) && categories.length) {
                dispatch(updateUnremovableCartCategories(categories, cartData));
            }
        },
        (e) => {
            dispatch(updateIsAdditionalCartDataLoading(false));
        });
    }

    async getCartTaxes(dispatch) {
        const quoteID = await this._getAppropriateQuoteId();
        fetchQuery(CartQuery._getCartAdditionalDataForItems(quoteID)).then(({
                cart: {
                    prices: {
                        applied_taxes,
                        discounts
                    }
                }
            }) => {
                dispatch(updateAppliedTaxesData(applied_taxes));
                // Clean discounts before updating
                dispatch(updateDiscountData(0, ''));
                discounts?.forEach(discount => {
                    const { amount: { value }, label } = discount;
                    dispatch(updateDiscountData(value, label));
                });
            },
            (err) => console.error(err)
        );
    }

    async applyCouponToCart(dispatch, couponCode, isBonusApplied) {
        const guestQuoteId = this._getGuestQuoteId();

        if (!guestQuoteId && !getAuthorizationToken()) {
            await this.createGuestEmptyCart(dispatch);
        }

        try {
            const {
                applyCoupon: {
                    cartData
                }
            } = await fetchMutation(CartQuery.getApplyCouponMutation(
                couponCode, !isSignedIn() && this._getGuestQuoteId()
            ));

            if (isBonusApplied) {
                // updates applied DataCandy bonus
                this.applyRewardBonus(dispatch);
            }

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was applied!')));
        } catch (error) {
            dispatch(showNotification('error', error[0].message));
        }
    }

    async removeCouponFromCart(dispatch, isBonusApplied) {
        try {
            const {
                removeCoupon: {
                    cartData
                }
            } = await fetchMutation(CartQuery.getRemoveCouponMutation(
                !isSignedIn() && this._getGuestQuoteId()
            ));

            if (isBonusApplied) {
                // updates applied DataCandy bonus
                this.applyRewardBonus(dispatch);
            }

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was removed!')));

        } catch (error) {
            dispatch(showNotification('error', error[0].message));
        }
    }

    applyRewardBonus(dispatch) {
        return fetchMutation(CheckoutQuery.getApplyRewardPointsMutation(this._getCustomerQuoteId()))
            .then(({
                applyRewardPointsToCart: {
                    cart: {
                        applied_reward_points
                    }
                }
            }) => {
                this._syncCartWithBE(dispatch);
                dispatch(updateAppliedRewardBonus(applied_reward_points));
            });
    }

    removeRewardBonus(dispatch) {
        return fetchMutation(CheckoutQuery.getRemoveRewardPointsMutation(this._getCustomerQuoteId()))
            .then(({
                removeRewardPointsFromCart: {
                    cart: {
                        applied_reward_points
                    }
                }
            }) => {
                this._syncCartWithBE(dispatch);
                dispatch(updateAppliedRewardBonus(applied_reward_points || {}));
            });;
    }

    _getSelectedShippingMethod(shippingMethods) {
        const state = store.getState();
        const { selectedShippingMethod } = state.CartReducer;

        const isPreferSelected = selectedShippingMethod
            && Object.keys(selectedShippingMethod)
            && shippingMethods.length
            && shippingMethods.some(
                ({ method_code }) => method_code === selectedShippingMethod.method_code);

        return isPreferSelected
            ? shippingMethods.filter(({ method_code }) => method_code === selectedShippingMethod.method_code)[0]
            : shippingMethods[0] || {};
    }

    setEstimateShippingQueryToArray(target) {
        const state = store.getState();
        const { estimateShippingFields } = state.CheckoutReducer;
        const { cartTotals: { items = [] } = {} } = state.CartReducer;

        if (
            !Object.keys(estimateShippingFields).length ||
            !items.length
        ) {
            return;
        }

        target.push(CheckoutQuery.getEstimateShippingCosts(
            estimateShippingFields,
            this._getGuestQuoteId()
        ));
    }

    updateSelectedShippingMethod(dispatch, estimateShippingCosts) {
        if (!estimateShippingCosts) {
            return;
        }

        const method = this._getSelectedShippingMethod(estimateShippingCosts);
        dispatch(updateSelectedShippingMethod(method));
    }

    async removeProductFromCart(dispatch, item_id, sku) {
        const quoteID = await this._getAppropriateQuoteId();
        const mutations = [CartQuery.getRemoveCartItemMutation(item_id, quoteID)];

        this.setEstimateShippingQueryToArray(mutations);

        try {
            this.changeItemQtyDebounced.resetCall(sku);
            await this.validateCartBeforeAction();
            const result = await fetchMutation(mutations);

            const {
                removeCartItem: {
                    cart: {
                        items: categories,
                        prices: {
                            applied_taxes,
                            discounts
                        }
                    },
                    cartData
                },
                estimateShippingCosts
            } = result;

            this.updateSelectedShippingMethod(dispatch, estimateShippingCosts);
            dispatch(clearProductWithChangedQty(sku));
            dispatch(updateAppliedTaxesData(applied_taxes));
            dispatch(updateSingleProductData(sku, cartData));
            // Clean discounts before updating
            dispatch(updateDiscountData(0, ''));
            discounts?.forEach(discount => {
                const { amount: { value }, label } = discount;
                dispatch(updateDiscountData(value, label));
            });

            this.deleteFromChangingQtySkus(sku);
            this._updateCartData(
                cartData,
                dispatch,
                categories,
                null
            );
        } catch (error) {
            this.deleteFromChangingQtySkus(sku);
            dispatch(showNotification('error', error[0].message))
        }
    }

    changeItemQtyDebounced = addProductDebounce(this._changeItemQty.bind(this), 1000);

    async changeItemQty(dispatch, options, tries = 0) {
        const {
            CartReducer: {
                cartTotals: {
                    items = []
                } = {}
            }
        } = store.getState();
        const { sku, quantity } = options;

        const currentQty = items.filter(({ sku: skuFromRedux }) => sku === skuFromRedux)[0]?.qty;

        this.setNewInChangingQtySkus(sku);

        dispatch(updateProductsWithChangedQty(sku));
        dispatch(updateItemsQty(sku, quantity));
        await this.changeItemQtyDebounced.debouncedFn(dispatch, options, tries, currentQty);
    }

    async _changeItemQty(dispatch, options, tries, currentQty) {
        const {
            item_id,
            quantity,
            sku
        } = options;
        const {
            pickup_location_code = 'default'
        } = BrowserDatabase.getItem(CLOSEST_STORE) || {}

        // return if qty is not changed
        if (currentQty === quantity) {
            return;
        }

        dispatch(updateFetchingAddProductsSkus(sku));
        const getMutations = (quoteID) => ([
            CartQuery.getSaveCartItemMutation({
                sku,
                item_id,
                quantity
            }, quoteID, pickup_location_code)
        ]);

        if (tries > 2) {
            dispatch(showNotification('error', __('Internal server error. Can not add to cart.')));
            return Promise.reject();
        }
        try {
            const quoteID = await this._getAppropriateQuoteId();

            const mutations = getMutations(quoteID);
            this.setEstimateShippingQueryToArray(mutations);

            await this.validateCartBeforeAction();
            const result = await fetchMutation(mutations);
            const {
                saveCartItem: {
                    cartData,
                    cart: {
                        items: categories,
                        prices: {
                            discounts
                        }
                    }
                },
                estimateShippingCosts
            } = result;

            this.updateSelectedShippingMethod(dispatch, estimateShippingCosts);
            this.deleteFromChangingQtySkus(sku);

            dispatch(clearProductWithChangedQty(sku));
            dispatch(updateFetchingAddProductsSkus(sku));

            // Clean discounts before updating
            dispatch(updateDiscountData(0, ''));
                discounts?.forEach(discount => {
                    const { amount: { value }, label } = discount;
                    dispatch(updateDiscountData(value, label));
                });

            // updating a cart only if all qty changes are accomplished
            if (this.changingQtySkus.length !== 0) {
                return;
            }

            this._updateCartData(
                cartData,
                dispatch,
                categories,
                null
            );
        } catch (error) {
            const [{
                debugMessage = ''
            }] = error || [{}];

            this.deleteFromChangingQtySkus(sku);
            if (debugMessage.match('No such entity with cartId ')) {
                return this._createEmptyCart(dispatch).then(
                    /** @namespace Store/Cart/Dispatcher/changeItemQtyFetchMutationCatch_createEmptyCartThen */
                    (data) => {
                        if (isSignedIn()) {
                            BrowserDatabase.setItem(data, CUSTOMER_QUOTE_ID);
                        }

                        BrowserDatabase.setItem(data, GUEST_QUOTE_ID);
                        this._updateCartData({}, dispatch);
                        return this.changeItemQty(dispatch, options, tries + 1);
                    }
                );
            } else {
                dispatch(updateFetchingAddProductsSkus(sku));
                dispatch(updateProductsWithChangedQty(sku, true));
            }

            dispatch(showNotification('error', error[0].message));
            return Promise.reject();
        }
    }

    async addProductToCart(dispatch, options) {
        const guestQuoteId = this._getGuestQuoteId();
        const {
            product,
            quantity,
            productOptionsData
        } = options;

        const {
            sku,
            type_id: product_type
        } = product;

        const {
            productOptions,
            productOptionsMulti
        } = productOptionsData || {};

        const productToAdd = {
            sku,
            product_type,
            quantity,
            product_option: {
                extension_attributes: getExtensionAttributes({
                    ...product,
                    productOptions,
                    productOptionsMulti
                })
            }
        };

        if (!guestQuoteId && !getAuthorizationToken()) {
            await this.createGuestEmptyCart(dispatch);
        }

        if (this._canBeAdded(options)) {
            try {
                const {
                    pickup_location_code = 'default'
                } = BrowserDatabase.getItem(CLOSEST_STORE) || {};
                const quoteID = await this._getAppropriateQuoteId();

                const mutations = [
                    CartQuery.getSaveCartItemMutation(
                        productToAdd,
                        quoteID,
                        pickup_location_code
                    )
                ];

                this.setEstimateShippingQueryToArray(mutations);

                const {
                    estimateShippingCosts,
                    saveCartItem: {
                        cartData,
                        cart: {
                            items: categories,
                            prices: {
                                applied_taxes,
                                discounts
                            }
                        }
                    }
                } = await fetchMutation(mutations);

                this.updateSelectedShippingMethod(dispatch, estimateShippingCosts);

                if (!categories) {
                    logToLoggly({
                        message: 'No categories are received by adding a product to the cart',
                        cartData,
                        quoteID,
                        ticket: 'AVR-910'
                    });
                }

                dispatch(updateAppliedTaxesData(applied_taxes));
                dispatch(updateSingleProductData(sku, cartData));
                dispatch(updateDiscountData(0, ''));
                discounts?.forEach(discount => {
                    const { amount: { value }, label } = discount;
                    dispatch(updateDiscountData(value, label));
                });

                return this._updateCartData(
                    cartData,
                    dispatch,
                    categories,
                    null
                );
            } catch ([{
                message
            }]) {
                dispatch(showNotification('error', message));
                return Promise.reject();
            }
        }

        return Promise.reject();
    }

    async _getAppropriateQuoteId() {
        const _isSignedIn = !!getAuthorizationToken();

        if (
            isSessionOver()
            || !_isSignedIn && !this._getGuestQuoteId()
        ) {
            await this.createGuestEmptyCart(store.dispatch);
        }

        if (!_isSignedIn) {
            return this._getGuestQuoteId();
        }

        return this._getCustomerQuoteId();
    }

    _getCustomerQuoteId() {
        return BrowserDatabase.getItem(CUSTOMER_QUOTE_ID);
    }

    async applyCustomizableOptions(dispatch, options, status, timesCalled = 3) {
        try {
            const quoteID = await this._getAppropriateQuoteId();
            await fetchMutation([
                CartQuery.setCallMeStatus(quoteID, status),
                CartQuery.getApplyCustomizableOptionsFields(quoteID, options)
            ]);

            return true;
        } catch (err) {
            // retry if promise rejects
            if (timesCalled !== 1) {
                setTimeout(() => this.applyCustomizableOptions(dispatch, options, status, timesCalled - 1), 1000);
            }

            return false;
        }
    }
}

export default new CartDispatcher();