import {
    NoMatch as SourceNoMatch,
} from 'SourceRoute/NoMatch/NoMatch.component';
import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import { ReactComponent as NoRouteImg } from './images/404.svg';

import './NoMatch.extend.style'

export class NoMatch extends SourceNoMatch {
    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                    mix={ { block: 'NoMatch' } }
                    wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                    label={ __('Page Not Found Content') }
                >
                    <div
                        block="NoMatch"
                        elem="Image"
                    >
                        <NoRouteImg />
                    </div>
                    <p block="NoMatch" elem="Subtitle">
                        { __('The page you are looking for cannot be found ...') }
                    </p>
                    <p block="NoMatch" elem="ContentText">
                        { /* eslint-disable-next-line max-len */ }
                        { __('Use the search bar at the top of the page to find your product\n' +
                            'or follow one of the links below:') }
                    </p>
                    <Link
                        to="/"
                        block="NoMatch"
                        elem="Button"
                        mix={ { block: 'Button' } }
                    >
                        { __('Home') }
                    </Link>
                </ContentWrapper>
            </main>
        );
    }
};

export default NoMatch;
