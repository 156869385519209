import PropTypes from 'prop-types';

import Loader from 'SourceComponent/Loader';
import { ProductType } from 'SourceType/ProductList';
import { isSignedIn } from 'SourceUtil/Auth';
import  {ProductWishlistButton as SourceProductWishlistButton} from "SourceComponent/ProductWishlistButton/ProductWishlistButton.component"

import './ProductWishlistButton.override.style';

import { ReactComponent as FavListIcon } from './icons/listfav.svg';

export class ProductWishlistButton extends SourceProductWishlistButton {
    static propTypes = {
        isReady: PropTypes.bool,
        isLoading: PropTypes.bool,
        isLoadingLocal: PropTypes.bool,
        quantity: PropTypes.number,
        isDisabled: PropTypes.bool,
        isInWishlist: PropTypes.bool,
        product: ProductType.isRequired,
        addToWishlist: PropTypes.func.isRequired,
        removeFromWishlist: PropTypes.func.isRequired,
        mix: PropTypes.shape({ block: PropTypes.string, elem: PropTypes.string, mod: PropTypes.string })
    };

    static defaultProps = {
        mix: {},
        quantity: 1,
        isReady: true,
        isLoading: false,
        isLoadingLocal: false,
        isDisabled: false,
        isInWishlist: false
    };

    getTitle = () => {
        const { isInWishlist, isReady } = this.props;

        if (!isSignedIn()) {
            return __('Please sign in first!');
        }

        if (!isReady) {
            return __('Please select variant first!');
        }

        if (isInWishlist) {
            return __('Remove from Wishlist');
        }

        return __('Add to Wishlist');
    };

    onClick = (e) => {
        const {
            product,
            quantity,
            isInWishlist,
            addToWishlist,
        } = this.props;

        e.preventDefault();
        e.stopPropagation();

        if (!isInWishlist) {
            return addToWishlist(product, quantity);
        }
    };

    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;
        if(mix.block === "ProductCard"){
                return (
                    <button
                    block="ButtonProductCard"
                    elem="Button"
                    mods={ { isInWishlist, isDisabled } }
                    mix={ { block: 'Button', mods: { isHollow: !isInWishlist }, mix } }
                    title={ this.getTitle() }
                    onClick={ this.onClick }
                    >
                     <FavListIcon />
                    </button>
                );
        }

          return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mods: { isHollow: !isInWishlist }, mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <FavListIcon />
               {__("Add to my list")}
            </button>
        );
    }

    renderLoader() {
        const { isLoadingLocal } = this.props;

        return (
            <Loader isLoading={ isLoadingLocal } />
        );
    }

    renderContent() {
        return (
            <>
                { this.renderLoader() }
                <div block="ProductWishlistButton">
                    { this.renderButton() }
                </div>
            </>
        );
    }

    render() {
        const { product: { id } = {} } = this.props;

        if (id !== -1) {
            return this.renderContent();
        }

        return null;
    }
}

export default ProductWishlistButton;
