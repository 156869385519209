/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_ACTITO_FORM,
    UPDATE_ACTITO_POPUP_DESCRIPTION,
    UPDATE_ACTITO_INTERACTION_ID,
    UPDATE_IS_ACTITO_FORM_LOADING,
    UPDATE_ACTITO_PREFILLED_EMAIL,
} from "./Actito.action";

/** @namespace Store/Blog/Reducer/getInitialState */
export const getInitialState = () => {
    return {
        prefilledEmail: '',
        actitoForm: {},
        actitoPopupDescription: {},
        interactionId: null,
        isActitoFormLoading: false
    };
};

export const ActitoReducer = (state = getInitialState(), action) => {
    const {
        type,
        prefilledEmail,
        actitoForm,
        actitoPopupDescription,
        interactionId,
        isActitoFormLoading
    } = action;

    switch (type) {
        case UPDATE_ACTITO_FORM:
            return {
                ...state,
                actitoForm,
            };

        case UPDATE_ACTITO_PREFILLED_EMAIL:
            return {
                ...state,
                prefilledEmail,
            };

        case UPDATE_ACTITO_POPUP_DESCRIPTION:
            return {
                ...state,
                actitoPopupDescription,
            };

        case UPDATE_ACTITO_INTERACTION_ID:
            return {
                ...state,
                interactionId,
            };

        case UPDATE_IS_ACTITO_FORM_LOADING:
            return {
                ...state,
                isActitoFormLoading,
            };

        default:
            return state;
    }
};

export default ActitoReducer;
