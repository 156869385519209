/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { US_ID, CANADA_ID } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';

/**
 * Updates telephone in state if validated.
 * Has to be bound to the component.
 * @namespace Util/Checkout/validateTelephone
 */
export function validateTelephone(input) {
  console.log('validateTelephone', input.length);
  // for empty string input
  if (!input) {
    this.setState({ telephone: input });
  }

  const regex = /^[0-9-]?[0-9]+$/g;
  const isValid = input.match(regex) && input.length < 12;

  if (isValid) {
    this.setState({ telephone: input });
  }
}

/**
 * On postcode field change.
 * Has to be bound to the component
 * @namespace Util/Checkout/onPostcodeChange
 */

export function onPostcodeChange(countryId, postcode) {
  const cleanPostcode = postcode.replaceAll('_', '').replaceAll(/-$/g, '');

  this.setState({
    postcode: cleanPostcode,
    postcodeMask: getPostcodeMask(postcode, countryId),
  });
}

/**
 * Gets appropriate postcode mask.
 * @namespace Util/Checkout/getPostcodeMask
 */
export const getPostcodeMask = (input, countryId) => {
  if (countryId === CANADA_ID) {
    return "W1W 1W1";
  } else if (countryId === US_ID) {
    if (input) {
      const lastIndex = _getLastDigitIndex(input);

      if (lastIndex > 3) {
        return "11111-1111";
      }
    }

    return "11111";
  }
};

/**
 * Gets an index of the last digit in a string.
 * @namespace Util/Checkout/_getLastDigitIndex
 */
export const _getLastDigitIndex = (input) => {
  if (!input) {
    return 0;
  }

  return input.split('').reduce((acc, sym, i) => {
    if (!isNaN(Number(sym))) {
      acc = i;
    }

    return acc;
  },
  0);
};

/**
 * Validates the input birthday.
 * @param {string} input - The input birthday in the format 'YYYY-MM-DD'.
 * @returns {string|null} - Returns an error message if the birthday is invalid, otherwise returns null.
 */
export const validateBirthday = (input) => {
  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());
  const maxDate = new Date(currentDate.getFullYear() - 14, currentDate.getMonth(), currentDate.getDate() - 1);

  const birthday = new Date(input);

  if (isNaN(birthday.getTime())) {
      return 'Por favor, insira uma data válida.';
  }

  if (birthday < minDate || birthday > maxDate) {
      return `A data de nascimento deve estar entre ${minDate.toLocaleDateString()} e ${maxDate.toLocaleDateString()}.`;
  }

  // Data de nascimento válida
  return null;
};