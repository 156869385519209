import {Breadcrumb as SourceBreadcrumb} from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.override.style';

export class Breadcrumb extends SourceBreadcrumb {

     state = {isLast:false,isPenultimate:false}

     componentDidMount(){
        this.designatePosition()
     }

     componentDidUpdate(){
        this.designatePosition()
     }

     designatePosition(){
        const { index,breadcrumbsLength } = this.props;
        let isLast = breadcrumbsLength - 1 === index;
        let isPenultimate = breadcrumbsLength - 2 === index;

        this.setState({isLast,isPenultimate})
     }

    render() {
        const { index, mix, isActive = false } = this.props;
        const { isLast,isPenultimate } = this.state;

        return (
            <li
              block="Breadcrumb"
              mods={ isPenultimate ? { isPenultimate, isActive } : isLast ? { isLast, isActive }: "" }
              mix={ mix }
              key={ index }
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
                { this.renderLink() }
            </li>
        );
    }
}

export default Breadcrumb;
