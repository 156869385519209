/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ProductListQuery from 'Query/ProductList.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';

/** @namespace AvrilPwa-App/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    getSaveCommentMutation(comment, quoteId) {
        return new Field('saveComment')
            .addArgument('cart_id', 'String!', quoteId)
            .addArgument('comment', 'String', comment)
            .addField('id');
    }

    _getProductField() {
        ProductListQuery.options.isForLinkedProducts = true;
        ProductListQuery.options.isSingleProduct = true;

        const productQuery = new Field('product')
            .addFieldList(ProductListQuery._getProductInterfaceFields(false, true));

        ProductListQuery.options.isForLinkedProducts = false;
        ProductListQuery.options.isSingleProduct = false;

        return productQuery;
    }

    getCheckIfPickupSlotEmptyQuery(quoteId) {
        const query = new Field('checkIfPickupSlotEmpty')
            .addArgument('cart_id', 'String!', quoteId);

        return query;
    }

    getSaveCartItemMutation(product, quoteId, storeCode = "default") {
        const mutation = new Field('saveCartItem')
            .addArgument('cartItem', 'CartItemInput!', product)
            .addFieldList(this._getSaveCartItemFields(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        mutation.addArgument('storeCode', 'String', storeCode);

        return mutation;
    }

    getDistributeCartMutation(storeCode = "default", quoteId) {
        return new Field('distributeCart')
            .addArgument('cart_id', 'String!', quoteId)
            .addArgument('store_code', 'String!', storeCode)
            .addFieldList([
                this._getCartItemsField(),
                this._getExcludedItemsField()
            ]);
    }

    getPickupMethods(quoteId) {
        return new Field('getPickupMethods')
            .addArgument('cart_id', 'String!', quoteId)
            .addFieldList([
                'amount',
                'available',
                'base_amount',
                'method_code',
                'carrier_code',
                'method_title',
                'carrier_title',
                'error_message',
                'price_excl_tax',
                'price_incl_tax'
            ]);
    }

    _getExcludedItemsField() {
        return new Field('excluded_items')
            .addFieldList([
                'reason',
                'available_quantity',
                ProductListQuery._getProductField()
            ])
    }

    getAddProductsToCartMutation(products, quoteId) {
        const mutation = new Field('addProductsToCart')
            .addArgument('cartItems', '[CartItemInput!]!', products)
            .addArgument('cartId', 'String!', quoteId)
            .addField(this._getCartAdditionalDataForItems());

        return mutation;
    }

    getDeliveryTimeSlots(storeCode = "default") {
        return new Field('getDeliveryTimeSlots')
            .addArgument('store_code', 'String!', storeCode)
            .addFieldList([
                'date',
                'day',
                this._getTimeSlotsFields()
            ]);
    }

    setDeliveryTimeslotToCart(cartID, slotData) {
        return new Field('setDeliverySlotToCart')
            .addArgument('cart_id', 'String!', cartID)
            .addArgument('slot_data', 'SlotData', slotData);
    }

    validateCart(cartID) {
        return new Field('validateCart')
            .addArgument('cart_id', 'String!', cartID);
    }

    renewCart(quoteID) {
        return new Field('renewCart')
            .addArgument('old_cart_id', 'String!', quoteID);
    }

    _getTimeSlotsFields() {
        return new Field('slots')
            .addFieldList([
                'id',
                'is_available',
                'time',
                'time_value'
            ]);
    }

    _getCartTotalsFields() {
        return [
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'is_virtual',
            'applied_rule_ids',
            'base_grand_total',
            'base_currency_code',
            this._getFeeFields(),
            this._getFeeListFields(),
            this._getCartItemsField(),
            this._getTaxes()
        ];
    }

    _getFeeFields() {
        return new Field('fee')
            .addFieldList([
                'currency',
                'value'
            ]);
    }

    _getFeeListFields() {
        return new Field('fee_list')
        .addFieldList([
            'name',
            this._getPriceFields()
        ]);
    }

    _getPriceFields() {
        return new Field('price')
            .addFieldList([
                'currency',
                'value'
            ]);
    }

    _getTaxes() {
        return new Field('applied_taxes')
            .addField('amount')
            .addField(this._getRatesFields());
    }

    _getRatesFields() {
        return new Field('rates')
            .addFieldList([
                'percent',
                'title'
            ]);
    }

    _getSaveCartItemFields(quoteId) {
        return [
            this.getCartQuery(quoteId),
            this._getCartAdditionalDataForItems(quoteId)
        ];
    }

    _getCustomerCartId() {
        return new Field('customerCart')
            .addField('id');
    }

    _getRemoveCartItemFields(quoteId) {
        return [
            this.getCartQuery(quoteId),
            this._getCartAdditionalDataForItems(quoteId)
        ];
    }

    _getCustomerRewardCart() {
        return new Field('customer')
            .addField(this._getRewardPointsField());
    }

    _getRewardPointsField() {
        return new Field('reward_points')
            .addField(this._getRewardBalanceField());
    }

    _getRewardBalanceField() {
        return new Field('balance')
            .addFieldList(this._getRewardBalanceFields());
    }

    _getRewardBalanceFields() {
        return [
            'points',
            this._getRewardMoneyField()
        ];
    }

    _getRewardMoneyField() {
        return new Field('money')
            .addFieldList(this._getRewardMoneyFields());
    }

    _getRewardMoneyFields() {
        return [
            'value',
            'currency'
        ];
    }

    _getCartAdditionalDataForItems(quoteId) {
        const cartField = new Field('cart')
            .addFieldList([
                this._getItemsForCart(),
                this._getPricesForCart(),
                this._getAppliedRewardForCart()
            ]);

            if (quoteId) {
                cartField.addArgument('cart_id', 'String!', quoteId);
            }

            return cartField;
    }

    _getFeesOptionsField() {
        return new Field('options')
            .addFieldList([
                'label',
                'price'
            ]);
    }

    _getAppliedRewardForCart() {
        return new Field('applied_reward_points')
            .addFieldList([
                'points',
                this._getAppliedRewardMoney()
            ]);
    }

    _getAppliedRewardMoney() {
        return new Field('money')
            .addFieldList([
                'currency',
                'value'
            ]);
    }

    _getItemsForCart() {
        return new Field('items')
            .addFieldList(this._getAlleyAndItems());
    }

    _getPricesForCart() {
        return new Field('prices')
        .addFieldList([
            this._getAppliedTaxes(),
            this._getDiscountData()
        ]);
    }

    _getAppliedTaxes() {
        return new Field('applied_taxes')
            .addFieldList([
                'label',
                this._getAppliedTaxesAmountFields()
            ]);
    }

    _getAppliedTaxesAmountFields() {
        return new Field('amount')
            .addFieldList([
                'value',
                'currency'
            ]);
    }

    _getDiscountData() {
        return new Field('discounts')
            .addFieldList([
                'label',
                this._getDiscountAmount()
            ]);
    }

    _getDiscountAmount() {
        return new Field('amount')
            .addField('value')
    }

    _getAlleyAndItems() {
        return [
            'alley',
            'items_count',
            this._getItemsForCategory()
        ];
    }

    _getItemsForCategory() {
        return new Field('items')
            .addFieldList(this._getCartItemsFields());
    }

    _getCartItemsFields() {
        return [
            'id',
            this._getProductFields()
        ];
    }

    _getProductFields() {
        return new Field('product')
            .addField('sku');
    }

    setCallMeStatus(cartID, status) {
        return new Field('setCallMeStatus')
            .addArgument('cart_id', 'String!', cartID)
            .addArgument('status', 'Boolean!', status);
    }

    getApplyCustomizableOptionsFields(cartID, options) {
        return new Field('applyCustomizableOptions')
            .addArgument('cart_id', 'String!', cartID)
            .addArgument('applied_options', '[ApplyCustomizableOptionsInput]!', options)
            .addField(this._getApplyCustomizableOptionsFields());
    }

    _getApplyCustomizableOptionsFields() {
        return new Field('cart')
            .addField('id');
    }
}

export default new CartQuery();
