import PropTypes from 'prop-types';
import DataContainer from 'Util/Request/DataContainer';
import SliderHtml from './SliderHtml.component';
import { loadCmsBlock } from "Component/CmsBlock/CmsBlock.loader";

export class SliderHtmlContainer extends DataContainer {
    static propTypes = {
        identifier: PropTypes.string.isRequired
    };

    state = {
        cmsBlock: {},
        isLoading: true,
    };

    componentDidMount() {
        this._getCmsBlock();
    }
    
    _getCmsBlock() {
        const { identifier } = this.props;
        loadCmsBlock(this, identifier, () => {});
    }

    render() {
        return (
            <>
                {!this.state.isLoading &&
                    <SliderHtml
                        cmsContent={this.state.cmsBlock.content}
                    />
                }
            </>
        );
    }
}

export default SliderHtmlContainer;
