import { PureComponent } from "react";
import { connect } from "react-redux";

import { composeBlogPostData } from "Util/Blog";

import BlogSimilarPostCard from "./BlogSimilarPostCard.component";

export const mapStateToProps = (state) => ({
	blogCategories: state.BlogReducer.blogCategories,
});

export const mapDispatchToProps = (dispatch) => ({});

export class BlogSimilarPostCardContainer extends PureComponent {
	containerProps() {
		const {
			post: postData,
			blogCategories: { items },
			mix,
		} = this.props;

		const post = postData
			? composeBlogPostData(postData, null, null, items)
			: {};

		return {
			post,
			mix,
			isPlaceholder: !Object.keys(post).length,
		};
	}

	render() {
		return <BlogSimilarPostCard {...this.containerProps()} />;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BlogSimilarPostCardContainer);
