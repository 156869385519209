/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ConfigQuery from 'Query/Config.query';
import { Field } from 'Util/Query';
import { fetchMutation } from 'Util/Request';
import { DEFAULT_LOCATION_CODE } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';
import { createMemo } from "Util/Memo";

export const _sortStoresArr = (stores, closestStoreCode, key) => {
    if (!stores.length) {
        return [];
    }

    return stores
        .filter(({ pickup_location_code }) => pickup_location_code !== closestStoreCode)
        .sort((a, b) => {
            let aComparableData = a[key];
            let bComparableData = b[key];

            if (typeof aComparableData === 'string') {
                aComparableData = aComparableData.toUpperCase();
                bComparableData = bComparableData.toUpperCase();
            }

            if (aComparableData < bComparableData) {
                return -1;
            } else if (aComparableData > bComparableData) {
                return 1;
            }
    });
}

/**
* sorts pickup locations in alphabetical order
* @namespace Util/Stores/sortPickupStores
*/
export const sortPickupStores = (stores, selectedStore, isDefaultStoreToRemove) => {
    const { pickup_location_code: closestStoreCode } = selectedStore;

    if (!stores) {
        return [];
    }

    const withoutDefaultStore = stores.filter(({ pickup_location_code }) => pickup_location_code !== 'default');
    const storesToSort = isDefaultStoreToRemove ? withoutDefaultStore : stores;

    const storesWithDistance = storesToSort.filter(({ distance_value }) => distance_value);
    const storesWithoutDistance = storesToSort.filter(({ distance_value }) => !distance_value);

    const sortedStoresWithDistance = _sortStoresArr(storesWithDistance, closestStoreCode, 'distance_value');
    const sortedStoresWithoutDistance = _sortStoresArr(storesWithoutDistance, closestStoreCode, 'name');

    const sortedStores = [
        ...sortedStoresWithDistance,
        ...sortedStoresWithoutDistance
    ];

    if (isDefaultStoreToRemove) {
        const defaultStore = stores.filter(({ pickup_location_code }) => pickup_location_code === 'default')[0];
        if (defaultStore) {
            sortedStores.push(defaultStore);
        }
    }

    sortedStores.unshift(selectedStore);

    return sortedStores;
};

const _getNeedSwapClosestStore = (store, prevStore, isWithDefault) => {
    const {
        distance_value: distance,
        pickup_location_code: storeCode
    } = store;
    const {
        distance_value: prevDistance,
        pickup_location_code: prevStoreCode
    } = prevStore;

    const distanceComparison = prevDistance === null
        ? true
        : distance < prevDistance;
    const isStoreCloser = distance !== null && distanceComparison;
    const isNeedDefaultStoreSwap = !isWithDefault
        && prevStoreCode === DEFAULT_LOCATION_CODE
        && storeCode !== DEFAULT_LOCATION_CODE;

    if (isStoreCloser || isNeedDefaultStoreSwap) {
        return true;
    }
}


/**
 * Fetches closest store for pickup
 *
 * @param { {items: [ { distance_value, pickup_location_code } ] } } pickupLocations
 * @return { Promise<{name, city, distance_value}> }
 */
async function fetchClosestStore(pickupLocations) {
    const pickupItemsFields = [
        'name',
        'city',
        'distance_value',
        'street',
        'country_id',
        'description',
        'latitude',
        'longitude',
        'postcode',
        'pickup_location_code',
        'region_id',
        'cms_page_url_en',
        'cms_page_url_fr',
        'place_id',
        'embedded_map',
        'phone',
        'is_pickup_location_active'
    ];

    const mutation = new Field('resolveClosestStore').addFieldList(pickupItemsFields);

    try {
        const { resolveClosestStore } = await fetchMutation(mutation);
        const closestStoreNull = Object.values(resolveClosestStore).every(x => x === null || x === '');
        const allDistanceValuesNull = pickupLocations?.items?.every(location => location.distance_value === null);
        if (closestStoreNull && allDistanceValuesNull) {
            const defaultStore = pickupLocations.items.filter(({ pickup_location_code }) => pickup_location_code === 'default')[0];
            return defaultStore;
        }
        return resolveClosestStore;
    } catch(e) {
        // TODO: Handle error properly
        console.error(e)
    }
}

const closestStoreMemo = createMemo();

/**
* gets the closest store by distances.
* @namespace Util/Stores/getClosestStore
*/
export const getClosestStore = async (pickupLocations) => {
    return closestStoreMemo(
        () => fetchClosestStore(pickupLocations),
        pickupLocations
    );
};

/**
* sorts pickup locations in alphabetical order
* @namespace Util/Stores/sortPickupStores
*/
export const getDistanceString = (distance) => {
    if (!distance) {
        return null;
    }

    const distanceToArr = distance.toString().split('');

    while (distanceToArr.length <= 3) {
        distanceToArr.unshift('0');
    }

    distanceToArr.splice(distanceToArr.length - 3, 0 , '.');

    if (Number(distanceToArr[distanceToArr.length - 1]) > 4) {
        distanceToArr[distanceToArr.length - 2] = (Number(distanceToArr[distanceToArr.length - 2]) + 1).toString();
    }

    distanceToArr.pop();
    const distanceText = distanceToArr.join('')

    return distanceText;
};

/**
* returns store name by it's code
* @namespace Util/Stores/getStoreName
*/
export const getStoreName = (stores, storeCode) => stores
    .reduce(
        (acc, { pickup_location_code, name }) => {
            if (pickup_location_code === storeCode) {
                acc = name;
            }

            return acc;
        },
    '');
