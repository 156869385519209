import PropTypes from 'prop-types';
import React from 'react';

import FieldForm from 'Component/FieldForm';
import Loader from 'SourceComponent/Loader';

import './TrackOrderForm.style.scss';
import MyAccountOrderPopupDetails from 'Component/MyAccountOrderPopupDetails';

export class TrackOrderForm extends FieldForm {
    static propTypes = {
        sendMessage: PropTypes.func.isRequired,
    };

    /**
     * On submit
     *
     * @param fields
     */
    onFormSuccess = (fields) => {
        const { sendMessage } = this.props;

        // set the value, expected by BE instead of translated string
        fields.find_order_by = 'EMAIL';

        sendMessage(fields);
    };

    /**
     * Fields map
     *
     */
    get fieldMap() {
        const { isSend } = this.props;

        return {
            order_id: {
                label: __('Order ID'),
                validation: ['notEmpty'],
                value: isSend ? "" : null
            },
            billing_last_name: {
                label: __('Billing Last Name'),
                validation: ['notEmpty'],
                value: isSend ? "" : null
            },
            /* hidden field, that might be returned on some day */

            // find_order_by: {
            //     id: 'find_order_by',
            //     label: __('Find Order By'),
            //     validation: ['notEmpty'],
            //     value: __('Email').toString(),
            //     isDisabled: true
            // },
            filter: {
                type: "text",
                label: __("Email"),
                validation: ['notEmpty', 'email'],
                value: isSend ? "" : null,
            },
        };
    }

    renderActions() {
        return (
            <button type="submit" block="Button" elem="Important">
                { __('Submit')}
            </button>
        );
    }

    renderOrderDetail() {
        const {
            orderData,
            orderData: {
                billing_info:  { billing_address: orderBillingAddress },
                base_order_info: { increment_id }
            }
        } = this.props;

        if (!!orderBillingAddress.street && Array.isArray(orderBillingAddress.street)) {
            orderBillingAddress.street = orderBillingAddress.street.join('\n');
        }

        return (
            <div block="TrackOrderForm">
                <h3 block="TrackOrderForm" elem="Header">
                    { __('Order #%s', increment_id) }
                </h3>
                <MyAccountOrderPopupDetails
                  mix={ { block: "MyAccountOrderPopup", mix: { block: "TrackOrderForm", elem: "OrderDetails" } } }
                  order={ orderData }
                  orderBillingAddress={ orderBillingAddress }
                />
            </div>
        );
    }

    renderLoader() {
        const { isLoading } = this.props;
        return <Loader isLoading={isLoading} />;
    }

    /**
     * Render form
     *
     * @return {*}
     */
    render() {
        const { isSend } = this.props;

        return (
            <>
                {isSend ? this.renderOrderDetail() :
                    <div block="TrackOrderForm" elem="Wrapper" mods={{isSend:isSend}}>
                    { this.renderLoader()}
                        <p class="TrackOrder-description">{__(`Order Information`)}</p>
                        {super.render()}
                    </div>}
            </>
        );
    }
}

export default TrackOrderForm
