import {
    SliderWidget as SourceSliderWidget,
} from 'SourceComponent/SliderWidget/SliderWidget.component';
import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';
import Html from 'Component/Html';
import Image from 'Component/Image';
import InfiniteSlider from 'Component/InfiniteSlider';
import Slider from 'Component/Slider';
import { getSlidesInRightForm } from 'Util/Slider';

import 'Component/SliderWidget/SliderWidget.extend.style';

/** @namespace Component/InfiniteSliderWidget/Component */
export class InfiniteSliderWidget extends SourceSliderWidget {
    static propTypes = {
        slider: PropTypes.shape({
            title: PropTypes.string,
            slideSpeed: PropTypes.number,
            slides: PropTypes.arrayOf(
                PropTypes.shape({
                    desktop_image: PropTypes.string,
                    mobile_image: PropTypes.string,
                    slide_text: PropTypes.string,
                    slide_link: PropTypes.string,
                    embed_code: PropTypes.string,
                    isPlaceholder: PropTypes.bool
                })
            )
        }),
        device: DeviceType.isRequired
    };

    state = {
        ...this.state,
        activeBreadCrumb: 0,
        isDragged: false
    };

    // rewritten to avoid super method call
    componentDidUpdate() {}

    sliderClickHandler(slideLink) {
        const { isDragged } = this.state;

        if (isDragged) {
            return;
        }
        if (slideLink) {
            window.location.href = slideLink;
        }
    }

    onActiveImageChange = (activeImage) => {
        this.setState({ activeImage });
    };

    onActiveBreadCrumbChange = (activeBreadCrumb) => {
        this.setState({ activeBreadCrumb });
    };

    setIsDragged = (isDragged) => {
        this.setState({ isDragged });
    }

    renderSlide = (slide) => {
        const {
            slide_text,
            frontEndSlideId,
            slide_link,
            embed_code,
            isPlaceholder,
            title: block
        } = slide;

        return (
            <figure
                block="SliderWidget"
                elem="Figure"
                key={ frontEndSlideId }
            >
                <div
                    mix={ slide_link ? { block: 'SliderWidget', elem: 'FigureImage-Link' } : '' }
                    onClick={ () => this.sliderClickHandler(slide_link) }
                >
                    <Image
                        mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                        ratio="custom"
                        src={ this.getSlideImage(slide) }
                        isPlaceholder={ isPlaceholder }
                    />
                    <figcaption
                        block="SliderWidget"
                        elem="Figcaption"
                        mix={ { block } }
                    >

                        <Html content={ slide_text || '' } />
                    </figcaption>
                    <figcaption
                        block="SliderWidget"
                        elem="Figcaption-EmbedCode"
                        mix={ { block } }
                    >
                        <Html content={ embed_code || '' } />
                    </figcaption>
                </div>
            </figure>
        );
    };

    render() {
        const {
            activeImage,
            activeBreadCrumb,
            isDragged
        } = this.state;

        const {
            slider: {
                slideSpeed,
                slides,
                // is_infinite,
                title: block
            }
        } = this.props;

        const rearrangedSlides = getSlidesInRightForm(slides);
        const isInfiniteSlider = block === 'MainImagesSlider';

        const WrapperComponent = isInfiniteSlider
            ? InfiniteSlider
            : Slider;

        return (
            <WrapperComponent
              mix={ { block: 'SliderWidget', mix: { block } } }
              showCrumbs
              slideSpeed={ slideSpeed }
              isDragged={ isDragged }
              areArrowsRendered={ !isInfiniteSlider }
              activeImage={ activeImage }
              activeBreadCrumb={ activeBreadCrumb }
              onActiveImageChange={ this.onActiveImageChange }
              onActiveBreadCrumbChange={ this.onActiveBreadCrumbChange }
              setIsDragged={ this.setIsDragged }
              isInfinite={ isInfiniteSlider }
            >
                { rearrangedSlides.map(this.renderSlide) }
            </WrapperComponent>
        );
    }
};

export default InfiniteSliderWidget;
