export const FULL_COOKING_TIME_STRING = "FULL_COOKING_TIME_STRING";

export const formatRecipeMethods = (method) => {
	if (!Array.isArray(method)) {
		return {};
	}

	let prevSection = "";

	return method.reduce((acc, { section, step }, stepIdx) => {
		if (!section && !prevSection) {
			return acc;
		}

		if (!section) {
			acc[prevSection].push({
                step,
                stepIdx
            });
			return acc;
		}

		if (!acc[section]) {
			prevSection = section;
			acc[section] = [];
		}

		acc[section].push({
            step,
            stepIdx
        });
		return acc;
	}, {});
};

export const formatIngredientsSections = (ingredients) => {
	if (!Array.isArray(ingredients)) {
		return {};
	}

	let prevSection = null;

	return ingredients.reduce((acc, { section, ...ingredientData }) => {
		// if no section name, it's unnamed ingredients section
        if (!section && !prevSection) {
            if (acc['']) {
                acc[''].push(ingredientData);
                return acc;
            }

            acc[''] = [ingredientData];
			return acc;
		}

		if (!section) {
			acc[prevSection].push(ingredientData);
			return acc;
		}

		if (!acc[section]) {
			prevSection = section;
			acc[section] = [];
		}

		acc[section].push(ingredientData);
		return acc;
	}, {});
};

const _getIngredientsArr = (ingredients) =>
	ingredients.map(({ measure, ingredient }) => `${measure} ${ingredient}`);

const _getInstructionsArr = (instructions) =>
	instructions.map(({ step }) => ({
		"@type": "HowToStep",
		text: step,
	}));

function _convertTimeToIso8601(data) {
	if (!data) {
		return "";
	}

	const [h, m] = data.split(":");
	const hours = Number(h);
	const minutes = Number(m);

	switch (true) {
		case !!hours && !minutes:
			return `PT${hours}H`;
		case !hours && !!minutes:
			return `PT${minutes}M`;
		default:
			return `PT${hours}H${minutes}M`;
	}
}

export const toggleGoogleRecipeScript = (() => {
	let script = null;

	function create(data) {
		const {
			title,
			short_description,
			thumbnail: { url },
			prep_time,
			cook_time,
			meta_keywords,
			categories: { items = [] } = {},
			ingredients = [],
			method,
			writer_info: { name: writerName },
		} = data;

		if (!data) {
			return;
		}

		const prepTimeStr = _convertTimeToIso8601(prep_time[0]?.data);
		const cookTimeStr = _convertTimeToIso8601(cook_time[0]?.data);
		const recipeCategory = items.map(({ name }) => name);
		const recipeIngredient = _getIngredientsArr(ingredients);
		const recipeInstructions = _getInstructionsArr(method);

		const scriptContent = {
			"@context": "https://schema.org/",
			"@type": "Recipe",
			name: title,
			image: url,
			description: short_description,
			prepTime: prepTimeStr,
			cookTime: cookTimeStr,
			keywords: meta_keywords,
			recipeCategory,
			recipeIngredient,
			recipeInstructions,
            author: {
                "@type": "Person",
                name: writerName,
            },
		};

		const script = document.createElement("script");
		script.setAttribute("type", "application/ld+json");
		script.text = JSON.stringify(scriptContent);

		document.head.appendChild(script);
    }

	function createScript(data) {
		removeScript();
		create(data);
	}

	function removeScript() {
		if (!script) {
			return;
		}

		document.head.removeChild(script);
		script = null;
	}

	return {
		createScript,
		removeScript,
	};
})();

export const getCookingTimeObj = (timeData) => {
	if (!timeData) {
		return timeData;
	}

	const regex = /0?(\d+)/;
	return timeData.split(":").map((value) => value.replace(regex, "$1"));
};

export const getCookingString = (timeArr, type, isWithLineBreak = false) => {
	if (!timeArr) {
		return timeArr;
	}

	switch (type) {
		case FULL_COOKING_TIME_STRING:
			return timeArr.reduce((acc, val, i) => {
				let label = "";

				if (val == 0) {
					return acc;
				}

				if (i == 0) {
					label = val === "1" ? __("hour") : __("hours");
					acc = `${val} ${label} `;
					return acc;
				}

				label = val === "1" ? __("minute") : __("minutes");

				if (isWithLineBreak && acc) {
					acc += `<br />${val} ${label}`;
				} else {
					acc += `${val} ${label}`;
				}

				return acc;
			}, "");
		default:
			return timeArr.reduce((acc, val, i) => {
				let label = "";

				if (val == 0) {
					return acc;
				}

				if (i == 0) {
					label = "h";
					acc = `${val} ${label} `;
					return acc;
				}

				label = "min";
				acc += `${val} ${label} `;
				return acc;
			}, "");
	}
};

export const summarizeCookAndPrep = (cookData, prepData) => {
    const { data: cookTime } = cookData[0] || {};
    const { data: prepTime } = prepData[0] || {};

    const cookTimeArr = getCookingTimeObj(cookTime || "00:00");
    const prepTimeArr = getCookingTimeObj(prepTime || "00:00");

    const hours = (Number(cookTimeArr[0])
        + Number(prepTimeArr[0])
        + Math.floor(
            (Number(cookTimeArr[1]) + Number(prepTimeArr[1])) / 60
        )).toString();

    const minutes = ((Number(cookTimeArr[1]) + Number(prepTimeArr[1])) % 60).toString();

    return getCookingString([hours, minutes]);
}

export const isHtmlType = (data = []) => data.length === 1
    && Object.values(data[0] || {})
        .filter(val => typeof val === 'string').length === 1;

// Checks if there is only one item with valid value in the array
export const isOnlySection = (data = []) => data.length === 1
    && Object.values(data[0] || {})
		.filter(val => val).length === 1;

export const isOnlyInstruction = (data = []) => data.length === 1
    && Object.values(data[0] || {})
		.filter(val => val === "0").length === 1
	&& Object.keys(data[0].step).length === 0;