import { PureComponent } from 'react';
import { connect } from 'react-redux';
import Loader from "Component/Loader";
import Promotion from './Promotion.component';

import PromotionQuery from 'Query/Promotion.query';
import { fetchQuery } from "Util/Request";

export const PromotionDispatcher = import(
	/* webpackMode: "lazy", webpackChunkName: "dispatchers" */
	"Store/Promotion/Promotion.dispatcher"
);

/** @namespace Component/Promotion/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    promotion: state.PromotionReducer.promotion,
    device: state.ConfigReducer.device,
});

/** @namespace Component/Promotion/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getPromotionData: (id) => 
        PromotionDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getPromotionData(
                dispatch,
                id
            )
        ),
});

/** @namespace Component/Promotion/Container */
export class PromotionContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            promotion: null,
            cards: [],
            isTablet: window.innerWidth >= 812 && window.innerWidth <= 1240,
            isDesktop: window.innerWidth > 1240
        };
    }

    containerProps = () => {
        const {
            device: { isMobile },
            id,
            title,
            description,
            note,
            date = ''
        } = this.props;
        return {
            isMobile,
            id,
            title,
            description,
            note,
            date
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        setTimeout(() => {
            this.getPromotion();
        }, 1000);
    }

    componentDidUpdate() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.setState({
            isTablet: window.innerWidth >= 812 && window.innerWidth <= 1240,
            isDesktop: window.innerWidth > 1240
        });
    };

    async getPromotion() {
        const { id, date, getPromotionData } = this.props;
        this.setState({ isLoading: true });
        try {
            await getPromotionData(id);
            const avrilPromotion = await fetchQuery(PromotionQuery.getQuery(id, date));
            if (avrilPromotion) {
                const { promoMonth: { promotion, promotion: { cards = [] } } } = avrilPromotion;
                this.setState({ promotion, cards, isLoading: false });
            } else {
                this.setState({ promotion: null, isLoading: false });
            }
        } catch (err) {
            console.log(err);
            this.setState({ promotion: null, isLoading: false });
        }
    }

    render() {
        const { promotion, cards, isLoading, isDesktop, isTablet } = this.state;
        return (
            <>
                {isLoading
                    ? <Loader isLoading={isLoading} />
                    : <Promotion
                        promotion={promotion}
                        cards={cards}
                        isTablet={isTablet}
                        isDesktop={isDesktop}
                        { ...this.containerProps() } />
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionContainer);
