/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import React, { Children, createRef, PureComponent } from 'react';

import Draggable from 'Component/Draggable';
import { ChildrenType, MixType } from 'Type/Common';
import CSS from 'Util/CSS';

import {
    ACTIVE_SLIDE_PERCENT,
    ANIMATION_DURATION,
    BUTTON_LEFT,
    BUTTON_RIGHT,
    DESKTOP_WIDTH,
    DESKTOP_SLIDE_WIDTH,
    MAX_WINDOW_SIZE
} from './ProductSlider.config';

import './ProductSlider.style';

export class ProductSlider extends PureComponent {
    static propTypes = {
        showCrumbs: PropTypes.bool,
        activeImage: PropTypes.number,
        onActiveImageChange: PropTypes.func,
        mix: MixType,
        children: ChildrenType.isRequired
    };

    static defaultProps = {
        activeImage: 0,
        onActiveImageChange: () => {},
        showCrumbs: false,
        mix: {}
    };

    sliderWidth = 0;

    slideWidth = 0;

    prevPosition = 0;

    slidesQty = 0;

    draggableRef = createRef();

    sliderRef = createRef();

    handleDragStart = this.handleDragStart.bind(this);

    handleDrag = this.handleDrag.bind(this);

    handleDragEnd = this.handleDragEnd.bind(this);

    renderCrumb = this.renderCrumb.bind(this);

    constructor(props) {
        super(props);

        const { activeImage } = this.props;

        this.state = {
            prevActiveImage: activeImage,
            slidesQtyPerPage: 1,
            newTranslate: 0,
            children:[]
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { activeImage} = props;
        const { prevActiveImage } = state;

        if (prevActiveImage !== activeImage) {
            return { prevActiveImage: activeImage };
        }

        return null;
    }

    componentDidMount() {
        const { children } = this.props;

        this.slidesQty = children.length;
        this.onSliderAppearing();

        if (children && children.length) {
            this.setState({ children })
        }
    }

    componentDidUpdate(prevProps) {
        const {
            activeImage: prevActiveImage,
            children: prevChildren,
            isSliderExist: prevIsSliderExist
        } = prevProps;
        const {
            activeImage,
            children,
            isSliderExist
        } = this.props;
        this.slidesQty = children.length;

        if(children !== this.state.children){
            this.setState({children:children});
        }

        if (
            children.length !== prevChildren.length
            || isSliderExist
            && isSliderExist !== prevIsSliderExist
        ) {
            this.onSliderAppearing();
        }

        if (activeImage !== prevActiveImage) {
            const newTranslate = Math.max(-activeImage * this.slideWidth, -this.getDraggableAreaWidth());

            CSS.setVariable(
                this.draggableRef,
                'animation-speed',
                `${ Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION) }ms`
            );

            this.setState({ newTranslate });
            CSS.setVariable(
                this.draggableRef,
                'translateX',
                `${newTranslate}px`
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    onSliderAppearing() {
        const sliderChildren = this.draggableRef.current.children;
        const sliderWidth = this.draggableRef.current.offsetWidth;
        this.sliderWidth = sliderWidth;
        const { children } = this.props;
        this.slidesQty = children.length;

        if (!sliderChildren || !sliderChildren[0]) return;

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        sliderChildren[0].onload = () => {
            CSS.setVariable(this.sliderRef, 'slider-height', `${sliderChildren[0].offsetHeight}px`);
        };

        setTimeout(() => {
            if (sliderChildren[0]) {
                CSS.setVariable(this.sliderRef, 'slider-height', `${sliderChildren[0].offsetHeight}px`);
            }
        }, ANIMATION_DURATION);
    }

    onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize) {
        const { originalX } = state;
        const { prevActiveImage: prevActiveSlider } = this.state;
        const { onActiveImageChange } = this.props;

        const fullSliderPoss = Math.round(fullSliderSize / slideSize);
        const elementPossitionInDOM = this.draggableRef.current.getBoundingClientRect().x;
        const sliderPossition = -prevActiveSlider;
        const realElementPossitionInDOM = elementPossitionInDOM - lastTranslate;
        const mousePossitionInElement = originalX - realElementPossitionInDOM;

        if (slideSize / 2 < mousePossitionInElement && -fullSliderPoss < sliderPossition) {
            const activeSlide = sliderPossition - 1;
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        if (slideSize / 2 > mousePossitionInElement && lastTranslate) {
            const activeSlide = sliderPossition + 1;
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        return sliderPossition;
    }

    getFullSliderWidth() {
        return this.slidesQty * this.slideWidth;
    }

    getDraggableAreaWidth() {
        return this.getFullSliderWidth() - this.sliderWidth;
    }

    isNeedPadding(){
        const { slidesQtyPerPage } = this.state;
        const { activeImage } = this.props;

        if (activeImage >= this.slidesQty - slidesQtyPerPage) {
            CSS.setVariable(
                this.sliderRef,
                'margin-right',
                `16px`
            );
        } else {
            CSS.setVariable(
                this.sliderRef,
                'margin-right',
                `1px`
            );
        }
    }
    getImage(type) {
        const { slidesQtyPerPage } = this.state;
        const { activeImage } = this.props;
        if (type === BUTTON_LEFT) {
            if (activeImage <= 0) {
                return 0;
            }
            return !activeImage ? activeImage : activeImage - 1;
        }
        this.isNeedPadding()
        return activeImage > Math.round(this.slidesQty - slidesQtyPerPage)
            ? activeImage
            : activeImage + 1;
    }

    renderButton = (type) => {
        const { slidesQtyPerPage } = this.state;
        const { onActiveImageChange } = this.props;

        if (this.slidesQty <= Math.floor(slidesQtyPerPage)) {
            return null;
        }

        const image = this.getImage(type);
        return (
            <button
              block="RecentlyViewedSlider"
              elem="Arrow"
              mods={ { type } }
              onClick={ () => onActiveImageChange(image) }
            />
        );
    };

    getChildWidth() {
        if (!this.draggableRef.current || !this.draggableRef.current.children[1]) {
            return 1;
        }

        const child = this.draggableRef.current.children[1];
        const childeRightMargin = window.getComputedStyle(child).marginRight;
        return child.offsetWidth + Number(childeRightMargin.slice(0, childeRightMargin.length - 2)) || 0;
    }

    updateWindowDimensions = () => {
        const { isMobileFullScreen, containerRef } = this.props;
        const { clientWidth: sliderVisibleWidth } = this.sliderRef.current || {};
        const { clientWidth: containerVisibleWidth } = containerRef?.current || {};

        const sliderWidth = containerVisibleWidth || sliderVisibleWidth;
        this.sliderWidth = sliderWidth;
        const windowSize = document.documentElement.clientWidth > MAX_WINDOW_SIZE
            ? MAX_WINDOW_SIZE
            : document.documentElement.clientWidth;

        if (
            !this.draggableRef.current
            || !this.draggableRef.current.children[1]
        ) {
            return;
        }

        const singleSlideWidth = this.getChildWidth();

        if (windowSize >= DESKTOP_WIDTH && !isMobileFullScreen) {
            const slidesQtyPerPage = this.slidesQty < sliderWidth / DESKTOP_SLIDE_WIDTH
                ? this.slidesQty
                : (sliderWidth / DESKTOP_SLIDE_WIDTH).toFixed(2);

            this.setState({ slidesQtyPerPage });
            this.slideWidth = sliderWidth / slidesQtyPerPage;
        } else {
            const slidesQtyPerPage = (sliderWidth / singleSlideWidth).toFixed(2);
            if (isMobileFullScreen) {
                this.slideWidth = singleSlideWidth;
                this.sliderWidth = sliderWidth;
                return;
            }

            this.slideWidth = sliderWidth / slidesQtyPerPage;
        }

        this.changeActiveImage(0);
    };

    calculateNextSlide(state) {
        const {
            translateX: translate,
            lastTranslateX: lastTranslate
        } = state;
        const { onActiveImageChange } = this.props;
        const { prevActiveImage = 0 } = this.state;
        const slideSize = this.slideWidth;

        const draggableAreaWidth = this.getDraggableAreaWidth();

        const activeSlidePosition = translate / slideSize;
        const activeSlidePercent = Math.abs(activeSlidePosition % 1);
        const isSlideBack = translate > lastTranslate;

        if (!translate) return -prevActiveImage;

        if (translate >= 0) {
            onActiveImageChange(0);
            return 0;
        }

        if (translate < -draggableAreaWidth) {
            const activeSlide = Math.floor(draggableAreaWidth / -slideSize);
            onActiveImageChange(Math.max(-activeSlide, 0));
            return activeSlide;
        }

        if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.ceil(activeSlidePosition);
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.floor(activeSlidePosition);
            onActiveImageChange(-activeSlide);
            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);
        onActiveImageChange(-activeSlide);
        return activeSlide;
    }

    handleDragStart() {
        CSS.setVariable(this.draggableRef, 'animation-speed', '0');
    }

    handleDrag(state) {
        const { translateX } = state;
        const { slidesCount } = this.props
        const { slidesQtyPerPage } = this.state
        const translate = translateX;

        const draggableAreaWidth = this.getDraggableAreaWidth();

        if(slidesCount <= slidesQtyPerPage){
            return
        }

        if (translate < 0 && translate > -draggableAreaWidth) {
            CSS.setVariable(
                this.draggableRef,
                'translateX',
                `${Math.max(translate, -draggableAreaWidth)}px`
            );
        }
    }

    handleDragEnd(state, callback) {
        const { slidesCount } = this.props
        const { slidesQtyPerPage } = this.state
        const activeSlide = this.calculateNextSlide(state);
        const slideSize = this.slideWidth;
        const draggableAreaWidth = this.getDraggableAreaWidth();
        const newTranslate = activeSlide === -(this.slidesQty - Math.floor(slidesQtyPerPage))
            ? -draggableAreaWidth : activeSlide * slideSize;
        CSS.setVariable(this.draggableRef, 'animation-speed', '300ms');

        if (slidesCount <= slidesQtyPerPage) {
            return
        }
        if (Math.abs(newTranslate) === draggableAreaWidth && draggableAreaWidth !== 0) {
            CSS.setVariable(
                this.sliderRef,
                'margin-right',
                `16px`
            );
        }else{
            CSS.setVariable(
                this.sliderRef,
                'margin-right',
                `1px`
            );
        }

        if (newTranslate !== 0 && this.slidesQty > 1) {
            CSS.setVariable(
                this.draggableRef,
                'translateX',
                `${Math.max(newTranslate, -draggableAreaWidth)}px`
            );
        }

        callback({
            originalX: newTranslate,
            lastTranslateX: newTranslate
        });
    }

    changeActiveImage(activeImage) {
        const { onActiveImageChange } = this.props;
        onActiveImageChange(activeImage);
    }

    renderCrumbs() {
        const { children, isMobileFullScreen } = this.props;

        return (
            <div
              block="RecentlyViewedSlider"
              elem="Crumbs"
              mods={ { isMobileFullScreen } }
            >
                { Children.map(children, this.renderCrumb) }
            </div>
        );
    }

    renderCrumb(_, i) {
        const { activeImage, isMobileFullScreen } = this.props;
        const { slidesQtyPerPage } = this.state;
        const isActive = i === Math.abs(-activeImage);

        if (
            !isMobileFullScreen
            && (i > (this.slidesQty - Math.floor(slidesQtyPerPage))
            || this.slidesQty <= Math.floor(slidesQtyPerPage))
        ) {
            return null;
        }

        return (
            <button
              block="RecentlyViewedSlider"
              elem="Image"
              mods={ { type: 'single' } }
              onClick={ () => this.changeActiveImage(i) }
            >
                <div
                  block="RecentlyViewedSlider"
                  elem="Crumb"
                  mods={ { isActive } }
                />
            </button>
        );
    }

    getNewShift() {
        const { activeImage } = this.props;

        if (!this.draggableRef.current) {
            return 0;
        }

        const fullDraggableWidth = this.draggableRef.current.getBoundingClientRect().width;

        const singleSlideWidth = this.getChildWidth();
        const slidesQtyPerPage = (window.innerWidth / singleSlideWidth).toFixed(2);
        const lastSlideTranslate = fullDraggableWidth - singleSlideWidth * slidesQtyPerPage;

        const newShift = activeImage === 5
            ? -lastSlideTranslate
            : -activeImage * this.slideWidth;

        return newShift;
    }

    render() {
        const {
            mix,
            activeImage,
            children,
            showCrumbs,
            isMobileFullScreen
        } = this.props;

        const { newTranslate } = this.state;

        const isCrumbsVisible = showCrumbs && !isMobileFullScreen;

        return (
            <div
              block="RecentlyViewedSlider"
              elem="Container"
            >
                { this.renderButton(BUTTON_LEFT) }
                <div
                  block="RecentlyViewedSlider"
                  mix={ mix }
                  ref={ this.sliderRef }
                >
                    <Draggable
                      mix={ {
                          block: 'RecentlyViewedSlider',
                          elem: 'Wrapper',
                          mods: { activeImage: activeImage % 2 ? 'odd' : 'even'}
                      } }
                      draggableRef={ this.draggableRef }
                      onDragStart={ this.handleDragStart }
                      onDragEnd={ this.handleDragEnd }
                      onDrag={ this.handleDrag }
                      shiftX={ newTranslate }
                    >
                        { children }
                    </Draggable>
                    { isCrumbsVisible && this.renderCrumbs() }
                </div>
                { showCrumbs && isMobileFullScreen && this.renderCrumbs() }
                { this.renderButton(BUTTON_RIGHT) }
            </div>
        );
    }
}

export default ProductSlider;
