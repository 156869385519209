import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
	mapStateToProps as sourceMapStateToProps,
	mapDispatchToProps as sourceMapDispatchToProps,
	BlogPaginationContainer,
} from "Component/BlogPagination/BlogPagination.container";

import { clearRecipesListItems } from "Store/Recipe/Recipe.action";

export const RecipeDispatcher = import(
	/* webpackMode: "lazy", webpackChunkName: "dispatchers" */
	"Store/Recipe/Recipe.dispatcher"
);

export const mapStateToProps = (state) => ({
	...sourceMapStateToProps(state),
	anchorTextNext: state.ConfigReducer.anchor_text_for_next,
	recipesPerPage: state.ConfigReducer.mageside_recipe_recipe_list_recipe_per_page,
	totalRecipesInCategoryCount: state.RecipeReducer.currentCategoryData.recipe_count,
	totalRecipesCount: state.RecipeReducer.recipes.total_count,
    isAllCategories: state.RecipeReducer.currentCategoryData.all_categories,
    isLoading: state.RecipeReducer.isCurrentCategoryLoading,
});

export const mapDispatchToProps = (dispatch) => ({
	...sourceMapDispatchToProps(dispatch),
	getRecipesList: (recipesPerPage, currentPage, filter) =>
		RecipeDispatcher.then(({ default: dispatcher }) =>
			dispatcher.getRecipesList(
				dispatch,
				recipesPerPage,
				currentPage,
				filter
			)
		),
	clearRecipesListItems: () => dispatch(clearRecipesListItems()),
});

export class RecipePaginationContainer extends BlogPaginationContainer {
	onPageSelect(pageNumber) {
		const {
			getRecipesList,
			recipesPerPage,
			clearRecipesListItems,
			category_id,
		} = this.props;

        window.scrollTo(0, 0);
        this.setState({ currentPage: pageNumber })
        clearRecipesListItems();
		getRecipesList(recipesPerPage, pageNumber, { category_id });
	}

	containerProps = () => {
		const {
            totalRecipesInCategoryCount,
            recipesPerPage,
            isAllCategories,
            totalRecipesCount
        } = this.props;

        const totalCount = isAllCategories === '1'
            ? totalRecipesCount
            : totalRecipesInCategoryCount;
        const totalPages = Math.ceil(totalCount / recipesPerPage);

		return { totalPages };
	};

	containerFunctions = {
		...this.containerFunctions,
		onPageSelect: this.onPageSelect.bind(this),
	};
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(RecipePaginationContainer)
);
