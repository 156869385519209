import { PureComponent } from "react";
import { connect } from "react-redux";

import CmsBlock from "Component/CmsBlock";
import Html from "Component/Html";
import { getCurrentEntity } from "Util/Blog";
import { ALL, CATEGORY, TAG } from "Route/BlogPage/BlogPage.config";

import { BLOG_BANNER_ID } from "./BlogCmsBanner.config";

import "./BlogCmsBanner.style";

/** @namespace Component/BlogCmsBanner/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    isVisible: state.BlogReducer.isBlogBannerVisible,
    pageInfo: state.BlogReducer.pageInfo,
    blogCategories: state.BlogReducer.blogCategories,
    blogTags: state.BlogReducer.blogTags,
});

export class BlogCmsBanner extends PureComponent {
    renderCategoryPageContent() {
        const {
            blogCategories: { items: categories } = {},
            pageInfo: { entityId } = {},
        } = this.props;

        const { description, name } = getCurrentEntity(
            categories,
            "category_id",
            entityId
        );

        if (!description) {
            return (
                <h1 block="BlogCmsBanner" elem="CategoryHeader">
                    {name}
                </h1>
            );
        }

        return (
            <div block="BlogCmsBanner" elem="Content">
                <Html content={description} />
            </div>
        );
    }

    renderTagPageContent() {
        const { blogTags: { items: tags } = {}, pageInfo: { entityId } = {} } =
            this.props;

        const { name } = getCurrentEntity(tags, "tag_id", entityId);

        if (!name) return null;

        return (
            <h1 block="BlogCmsBanner" elem="TagHeader">
                {name}
            </h1>
        );
    }

    renderContent() {
        const { pageInfo: { pageType } = {} } = this.props;

        switch (pageType) {
            case ALL:
                return <CmsBlock identifier={BLOG_BANNER_ID} />;
            case CATEGORY:
                return this.renderCategoryPageContent();
            case TAG:
                return this.renderTagPageContent();
        }
    }

    render() {
        const {
            isVisible,
            isRenderedInRouter,
            mix,
            pageInfo: { pageType } = {},
        } = this.props;

        if (!isVisible) {
            return null;
        }

        return (
            <div
                block="BlogCmsBanner"
                mix={mix}
                mods={{
                    isRenderedInRouter,
                    isTagPage: pageType === TAG,
                }}
            >
                {this.renderContent()}
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(BlogCmsBanner);
