import amastyCollector from '../util/AmastySearchCollector';

const initAmastyCollector = (args, callback) => {
    const [{ storeConfig: { base_url, amasty_xsearch_recently_viewed_enabled } }] = args;
    amastyCollector.init(base_url, amasty_xsearch_recently_viewed_enabled);
    return callback(...args);
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            'onSuccess': initAmastyCollector
        }
    }
};
