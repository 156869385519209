import { PureComponent } from "react";

import Link from "Component/Link";
import Image from "Component/Image";
import Breadcrumbs from "Component/Breadcrumbs";
import RecipeCard from "Component/RecipeCard";
import BlogTag from "Component/BlogTag";
import ShareDropdown from "Component/ShareDropdown";
import Loader from "Component/Loader";
import Html from "Component/Html";
import ProductSlider from "Component/ProductSliders";
import RecipeIngredientProductsOverlay from "Component/RecipeIngredientProductsOverlay";

import {
	formatRecipeMethods,
	formatIngredientsSections,
	getCookingTimeObj,
	getCookingString,
	isHtmlType,
	isOnlySection,
	isOnlyInstruction,
	FULL_COOKING_TIME_STRING,
} from "Util/Recipe";

import { ReactComponent as CartIcon } from "./icons/cart.svg";
import { ReactComponent as CookingIcon } from "./icons/cooking.svg";
import { ReactComponent as PreparationIcon } from "./icons/preparation.svg";
import { ReactComponent as PersonIcon } from "./icons/person.svg";
import { ReactComponent as PrintIcon } from "./icons/print.svg";
import { ReactComponent as PrintMobileIcon } from "./icons/print_mobile.svg";

import "Route/BlogPostPage/BlogPostPage.style";
import "./RecipePage.style";

/** @namespace Route/RecipePage/Component */
export class RecipePage extends PureComponent {
	renderCategory({ name, url }) {
		const {
			device: { isMobile },
			recipeData: { title_color }
		} = this.props;

		if (isMobile) {
			return <BlogTag tag={{ name, url }} />;
		}

		return (
			<Link
				mix={{ block: "RecipePage", elem: "CategoryLink" }}
				to={url}
				style={title_color && { color: title_color } }
				>
				{name}
			</Link>
		);
	}

	renderServingItem(itemData = [], Icon) {
		const { data } = itemData[0] || {};

		if (!data) {
			return null;
		}

		return (
			<span block="RecipePage" elem="ServingItem">
				<span block="RecipePage" elem="ServingItemIconContainer">
					<Icon />
				</span>
				<p block="RecipePage" elem="ServingItemText">
					<Html content={data} />
				</p>
			</span>
		);
	}

	renderServingRow() {
		const {
			recipeData: { prep_time, cook_time, servings_number, unit },
		} = this.props;

		const servingData = { ...(servings_number[0] || {}) };
		servingData.data = `${servingData.data} ${unit}`;

		const prepData = getCookingTimeObj(prep_time[0]?.data);
		const cookData = getCookingTimeObj(cook_time[0]?.data);
		const prepTime = [{ data: getCookingString(prepData, null, true) }];
		const cookTime = [{ data: getCookingString(cookData, null, true) }];

		return (
			<div block="RecipePage" elem="ServingRow">
				{this.renderServingItem([servingData], PersonIcon)}
				{this.renderServingItem(prepTime, PreparationIcon)}
				{this.renderServingItem(cookTime, CookingIcon)}
			</div>
		);
	}

	renderBreadcrumbs() {
		return (
			<Breadcrumbs
				areBreadcrumbsVisible
				isForceRendered
				mix={{ block: "RecipePage", elem: "Breadcrumbs" }}
			/>
		);
	}

	renderMobileUpperBlock() {
		const {
			recipeData: {
				title,
				mobile_main_image: { alt: mobileAlt, url: mobileUrl } = {},
				media_type_image: { alt: desktopAlt, url: desktopUrl } = {},
				categories: { items = [] } = {},
			},
		} = this.props;

		const url = mobileUrl || desktopUrl;
		const alt = mobileAlt || desktopAlt;

		return (
			<section block="RecipePage" elem="UpperBlock">
				{this.renderBreadcrumbs()}
				<h1 block="RecipePage" elem="Title">
					{title}
				</h1>
				<nav block="RecipePage" elem="Categories">
					{items.map(this.renderCategory.bind(this))}
				</nav>
				<div block="RecipePage" elem="TitleAndCategories">
					<Image
						src={url}
						mix={{
							block: "RecipePage",
							elem: "UpperBlockImg",
						}}
						ratio="custom"
						alt={alt}
					/>

					{this.renderServingRow()}
				</div>
			</section>
		);
	}

	renderUpperBlock() {
		const {
			device: { isMobile },
			recipeData: { title, categories: { items = [] } = {} },
		} = this.props;

		if (!title) {
			return null;
		}

		if (isMobile) {
			return this.renderMobileUpperBlock();
		}

		return (
			<section block="RecipePage" elem="UpperBlock">
				<div block="RecipePage" elem="TitleAndCategories">
					{this.renderRecipeTitle()}
					<nav block="RecipePage" elem="Categories">
						{items.map(this.renderCategory.bind(this))}
					</nav>
					{this.renderServingRow()}
				</div>
				{this.renderUpperBlockImg()}
			</section>
		);
	}

	renderRecipeTitle() {
		const {
			recipeData: { title, title_color },
		} = this.props;
		
		return (
			<h1
				block="RecipePage"
				elem="Title"
				style={title_color && { color: title_color, borderBottomColor: title_color } }
			>
				{title}
			</h1>
		);
	}

	renderUpperBlockImg() {
		const {
			recipeData: { media_type_image: { alt, url } = {} },
		} = this.props;

		return (
			<Image
				src={url}
				mix={{
					block: "RecipePage",
					elem: "UpperBlockImg",
				}}
				ratio="custom"
				alt={alt}
			/>
		);
	}

	renderShortDescription() {
		const {
			recipeData: { short_description },
		} = this.props;

		if (!short_description) {
			return null;
		}

		return (
			<section block="RecipePage" elem="ShortDescriptionSection">
				<p block="RecipePage" elem="ShortDescription">
					{short_description}
				</p>
			</section>
		);
	}

    renderOpenAddToCartOverlayBtn(url) {
        const { onAddIngredientProductClick } = this.props;

        if (!url) {
            return null;
        }

        return (
            <td block="RecipePage" elem="OpenAddToCartOverlay">
                <button
                    block="RecipePage"
                    elem="OpenAddToCartOverlayBtn"
                    onClick={() => onAddIngredientProductClick(url)}
                >
                    <CartIcon />
                </button>
            </td>
        );
    }

	renderIngredient({ measure, ingredient, url }) {
		return (
			<tr block="RecipePage" elem="IngredientRow">
				<td block="RecipePage" elem="IngredientMeasure">
					{measure}
				</td>
				<td block="RecipePage" elem="IngredientName">
					{ingredient}
				</td>
				{this.renderOpenAddToCartOverlayBtn(url)}
			</tr>
		);
	}

	renderIngredientSection([section, ingredients]) {
		return (
			<div block="RecipePage" elem="IngredientsSection">
                {section && (
                    <h5 block="RecipePage" elem="IngredientsSectionHeader">
                        {section}
                    </h5>
                )}
				<table block="RecipePage" elem="IngredientsList">
					{ingredients.map(this.renderIngredient.bind(this))}
				</table>
			</div>
		);
	}

    renderIngredientsContent() {
        const {
            recipeData: { ingredients = [] },
        } = this.props;

        const ingredientsSections = formatIngredientsSections(ingredients);

		if (isHtmlType(ingredients) || isOnlySection(ingredients)) {
            return <Html content={ingredients[0].section} />;
        }

        return (
            <>
                {Object.entries(ingredientsSections).map(
                    this.renderIngredientSection.bind(this)
                )}
            </>
        );
    }

    renderIngredientsBlock() {
        const { isRecipeIngredientsProductsLoading } = this.props;

		return (
			<section block="RecipePage" elem="IngredientsWrapper">
				<h4
					block="RecipePage"
					elem="IngredientsHeader"
					mix={{ block: "RecipePage", elem: "RecipeDetailsHeader" }}
				>
					{__("Ingredients")}
				</h4>
				<table block="RecipePage" elem="IngredientsList">
					{this.renderIngredientsContent()}
				</table>
				<Loader isLoading={isRecipeIngredientsProductsLoading} />
			</section>
		);
	}

	renderInstruction(
        { step, stepIdx },
        instructionIdx,
        idx,
        instructionsLength,
        methodsLength
    ) {
        if (instructionIdx === methodsLength) {
            return (
                <>
                    <p>{step}</p>
                    <br />
                </>
            );
        }

        if (instructionsLength === idx + 1) {
            return (
                <>
                    <p>
                        {stepIdx + 1}. {step}
                    </p>
                </>
            );
        }

		return (
			<>
                <p>
                    {stepIdx + 1}. {step}
                </p>
                <br />
			</>
		);
	}

    renderInstructionsSection(
        [section, instructions],
        instructionIdx,
        methodsLength
    ) {
		return (
			<div block="RecipePage" elem="InstructionsSection">
				<h5 block="RecipePage" elem="InstructionsSectionName">
					<br />
					{section}
					<br />
					<br />
				</h5>
				<div block="RecipePage" elem="InstructionsSteps">
					{instructions.map((step, idx) =>
                        this.renderInstruction(
                            step,
                            instructionIdx,
                            idx,
                            instructions.length,
                            methodsLength
                        )
                    )}
				</div>
			</div>
		);
	}

    renderShareDropdown() {
        return <ShareDropdown />;
    }

    renderInstructionsContent() {
        const {
			recipeData: { method = [], notes },
		} = this.props;

		const methods = formatRecipeMethods(method);
		const methodsLength = Object.keys(methods).length;

		if (notes) {
			const noteSection = __("Note");
			methods[noteSection.toString()] = [{ step: notes }];
		}

        if (isHtmlType(method) || isOnlyInstruction(method)) {
            return <Html content={method[0].section} />;
        }

        return (
            <>
                {Object.entries(methods).map((method, idx) =>
                    this.renderInstructionsSection(method, idx, methodsLength)
                )}
            </>
        );
    }

    renderInstructionsBlock(isPrint) {
        const {
            device: { isMobile },
            onPrintClick,
        } = this.props;

		return (
			<section block="RecipePage" elem="InstructionsWrapper">
				<h4
					block="RecipePage"
					elem="InstructionsHeader"
					mix={{ block: "RecipePage", elem: "RecipeDetailsHeader" }}
				>
					{__("Instructions")}
					<div block="RecipePage" elem="PrintAndShare">
						<button
							onClick={onPrintClick}
							block="RecipePage"
							elem="PrintButton"
						>
							{isMobile ? <PrintMobileIcon /> : <PrintIcon />}
						</button>
                        {!isPrint && this.renderShareDropdown()}
					</div>
				</h4>
				{this.renderRecipeValues()}
				<div block="RecipePage" elem="InstructionsList">
                    {this.renderInstructionsContent()}
				</div>
			</section>
		);
	}

	renderRecipeValues() {
		const {
			recipeData: { prep_time = [], cook_time = [] },
		} = this.props;

		const prepData = getCookingTimeObj(prep_time[0]?.data);
		const cookData = getCookingTimeObj(cook_time[0]?.data);
		const prepTime = getCookingString(prepData, FULL_COOKING_TIME_STRING);
		const cookTime = getCookingString(cookData, FULL_COOKING_TIME_STRING);

		return (
			<div block="RecipePage" elem="InstructionsTimeData">
				<span>{`${__("Preparation")} ${prepTime}`}</span>
				<span>{`${__("Cooking")} ${cookTime}`}</span>
			</div>
		);
	}

	renderRecipeDetails() {
		const {
			device: { isMobile },
		} = this.props;

		return (
			<section block="RecipePage" elem="RecipeDetailsWrapper">
				{this.renderShortDescription()}
				<div block="RecipePage" elem="RecipeDetails">
					{this.renderIngredientsBlock()}
					{!isMobile && this.renderInstructionsBlock()}
				</div>
				{isMobile && this.renderInstructionsBlock()}
			</section>
		);
	}

	renderRecipeCard(data) {
		return <RecipeCard {...data} />;
	}

	renderRelatedRecipesBlock() {
		const {
			device: { isMobile },
			activeRecipe,
			relatedRecipesScrollableRef,
			updateActiveRecipe,
			recipeData: { related_recipes: { items = [] } = {} },
		} = this.props;

		if (isMobile) {
			return (
				<ProductSlider
					activeImage={activeRecipe}
					onActiveImageChange={updateActiveRecipe}
					isSliderExist={true}
					containerRef={relatedRecipesScrollableRef}
					showCrumbs={false}
					mix={{
						block: "RecipePage",
						elem: "RelatedRecipesCards",
					}}
				>
					{items.map(this.renderRecipeCard.bind(this))}
				</ProductSlider>
			);
		}

		return (
			<div block="RecipePage" elem="RelatedRecipesCards">
				{items.map(this.renderRecipeCard.bind(this))}
			</div>
		);
	}

	renderRelatedRecipes() {
		const {
			recipeData: { related_recipes: { items = [] } = {} },
		} = this.props;

		if (!items.length) {
			return null;
		}

		return (
			<section block="RecipePage" elem="RelatedRecipesWrapper">
				<div block="RecipePage" elem="RelatedRecipes">
					<h3>{__("Looking for inspiration?")}</h3>
					<p>
						{__(
							"Discover the delicious recipes that we have chosen for you"
						)}
					</p>
					{this.renderRelatedRecipesBlock()}
				</div>
			</section>
		);
	}

	renderFoodPairingBlock() {
		const {
			recipeData: { drinks },
		} = this.props;

		if (!drinks) {
			return null;
		}

		return (
			<div block="RecipePage" elem="FoodPairingBlockWrapper">
				<Html content={drinks} />
			</div>
		);
	}

	renderVideoBlock() {
		const {
			recipeData: { media_type_video_url },
		} = this.props;

        if (!media_type_video_url) {
            return null;
        }

		return (
			<section block="RecipePage" elem="VideoIframe">
				<div>
					<iframe src={media_type_video_url}></iframe>
				</div>
			</section>
		);
	}

	renderContent() {
		const {
			device: { isMobile },
			isRecipesLoading,
		} = this.props;

		if (isRecipesLoading) {
			return (
				<section
					mix={{
						block: "BlogPostPage",
						elem: "PostContentPlaceholders",
					}}
				>
					{new Array(3).fill(null).map(() => (
						<div
							mix={{
								block: "CmsPage",
								elem: "SectionPlaceholder",
							}}
						/>
					))}
				</section>
			);
		}

		return (
			<>
				{this.renderUpperBlock()}
				{!isMobile && this.renderBreadcrumbs()}
				{this.renderRecipeDetails()}
				{this.renderFoodPairingBlock()}
				{this.renderVideoBlock()}
				{this.renderRelatedRecipes()}
				{this.renderIngredientProductsOverlay()}
			</>
		);
	}

	renderPrintableContent() {
		return (
			<div id="RecipePrintableContent">
				<h2 block="RecipePage" elem="AvrilTitle">
					Avril
				</h2>
				{this.renderUpperBlockImg()}
				{this.renderRecipeTitle()}
				<br />
				{this.renderRecipeValues()}
				<br />
				{this.renderIngredientsBlock()}
				{this.renderInstructionsBlock(true)}
			</div>
		);
	}

	renderIngredientProductsOverlay() {
		return <RecipeIngredientProductsOverlay />;
	}

	render() {
		return (
			<>
				<main block="RecipePage" aria-label="Recipe Page">
					{this.renderContent()}
				</main>
				{this.renderPrintableContent()}
			</>
		);
	}
}

export default RecipePage;