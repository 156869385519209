/* eslint-disable quote-props */
import { store } from '@scandipwa/scandipwa/src/store';
import Event, { EVENT_GTM_PURCHASE } from '../../util/Event';

const setDetailsStep = (args, callback, instance) => {
    const [orderID] = args;
    const {
        totals: { items = [], coupon_code }
    } = instance.props;
    const { paymentTotals: totals } = instance.state;
    const chosenTimeslot = store.getState().CartReducer.chosenTimeslot || [];

    Event.dispatch(
        EVENT_GTM_PURCHASE,
        { orderID, totals: { ...totals, items, coupon_code, chosenTimeslot } }
    );

    return callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            'setDetailsStep': setDetailsStep
        }
    }
};