/* eslint-disable quote-props */
import Event, { EVENT_GTM_NO_MATCH } from '../../util/Event';

export const componentDidMount = (args, callback, instance) => {
    const { location: { pathname } } = instance.props;
    Event.dispatch(EVENT_GTM_NO_MATCH, {
        pathname
    });
    return callback(...args);
};

export default {
    'Route/NoMatch/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
