/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Meta as SourceMeta } from 'SourceComponent/Meta/Meta.component'

export class Meta extends SourceMeta {

    state = { title: "" }

    componentDidUpdate() {
        const { title } = this.props
        if (title !== this.state.title) {
            this.setState({ title })
        }
    }
    
    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix
        } = this.props;
        const { title } = this.state

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';
        return (
            <title>
                { `${ titlePrefix }${ title || default_title }${ titleSuffix }` }
            </title>
        );
    }

    renderCanonical() {
        return null;
    }

}

export default Meta;
