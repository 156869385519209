/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.extended.style';

/** @namespace Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    renderSelectedQty() {
        const {
            attributeCode,
            selectedFiltersQty = {}
        } = this.props;

        const qty = selectedFiltersQty[attributeCode];

        if (!qty) {
            return null;
        }

        return (
            <span
              block="ExpandableContent"
              elem="SelectedOptionsQty"
            >
                {qty}
            </span>
        );
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const {
            heading,
            subHeading,
            mix,
        } = this.props;

        return (
            <button
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
            >
                <span
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length="medium" />
                    ) : (
                        heading
                    ) }
                </span>
                <span
                  block="ExpandableContent"
                  elem="SubHeading"
                  mix={ { ...mix, elem: 'ExpandableContentSubHeading' } }
                >
                    { subHeading }
                </span>
                {this.renderSelectedQty()}
            </button>
        );
    }
}
export default ExpandableContent;
