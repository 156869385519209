export const firstLetterToUpperCase = (str) => {
    if (str && typeof str === 'object') {
        str = str.toString();
    }

    if (typeof str !== 'string') {
        return str;
    }

    const lcStr = str.toLowerCase();
    return lcStr[0].toUpperCase() + lcStr.slice(1);
}
