/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

import { OrderQuery as SourceOrderQuery } from 'SourceQuery/Order.query';

/**
 * Order Query
 * @class OrderQuery
 *
 */
export class OrderQuery extends SourceOrderQuery {
    getCancelOrderMutation(orderID) {
        return new Field('cancelOrder')
            .addArgument('order_id', 'String!', orderID);
    }

    getGuestCustomerOrder(field) {
        return  new Field('getGuestCustomerOrder')
            .addArgument("input", 'GetGuestCustomerOrderInput!',{
                billing_last_name: field.billing_last_name,
                order_id:  field.order_id,
                filter:field.filter,
                find_order_by: field.find_order_by
            })
            .addFieldList([
                new Field('order')
                .addFieldList([
                    new Field('base_order_info')
                    .addFieldList([
                        "id",
                        'increment_id',
                        'grand_total',
                        'created_at',
                        'sub_total',
                        'status',
                        'status_label',
                        'total_qty_ordered',
                        'sub_total',
                        this._getFeeFields(),
                        this._getFeeListFields(),
                        this._getTaxesField(),
                        this._getTotalTaxesField(),
                        this._getTaxesField(),
                        this._getUsedCouponField(),
                        this._getUsedAvrilDollarsField(),
                    ]),
                    this._getOrderProductsField(),
                    this._getOrderBillingInfo(),
                    this._getPaymentInfoField(),
                    this._prepareShippingInfo()
                ])
            ])
    }

    _getOrderField(){
        return new Field('billing_info')
            .addField(this._getOrderBillingAddressFields());
    }

    _getOrderItemsFields(isList) {
        return [
            this._getBaseOrderInfoField(isList),
            ...(!isList ? [
                this._getPaymentInfoField(),
                this._prepareShippingInfo(),
                this._getOrderProductsField(),
                this._getOrderBillingInfo()
            ] : [])
        ];
    }

    _getOrderBillingInfo() {
        return new Field('billing_info')
            .addField(this._getOrderBillingAddressFields());
    }

    _getOrderBillingAddressFields() {
        return new Field('billing_address')
            .addFieldList([
                'city',
                'company',
                'country_code',
                'firstname',
                'lastname',
                'postcode',
                'region_id',
                'region',
                'telephone',
                'street'
            ]);
    }

    _getBaseOrderInfoFields(isList) {
        return [
            'id',
            'increment_id',
            'created_at',
            'status_label',
            'status',
            'grand_total',
            this._getFeeFields(),
            this._getFeeListFields(),
            this._getTaxesField(),
            this._getTotalTaxesField(),
            this._getUsedCouponField(),
            this._getUsedAvrilDollarsField(),
            ...(isList ? [] : ['sub_total'])
        ];
    }

    _getFeeFields() {
        return new Field('fee')
            .addFieldList(this._getMoneyFields());
    }

    _getFeeListFields() {
        return new Field('fee_list')
            .addFieldList([
                'name',
                this._getPriceFields()
            ]);
    }

    _getPriceFields() {
        return new Field('price')
            .addFieldList(this._getMoneyFields());
    }

    _getMoneyFields() {
        return [
            'currency',
            'value'
        ];
    }

    _getTaxesField() {
        return new Field('taxes')
            .addFieldList([
                'label',
                this._getAmountField()
            ]);
    }

    _getTotalTaxesField() {
        return new Field('total_tax_amount')
            .addFieldList(this._getCurrencyValueFields());
    }

    _getUsedCouponField() {
        return new Field('used_coupon_code')
            .addFieldList([
                'code',
                this._getAmountField()
            ]);
    }

    _getUsedAvrilDollarsField() {
        return new Field('used_avril_dollars')
            .addFieldList(this._getCurrencyValueFields());
    }

    _getAmountField() {
        return new Field('amount')
            .addFieldList(this._getCurrencyValueFields());
    }

    _getCurrencyValueFields() {
        return [
            'currency',
            'value'
        ];
    }
    
    _getDefaultFields() {
        return [
            'id',
            'name',
            (new Field('short_description').addField('html')),
            'sku',
            'qty',
            'row_total',
            'original_price',
            'license_key',
            this._getPriceRange()

        ];
    }

    _getPriceRange(){
        return new Field('price_range')
        .addFieldList([
            this._getPriceRangeField()
        ])
    }

    _getPriceRangeField(){
        return new Field('minimum_price')
        .addFieldList([
            this._getFinalPriceField(),
        ])
    }

    _getFinalPriceField(){
        return new Field('final_price')
        .addFieldList([
            'currency',
            'value'
        ])
    }

    _getSlotsField() {
        return new Field('slots')
            .addFieldList([
                'id',
                'time',
                'time_value',
                'is_available'
            ]);
    }

    _getSlotField() {
        return new Field('delivery_slot')
            .addFieldList([
                'day',
                'date',
                this._getSlotsField()
            ]);
    }

    _prepareShippingInfoFields() {
        return [
            'shipping_method',
            'shipping_description',
            'shipping_amount',
            'tracking_numbers',
            this._getSlotField(),
            this._prepareOrderCustomerAddressInfo()
        ];
    }
}

export default new OrderQuery();
