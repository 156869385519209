import { PureComponent } from "react";

import ContentWrapper from "Component/ContentWrapper";
import RecipeCard from "Component/RecipeCard";
import RecipePagination from "Component/RecipePagination";
import RecipeCmsBanner from "Component/RecipeCmsBanner";

import RecipeCategoriesNav from "Component/RecipeCategoriesNav";

import "Route/BlogPage/BlogPage.style";
import "./RecipeCategoryPage.style";

/** @namespace Route/RecipeCategoryPage/Component */
export class RecipeCategoryPage extends PureComponent {
	renderSingleRecipe = (recipeData, isPlaceholder) => {
		const {
			device: { isMobile },
		} = this.props;

		return (
			<RecipeCard
				{...recipeData}
				mix={{ block: "RecipeCategoryPage", elem: "RecipeCard" }}
				isPlaceholder={isPlaceholder}
				isWithoutCategory={!isMobile}
				isDescriptionHidden={true}
			/>
		);
	};

	renderRecipes() {
		const {
			recipes: { items = [] },
			isRecipesLoading,
			recipesPerPage,
		} = this.props;

		if (isRecipesLoading) {
			return new Array(Number(recipesPerPage) || 16)
				.fill(null)
				.map(this.renderSingleRecipe.bind(this, [], true));
		}

		return <>{items.map((data) => this.renderSingleRecipe(data, false))}</>;
	}

	renderRecipesWrapper() {
		return (
			<div
				block="RecipeCategoryPage"
				elem="PostsWrapper"
				mix={{ block: "BlogPage", elem: "PostsWrapper" }}
			>
				{this.renderRecipes()}
			</div>
		);
	}

	renderPagination() {
		const { category_id } = this.props;

		return (
			<div block="BlogPage" elem="Pagination">
				<RecipePagination
					pageUrlKey="page"
					category_id={category_id}
				/>
			</div>
		);
	}

	renderMobileBanner() {
		const {
			device: { isMobile },
		} = this.props;

		if (!isMobile) {
			return null;
		}

		return (
			<RecipeCmsBanner
				mix={{ block: "BlogPage", elem: "MobileCmsBanner" }}
			/>
		);
	}

	renderCategoriesNav() {
		return <RecipeCategoriesNav />;
	}

	renderMainContent() {
		const {
			device: { isMobile },
		} = this.props;

		return (
			<section
				block="RecipeCategoryPage"
				elem="MainContent"
				mix={{ block: "BlogPage", elem: "MainContent" }}
			>
				{this.renderMobileBanner()}
				{isMobile && this.renderCategoriesNav()}
				{this.renderRecipesWrapper()}
				{this.renderPagination()}
			</section>
		);
	}

	render() {
		const {
			device: { isMobile },
		} = this.props;

		return (
			<main
				block="RecipeCategoryPage"
				mix={{ block: "BlogPage" }}
				aria-label="Recipes Page"
			>
				<ContentWrapper
					wrapperMix={{
						block: "RecipeCategoryPage",
						elem: "Wrapper",
						mix: { block: "BlogPage", elem: "Wrapper" },
					}}
					label="Recipes category page"
				>
					{!isMobile && this.renderCategoriesNav()}
					<div
						block="RecipeCategoryPage"
						elem="Content"
						mix={{ block: "BlogPage", elem: "Content" }}
					>
						{this.renderMainContent()}
					</div>
				</ContentWrapper>
			</main>
		);
	}
}

export default RecipeCategoryPage;
