const addAmastyProductsConfig = (args, callback) => {
    return [
        ...callback(...args),
        'amasty_xsearch_recently_viewed_enabled'
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': addAmastyProductsConfig,
        }
    }
};
