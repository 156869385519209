/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import Breadcrumbs from './Breadcrumbs.component';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';


export const mapStateToProps = (state) => ({
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs,
    areBreadcrumbsVisible: state.BreadcrumbsReducer.areBreadcrumbsVisible,
    navigation: state.NavigationReducer[TOP_NAVIGATION_TYPE],
    typePage: state.UrlRewritesReducer.urlRewrite.type,
    product: state.ProductReducer.product,
    device: state.ConfigReducer.device,
    recipesPageRoute: state.ConfigReducer.mageside_recipe_seo_route,
    blogSettings: state.BlogReducer.blogSettings,
    currentCategoryData: state.RecipeReducer.currentCategoryData,
});

/** @namespace Component/Breadcrumbs/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
