import {
    SliderQuery as SourceSliderQuery,
} from 'SourceQuery/Slider.query';

export class SliderQuery extends SourceSliderQuery {
    _getSliderFields() {
        return [
            this._getSlidesField(),
            this._getSlideSpeedField(),
            'slider_id',
            'title',
            // 'is_infinite'
        ];
    }

    _getSlideFields() {
        return [
            'slide_text',
            'slide_link',
            'embed_code',
            'slide_id',
            'mobile_image',
            'desktop_image',
            'title',
            'is_active'
        ];
    }
};

export default new SliderQuery();
