/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    CheckoutDeliveryOptionsContainer as SourceCheckoutDeliveryOptionsContainer
} from 'SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.container';

export const mapStateToProps = (state) => ({
    selectedShippingMethod: state.CartReducer.selectedShippingMethod
});

export const mapDispatchToProps = () => ({});

/** @namespace Component/CheckoutDeliveryOptions/Container */
export class CheckoutDeliveryOptionsContainer extends SourceCheckoutDeliveryOptionsContainer {
    static _getDefaultMethod(props) {
        const { shippingMethods, selectedShippingMethod } = props;

        if (Object.keys(selectedShippingMethod).length) {
            const { method_code } = selectedShippingMethod;
            return method_code;
        }

        const [{ method_code } = [{}]] = shippingMethods;
        return method_code;
    }


    static getDerivedStateFromProps(props, state) {
        const { shippingMethods } = props;
        const { prevShippingMethods } = state;

        if (shippingMethods.length !== prevShippingMethods.length) {
            const selectedShippingMethodCode = CheckoutDeliveryOptionsContainer._getDefaultMethod(props);

            return {
                selectedShippingMethodCode,
                prevShippingMethods: shippingMethods
            };
        }

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOptionsContainer);
