/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';
import ClickOutside from 'Component/ClickOutside';
import TextPlaceholder from 'Component/TextPlaceholder';

import './ProductConfigurableAttributes.extended.style';

export class ProductConfigurableAttributes extends SourceProductConfigurableAttributes {
    render() {
        const { isReady, mix, onFilterClick } = this.props;

        return (
            <ClickOutside
              onClick={ onFilterClick }
              key={ 'category-filters' }
            >
                <div
                  block="ProductConfigurableAttributes"
                  mods={ { isLoading: !isReady } }
                  mix={ mix }
                >
                    { isReady ? this.renderConfigurableAttributes() : <TextPlaceholder length="long" /> }
                </div>
            </ClickOutside>
        );
    }
}

export default ProductConfigurableAttributes;
