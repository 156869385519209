/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';

import { SliderWidgetContainer } from 'SourceComponent/SliderWidget/SliderWidget.container';

import InfiniteSliderWidget from './InfiniteSliderWidget.component';

/** @namespace Component/InfiniteSliderWidgetContainer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Component/InfiniteSliderWidgetContainer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, title, error) => dispatch(showNotification(type, title, error))
});

/** @namespace Component/InfiniteSliderWidgetContainer/Container */
export class InfiniteSliderWidgetContainer extends SliderWidgetContainer {
    render() {
        return (
            <InfiniteSliderWidget
                { ...this.props }
                { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InfiniteSliderWidgetContainer);
