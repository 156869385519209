/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ExpandableContent from "Component/ExpandableContent";
import ExpandableContentShowMore from "Component/ExpandableContentShowMore";
import { formatPrice } from "Util/Price";
// eslint-disable-next-line max-len
import { CategoryConfigurableAttributes as SourceCategoryConfigurableAttributes } from "SourceComponent/CategoryConfigurableAttributes/CategoryConfigurableAttributes.component";

export class CategoryConfigurableAttributes extends SourceCategoryConfigurableAttributes {
    getPriceLabel(option) {
        const { storeCode } = this.props;
        const { value_string } = option;
        const [from, to] = value_string.split("_");
        const priceFrom = formatPrice(from || 0, storeCode);
        const priceTo = formatPrice(to, storeCode);

        if (from === "*") {
            return __("Up to %s", priceTo);
        }

        if (to === "*") {
            return __("From %s", priceFrom);
        }

        return __("From %s, to %s", priceFrom, priceTo);
    }

    renderPriceSwatch(option) {
        const { attribute_options, ...priceOption } = option;
        if (attribute_options) {
            // do not render price filter if it includes "*_*" aggregation
            if (attribute_options["*_*"]) {
                return null;
            }

            priceOption.attribute_options = Object.entries(
                attribute_options
            ).reduce((acc, [key, option]) => {
                acc[key] = {
                    ...option,
                    label: this.getPriceLabel(option),
                };

                return acc;
            }, {});
        }
        return this.renderDropdownOrSwatch(priceOption);
    }

    renderDropdownOrSwatch(option) {
        const {
            getSubHeading,
            getIsFilterSelected,
            onFilterClick,
            expandedFilterCode,
            getRightAttrLabel,
            selectedFiltersQty
        } = this.props;

        const { attribute_label, attribute_code, attribute_options } = option;

        const isFilterSelected = getIsFilterSelected(option);
        const isContentExpanded = expandedFilterCode === attribute_code;
        const [{ swatch_data }] = Object.values(attribute_options).length > 0
            ? Object.values(attribute_options)
            : [{}];
        const isSwatch = !!swatch_data;
        const correctFormLabel = getRightAttrLabel(attribute_label);

        return (
            <ExpandableContent
                key={attribute_code}
                attributeCode={attribute_code}
                heading={correctFormLabel}
                subHeading={getSubHeading(option)}
                selectedFiltersQty={selectedFiltersQty}
                mix={{
                    block: "ProductConfigurableAttributes",
                    elem: "Expandable",
                    mods: { isFilterSelected },
                }}
                onClick={() => onFilterClick(attribute_code)}
                isContentExpanded={isContentExpanded}
            >
                {isSwatch
                    ? this.renderSwatch(option)
                    : this.renderDropdown(option)}
            </ExpandableContent>
        );
    }

    renderDropDownContent(option) {
        const { attribute_values } = option;
        const { isWithoutShowMore = false } = this.props;

        if (isWithoutShowMore) {
            return (
                <>
                    {attribute_values.map((attribute_value) =>
                        this.renderConfigurableAttributeValue({
                            ...option,
                            attribute_value,
                        })
                    )}
                </>
            );
        }

        return (
            <ExpandableContentShowMore>
                {attribute_values.map((attribute_value) =>
                    this.renderConfigurableAttributeValue({
                        ...option,
                        attribute_value,
                    })
                )}
            </ExpandableContentShowMore>
        );
    }

    renderDropdown(option) {
        return (
            <div block="ProductConfigurableAttributes" elem="DropDownList">
                {this.renderDropDownContent(option)}
            </div>
        );
    }
}

export default CategoryConfigurableAttributes;
