/**
 * @callback MemoizedValue
 * @template T
 * @template P
 * @param {function(): T} expensiveCall
 * @param {P} expensive function name
 * @return T
 */

/**
 * Creates a memoized container
 *
 * @return { MemoizedValue }
 */
export function createMemo() {
    const memo = {
        isAvailable: false,
        dependencies: undefined,
        value: undefined
    };

    return (fallback, dependencies) => {
        if (memo.isAvailable && dependencies === memo.dependencies) {
            return memo.value;
        }

        memo.isAvailable = true;
        memo.dependencies = dependencies;
        memo.value = fallback();
        return memo.value;
    }
}
