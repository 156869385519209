/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Field from 'Component/Field';
import Form from 'Component/Form';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import {
    MyAccountSignIn as SourceMyAccountSignIn
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';

import { appendWithStoreCode } from 'Util/Url';
import './MyAccountSignIn.extended.style';

/** @namespace AvrilPwa-App/Component/MyAccountSignIn/Component/MyAccountSignInComponent */
export class MyAccountSignInComponent extends SourceMyAccountSignIn {
    renderSignInForm() {
        const {
            onSignInAttempt,
            onSignInSuccess,
            onFormError,
            emailValue,
            handleEmailInput,
            isLoading,
            isCheckout
        } = this.props;

        const passwordValidation = isCheckout ? ['password'] : ['notEmpty', 'password'];

        return (
            <>
              <Loader isLoading={ isLoading } />
              <Form
                key="sign-in"
                onSubmit={ onSignInAttempt }
                onSubmitSuccess={ onSignInSuccess }
                onSubmitError={ onFormError }
              >
                  { !isCheckout && this.renderMyAccountBlock() }
                  <Field
                    type="text"
                    label={ __('Email') }
                    id="email"
                    name="email"
                    value={ emailValue }
                    autocomplete="email"
                    validation={ ['notEmpty', 'email'] }
                    onChange={ handleEmailInput }
                  />
                  <Field
                    type="password"
                    label={ __('Password') }
                    id="password"
                    name="password"
                    autocomplete="current-password"
                    validation={ passwordValidation }
                  />
                  { this.renderSignInActions() }
              </Form>
            </>
        );
    }

    renderMyAccountBlock() {
        return (
            <>
              <legend>{ __('My Account') }</legend>
              <a block="MyAccountSignIn-CMSButton" mods={{ hideMobile: true }} href={appendWithStoreCode('/registration')}>{__('Créer un compte')}</a>
              <p block="MyAccountSignIn" elem="GreyText">
                { __('If you already have an account, login directly with your email.') }
              </p>
            </>
        );
    }

    renderSignInActions() {
        const {
            isCheckout
        } = this.props;

        return (
        <div block="MyAccountSignIn" elem="ButtonsWrapper" mods={ { isCheckout } }>
          <div block="MyAccountOverlay" elem="Buttons">
            <button
              block="Button"
              elem="Important"
              mods={ { SignIn: true } }
            >
                { __('SignIn') }
            </button>
          </div>
          { this.renderForgotPasswordButton() }
        </div>
        );
    }

    renderForgotPasswordButton() {
        return (
            <Link to={ appendWithStoreCode('/forgotpassword') }>
              <button
                block="Button"
                mods={{ likeLink: true, darkLink: true }}
              >
                {__('Forgot password?')}
              </button>
            </Link>
        );
    }

    renderAdditionalField() {
        return null;
    }
}

export default MyAccountSignInComponent;
 