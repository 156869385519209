import MenuItem from 'Component/MenuItem';
import Link from 'Component/Link';
import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';
import ClickOutside from 'Component/ClickOutside';
import CmsBlock from 'Component/CmsBlock';
import './Menu.override.style.scss';
import StoreSwitcher from 'Component/StoreSwitcher'
import { ReactComponent as BackIcon } from './icons/left.svg';
import { appendWithStoreCode } from 'Util/Url';
import { ReactComponent as MyAccountIcon } from './icons/myAccount.svg';

export class Menu extends SourceMenu {
    state = {
        isSubMenuShow: false,
        activeLink: "",
        location: "",
        shopOnlineSubCategory: [],
        myAccountData: [],
        isSignIn: false,
        menuField: []
    }

    static getDerivedStateFromProps(props, state) {
        if (state.location !== props.locations) {
            window.scrollTo(0, 0)
            return ({ isSubMenuShow: false, activeLink: "", location: props.locations })
        }
        return null
    }

    componentDidMount() {
        const { isSignedIn } = this.props;
        this.fillMyAccountField(isSignedIn)
    }

    componentDidUpdate() {
        const { device, allCategory, allMenu, isSignedIn } = this.props;
        const { shopOnlineSubCategory, isSignIn } = this.state
        if (isSignIn !== isSignedIn) {
            this.fillMyAccountField(isSignedIn)
        }
        if (device.isMobile && shopOnlineSubCategory !== allCategory) {
            if (!!allMenu.length) {
                let shopOnline = allMenu.find(e => e.shop_menu_item);
                if(shopOnline){
                    shopOnline.children = [allCategory]
                    shopOnline.isCategory = true;
                }
                this.setState({ shopOnlineSubCategory: allCategory })
            }
        }
    }

    fillMyAccountField(isSignedIn) {
        if (isSignedIn) {
            this.setState({
                myAccountData: [
                    {
                        url: appendWithStoreCode('/my-account/'),
                        title: __('Dashboard')
                    },
                    {
                        url: appendWithStoreCode('/my-account/my-orders'),
                        title: __('My orders')
                    },
                    {
                        url: appendWithStoreCode('/my-account/loyalty-card'),
                        title: __('Loyalty page')
                    },
                    {
                        url: appendWithStoreCode('/my-account/my-wishlist'),
                        title: __('Wishlist')
                    },
                    {
                        url: appendWithStoreCode('/'),
                        title: __('Logout'),
                        isLogout: true
                    }
                ],
                isSignIn: isSignedIn
            })

        } else {
            this.setState({
                myAccountData: [
                    {
                        url: appendWithStoreCode('/login'),
                        title: __('Login')
                    },
                    {
                        url: appendWithStoreCode('/registration/'),
                        title: __('Create an account')
                    },
                    {
                        url: appendWithStoreCode('/login'),
                        title: __('Wishlist')
                    }
                ],
                isSignIn: isSignedIn
            })
        }
    }


    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu,
            device,
            onCategoryClick,
            showMobileMenu,
            logout,
            closeMobileMenu,
            isEcom
        } = this.props;
        const { isSubMenuShow, activeLink, isSignIn, shopOnlineSubCategory } = this.state
        const { children, isCategory } = item;
        const itemMods = { type: 'main' };
        if (children.length && device.isMobile) {
            return (
                <div
                    tabIndex="0"
                    block="Menu"
                    elem="SubCatLink"
                    role="button"
                    mods={{ isCategory: !!isCategory }}
                    mix={{ block: "Menu", elem: "isHasChildren" }}
                    onClick={() => this.setState({ activeLink: item.title })}
                >
                    <MenuItem
                        activeMenuItemsStack={activeMenuItemsStack}
                        item={item}
                        itemMods={itemMods}
                        activeLink={activeLink}
                        onCategoryHover={onCategoryHover}
                        closeMenu={closeMenu}
                        device={device}
                        showMobileMenu={showMobileMenu}
                        logout={logout}
                        closeMobileMenu={closeMobileMenu}
                        isSignedIn={isSignIn}
                        shopOnlineSubCategory={shopOnlineSubCategory}
                        isEcom={isEcom}
                        renderShippingCms={this.renderShippingCms.bind(this)}
                    />
                </div>
            );
        }
        return (
            <ClickOutside onClick={() => { activeLink == item.title ? this.setState({ activeLink: "" }) : null }}>
                <div block="Menu" elem="ItemWrapper"
                    mods={{ isActive: location.pathname == item.url }}
                    onClick={() => this.setState({ activeLink: item.title })}
                >
                    <MenuItem
                        activeMenuItemsStack={activeMenuItemsStack}
                        item={item}
                        itemMods={itemMods}
                        activeLink={activeLink}
                        onCategoryHover={onCategoryHover}
                        closeMenu={closeMenu}
                        isLink
                        isSubMenuShow={isSubMenuShow}
                        onCategoryClick={onCategoryClick}
                        closeMobileMenu={closeMobileMenu}
                    />
                </div>
            </ClickOutside>
        );
    }

    renderSubMenuDesktop(itemList) {
        const { device } = this.props;
        if (device.isMobile || !itemList.length) {
            return null;
        }

        return itemList.map(this.renderSubMenuDesktopItems);
    }

    renderTopLevelMobile() {
        const { myAccountData, menuField } = this.state
        const { allMenu } = this.props;
        const myAccount = {
            url: appendWithStoreCode('/my-account/'),
            title: __('My account'),
            children: myAccountData,
            icon: <MyAccountIcon />,
            isAccount: true
        }
        if (!!!menuField.find(e => !!e.isAccount) || myAccountData.length !== menuField.find(e => !!e.isAccount).children.length) {
            if (menuField.findIndex(e => !!e.isAccount) === -1) {
                allMenu.splice(1, 0, myAccount)
            } else {
                allMenu.splice(menuField.findIndex(e => !!e.isAccount), 1)
            }
            this.setState({ menuField: allMenu })
        }
        return (
            <>
                <div block="Menu" elem="MainCategories">
                    <ul
                        block="Menu"
                        elem="ItemList"
                        mods={{ type: 'main' }}
                        aria-label={"Parent"}
                    >
                        {menuField.map(this.renderFirstLevel)}
                    </ul>
                </div>
            </>
        );
    }

    renderTopLevel() {
        const { allMenu, device } = this.props;

        if (!allMenu.length) {
            return null
        }
        if (device.isMobile) {
            return this.renderTopLevelMobile()
        }
        return (
            <>
                <div block="Menu" elem="MainCategories">
                    <ul
                        block="Menu"
                        elem="ItemList"
                        mods={{ type: 'main' }}
                        aria-label={"Parent"}
                    >
                        {allMenu.map(this.renderFirstLevel)}
                    </ul>
                </div>
            </>
        );
    }

    renderFirstLevel = (item) => {
        const { item_id, isCategory } = item;
        const { activeLink } = this.state;
        const {
            isEcom,
            closeMobileMenu,
            device: { isMobile }
        } = this.props;

        if (isEcom && (!isCategory && isMobile)) {
            return <Link
                to={item.url}
                key={item_id}
                block="Menu"
                elem="Item"
                mods={{ isLink: true }}
                onClick={() => !!closeMobileMenu ? closeMobileMenu() : null}
            >
                {this.renderFirstLevelItems(item)}
            </Link>
        }

        return (
            <li key={item_id} block="Menu" elem="Item" mods={{ isActive: item.title === activeLink }}>
                { this.renderFirstLevelItems(item)}
            </li>
        );
    };

    renderOverlay() {
        const { activeLink } = this.state
        const { allMenu } = this.props
        const activeItem = allMenu.find(e => e.title === activeLink)
        const isShow = !!activeItem ? !!activeItem.children.length : false

        return (
            <div
                block="darkOverlay"
                mods={{ isActive: activeLink !== "" && isShow }}
            >
            </div>
        )
    }

    renderShippingCms() {
        return <CmsBlock identifier={'header_shipping_text'} />
    }

    renderMobileMenu() {
        const {
            showMobileMenu,
            closeMobileMenu,
            isDisclaimerHidden,
            menuRef
        } = this.props;

        return (
            <div
                block="Menu"
                elem="MenuWrapper"
                ref={ menuRef }
                mods={{ showMobileMenu, withDisclaimer: !isDisclaimerHidden && document.body.style.marginTop === "0px" }}
                onClick={(e) => { e.target === e.currentTarget ? closeMobileMenu() : null }}
            >
                {this.renderTopLevel()}
                <div
                    block="Menu"
                    elem="bottomBlock"
                >
                    {this.renderShippingCms()}
                    <StoreSwitcher />
                </div>
            </div>
        )
    }

    render() {
        const { device } = this.props;
        const { closeMenu } = this.props;

        if (device.isMobile) {
            return this.renderMobileMenu()
        }
        
        return (
            <div
                block="Menu"
                elem="MenuWrapper"
                onMouseLeave={closeMenu}
            >
                {this.renderTopLevel()}
                {this.renderOverlay()}
            </div>
        );
    }
}

export default Menu;
