import { connect } from 'react-redux';

import BlogCategoriesWidget from './BlogCategoriesWidget.component';

export const BlogDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Blog/Blog.dispatcher'
);

export const mapStateToProps = (state) => ({
    blogCategories: state.BlogReducer.blogCategories,
    isBlogLoading: state.BlogReducer.isBlogLoading,
    blogSettings: state.BlogReducer.blogSettings,
    device: state.ConfigReducer.device,
});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BlogCategoriesWidget);
