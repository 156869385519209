import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Popup from 'Component/Popup';
import CmsBlock from 'Component/CmsBlock';
import Loader from 'Component/Loader';

import { PRODUCT_AVAILABILITY_POPUP_ID } from './ProductAvailabilityPopup.config';

import './ProductAvailabilityPopup.style';

export const mapStateToProps = (state) => ({
    productAvailabilityActivePopupId: state.ProductReducer.productAvailabilityActivePopupId
});

export const mapDispatchToProps = (dispatch) => ({});


export class ProductAvailabilityPopup extends PureComponent {
    state = {
        isLoading: false
    };

    setIsLoading(isLoading) {
        this.setState({ isLoading });
    }

    renderContent() {
        const { productAvailabilityActivePopupId } = this.props;

        return <CmsBlock
          identifier={ productAvailabilityActivePopupId }
          setIsLoading={ this.setIsLoading.bind(this) }
        />;
    }

    render() {
        const { isLoading } = this.state;

        const isPopupRendered = document.querySelectorAll('.ProductAvailabilityPopup-Wrapper');

        if (isPopupRendered.length > 1) {
            return null;
        }

        return (
            <Popup
              id={ PRODUCT_AVAILABILITY_POPUP_ID }
              mix={ { block: 'ProductAvailabilityPopup' } }
            >
                <Loader isLoading={ isLoading } />
                <div
                  block="ProductAvailabilityPopup"
                  elem="Wrapper"
                >
                    { this.renderContent() }
                </div>
            </Popup>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAvailabilityPopup);
