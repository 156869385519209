/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { withRouter } from 'react-router-dom';

import Loader from 'Component/Loader';
import Overlay from 'Component/Overlay';

import {
    CUSTOMER_ACCOUNT_OVERLAY_KEY,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';

import {
    MyAccountOverlay as SourceMyAccountOverlayComponent
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.component';

import './MyAccountOverlay.override.style.scss';

export class MyAccountOverlay extends SourceMyAccountOverlayComponent {

    renderMyAccount() {
        const { visibleForm } = this.props;

        if (!visibleForm) {
            return null;
        }

        const { render, title } = this.renderMap[visibleForm];

        return (
            <div block="MyAccountOverlay" elem="Action" mods={ { visibleForm } }>
                <p block="MyAccountOverlay" elem="Heading">{ title }</p>
                { render() }
            </div>
        );
    }

    render() {
        const {
            isLoading,
            onVisible,
        } = this.props;

        return (
            <Overlay
              id={ CUSTOMER_ACCOUNT_OVERLAY_KEY }
              mix={ { block: 'MyAccountOverlay' } }
              onVisible={ onVisible }
              activeOverlay="customer_account"
              isStatic
            >
                <Loader isLoading={ isLoading } />
                { this.renderMyAccount() }
            </Overlay>
        );
    }
}

export default withRouter(
    MyAccountOverlay
);
