/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from "react-redux";

import {
    NavigationTabsContainer,
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps,
} from "SourceComponent/NavigationTabs/NavigationTabs.container";

import { MENU_TAB } from "./NavigationTabs.config";

/** @namespace Component/NavigationTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    activeOverlay: state.OverlayReducer.activeOverlay,
});

export const DEFAULT_NAVIGATION_TABS_STATE = { name: MENU_TAB };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationTabsContainer);
