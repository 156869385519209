
import PropTypes from 'prop-types';
import { MixType } from 'SourceType/Common';
import { ProductType } from 'SourceType/ProductList';
import { AddToCart as SourceAddToCart} from "SourceComponent/AddToCart/AddToCart.component"
import './AddToCart.style';
import { ReactComponent as AddIcon } from './images/icons.svg';
import TextPlaceholder from 'Component/TextPlaceholder';

export class AddToCart extends SourceAddToCart {
    static propTypes = {
        isLoading: PropTypes.bool,
        product: ProductType,
        mix: MixType,
        buttonClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        product: {},
        mix: {},
        isLoading: false
    };

    renderPlaceholder() {
        const { isLoading, mix } = this.props;

        return (
            <div
              block="AddToCart"
              mods={ { isLoading, isPlaceholder: true } }
              mix={ mix }
            />
        );
    }

    renderTextButton(isDisabled, isDeliverable, pickup_location_code) {
        const { isProductLoading, isAppInitialized } = this.props;

        const isPlaceholder = isProductLoading || !isAppInitialized;

        if (isPlaceholder) {
            return <TextPlaceholder length="short" mix={ { block: "AddToCart", elem: "BtnTextPlaceholder" } } />;
        }

        if (isDisabled) {
            if (pickup_location_code == 'default') {
                if(isDeliverable == 1) {
                    return (
                        <span><AddIcon />{__('Out of stock')}</span>
                    )
                } else {
                    return (
                        <span><AddIcon />{__('In store only')}</span>
                    )
                }
            } else {
                return (
                    <span><AddIcon />{__('Out of stock')}</span>
                )
            }
        }
        return (
            <>
                <span><AddIcon />{__('Add to cart')}</span>
                <span><AddIcon />{__('Adding...')}</span>
            </>
        )
    }


    render() {
        const {
            mix,
            wrapperMix,
            totals: { items = [] },
            product: {
                sku,
                type_id,
                is_deliverable,
                shipments: {
                    is_saleable
                } = {}
            },
            isLoading,
            buttonClick,
            isProductLoading,
            isAppInitialized,
            pickup_location_code
        } = this.props;

        const isDeliverable = is_deliverable;
        const isDisabled = !is_saleable || isProductLoading || !isAppInitialized;
        const qtyInCart = items.filter(({ sku: cartProductSku }) => cartProductSku === sku)[0]?.qty;

        if (!type_id) {
            this.renderPlaceholder();
        }

        if (mix.block === 'ProductCard') {
            return (
                // Wrapped a button to stop propagation for disabled button too
                <div
                  block="AddToCart"
                  elem="BtnWrapper"
                  mix={ wrapperMix }
                  onClick={ buttonClick }
                >
                    <button
                      block="AddToCart"
                      mix={ mix }
                      mods={ { isLoading, isDisabled } }
                      disabled={ isDisabled || isLoading }
                    >
                        { qtyInCart || <AddIcon/> }
                    </button>
                </div>
            )
        }

        return (
            <button
                onClick={buttonClick}
                block="Button AddToCart"
                mix={ mix }
                mods={ {
                    isLoading,
                    outOfStock: isDisabled,
                    inStoreOnly: !isDeliverable,
                    isFirstLoad: isDeliverable === undefined || isDeliverable === null
                } }
                disabled={ isDisabled || isLoading }
            >
                { this.renderTextButton(isDisabled, isDeliverable, pickup_location_code) }
            </button>
        );
    }
}

export default AddToCart;
