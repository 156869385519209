/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { CategoryConfigurableAttributesContainer as SourceCategoryConfigurableAttributesContainer } from "SourceComponent/CategoryConfigurableAttributes/CategoryConfigurableAttributes.container";

import CategoryConfigurableAttributes from "./CategoryConfigurableAttributes.component";

export const mapStateToProps = (state) => ({
	storeCode: state.ConfigReducer.code,
	device: state.ConfigReducer.device,
});

/** @namespace Component/CategoryConfigurableAttributes/Container */
export class CategoryConfigurableAttributesContainer extends SourceCategoryConfigurableAttributesContainer {
	state = {
		expandedFilterCode: "",
		selectedFiltersQty: {},
	};

	componentDidMount() {
		const { configurable_options } = this.props;

		if (configurable_options) {
			this.getSelectedQty();
		}
	}

	componentDidUpdate(prevProps) {
		const {
			configurable_options,
			location: { search },
		} = this.props;
		const {
			configurable_options: prevConfigurableOptions,
			location: { search: prevSearch },
		} = prevProps;

		if (
			JSON.stringify(configurable_options) !==
			JSON.stringify(prevConfigurableOptions) ||
            search !== prevSearch
		) {
			this.getSelectedQty();
		}
	}

	containerFunctions = {
		handleOptionClick: this.handleOptionClick.bind(this),
		getSubHeading: this.getSubHeading.bind(this),
		isSelected: this.isSelected.bind(this),
		getLink: this.getLink.bind(this),
		getIsFilterSelected: this.getIsFilterSelected.bind(this),
		onFilterClick: this.onFilterClick.bind(this),
		getRightAttrLabel: this.getRightAttrLabel.bind(this),
	};

	onFilterClick(expandedFilterCode) {
		const { expandedFilterCode: currentExpandedFilterCode } = this.state;

		if (
			!expandedFilterCode ||
			expandedFilterCode === currentExpandedFilterCode
		) {
			this.setState({ expandedFilterCode: "" });
			return;
		}

		this.setState({ expandedFilterCode });
	}

	getRightAttrLabel(attrLabel) {
		let rightAttrLabel = attrLabel;

		if (attrLabel === "Price") {
			rightAttrLabel = __("Price");
		} else if (attrLabel === "Category") {
			rightAttrLabel = __("Category");
		}

		return rightAttrLabel.split("_").join(" ");
	}

	getIsFilterSelected({ attribute_values, attribute_code }) {
		return attribute_values.some((attribute_value) => {
			return this.isSelected({ attribute_code, attribute_value });
		});
	}

	getSelectedQty() {
		const { configurable_options = {} } = this.props;

		const selectedFiltersQty = Object.entries(configurable_options).reduce(
			(acc, [attribute_code, { attribute_values }], i) => {
				attribute_values.forEach((attribute_value) => {
					if (this.isSelected({ attribute_code, attribute_value })) {
						acc[attribute_code] = acc[attribute_code] + 1 || 1;
					}
				});

				return acc;
			},
			{}
		);

		this.setState({ selectedFiltersQty });
	}

	render() {
		return (
			<CategoryConfigurableAttributes
				{...this.props}
				{...this.state}
				{...this.containerFunctions}
			/>
		);
	}
}

/** @namespace Component/CategoryConfigurableAttributes/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CategoryConfigurableAttributesContainer)
);
