/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isBlockQueryNeeded, getRejectedPromise } from './AuthCheckHelper';

import { hash } from 'SourceUtil/Request/Hash';

import {
    GRAPHQL_URI,
    HTTP_201_CREATED,
    HTTP_410_GONE,
    appendTokenToHeaders,
    checkForErrors,
    debounce,
    formatURI,
    handleConnectionError,
    listenForBroadCast,
    parseResponse,
    getStoreCodePath,
} from 'SourceUtil/Request/Request';

export {
    GRAPHQL_URI,
    HTTP_201_CREATED,
    HTTP_410_GONE,
    appendTokenToHeaders,
    checkForErrors,
    debounce,
    formatURI,
    getStoreCodePath,
    handleConnectionError,
    listenForBroadCast,
    parseResponse,
};

/**
 *
 * @param {String} uri
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Util/Request/getFetch
 */
const getFetch = (uri, name, forceCache = false) => fetch(uri,
    {
        method: 'GET',
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            'Application-Model': name,
            Accept: 'application/json',
            'Force-Cache': forceCache ? true : false
        })
    });

/**
 *
 * @param {String} graphQlURI
 * @param {{}} query Request body
 * @param {Int} cacheTTL
 * @namespace Util/Request/putPersistedQuery
 */
 export const putPersistedQuery = (graphQlURI, query, cacheTTL) => fetch(`${ graphQlURI }?hash=${ hash(query) }`,
 {
     method: 'PUT',
     body: JSON.stringify(query),
     headers: {
         'Content-Type': 'application/json',
         'SW-Cache-Age': cacheTTL
     }
 });
/**
*
* @param {String} graphQlURI
* @param {String} queryObject
* @param {String} name
* @returns {Promise<Response>}
* @namespace Util/Request/postFetch
*/
export const postFetch = (graphQlURI, query, variables) => fetch(graphQlURI,
 {
     method: 'POST',
     body: JSON.stringify({ query, variables }),
     headers: appendTokenToHeaders({
         'Content-Type': 'application/json',
         Accept: 'application/json'
     })
 });
/** @namespace Util/Request/getGraphqlEndpoint */
export const getGraphqlEndpoint = () => {
    const graphqlURI = getStoreCodePath().concat(GRAPHQL_URI);
    const baseURL = `${process.env.REACT_APP_BASE_URL}${graphqlURI}`;
    return baseURL;
}

/**
 * Make GET request to endpoint (via ServiceWorker)
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @param  {Number} cacheTTL Cache TTL (in seconds) for ServiceWorker to cache responses
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Util/Request/executeGet
 */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    if (isBlockQueryNeeded(query)) {
        return getRejectedPromise();
    }

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace Util/Request/getFetchThen */
            (res) => {
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace Util/Request/putPersistedQueryThen */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace Util/Request/putResponseGetFetchThen */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else {
                    resolve(res);
                }
            }
        );
    }));
};

/**
 * Make POST request to endpoint
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Util/Request/executePost
 */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;

    if (isBlockQueryNeeded(query)) {
        return getRejectedPromise();
    }

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables));
};

/** @namespace Util/Request/addProductDebounce */
export const addProductDebounce = (callback, delay) => {
    let timeouts = {};

    return {
        debouncedFn: (...args) => {
            const [, { sku }] = args;

            if (timeouts[sku]) {
                clearTimeout(timeouts[sku]);
            }

            timeouts[sku] = setTimeout(() => callback.apply(this, args), delay);
            return timeouts;
        },
        resetCall: (sku) => {
            clearTimeout(timeouts[sku]);
        }
    }
};
