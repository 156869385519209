import { PureComponent } from 'react';

import './PickupSchedule.style';

export class PickupSchedule extends PureComponent {
    getIsLongList() {
        const { storeTimeslots = [] } = this.props;
        return storeTimeslots.length > 7;
    }

    renderDay(dayData) {
        const { onDayClick, selectedDate, storeTimeslots } = this.props;
        const { day, date } = dayData;
        const isSelected = date === selectedDate;

        const isAvailable = storeTimeslots.filter(({ date: dateFromArr }) => date === dateFromArr)
            [0].slots.some(({ is_available }) => is_available);

        return (
            <div
              block="PickupSchedule"
              elem="Day"
              onClick={ () => onDayClick(date, day, isAvailable) }
              mods={ { isAvailable, isSelected, isLong: this.getIsLongList() } }
            >
                <p
                  block="PickupSchedule"
                  elem="DayText"
                >
                    { day }
                </p>
            </div>
        );
    }

    renderTimeSlot(timeSlot) {
        const { selectedTimeslot, onTimeSlotClick } = this.props;
        const { time, is_available: isAvailable, id } = timeSlot;
        const { id: selectedTimeId } = selectedTimeslot;

        const isSelected = selectedTimeId === id;

        return (
            <div
              block="PickupSchedule"
              elem="TimeSlot"
              mods={ { isAvailable, isSelected } }
              onClick={ () => onTimeSlotClick(timeSlot, isAvailable) }
            >
                <p
                  block="PickupSchedule"
                  elem="TimeText"
                >
                    { time }
                </p>
            </div>
        );
    }

    renderNoAvailableDays() {
        const { isAllDaysUnavailable } = this.props;

        if (!isAllDaysUnavailable) {
            return null;
        }

        return (
            <div
              block="PickupSchedule"
              elem="AddressFormNote"
            >
                <span
                  block="PickupSchedule"
                  elem="AddressFormNoteIcon"
                />
                <p
                  block="PickupSchedule"
                  elem="AddressFormNoteText"
                  mods={ { isNoSlotTxt: true } }
                >{ __('All the timeslots are unavailable.') }</p>
            </div>
        );
    }

    renderDays() {
        const { storeTimeslots, isAllDaysUnavailable, isMobile } = this.props;

        const isShort = storeTimeslots.length < 5;

        return (
            <div
              block="PickupSchedule"
              elem="DaysWrapper"
            >
                { (isMobile && !isAllDaysUnavailable) && <p block="PickupSchedule" elem="BlockNote">
                    { __('Pick up date') }
                </p> }
                <div
                  block="PickupSchedule"
                  elem="Days"
                  mods={ { isShort, isLong: this.getIsLongList() } }
                >
                    { storeTimeslots.map(this.renderDay.bind(this)) }
                </div>
                { this.renderNoAvailableDays() }
            </div>
        );
    }

    renderTimeSlots() {
        const { storeTimeslots, selectedDate, isMobile } = this.props;

        if (!selectedDate) {
            return null;
        }

        const { slots } = storeTimeslots.filter(({ date }) => date === selectedDate)[0];

        return (
            <div
              block="PickupSchedule"
              elem="TimeSlotsWrapper"
            >
                { isMobile && <p block="PickupSchedule" elem="BlockNote">
                    { __('Pick up time') }
                </p> }
                <div
                  block="PickupSchedule"
                  elem="TimeSlots"
                >
                    { slots.map(this.renderTimeSlot.bind(this)) }
                </div>
            </div>
        );
    }

    render() {
        return (
            <section
              block="PickupSchedule"
              elem="Wrapper"
            >
                { this.renderDays() }
                { this.renderTimeSlots() }
            </section>
        );
    }
}

export default PickupSchedule;
