/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    CartOverlayContainer,
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
} from 'Component/CartOverlay/CartOverlay.container';

import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hasOutOfStockProductsInCartItems } from 'Util/Cart';

import RecipeIngredientProductsOverlay from './RecipeIngredientProductsOverlay.component';
import { RECIPE_INGREDIENT_PRODUCTS_OVERLAY_ID } from './RecipeIngredientProductsOverlay.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Component/CartOverlay/Container/mapDispatchToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    recipeIngredientProducts: state.RecipeReducer.recipeIngredientProducts,
    isRecipeIngredientsProductsLoading: state.RecipeReducer.isRecipeIngredientsProductsLoading,
});

/** @namespace Component/CartOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Component/CartOverlay/Container */
export class RecipeIngredientProductsOverlayContainer extends CartOverlayContainer {
    containerFunctions = {
        changeHeaderState: this.changeHeaderState.bind(this),
        handleCheckoutClick: this.handleCheckoutClick.bind(this),
        closeMiniCart: this.closeMiniCart.bind(this),
    };

    containerProps = () => {
        const {
            totals: { items },
            recipeIngredientProducts: { items: products }
        } = this.props;

        return {
            hasOutOfStockProductsInCart: hasOutOfStockProductsInCartItems(items),
            products
        };
    };

    changeHeaderState() {
        const {
            changeHeaderState,
            totals: { count = 0 }
        } = this.props;
        const title = __('%s Items', count || 0);

        changeHeaderState({
            name: RECIPE_INGREDIENT_PRODUCTS_OVERLAY_ID,
            title,
            onEditClick: () => {
                this.setState({ isEditing: true });
                changeHeaderState({
                    name: CART_EDITING,
                    title,
                    onOkClick: () => this.setState({ isEditing: false }),
                    onCancelClick: () => this.setState({ isEditing: false })
                });
            },
            onCloseClick: () => this.setState({ isEditing: false })
        });
    }

    closeMiniCart() {
        const { activeOverlay, hideActiveOverlay, goToPreviousNavigationState } = this.props;

        if (activeOverlay !== RECIPE_INGREDIENT_PRODUCTS_OVERLAY_ID) {
            return;
        }

        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    render() {
        return (
            <RecipeIngredientProductsOverlay
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeIngredientProductsOverlayContainer);
