import { PureComponent } from "react";
import { connect } from "react-redux";

import BlogPost from "./BlogPost.component";

export const mapStateToProps = (state) => ({
    blogCategories: state.BlogReducer.blogCategories,
});

/** @namespace Component/BlogPost/Container */
export class BlogPostContainer extends PureComponent {
    containerFunctions = {};

    render() {
        return <BlogPost {...this.props} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, null)(BlogPostContainer);
