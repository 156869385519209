export const breadcrumbsToLowerC = (breadcrumbs) => breadcrumbs.map((breadcrumb) => {
    const { name: prevName } = breadcrumb;

    let name = prevName;

    if (typeof name === 'string') {
        const lcName = name.toLowerCase();
        name = lcName[0].toUpperCase() + lcName.slice(1);
    }

    return {
        ... breadcrumb,
        name
    };
});
