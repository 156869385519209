import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import {ProductCardContainer as SourceProductCardContainer} from 'SourceComponent/ProductCard/ProductCard.container'
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import { FilterType, ProductType } from 'Type/ProductList';
import { getVariantsIndexes } from 'Util/Product';
import { makeCancelable } from 'Util/Promise';
import { objectToUri } from 'Util/Url';
import ProductCard from './ProductCard.component';
import { DeviceType } from 'Type/Device';
import history from 'Util/History';
export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const mapStateToProps = (state) => ({
    Cart: state.CartReducer.cartTotals.items,
    device: state.ConfigReducer.device,
    totals: state.CartReducer.cartTotals,
    fetchingAddProductsSkus: state.CartReducer.fetchingAddProductsSkus
});

export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    changeItemQty: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.changeItemQty(dispatch, options)
    ),
    removeProduct: (itemId, sku) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeProductFromCart(dispatch, itemId, sku)
    )
});

export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        product: ProductType,
        selectedFilters: FilterType,
        changeItemQty: PropTypes.func.isRequired,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        product: {},
        selectedFilters: {}
    };

    handlers = [];

    state={ isInCart: false, qty: 0, item: {} ,isLoading: false}

    static getDerivedStateFromProps(props, state){
        if(props.Cart === undefined){
            // Setting initial state, if the cart is empty
            return { isInCart: false, qty: 0, item: {} };
        } else {
        for(let i = 0; i < props.Cart.length;i++){
            if(props.Cart[i].sku === props.product.sku){
                return {isInCart:true, qty:props.Cart[i].qty, item:props.Cart[i] };
            }
        }

        return {isInCart:false};
       }
    }

    componentDidUpdate(){
        const { isLoadingLocal } = this.props;
        const { isLoading } = this.state;

        if( isLoading !== isLoadingLocal && typeof isLoadingLocal !== "undefined" ){
            this.setState({isLoading: isLoadingLocal});
        }
    }

    containerFunctions = {
        getAttribute: this.getAttribute.bind(this),
        handleChangeQuantity: this.handleChangeQuantity.bind(this),
        handleRemoveItem: this.handleRemoveItem.bind(this),
        setIsLoading: this.setIsLoading.bind(this)
    };

    getAttribute(code) {
        const { selectedFilters } = this.props;

        if (!Object.keys(selectedFilters).length) {
            const { product: { attributes = {} } } = this.props;
            return attributes[code];
        }

        const currentVariantIndex = this._getCurrentVariantIndex();
        const { product, product: { variants = [] } } = this.props;
        const { attributes: parentAttributes = {} } = product;
        const { attributes = parentAttributes } = variants[currentVariantIndex] || product;
        const { attribute_options = {} } = parentAttributes[code] || {};

        return {
            ...attributes[code],
            attribute_options
        };
    }

    containerProps = () => {
        const { isLoading: isLoadingFromState } = this.state;
        const {
            fetchingAddProductsSkus,
            product: {
                sku
            } = {}
        } = this.props;

        const isAddingInProcess = fetchingAddProductsSkus.includes(sku);

        return {
            availableVisualOptions: this._getAvailableVisualOptions(),
            currentVariantIndex: this._getCurrentVariantIndex(),
            productOrVariant: this._getProductOrVariant(),
            thumbnail: this._getThumbnail(),
            linkTo: this._getLinkTo(),
            isLoading: isLoadingFromState || isAddingInProcess
        }
    };

    handleChangeQuantity(quantity) {
        this.setState({ isLoading: true }, () => {
            const { item: { item_id, sku } } = this.state;
            const { changeItemQty } = this.props;
            if(quantity === 0){
                this.handleRemoveItem()
            }else{
                this.hideLoaderAfterPromise(changeItemQty({ item_id, quantity, sku }));
            }
        });
    }

    handleRemoveItem() {
        this.setState({ isLoading: true }, () => {
            const { item: { item_id, sku } } = this.state;
            const {removeProduct} = this.props;
            this.hideLoaderAfterPromise(removeProduct(item_id, sku));
        });
    }

    registerCancelablePromise(promise) {
        const cancelablePromise = makeCancelable(promise);
        this.handlers.push(cancelablePromise);
        return cancelablePromise;
    }

    hideLoaderAfterPromise(promise) {
        this.registerCancelablePromise(promise)
            .promise.then(this.setStateNotLoading.bind(this), this.setStateNotLoading.bind(this));
    }

    setStateNotLoading() {
        this.setState({ isLoading: false });
    }

    setIsLoading(isLoading) {
        this.setState({ isLoading });
    }

    _getLinkTo() {
        const { product: { url }, product } = this.props;
        const { state: { category = null } = {} } = history.location;

        if (!url) {
            return undefined;
        }

        const { parameters } = this._getConfigurableParameters();
        return {
            pathname: url,
            state: { product, prevCategoryId: category  },
            search: objectToUri(parameters)
        };
    }

    _getCurrentVariantIndex() {
        const { index } = this._getConfigurableParameters();
        return index >= 0 ? index : 0;
    }

    _getConfigurableParameters() {
        const { product: { variants = [] }, selectedFilters = {} } = this.props;
        const filterKeys = Object.keys(selectedFilters);

        if (filterKeys.length < 0) {
            return { indexes: [], parameters: {} };
        }

        const indexes = getVariantsIndexes(variants, selectedFilters);
        const [index] = indexes;

        if (!variants[index]) {
            return { indexes: [], parameters: {} };
        }
        const { attributes } = variants[index];

        const parameters = Object.entries(attributes)
            .reduce((parameters, [key, { attribute_value }]) => {
                if (filterKeys.includes(key)) {
                    return { ...parameters, [key]: attribute_value };
                }

                return parameters;
            }, {});

        return { indexes, index, parameters };
    }

    _isThumbnailAvailable(path) {
        return path && path !== 'no_selection';
    }

    _getThumbnail() {
        const product = this._getProductOrVariant();
        const { small_image: { url } = {} } = product;
        if (this._isThumbnailAvailable(url)) {
            return url;
        }

        // If thumbnail is, missing we try to get image from parent
        const { product: { small_image: { url: parentUrl } = {} } } = this.props;
        if (this._isThumbnailAvailable(parentUrl)) {
            return parentUrl;
        }

        return '';
    }

    _getProductOrVariant() {
        const { product: { type_id, variants }, product } = this.props;

        if (
            type_id === 'configurable'
            && variants !== undefined
            && variants.length
        ) {
            return variants[this._getCurrentVariantIndex()] || {};
        }

        return product || {};
    }

    _getAvailableVisualOptions() {
        const { product: { configurable_options = [] } } = this.props;

        return Object.values(configurable_options).reduce((acc, { attribute_options = {}, attribute_values }) => {
            const visualOptions = Object.values(attribute_options).reduce(
                (acc, option) => {
                    const {
                        swatch_data,
                        label,
                        value: attrValue
                    } = option;

                    const { type, value } = swatch_data || {};

                    if (
                        type === '1'
                        && attribute_values.includes(attrValue)
                    ) {
                        acc.push({ value, label });
                    }

                    return acc;
                }, []
            );

            if (visualOptions.length > 0) {
                return [...acc, ...visualOptions];
            }

            return acc;
        }, []);
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <ProductCard
                      { ...{ ...this.props, registerSharedElement } }
                      { ...this.containerFunctions }
                      { ...this.state}
                      { ...this.containerProps() }
                    />
                ) }
            </Subscribe>
        );
    }
}

// eslint-disable-next-line no-unused-vars

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
