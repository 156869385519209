cardsMock = [
    {
        "id": 1,
        "name": "Card 1",
        "title": "2 pour 1",
        "description": "Boisson à l’avoine Barista ",
        "date": "2023-03-01",
        "status": "expired",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_1.png",
        "imageBack": "AvrilNaturellement_1.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 2,
        "name": "Card 2",
        "title": "3 pour 3,99",
        "description": "Carrés au chocolat et aux superaliments ",
        "date": "2023-03-02",
        "status": "expired",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_2.png",
        "imageBack": "MID-DAY_SQUARES.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 3,
        "name": "Card 3",
        "title": "3 pour 3,99",
        "description": "Pâtes à la semoule de blé dur sélectionnées ",
        "date": "2023-03-03",
        "status": "expired",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_3.png",
        "imageBack": "AvrilNaturellement_2.png",
        "imageSpecial": null,
        "link": "www.2dev.ca"
    },
    {
        "id": 4,
        "name": "Card 4",
        "title": "2 pour 1",
        "description": "Shampooings et revitalisants ",
        "date": "2023-03-04",
        "status": "expired",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_4.png",
        "imageBack": "Oneka.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 5,
        "name": "Card 5",
        "title": "2,99",
        "description": "Pâte à pizza",
        "date": "2023-03-05",
        "status": "expired",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_5.png",
        "imageBack": "AvrilNaturellement_3.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 6,
        "name": "Card 6",
        "title": "25 X  les points",
        "description": "Pâtes à la semoule de blé dur sélectionnées, sur la carte Privilège Avril à l’achat de produits préparés et servis au Bistro Avril",
        "date": "2023-03-06",
        "status": "expired",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_6.png",
        "imageBack": "BistroAvril.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 7,
        "name": "Card 7",
        "title": "2 pour 1",
        "description": "Suppléments de collagène (format de 500 ml)",
        "date": "2023-03-07",
        "status": "expired",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_7.png",
        "imageBack": "Medelys.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 8,
        "name": "Card 8",
        "title": "2 pour 1",
        "description": "Kombuchas (paquet de 4)",
        "date": "2023-03-08",
        "status": "expired",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_8.png",
        "imageBack": "Remedy.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 9,
        "name": "Card 9",
        "title": "Pâques",
        "description": null,
        "date": "2023-03-09",
        "status": "expired",
        "cardType": "holiday",
        "imageFront": null,
        "imageBack": null,
        "imageSpecial": null,
        "link": null
    },
    {
        "id": 10,
        "name": "Card 10",
        "title": "4,99",
        "description": "Couscous (formats de 907 g et 1 kg)",
        "date": "2023-03-10",
        "status": "active",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_10.png",
        "imageBack": "AvrilNaturellement_4.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 11,
        "name": "Card 11",
        "title": "2 pour 1",
        "description": "Dentifrices, antisudorifiques et déodorants",
        "date": "2023-03-11",
        "status": "upcoming",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_11.png",
        "imageBack": "GreenBeaver.jpg",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 12,
        "name": "Card 12",
        "title": "13,99",
        "description": "Pâté au poulet",
        "date": "2023-03-12",
        "status": "upcoming",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_12.png",
        "imageBack": "AvrilNaturellement_5.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 13,
        "name": "Card 13",
        "title": "25 X les points",
        "description": "Sur la carte Privilège Avril à l’achat de produits préparés et servis au Bistro Avril",
        "date": "2023-03-13",
        "status": "upcoming",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_13.png",
        "imageBack": "BistroAvril_1.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 14,
        "name": "Card 14",
        "title": "2 pour 1",
        "description": "Citrate de magnésium 150 mg",
        "date": "2023-03-14",
        "status": "future",
        "cardType": "promotion",
        "imageFront": "AEA_Jour_14.png",
        "imageBack": "NaturalFactors.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    },
    {
        "id": 15,
        "name": "Card 15",
        "title": null,
        "description": null,
        "date": "2023-03-15",
        "status": "future",
        "cardType": "special",
        "imageFront": "AEA_Jour_15.png",
        "imageBack": null,
        "imageSpecial": "foire1.png",
        "link": "www.avril.ca",

    },
    {
        "id": 16,
        "name": "Card 16",
        "title": null,
        "description": null,
        "date": "2023-03-16",
        "status": "future",
        "cardType": "special",
        "imageFront": "AEA_Jour_16.png",
        "imageBack": "LOOP.png",
        "imageSpecial": null,
        "link": "www.avril.ca"
    }
]