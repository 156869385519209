import {
    SomethingWentWrong as SourceSomethingWentWrong,
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';
import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import { logToLoggly } from 'Util/Loggly';

import './SomethingWentWrong.extend.style'

export class SomethingWentWrong extends SourceSomethingWentWrong {
    renderErrorDetails() {
        const { errorDetails: { err, info: { componentStack } = {} } } = this.props;
        const errorString = err ? err.toString() : null;

        logToLoggly({
            err: 'Error from Ooops component.',
            message: errorString,
            stack: err.stack,
            componentStack: componentStack
        });

        console.groupCollapsed('Suppressed error log:');
        console.error(errorString);
        console.groupEnd();

        // if (process.env.NODE_ENV === 'production') {
        //     return null;
        // }

        return (
            <div block="SomethingWentWrong" elem="Debug">
                { errorString }
                { componentStack }
            </div>
        );
    }

    render() {
        const { onClick } = this.props;

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <h1 block="SomethingWentWrong" elem="Heading">{ __('Ooops!') }</h1>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{ __('Something went wrong!') }</h2>
                    <Link
                        to="/"
                        block="SomethingWentWrong"
                        elem="Button"
                        mix={ { block: 'Button' } }
                        onClick={ onClick }
                    >
                        { __('Back to homepage') }
                    </Link>
                    { this.renderErrorDetails() }
                </ContentWrapper>
            </main>
        );
    }
};

export default SomethingWentWrong;
