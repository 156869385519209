import { PureComponent, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { toggleOverlayByKey, hideActiveOverlay } from "Store/Overlay/Overlay.action";
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { getQueryParam, setQueryParams } from 'Util/Url';

import RecipeCategoriesNav from "./RecipeCategoriesNav.component";

import { RECIPES_FILTER_OVERLAY_ID } from "./RecipeCategoriesNav.config";

import { CategoryFilterOverlayContainer } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.container';

export const BreadcrumbsDispatcher = import(
	/* webpackMode: "lazy", webpackChunkName: "dispatchers" */
	"Store/Breadcrumbs/Breadcrumbs.dispatcher"
);

export const mapStateToProps = (state) => ({
	device: state.ConfigReducer.device,
    recipeFilters: state.RecipeReducer.recipeFilters,
    recipeCategories: state.RecipeReducer.recipeCategories,
    recipesTotalCount: state.RecipeReducer.recipes.total_count,
    currentCategoryId: state.RecipeReducer.currentCategoryData.entity_id,
    isRecipesCategoriesLoading: state.RecipeReducer.isRecipesCategoriesLoading,
    isCurrentCategoryLoading: state.RecipeReducer.isCurrentCategoryLoading,
    isInfoLoading: state.RecipeReducer.isRecipesLoading,
	activeOverlay: state.OverlayReducer.activeOverlay,
	closestStore: state.ConfigReducer.getClosestStore
});

export const mapDispatchToProps = (dispatch) => ({
	toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(BOTTOM_NAVIGATION_TYPE)),
    updateBreadcrumbs: (breadcrumbs) =>
		BreadcrumbsDispatcher.then(({ default: dispatcher }) =>
			dispatcher.update(breadcrumbs, dispatch)
		),
});

export class RecipeCategoriesNavContainer extends CategoryFilterOverlayContainer {
	moreFiltersBtnRef = createRef();

	state = {
		isMoreFiltersActive: false,
	};

	containerProps = () => {
		return {
			...this.props,
			...this.state,
			moreFiltersBtnRef: this.moreFiltersBtnRef,
            selectedFilters: this.getSelectedFiltersFromUrl()
		};
	};
    // onFiltersOverlayOpen
	containerFunctions = {
		onFiltersOverlayOpen: this.onFiltersOverlayOpen.bind(this),
		closeOverlay: this.closeOverlay.bind(this),
		onCloseButtonClick: this.closeOverlay.bind(this),
		onVisible: this.onVisible.bind(this),
		onHide: this.onHide.bind(this),
        getCustomFilterUrl: this.getCustomFilterUrl.bind(this),
        toggleCustomFilter: this.toggleCustomFilter.bind(this),
        getFilterUrl: this.getFilterUrl.bind(this),
        resetFilters: this.resetFilters.bind(this),
        onSeeResultsClick: this.onSeeResultsClick.bind(this),
        onCategoryClick: this.onCategoryClick.bind(this),
	};

    onHide() {}

    onVisible() {}

    updateFilter(filterName, filterArray) {
		const { location, history } = this.props;

		setQueryParams(
			{
				customFilters: this.getFilterUrl(
					filterName,
					filterArray,
					false
				),
				page: "",
			},
			location,
			history
		);
	}

    onCategoryClick() {
        const { updateBreadcrumbs } = this.props;
        updateBreadcrumbs([]);
    }

    _getNewSelectedFiltersString(filterName, filterArray) {
        if (typeof filterArray === 'string') {
            filterArray = [filterArray];
        }

        const prevCustomFilters = this._getSelectedFiltersFromUrl();
        const customFilers = {
            ...prevCustomFilters,
            [filterName]: filterArray
        };

        return Object.entries(customFilers)
            .reduce((accumulator, [filterKey, filterValue]) => {
                if (filterValue.length) {
                    const filterValues = filterValue.sort().join(',');

                    accumulator.push(`${filterKey}:${filterValues}`);
                }

                return accumulator;
            }, [])
            .sort()
            .join(';');
    }

    _getNewFilterArray(filterKey, value) {
        const customFiltersValues = this.getSelectedFiltersFromUrl();
		const newFilterArray =
			customFiltersValues[filterKey] !== undefined
				? Array.from(customFiltersValues[filterKey])
				: [];

		const filterValueIndex = newFilterArray.indexOf(value);

		if (filterValueIndex === -1) {
			newFilterArray.push(value);
		} else {
			newFilterArray.splice(filterValueIndex, 1);
		}

		return newFilterArray;
	}

    getSelectedFiltersFromUrl() {
        const { location } = this.props;
        const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');

        return selectedFiltersString.reduce((acc, filter) => {
            if (!filter) {
                return acc;
            }
            const [key, value] = filter.split(':');

            if (!value) {
                return acc;
            }

            return { ...acc, [key]: value.split(',') };
        }, {});
    }

    closeOverlay() {
		const { hideActiveOverlay, activeOverlay } = this.props;

		if (activeOverlay !== RECIPES_FILTER_OVERLAY_ID) {
			return;
		}

		hideActiveOverlay();
	}

	onFiltersOverlayOpen() {
		const { isMoreFiltersActive } = this.state;
		const { toggleOverlayByKey } = this.props;

        toggleOverlayByKey(RECIPES_FILTER_OVERLAY_ID);
		this.setState({
			isMoreFiltersActive: !isMoreFiltersActive,
		});
	}

	render() {
        return (
			<RecipeCategoriesNav
				{...this.containerProps()}
				{...this.containerFunctions}
			/>
		);
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(RecipeCategoriesNavContainer));
