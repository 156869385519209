import MyAccountInfoList from 'Component/MyAccountInfoList';
import Loader from 'Component/Loader';

import './MyAccountAddressTable.override.style'

export class MyAccountAddressTable extends MyAccountInfoList {

    get dataPairArray() {
        const {
            address,
            getFormatedRegion,
            showAdditionalFields,
            isPickupDetails,
            pickupDetails
        } = this.props;

        if (isPickupDetails) {
            return [
                {
                    key: 'day',
                    label: __('Day'),
                    source: pickupDetails
                },
                {
                    key: 'time',
                    label: __('Time'),
                    source: pickupDetails
                },
            ]
        }

        const regionData = getFormatedRegion(address);

        const additionalFields = [
            {
                key: 'city',
                label: __('City'),
                source: address
            },
            {
                key: 'region',
                label: __('State/Province'),
                source: regionData
            },
            {
                key: 'postcode',
                label: __('Postal code'),
                source: address
            },
            {
                key: 'country',
                label: __('County'),
                source: regionData
            },
            {
                key: 'telephone',
                label: __('Phone number'),
                source: address
            }
        ];

        return [
            {
                key: 'firstname',
                label: __('First name'),
                source: address
            },
            {
                key: 'lastname',
                label: __('Last name'),
                source: address
            },
            {
                key: 'company',
                label: __('Company'),
                source: address
            },
            {
                key: 'street',
                label: __('Street'),
                source: address
            },

            ...(showAdditionalFields ? additionalFields : [])
        ];
    }

    renderActions() {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            isPickupDetails,
            address: { default_billing, default_shipping } = {}
        } = this.props;

        if (!showActions || isPickupDetails) {
            return null;
        }

        const isDeleteAllowed = default_shipping || default_billing;

        return (
            <>
                <button
                    block="Button"
                    onClick={ onEditClick }
                >
                    { __('Edit address') }
                </button>
                <button
                    block="Button"
                    mods={ { isHollow: true } }
                    onClick={ onDeleteClick }
                    disabled={ isDeleteAllowed }
                    style={isDeleteAllowed ? { display: 'none' } : { display: 'inline-block' }}
                    title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address' }
                >
                    { __('Delete') }
                </button>
            </>
        );
    }

    render() {
        const { countries, mix } = this.props;

        return (
            <div block="MyAccountAddressTable" mix={ mix }>
                <Loader isLoading={ !countries.length } />
                { this.renderList() }
                { this.renderActions() }
            </div>
        );
    }
};

export default MyAccountAddressTable;
