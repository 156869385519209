import { Field } from 'SourceUtil/Query';

export class SitemapQuery {
    getSitemap() {
        return new Field('getSitemap')
            .addFieldList(this._getSitemapField())
    }

    _getSitemapField() {
        return [
            new Field('cms').addFieldList([
                'url',
                'name',
            ]),
            new Field('category').addFieldList([
                'name',
                'url',
                'level',
                new Field('children').addFieldList([
                    'name',
                    'url',
                    'level',
                    new Field('children').addFieldList([
                        'name',
                        'url',
                        'level',
                        new Field('children').addFieldList([
                            'name',
                            'url',
                            'level',
                            new Field('children').addFieldList([
                                'name',
                                'url',
                                'level',
                            ])
                        ])
                    ])
                ])
            ])
        ];
    }

};

export default new SitemapQuery();
