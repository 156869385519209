/* eslint-disable react/prop-types */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import { appendWithStoreCode } from 'Util/Url';

import {
    LinkContainer as SourceLinkContainer,
    mapStateToProps,
    mapDispatchToProps
} from 'SourceComponent/Link/Link.container';

/** @namespace Component/Link/Container */
export class LinkContainer extends SourceLinkContainer {
    getTo() {
        const { to } = this.props;

        if (typeof to === 'string') {
            // in case this URL is absolute, do not append store
            if (/^https?:\/\//.test(to)) {
                return to;
            }

            return appendWithStoreCode(to);
        } else if (!to) {
            return null;
        }

        const { pathname } = to;

        return {
            pathname: appendWithStoreCode(pathname),
            ...to
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
