import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceDispatchToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';

import MyAccountSignIn from './MyAccountSignIn.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace AvrilPwaApp/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    isLoading: state.MyAccountReducer.isLoading,
    isEmailAvailabilityLoading: state.CheckoutReducer.isEmailAvailabilityLoading
});

export const mapDispatchToProps = (dispatch) => ({
    ...SourceDispatchToProps(dispatch),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    )
});


/** @namespace AvrilPwaApp/Component/MyAccountSignIn/Container/MyAccountSignInContainer */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
  static propTypes = {
      state: PropTypes.string.isRequired,
      onFormError: PropTypes.func.isRequired,
      handleForgotPassword: PropTypes.func.isRequired,
      handleCreateAccount: PropTypes.func.isRequired,
      isCheckout: PropTypes.bool.isRequired,
      signIn: PropTypes.func.isRequired,
      showNotification: PropTypes.func.isRequired,
      onSignIn: PropTypes.func.isRequired,
      setLoadingState: PropTypes.func.isRequired,
      emailValue: PropTypes.string,
      isEmailAvailable: PropTypes.bool,
      setSignInState: PropTypes.func,
      handleEmailInput: PropTypes.func,
      handleSignIn: PropTypes.func
  };

  state={ isLoading: false };

  componentDidUpdate(prevProps) {
      const { isCheckout, isEmailAvailable, setSignInState } = this.props;
      const { isEmailAvailable: prevIsEmailAvailable } = prevProps;

      if (isCheckout && isEmailAvailable && !prevIsEmailAvailable) {
          setSignInState('');
      }
  }

  containerProps = () => {
    const {
        state,
        onFormError,
        handleForgotPassword,
        handleCreateAccount,
        isCheckout,
        setLoadingState,
        emailValue,
        handleEmailInput,
        isEmailAvailabilityLoading,
        isLoading: isLoadingFromProps
    } = this.props;

    const { isLoading } = this.state

    return {
        state,
        onFormError,
        handleForgotPassword,
        handleCreateAccount,
        isCheckout,
        setLoadingState,
        emailValue,
        handleEmailInput,
        isLoading: isLoading || isLoadingFromProps || isEmailAvailabilityLoading
    };
};

  setIsLoading(isLoading) {
      this.setState({ isLoading });
  }

  async onSignInSuccess(fields) {
      const {
          signIn,
          showNotification,
          onSignIn,
          setLoadingState,
          requestCustomerData
      } = this.props;

      this.setIsLoading(true);
      signIn(fields).then(
          /** @namespace AvrilPwaApp/Component/MyAccountSignIn/Container/signIn/then */
          () => {
                requestCustomerData()
                  .then(() => {
                    setLoadingState(false);
                    onSignIn();
                });

                this.setIsLoading(false);
          },
          /** @namespace AvrilPwaApp/Component/MyAccountSignIn/Container/signIn/then */
          (err) => {
              this.setState({ isLoading: false });
              setLoadingState(false);
              showNotification('error', err.message);
          }
      );
  }

  render() {
      return (
        <MyAccountSignIn
          { ...this.containerFunctions }
          { ...this.containerProps() }
        />
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
