import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DEFAULT_LOCATION_CODE } from 'Store/Config/Config.dispatcher';
import { sortPickupStores } from 'Util/Stores';
import { getSelectedLocation } from 'Util/Cart';

import PickupStoreSelector from './PickupStoreSelector.component';

export const mapStateToProps = (state) => ({
    pickupLocations: state.ConfigReducer.pickupLocations,
    closestStore: state.ConfigReducer.getClosestStore,
    countries: state.ConfigReducer.countries,
    device: state.ConfigReducer.device,
    mapLanguage: state.ConfigReducer.code
});

export const mapDispatchToProps = (dispatch) => ({});

export class PickupStoreSelectorContainer extends PureComponent {
    static propTypes = {
        onShippingEstimationFieldsChange: PropTypes.func.isRequired
    };

    state = {
        isOtherStoresOpened: false,
        isMapLoading: true,
        storeSelectorWidth: 0
    }

    containerProps = () => {
        const {
            pickupLocations: { items } = {},
            closestStore,
            countries,
            mapLanguage,
            selectedStoreCode,
            device: { isMobile }
        } = this.props;

        const {
            isOtherStoresOpened,
            isMapLoading
        } = this.state;

        const pickupLocations = sortPickupStores(items, closestStore, true);
        const otherStoresOptions = pickupLocations
            .filter(({ pickup_location_code, is_pickup_location_active }) => (
                pickup_location_code !== selectedStoreCode && is_pickup_location_active
            ));
        const selectedStore = getSelectedLocation(pickupLocations, selectedStoreCode);
        const storeToRender = selectedStore?.pickup_location_code === DEFAULT_LOCATION_CODE
            ? otherStoresOptions[0]
            : selectedStore;

        return {
            isOtherStoresOpened,
            selectedStoreCode,
            pickupLocations,
            otherStoresOptions,
            storeToRender,
            closestStore,
            mapLanguage,
            isMapLoading,
            countries,
            selectedStoreCode,
            isMobile
        };
    };

    containerFunctions = {
        openStoreSelector: this.openStoreSelector.bind(this),
        onStoreOptionClick: this.onStoreOptionClick.bind(this),
        setIsMapLoading: this.setIsMapLoading.bind(this)
    };

    setIsMapLoading(isMapLoading) {
        this.setState({ isMapLoading });
    }

    openStoreSelector() {
        const { isOtherStoresOpened } = this.state;
        this.setState({ isOtherStoresOpened: !isOtherStoresOpened });
    }

    onStoreOptionClick(selectedStoreCode) {
        const { setSelectedStoreCode } = this.props;
        setSelectedStoreCode(selectedStoreCode);
        this.setState({ isOtherStoresOpened: false, isMapLoading: true });
    }

    render() {
        return (
            <PickupStoreSelector
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupStoreSelectorContainer);
