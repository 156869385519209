/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    CHECKBOX_TYPE,
    RADIO_TYPE
} from './Field.config';
import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';
import { NUMBER_TYPE, TEXT_NUMBER_TYPE } from './Field.config';

/** @namespace Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onHandleChange: this.onHandleChange.bind(this)
    };

    getInitialPropsValue() {
        const { type, value } = this.props;

        if (value) {
            return value;
        }

        switch (type) {
        case NUMBER_TYPE:
        case TEXT_NUMBER_TYPE:
            return 0;
        default:
            return '';
        }
    }

    handleChange(value, shouldUpdate = true) {
        const {
            isControlled,
            onChange,
            type,
            min,
            max
        } = this.props;


        switch (type) {
        case NUMBER_TYPE:
        case TEXT_NUMBER_TYPE:
            const isValueNaN = Number.isNaN(parseInt(value, 10));
            if (min > value || value > max || isValueNaN) {
                break;
            }
            if (onChange && shouldUpdate) {
                onChange(value);
            }
            if (!isControlled) {
                this.setState({ value });
            }
            break;
        default:
            if (onChange) {
                onChange(value);
            }
            if (!isControlled) {
                this.setState({ value });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue, checked: prevChecked } = prevProps;
        const {
            value: currentValue,
            checked: currChecked,
            type,
            isFieldsCleanRequired,
            onFieldCleanSuccess
        } = this.props;

        if (isFieldsCleanRequired) {
            this.setState({ value: '' });
            onFieldCleanSuccess()
        }

        if (prevValue !== currentValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: currentValue });
        }
        if ([CHECKBOX_TYPE, RADIO_TYPE].includes(type) && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }
    }

    onHandleChange(e, value, shouldUpdate) {
        e.preventDefault();
        e.stopPropagation();
        this.handleChange(value, shouldUpdate);
    }
}

export default FieldContainer;
