/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MyAccountOrderPopupDetails from './MyAccountOrderPopupDetails.component';

/** @namespace Component/MyAccountOrderPopupDetails/Container/mapStateToProps */
export const mapStateToProps = (state) =>({
    currency_code: state.ConfigReducer.default_display_currency_code,
    storeCode: state.ConfigReducer.code
});

/** @namespace Component/MyAccountOrderPopupDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({});

export class MyAccountOrderPopupDetailsContainer extends PureComponent {
    state = {
        ...this.state,
        areTaxesRendered: false
    };

    containerFunctions = {
        onTaxesClick: this.onTaxesClick.bind(this)
    }

    onTaxesClick() {
        const { areTaxesRendered } = this.state;
        this.setState({ areTaxesRendered: !areTaxesRendered });
    }

    render() {
        return <MyAccountOrderPopupDetails
          { ...this.props }
          { ...this.state }
          { ...this.containerFunctions }
        />;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderPopupDetailsContainer);
