/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from "react";
import { connect } from "react-redux";

import { updateIsSearchInputClicked } from "Store/Search/Search.action";

import SearchField from "./SearchField.component";

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
} from "SourceComponent/SearchField/SearchField.container";

/** @namespace Component/SearchField/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSearchInputClicked: state.SearchReducer.isSearchInputClicked,
});

/** @namespace Component/SearchField/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateIsSearchInputClicked: (isClicked) =>
        dispatch(updateIsSearchInputClicked(isClicked)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchField);
