/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_ACTITO_FORM = 'UPDATE_ACTITO_FORM';
export const UPDATE_ACTITO_POPUP_DESCRIPTION = 'UPDATE_ACTITO_POPUP_DESCRIPTION';
export const UPDATE_ACTITO_INTERACTION_ID = 'UPDATE_ACTITO_INTERACTION_ID';
export const UPDATE_IS_ACTITO_FORM_LOADING = 'UPDATE_IS_ACTITO_FORM_LOADING';
export const UPDATE_ACTITO_PREFILLED_EMAIL = 'UPDATE_ACTITO_PREFILLED_EMAIL';

/**
 * Update actito prefilled email
 * @param  {string} prefilledEmail
 * @return {Object}
 * @namespace Store/Cart/Action/updateActitoPrefilledEmail */
export const updateActitoPrefilledEmail = (prefilledEmail) => ({
    type: UPDATE_ACTITO_PREFILLED_EMAIL,
    prefilledEmail
});
/**
 * Update actito form data
 * @param  {Object} actitoForm
 * @return {Object}
 * @namespace Store/Cart/Action/updateActitoForm */
export const updateActitoForm = (actitoForm) => ({
    type: UPDATE_ACTITO_FORM,
    actitoForm
});

/**
 * Update actito current description
 * @param  {Object} actitoPopupDescription
 * @return {Object}
 * @namespace Store/Cart/Action/updateActitoPopupDescription */
export const updateActitoPopupDescription = (actitoPopupDescription) => ({
    type: UPDATE_ACTITO_POPUP_DESCRIPTION,
    actitoPopupDescription
});

/**
 * Update actito interaction id
 * @param  {Number} interactionId
 * @return {Object}
 * @namespace Store/Cart/Action/updateActitoInteractionId */
export const updateActitoInteractionId = (interactionId) => ({
    type: UPDATE_ACTITO_INTERACTION_ID,
    interactionId
});

/**
 * Update is actito form loading flag
 * @param  {Boolean} isActitoFormLoading
 * @return {Object}
 * @namespace Store/Cart/Action/updateIsActitoFormLoading */
export const updateIsActitoFormLoading = (isActitoFormLoading) => ({
    type: UPDATE_IS_ACTITO_FORM_LOADING,
    isActitoFormLoading
});
