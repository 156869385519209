import { createRef } from 'react';
import {
    getFractionsQty,
    getRefWidth,
    getPaddingLeft
} from 'Util/Ecom';
import { throttle } from 'Util/Window';
import CmsBlock from 'Component/CmsBlock';

import EcomMenuFirstLevel from 'Component/EcomMenuFirstLevel';

import './EcomMenu.style';


export class EcomMenu extends React.PureComponent {
    ecomWrapperRef = createRef();
    wrapperWidthRef = createRef();
    ecomCmsLinkRef = createRef();
    ecomContentRef = createRef();

    wrapperRenderMap = [
        {
            ref: this.ecomWrapperRef,
            mix: { block: "ecom", elem:"wrapper" },
            isClickableOutside: true
        },
        {
            ref: this.wrapperWidthRef,
            mix: { block: "ecom", elem:"wrapper", mods: { isWidthRef: true } },
            isClickableOutside: false
        }
    ]

    state = {
        activeLinks: [],
        location: "",
        direction: true
    }


    componentDidMount() {
        this.setState({ location: location.pathname });
        this.onResize();

        window.addEventListener("resize", throttle(this.onResize.bind(this, 200)));
    }

    componentDidUpdate() {
        this.onResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', throttle(this.onResize.bind(this, 200)));
    }

    setActiveLinks(linkName, isRemoving, isClearingAll) {
        const { activeLinks: prevActiveLinks } = this.state;
        let activeLinks = [ ...prevActiveLinks ];

        if (isClearingAll) {
            this.setState({ activeLinks: [] });
            return;
        }

        if (
            prevActiveLinks.includes(linkName) && !isRemoving
            || !prevActiveLinks.length && isRemoving
        ) {
            return;
        }

        if (!isRemoving) {
            activeLinks.push(linkName);
        } else {
            activeLinks = activeLinks.filter((name) => name !== linkName);
        }

        this.setState({ activeLinks });
    }

    onResize() {
        this.initEcomMenu();
    }

    initEcomMenu() {
        const { allCategory } = this.props;

        const isCategoriesDefined = allCategory && typeof allCategory === 'object';

        if (!isCategoriesDefined) {
            return;
        }

        const categories = Object.values(allCategory);
        const fractionsQty = getFractionsQty(categories);

        this.setLinksWidths(fractionsQty);
    }


    setLinksWidths(qty) {
        const cmsLinkWidth = getRefWidth(this.ecomCmsLinkRef),
            fullBlockWidth = getRefWidth(this.ecomContentRef),
            currentLinksWidthRef = getRefWidth(this.wrapperWidthRef),
            fullLinksWidth = fullBlockWidth - cmsLinkWidth,
            singleLinkWidth = Math.round(fullLinksWidth / qty);

        if (this.ecomWrapperRef?.current?.children && !isNaN(singleLinkWidth)) {
            Array.prototype.forEach.call(this.ecomWrapperRef?.current?.children, (elem) => {
                const contentLeftPadding = getPaddingLeft(this.ecomContentRef);

                if (currentLinksWidthRef <= fullBlockWidth - contentLeftPadding) {
                    elem.style.maxWidth = `unset`;
                    return;
                }

                elem.style.maxWidth = `${ singleLinkWidth }px`;
            });
        }
    }

    renderMainLink(clickOutside) {
        const { allCategory, locations } = this.props;
        const { activeLinks } = this.state;

        return (
            <>
                { Object.values(allCategory).sort((a, b) => a.position - b.position).map(category => {
                    const {
                        include_in_menu,
                        name,
                        children
                    } = category;

                    if (include_in_menu === 0) {
                        return null
                    }

                    return (
                        <EcomMenuFirstLevel
                          locations={ locations }
                          category={ category }
                          clickOutside={ clickOutside }
                          name={ name }
                          children={ children }
                          activeLinks={ activeLinks }
                          setActiveLinks={ throttle(this.setActiveLinks.bind(this), 200) }
                        />
                    );
                }) }
            </>
        )
    }

    renderShippingCms() {
        const { isEcom } = this.props
        const { direction } = this.state

        if (!direction || !isEcom) {
            return null
        }

        return <span ref={ this.ecomCmsLinkRef }>
            <CmsBlock identifier={'header_shipping_text'}/>
        </span>;
    }

    renderOverlay() {
        const { activeLinks } = this.state
        return (
            <div
                block="darkOverlay"
                mods={{ isActive: !!activeLinks.length }}
            />
        );
    }

    render() {
        const { isEcom } = this.props
        const { direction } = this.state

        return (
            <div block="ecomWrapper" mods={{ isEcom, isHide: !direction }}>
                <div block="ecom">
                    <div block="ecom" elem="content" ref={ this.ecomContentRef }>
                    {
                            this.wrapperRenderMap.map(({ ref, mix, isClickableOutside }) => (
                                <div mix={ mix } ref={ ref }>
                                    {this.renderMainLink(isClickableOutside)}
                                </div>
                            ))
                    }
                    {this.renderShippingCms()}
                    {this.renderOverlay()}
                    </div>
                </div>
            </div>
        );
    }
}

export default EcomMenu;
