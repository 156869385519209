/* eslint-disable quote-props */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import Event, {
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART
} from '../../util/Event';

// TODO split
export class RemoveItemPlugin {
    handleRemoveItem = (args, callback, instance) => {
        callback(...args);

        let item = instance.props?.item;
        let quantity = item?.qty;

        if (!item) {
            const { totals, product: { sku } = {} } = instance.props;

            if (!totals || !sku) {
                callback(...args);
                return;
            }

            item = totals.items.filter(({ sku: itemSku }) => itemSku === sku)[0];
            quantity = item.qty;
        }

        this.handleRemoveState = {
            item,
            quantity
        };
    };

    removeProductFromCart = (args, callback) => {
        const { item, quantity } = this.handleRemoveState;

        return callback(...args)
            .then(
                result => {
                    Event.dispatch(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, {
                        item,
                        quantity
                    });

                    return result;
                }
            );
    };
}

const {
    handleRemoveItem,
    removeProductFromCart
} = new RemoveItemPlugin();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            handleRemoveItem
        }
    },
    'Component/ProductCard/Container': {
        'member-function': {
            handleRemoveItem
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            removeProductFromCart
        }
    }
};
