/* eslint-disable max-len */

import { store } from 'Store';
import { maxDate, minDate } from 'Util/Customer';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

//  const state = store.getState()
//  const { ConfigReducer: {minimum_password_length}} = state
// export const MIN_PASSWORD_LENGTH = !!minimum_password_length ? minimum_password_length : 6;
export const MIN_PASSWORD_LENGTH = 6;
export const MINMAX_AVRIL_CARD_LENGTH = 16;

export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace AvrilPwa-App/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace AvrilPwa-App/Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => value.length >= MIN_PASSWORD_LENGTH;

/** @namespace AvrilPwa-App/Component/Form/Config/validatePasswordNonRequired */
export const validatePasswordNonRequired = ({ value }) => !value || value.length >= MIN_PASSWORD_LENGTH;

/** @namespace AvrilPwa-App/Component/Form/Config/validateTelephone */
// eslint-disable-next-line no-magic-numbers
export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^[0-9-]?[0-9]+$/) && value.length < 12;

/** @namespace AvrilPwa-App/Component/Form/Config/validatePhone */
// eslint-disable-next-line no-magic-numbers
export const validatePhone = (target) => {
    const { props: {value} } = target; 
    const cleanedPhoneNumber = value.replace(/\D/g, '');
    return cleanedPhoneNumber.length === 10;
};

/** @namespace AvrilPwa-App/Component/Form/Config/validateInteger */
export const validateInteger = ({ value }) => value.match(/^[+-]?[0-9]*$/);

/** @namespace AvrilPwa-App/Component/Form/Config/validateFloat */
export const validateFloat = ({ value }) => value.match(/^[+-]?([0-9]*[.])?[0-9]*$/);

/** @namespace AvrilPwa-App/Component/Form/Config/isNotEmpty */
export const isNotEmpty = (target) => {
    const { value } = target

    if (typeof value === "string") {
        return value.trim().length > 0
    } else {
        const { input: { defaultValue } } = target;
        return defaultValue.trim().length > 0
    }
};

/** @namespace AvrilPwa-App/Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    const { current: { value: passwordValue } } = password || { current: {} };
    return value === passwordValue;
};

/** @namespace AvrilPwa-App/Component/Form/Config/validateAvrilPrivilegeCardLength */
export const validateAvrilPrivilegeCardLength = ({ input: { defaultValue } }) => {
    const cleanValue = defaultValue.trim().replaceAll('_', '').replaceAll(' ', '');
    return cleanValue.length === MINMAX_AVRIL_CARD_LENGTH;
};

/** @namespace AvrilPwa-App/Component/Form/Config/validateCard */
export const validateCard = ({ input: { defaultValue } }) => {
    const cleanValue = defaultValue.trim().replaceAll('_', '').replaceAll(' ', '');
    return cleanValue.match(/^[\d\s]+$/g);
};

/** @namespace AvrilPwa-App/Component/Form/Config/validatePostalCA */
export const validatePostalCA = (target) => {
    const { value, input: { defaultValue } = {} } = target;
    const existingVal = typeof value === "string" ? value : defaultValue;

    return existingVal.replace('_', '').match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/)
};

export const validateBirthday = ({ value }) => {
    const maxDateFormatted = maxDate;
    const minDateFormatted = minDate;
    return value >= minDateFormatted && value <= maxDateFormatted;
};

/** @namespace AvrilPwa-App/Component/Form/Config/validatePostalUS */
export const validatePostalUS = (target) => {
    const { value, input: { defaultValue } = {} } = target;
    const existingVal = typeof value === "string" ? value : defaultValue;

    return existingVal.replaceAll('_', '').replaceAll(/-$/g, '').match(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
};

export const validateBirthdate = ({ value }) => {
    const currentDate = new Date();
    const minDate = new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());
    const maxDate = new Date(currentDate.getFullYear() - 14, currentDate.getMonth(), currentDate.getDate() - 1);
    const birthdate = new Date(value);
    if (isNaN(birthdate.getTime())) {
        return false;
    }
    if (birthdate < minDate || birthdate > maxDate) {
        return false;
    }
    return true;
};


/** @namespace  AvrilPwa-App/Component/Form/Config/formConfig */
export const formConfig = () => ({
    cardNumberLength: {
        validate: validateAvrilPrivilegeCardLength,
        message: __('Text length does not satisfy specified text range')
    },
    cardNumber: {
        validate: validateCard,
        message: __('Invalid card number!')
    },
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password should be at least %s characters long', MIN_PASSWORD_LENGTH)
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    phone: {
        validate: validatePhone,
        message: __('Phone number is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    password_non_required: {
        validate: validatePasswordNonRequired,
        message: __('Password should be at least %s characters long', MIN_PASSWORD_LENGTH)
    },
    postal_CA: {
        validate: validatePostalCA,
        message: __('Please enter a valid postal code. For example H1H 1H1.')
    },
    postal_US: {
        validate: validatePostalUS,
        message: __('Please enter a valid zip code. For Example 90902 or 90602-1234')
    },
    integer: {
        validate: validateInteger,
        message: __('Please enter a valid integer number')
    },
    float: {
        validate: validateFloat,
        message: __('Please enter a valid float number')
    },
    birthdate: {
        validate: validateBirthdate,
        message: __('Please enter a valid date')
    }
});

export default formConfig();
