import { PureComponent } from "react";

import BlogWidget from "Component/BlogWidget";
import BlogTag from "Component/BlogTag";
import TextPlaceholder from "Component/TextPlaceholder";

import "./BlogTagsWidget.style";

export class BlogTagsWidget extends PureComponent {
    renderTag = (tagData) => <BlogTag tag={tagData} />;

    renderNoTagsPlaceholders() {
        return (
            <div>
                <TextPlaceholder length="short" />
            </div>
        );
    }

    renderTags() {
        const { blogTags: { items } = {} } = this.props;

        const renderMethod = items?.length
            ? () => items.map(this.renderTag)
            : this.renderNoTagsPlaceholders;

        return (
            <div block="BlogTagsWidget" elem="TagsWrapper">
                {renderMethod()}
            </div>
        );
    }

    render() {
        const {
            blogTags: { title, items },
        } = this.props;

        if (!items) {
            return null;
        }

        return (
            <BlogWidget
                mix={{ block: "BlogTagsWidget", elem: "WidgetWrapper" }}
                title={title || __("Related topics")}
            >
                {this.renderTags()}
            </BlogWidget>
        );
    }
}

export default BlogTagsWidget;
