/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright Â© Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Loader.extended.style';

export class Loader extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired
    };

    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_i)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM32 58C46.3594 58 58 46.3594 58 32C58 17.6406 46.3594 6 32 6C17.6406 6 6 17.6406 6 32C6 46.3594 17.6406 58 32 58Z" fill="#BBD422" />
                    </g>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M52.5061 52.5061C51.3345 51.3345 51.3502 49.4445 52.38 48.1465C55.3146 44.4475 57.2634 39.9309 57.8295 34.994C58.0182 33.3479 59.3431 32 61 32V32C62.6569 32 64.0146 33.3461 63.8595 34.9956C63.5609 38.1708 62.7889 41.2891 61.5641 44.2459C60.3394 47.2026 58.6803 49.9536 56.6463 52.4098C55.5895 53.6859 53.6777 53.6777 52.5061 52.5061V52.5061Z" fill="#E4F779" />
                    <defs>
                        <filter id="filter0_i" x="0" y="0" width="64" height="65" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="0.5" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.267299 0 0 0 0 0.420833 0 0 0 0 0.0718924 0 0 0 0.09 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
                        </filter>
                    </defs>
                </svg>
            </div>
        );
    }

    render() {
        const { isLoading, mix } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader" mix={ mix }>
                <div block="Loader" elem="Scale">
                    {this.renderMain()}
                </div>
            </div>
        );
    }
}

export default Loader;
