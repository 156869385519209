import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Loader from 'Component/Loader';
import Link from 'Component/Link';
import Popup from 'Component/Popup';
import PickupLocationForm from 'Component/PickupLocationForm';
import PickupSchedule from 'Component/PickupSchedule';
import PickupStoreSelector from 'Component/PickupStoreSelector';
import { getStoreName } from 'Util/Stores';
import { ReactComponent as CarIcon } from './icons/car.svg'
import { ReactComponent as TruckIcon } from './icons/truck.svg'

import {
    PICKUP_OVERLAY_KEY,
    HOME_DELIVERY_OPTION,
    PICKUP_OPTION,
    DELIVERY_STEP,
    SCHEDULE_STEP
} from './PickupOverlay.config';

import './PickupOverlay.style';

export class PickupOverlay extends PureComponent {
    static propTypes = {
        buttonId: PropTypes.string.isRequired,
        onDeliveryOptionClick: PropTypes.func.isRequired,
        confirmButtonRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired,
        isDelivery: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        onBackClick: PropTypes.func.isRequired,
        onDeliveryOptionClick: PropTypes.func.isRequired,
        onShippingEstimationFieldsChange: PropTypes.func.isRequired,
        isShippingAddressAllowed: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number
        ]).isRequired
    };

    static defaultProps = {

    };

    overlayRenderMap = {
        [DELIVERY_STEP]: this.renderDeliveryStep.bind(this),
        [SCHEDULE_STEP]: this.renderScheduleStep.bind(this)
    }

    renderDeliveryButton(text, option, Icon) {
        const { onDeliveryOptionClick, buttonId, isMobile } = this.props;

        return (
            <button
              block="PickupOverlay"
              elem="DeliveryButton"
              mods={ { isActive: buttonId === option } }
              onClick={ () => onDeliveryOptionClick(option) }
            >
                { !isMobile && <div><Icon /></div> }
                { text }
            </button>
        );
    }

    renderDeliveryOptionsRow() {
        const { isMobile } = this.props;

        return (
            <div
              block="PickupOverlay"
              elem="DeliveryOptionsRow"
            >
                { this.renderDeliveryButton(__('Home delivery'), HOME_DELIVERY_OPTION, TruckIcon) }
                { !isMobile && <span block="PickupOverlay" elem="OrText">{ __('or') }</span> }
                { this.renderDeliveryButton(__('Pick up in store'), PICKUP_OPTION, CarIcon) }
            </div>
        );
    }

    renderFormAndMap() {
        const { isDelivery } = this.props;

        return (
            <div
              block="PickupOverlay"
              elem="FormAndMap"
              mods={ { isDelivery } }
            >
                { this.renderLocation() }
            </div>
        );
    }

    renderLocationForm() {
        const {
            buttonId,
            isMobile,
            shippingMethods
        } = this.props;

        const isDelivery = buttonId === HOME_DELIVERY_OPTION;
        const isShippingAvailable = !!shippingMethods.length;

        if (!isDelivery) {
            return null;
        }

        return (
            <div
              block="PickupOverlay"
              elem="LocationForm"
            >
                { isMobile && <span block="PickupOverlay" elem="LocationIcon" /> }
                <h4
                  block="PickupOverlay"
                  elem="FormHeading"
                >{ __('Enter your location') }</h4>
                <div
                  block="PickupOverlay"
                  elem="FormWrapper"
                  mods={ { isDelivery, isShippingAvailable } }
                >
                    { !isMobile && <span block="PickupOverlay" elem="LocationIcon" /> }
                    { this.renderLocationFields() }
                </div>
            </div>
        );
    }

    renderLocation() {
        return (
            <div
              block="PickupOverlay"
              elem="LocationWrapper"
            >
                {/* { this.renderLocationForm() } */}
                { this.renderPickupStoreSelector() }
            </div>
        );
    }

    renderPickupStoreSelector() {
        const {
            isDelivery,
            selectedStoreCode,
            setSelectedStoreCode
        } = this.props;

        if (isDelivery) {
            return null;
        }

        return <PickupStoreSelector
          selectedStoreCode={ selectedStoreCode }
          setSelectedStoreCode={ setSelectedStoreCode }
        />;
    }

    renderLocationFields() {
        const {
            onShippingEstimationFieldsChange,
            isShippingAddressAllowed,
            isDelivery,
            confirmButtonRef,
            isDeliveryOptionsLoading,
            shippingMethods,
            selectedShippingMethod,
            setLocationFormRegionId
        } = this.props;

        return <PickupLocationForm
          address={ {} }
          isDelivery={ isDelivery }
          shippingMethods={ shippingMethods }
          isDeliveryOptionsLoading={ isDeliveryOptionsLoading }
          selectedShippingMethod={ selectedShippingMethod }
          confirmButtonRef={ confirmButtonRef }
          onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
          isShippingAddressAllowed={ isShippingAddressAllowed }
          setLocationFormRegionId={ setLocationFormRegionId }
        />;
    }

    renderConfirmBtn() {
        const {
            isDelivery,
            isShippingAddressAllowed,
            confirmButtonRef,
            onConfirmClick,
            overlayStep,
            selectedTimeslot,
            isCartProductsAdding
        } = this.props;

        const isTimeslotChosen = Object.keys(selectedTimeslot).length !== 0
        const text = isDelivery || overlayStep === SCHEDULE_STEP
            ? __('Confirm')
            : __('Select a time slot');
        const isDisabled = isCartProductsAdding
            || (isDelivery && !isShippingAddressAllowed)
            ||  (overlayStep === SCHEDULE_STEP)
            && !isTimeslotChosen;

        // No redirect to '/cart' if overlay is opened at delivery step of pickup option
        if (!isDelivery) {
            return (
                <button
                  block="PickupOverlay"
                  elem="ConfirmButton"
                  mods={ { isDeliverySelected: isDelivery } }
                  disabled={ isDisabled }
                  ref={ confirmButtonRef }
                  onClick={ onConfirmClick }
                >
                    { text }
                </button>
            );
        }

        // Link to '/cart' for the last overlay's steps
        return (
            <button
              block="PickupOverlay"
              elem="ConfirmButton"
              mods={ { isDeliverySelected: isDelivery } }
              // disabled={ isDelivery && !isShippingAddressAllowed }
              ref={ confirmButtonRef }
              onClick={ onConfirmClick }
            >
                { text }
            </button>
        )
    }

    renderActionsRow() {
        return (
            <div
              block="PickupOverlay"
              elem="ActionsWrapper"
            >
                <Link
                  block="PickupOverlay"
                  elem="InfoLink"
                  to="/politique-de-livraison"
                  target="_blank"
                >
                    { __('Info and prices') }
                </Link>
                { this.renderConfirmBtn() }
            </div>
        );
    }

    renderDeliveryHeader() {
        return (
            <header block="Popup" elem="Header">
                <h3 block="Popup" elem="Heading">
                    { __('How do you want to collect your items ?') }
                </h3>
            </header>
        );
    }

    renderScheduleHeader() {
        const { isMobile } = this.props;

        return (
            <header
              block="PickupOverlay"
              elem="ScheduleHeader"
              mix={ { block: "Popup", elem: "Header" } }
            >
                { isMobile
                    ? this.renderMobileScheduleHeader()
                    : this.renderDesktopScheduleHeader() }
            </header>
        );
    }

    renderDesktopScheduleHeader() {
        const { selectedStoreCode, pickupLocations, onBackClick } = this.props;

        const storeForPickupName = getStoreName(pickupLocations, selectedStoreCode);

        return (
            <>
                <button
                  block="PickupOverlay"
                  elem="BackToStoreBtn"
                  onClick={ onBackClick }
                />
                <div
                  block="PickupOverlay"
                  elem="ScheduleHeading"
                >
                    <h3
                      block="PickupOverlay"
                      elem="Header"
                      mix={ { block:"Popup", elem: "Heading" } }
                    >
                        { __('Select another store') }
                    </h3>
                    <p block="PickupOverlay" elem="HeadingChosenStore">
                        { __(`Vous avez choisi de retirer vos courses au point de retrait d’`) }
                        <span block="PickupOverlay" elem="HeadingChosenStoreName">{ storeForPickupName }</span>
                    </p>
                    <p block="PickupOverlay" elem="HeadingText">
                        { __('2. Choisissez votre créneau') }
                    </p>
                </div>
            </>
        );
    }

    renderMobileScheduleHeader() {
        const { selectedStoreCode, pickupLocations, onBackClick } = this.props;

        const storeForPickupName = getStoreName(pickupLocations, selectedStoreCode);

        return (
            <>
                <div
                  block="PickupOverlay"
                  elem="ScheduleMobileHeaderWrapper"
                >
                    <button
                      block="PickupOverlay"
                      elem="BackToStoreBtn"
                      onClick={ onBackClick }
                    />
                    <h3
                      block="PickupOverlay"
                      elem="Header"
                      mix={ { block:"Popup", elem: "Heading" } }
                    >
                        { __('Select another store') }
                    </h3>
                </div>
                <div>
                    <p block="PickupOverlay" elem="HeadingChosenStore">
                        { __(`Vous avez choisi de retirer vos courses au point de retrait d’`) }
                        <span block="PickupOverlay" elem="HeadingChosenStoreName">{ storeForPickupName }</span>
                    </p>
                </div>
                <div>
                    <p block="PickupOverlay" elem="HeadingText">
                        { __('2. Choisissez votre créneau') }
                    </p>
                </div>
            </>
        );
    }

    renderHeader() {
        const { overlayStep } = this.props;

        if (overlayStep === SCHEDULE_STEP) {
            return this.renderScheduleHeader();
        }

        return this.renderDeliveryHeader();
    }

    renderDeliveryStep() {
        return (
            <section
              block="PickupOverlay"
              elem="Wrapper"
            >
                { this.renderDeliveryOptionsRow() }
                { this.renderFormAndMap() }
            </section>
        );
    }

    renderScheduleStep() {
        const {
            onTimeSlotClick,
            onDayClick,
            selectedTimeslot,
            selectedDate
        } = this.props;

        return <PickupSchedule
          selectedTimeslot={ selectedTimeslot }
          selectedDate={ selectedDate }
          onTimeSlotClick={ onTimeSlotClick }
          onDayClick={ onDayClick }
        />;
    }

    renderContent() {
        const { overlayStep } = this.props;

        const render = this.overlayRenderMap[overlayStep];
        return render();
    }

    renderCloseBtn() {
        const { hidePopup, isPickupPopupCloseVisible } = this.props;

        if (!isPickupPopupCloseVisible) {
            return null;
        }

        return <button
            block="Popup"
            elem="CloseBtn"
            mods={ { isPickup: true } }
            onClick={ hidePopup }
        />;
    }

    render() {
        const { overlayStep, isLoading, isPickupPopupCloseVisible } = this.props;

        const isScheduleStep = overlayStep === SCHEDULE_STEP;

        return (
            <Popup
              id={ PICKUP_OVERLAY_KEY }
              clickOutside={ false }
              isCloseableOnEsc={ isPickupPopupCloseVisible }
              mix={ { block: "PickupOverlay", mods: { isScheduleStep } } }
            >
                <Loader isLoading={ isLoading } />
                <div>
                    { this.renderHeader() }
                    { this.renderContent() }
                </div>
                <div block="PickupOverlay" elem="ActionsRow">
                    { this.renderActionsRow() }
                </div>
                { this.renderCloseBtn() }
            </Popup>
        );
    }
}

export default PickupOverlay;
