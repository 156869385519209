
import { StoreSwitcher as SourceStoreSwitcher } from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

import './StoreSwitcher.style';

export class StoreSwitcher extends SourceStoreSwitcher {
    renderStoreSwitcher() {
        const {
            onStoreSwitcherClick,
            storeLabel,
            mix
        } = this.props;

        return (
           <div block="StoreSwitcher" mix={ { mix } }>
                <button
                    block="StoreSwitcher"
                    elem="Title"
                    onClick={ onStoreSwitcherClick }
                >
                    { storeLabel }
                </button>
            </div>
       );
    }

    render() {
        return this.renderStoreSwitcher();
    }
}

export default StoreSwitcher;
