/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


import FieldInput from 'Component/FieldInput';

import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import { ReactComponent as ShowPasswordIcon } from './icons/eye.svg';

import {
    CHECKBOX_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXTAREA_TYPE,
    TEXT_NUMBER_TYPE,
    DATE_TYPE,
    TYPE_HIDDEN
} from './Field.config';

import './Field.extended.style';

export class Field extends SourceField {

    state = {
        ...this.state,
        isPasswordShown: false
    }

    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case TEXT_NUMBER_TYPE:
            return this.renderTextTypeNumber();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case DATE_TYPE:
            return this.renderTypeDate();
        case TYPE_HIDDEN:
            return this.renderTypeHidden();
        default:
            return this.renderTypeText();
        }
    }

    renderRadioButton() {
        const {
            id,
            onClick
        } = this.props;

        return (
            <label htmlFor={ id }>
                <FieldInput
                  { ...this.props }
                  type="radio"
                  onChange={ onClick }
                />
                <label htmlFor={ id } />
            </label>
        );
    }

    renderLabel() {
        const { id, label, validation, labelRef, optional } = this.props;
        const isRequired = validation.includes('notEmpty');

        if (!label) {
            return null;
        }

        return (
            <label
              block="Field"
              elem="Label"
              mods={ { isRequired } }
              htmlFor={ id }
              ref={labelRef}
            >
                { label }
                <span
                  block="Field"
                  elem="LabelOptional"
                >{ optional ? ' (optional)' : null }</span>
                { this.renderStar(isRequired) }
            </label>
        );
    }

    renderTextTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            onChange,
            setQty
        } = this.props;

        return (
            <>
                <button
                  disabled={ +value === min || !value }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => onChange(value - 1) }
                />
                <FieldInput
                  { ...this.props }
                  type="text"
                //   readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => setQty(value, e) }
                  onKeyDown={ onKeyEnterDown }
                />
                <button
                  disabled={ +value === max }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => onChange(+value + 1) }
                />
            </>
        );
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            onHandleChange
        } = this.props;

        return (
            <>
                <button
                  disabled={ +value === min }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => onHandleChange(e, +value - 1) }
                />
                <FieldInput
                  { ...this.props }
                  type="number"
                  readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e, e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                />
                <button
                  disabled={ +value === max }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => onHandleChange(e, +value + 1) }
                />
            </>
        );
    }

    renderStar(isRequired) {
        const { isRequiredWithStar } = this.props;

        if (!isRequired || !isRequiredWithStar) {
            return null;
        }

        return (
            <span
              block="Field"
              elem="RequiredStar"
            >
                *
            </span>
        );
    }

    renderTypePassword() {
        const { canShowPassword } = this.props;

        if (canShowPassword) {
            return this.renderTypePasswordWithSwitch();
        }

        return (
            <FieldInput
              { ...this.props }
              type="password"
            />
        );
    }

    renderTypeHidden() {
        return (
            <FieldInput
              { ...this.props }
              type="hidden"
            />
        );
    }

    handleOnEyeClick() {
        const { isPasswordShown } = this.state;

        this.setState({ isPasswordShown: !isPasswordShown })
    }

    renderTypePasswordWithSwitch() {
        const { isPasswordShown } = this.state;

        return (
            <>
                <FieldInput
                    { ...this.props }
                    type={ isPasswordShown ? 'text' : 'password'}
                />
                <button
                    type="button"
                    block="Field"
                    elem="Eye"
                    onClick={ () => this.handleOnEyeClick()}
                >
                    <ShowPasswordIcon />
                </button>
            </>
        );
    }

    renderTypeDate() {
        return (
            <>
                <FieldInput
                    { ...this.props }
                    type="date"
                />
            </>
        );
    }

    renderMessage() {
        const { message, errorForFieldset } = this.props;

        if (!message || errorForFieldset) {
            return null;
        }

        return (
            <p block="Field" elem="Message">
                { message }
            </p>
        );
    }

    render() {
        const {
            mix,
            type,
            message
        } = this.props;

        const modsType = type === TEXT_NUMBER_TYPE
            ? NUMBER_TYPE
            : type;

        return (
            <div
              block="Field"
              mods={ { type: modsType, hasError: !!message } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }
}


export default Field;
