import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { sortPickupStores } from 'Util/Stores';
import { STORE_SELECTOR_STEP, STORE_CONFIRMATION_STEP } from './ClosestStoreOverlay.config';

import ClosestStoreOverlay from './ClosestStoreOverlay.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapStateToProps = (state) => ({
    pickupLocations: state.ConfigReducer.pickupLocations,
    closestStore: state.ConfigReducer.getClosestStore,
    countries: state.ConfigReducer.countries,
    code: state.ConfigReducer.code,
    device: state.ConfigReducer.device,
    isLoading: state.ConfigReducer.isLoading
});

export const mapDispatchToProps = (dispatch) => ({
    assignStoreToCustomer: (storeCode) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.assignStoreToCustomer(dispatch, storeCode)
    ),
    goToPrevNavState: (navigationType) => dispatch(goToPreviousNavigationState(navigationType))
});

export class ClosestStoreOverlayContainer extends PureComponent {
    state = {
        step: STORE_SELECTOR_STEP,
        isLoading: false,
        storeOption: {}
    }

    containerProps = () => {
        const {
            pickupLocations: { items },
            onLocationPopupAction,
            hideActiveOverlay,
            countries,
            isLocationPopupOpen,
            code,
            closestStore,
            device: { isMobile }
        } = this.props;

        const { pickup_location_code: closestStoreCode } = closestStore;

        const {
            step,
            storeOption,
            isLoading
        } = this.state;

        const pickupLocations = sortPickupStores(items, closestStore);

        return {
            step,
            storeOption,
            pickupLocations,
            countries,
            closestStoreCode,
            onLocationPopupAction,
            hideActiveOverlay,
            isLocationPopupOpen,
            code,
            isLoading,
            isMobile
        };
    }

    containerFunctions = {
        onPopupClose: this.onPopupClose.bind(this),
        onChooseStoreClick: this.onChooseStoreClick.bind(this),
        hideOverlayClick: this.hideOverlayClick.bind(this),
        onConfirmClick: this.onConfirmClick.bind(this),
        setIsLoading: this.setIsLoading.bind(this)
    }

    async onConfirmClick(storeOption) {
        const { assignStoreToCustomer } = this.props;

        try {
            this.setIsLoading(true);
            await assignStoreToCustomer(storeOption);
            this.setIsLoading(false);

            this.hideOverlayClick();
        } catch(e) {
            this.setIsLoading(false);
        }
    }

    setIsLoading(isLoading) {
        this.setState({ isLoading });
    }

    onPopupClose() {
        const { onLocationPopupAction } = this.props;

        this.setState({ step: STORE_SELECTOR_STEP });
        onLocationPopupAction(false);
    }

    hideOverlayClick() {
        const { onLocationPopupAction, hideActiveOverlay, goToPrevNavState } = this.props;

        onLocationPopupAction(false);
        hideActiveOverlay();
        goToPrevNavState(TOP_NAVIGATION_TYPE);
        this.setState({ step: STORE_SELECTOR_STEP});
    }

    onChooseStoreClick(storeOption) {
        this.setState({ step: STORE_CONFIRMATION_STEP, storeOption });
    }

    render() {
        return <ClosestStoreOverlay
          { ...this.containerProps() }
          { ...this.containerFunctions }
        />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClosestStoreOverlayContainer);
