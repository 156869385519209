/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright Â© Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { createPortal } from "react-dom";

import CategoryConfigurableAttributes from "Component/CategoryConfigurableAttributes";
import Overlay from "Component/Overlay";
import Switch from "Component/Switch";
import ClickOutside from "Component/ClickOutside";
import Field from "Component/Field";
import MoreFiltersBtn from "Component/MoreFiltersBtn";

import { CategoryFilterOverlay as SourceCategoryFilterOverlay } from "SourceComponent/CategoryFilterOverlay/CategoryFilterOverlay.component";
import { DEFAULT_LOCATION_CODE } from "Component/ClosestStoreOverlay/ClosestStoreOverlay.config";

import { isMobile } from "Util/Mobile";
import { CATEGORY_FILTER_OVERLAY_ID } from "./CategoryFilterOverlay.config";
import { ReactComponent as CloseIcon } from "Component/Header/icons/close.svg";
import { SELECT_TYPE } from "Component/Field/Field.config";

import { IS_DELIVERABLE_FILTER_KEY } from './CategoryFilterOverlay.config';

import "./CategoryFilterOverlay.extended.style";

export const DESKTOP = "desktop";
export const MOBILE = "mobile";

export class CategoryFilterOverlay extends SourceCategoryFilterOverlay {
    renderFilters(filters = null, isWithoutShowMore = false, mix = null) {
        const {
            device: { isMobile },
            customFiltersValues,
            toggleCustomFilter,
            isMatchingInfoFilter,
            getFilterUrl,
            getVisibleFilters,
            isNavigationInFilter
        } = this.props;

        let filtersToRender = filters || getVisibleFilters();

        if (isNavigationInFilter && !isMobile) {
            const { alley, ...restFilters } = filtersToRender;
            filtersToRender = restFilters;
        }

        return (
            <CategoryConfigurableAttributes
                mix={{
                    block: "CategoryFilterOverlay",
                    elem: "Attributes",
                    mix,
                }}
                isReady={isMatchingInfoFilter}
                configurable_options={filtersToRender}
                getLink={getFilterUrl}
                parameters={customFiltersValues}
                updateConfigurableVariant={toggleCustomFilter}
                isWithoutShowMore={isWithoutShowMore}
            />
        );
    }

    onResetClick = () => {
        const { onSeeResultsClick, resetFilters } = this.props;

        if (!isMobile.any()) {
            onSeeResultsClick();
        }

        resetFilters();
    };

    renderResetButton() {
        const {
            device: { isMobile },
            isContentFiltered,
            isMatchingInfoFilter,
            isSortOverlayOpened,
        } = this.props;

        const isInvisible =
            !isContentFiltered ||
            !isMatchingInfoFilter ||
            (isSortOverlayOpened && isMobile);

        return (
            <button
                block="CategoryFilterOverlay"
                elem="ResetButton"
                mods={{ isMobile, isInvisible }}
                onClick={this.onResetClick}
            >
                {__("Clear filters")}
            </button>
        );
    }

    renderValidateButton() {
        const { onCloseButtonClick } = this.props;

        return (
            <button
                block="CategoryFilterOverlay"
                elem="ValidateButton"
                onClick={onCloseButtonClick}
            >
                {__("Validate")}
            </button>
        );
    }

    renderFiltersHeading() {
        const { hideActiveOverlay } = this.props;

        if (!isMobile.any()) {
            return null;
        }

        return (
            <div block="CategoryFilterOverlay" elem="HeadingWrapper">
                <h2 block="CategoryFilterOverlay" elem="Heading">
                    {__("Filter By")}
                </h2>
                <button
                    block="CategoryFilterOverlay"
                    elem="HeadingButton"
                    onClick={hideActiveOverlay}
                >
                    <CloseIcon />
                </button>
            </div>
        );
    }

    renderFiltersMobileFooter() {
        const { isContentFiltered, isMatchingInfoFilter } = this.props;

        const isResetRendered =
            !isContentFiltered || !isMatchingInfoFilter ? false : true;

        return (
            <div
                block="CategoryFilterOverlay"
                elem="PopupFooter"
                mods={{ isResetRendered }}
            >
                {this.renderResetButton(MOBILE)}
                {this.renderValidateButton()}
            </div>
        );
    }

    renderSelectedFilterTag() {
        const { customFiltersValues, availableFilters, toggleCustomFilter } =
            this.props;

        let selectedFilters = Object.entries(customFiltersValues);
        if (!selectedFilters.length || isMobile.any()) {
            return null;
        }

        if (Object.entries(availableFilters).length > 0) {
            const tags = selectedFilters.map((e) => {
                let childTags = e[1].map((el) => {
                    const label = (function () {
                        if (
                            // step by step check
                            !availableFilters ||
                            !availableFilters[e[0]] ||
                            !availableFilters[e[0]].attribute_options ||
                            !availableFilters[e[0]].attribute_options[el]
                        ) {
                            return null;
                        }

                        return availableFilters[e[0]].attribute_options[el];
                    })();

                    if (label === null) {
                        return null;
                    }

                    return {
                        label: availableFilters[e[0]].attribute_options[el]
                            ?.label,
                        values: el,
                        code: availableFilters[e[0]].attribute_code,
                    };
                });
                return childTags;
            });
            return tags
                .flat()
                .filter((e) => e !== null)
                .map((e) => {
                    return (
                        <p
                            key={e.values}
                            block="CategoryFilterOverlay"
                            onClick={() => toggleCustomFilter(e.code, e.values)}
                            elem="Tags"
                        >
                            {e.label}
                        </p>
                    );
                });
        }
    }

    renderMoreFiltersOverlay() {
        const {
            device: { isMobile },
            isMoreFiltersActive,
            onFiltersOverlayOpen,
            moreFiltersBtnRef,
            isMatchingInfoFilter
        } = this.props;

        if (!isMatchingInfoFilter) {
            return null;
        }

        return <MoreFiltersBtn
            isMoreFiltersActive={isMoreFiltersActive}
            onFiltersOverlayOpen={onFiltersOverlayOpen}
            moreFiltersBtnRef={moreFiltersBtnRef}
            buttonText={ isMobile ? __("Filters") : null }
        />;
    }

    renderDefaultFilters() {
        const {
            device: { isMobile },
        } = this.props;

        return (
            <>
                <div
                    block="CategoryFilterOverlay"
                    elem="FiltersAndResetContainer"
                >
                    {!isMobile && this.renderAvailabilitySwitchRow()}
                    {!isMobile && this.renderFilters()}
                    {this.renderMoreFiltersOverlay()}
                    {!isMobile && this.renderResetButton()}
                </div>
            </>
        );
    }

    renderAvailabilitySwitchRow() {
        const {
            isDeliverableSwitched,
            toggleIsDeliverableSwitched,
            isMatchingInfoFilter,
            availableFilters
        } = this.props;

        if (
            !isMatchingInfoFilter ||
            !(IS_DELIVERABLE_FILTER_KEY in availableFilters)
        ) {
            return null;
        }

        return (
            <button
                block="CategoryFilterOverlay"
                elem="AvailabilityFilterRow"
                mods={{ isEnabled: isDeliverableSwitched }}
                onClick={toggleIsDeliverableSwitched}
            >
                <Switch
                    isActivated={isDeliverableSwitched}
                    mix={{
                        block: "CategoryFilterOverlay",
                        elem: "AvailabilitySwitch",
                    }}
                />
                <span block="CategoryFilterOverlay" elem="AvailabilityText">
                    {__("Delivery-eligible products")}
                </span>
            </button>
        );
    }

    renderEmptyFilters() {
        return (
            <>
                { this.renderResetButton() }
                { this.renderSeeResults() }
            </>
        );
    }

    renderContent() {
        const { totalPages, areFiltersEmpty, isProductsLoading } = this.props;

        if (!isProductsLoading && totalPages === 0) {
            return this.renderEmptyFilters();
        }

        if (areFiltersEmpty) {
            return this.renderMinimalFilters();
        }

        return this.renderDefaultFilters();
    }

    renderPopupHeader() {
        const { hideActiveOverlay, isSortOverlayOpened } = this.props;

        const text = isSortOverlayOpened ? __("Sort by") : __("Filters");

        return (
            <div block="CategoryFilterOverlay" elem="PopupHeader">
                <h4 block="CategoryFilterOverlay" elem="PopupHeaderText">
                    {text}
                </h4>
                <button
                    block="CategoryFilterOverlay"
                    elem="PopupHeaderButton"
                    onClick={hideActiveOverlay}
                >
                    <CloseIcon />
                </button>
            </div>
        );
    }

    renderPopupFilters() {
        const { getPopupVisibleFilters } = this.props;

        const filters = getPopupVisibleFilters();

        return (
            <div block="CategoryFilterOverlay" elem="PopupFiltersWrapper">
                {this.renderFilters(filters, true, {
                    block: "CategoryFilterOverlay",
                    elem: "PopupFilters",
                })}
            </div>
        );
    }

    renderOverlayAvailabilitySwitch() {
        return (
            <div block="CategoryFilterOverlay" elem="OverlayAvailabilitySwitch">
                {this.renderAvailabilitySwitchRow()}
            </div>
        );
    }

    renderFiltersOverlay() {
        const { onVisible, closeOverlay, activeOverlay, onHide, moreFiltersBtnRef } = this.props;

        return (
            <Overlay
                onVisible={onVisible}
                onHide={onHide}
                mix={{
                    block: "CategoryFilterOverlay",
                    elem: "Popup",
                    mix: {
                        block: "CartOverlay",
                        mods: {
                            isVisible:
                                activeOverlay === CATEGORY_FILTER_OVERLAY_ID,
                        },
                    },
                }}
                id={CATEGORY_FILTER_OVERLAY_ID}
                isRenderInPortal={true}
            >
                <ClickOutside onClick={closeOverlay} exceptionsElemsRefs={[moreFiltersBtnRef]}>
                    <div
                        block="CategoryFilterOverlay"
                        elem="FiltersPopup"
                        ref={this.filters}
                    >
                        {this.renderPopupHeader()}
                        {this.renderOverlayContent()}
                        {this.renderFiltersMobileFooter()}
                        {this.renderLoader()}
                    </div>
                </ClickOutside>
            </Overlay>
        );
    }

    renderOverlayContent() {
        const {
            isSortOverlayOpened,
            device: { isMobile },
        } = this.props;

        if (isSortOverlayOpened && isMobile) {
            return this.renderSortOverlayContent();
        }

        return (
            <div block="CategoryFilterOverlay" elem="OverlayContent">
                {this.renderOverlayAvailabilitySwitch()}
                {this.renderPopupFilters()}
            </div>
        );
    }

    renderMobileFilters() {
        const { renderCategorySort } = this.props;

        return (
            <>
                <div block="CategoryFilterOverlay" elem="MobileFiltersRow">
                    {this.renderContent()}
                    {renderCategorySort()}
                </div>
                <div
                    block="CategoryFilterOverlay"
                    elem="MobileFiltersSwitchRow"
                >
                    {this.renderAvailabilitySwitchRow()}
                </div>
            </>
        );
    }

    renderDesktopFilters() {
        return <>{this.renderContent()}</>;
    }

    renderFiltersNavContent() {
        const {
            device: { isMobile },
        } = this.props;

        return (
            <>
                {isMobile
                    ? this.renderMobileFilters()
                    : this.renderDesktopFilters()}

                {this.renderLoader()}
            </>
        );
    }

    renderSortOverlayContent() {
        const { selectOptions, selectedSort, onSortSelectChange } = this.props;

        const { sortDirection, sortKey } = selectedSort;

        return (
            <Field
                type={SELECT_TYPE}
                selectOptions={selectOptions}
                onChange={onSortSelectChange}
                value={`${sortDirection} ${sortKey}`}
                isContentVisible
                isOptionsComponent
                mix={{
                    block: "CategoryFilterOverlay",
                    elem: "SortOverlayOptions",
                }}
            />
        );
    }

    render() {
        const { totalPages, isProductsLoading, isContentFiltered } = this.props;

        if (!isProductsLoading && totalPages === 0 && !isContentFiltered) {
            return <div block="CategoryFilterOverlay" />;
        }

        const filtersOverlay = this.renderFiltersOverlay();
        const filtersSlotNode = document.getElementById(
            "HeaderForFiltersPortalSlot"
        );

        return (
            <nav block="CategoryFilterOverlay">
                {this.renderFiltersNavContent()}
                {createPortal(filtersOverlay, filtersSlotNode)}
            </nav>
        );
    }
}

export default CategoryFilterOverlay;
