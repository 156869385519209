import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import ClosestStoreOverlay from 'Component/ClosestStoreOverlay';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';

import { HEADER_CLOSEST_STORE_POPUP } from './HeaderClosestStore.config';

import './HeaderClosestStore.style';

export class HeaderClosestStore extends PureComponent {
    renderClosestStoreData() {
        const {
            closestStore: {
                name,
                street,
                city,
                postcode,
                regionCode
            } = {}
        } = this.props;

        if (!name) {
            return null;
        }

        return (
            <div
              block="HeaderClosestStore"
              elem="Address"
            >
                <p
                  block="HeaderClosestStore"
                  elem="AddressText"
                >
                    { name }<br />
                    { street }<br />
                    { city }, { regionCode } { postcode }
                </p>
            </div>
        );
    }

    renderInformationButton() {
        const {
            closestStore: {
                cms_page_url_en,
                cms_page_url_fr
            } = {},
            storeCode
        } = this.props;

        const pathname = storeCode === 'fr' ? cms_page_url_fr : cms_page_url_en;

        // does nothing, if the link is undefined for some reason
        return (
            <Link
              mix={ { block: "HeaderClosestStore", elem: "InfoBtn" } }
              to={ pathname || "#" }
              target="_blank"
            >
                { __('View more information') }
            </Link>
        );
    }

    renderChangeStoreBtn() {
        const { onChangeStoreClick } = this.props;

        return (
            <button
                  block="HeaderClosestStore"
                  elem="ChangeStoreBtn"
                  onClick={ onChangeStoreClick }
                >
                    { __('Change store') }
            </button>
        );
    }

    renderMobilePopup() {
        const { isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <Popup
              id={ HEADER_CLOSEST_STORE_POPUP }
              mix={ { block: 'HeaderClosestStorePopup' } }
              clickOutside={ false }
            >
                <div
                  block="HeaderClosestStore"
                  elem="PopupWrapper"
                >
                    <h3
                      block="HeaderClosestStore"
                      elem="PopupHeading"
                    >
                        { __('You are located in:') }
                    </h3>
                    { this.renderClosestStoreData() }
                    <div
                      block="HeaderClosestStore"
                      elem="ActionsMobileWrapper"
                    >
                        { this.renderInformationButton() }
                        { this.renderChangeStoreBtn() }
                    </div>
                </div>
            </Popup>
        );
    }

    renderContent() {
        return (
            <>
                { this.renderClosestStoreData() }
                { this.renderInformationButton() }
                { this.renderChangeStoreBtn() }
            </>
        );
    }

    renderModal() {
        const {
            isStoresModalOpen,
            onLocationClick,
            isLoading
        } = this.props;

        if (!isStoresModalOpen) {
            return null;
        }

        return (
            <ClickOutside onClick={ () => onLocationClick(false) }>
                <div
                  block="HeaderClosestStore"
                  elem="Wrapper"
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderContent() }
                </div>
            </ClickOutside>
        );
    }


    render() {
        const {
            onLocationPopupAction,
            hideActiveOverlay,
            isLocationPopupOpen
        } = this.props;

        return (
            <>
                { this.renderMobilePopup() }
                <ClosestStoreOverlay
                  onLocationPopupAction={ onLocationPopupAction }
                  hideActiveOverlay={ hideActiveOverlay }
                  isLocationPopupOpen={ isLocationPopupOpen }
                />
                { this.renderModal() }
            </>
        );
    }
}

export default HeaderClosestStore;
