/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ALL } from "Route/BlogPage/BlogPage.config";

import {
    UPDATE_BLOG_POSTS,
    UPDATE_BLOG_TAGS,
    UPDATE_BLOG_AUTHORS,
    UPDATE_IS_BLOG_LOADING,
    UPDATE_BLOG_SETTINGS,
    UPDATE_TOTAL_BLOG_PAGES,
    UPDATE_BLOG_CATEGORIES,
    UPDATE_BLOG_CATEGORIES_TREE,
    UPDATE_IS_BLOG_BANNER_VISIBLE,
    UPDATE_PAGE_INFO,
} from "./Blog.action";

export const updateBlogPosts = (state, action) => {
    const {
        blogPosts,
        blogPosts: { items, all_post_size },
    } = action;
    const { blogPosts: prevBlogPosts } = state;

    if (typeof all_post_size === "number" && !items) {
        blogPosts.items = prevBlogPosts.items;
    }

    return {
        ...state,
        blogPosts,
    };
};

/** @namespace Store/Blog/Reducer/getInitialState */
export const getInitialState = () => {
    return {
        blogPosts: {},
        blogTags: {},
        blogAuthors: {},
        blogCategories: {},
        blogSettings: {},
        categoriesTree: [],
        totalBlogPages: 0,
        isBlogLoading: false,
        isBlogBannerVisible: false,
        pageInfo: {
            pageType: ALL,
            entityId: 1,
        },
    };
};

export const BlogReducer = (state = getInitialState(), action) => {
    const {
        type,
        blogSettings,
        blogTags,
        blogAuthors,
        blogCategories,
        categoriesTree,
        totalBlogPages,
        isBlogLoading,
        isBlogBannerVisible,
        pageInfo,
    } = action;

    switch (type) {
        case UPDATE_BLOG_SETTINGS:
            return {
                ...state,
                blogSettings,
            };

        case UPDATE_TOTAL_BLOG_PAGES:
            return {
                ...state,
                totalBlogPages,
            };

        case UPDATE_BLOG_POSTS:
            return updateBlogPosts(state, action);

        case UPDATE_BLOG_CATEGORIES:
            return {
                ...state,
                blogCategories,
            };

        case UPDATE_BLOG_CATEGORIES_TREE:
            return {
                ...state,
                categoriesTree,
            };

        case UPDATE_BLOG_TAGS:
            return {
                ...state,
                blogTags,
            };

        case UPDATE_BLOG_AUTHORS:
            return {
                ...state,
                blogAuthors,
            };

        case UPDATE_IS_BLOG_LOADING:
            return {
                ...state,
                isBlogLoading,
            };

        case UPDATE_IS_BLOG_BANNER_VISIBLE:
            return {
                ...state,
                isBlogBannerVisible,
            };

        case UPDATE_PAGE_INFO:
            return {
                ...state,
                pageInfo,
            };

        default:
            return state;
    }
};

export default BlogReducer;
