/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    TextPlaceholder as SourceTextPlaceholder
} from 'SourceComponent/TextPlaceholder/TextPlaceholder.component';

import './TextPlaceholder.style';

/**
 * Text placeholder
 * @class TextPlaceholder
 * @namespace Component/TextPlaceholder/Component
 */
export class TextPlaceholder extends SourceTextPlaceholder {
    render() {
        const { content, length, mix } = this.props;

        if (content) {
            return <span>
                { content }
            </span>;
        }

        return <span mix={ mix } block="TextPlaceholder" mods={ { length } } />;
    }
}

export default TextPlaceholder;
