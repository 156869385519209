/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export {
    formatCurrency,
    calculateFinalPrice
} from 'SourceUtil/Price/Price';

/* eslint-disable import/prefer-default-export */
import {
    EN_STORE,
    FR_STORE
} from './Price.config';

export const formatPrice = (price, storeCode = EN_STORE) => {
     let finalPrice = price;
    if (typeof price === 'string') {
        finalPrice = parseFloat(price.replace(/,/, "."))
    }
    const isPriceNegative = finalPrice < 0;
    if (storeCode === FR_STORE) {
         return `${new Intl.NumberFormat("fr", {minimumFractionDigits:2}).format(finalPrice)} $`;
    } else {
         return `${isPriceNegative ? "-" : ""}$${new Intl.NumberFormat("en", {minimumFractionDigits:2}).format(Math.abs(finalPrice))}`;
    }
};

export const transformToGrams = (weightKgs) => weightKgs * 1000;

/** @namespace Util/Price/_getPrice */
export const _getPrice = (price, currency) => {
     const language = navigator.languages ? navigator.languages[0] : navigator.language;
    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price);
}

/**
 * Calculate final price
 * @param {Number} price
 * @return {Number} price rounded to 2 digits
 * @namespace Util/Price/roundPrice
 */
export const roundPrice = (price) => {
     let finalPrice = price;
    if (typeof price === 'string') {
         finalPrice = price.replace(/,/, ".")
    }
     return parseFloat(finalPrice).toFixed(2)
};

/**
 * returns true, if parsed special date exists
 * @param {String} specialFromDate
 * @return {Boolean} areSpecialDatesExist
 * @namespace Util/Price/getAreSpecialDatesExist
 */
export const getAreSpecialDatesExist = (specialFromDate) => {
     const parsedSpecialFromDate = Date.parse(specialFromDate);
     return typeof parsedSpecialFromDate === 'number' && !isNaN(parsedSpecialFromDate);
};

/**
 * returns true if special price is active depending on date
 * @param {String} specialFromDate
 * @param {String} specialToDate
 * @return {Boolean} isCurrentDateInRange
 * @namespace Util/Price/getIsCurrentDateInRange
 */
export const getIsCurrentDateInRange = (specialFromDate, specialToDate) => {
     const parsedSpecialFromDate = Date.parse(specialFromDate),
     parsedSpecialToDate = Date.parse(specialToDate),
     areSpecialDatesExist = getAreSpecialDatesExist(specialFromDate),
     oneDay = 1000 * 60 * 60 * 24,
     currentDate = Date.now();

     if (!specialToDate) {
          return areSpecialDatesExist && parsedSpecialFromDate < currentDate;
     }

     return areSpecialDatesExist
          && parsedSpecialFromDate < currentDate
          && (parsedSpecialToDate + oneDay) > currentDate;
};

/**
 * returns true if needed to render old price
 * @param {String} specialFromDate
 * @param {String} specialToDate
 * @return {Boolean} isOldPriceRendered
 * @namespace Util/Price/getisOldPriceRendered
 */
export const getisOldPriceRendered = (specialFromDate, specialToDate, discountPercentage) => {
     const areSpecialDatesExist = getAreSpecialDatesExist(specialFromDate, specialToDate),
     isCurrentDateInRange = getIsCurrentDateInRange(specialFromDate, specialToDate);

     return discountPercentage > 0 && areSpecialDatesExist
            ? (discountPercentage > 0 && isCurrentDateInRange)
            : discountPercentage > 0;
};
