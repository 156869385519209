/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Popup from "Component/Popup";
import ProductListPage from "Component/ProductListPage";

import { CartOverlay } from "Component/CartOverlay/CartOverlay.component";

import ClickOutside from "Component/ClickOutside";
import ProductCard from "Component/ProductCard";

import { RECIPE_INGREDIENT_PRODUCTS_OVERLAY_ID } from "./RecipeIngredientProductsOverlay.config";
import { ReactComponent as CloseIcon } from "../Header/icons/close.svg";

import "Component/CartOverlay/CartOverlay.style";
import "./RecipeIngredientProductsOverlay.style";

/***/
export class RecipeIngredientProductsOverlay extends CartOverlay {
	renderProductList() {
		const {
			device: { isMobile },
			products,
			isRecipeIngredientsProductsLoading,
			hasOutOfStockProductsInCart: OutOfStock,
		} = this.props;

		if (
			!isRecipeIngredientsProductsLoading &&
			(!products || products.length < 1)
		) {
			return this.renderNoCartItems();
		}

		if (isMobile) {
			return this.renderMobileItems();
		}

		return this.renderDesktopItemsSlider();
	}

	renderMobileItems() {
		const {
			isRecipeIngredientsProductsLoading,
			hasOutOfStockProductsInCart: OutOfStock,
		} = this.props;

		return (
			<ul
				block="RecipeIngredientProductsOverlay"
				elem="Items"
				mix={{
					block: "CartOverlay",
					elem: "Items",
				}}
				mods={{ OutOfStock }}
				aria-label="List of items in CartOverlay"
			>
				<>
					{isRecipeIngredientsProductsLoading
						? this.renderPlaceholderCards()
						: this.renderProductCards()}
				</>
			</ul>
		);
	}

	renderDesktopItemsSlider() {
		const { products, isRecipeIngredientsProductsLoading } = this.props;

		return (
			<ProductListPage
				items={products}
				isInfiniteLoaderEnabled={false}
				isVisible
				isLoading={isRecipeIngredientsProductsLoading}
				isPlaceholderOffsetHidden
				mix={{
					block: "RecipeIngredientProductsOverlay",
					elem: "ProductList",
				}}
			/>
		);
	}

	renderProductCards() {
		const { products } = this.props;

		return (
			<>
				{products.map((item) => {
					const { id } = item;

					return (
						<ProductCard
							product={item}
							isQueryFetching={false}
							// eslint-disable-next-line react/no-array-index-key
							key={id}
							mix={{
								block: "RecipeIngredientProductsOverlay",
								elem: "ProductCard",
							}}
						/>
					);
				})}
			</>
		);
	}

	renderPlaceholderCards() {
		return new Array(3).fill(null).map((_, i) => (
			<ProductCard
				key={`IngredientProductPlaceholder_${i}`}
				product={{}}
				mix={{
					block: "RecipeIngredientProductsOverlay",
					elem: "ProductCard",
					mods: { isPlaceholder: true },
				}}
				isMobileRecipeCard
			/>
		));
	}

	renderNoCartItems() {
		return (
			<p
				block="RecipeIngredientProductsOverlay"
				elem="NoItems"
				mix={{
					block: "CartOverlay",
					elem: "Items",
					mix: { block: "CartOverlay", elem: "Empty" },
				}}
			>
				{__("There are no products for ingredient.")}
			</p>
		);
	}

	renderHeader() {
		const { closeMiniCart } = this.props;

		return (
			<header mix={{ block: "CartOverlay", elem: "Heading" }}>
				<p mix={{ block: "CartOverlay", elem: "Promo" }}>
					{__("Our suggestions")}
				</p>
				<CloseIcon onClick={() => closeMiniCart()} />
			</header>
		);
	}

	render() {
		const { isRecipeIngredientsProductsLoading: isLoading, closeMiniCart } =
			this.props;

		return (
			<Popup
				id={RECIPE_INGREDIENT_PRODUCTS_OVERLAY_ID}
				clickOutside={false}
				mix={{
					block: "RecipeIngredientProductsOverlay",
					mods: { isLoading },
				}}
			>
				<ClickOutside onClick={closeMiniCart}>
					<div
						block="RecipeIngredientProductsOverlay"
						elem="PopupContentWrapper"
					>
						{this.renderHeader()}
						{this.renderProductList()}
					</div>
				</ClickOutside>
			</Popup>
		);
	}
}

export default RecipeIngredientProductsOverlay;
