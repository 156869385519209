import { PureComponent } from "react";

import ProductCard from "Component/ProductCard";
import BlogSimilarPostCard from "Component/BlogSimilarPostCard";
import RecipeCard from "Component/RecipeCard";
import TextPlaceholder from "Component/TextPlaceholder";
import Link from "Component/Link";
import ProductSlider from "Component/ProductSliders";
import SearchSuggestions from "Component/SearchSuggestions";
import BlogTag from "Component/BlogTag";
import {
	BLOG_TYPE,
	PRODUCTS_TYPE,
	RECIPE_TYPE,
} from "Component/SearchField/SearchField.config";

import {
	getMoreProductsBtnText,
	getMoreProductsBtnUrl,
	getSearchCardItems,
	getTopProductsSectionHeader,
} from "Util/Search";

import {
	PRODUCT_PLACEHOLDERS,
	X_SEARCH_PRODUCTS_PLACEHOLDERS,
} from "./SearchWindow.config";

import "./SearchWindow.style.scss";

export class SearchWindow extends PureComponent {
	renderMobileCloseBtn() {
		const {
			onCloseBtnClick,
			mobileCloseBtnRef,
			device: { isMobile },
		} = this.props;

		if (!isMobile) {
			return null;
		}

		return (
			<div block="SearchWindow" elem="MobileCloseBtn">
				<button ref={mobileCloseBtnRef} onClick={onCloseBtnClick} />
			</div>
		);
	}

	renderPlaceholders() {
		const {
			activeType,
			isTopProductsLoading,
			searchCriteria,
			isXSearchLoading,
			isSearchProductsOpening,
		} = this.props;

		const numberOfPlaceholders =
			activeType === PRODUCTS_TYPE && searchCriteria && isXSearchLoading
				? X_SEARCH_PRODUCTS_PLACEHOLDERS
				: PRODUCT_PLACEHOLDERS;

		if (
			!isTopProductsLoading &&
			!isXSearchLoading &&
			!isSearchProductsOpening
		) {
			return null;
		}

		return Array.from({ length: numberOfPlaceholders }, (_, i) => (
			<ProductCard
				key={i}
				mix={{
					block: "SearchWindow",
					elem: "ProductCard",
					mods: {
						isXSearch: !!searchCriteria.trim(),
						isBlogCardPlaceholder:
							activeType === BLOG_TYPE ||
							activeType === RECIPE_TYPE,
					},
				}}
				product={{}}
				isSearchWindow
			/>
		));
	}

	renderCategoriesPlaceholders() {
		const { activeType } = this.props;

		const numberOfPlaceholders = activeType === PRODUCTS_TYPE ? 4 : 8;

		return Array.from({ length: numberOfPlaceholders }, (_, i) => (
			<div block="SearchWindow" elem="CategoriesNavItem">
				<TextPlaceholder content={""} />
			</div>
		));
	}

	renderCategoriesItems() {
		const { topCategories } = this.props;

		return topCategories
			.sort((a, b) => a - b)
			.slice(0, 5)
			.map(this.renderCategoriesItem);
	}

	renderCategoriesItem({ name, url }) {
		return (
			<Link
				to={url}
				mix={{
					block: "SearchWindow",
					elem: "CategoriesNavItem",
				}}
			>
				{name}
			</Link>
		);
	}

	renderCategories() {
		const { isTopCategoriesLoading, topCategories } = this.props;

		if (isTopCategoriesLoading || !topCategories.length) {
			return this.renderCategoriesPlaceholders();
		}

		return this.renderCategoriesItems();
	}

	renderProductCards() {
		const {
			topProducts,
			searchCriteria,
			isXSearchLoading,
			isXSearchResults: isXSearch,
			xSearchSuggestions: { xsearchProducts },
		} = this.props;

		if (
			isXSearchLoading ||
			(searchCriteria &&
				Array.isArray(xsearchProducts) &&
				!xsearchProducts.length)
		) {
			return [null];
		}

		const productsToRender = isXSearch
			? xsearchProducts.slice(0, 4)
			: topProducts;

		return productsToRender.map(({ product }, i) => (
			<ProductCard
				key={i}
				isSearchProductSlider
				isSearchWindow
				product={product}
				mix={{
					block: "SearchWindow",
					elem: "ProductCard",
					mods: {
						isLoaded: true,
						isXSearch: !!searchCriteria.trim(),
					},
				}}
			/>
		));
	}

	renderRecipesCards() {
		const {
			isXSearchResults: isXSearch,
			isXSearchLoading,
			searchCriteria,
			recipesSearchResult: { items: searchedRecipes } = {},
			lastRecipes: { items: lastRecipes } = {},
		} = this.props;

		if (
			isXSearchLoading ||
			(searchCriteria &&
				Array.isArray(searchedRecipes) &&
				!searchedRecipes.length)
		) {
			return [null];
		}

		const recipesToRender = isXSearch
			? searchedRecipes || []
			: lastRecipes || [];

		return recipesToRender.map((data) => (
			<RecipeCard
				{...data}
				isWithoutCategory
				mix={{ block: "SearchWindow", elem: "RecipeCard" }}
			/>
		));
	}

	renderBlogCards() {
		const {
			lastBlogPosts: { items: lastPosts },
			blogPostsSearchResult: { items: searchedPosts },
			searchCriteria,
			isXSearchLoading,
			isXSearchResults: isXSearch,
		} = this.props;

		if (
			isXSearchLoading ||
			(searchCriteria &&
				Array.isArray(searchedPosts) &&
				!searchedPosts.length)
		) {
			return [null];
		}

		const postsToRender = isXSearch
			? (searchedPosts || []).slice(0, 8)
			: (lastPosts || []).slice(0, 8);

		return postsToRender.map((post) => (
			<BlogSimilarPostCard
				post={post}
				mix={{
					block: "SearchWindow",
					elem: "BlogCard",
					mods: {
						isLoaded: true,
						isXSearch: !!searchCriteria.trim(),
					},
				}}
			/>
		));
	}

	renderCards() {
		const { activeType } = this.props;

		switch (activeType) {
			case BLOG_TYPE:
				return this.renderBlogCards();
			case RECIPE_TYPE:
				return this.renderRecipesCards();
			default:
				return this.renderProductCards();
		}
	}

	renderCardsSlider() {
		const {
			isTopProductsLoading,
			topProducts,
			isActive,
			activeImage,
			onActiveImageChange,
			isSearchProductsOpening,
			searchProductsScrollableRef,
			searchCriteria,
			device: { isMobile },
		} = this.props;

		if (isTopProductsLoading || isSearchProductsOpening) {
			return null;
		}

		if (!isMobile) {
			return this.renderCards();
		}

		return (
			<ProductSlider
				activeImage={activeImage}
				onActiveImageChange={onActiveImageChange}
				isSliderExist={isActive}
				containerRef={searchProductsScrollableRef}
				// show breadcrumbs only on tiny screens
				showCrumbs={isMobile}
				isMobileFullScreen
				slidesCount={topProducts.length}
				mix={{
					block: "SearchWindow",
					elem: "MobileProductsSlider",
					mods: { isXSearch: !!searchCriteria.trim() },
				}}
			>
				{this.renderCards()}
			</ProductSlider>
		);
	}

	renderSearchResults() {
		const { searchCriteria, isXSearchLoading, searchSuggestionsRef } =
			this.props;

		return (
			<SearchSuggestions
				isLoading={isXSearchLoading}
				searchedTxt={searchCriteria}
				searchSuggestionsRef={searchSuggestionsRef}
			/>
		);
	}

	renderTopCategories() {
		const { isXSearchLoading } = this.props;

		if (isXSearchLoading) {
			return this.renderCategoriesPlaceholders();
		}

		return (
			<>
				{this.renderNoResultsFor()}
				{this.renderBlockHeader(__("Top categories"))}
				<nav block="SearchWindow" elem="CategoriesNav">
					{this.renderCategories()}
				</nav>
			</>
		);
	}

	renderProductsLinks() {
		const {
			searchCriteria = "",
			isFallbackNeeded,
			searchCategoriesSectionRef,
		} = this.props;

		const isSearchResultsRendered =
			!!searchCriteria.trim() && !isFallbackNeeded;

		const children = isSearchResultsRendered
			? this.renderSearchResults()
			: this.renderTopCategories();

		return (
			<section
				block="SearchWindow"
				elem="CategoriesSection"
				ref={searchCategoriesSectionRef}
				mods={{
					isXSearch: isSearchResultsRendered,
					isNoResults:
						!isSearchResultsRendered && !!searchCriteria.trim(),
				}}
			>
				{children}
			</section>
		);
	}

	renderBlogLinksBlock({ title, content, wrapperMix }) {
		const { blogSettings } = this.props;

		const isNoContent =
			!!Object.keys(blogSettings).length && !content?.length;

		if (isNoContent) {
			return null;
		}

		const blockContent = content?.length ? (
			content
		) : (
			<TextPlaceholder length="short" />
		);

		return (
			<div block="SearchWindow" elem="BlogLeftSection">
				<h3 block="SearchWindow" elem="SectionHeader">
					{title}
				</h3>
				<div
					block="SearchWindow"
					elem="SectionContent"
					mix={wrapperMix}
				>
					{blockContent}
				</div>
			</div>
		);
	}

	renderBlogCategoryLink({ name, url }) {
		return (
			<Link
				to={url}
				mix={{
					block: "SearchWindow",
					elem: "CategoriesNavItem",
				}}
			>
				{name}
			</Link>
		);
	}

	renderRecipesLinks() {
		const { recipeCategories } = this.props;

		const linkBlocks = [
			{
				title: "Categories",
				content: (recipeCategories || []).map(
					this.renderBlogCategoryLink
				),
			},
		];

		return (
			<div
				block="SearchWindow"
				elem="CategoriesSection"
				mods={{ isBlogType: true }}
			>
				{linkBlocks.map(this.renderBlogLinksBlock.bind(this))}
			</div>
		);
	}

	renderBlogLinks() {
		const {
			blogTags: { items: tags } = {},
			blogCategories: { items: categories } = {},
		} = this.props;

		const linkBlocks = [
			{
				title: __("Categories"),
				content: (categories || []).map(this.renderBlogCategoryLink),
			},
			{
				title: __("Related topics"),
				content: (tags || []).map((tag) => (
					<BlogTag
						tag={tag}
						mix={{ block: "SearchWindow", elem: "BlogTag" }}
					/>
				)),
				wrapperMix: { block: "SearchWindow", elem: "BlogTags" },
			},
		];

		return (
			<div
				block="SearchWindow"
				elem="CategoriesSection"
				mods={{ isBlogType: true }}
			>
				{linkBlocks.map(this.renderBlogLinksBlock.bind(this))}
			</div>
		);
	}

	renderLinksContent() {
		const { activeType } = this.props;

		switch (activeType) {
			case BLOG_TYPE:
				return this.renderBlogLinks();
			case RECIPE_TYPE:
				return this.renderRecipesLinks();
			default:
				return this.renderProductsLinks();
		}
	}

	renderLinksSection() {
		const {
			device: { isMobile },
		} = this.props;

		return (
			<div>
				{this.renderLinksContent()}
				{!isMobile && this.renderInsentBottomShadow()}
			</div>
		);
	}

	renderInsentBottomShadow() {
		const { isBottomBlurVisible } = this.props;

		return (
			<div
				block="SearchWindow"
				elem="InsentShadow"
				mods={{ isHidden: !isBottomBlurVisible }}
			/>
		);
	}

	renderNoResultsFor() {
		const { activeType, isFallbackNeeded, searchCriteria } = this.props;

		const noResultsTxt = __("No results for");

		if (!isFallbackNeeded && activeType === PRODUCTS_TYPE) {
			return null;
		}

		return (
			<div
				block="SearchWindow"
				elem="NoResultsWrapper"
				mods={{ isProductsSearchType: activeType === PRODUCTS_TYPE }}
			>
				{this.renderBlockHeader(noResultsTxt, null, {
					block: "SearchWindow",
					elem: "NoResultsHeader",
				})}
				<p
					block="SearchWindow"
					elem="NoResultsCriteriaTxt"
					mix={{
						block: "SearchWindow",
						elem: "SectionHeader",
					}}
				>
					{searchCriteria}
				</p>
			</div>
		);
	}

	renderTopProductsHeader() {
		const {
			activeType,
			searchCriteria,
			productsHeaderRef,
			device: { isMobile },
		} = this.props;

		if (activeType === PRODUCTS_TYPE && !!searchCriteria.trim()) {
			return null;
		}

        const isBlog = activeType === BLOG_TYPE;
		const topProductsHeader = getTopProductsSectionHeader(
			activeType,
			searchCriteria
		);

		return (
			<div block="SearchWindow" elem="SectionHeaderWrapper">
				{this.renderBlockHeader(topProductsHeader, productsHeaderRef, {
					block: "SearchWindow",
					elem: "ProductsHeader",
					mods: { isBlog },
				})}
				{isMobile && this.renderMoreProductsBtn(true)}
			</div>
		);
	}

	renderCardsSection() {
		const {
			activeType,
			productsSectionRef,
			isFallbackNeeded,
			searchCriteria,
			isXSearchLoading,
			device: { isMobile },
			xSearchSuggestions: { xsearchProducts = [] },
			blogPostsSearchResult: { items: searchedPosts },
			recipesSearchResult: { items: searchedRecipes },
		} = this.props;

		const items = getSearchCardItems(
            activeType,
			searchedPosts,
			searchedRecipes,
			xsearchProducts
		);
		const isNoResults = !!searchCriteria && !items.length;
		const isProductsType = activeType == PRODUCTS_TYPE;

		const nullReturned =
			(isProductsType && isMobile && searchCriteria) ||
			(isProductsType &&
				!isXSearchLoading &&
				(isNoResults || isFallbackNeeded));

		if (nullReturned) {
			return null;
		}

		return (
			<section
				block="SearchWindow"
				elem="ProductsSection"
				mods={{ isBlogType: activeType === BLOG_TYPE }}
				ref={productsSectionRef}
			>
				{isNoResults && !isXSearchLoading
					? this.renderNoResultsFor()
					: this.renderCardsSectionContent()}
			</section>
		);
	}

	renderCardsSectionContent() {
		const { activeType, searchProductsScrollableRef, searchCriteria } =
			this.props;

		return (
			<>
				{this.renderTopProductsHeader()}
				<div
					block="SearchWindow"
					elem="ProductCardsScrollable"
					mods={{ isXSearch: !!searchCriteria.trim() }}
					ref={searchProductsScrollableRef}
				>
					<ul
						block="SearchWindow"
						elem="CardsWrapper"
						mods={{
							isXSearch: !!searchCriteria.trim(),
							isBlogSearch:
								activeType === BLOG_TYPE ||
								activeType === RECIPE_TYPE,
						}}
					>
						{this.renderPlaceholders()}
						{this.renderCardsSlider()}
					</ul>
				</div>
			</>
		);
	}

	renderMoreProductsBtn(isRenderedOnMobile = false) {
		const {
			activeType,
			searchCriteria,
			mageside_recipe_seo_route,
			recipeCategories,
            isConfigLoading,
			blogSettings: { search_engine_route } = {},
			topCategoryForTopProducts: { url: productUrl } = {},
			device: { isMobile },
		} = this.props;

		const recipeCategoryUrlKey = recipeCategories.filter(
			({ all_categories }) => all_categories === "1"
		)[0]?.url_key;

		const text = getMoreProductsBtnText(isMobile, activeType);

		const isProductsResult =
			activeType === PRODUCTS_TYPE && !!searchCriteria.trim();
		const url = getMoreProductsBtnUrl(
			activeType,
			search_engine_route,
			mageside_recipe_seo_route,
			recipeCategoryUrlKey,
			productUrl
		);

		if ((!isRenderedOnMobile && isMobile) || isProductsResult) {
			return null;
		}

        if (isConfigLoading) {
            return (
				<TextPlaceholder
					length="short"
					mix={{ block: "SearchWindow", elem: "MoreProductsLink" }}
				/>
			);
        }

		if (!url) {
            return null;
		}

		return (
			<Link
				mix={{ block: "SearchWindow", elem: "MoreProductsLink" }}
				to={url}
			>
				{text}
			</Link>
		);
	}

	renderBlockHeader(headerTxt, ref = null, mix) {
		return (
			<h3 block="SearchWindow" elem="SectionHeader" mix={mix} ref={ref}>
				{headerTxt}
			</h3>
		);
	}

	render() {
		const { activeType, isActive, searchWindowRef, searchCriteria } =
			this.props;

		return (
			<>
				<section
					block="SearchWindow"
					mods={{
						isActive,
						isXSearch: !!searchCriteria.trim(),
						isBlogSearch: activeType === BLOG_TYPE,
					}}
					ref={searchWindowRef}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<div block="SearchWindow" elem="ContentWrapper">
						{this.renderLinksSection()}
						{this.renderCardsSection()}
					</div>
					{this.renderMoreProductsBtn()}
					{this.renderMobileCloseBtn()}
				</section>
			</>
		);
	}
}

export default SearchWindow;
