import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { MixType } from 'SourceType/Common';
import { PriceType } from 'SourceType/ProductList';
import {
    calculateFinalPrice,
    formatPrice,
    roundPrice
} from 'SourceUtil/Price';

import ProductPrice from './ProductPrice.component';

export const mapStateToProps = (state) => ({
    currentStoreCode: state.ConfigReducer.code,
    storeCode: state.ConfigReducer.code,
    device: state.ConfigReducer.device
});
export class ProductPriceContainer extends PureComponent {
    static propTypes = {
        isSchemaRequired: PropTypes.bool,
        price: PriceType,
        mix: MixType
    };

    static defaultProps = {
        isSchemaRequired: false,
        mix: {},
        price: {}
    };

    containerProps = () => {
        const {
            storeCode,
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    regular_price: {
                        value: regularPriceValue
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!minimalPriceValue || !regularPriceValue) {
            return {};
        }

        const roundedRegularPrice = roundPrice(regularPriceValue);
        const finalPrice = calculateFinalPrice(discountPercentage, minimalPriceValue, regularPriceValue);
        const formattedFinalPrice = formatPrice(finalPrice, storeCode);

        return {
            roundedRegularPrice,
            priceCurrency,
            discountPercentage,
            formattedFinalPrice
        };
    };

    render() {
        return (
            <ProductPrice
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
