/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright Â© Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Html from 'Component/Html';
import { CmsBlock as SourceCmsBlock } from 'SourceComponent/CmsBlock/CmsBlock.component';
export class CmsBlock extends SourceCmsBlock {
    renderPlaceholder() {
        const {
            children
        } = this.props;

        if (!(children && children.length)) {
            return null
        }

        return children;
    }

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            },
            children
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
            >
                <Html content={ content } />
                { children }
            </div>
        );
    }
}

export default CmsBlock;
