/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Notification as SourceNotification } from 'SourceComponent/Notification/Notification.component'
import { ReactComponent as SuccessIcon } from './images/success.svg';
import { ReactComponent as ErrorIcon } from './images/error.svg';

import './Notification.style';

/**
 * Notification block
 * @class Notification
 */
export class Notification extends SourceNotification {

    render() {
        const { notification } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };


        let icon =msgType === "success" ? <SuccessIcon/>  : msgType === "error" ? <ErrorIcon/> : null;

        return (
            <div block="Notification" mods={ mods } ref={ this.notification }>
                {icon}
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default Notification;
