import { createPortal } from "react-dom";

import CategoryConfigurableAttributes from "Component/CategoryConfigurableAttributes";
import { CategoryFilterOverlay } from "Component/CategoryFilterOverlay/CategoryFilterOverlay.component";

import { MOBILE } from "Component/CategoryFilterOverlay/CategoryFilterOverlay.component";
import MoreFiltersBtn from "Component/MoreFiltersBtn";
import Overlay from "Component/Overlay";
import ClickOutside from "Component/ClickOutside";
import Link from "Component/Link";
import Loader from "Component/Loader";

import { ReactComponent as CloseIcon } from "Component/Header/icons/close.svg";

import { RECIPES_FILTER_OVERLAY_ID } from "./RecipeCategoriesNav.config";

import TextPlaceholder from "Component/TextPlaceholder";

import "Component/BlogMobileMenuButtonRow/BlogMobileMenuButtonRow.style";
import "./RecipeCategoriesNav.style";

export class RecipeCategoriesNav extends CategoryFilterOverlay {
	onResetClick = () => {
		const {
			device: { isMobile },
			onSeeResultsClick,
			resetFilters,
		} = this.props;

		if (isMobile) {
			onSeeResultsClick();
		}

		resetFilters();
	};

    renderLoader() {
        const { isInfoLoading } = this.props;

        return <Loader isLoading={isInfoLoading} />;
    }

	renderFilters(filters = null, isWithoutShowMore = false, mix = null) {
		const {
			selectedFilters,
			toggleCustomFilter,
			getFilterUrl,
			recipeFilters,
		} = this.props;

		return (
			<CategoryConfigurableAttributes
				mix={{
					block: "CategoryFilterOverlay",
					elem: "Attributes",
					mix,
				}}
				isReady={!!Object.keys(recipeFilters).length}
				configurable_options={recipeFilters}
				getLink={getFilterUrl}
				parameters={selectedFilters}
				updateConfigurableVariant={toggleCustomFilter}
				isWithoutShowMore={isWithoutShowMore}
			/>
		);
	}

	renderOverlayContent() {
		const {
			isSortOverlayOpened,
			device: { isMobile },
		} = this.props;

		if (isSortOverlayOpened && isMobile) {
			return this.renderSortOverlayContent();
		}

		return (
			<div block="CategoryFilterOverlay" elem="OverlayContent">
				{this.renderCategoriesNavButtons(isMobile)}
				{this.renderPopupFilters()}
			</div>
		);
	}

	renderCategoryButton({ entity_id, url, name, recipe_count }) {
		const { currentCategoryId, recipesTotalCount, onCategoryClick } = this.props;

		const isActive = entity_id === currentCategoryId;
        const count = isActive ? recipesTotalCount : recipe_count;

		return (
			<div block="RecipeCategoriesNav" elem="CategoryLinkWrapper">
				<Link
					to={url}
					block="RecipeCategoriesNav"
					elem="CategoryLink"
                    onClick={onCategoryClick}
					mods={{ isActive }}
				>
					<p block="RecipeCategoriesNav" elem="CategoryName">
						{name}
					</p>
					<span block="RecipeCategoriesNav" elem="RecipesQty">
						{count}
					</span>
				</Link>
				{isActive && (
					<div
						block="RecipeCategoriesNav"
						elem="ActiveBtnUnclickable"
					/>
				)}
			</div>
		);
	}

	renderPopupFilters() {
		const {
			recipeFilters,
			device: { isMobile },
		} = this.props;

		return (
			<div
				block="RecipeCategoriesNav"
				elem="PopupFiltersWrapper"
				mix={{
					block: "CategoryFilterOverlay",
					elem: "PopupFiltersWrapper",
				}}
			>
				{isMobile && (
					<h3 block="RecipeCategoriesNav" elem="MobileFiltersHeader">
						{__("other filters")}
					</h3>
				)}
				{this.renderFilters(recipeFilters, true, {
					block: "CategoryFilterOverlay",
					elem: "PopupFilters",
				})}
			</div>
		);
	}

	renderCategoriesNavButtons(isRendered = true) {
		const { recipeCategories = [], isRecipesCategoriesLoading } =
			this.props;

		if (!isRendered) {
			return null;
		}

		// prettier-ignore
		const content = isRecipesCategoriesLoading
            ? <TextPlaceholder length="long" />
            : recipeCategories.map(this.renderCategoryButton.bind(this));

		return (
			<div block="RecipeCategoriesNav" elem="ButtonsWrapper">
				{content}
			</div>
		);
	}

	renderMoreFiltersButton() {
		const {
			isMoreFiltersActive,
			isCurrentCategoryLoading,
			onFiltersOverlayOpen,
			moreFiltersBtnRef,
            recipeFilters,
			device: { isMobile },
		} = this.props;

		if (isCurrentCategoryLoading) {
			return <TextPlaceholder length="short" />;
		}

        if (!Object.keys(recipeFilters).length) {
            return null;
        }

		return (
			<MoreFiltersBtn
				isMoreFiltersActive={isMoreFiltersActive}
				onFiltersOverlayOpen={onFiltersOverlayOpen}
				moreFiltersBtnRef={moreFiltersBtnRef}
				isMobileType={isMobile}
                buttonText={isMobile ? __("Categories") : __("+ filters")}
				mix={{ block: "RecipeCategoriesNav", elem: "MoreFiltersBtn" }}
			/>
		);
	}

	renderFiltersOverlay() {
		const {
			onVisible,
			closeOverlay,
			activeOverlay,
			onHide,
			moreFiltersBtnRef,
		} = this.props;

		return (
			<Overlay
				onVisible={onVisible}
				onHide={onHide}
				mix={{
					block: "CategoryFilterOverlay",
					elem: "Popup",
					mix: {
						block: "CartOverlay",
						mods: {
							isVisible:
								activeOverlay === RECIPES_FILTER_OVERLAY_ID,
						},
						mix: { block: "RecipeCategoriesNav", elem: "Overlay" },
					},
				}}
				id={RECIPES_FILTER_OVERLAY_ID}
				isRenderInPortal={true}
			>
				<ClickOutside
					onClick={closeOverlay}
					exceptionsElemsRefs={[moreFiltersBtnRef]}
				>
					<div
						block="CategoryFilterOverlay"
						elem="FiltersPopup"
						ref={this.filters}
					>
						{this.renderPopupHeader()}
						{this.renderOverlayContent()}
						{this.renderFiltersMobileFooter()}
						{this.renderLoader()}
					</div>
				</ClickOutside>
			</Overlay>
		);
	}

	renderPopupHeader() {
		const { hideActiveOverlay } = this.props;

		return (
			<div block="CategoryFilterOverlay" elem="PopupHeader">
				<h4 block="CategoryFilterOverlay" elem="PopupHeaderText">
					{__("Recipes")}
				</h4>
				<button
					block="CategoryFilterOverlay"
					elem="PopupHeaderButton"
					onClick={hideActiveOverlay}
				>
					<CloseIcon />
				</button>
			</div>
		);
	}

	renderFiltersMobileFooter() {
		const isResetRendered = true;

		return (
			<div
				block="CategoryFilterOverlay"
				elem="PopupFooter"
				mods={{ isResetRendered }}
			>
				{this.renderResetButton(MOBILE)}
				{this.renderValidateButton()}
			</div>
		);
	}

	renderResetButton() {
		const {
			device: { isMobile },
			selectedFilters,
		} = this.props;

		const isInvisible = !Object.keys(selectedFilters).length;

		return (
			<button
				block="CategoryFilterOverlay"
				elem="ResetButton"
				mods={{ isMobile, isInvisible }}
				onClick={this.onResetClick}
			>
				{__("Clear filters")}
			</button>
		);
	}

	renderFiltersContent() {
		const filtersOverlay = this.renderFiltersOverlay();
		const filtersSlotNode = document.getElementById(
			"HeaderForFiltersPortalSlot"
		);

		return (
			<>
				{this.renderMoreFiltersButton()}
				{createPortal(filtersOverlay, filtersSlotNode)}
			</>
		);
	}

	renderMobileTotalRecipes() {
		const {
			device: { isMobile },
			recipesTotalCount,
		} = this.props;

		if (!isMobile) {
			return null;
		}

		if (!recipesTotalCount) {
			return <TextPlaceholder length="short" />;
		}

		return (
			<div
				block="RecipeCategoriesNav"
				elem="TotalRecipesCount"
				mix={{ block: "BlogMobileMenuButtonRow", elem: "PostsCount" }}
			>{`${recipesTotalCount} ${__("recipes")}`}</div>
		);
	}

	render() {
		const {
			device: { isMobile },
		} = this.props;

		return (
			<nav
				block="RecipeCategoriesNav"
				mix={{ block: isMobile ? "BlogMobileMenuButtonRow" : "" }}
			>
				{this.renderCategoriesNavButtons(!isMobile)}
				{this.renderMobileTotalRecipes()}
				{this.renderFiltersContent()}
			</nav>
		);
	}
}

export default RecipeCategoriesNav;
