export const DEFAULT_PLACEHOLDER_COUNT = 8;
export const TABLET_WIDTH = 810;
export const DESKTOP_WIDTH = 1024;
export const MAX_WINDOW_SIZE = 1024;
export const DESKTOP_SLIDE_WIDTH = 277;
export const TABLET_SLIDE_WIDTH = 288;
export const BUTTON_LEFT = 'left';
export const BUTTON_RIGHT = 'right';
export const ANIMATION_DURATION = 300;
export const ACTIVE_SLIDE_PERCENT = 0.1;
