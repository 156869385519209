import PropTypes from 'prop-types';
import React from 'react';

import FieldForm from 'Component/FieldForm';

export class ContactUsForm extends FieldForm {

    static propTypes = {
        sendMessage: PropTypes.func.isRequired,
    };
    /**
     * On submit
     *
     * @param fields
     */
    onFormSuccess = (fields) => {
        const { sendMessage } = this.props;
        sendMessage(fields);
    };
    /**
     * Fields map
     *
     */
    get fieldMap() {
        const { isSend, reasonOptions, storeOptions} = this.props;

        return {
            name: {
                label: __('Full name'),
                validation: ['notEmpty'],
                placeholder: __('Enter your full name'),
                value: isSend? "": null
            },
            email: {
                label: __('Email address'),
                validation: ['notEmpty','email'],
                placeholder: __('Enter your email'),
                value: isSend? "": null
            },
            store: {
                type:"select",
                label: __('Which Avril branch is concerned?'),
                placeholder: __('Select a branch'),
                value: isSend? "": null,
                selectOptions: storeOptions,
            },
            reason: {
                type:"select",
                label: __('Subject'),
                validation: ['notEmpty'],
                placeholder: __('Select the subject of your request'),
                selectOptions: reasonOptions,
                value: isSend? "": null,
            },
            message: {
                type: 'textarea',
                label: __('Your message (max. 1500 characters)'),
                validation: ['notEmpty'],
                maxLength:1500,
                value: isSend ? "": null
            }
        };
    }

    /**
     * Render actions
     *
     * @return {*}
     */
    renderActions() {

        return (
            <button type="submit" block="Button">
                { __('Submit') }
            </button>
        );
    }

    /**
     * Render form
     *
     * @return {*}
     */
    render() {
        return (
            <div block="ContactForm" elem="Wrapper">
                { super.render() }
            </div>
        );
    }
}

export default ContactUsForm
