/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import MaskedInput from 'react-maskedinput';

import { FieldInput as SourceFieldInput } from 'SourceComponent/FieldInput/FieldInput.component';

/** @namespace Component/FieldInput/Component */
export class FieldInput extends SourceFieldInput {
    formatCharacters = {
        // Use W char in mask, if it's shall be replaced by letter.
        'W': {
            validate: (char) => {
                return /[a-z]|[A-Z]/.test(char)},
            transform: (char) => char.toUpperCase()
        }
    }

    renderMaskedInput() {
        const {
          formRef,
          placeHolder,
          ...validProps
        } = this.props;

        return <MaskedInput
          ref={ formRef }
          formatCharacters={ this.formatCharacters }
          placeholder={ placeHolder }
          { ...validProps }
        />;
    }

    renderInput() {
        const {
            formRef,
            ...validProps
        } = this.props;

        return <input
          ref={ formRef }
          { ...validProps }
        />;
    }

    render() {
        const { mask } = this.props;

        return (
            <>
                { mask ? this.renderMaskedInput() : this.renderInput() }
            </>
        );
    }
}

export default FieldInput;
