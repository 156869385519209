import { PureComponent } from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';

import { ReactComponent as CarIcon } from './icons/car.svg'
import { ReactComponent as TruckIcon } from './icons/truck.svg'

import {
    SHIPPING_INFO_CMS,
    PICKUP_INFO_CMS
} from './ProductAvailability.config'

import './ProductAvailability.style';

export class ProductAvailability extends PureComponent {
    getIconsData = () => {
        const {
            isProductPage = false,
            onIconClick,
            product: {
                shipments: {
                    is_pickable,
                    is_shippable
                } = {}
            }
        } = this.props;

        const pickupText = is_pickable
            ? __('Pickup is available')
            : __('Pickup is not available');

        const shippingText = is_shippable
            ? __('Shipping is available')
            : __('Shipping is not available');

        return [
            {
                icon: <CarIcon />,
                tooltipText: pickupText,
                text: isProductPage ? pickupText : null,
                onClick: (e) => onIconClick(e, PICKUP_INFO_CMS, is_pickable),
                mods: {
                    isAvailable: is_pickable,
                    isProductPage
                }

            },
            {
                icon: <TruckIcon />,
                tooltipText: shippingText,
                text: isProductPage ? shippingText : null,
                onClick: (e) => onIconClick(e, SHIPPING_INFO_CMS, is_shippable),
                mods: {
                    isAvailable: is_shippable,
                    isProductPage
                }

            }
        ];
    };

    renderIcon(iconData) {
        const {
            icon,
            tooltipText,
            text,
            mods,
            onClick
        } = iconData;

        return (
            <div
              block="ProductAvailability"
              elem="AvailabilityIconWrapper"
              mods={ mods }
              onClick={ onClick }
            >
                <span
                  block="ProductAvailability"
                  elem="AvailabilityIcon"
                  mods={ mods }
                >
                    <div block="ProductAvailability" elem="Tooltip">
                        <span block="ProductAvailability" elem="TooltipText">
                            { tooltipText }
                        </span>
                        { icon }
                    </div>
                </span>
                { text && <p block="ProductAvailability" elem="AvailabilityIconText">{ text }</p> }
            </div>

        );
    }

    renderPlaceHolder() {
        return <TextPlaceholder length="long" />;
    }

    renderAvailabilityIcons() {
        const { isQueryFetching, isAppInitialized, isProductLoading } = this.props;

        if (isQueryFetching || !isAppInitialized || isProductLoading) {
            return this.renderPlaceHolder();
        }

        return this.getIconsData().map((iconData) => this.renderIcon.call(this, iconData));
    }

    render() {
        const {
            product: {
                shipments: {
                    is_shippable
                } = {}
            },
            isProductPage = false,
            isAppInitialized,
            isProductLoading
        } = this.props;

        if (
            is_shippable === undefined
            && isAppInitialized
            && !isProductLoading
        ) {
            return null;
        }

        return (
            <div
              block="ProductAvailability"
              elem="AvailabilityIconsContainer"
              mods={ { isProductPage } }
            >
                { this.renderAvailabilityIcons() }
            </div>
        );
    }
}

export default ProductAvailability;
