import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import ConfigQuery from 'Query/Config.query';
import StoreQuery from 'Query/Store.query';
import {
    StoreSwitcherContainer as SourceStoreSwitcherContainer,
    mapStateToProps,
    mapDispatchToProps
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.container';
import { getCurrentUrl, updateHrefLangTags } from 'Util/StoreSwitcher';

import './StoreSwitcher.style.scss';

export class StoreSwitcherContainer extends SourceStoreSwitcherContainer {
    constructor(props) {
        super(props);
        const { currentStoreCode } = props;

        this.state = {
            storeList: [],
            isOpened: false,
            storeLabel: '',
            currentStoreCode,
            otherStoreUrl: '',
        }
    }

    containerProps = () => {
        const { currentStoreCode, device, mix } = this.props;
        return { currentStoreCode, device, mix };
    };

    componentDidMount() {
        this._getStoreList();
    }

    componentDidUpdate(prevProps) {
        const { currentStoreCode: stateCurrentStoreCode, getOtherStoreUrls } = this.state;
        const { currentStoreCode: currentStoreCode, location: { pathname, search } } = this.props;
        const { location: { pathname: prevPathname, search: prevSearch } } = prevProps;

        if (currentStoreCode !== stateCurrentStoreCode) {
            this.setState({ currentStoreCode });
        }

        if (
            pathname !== prevPathname ||
            search !== prevSearch
        ) {
            this.setState({ otherStoreUrl: '' });
            updateHrefLangTags.removeTags();
            this._getStoreList();
        }

        this.getCurrentLabel(currentStoreCode);
    }

    _getStoreList() {
        this.fetchData(
            [ConfigQuery.getStoreListField()],
            this.onStoreListFetchSuccess.bind(this)
        );
    }

    _getOtherStoreUrls(storeList) {
        const { location: { pathname } } = this.props;

        const storeCodes = storeList.map(({ code }) => code);
        const currentUrl = getCurrentUrl(storeCodes, pathname);

        this.fetchData(
            [StoreQuery.getOtherStoresUrl(currentUrl)],
            this.onOtherUrlsFetchSuccess.bind(this)
        );
    }

    _handleStoreSelect(storeCode) {
        const { showErrorNotification, location: { pathname } } = this.props;
        const { storeList, otherStoreUrl, currentStoreCode } = this.state;

        const currentLocation = pathname.split(currentStoreCode)
            .filter((_, i) => i !== 0).join(currentStoreCode);

        const store = storeList.find(
            ({ value }) => value === storeCode
        );
        this.setState({ storeLabel: store.label, currentStoreCode: storeCode })
        if (!store) {
            showErrorNotification(__('This store can not be opened!'));
            return;
        }
        this.getCurrentLabel(storeCode);

        if (otherStoreUrl) {
            window.location = otherStoreUrl;
        } else if (currentLocation) {
            window.location = `/${store.value}${ currentLocation }`;
        } else {
            window.location = `/${ store.value }/`;
        }
    }

    onStoreListFetchSuccess({ storeList }) {
        this._getOtherStoreUrls(storeList);

        this.setState({
            storeList: this._formatStoreList(storeList)
        });
    }

    onOtherUrlsFetchSuccess({ getOtherStoreUrls = [] }) {
        const { currentStoreCode } = this.props;

        updateHrefLangTags.createTags(getOtherStoreUrls, currentStoreCode);

        if (getOtherStoreUrls.length === 0) {
            return;
        }

        const { store_code, url_key } = getOtherStoreUrls[0];
        const otherStoreUrl = `/${ store_code }/${ url_key }`

        this.setState({ otherStoreUrl });
    }

    getCurrentLabel(storeCode) {
        const { storeList } = this.state;

        const store = storeList.find(
            ({ value }) => value !== storeCode
        );

        if (!store) {
            return;
        }

        const { label } = store;

        this.setState({ storeLabel: label });
    }


    onStoreSwitcherClick() {
        const { storeList, currentStoreCode } = this.state;

        if (!storeList.length <= 1) {
            if (currentStoreCode === storeList[storeList.length - 1].value) {
                this._handleStoreSelect(storeList[0].value);
            } else {
                const NextStorIndex = storeList.findIndex((e) => e.value === currentStoreCode) + 1;
                this._handleStoreSelect(storeList[NextStorIndex].value);
            }
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(StoreSwitcherContainer);
