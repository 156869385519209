import { PureComponent } from "react";

import Link from "Component/Link";

import { firstLetterToUpperCase } from "Util/Helper";

import "./BlogTag.style";

export class BlogTag extends PureComponent {
	render() {
		const {
            mix,
			tag: { name, url },
		} = this.props;

		const tagName = firstLetterToUpperCase(name);

		return (
			<Link block="BlogTag" elem="TagLink" to={url}>
				<button block="BlogTag" elem="TagButton" mix={ mix }>
					{tagName}
				</button>
			</Link>
		);
	}
}

export default BlogTag;
