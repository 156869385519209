import { PureComponent } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SearchSuggestions from './SearchSuggestions.component';

export const SearchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Search/Search.dispatcher'
);

const mapStateToProps = state => ({
    topCategories: state.SearchReducer.topCategories,
    topProducts: state.SearchReducer.topProducts,
    topCategoryForTopProducts: state.SearchReducer.topCategoryForTopProducts,
    isTopCategoriesLoading: state.SearchReducer.isTopCategoriesLoading,
    isTopProductsLoading: state.SearchReducer.isTopProductsLoading,
    isTopCategoryForTopProductsLoading: state.SearchReducer.isTopCategoryForTopProductsLoading,
    xSearchSuggestions: state.SearchReducer.xSearchSuggestions,
    isXSearchLoading: state.SearchReducer.isXSearchLoading,
    device: state.ConfigReducer.device
});

const mapDispatchToProps = dispatch => ({
    getTopSearchProducts: () => SearchDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getTopSearchProducts(dispatch)
    ),
    getTopSearchCategories: () => SearchDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getTopSearchCategories(dispatch)
    ),
});

export class SearchSuggestionsContainer extends PureComponent {
    render() {
        return <SearchSuggestions
          { ...this.props }
        />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchSuggestionsContainer));
