import { Field } from 'Util/Query';

class PromotionQuery {
    getQuery(id, date) {
        if (!id) {
            throw new Error('Missing argument `id`');
        }

        const avrilPromotionField = new Field('avrilPromotion')
            .addArgument('id', 'Int!', id)
            .addField(
                new Field('promotion')
                    .addFieldList([
                        'id',
                        'name',
                        'createdAt',
                        new Field('cards')
                            .addFieldList([
                                'id',
                                'title',
                                'name',
                                'description',
                                'date',
                                'cardType',
                                'status',
                                'imageFront',
                                'imageBack',
                                'imageSpecial',
                                'link',
                                'specialLink'
                            ])
                    ])
            )
            .setAlias('promoMonth');
        
        if (date) {
            avrilPromotionField.addArgument("date", "String", date);
        }

        return avrilPromotionField;
    }
}

export default new PromotionQuery();
