/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/***/
export class StoreQuery {
    getClosestStore() {
        return new Field('getClosestStore')
            .addFieldList(this._getStoreField())
    }

    getOtherStoresUrl(currentUrl) {
        return new Field('getOtherStoreUrls')
            .addArgument('current_url', 'String!', currentUrl)
            .addFieldList([
                'store_code',
                'store_id',
                'url_key'
            ]);
    }

    getStoreByFilter(code){
        return new Field('getStoreByFilter')
        .addArgument('filter','getStoreFilter!',{"source_code":code})
        .addFieldList(this._getStoreField())
    }

    _getStoreFilter(code){
        return new Field('getStoreFilter')
        .addArgument('source_code','String',code)
    }

    _getStoreField() {
        return [
            'name',
            'street',
            'city',
            'region',
            'postcode',
            'phone',
            'pickup_location_code'
        ]
    }
}

export default new StoreQuery();
