import { PureComponent } from 'react';
import Link from 'Component/Link';


import './SitemapPage.style'

export class SitemapPage extends PureComponent {

    renderPages() {
        const { cms } = this.props
        return (
            <ul block="Pages" elem="List">
                {cms.map(e => <li block="Page"><Link to={e.url}>{e.name}</Link></li>)}
            </ul>
        )
    }

    renderCategory() {
        const { category } = this.props

        return (
            <ul block="Category" elem="List">
                {category.map(e => {
                    return (
                        <ul block="Category" elem="List">
                            <li
                                block="Category"
                                elem="Title"
                                mods={{ isFirstLevel: e.level === 2 }}
                            >
                                <Link to={e.url}>{e.name}</Link>
                            </li>
                            <ul
                                block="Category"
                                elem="subCategory" >
                                {this.renderSubLevel(e.children)}
                            </ul>
                        </ul>
                    )
                })}
            </ul>
        )
    }

    renderSubLevel(children) {

        if(children === null){
            return null
        }

        return children.map(e => {
            return (
                <>
                    <li block="Category" elem="Title">
                        <Link to={e.url}>{e.name}</Link>
                    </li>

                    {
                        !!e.children ?
                            <ul block="Category" elem="subCategory">
                                {this.renderSubLevel(e.children)}
                            </ul>
                            : null
                    }
                </>
            )
        })
    }


    render() {
        return (
            <div block="Sitemap">
                <div block="Sitemap" elem="Content">
                    <div block="Sitemap" elem="Pages">
                        <h3>{__("Pages")}</h3>
                        {this.renderPages()}
                    </div>
                    <div block="Sitemap" elem="Categories">
                        <h3>{__("Categories")}</h3>
                        {this.renderCategory()}
                    </div>
                </div>
            </div>
        );
    }
};

export default SitemapPage;
