/* eslint-disable no-param-reassign */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { DEFAULT_LOCATION_CODE } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';
import { CHECKOUT_FLOW } from 'Component/PickupOverlay/PickupOverlay.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { DELIVERY_FLOW } from 'Route/Checkout/Checkout.config';
import { CLOSEST_STORE } from 'Store/Config/Config.dispatcher';

import { BILLING_STEP } from 'Route/Checkout/Checkout.config'
import history from 'Util/History';

/**
 * get country label for current address
 * @namespace AvrilPwa-App/Util/Checkout/getCountryLabel */
export const getCountryLabel = (country_id, countries) => countries
    .reduce(
        (acc, { id, label }) => {
            if (id === country_id) {
                acc = label;
            }

            return acc;
        },
        ''
    );

/**
 * returns checkout flow depending on chosen store
 * @namespace AvrilPwa-App/Util/Checkout/getCheckoutFlowFromClosestStore */
export const getCheckoutFlowFromClosestStore = () => {
    const closestStore = BrowserDatabase.getItem(CLOSEST_STORE);
    const checkoutFlow = BrowserDatabase.getItem(CHECKOUT_FLOW);

    if (checkoutFlow) {
        return checkoutFlow;
    }

    if (!closestStore) {
        return '';
    }

    const { pickup_location_code } = closestStore;

    return pickup_location_code === DEFAULT_LOCATION_CODE
        ? DELIVERY_FLOW
        : '';
};

/**
 * get country label for current address
 * @namespace AvrilPwa-App/Util/Checkout/getCountryLabel */
export const getRegionLabel = (region_id, country_id, countries) => countries
    .reduce(
        (acc, { id, available_regions }) => {
            if (id === country_id) {
                acc = available_regions
                    .reduce((acc, { id, name }) => {
                        if (id === Number(region_id)) {
                            acc = name;
                        }

                        return acc
                    }
                , '');
            }

            return acc;
        },
        ''
    );

/**
 * get region code
 * @namespace AvrilPwa-App/Util/Checkout/getRegionCode */
export const getRegionCode = (region_id, country_id, countries) => countries
    .reduce(
        (acc, { id, available_regions }) => {
            if (id === country_id) {
                acc = available_regions
                    .reduce((acc, { id, code }) => {
                        if (id === Number(region_id)) {
                            acc = code;
                        }

                        return acc
                    }
                , '');
            }

            return acc;
        },
        ''
    );

/**
 * get streets in separated strings for checkout address table
 * @namespace AvrilPwa-App/Util/Checkout/getStreetLines */
export const getStreetLines = (street) => street
    .map((street) => {
        return {
            key: 'street',
            label: __('Street'),
            source: {
                street
            }
        };
    });

/**
 * fieldMap rearrangement in the correct order
 * @namespace AvrilPwa-App/Util/Checkout/rearrangeFieldMap */
export const rearrangeFieldMap = (fieldMapToRearrange, defaultAddressFields, onChange = null) => {
    const {
        firstname,
        lastname,
        company,
        telephone,
        city,
        postcode,
        country_id,
        region_id
    } = fieldMapToRearrange;

    const addressFields = defaultAddressFields;

    const fieldMap = {
        firstname: {
            ...firstname,
            placeHolder: __('Firstname')
        },
        lastname: {
            ...lastname,
            placeHolder: __('Lastname')
        },
        company: {
            ...company,
            optional: true,
            placeHolder: __('Company Name')
        },
        ...addressFields,
        city: {
            ...city,
            placeHolder: __('City')
        },
        country_id: {
            ...country_id

        },
        region_id: {
            ...region_id
        },
        postcode: {
            ...postcode,
            label: __('Postal code'),
            placeHolder: __('Postal code')
        },
        telephone: {
            ...telephone,
            validation: ['notEmpty', 'telephone'],
            placeHolder: __('Phone')
        }
    };

    if (onChange) {
        fieldMap.city.onChange = (value) => onChange('city', value);
    }

    return fieldMap;
};

export const getIsCurrentLoaction = (pathToCheck) => {
    const { location: { pathname } } = history;

    return pathname.includes(pathToCheck);
}

export const getPickupShippingAddress = (selectedStore, countries, storeCode) => {
    const {
        name,
        city,
        country_id,
        postcode,
        region_id,
        street,
        phone: telephone
    } = selectedStore;

    const region = getRegionLabel(region_id, country_id, countries);
    const region_code = getRegionCode(region_id, country_id, countries);
    const splittedStoreName = name.split(' ');
    // translation function returns object, but we need to set a string
    const pickupTranslated = storeCode === 'fr' ? 'Ramassage' : 'Pickup';

    const pickupNames = splittedStoreName.length > 1
        ? { firstname: splittedStoreName[0], lastname: splittedStoreName.slice(1).join(' ') }
        : { firstname: name, lastname: pickupTranslated };

    return {
        ...pickupNames,
        city,
        country_id,
        postcode,
        region,
        region_code,
        region_id,
        street: [street],
        telephone
    };
}

/**
 * get billing address data-object from form
 * @namespace AvrilPwa-App/Util/Checkout/getBillingAddress */
export const getBillingAddress = () => {
    const billingFormElems = document.getElementById(BILLING_STEP).elements;
    const billingAddressFieldsIds = [
        'city',
        'country_id',
        'firstname',
        'lastname',
        'postcode',
        'region',
        'region_id',
        'street',
        'telephone'
    ];

    const billingAddressAllFields = {};

    for (let i = 0; i < billingFormElems.length; i++) {
        const { id, value } = billingFormElems[i];
        billingAddressAllFields[id] = value;
    };

    const billingAddress = Object.entries(billingAddressAllFields).reduce((acc, [id, value]) => {
        if (billingAddressFieldsIds.includes(id)) {
            acc[id] = value;
        } else if (id.includes('street') && !!acc.street) {
            value ? acc.street.push(value) : acc.street.push(undefined);
        } else if (id.includes('street') && !acc.street) {
            acc.street = [];
            value ? acc.street.push(value) : acc.street.push(undefined);
        }

        return acc;
    }
    , {});

    return billingAddress;
}

/**
 * get billing address data-object from form
 * @namespace AvrilPwa-App/Util/Checkout/getBillingAddress */
export const trimAddressMagentoStyle = (address, countries) => {
    const {
        country_id,
        region_code, // drop this
        region_id,
        region,
        ...restOfBillingAddress
    } = address;

    const newAddress = {
        ...restOfBillingAddress,
        country_code: country_id,
        region
    };

    /**
     * If there is no region specified, but there is region ID
     * get the region code by the country ID
     */
    if (region_id) {
        // find a country by country ID
        const { available_regions } = countries.find(
            ({ id }) => id === country_id
        ) || {};

        if (!available_regions) {
            return newAddress;
        }

        // find region by region ID
        const { code } = available_regions.find(
            ({ id }) => +id === +region_id
        ) || {};

        if (!code) {
            return newAddress;
        }

        newAddress.region = code;
    }

    return newAddress;
}

export const changeShippingRootBackground = (isUnmounting) => {
    // coloring the page for checkout shipping step
    const rootElem = document.getElementById('root');

    isUnmounting
        ? rootElem.style.backgroundColor = '#fff'
        : rootElem.style.backgroundColor = '#fafbfb';
}
