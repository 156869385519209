import {
    lazy,
} from 'react';
import { Route } from 'react-router-dom';

import {
    Router as SourceRouter
} from 'SourceComponent/Router/Router.component';

import BlogCmsBanner from 'Component/BlogCmsBanner';
import RecipeCmsBanner from 'Component/RecipeCmsBanner';
import Footer from 'Component/Footer';
import CookiePopup from 'Component/CookiePopup';
import PickupOverlay from 'Component/PickupOverlay';
import ProductAvailabilityPopup from 'Component/ProductAvailabilityPopup';
import UrlRewrites from 'Route/UrlRewrites';
import { componentLoader } from 'Util/Component';

import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    SWITCH_ITEMS_TYPE
} from './Router.config';

import './Router.style';

/** @namespace Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const CartPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'), 'Route/CartPage'));
export const Checkout = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'), 'Route/Checkout'));
export const CmsPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'), 'Route/CmsPage'));
export const HomePage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'), 'Route/HomePage'));
export const LoginPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/LoginPage'), 'Route/LoginPage'));
export const MyAccount = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'), 'Route/MyAccount'));
export const MyAccountRegistration = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccountRegistration'), 'Route/MyAccountRegistration'));
export const ConfirmAccountPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/ConfirmAccountPage'), 'Route/ConfirmAccountPage'));
export const PasswordChangePage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage'), 'Route/PasswordChangePage'));
export const WishlistShared = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/WishlistSharedPage'), 'Route/WishlistSharedPage'));
export const SearchPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/SearchPage'), 'Route/SearchPage'));
export const MenuPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Route/MenuPage'), 'Route/MenuPage'));
export const BlogPostPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "blog" */ 'Route/BlogPostPage'), 'Route/BlogPostPage'));
export const BlogPostPreviewPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "blog" */ 'Route/BlogPostPreviewPage'), 'Route/BlogPostPreviewPage'));
export const RecipePage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "recipe" */ 'Route/RecipePage'), 'Route/RecipePage'));
export const FlippPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "flipp" */ 'Route/FlippPage'), 'Route/FlippPage'));
export const RequestBirthday = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/RequestBirthday'), 'Route/RequestBirthday'));
export const BonusStep = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/BonusStep'), 'Route/BonusStep'));
export const NewsletterSubscription = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/NewsletterSubscription'), 'Route/NewsletterSubscription'));

export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        ...this.BEFORE_ITEMS_TYPE,
        {
            component: <BlogCmsBanner isRenderedInRouter />,
            position: 28
        },
        {
            component: <RecipeCmsBanner isRenderedInRouter />,
            position: 29
        },
        {
            component: <PickupOverlay />,
            position: 36
        },
        {
            component: <ProductAvailabilityPopup />,
            position: 37
        },
    ];

    [SWITCH_ITEMS_TYPE] = [
        // rewrite all the switch items to lazy load them with componentLoader wrapper
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55
        },
        {
            component: <Route path={ withStoreRegex('/:account*/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60
        },
        {
            component: <Route path={ withStoreRegex('/:account*/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 70
        },
        {
            component: <Route path={ withStoreRegex('/forgot-password') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 71
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81
        },
        {
            component: <Route path={withStoreRegex('/login/')} render={(props) => <LoginPage {...props} />} />,
            position: 90
        },
        {
            component: <Route path={withStoreRegex('/registration/')} render={(props) => <MyAccountRegistration {...props} />} />,
            position: 91
        },
        {
            component: <Route exact path={withStoreRegex('/amblog/post/preview/amblog_key/:amblog_key')} render={(props) => <BlogPostPreviewPage {...props} />} />,
            position: 110
        },
        {
            component: <Route exact path={withStoreRegex('/flyers')} render={(props) => <FlippPage {...props} />} />,
            position: 120
        },
        {
            component: <Route exact path={withStoreRegex('/request-birthday')} render={ (props) => <RequestBirthday { ...props } /> } />,
            position: 130
        },
        {
            component: <Route exact path={withStoreRegex('/completion')} render={ (props) => <BonusStep { ...props } /> } />,
            position: 140
        },
        {
            component: <Route exact path={withStoreRegex('/newsletter-subscription')} render={ (props) => <NewsletterSubscription { ...props } /> } />,
            position: 140
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000
        },
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <CookiePopup />,
            position: 20
        }
    ];

    renderFooter() {
        return <Footer />;
    }

    renderDefaultRouterContent() {
        const { mainRef, isLoading, isContentLoading } = this.props;
        return (
            <>
                {/* footer in a separate container, to align it to the bottom of a page */}
                <div
                    block="Router"
                    elem="MainContainer"
                    id="Router"
                    ref={mainRef}
                    mods={{ isLoading: isLoading }}
                >
                    {this.renderItemsOfType(BEFORE_ITEMS_TYPE)}
                    {this.renderMainItems()}
                    {this.renderItemsOfType(AFTER_ITEMS_TYPE)}
                    <div
                      id="RouterSearchInsentShadow"
                      block="Router"
                      elem="SearchInsentShadow"
                    />
                </div>
                <div
                    block="Router"
                    elem="FooterContainer"
                    id="FooterWrapper"
                    mods={{ isContentLoading: isContentLoading }}
                >
                    {this.renderFooter()}
                </div>
            </>
        );
    }
}

export default Router;
 