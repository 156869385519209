import { PureComponent, createRef } from 'react'

import Link from 'Component/Link';
import { capitalizeBrand } from 'Util/Product/Product';

import './SearchSuggestionRow.style.scss';

export class SearchSuggestionRow extends PureComponent {
    linkRef = createRef();

    componentDidMount() {
        this.setInnerContent();
    }

    componentDidUpdate(prevProps) {
        const { linkTxt: prevLinkTxt } = prevProps;
        const { linkTxt } = this.props;

        if (linkTxt !== prevLinkTxt) {
            this.setInnerContent();
        }
    }

    setInnerContent() {
        const { linkTxt } = this.props;

        if (this.linkRef.current) {
            this.linkRef.current.innerHTML = linkTxt;
        }
    }

    renderBrand() {
        const { brand } = this.props;

        if (!brand) {
            return null;
        }

        const capitalizedBrand = capitalizeBrand(brand);

        return (
            <span
              block="SearchSuggestionRow"
              elem="ProductBrand"
            >
                { capitalizedBrand }
            </span>
        );
    }

    render() {
        const { linkUrl, brand, mods } = this.props;

        return (
            <Link
              to={ linkUrl }
              mix={ {
                block: "SearchSuggestionRow",
                mods: { ...mods, isWithBrand: !!brand },
                mix: {
                  block: "SearchWindow",
                  elem: "CategoriesNavItem"
                }
              } }
            >
                <span ref={ this.linkRef } />
                <br />
                { this.renderBrand() }
            </Link>
        );
    }
}

export default SearchSuggestionRow;
