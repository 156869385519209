/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    FieldSelectContainer as SourceFieldSelectContainer
} from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    sortSelectOptions() {
        const { selectOptions } = this.props;

        /**
         * Trim all null label values, sort alphabetically
         */
        const sortedOptions = selectOptions.reduce(
            (acc, a) => (a.label ? [...acc, a] : acc), []
        ).sort((a, b) => {
            if (a.keepPosition || b.keepPosition) {
                return 0;
            }

            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            // eslint-disable-next-line no-nested-ternary
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        return sortedOptions;
    }
}

export default FieldSelectContainer;
