import { Fragment, createRef } from 'react';
import Link from 'Component/Link';
import ClickOutside from 'Component/ClickOutside';
import {
    sortCategoriesByPosition,
    getIncludedCategories,
    getNextActiveSubMenu,
    getNextActiveSubMenuKey,
    getSubmenuItemsByParts,
    getFullFirstLvlMenuHeight,
    getIsConsistsActiveChildren,
    resetActiveSubmenuWidth
} from 'Util/Ecom';
import { throttle, freezeGlobalScrollModule } from 'Util/Window';

import '../EcomMenu/EcomMenu.style';

export class EcomMenuFirstLevel extends React.PureComponent {
    ecomWrapperRef = createRef();
    wrapperWidthRef = createRef();
    ecomCmsLinkRef = createRef();
    ecomContentRef = createRef();
    subMenuWrapperRef = createRef();
    ecomMenuLinksWrapperRef = createRef();
    ecomSubcategoryRef = createRef();

    constructor(props) {
        super(props);

        const location = window.location.pathname;

        this.state = {
            activeLink: "",
            activeLinkChildren: [],
            activeSubLinkChildren: [],
            secondLvlsubMenu: {},
            subLink: "",
            location,
            ecomSubmenuMaxHeight: 0,
            direction: true,
            linksInPortion: 0
        };
    }

    wrapperRenderMap = [
        {
            ref: this.ecomWrapperRef,
            mix: { block: "ecom", elem:"wrapper" },
            isClickableOutside: true
        },
        {
            ref: this.wrapperWidthRef,
            mix: { block: "ecom", elem:"wrapper", mods: { isWidthRef: true } },
            isClickableOutside: false
        }
    ]

    static getDerivedStateFromProps(props, state) {
        const { setActiveLinks, name } = props;

        if (state.location !== props.locations) {
            window.scrollTo(0, 0)
            setActiveLinks(name, true, true);
            return ({
                activeLink: "",
                subLink: "",
                location: props.locations,
                secondLvlsubMenu: {
                    menu_1: {
                        isActive: false,
                        activeSubLinkChildren: []
                    },
                    menu_2: {
                        isActive: false,
                        activeSubLinkChildren: []
                    }
                }
            });
        }

        return null;
    }

    componentDidMount() {
        this.setState({ location: location.pathname });
        this.onResize();

        this.setInitialSubMenuState();
        this.getMaxSubmenuHeight();

        this.subMenuWrapperRef.current.addEventListener('transitionend', this.setSubMenuesStyles.bind(this));
        window.addEventListener("scroll", (e) => this.scrollHandler(e));
        window.addEventListener("resize", throttle(this.onResize.bind(this), 200));
    }

    componentDidUpdate(_, prevState) {
        const { subLink } = this.state;
        const { subLink: prevSubLink } = prevState;

        this.onResize();
        this.getMaxSubmenuHeight();
        this.setSubMenuesStyles();

        if (subLink !== prevSubLink) {
            resetActiveSubmenuWidth(this.ecomSubcategoryRef);
        }
    }

    componentWillUnmount() {
        this.subMenuWrapperRef.current.removeEventListener('transitionend', this.setSubMenuesStyles.bind(this));
        window.removeEventListener('scroll', (e) => this.scrollHandler(e));
        window.removeEventListener('resize', throttle(this.onResize.bind(this), 200));
    }

    onResize() {
        this.setEcomMenuHeights();
        this.setSubMenuesStyles();
    }

    setSubMenuesStyles() {
        const { ecomSubmenuMaxHeight } = this.state;

        const subMenuWrapper = this.subMenuWrapperRef.current;
        const offset = 15;

        if (!subMenuWrapper) {
            return;
        }

        Array.prototype.forEach.call(subMenuWrapper.children, (child, idx) => {

            const { bottom, top, height } = child.getBoundingClientRect();
            const maxWindowHeightForFullSize = ecomSubmenuMaxHeight + offset + top;

            const isScrollableMenu = bottom > 0
                && maxWindowHeightForFullSize > window.innerHeight
                && height <= ecomSubmenuMaxHeight;

            const isFullSizeMenu = bottom > 0
                && maxWindowHeightForFullSize <= window.innerHeight;

            this.setSubMenuesHeights(child, idx, offset, isScrollableMenu, isFullSizeMenu);
            this.setSubMenuesOverflow(child, idx, isScrollableMenu, isFullSizeMenu);
        });
    }

    setSubMenuesHeights(
        child,
        idx,
        offset,
        isScrollableMenu,
        isFullSizeMenu
    ) {
        const { ecomSubmenuMaxHeight } = this.state;
        const elemToTop = child.getBoundingClientRect().top;

        if (isScrollableMenu) {
            const calculatedHeight = Math.round(window.innerHeight - elemToTop - offset);
            const newHeight = idx === 0
                ? calculatedHeight - 36
                : calculatedHeight - 18;

            child.style.height = `${ newHeight }px`;

        } else if (isFullSizeMenu) {
            const newHeight = idx === 0
                ? ecomSubmenuMaxHeight - 36
                : ecomSubmenuMaxHeight;

            child.style.height = `${ newHeight }px`;
        }
    }

    setSubMenuesOverflow(
        child,
        idx,
        isScrollableMenu,
        isFullSizeMenu
    ) {
        const elem = idx === 0 ? child : child.children[0];

        if (isScrollableMenu) {
            elem.style.overflowY = 'scroll';
        } else if (isFullSizeMenu) {
            elem.style.overflowY = 'visible';
        }
    }

    getMaxSubmenuHeight() {
        if (!this.subMenuWrapperRef.current) {
            return 0;
        }

        const submenuWrapper = this.subMenuWrapperRef.current;
        const ecomSubcategory = this.ecomSubcategoryRef.current;
        const ecomSubmenuMaxHeight = getFullFirstLvlMenuHeight(submenuWrapper, ecomSubcategory);

        this.setState({ ecomSubmenuMaxHeight }, this.setSubMenuesStyles);
    }



    setLinksInPortion(linksInPortion) {
        this.setState({ linksInPortion });
    }

    setInitialSubMenuState() {
        this.setState({
            secondLvlsubMenu: {
                menu_1: {
                    isActive: false,
                    activeSubLinkChildren: []
                },
                menu_2: {
                    isActive: false,
                    activeSubLinkChildren: []
                }
            }
        })
    }

    setEcomMenuHeights(e) {
        if (!this.ecomMenuLinksWrapperRef.current) {
            return;
        }

        const { height } = this.ecomMenuLinksWrapperRef.current.getBoundingClientRect();
        const singleLinkHeight = this.getSingleLinkHeight();

        if (!singleLinkHeight) {
            return;
        }

        const linksInPortion = Math.round(height / singleLinkHeight);

        this.setLinksInPortion(linksInPortion);
    }

    getSingleLinkHeight() {
        if (!this.ecomMenuLinksWrapperRef.current || !this.ecomMenuLinksWrapperRef.current.children[0]) {
            return 0;
        }

        return this.ecomMenuLinksWrapperRef.current.children[0].getBoundingClientRect().height || 0;
    }

    setSubMenu(newActiveSubLinkChildren) {
        const { secondLvlsubMenu } = this.state;
        const nextActiveKey = getNextActiveSubMenuKey(secondLvlsubMenu);
        this.setSecondLvlsubMenu(nextActiveKey, newActiveSubLinkChildren);
    }

    setSecondLvlsubMenu(nextActiveKey, newActiveSubLinkChildren) {
        const { secondLvlsubMenu: prevSecondLvlsubMenu } = this.state;

        const subMenuArr = Object.entries(prevSecondLvlsubMenu);
        const secondLvlsubMenu = getNextActiveSubMenu(subMenuArr, nextActiveKey, newActiveSubLinkChildren);

        this.setState({ secondLvlsubMenu });
    }

    scrollHandler(e) {
        const { scrollPosition } = this.state
        const { setActiveLinks, name } = this.props
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (!e || !e.target || !e.target.documentElement) {
            // Invalid event object or properties, return early
            return;
        }
        
        const isScrollDisable = /scrollDisabled/.test(e.target.documentElement.className)

        if(isScrollDisable){
            return;
        }

        if (scrollTop >= scrollPosition && scrollTop > 0) {
            setActiveLinks(name, true, true);
            this.setState({ scrollPosition: scrollTop, direction: false, activeLink: "", subLink: "" });
        } else {
            this.setState({ scrollPosition: scrollTop, direction: true })
        }
    }

    setActiveSubLink(name, activeSubLinkChildren) {
        const { subLink } = this.state;

        if (subLink === name) {
            return;
        }

        this.setSubMenu(activeSubLinkChildren);
        this.setState({
            subLink: name,
            activeSubLinkChildren
        });
    }

    renderCategoryAtLevel(category) {
        const {
            name,
            url
        } = category;

        return (
            <Link
              block="firstLevel"
              elem="categories"
              key={ url }
              to={ url }
              onClick={ () => freezeGlobalScrollModule.unfreezeScroll() }
              mods={{ withOutChildren: true }}
            >
                { name }
            </Link>
        );
    }

    renderCategoriesLevel(categories) {
        if (!categories || !categories.length) {
            return null;
        }

        return categories.map(this.renderCategoryAtLevel.bind(this));
    }

    renderViewAllLink(url) {
        return <Link
          block="firstLevel"
          elem="viewAll"
          to={url}
          onClick={ () => freezeGlobalScrollModule.unfreezeScroll() }
        >
            { __('View all') }
        </Link>;
    }

    renderSubLevel(isSubActive, parentCategories, categories, subLevelItems, levelIndex) {
        return (
            <div
                block="subLevel"
                data-level={ levelIndex }
                mods={ {
                    isBigger: categories.length >= parentCategories.length,
                    isSubActive: isSubActive && !!subLevelItems.length,
                    isSecondLvl: levelIndex === 1,
                    isThirdLvl: levelIndex === 2,
                    isFourthLvl: levelIndex === 3
                } }
            >
                <div
                  block="subLevel"
                  elem="Transitionable"
                >
                    { this.renderFirstLevelLinks(levelIndex) }
                    { this.renderCategoriesLevel(subLevelItems) }
                </div>
            </div>
        );
    }

    renderFirstLevelLinks(levelIndex) {
        const {
            subLink,
            activeLinkChildren
        } = this.state;

        if (levelIndex !== 0) {
            return null;
        }

        const selectedCategory = activeLinkChildren.filter(({ name }) => name === subLink)[0];
        const { url } = selectedCategory || {};

        return(
            <>
                <h4>
                    { __('category') }
                </h4>
                { this.renderViewAllLink(url, url) }
            </>
        );
    }

    renderFirstLevelCategory(_, category) {
        const { subLink } = this.state;
        const {
            include_in_menu,
            name,
            url,
            id,
            children: childCategoryChildren
        } = category;

        if (include_in_menu === 0) {
            return null
        }

        if (!childCategoryChildren.length) {
            return <Link
              block="firstLevel"
              elem="categories"
              key={ id }
              to={ url }
              mods={{ withOutChildren: true }}
              onClick={ () => freezeGlobalScrollModule.unfreezeScroll() }
            >
                { name }
            </Link>
        }

        if (!getIsConsistsActiveChildren(childCategoryChildren)) {
            return this.renderCategoryAtLevel(category);
        }

        return (
            <Fragment key={ name }>
                <p
                  block="firstLevel"
                  elem="categories"
                  mods={{ isFirstLevelActive: name == subLink }}
                  onClick={ () => this.setActiveSubLink(name, childCategoryChildren) }
                >
                    { name }
                </p>
            </Fragment>
        )
    }

    renderSecondLevelSubMenu(isActive, activeSubLinkChildren) {
        const { activeLinkChildren } = this.state;

        const enabledFirstLevelArr = getIncludedCategories(activeLinkChildren);
        const enabledSubLevelArr = getIncludedCategories(activeSubLinkChildren);

        const subLevelByParts = getSubmenuItemsByParts(enabledFirstLevelArr, enabledSubLevelArr);

        // rendering 3 sublevels
        while (subLevelByParts.length !== 3) {
            subLevelByParts.push([]);
        }

        return subLevelByParts.map((subLevelPart, idx) => {
            return this.renderSubLevel(
                isActive,
                activeLinkChildren,
                activeSubLinkChildren,
                subLevelPart,
                idx
            );
        });
    }

    renderSecondLevelSubMenues() {
        const { secondLvlsubMenu } = this.state;

        return Object
            .values(secondLvlsubMenu)
            .map((
                {
                    isActive,
                    activeSubLinkChildren
                }
            ) => this.renderSecondLevelSubMenu(isActive, activeSubLinkChildren));
    }

    renderSubMenu(category) {
        const { url, children } = category || {};

        return (
            <>
                <div
                  block="ecom"
                  elem="subCategory"
                  ref={ this.ecomSubcategoryRef }
                >
                    <h4>
                        { __('departments') }
                    </h4>
                    { this.renderViewAllLink(url) }
                    <div
                      block="firstLevel"
                      ref={ this.ecomMenuLinksWrapperRef }
                    >
                        {
                            sortCategoriesByPosition(children)
                                .map(this.renderFirstLevelCategory.bind(this, children))
                            }
                    </div>
                </div>
                { this.renderSecondLevelSubMenues(category) }
            </>
        )
    }

    setActiveMainLink(_, name, activeLinkChildren) {
        const { setActiveLinks } = this.props;

        freezeGlobalScrollModule.freezeScroll();
        setActiveLinks(name);
        this.setState({
            activeLink: name,
            activeLinkChildren,
            subLink: ""
        });
    }

    onOutsideClick(e, clickOutside) {
        const { activeLink } = this.state;
        const { setActiveLinks, name } = this.props;

        if (clickOutside && activeLink == e.name) {
            this.setInitialSubMenuState();
            setActiveLinks(name, true);
            this.setState({
                activeLink: "",
                activeLinkChildren: [],
                activeSubLinkChildren: [],
                subLink: ""
            });

            setTimeout(() => {
                const { activeLinks } = this.props;

                if (!activeLinks.length) {
                    freezeGlobalScrollModule.unfreezeScroll();
                }
            });
        }
    }

    renderMenuMainUnit() {
        const { activeLink } = this.state
        const {
            category: { url },
            name,
            children,
        } = this.props;

        const isActive = activeLink == name;


        if (!children?.length) {
            return (
                <Link
                  to={ url }
                  mix={ { block: "MainLink" } }
                >
                    { name }
                </Link>
            );
        }

        return (
            <p block="MainLink"
                mods={ { isActive } }
                onClick={ (event) => this.setActiveMainLink(event, name, children) }
            >
                { name }
            </p>
        );
    }

    render() {
        const {
            category,
            clickOutside,
            name,
            activeLinks
        } = this.props;

        return (
            <ClickOutside onClick={ this.onOutsideClick.bind(this, category, clickOutside) }>
                <div
                  block="ecom"
                  elem="MainLinkWrapper"
                >
                    {this.renderMenuMainUnit()}
                    <div
                      block="ecom"
                      elem="SubMenuWrapper"
                      ref={ this.subMenuWrapperRef }
                    >
                        {this.renderSubMenu(category)}
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

export default EcomMenuFirstLevel;
