/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export { default } from 'SourceUtil/Price/Price.config';

export const EN_STORE = 'en';
export const FR_STORE = 'fr';
export const CAD = 'CAD';
export const USD = 'USD';
