/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getProductsWithIdKeys } from "Util/Product";

import {
    UPDATE_POST_DATA,
    UPDATE_IS_POST_LOADING,
    UPDATE_IS_SIMILAR_POSTS_LOADING,
    UPDATE_ARE_RELATED_PRODUCTS_LOADING,
    UPDATE_POST_RELATED_PRODUCTS,
    UPDATE_SIMILAR_POSTS
} from "./BlogPost.action";

/** @namespace Store/Blog/Reducer/getInitialState */
export const getInitialState = () => {
    return {
        postData: {},
        postRelatedProducts: [],
        similarPosts: [],
        isSimilarPostsLoading: false,
        isPostLoading: false,
        areRelatedProductsLoading: false,
    };
};

export const BlogPostReducer = (state = getInitialState(), action) => {
    const {
        type,
        postData,
        postRelatedProducts,
        similarPosts,
        isPostLoading,
        isSimilarPostsLoading,
        areRelatedProductsLoading,
    } = action;

    switch (type) {
        case UPDATE_POST_DATA:
            return {
                ...state,
                postData,
            };

        case UPDATE_IS_POST_LOADING:
            return {
                ...state,
                isPostLoading,
            };

        case UPDATE_IS_SIMILAR_POSTS_LOADING:
            return {
                ...state,
                isSimilarPostsLoading,
            };

        case UPDATE_ARE_RELATED_PRODUCTS_LOADING:
            return {
                ...state,
                areRelatedProductsLoading,
            };

        case UPDATE_POST_RELATED_PRODUCTS:
            return {
                ...state,
                postRelatedProducts,
            };

        case UPDATE_SIMILAR_POSTS:
            return {
                ...state,
                similarPosts,
            };

        default:
            return state;
    }
};

export default BlogPostReducer;
