/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import BlogPostPage from "./BlogPostPage.component";
import BlogQuery from "Query/Blog.query";
import { updateMeta } from "Store/Meta/Meta.action";

import { composeBlogPostData, ogTagsModule } from "Util/Blog";
import { setMainMarginTop } from "Util/Window";

export const BlogPostDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/BlogPost/BlogPost.dispatcher"
);

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/Breadcrumbs/Breadcrumbs.dispatcher"
);

export const mapStateToProps = (state) => ({
    postData: state.BlogPostReducer.postData?.amBlogPost || {},
    isPostLoading: state.BlogPostReducer.isPostLoading,
    isSimilarPostsLoading: state.BlogPostReducer.isSimilarPostsLoading,
    similarPosts: state.BlogPostReducer.similarPosts,
    blogTags: state.BlogReducer.blogTags,
    blogAuthors: state.BlogReducer.blogAuthors,
    blogCategories: state.BlogReducer.blogCategories,
    blogSettings: state.BlogReducer.blogSettings,
    postRelatedProducts: state.BlogPostReducer.postRelatedProducts,
    areRelatedProductsLoading: state.BlogPostReducer.areRelatedProductsLoading,
    device: state.ConfigReducer.device,
    blogPosts: state.BlogReducer.blogPosts,
});

export const mapDispatchToProps = (dispatch) => ({
    getPostById: (postId) =>
        BlogPostDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getPostByIdentificator(
                dispatch,
                BlogQuery.getPostByIdQuery,
                postId
            )
        ),
    getRelatedProductsByPostId: (postId) =>
        BlogPostDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getRelatedProductsByPostId(dispatch, postId)
        ),
    getSimilarPostsIds: (ids) =>
        BlogPostDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getSimilarPostsIds(dispatch, ids)
        ),
    updateBreadcrumbs: (breadcrumbs) =>
        BreadcrumbsDispatcher.then(({ default: dispatcher }) =>
            dispatcher.update(breadcrumbs, dispatch)
        ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
});

/** @namespace Route/BlogPostPage/Container */
export class BlogPostPageContainer extends PureComponent {
    similarPostsContainerRef = createRef();

    state = {
        isLoading: false,
        isBreadSet: false,
        similarPostsActiveImg: 0,
    };

    componentDidMount() {
        const { getPostById, getRelatedProductsByPostId, postId } = this.props;

        getPostById(postId).then(this.getSimilarPosts.bind(this));
        getRelatedProductsByPostId(postId);
        this.setBreadcrumbs();
        window.scrollTo(0, 0);
        this.setOgMetaTags();
    }

    componentDidUpdate(prevProps) {
        const {
            postData,
            device: { isMobile },
            updateMeta,
            history: {
                location: { pathname },
            },
            blogSettings: {
                search_engine_title_suffix,
                search_engine_title_prefix,
            } = {},
        } = this.props;

        const {
            postData: prevPostData,
            history: {
                location: { pathname: prevPathname },
            },
        } = prevProps;

        if (pathname !== prevPathname) {
            window.scrollTo(0, 0);
        }

        setMainMarginTop(isMobile);
        this.setBreadcrumbs();

        if (
            Object.keys(postData).length &&
            JSON.stringify(postData) !== JSON.stringify(prevPostData)
        ) {
            const { meta_title, meta_description, meta_tags } = postData;

            updateMeta({
                title: meta_title,
                description: meta_description,
                keywords: meta_tags,
                title_prefix: search_engine_title_prefix,
                title_suffix: search_engine_title_suffix,
            });
            ogTagsModule.removeOgTags();
            this.setOgMetaTags();
        }
    }

    componentWillUnmount() {
        const { updateMeta } = this.props;

        updateMeta({
            title: "",
            description: "",
            keywords: "",
            title_prefix: "",
            title_suffix: "",
        });
        ogTagsModule.removeOgTags();
    }

    containerProps = () => {
        const {
            postRelatedProducts,
            similarPosts,
            areRelatedProductsLoading,
            isPostLoading,
            isSimilarPostsLoading,
            device,
            blogSettings,
            blogPosts: { all_post_size },
        } = this.props;

        const post = this.getPost();

        return {
            post,
            postRelatedProducts,
            similarPosts,
            isPostLoading,
            isSimilarPostsLoading,
            areRelatedProductsLoading,
            device,
            all_post_size,
            blogSettings,
            searchProductsScrollableRef: this.searchProductsScrollableRef,
        };
    };

    containerFunctions = {
        updateSimilarPostsActiveImg:
            this.updateSimilarPostsActiveImg.bind(this),
    };

    setOgMetaTags() {
        const {
            postData: {
                is_open_graph_enabled,
                open_graph_meta_type,
                open_graph_meta_title,
                open_graph_meta_description,
                list_thumbnail
            } = {},
        } = this.props;

        if (!is_open_graph_enabled) {
            return;
        }

        // ogTagsModule.setOgTags({
        //     "og:title": open_graph_meta_title,
        //     "og:type": open_graph_meta_type,
        //     "og:image": list_thumbnail,
        //     "og:url": location.href,
        //     "og:description": open_graph_meta_description
        // });
    }

    updateSimilarPostsActiveImg(similarPostsActiveImg) {
        this.setState({ similarPostsActiveImg });
    }

    getSimilarPosts(post) {
        const { getSimilarPostsIds } = this.props;
        const {
            amBlogPost: { related_post_ids = "" },
        } = post;

        const relatedPostIds = related_post_ids
            .split(",")
            .map((id) => Number(id))
            .slice(0, 4);

        if (typeof relatedPostIds[0] !== "number") {
            return;
        }

        getSimilarPostsIds(relatedPostIds);
    }

    getPost() {
        const {
            postData,
            blogTags: { items: tags = [] } = {},
            blogAuthors: { items: authors = [] } = {},
            blogCategories: { items: categoriesItems = [] } = {},
        } = this.props;

        return composeBlogPostData(postData, tags, authors, categoriesItems);
    }

    setBreadcrumbs() {
        const {
            blogSettings: { search_engine_route, search_engine_bread } = {},
            updateBreadcrumbs,
        } = this.props;
        const { isBreadSet } = this.state;
        const post = this.getPost();
        const { url, categories } = post;

        if (isBreadSet || !url || !categories.length) {
            return;
        }

        const breadcrumbs = [
            { url: "/", name: __("Home") },
            {
                url: `/${search_engine_route}`,
                name: search_engine_bread,
            },
        ];

        if (categories.length) {
            breadcrumbs.splice(1, 0, {
                url: categories[0].url,
                name: categories[0].name,
            });
        }

        this.setState({ isBreadSet: true });
        updateBreadcrumbs(breadcrumbs);
    }

    render() {
        return (
            <BlogPostPage
                {...this.state}
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BlogPostPageContainer)
);
