import ClickOutside from 'Component/ClickOutside';
import {
    Popup as SourcePopup,
} from 'SourceComponent/Popup/Popup.component';
import { ESCAPE_KEY } from './Popup.config';

import './Popup.extend.style'

export class Popup extends SourcePopup {
    
    hidePopUp = this.hidePopUp.bind(this);

    hidePopupAndGoBack = this.hidePopupAndGoBack.bind(this);

    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown.bind(this));
    }

    componentDidUpdate(prevProps) {
        const { shouldPopupClose, resetHideActivePopup } = this.props;
        const { shouldPopupClose: prevShouldPopupClose } = prevProps;

        if (shouldPopupClose && shouldPopupClose !== prevShouldPopupClose) {
            this.hidePopUp();
            resetHideActivePopup();
        }

        super.componentDidUpdate(prevProps);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown.bind(this));
        this.onHide()
    }

    handleKeyDown = (e) => {
        const { isCloseableOnEsc = true } = this.props;

        switch (e.keyCode) {
        case ESCAPE_KEY:
            if (isCloseableOnEsc) {
                this.hidePopupAndGoBack();
            }
            break;
        default:
            break;
        }
    };

    onVisible() {
        const { onVisible } = this.props;
        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        window.history.pushState(
            {
                popupOpen: true
            },
            '',
            `${ location.pathname }${ location.search }${ location.hash }`
        );

        onVisible();
    }

    hidePopupAndGoBack() {
        this.hidePopUp();
        history.goBack();
    }

    // Same with click outside
    handleClickOutside() {
        const { clickOutside, isMobile } = this.props;

        if (!clickOutside) {
            return;
        }

        if (isMobile) {
            return;
        }

        this.hidePopupAndGoBack();
    }

    renderContent() {
        const {
            children,
            contentMix,
            isCheckoutAddressPopup,
            isCloseBtnHidden
        } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { (!isCheckoutAddressPopup && !isCloseBtnHidden) && this.renderCloseButton() }
                    </header>
                    { (isCheckoutAddressPopup && !isCloseBtnHidden) && this.renderCloseButton() }
                    { children }
                </div>
            </ClickOutside>
        );
    }
};

export default Popup;
