/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from "react";

import BlogCategoriesWidget from "Component/BlogCategoriesWidget";
import BlogPagination from "Component/BlogPagination";
import BlogPost from "Component/BlogPost";
import BlogTagsWidget from "Component/BlogTagsWidget";
import BlogMobileNav from "Component/BlogMobileNav";
import BlogMobileMenuButtonRow from "Component/BlogMobileMenuButtonRow";
import CmsBlock from "Component/CmsBlock/CmsBlock.component";
import ContentWrapper from "Component/ContentWrapper";
import { DEFAULT_PLACEHOLDER_COUNT } from "./BlogPage.config";
import { ALL, CATEGORY } from "./BlogPage.config";
import BlogCmsBanner from "Component/BlogCmsBanner";

import { isMobile as isMobileUtil } from "Util/Mobile";

import "./BlogPage.style";

/** @namespace Route/BlogPage/Component */
export class BlogPage extends PureComponent {
    renderSinglePost(postData, isPlaceholder) {
        // array.map(fn.bind(this)) will pass indices if no arguments
        isPlaceholder = isPlaceholder && typeof isPlaceholder !== "number";
        return <BlogPost postData={postData} isPlaceholder={isPlaceholder} />;
    }

    renderPagination() {
        const { getPostsByPage } = this.props;

        return (
            <div block="BlogPage" elem="Pagination">
                <BlogPagination getPosts={getPostsByPage} pageUrlKey="page" />
            </div>
        );
    }

    renderCategoriesTree() {
        const { pageType, entityId } = this.props;

        return (
            <div block="BlogPage" elem="CategoriesTree">
                <BlogCategoriesWidget
                    isLandingLinkVisible={pageType !== ALL}
                    activeCategoryId={pageType === CATEGORY && entityId}
                />
            </div>
        );
    }

    renderTagsWidget() {
        return (
            <div block="BlogPage" elem="TagsWidget">
                <BlogTagsWidget />
            </div>
        );
    }

    renderNav() {
        const {
            device: { isMobile },
        } = this.props;

        if (isMobileUtil.tablet() || isMobile) {
            return null;
        }

        return (
            <nav block="BlogPage" elem="Nav">
                {this.renderCategoriesTree()}
                {this.renderTagsWidget()}
            </nav>
        );
    }

    renderBlogBannerCms() {
        return <CmsBlock identifier={"blog_banner"} />;
    }

    renderPosts() {
        const { postsItems = [], isBlogLoading, blogSettings: { list_count_per_page } = {} } =
            this.props;

        if (isBlogLoading) {
            return new Array(list_count_per_page || DEFAULT_PLACEHOLDER_COUNT)
                .fill(null)
                .map(this.renderSinglePost.bind(this, [], true));
        }

        return <>{postsItems.map(this.renderSinglePost.bind(this))}</>;
    }

    renderPostsWrapper() {
        return (
            <div block="BlogPage" elem="PostsWrapper">
                {this.renderPosts()}
            </div>
        );
    }

    renderMobileBanner() {
        const {
            device: { isMobile },
        } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <BlogCmsBanner
                mix={{ block: "BlogPage", elem: "MobileCmsBanner" }}
            />
        );
    }

    renderMainContent() {
        return (
            <section block="BlogPage" elem="MainContent">
                {this.renderMobileBanner()}
                {this.renderMenuButtonRow()}
                {this.renderPostsWrapper()}
                {this.renderPagination()}
            </section>
        );
    }

    renderMenuButtonRow() {
        const { all_post_size } = this.props;

        if (!isMobileUtil.tablet() && !isMobileUtil.any()) {
            return null;
        }

        return <BlogMobileMenuButtonRow postsCount={all_post_size} />;
    }

    render() {
        const { pageType } = this.props;

        return (
            <main block="BlogPage" aria-label="Blog Page">
                <ContentWrapper
                    wrapperMix={{ block: "BlogPage", elem: "Wrapper" }}
                    label="Blog page"
                >
                    <BlogMobileNav isLandingLinkVisible={pageType !== ALL} />
                    <div block="BlogPage" elem="Content">
                        {this.renderMainContent()}
                        {this.renderNav()}
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default BlogPage;
