/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

import { store } from 'Store';

/**
* scrolls to the element by id
* @namespace Util/Window/scrollToElement
*/
export const scrollToElement = (id) => {
    const elem = document.getElementById(id);
    const elemTopRelToWindow = elem.getBoundingClientRect().top;
    const scrollY = window.scrollY;
    const scrollTo = elemTopRelToWindow + scrollY;

    window.scrollTo({
        top: scrollTo,
        behavior: 'smooth'
    });
}

/**
* module throttles callback calls
* @namespace Util/Window/throttle
*/
export const throttle = (callback, delay) => {
    let isThrottling = false;
    let savedArgs = null;
    let savedContext = null;

    function wrapper() {
        if (isThrottling) {
            savedArgs = arguments;
            savedContext = this;
            return;
        }

        isThrottling = true;
        callback.apply(this, arguments);

        setTimeout(() => {
            isThrottling = false;

            if (savedArgs) {
                wrapper.apply(savedContext, savedArgs);
                savedArgs = savedContext = null;
            }
        }, delay)
    }

    return wrapper;
}

export const callOnceInTimeRange = (callback, delay) => {
    let isCalled = false;

    return function wrapper() {
        if (!isCalled) {
            isCalled = true;
            callback.apply(this, arguments);

            setTimeout(() => {
                isCalled = false;
            }, delay);
        }
    }
}

const _getRouterNodes = (acc, node) => {
    if (node.localName === 'main') {
        acc.main = node;
    } else if (/Breadcrumbs/.test(node.className)) {
        acc.breadcrumbs = node;
    }

    return acc;
}

export const setMainMarginTop = () => {
    const state = store.getState();
    const { isDisclaimerHidden, device: { isMobile } } =
        state.ConfigReducer;

    const router = document.getElementById("Router");

    if (!router || !router.childNodes) {
        return;
    }

    const { main, breadcrumbs } = Array.prototype.reduce.call(router.childNodes, _getRouterNodes, {});

    if (!isMobile) {
        if (main) {
            main.style.marginTop = 0;
        }

        if (breadcrumbs) {
            breadcrumbs.style.marginTop = 0;
        }

        if (main && /RecipePage/.test(main.className)) {
            const mTop = isDisclaimerHidden ? '155px' : '190px';
            main.style.marginTop = mTop;
        }

        return;
    }

    if (!isDisclaimerHidden) {
        if (!main) {
            return;
        }

        main.style.marginTop = `154px`;

        if (
            main.className === "ProductPage" ||
            main.className === "BlogPage"
        ) {
            main.style.marginTop = `0px`;
            breadcrumbs
                ? (breadcrumbs.style.marginTop = `154px`)
                : null;
        }

        if (/BlogPage/.test(main.className) && isMobile) {
            main.style.marginTop = `154px`;
        }

        if (/Checkout/.test(main.className)) {
            main.style.marginTop = `106px`;
        }

        if (/RecipeCategoryPage/.test(main.className)) {
            main.style.marginTop = `154px`;
        }
    } else {
        if (!main) {
            return;
        }

        main.style.marginTop = `100px`;
        if (main.className === "ProductPage") {
            main.style.marginTop = `0px`;
            breadcrumbs
                ? (breadcrumbs.style.marginTop = `100px`)
                : null;
        }
        if (/Checkout/.test(main.className)) {
            main.style.marginTop = `50px`;
        }

        if (/RecipeCategoryPage/.test(main.className)) {
            main.style.marginTop = `40px`;
        }
    }
}

export const freezeGlobalScrollModule = (() => {
    let YoffsetWhenScrollDisabled = null;

    function freezeScroll() {
        if (
            document.documentElement.className.includes('scrollDisabled')
        ) {
            return;
        }

        YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        document.documentElement.classList.add('scrollDisabled');
        document.body.style.marginTop = `${-YoffsetWhenScrollDisabled}px`;
    }

    function unfreezeScroll() {
        if (
            !document.documentElement.className.includes('scrollDisabled')
        ) {
            return;
        }

        document.documentElement.classList.remove('scrollDisabled');
        document.body.style.marginTop = 0;
        window.scrollTo(0, YoffsetWhenScrollDisabled);
    }

    return {
        freezeScroll,
        unfreezeScroll
    }
})();

export const getElemHeightWithMargin = (ref) => {
    if (!ref.current) {
        return 0;
    }

    const elem = ref.current;
    const styles = window.getComputedStyle(elem);
    const boundingRect =  elem.getBoundingClientRect();

    return boundingRect.height + getStyleInNumberForm(styles.marginTop) + getStyleInNumberForm(styles.marginBottom);
}

export function getStyleInNumberForm(paddingStr) {
    return Number(paddingStr.slice(0, paddingStr.length - 2));
}
