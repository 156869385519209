import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateActitoPrefilledEmail } from 'Store/Actito/Actito.action';

import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import NewsletterSubscription from './NewsletterSubscription.component';

export const NewsletterSubscriptionDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NewsletterSubscription/NewsletterSubscription.dispatcher'
    );

export const mapStateToProps = (state) => ({
    newsletterFormUrl: state.ConfigReducer.newsletterFormUrl
});

export const mapDispatchToProps = (dispatch) => ({
    subscribeToNewsletter: (email) => NewsletterSubscriptionDispatcher.then(
        ({ default: dispatcher }) => dispatcher.subscribeToNewsletter(dispatch, email)
    ),
    updateActitoPrefilledEmail: (email) => dispatch(updateActitoPrefilledEmail(email))
});

export class NewsletterSubscriptionContainer extends PureComponent {
    static propTypes = {
        subscribeToNewsletter: PropTypes.func.isRequired
    };

    containerFunctions = {
        onFormSubmit: this.onFormSubmit.bind(this),
        onEmailChange: this.onEmailChange.bind(this),
    };

    state = {
        emailValue: '',
        isLoading: false
    };

    onEmailChange(emailValue) {
        this.setState({ emailValue });
    }

    onFormSubmit(fields) {
        const { updateActitoPrefilledEmail, newsletterFormUrl } = this.props;
        const { email } = fields;

        updateActitoPrefilledEmail(email);
        this.onEmailChange('');
        history.push({ pathname: newsletterFormUrl });
    }

    render() {
        return (
            <NewsletterSubscription
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterSubscriptionContainer);
