/* eslint-disable import/first */
/* eslint-disable simple-import-sort/sort */
import {
    CART,
    CATEGORY,
    CHECKOUT,
    CMS_PAGE,
    CUSTOMER_ACCOUNT,
    HOME_PAGE,
    MENU,
    PDP,
    SEARCH,
    NO_MATCH
} from 'Component/Header/Header.config';

export const URL_REWRITE = 'url-rewrite';
export const PASSWORD_CHANGE = 'password-change';
export const CONFIRM_ACCOUNT = 'confirm_account';

export const TYPE_BLOG_LANDING = "AM_BLOG";
export const TYPE_BLOG_TAG = "AM_BLOG_TAG";
export const TYPE_BLOG_CATEGORY = "AM_BLOG_CATEGORY";
export const TYPE_BLOG_POST = "AM_BLOG_POST";
export const RECIPE = "RECIPE";
export const RECIPE_CATEGORY = "RECIPE_CATEGORY";

import withGTM from '../component/GoogleTagManager/withGTM.hoc';

const wrapperFunction = (route) => (args, callback) => withGTM(callback(...args), route);

export default {
    'Route/HomePage/Container': { 'member-function': { render: wrapperFunction(HOME_PAGE) } },
    'Route/CategoryPage/Container': { 'member-function': { render: wrapperFunction(CATEGORY) } },
    'Route/ProductPage/Container': { 'member-function': { render: wrapperFunction(PDP) } },
    'Route/SearchPage/Container': { 'member-function': { render: wrapperFunction(SEARCH) } },
    'Route/CmsPage/Container': { 'member-function': { render: wrapperFunction(CMS_PAGE) } },
    'Route/CartPage/Container': { 'member-function': { render: wrapperFunction(CART) } },
    'Route/Checkout/Container': { 'member-function': { render: wrapperFunction(CHECKOUT) } },
    'Route/PasswordChangePage/Container': { 'member-function': { render: wrapperFunction(PASSWORD_CHANGE) } },
    'Route/ConfirmAccountPage/Container': { 'member-function': { render: wrapperFunction(CONFIRM_ACCOUNT) } },
    'Route/MyAccount/Container': { 'member-function': { render: wrapperFunction(CUSTOMER_ACCOUNT) } },
    'Route/MenuPage/Container': { 'member-function': { render: wrapperFunction(MENU) } },
    'Route/UrlRewrites/Container': { 'member-function': { render: wrapperFunction(URL_REWRITE) } },
    'Route/BlogPage/Container': { 'member-function': { render: wrapperFunction(TYPE_BLOG_LANDING) } },
    'Route/BlogPage/Container': { 'member-function': { render: wrapperFunction(TYPE_BLOG_TAG) } },
    'Route/BlogPage/Container': { 'member-function': { render: wrapperFunction(TYPE_BLOG_CATEGORY) } },
    'Route/BlogPostPage/Container': { 'member-function': { render: wrapperFunction(TYPE_BLOG_POST) } },
    'Route/RecipePage/Container': { 'member-function': { render: wrapperFunction(RECIPE) } },
    'Route/RecipeCategoryPage/Container': { 'member-function': { render: wrapperFunction(RECIPE_CATEGORY) } },
    'Route/NoMatch/Container': { 'member-function': { render: wrapperFunction(NO_MATCH) } },
};
