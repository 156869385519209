import { PureComponent } from "react";

import ClickOutside from "Component/ClickOutside";

import { ReactComponent as ArrowDown } from "./icons/vector.svg";

import "./SearchTypeDropdown.style";

export class SearchTypeDropdown extends PureComponent {
    renderTypesButton() {
        const {
            onDropdownOpen,
            isOpened,
            activeType,
            searchTypes,
        } = this.props;

        const type = searchTypes.filter(
            ({ type: searchType }) => searchType === activeType
        )[0].title;

        return (
            <button
                id="SearchTypeDropdownButton"
                block="SearchTypeDropdown"
                elem="Button"
                mods={{ isOpened }}
                type="button"
                onClick={onDropdownOpen}
            >
                <span>{type}</span>
                <span>
                    <ArrowDown />
                </span>
            </button>
        );
    }

    renderSearchType({ title, brand, type }) {
        const { onSearchTypeSelect } = this.props;

        return (
            <button
                block="SearchTypeDropdown"
                elem="TypeOptionBtn"
                onClick={() => onSearchTypeSelect(type)}
            >
                <span block="SearchTypeDropdown" elem="TypeTitle">
                    {title}
                </span>
            </button>
        );
    }

    renderOptionsWindow() {
        const { searchTypes, isOpened } = this.props;

        return (
            <div
                block="SearchTypeDropdown"
                elem="OptionsWindow"
                mods={{ isOpened }}
            >
                {searchTypes.map(this.renderSearchType.bind(this))}
            </div>
        );
    }

    render() {
        const { closeDrowpdown } = this.props;

        return (
            <ClickOutside onClick={closeDrowpdown}>
                {this.renderTypesButton()}
                {this.renderOptionsWindow()}
            </ClickOutside>
        );
    }
}

export default SearchTypeDropdown;
