/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CheckoutDeliveryOption from 'Component/CheckoutDeliveryOption';

import {
    CheckoutDeliveryOptions as SourceCheckoutDeliveryOptions
} from 'SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.component';

import './CheckoutDeliveryOptions.extended.style';

/** @namespace Component/CheckoutDeliveryOptions/Component */
export class CheckoutDeliveryOptions extends SourceCheckoutDeliveryOptions {
    renderHeading() {
        const {
            isPickupOverlay
        } = this.props;

        if (isPickupOverlay) {
            return (
                <h3 block="Checkout" elem="Heading">
                    { __('Shipping and taxes estimate') }
                </h3>
            );
        }

        return (
            <h2 block="Checkout" elem="Heading">
                { __('Delivery method') }
            </h2>
        );
    }

    renderDeliveryOption = (option) => {
        const {
            selectedShippingMethodCode,
            selectShippingMethod,
            isPickupOverlay
        } = this.props;

        const { method_code } = option;
        const isSelected = selectedShippingMethodCode === method_code;

        return (
            <CheckoutDeliveryOption
              key={ method_code }
              isSelected={ isSelected }
              option={ option }
              onClick={ selectShippingMethod }
              isPickupOverlay={ isPickupOverlay }
            />
        );
    };

    renderShippingMethods() {
        const { shippingMethods, isPickupOverlay } = this.props;
        if (!shippingMethods.length && !isPickupOverlay) {
            return this.renderNoDeliveryOptions();
        }

        return shippingMethods.map(this.renderDeliveryOption);
    }

    render() {
        const { mix } = this.props;

        return (
            <div block="CheckoutDeliveryOptions" mix={ mix }>
                { this.renderHeading() }
                <ul block="CheckoutPayments" elem="Methods">
                    { this.renderShippingMethods() }
                </ul>
                { this.renderSelectedShippingMethod() }
            </div>
        );
    }
}

export default CheckoutDeliveryOptions;
