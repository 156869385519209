/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { RIGHT } from 'Component/InfiniteSlider/InfiniteSlider.config';
import { getStyleInNumberForm } from 'Util/Ecom';
/**
 * calls addFrontEndIdsToSlides, if slides array exists.
 * @namespace Util/Slider/getSlidesInRightForm
 */
export const getSlidesInRightForm = (slides) => {
    if (!slides || !Array.isArray(slides)) {
        return slides;
    }

    const slidesWithIds = addFrontEndIdsToSlides(slides);

    return slidesWithIds;
}

/**
 * attaches id to each slide.
 * @namespace Util/Slider/addFrontEndIdsToSlides
 */
export const addFrontEndIdsToSlides = (slides) => {
    return [ ...slides ].map((slide, frontEndSlideId) => {
        return {
            ...slide,
            frontEndSlideId
        };
    });
}

/**
 * returns new translate position, based on last DOM slider changes.
 * @namespace Util/Slider/getNewTranslateAfterDOMChange
 */
export const getNewTranslateAfterDOMChange = (
    childrenKeysMap,
    activeBreadCrumb,
    slideSize
) => childrenKeysMap.reduce(
    (acc, key, i) => {
        if (key === activeBreadCrumb) {
            acc = i;
        }

        return acc;
    },
    0
) * -slideSize;

/**
 * detects the drag side, comparing to the last translate position.
 * @namespace Util/Slider/getIsDragOnRight
 */
export const getIsDragOnRight = (dragStartX) => {
    const prevTranslateX = dragStartX;

    return (translateX) => {
        // rarely drag/mouse/pointer move event is fired before pointer starts moving
        if (
            translateX === null
            || translateX === prevTranslateX
        ) {
            return null;
        }

        return translateX < prevTranslateX;
    };
}

/**
 * For slider infinity, the slides are changing their position in DOM.
 * To detect, which slide is currently active, array with slides keys is stored in slider state.
 * This array updates along with slides repositioning, so, we always know the slides position.
 *
 * @namespace Util/Slider/getOrderedChildrenIds
 */
export const getOrderedChildrenIds = (children, side) => {
    const childrenToChange = [ ...children ];

    if (side === RIGHT) {
        const lastChild = childrenToChange.pop();
        childrenToChange.unshift(lastChild);
    } else {
        const firstChild = childrenToChange.shift();
        childrenToChange.push(firstChild);
    }

    return childrenToChange;
}

/**
 * returns the position of the current slide in childrenKeysMap array
 * @namespace Util/Slider/getSlidePositionIndexConditionally
 */
export const getSlidePositionIndexConditionally = (childrenKeysMap, keyEq) => {
    return childrenKeysMap.reduce((acc, key, i) => {
        if (key == keyEq) {
            acc = i;
        }

        return acc;
    }, 0);
}

/**
 * returns the position of the current slide in childrenKeysMap array
 * @namespace Util/Slider/getIsLastSlideCurrently
 */
export const getIsLastSlideCurrently = (children, childrenKeysMap) => {
    const curentSlidePositionIndex = getSlidePositionIndexConditionally(childrenKeysMap, children.length - 1);
    return curentSlidePositionIndex === children.length - 1;
}

/**
 * moves the last child to the 1 index
 * @namespace Util/Slider/prependLastArrayChild
 */
export const prependLastArrayChild = (arr) => [ arr[arr.length - 1], ...arr ].slice(0, arr.length);

/**
 * returns first ref child
 * @namespace Util/Slider/getFirstRefChild
 */
export const getFirstRefChild = (ref) => ref.current && ref.current.children[0];

/**
 * returns last ref child
 * @namespace Util/Slider/getLastRefChild
 */
export const getLastRefChild = (ref, children) => ref.current && ref.current.children[children.length - 1];

/**
 * returns full slider width
 * @namespace Util/Slider/getFullSliderWidth
 */
export const getFullSliderWidth = (ref) => {
    if (!ref?.current || !ref.current.children[0]) {
        return 0;
    }

    return []
        .reduce
        .call(ref.current.children,
            (acc, child) => {
                const styles = window.getComputedStyle(child),
                    mRight = getStyleInNumberForm(styles['margin-right']),
                    width = getStyleInNumberForm(styles['width']);

                acc += mRight + width;
                return acc;
            },
        0);
}
