/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import Image from 'Component/Image';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import TotalsFees from 'Component/TotalsFees';
import { formatPrice } from 'Util/Price';

import './MyAccountOrderPopupDetails.extended.style'

export class MyAccountOrderPopupDetails extends PureComponent {
    renderShippingAddressTable() {
        const { order: { shipping_info: { shipping_address, shipping_method } } } = this.props;
        const title = shipping_method.indexOf('pickup') === -1
            ? __('Shipping address')
            : __('Pick up at');

        return (
            <MyAccountAddressTable
                title={ title }
                address={ shipping_address }
                showAdditionalFields
                mix={ { block: 'MyAccountOrderPopup', elem: 'Address' } }
            />
        );
    }

    renderPickupDetails() {
        const { order: { shipping_info: { shipping_method, delivery_slot } } } = this.props;
        const isPickup = shipping_method.indexOf('pickup') !== -1;

        if (!isPickup || !delivery_slot) {
            return null;
        }

        const {
            day,
            slots
        } = delivery_slot

        const pickupDetails = {
            day,
            time: (Array.isArray(slots) && slots.length > 0) ? slots[0].time : __("Not set")
        };

        return (
            <MyAccountAddressTable
                title={ __('Pick up details') }
                pickupDetails={ pickupDetails }
                isPickupDetails
                mix={ { block: 'MyAccountOrderPopup', elem: 'Address' } }
            />
        );
    }

    renderBillingAddressTable() {
        const { orderBillingAddress } = this.props;

        if (Object.keys(orderBillingAddress).length === 0) {
            return null;
        }

        return (
            <MyAccountAddressTable
                title={ __('Billing address') }
                address={ orderBillingAddress }
                showAdditionalFields
                mix={ { block: 'MyAccountOrderPopup', elem: 'Address' } }
            />
        );
    }

    renderShipping() {
        const { order: { shipping_info }, storeCode } = this.props;

        const {
            shipping_description,
            shipping_amount,
            shipping_address
        } = shipping_info || {};

        if (!shipping_address) {
            return null;
        }

        return (
            <div block="MyAccountOrderPopup" elem="ShippingWrapper">
                <h4>{ __('Shipping & Handling Information') }</h4>
                <dl>
                    <dt>{ __('Description: ') }</dt>
                    <dd>
                        { shipping_description }
                    </dd>
                    <dt>{ __('Price: ') }</dt>
                    <dd>
                        { formatPrice(shipping_amount, storeCode) }
                    </dd>
                </dl>
                <div
                  block="MyAccountOrderPopup"
                  elem="AddressesWrapper"
                >
                    { this.renderShippingAddressTable() }
                    { this.renderBillingAddressTable() }
                    { this.renderPickupDetails() }
                </div>
            </div>
        );
    }

    renderTaxes() {
        const {
            storeCode,
            areTaxesRendered,
            onTaxesClick,
            order: {
                base_order_info: {
                    taxes,
                    total_tax_amount: { value }
                }
            }
        } = this.props;

        const areTaxesEmpty = !Array.isArray(taxes) || !taxes.length;

        return (
            <>
                <dt
                  block="MyAccountOrderPopup"
                  elem="Tax"
                  mods={ { areTaxesRendered, areTaxesEmpty } }
                  onClick={ onTaxesClick }
                >
                    <span>
                        { __('Tax:') }
                    </span>
                </dt>
                <dd
                  block="MyAccountOrderPopup"
                  elem="TaxValue"
                  mods={ { areTaxesEmpty } }
                  onClick={ onTaxesClick }
                >
                    { formatPrice(value, storeCode) }
                </dd>
                {
                    !areTaxesEmpty
                        && areTaxesRendered
                        && this.renderTaxesItems(taxes)
                }
            </>
        );
    }

    renderAppliedFees() {
        const {
            order: {
                base_order_info: {
                    fee,
                    fee_list
                }
            }
        } = this.props;

        return <TotalsFees
            isNotOpenable
            isMyAccountFees
            renderPriceLine={ this.renderPriceLine.bind(this) }
            fee={ fee }
            feeList={ fee_list }
            isOrderDetails
            mix={ { block: 'MyAccountOrderPopup', elem: 'Fees' } }
        />;
    }

    renderTaxesItems = (taxes) => taxes.map(this.renderTaxItem.bind(this));

    renderTaxItem({ label, amount: { value } }) {
        const { storeCode } = this.props;

        return (
            <>
                <dt block="MyAccountOrderPopup" elem="TaxLabel">{ `${ label }:` }</dt>
                <dd>
                    { formatPrice(value, storeCode) }
                </dd>
            </>
        );
    }

    renderPriceLine(value) {
        const { storeCode } = this.props;

        return (
            <dd>
                { formatPrice(value, storeCode) }
            </dd>
        );
    }

    renderAppliedCoupon() {
        const {
            storeCode,
            order: {
                base_order_info: {
                    used_coupon_code
                }
            }
        } = this.props;

        if (!used_coupon_code) {
            return null;
        }

        const { code, amount: { value } } = used_coupon_code;

        return (
            <>
                <dt>{ __('Discount/Coupon %s:', code) }</dt>
                <dd block="MyAccountOrderPopup" elem="Coupon">
                    { formatPrice(value, storeCode) }
                </dd>
                </>
        );
    }

    renderAppliedAvrilDollars() {
        const {
            storeCode,
            order: {
                base_order_info: {
                    used_avril_dollars: { value }
                }
            }
        } = this.props;

        if (value === 0) {
            return null;
        }

        return (
            <>
                <dt>{ __('Avril dollars:') }</dt>
                <dd block="MyAccountOrderPopup" elem="AvrilDollars">
                    { formatPrice(value, storeCode) }
                </dd>
                </>
        );
    }

    renderItems() {
        const { order: { order_products = [] }, storeCode } = this.props;

        return order_products.map((product, i) => {
            const {
                name,
                thumbnail,
                id,
                qty,
                price_range:{
                    minimum_price:{
                        final_price: { value }
                    }
                }
            } = product;

            const numberValue = typeof value === 'string' ? Number(value.replace(/,/g, '.')) : value;
            const { url } = thumbnail || {};

            return (
                <tr
                  key={ id || i.toString() }
                  block="MyAccountOrderPopup"
                  elem="Row"
                >
                    <td>
                        { url && (
                            <Image
                              src={ url }
                              alt={ name }
                              mix={ { block: 'MyAccountOrderPopup', elem: 'Thumbnail' } }
                            />
                        ) }
                    </td>
                    <td>{ name }</td>
                    <td>{ qty }</td>
                    <td block="MyAccountOrderPopup" elem="Price">
                        { formatPrice(numberValue * qty, storeCode) }
                    </td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { order: { base_order_info, shipping_info }, storeCode } = this.props;
        const {
            grand_total,
            sub_total
        } = base_order_info || {};
        const { shipping_amount, shipping_method = '' } = shipping_info || {};

        const isPickup = shipping_method.indexOf('pickup') !== -1;
        const shippingLabel = !isPickup ? __("Shipping amount: ") : __("Handling fees: ");

        return (
            <div block="MyAccountOrderPopup" elem="OrderWrapper">
                <h4>{ __('Order Total') }</h4>
                <dl>
                    <dt>{ __('Subtotal: ') }</dt>
                    <dd>
                        { formatPrice(sub_total, storeCode) }
                    </dd>
                    <dt>{ shippingLabel }</dt>
                    <dd>
                        { formatPrice(shipping_amount, storeCode) }
                    </dd>
                    { this.renderAppliedCoupon() }
                    { this.renderTaxes() }
                    { this.renderAppliedFees() }
                    { this.renderAppliedAvrilDollars() }
                    <dt block="MyAccountOrderPopup" elem="GrandTotal">{ __('Grand total: ') }</dt>
                    <dd>
                        { formatPrice(grand_total, storeCode) }
                    </dd>
                </dl>
            </div>
        );
    }

    renderBaseInfo() {
        const { order: { base_order_info } } = this.props;
        const { status_label, created_at } = base_order_info || {};

        return (
            <div>
                <h4>{ __('Order details') }</h4>
                <dl>
                    <dt>{ __('Created at: ') }</dt>
                    <dd>{ created_at }</dd>
                    <dt>{ __('Status: ') }</dt>
                    <dd>{ status_label }</dd>
                </dl>
            </div>
        );
    }

    renderPayment() {
        const { order: { payment_info } } = this.props;
        const { additional_information: { method_title } = {} } = payment_info || {};

        return (
            <div>
                <h4>{ __('Payment Information') }</h4>
                <dl>
                    <dt>{ __('Method: ') }</dt>
                    <dd>{ method_title }</dd>
                </dl>
            </div>
        );
    }

    renderProducts() {
        return (
            <div block="MyAccountOrderPopup" elem="ProductsWrapper">
                <h4>{ __('Items Ordered') }</h4>
                <table
                  block="MyAccountOrderPopup"
                  elem="Products"
                >
                    <thead>
                        { this.renderItemsHeading() }
                    </thead>
                    <tbody>
                        { this.renderItems() }
                    </tbody>
                </table>
            </div>
        );
    }

    renderItemsHeading() {
        return (
            <tr>
                <th>{ __('Image') }</th>
                <th>{ __('Name') }</th>
                <th>{ __('Quantity') }</th>
                <th>{ __('Total') }</th>
            </tr>
        );
    }

    renderContent() {
        const { order: { order_products } } = this.props;

        if (!order_products) {
            return null;
        }

        return (
            <>
                { this.renderBaseInfo() }
                { this.renderPayment() }
                { this.renderShipping() }
                { this.renderProducts() }
                { this.renderTotals() }
            </>
        );
    }

    render() {
        const { mix, isLoading } = this.props;

        return (
            <div block="MyAccountOrderPopup" elem="Content" mix={ mix }>
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </div>
        );
    }
};

export default MyAccountOrderPopupDetails;
