import {
    NavigationTabs as SourceNavigationTabs
} from 'SourceComponent/NavigationTabs/NavigationTabs.component';

export class NavigationTabs extends SourceNavigationTabs {

    render() {
        const { navigationState: { isHidden }, device } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return null;

    }
}

export default NavigationTabs;
