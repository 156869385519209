import { logToLoggly } from 'Util/Loggly';

export function componentLoader(lazyComponent, importName = '', attemptsLeft = 3) {
    return new Promise((resolve, reject) => {
      lazyComponent()
        .then(resolve)
        .catch((error) => {
          // repeating function call after 500 ms
            setTimeout(() => {
                // reject promise if no more attempts left
                if (attemptsLeft === 1) {
                    // push error to Loggly
                    logToLoggly({
                        message: 'Error from componentLoader',
                        importName,
                        error
                    });

                    reject(error);
                    return;
                }

                componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
            }, 500);
        });
    });
}
