import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CmsPage from 'Route/CmsPage';

import './RecipeLandingPage.style';

export const mapStateToProps = (state) => ({
    cmsPageId: state.ConfigReducer.mageside_recipe_seo_route_cms_id
});

export class RecipeLandingPage extends PureComponent {
    render() {
        const { cmsPageId } = this.props;
        return <CmsPage pageIds={ cmsPageId} />;
    }
}

export default connect(mapStateToProps, null)(RecipeLandingPage);
