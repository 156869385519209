import { getCallStack } from "Util/Error";

const _getUserDeviceData = () => {
    const userDeviceData = {};

    if ('userAgent' in navigator) {
        userDeviceData.userAgent = navigator.userAgent;
    }

    if ('userAgentData' in navigator) {
        userDeviceData.userAgentData = navigator.userAgentData;
    }

    if (location) {
        userDeviceData.location = location.pathname;
    }

    return userDeviceData;
}

export const logToLoggly = (data) => {
    if (!!_LTracker) {
        const objToPush = {
            ...data,
            userDeviceData: _getUserDeviceData(),
            callstack: getCallStack()
        };

        _LTracker.push({
            ...objToPush,
            dataJSON: JSON.stringify(objToPush)
        });
    }
}
