import { PureComponent } from "react";

import Link from "Component/Link";
import Image from "Component/Image";
import TextPlaceholder from "Component/TextPlaceholder";
import { summarizeCookAndPrep } from "Util/Recipe";

import "Component/ProductRelatedPostCard/ProductRelatedPostCard.style";
import "./RecipeCard.style";

export class RecipeCard extends PureComponent {
	renderAdditionalDataItem(data, title) {
		if (!data || !title) {
			return null;
		}

		return (
			<div block="RecipeCard" elem="AdditionalDataItem">
				<div block="RecipeCard" elem="AdditionalDataInnerWrapper">
					<span>{title}</span>
					<span>{data}</span>
				</div>
			</div>
		);
	}

	renderTimeData() {
		const { cook_time, prep_time, servings_number, unit } = this.props;

		const { data: servingsData } = servings_number[0] || {};
		const timeLine = summarizeCookAndPrep(cook_time, prep_time);

		return (
			<>
				{this.renderAdditionalDataItem(timeLine, __("Duration"))}
				{this.renderAdditionalDataItem(servingsData, unit)}
			</>
		);
	}

	renderShowMoreText() {
		return (
			<span mix={{ block: "ProductRelatedPostCard", elem: "PostLink" }}>
				{__("View the recipe")}
			</span>
		);
	}

	renderAdditionalData() {
		const { isShowMoreType } = this.props;


		return (
			<div block="RecipeCard" elem="AdditionalData">
				{isShowMoreType ? this.renderShowMoreText() : this.renderTimeData()}
			</div>
		);
	}

	renderRecipeTitle() {
		const { title } = this.props;

		return (
			<h4 block="RecipeCard" elem="Title">
				{title}
			</h4>
		);
	}

	renderShortContent() {
		const { short_description, isDescriptionHidden } = this.props;

        if (isDescriptionHidden) {
            return null;
        }

		return (
			<p block="RecipeCard" elem="ShortDescription">
				{short_description}
			</p>
		);
	}

    renderCategory({ name }) {
        return (
            <span block="RecipeCard" elem="Category">
                {name}
            </span>
        );
    }

    renderCategories() {
        const { categories: { items = [] } = {} } = this.props;

        return (
            <div block="RecipeCard" elem="CategoriesWrapper">
                {items.map(this.renderCategory.bind(this))}
            </div>
        );
    }

	renderInfoBlock() {
		const { isPlaceholder } = this.props;

		if (isPlaceholder) {
			return (
				<>
					<TextPlaceholder length="medium" />
					<TextPlaceholder length="short" />
				</>
			);
		}

		return (
			<>
                <div>
                    {this.renderCategories()}
                    {this.renderRecipeTitle()}
                    {this.renderShortContent()}
                </div>
				{this.renderAdditionalData()}
			</>
		);
	}

	renderContent() {
		const { isPlaceholder, thumbnail: { alt, url: imgUrl } = {} } =
			this.props;

		return (
			<>
				<Image
					src={isPlaceholder ? "" : imgUrl}
					alt={alt}
					isPlaceholder={isPlaceholder}
					mix={{ block: "RecipeCard", elem: "Image" }}
				/>{" "}
				<div block="RecipeCard" elem="Info" mods={{ isPlaceholder }}>
					{this.renderInfoBlock()}
				</div>
			</>
		);
	}

	render() {
		const { url, mix, isPlaceholder } = this.props;

		const wrapperMix = { block: "RecipeCard", mix };

		if (isPlaceholder) {
			return <div mix={wrapperMix}>{this.renderContent()}</div>;
		}

		return (
			<Link to={url} mix={{ block: "RecipeCard", mix }}>
				{this.renderContent()}
			</Link>
		);
	}
}

export default RecipeCard;
