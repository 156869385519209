import { connect } from "react-redux";

import BlogTagsWidget from "./BlogTagsWidget.component";

export const BlogDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/Blog/Blog.dispatcher"
);

export const mapStateToProps = (state) => ({
    blogTags: state.BlogReducer.blogTags,
});

export const mapDispatchToProps = (dispatch) => ({
    getPostsByTag: (page, tagId) =>
        BlogDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getPostsByType(dispatch, page, tagId, "TAG")
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogTagsWidget);
