import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
    CategoryFilterOverlayContainer as SourceCategoryFilterOverlayContainer,
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
} from "Component/CategoryFilterOverlay/CategoryFilterOverlay.container";
import { toggleOverlayByKey } from "Store/Overlay/Overlay.action";
import BlogMobileNav from "./BlogMobileNav.component";

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    blogSettings: state.BlogReducer.blogSettings,
});

export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
});

export class BlogMobileNavContainer extends SourceCategoryFilterOverlayContainer {
    componentDidMount() {
        const { showOverlay } = this.props;
        showOverlay("");
    }

    componentDidUpdate(prevProps) {
        const {
            showOverlay,
            history: {
                location: { pathname },
            },
        } = this.props;
        const {
            history: {
                location: { pathname: prevPathname },
            },
        } = prevProps;

        if (pathname !== prevPathname) {
            showOverlay("");
        }
    }

    containerFunctions = {
        onCloseButtonClick: this.onCloseButtonClick.bind(this),
    };

    onCloseButtonClick() {
        const { showOverlay } = this.props;
        showOverlay("");
    }

    render() {
        return <BlogMobileNav {...this.props} {...this.containerFunctions} />;
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BlogMobileNavContainer)
);
