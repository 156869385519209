
import { PureComponent } from 'react';

import './Switch.style';

export class Switch extends PureComponent {
    render() {
        const {
            isActivated,
            mix,
            onClick
        } = this.props;

        return (
            <div block="Switch" elem="Wrapper" mix={ mix }>
                <button
                  block="Switch"
                  elem="Toggle"
                  mods={ { isActivated } }
                  onClick={ onClick }
                >
                    <span
                      block="Switch"
                      elem="ToggleKey"
                      mods={ { isActivated } }
                    />
                </button>
                <p block="Switch" elem="Text">
                    <div block="Switch" elem="TextSlidingWrapper" mods={ { isActivated } }>
                        <span>
                            { __('No') }
                        </span>
                        <span>
                            { __('yes') }
                        </span>
                    </div>
                </p>
            </div>
        );
    }
}

export default Switch;
