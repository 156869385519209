/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';

import { componentLoader } from 'Util/Component';

import {
    CATALOG_PRODUCT_LIST,
    NEW_PRODUCTS,
    SLIDER,
    CARDS_SLIDER,
    ACTITO_FORM,
    CONTACT_FORM,
    PROMOTION
} from './WidgetFactory.config';
import { WidgetFactory as SourceWidgetFactory } from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import HomeSlider from 'Component/InfiniteSliderWidget';
import RenderWhenVisible from 'Component/RenderWhenVisible';

import PromotionContainer from 'Component/Promotion/Promotion.container';

export const ProductListWidget = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/ProductListWidget'), 'Component/ProductListWidget'));
export const NewProducts = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/NewProducts'), 'Component/NewProducts'));
export const CardsSliderWidget = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "cardsSlider" */ 'Component/CardsSliderWidget'), 'Component/CardsSliderWidget'));
export const ActitoWidget = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Component/ActitoWidget'), 'Component/ActitoWidget'));
export const ContactUsFormApp = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Component/ContactUsFormApp'), 'Component/ContactUsFormApp'));

/** @namespace Component/WidgetFactory/Component */
export class WidgetFactory extends SourceWidgetFactory {
    renderMap = {
        [SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback
        },
        [NEW_PRODUCTS]: {
            component: NewProducts
        },
        [CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget
        },
        [CARDS_SLIDER]: {
            component: CardsSliderWidget
        },
        [ACTITO_FORM]: {
            component: ActitoWidget
        },
        [CONTACT_FORM]: {
            component: ContactUsFormApp
        },
        [PROMOTION]: {
            component: PromotionContainer
        }
    };

    renderContent() {
        const { type } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            if ([CATALOG_PRODUCT_LIST, CARDS_SLIDER, ACTITO_FORM, CONTACT_FORM, PROMOTION].includes(type)) {
                return <Widget { ...this.props } />;
            }

            return (
                <RenderWhenVisible fallback={ fallback }>
                    <Widget { ...this.props } />
                </RenderWhenVisible>
            );
        }

        return null;
    }
}


export default WidgetFactory;
