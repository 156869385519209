/* eslint-disable quote-props */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import Event, {
    EVENT_GTM_PRODUCT_ADD_TO_CART,
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART
} from '../../util/Event';

export class QuantityChangePlugin {
    handleChangeQuantity = (args, callback, instance) => {
        const [quantity] = args;

        let item = instance.props?.item;
        let qty = item?.qty;

        if (!item) {
            const { totals, product: { sku } = {} } = instance.props;

            if (!totals || !sku) {
                callback(...args);
                return;
            }

            item = totals.items.filter(({ sku: itemSku }) => itemSku === sku)[0];
            qty = item.qty;
        }

        this.handleChangeState = {
            newQuantity: quantity,
            oldQuantity: qty,
            item
        };

        callback(...args);
    };

    changeItemQty = (args, callback) => {
        const { newQuantity, item, oldQuantity } = this.handleChangeState;

        return callback(...args)
            .then(
                result => {
                    if (oldQuantity < newQuantity) {
                        Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                            product: item,
                            quantity: newQuantity - oldQuantity,
                            isItem: true,
                            isFromCart: true
                        });
                    } else {
                        Event.dispatch(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, {
                            item,
                            quantity: oldQuantity - newQuantity
                        });
                    }

                    return result;
                }
            );
    };
}

const {
    handleChangeQuantity,
    changeItemQty
} = new QuantityChangePlugin();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            handleChangeQuantity
        }
    },
    'Component/ProductCard/Container': {
        'member-function': {
            handleChangeQuantity
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            changeItemQty
        }
    }
};
