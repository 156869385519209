import {
    SliderWidget as SourceSliderWidget,
} from 'SourceComponent/SliderWidget/SliderWidget.component';
import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';
import Html from 'Component/Html';
import Image from 'Component/Image';
import './SliderWidget.extend.style'

export class SliderWidget extends SourceSliderWidget {
    static propTypes = {
        slider: PropTypes.shape({
            title: PropTypes.string,
            slideSpeed: PropTypes.number,
            slides: PropTypes.arrayOf(
                PropTypes.shape({
                    desktop_image: PropTypes.string,
                    mobile_image: PropTypes.string,
                    slide_text: PropTypes.string,
                    slide_link: PropTypes.string,
                    embed_code: PropTypes.string,
                    isPlaceholder: PropTypes.bool
                })
            )
        }),
        device: DeviceType.isRequired
    };


    sliderClickHandler(slide) {
        if (slide.slide_link) {
            window.location.href = slide.slide_link
        }
    }

    renderSlide = (slide, i) => {
        const {
            slide_text,
            slide_link,
            isPlaceholder,
            embed_code,
            title: block
        } = slide;

        return (
            <figure
                block="SliderWidget"
                elem="Figure"
                key={ i }
            >
                <div mix={ slide_link ? { block: 'SliderWidget', elem: 'FigureImage-Link' } : '' }
                    onClick={() => { this.sliderClickHandler(slide)}}
                >
                    <Image
                        mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                        ratio="custom"
                        src={ this.getSlideImage(slide) }
                        isPlaceholder={ isPlaceholder }
                    />
                    <figcaption
                        block="SliderWidget"
                        elem="Figcaption"
                        mix={ { block } }
                    >

                        <Html content={ slide_text || '' } />
                    </figcaption>
                    <figcaption
                        block="SliderWidget"
                        elem="Figcaption-EmbedCode"
                        mix={ { block } }
                    >
                        <Html content={ embed_code || '' } />
                    </figcaption>
                </div>
            </figure>
        );
    };
};

export default SliderWidget;