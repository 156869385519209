import { PureComponent, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { BLOG_TYPE, PRODUCTS_TYPE, RECIPE_TYPE } from 'Component/SearchField/SearchField.config';

import SearchWindow from "./SearchWindow.component";
import CSS from "Util/CSS";
import {
    throttle,
    freezeGlobalScrollModule,
    getElemHeightWithMargin,
} from "Util/Window";

export const BlogDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/Blog/Blog.dispatcher"
);

export const SearchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/Search/Search.dispatcher"
);

const mapStateToProps = (state) => ({
    topCategories: state.SearchReducer.topCategories,
    topProducts: state.SearchReducer.topProducts,
    topCategoryForTopProducts: state.SearchReducer.topCategoryForTopProducts,
    isTopCategoriesLoading: state.SearchReducer.isTopCategoriesLoading,
    isTopProductsLoading: state.SearchReducer.isTopProductsLoading,
    isTopCategoryForTopProductsLoading:
        state.SearchReducer.isTopCategoryForTopProductsLoading,
    xSearchSuggestions: state.SearchReducer.xSearchSuggestions,
    lastBlogPosts: state.SearchReducer.lastBlogPosts,
    blogPostsSearchResult: state.SearchReducer.blogPostsSearchResult,
    lastRecipes: state.SearchReducer.lastRecipes,
    recipesSearchResult: state.SearchReducer.recipesSearchResult,
    isXSearchLoading: state.SearchReducer.isXSearchLoading,
    isSearchProductsOpening: state.SearchReducer.isSearchProductsOpening,
    device: state.ConfigReducer.device,
    mageside_recipe_seo_route: state.ConfigReducer.mageside_recipe_seo_route,
    isConfigLoading: state.ConfigReducer.isLoading,
    recipeCategories: state.RecipeReducer.recipeCategories,
    blogCategories: state.BlogReducer.blogCategories,
    blogTags: state.BlogReducer.blogTags,
    blogSettings: state.BlogReducer.blogSettings,
    isBlogLoading: state.BlogReducer.isBlogLoading,
});

const mapDispatchToProps = (dispatch) => ({
    getTopSearchProducts: () =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getTopSearchProducts(dispatch)
        ),
    getTopSearchCategories: () =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getTopSearchCategories(dispatch)
        ),
    onSearchProductsOpen: () =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.onSearchProductsOpen(dispatch)
        ),
    getLastBlogPosts: () =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getLastBlogPosts(dispatch)
        ),
    getLastRecipes: () =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getLastRecipes(dispatch)
        ),
});

export class SearchWindowContainer extends PureComponent {
    searchSuggestionsRef = createRef();
    productsScrollableRef = createRef();
    productsSectionRef = createRef();
    productsHeaderRef = createRef();

    isCategoriesScrollListening = false;

    state = {
        activeImage: 0,
        isBottomBlurVisible: true,
    };

    componentDidMount() {
        const {
            getTopSearchProducts,
            getTopSearchCategories,
            getLastBlogPosts,
            getLastRecipes
        } = this.props;

        getTopSearchProducts();
        getTopSearchCategories();
        getLastBlogPosts();
        getLastRecipes();

        this.onResize();
        window.addEventListener(
            "resize",
            throttle(this.onResize.bind(this), 100)
        );
    }

    componentDidUpdate(prevProps) {
        const {
            isActive: prevIsActive,
            location: { pathname: prevPathname },
        } = prevProps;
        const {
            isActive,
            location: { pathname },
            onSearchProductsOpen,
            searchInputRef,
            handleSearchValChange,
            setIsSearchWindowOpened,
        } = this.props;

        if (pathname !== prevPathname) {
            setTimeout(() => {
                setIsSearchWindowOpened(false);

                if (searchInputRef.current) {
                    searchInputRef.current.blur();
                    handleSearchValChange({ target: { value: "" } });
                }
            });
        }

        if (isActive && isActive !== prevIsActive) {
            onSearchProductsOpen();
        }

        if (isActive) {
            this.onResize();
            freezeGlobalScrollModule.freezeScroll();
        } else {
            freezeGlobalScrollModule.unfreezeScroll();
        }

        this.setCategoriesScrollListener();
    }

    componentWillUnmount() {
        window.removeEventListener(
            "resize",
            throttle(this.onResize.bind(this), 100)
        );
    }

    containerProps = () => {
        const {
            activeType,
            searchCriteria = "",
            isXSearchLoading,
            blogPostsSearchResult: { items: blogPosts } = {},
            recipesSearchResult: { items: recipes } = {},
            xSearchSuggestions: {
                xsearchProducts,
                xsearchCategories: { items: categories = [] } = {},
                xsearchRelatedTerms: { items: terms = [] } = {},
            },
        } = this.props;

        const isFallbackNeeded =
            !!searchCriteria &&
            Array.isArray(xsearchProducts) &&
            !xsearchProducts.length &&
            !categories.length &&
            !terms.length;

        const cardItems = activeType === BLOG_TYPE
            ? blogPosts
            : activeType === RECIPE_TYPE ? recipes : xsearchProducts;
        const isXSearchResults =
            !isXSearchLoading && (!!searchCriteria && !!cardItems);

        return {
            ...this.props,
            ...this.state,
            isFallbackNeeded,
            isXSearchResults,
            productsHeaderRef: this.productsHeaderRef,
            productsSectionRef: this.productsSectionRef,
            searchSuggestionsRef: this.searchSuggestionsRef,
        };
    };

    setCategoriesScrollListener() {
        const { searchCategoriesSectionRef } = this.props;

        if (
            searchCategoriesSectionRef.current &&
            !this.isCategoriesScrollListening
        ) {
            searchCategoriesSectionRef.current.addEventListener(
                "scroll",
                throttle(
                    this.setIsInnerBlurVisible.bind(
                        this,
                        searchCategoriesSectionRef.current
                    ),
                    150
                )
            );
        }
    }

    setIsInnerBlurVisible(target) {
        const { isBottomBlurVisible: currentIsBottomBlurVisible } = this.state;

        const scrollTop = target.scrollTop;
        const containerHeight = target.offsetHeight;
        const childrenHeightSum = Array.prototype.reduce.call(
            target.children,
            (acc, child) => {
                acc += getElemHeightWithMargin({ current: child });
                return acc;
            },
            0
        );

        const isScrolledToBottom =
            ((scrollTop + containerHeight) / childrenHeightSum).toFixed(2) >=
            0.98;
        const isBottomBlurVisible = isScrolledToBottom ? false : true;

        if (isBottomBlurVisible !== currentIsBottomBlurVisible) {
            this.setState({ isBottomBlurVisible });
        }
    }

    containerFunctions = {
        onActiveImageChange: this.onActiveImageChange.bind(this),
        onCloseBtnClick: this.onCloseBtnClick.bind(this),
    };

    onResize() {
        const {
            device: { isMobile },
            searchWindowRef,
        } = this.props;
        const searchWindow = searchWindowRef.current;

        if (searchWindow && !isMobile) {
            // read and set dimensions 100ms after grow-down animation (200ms for animation)
            setTimeout(() => {
                this.setSearchRectStyle(
                    searchWindow,
                    this.getSearchWindowSize().height,
                    20,
                    "clientHeight",
                    "top",
                    "search-window-height"
                );
                this.setSearchRectStyle(
                    searchWindow,
                    this.getSearchWindowSize().width,
                    30,
                    "clientWidth",
                    "left",
                    "search-window-width"
                );
                this.setScrollableProductsHeight(searchWindow);
            }, 300);
        } else if (isMobile) {
            this.setMobileMaxHeight(searchWindow);
        }
    }

    getSearchWindowSize() {
        const { activeType, searchCriteria } = this.props;

        switch(activeType) {
            case BLOG_TYPE:
            case RECIPE_TYPE:
                return {
                    height: 665,
                    width: 1002
                };
            default:
                const width = !!searchCriteria.trim() ? 948 : 972;

                return {
                    height: 641,
                    width
                };
        }
    }

    setMobileMaxHeight() {
        const { searchWindowRef } = this.props;
        const newMaxMobileHeight =
            document.documentElement.clientHeight -
            document.querySelector("header.Header").getBoundingClientRect()
                .height;
        CSS.setVariable(
            searchWindowRef,
            "search-window-mobile-max-height",
            `${newMaxMobileHeight}px`
        );
    }

    setSearchRectStyle(
        searchWindow,
        initVal,
        offset,
        docPropName,
        rectPropName,
        cssVarName
    ) {
        const { searchWindowRef } = this.props;
        const rect = searchWindow.getBoundingClientRect();
        const newDimension =
            document.documentElement[docPropName] - rect[rectPropName] - offset;
        const newStyle =
            newDimension < initVal ? `${newDimension}px` : `${initVal}px`;

        CSS.setVariable(searchWindowRef, cssVarName, newStyle);
    }

    setScrollableProductsHeight() {
        const { searchProductsScrollableRef, activeType } = this.props;

        try {
            const isXSearchResults = this.containerProps().isXSearchResults;

            const productsSectHeight =
                this.productsSectionRef.current.getBoundingClientRect().height;
            const headerHeight = getElemHeightWithMargin(
                this.productsHeaderRef
            );
            const newScrollableHeight = (isXSearchResults && activeType === PRODUCTS_TYPE)
                ? productsSectHeight
                : productsSectHeight - headerHeight;

            CSS.setVariable(
                searchProductsScrollableRef,
                "products-scrollable-height",
                `${newScrollableHeight}px`
            );
        } catch (e) {

        }
    }

    onCloseBtnClick(e) {
        const { onClearSearchButtonClick, setIsSearchWindowOpened } =
            this.props;

        onClearSearchButtonClick();
        setIsSearchWindowOpened(false, e.target);
    }

    onActiveImageChange(activeImage) {
        this.setState({ activeImage });
    }

    render() {
        return (
            <SearchWindow
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SearchWindowContainer)
);
