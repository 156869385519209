/* eslint-disable fp/no-let */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getStore } from 'Store';

import {
    updateQueryParamWithoutHistory,
    removeQueryParamWithoutHistory,
    getUrlParam,
    getQueryParam,
    updateKeyValuePairs,
    convertKeyValuesToQueryString,
    generateQuery,
    setQueryParams,
    clearQueriesFromUrl,
    objectToUri
} from 'SourceUtil/Url/Url';

export {
    updateQueryParamWithoutHistory,
    removeQueryParamWithoutHistory,
    getUrlParam,
    getQueryParam,
    updateKeyValuePairs,
    convertKeyValuesToQueryString,
    generateQuery,
    setQueryParams,
    clearQueriesFromUrl,
    objectToUri,
};

/**
 * Convert url params to object with key value pairs
 * @param {String} queryString url query string
 * @return {Object} Key-Value pairs
 * @namespace Util/Url/convertQueryStringToKeyValuePairs
 */
export const convertQueryStringToKeyValuePairs = (queryString) => {
    const keyValuePairs = {};
    const params = queryString.substring(1).split('&');

    try {
        params.forEach((param) => {
            const pair = param.split('=');
            const [keyPair, valuePair = []] = pair;

            if (keyPair.length > 0 && valuePair.length > 0) {
                keyValuePairs[keyPair] = decodeURIComponent(valuePair);
            }
        });
    } catch(e) {
        return {};
    }

    return keyValuePairs;
};

export const decodeURIComponentSafe = (str) => {
    if (!str) {
        return str;
    }

    return decodeURIComponent(str.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
}

/**
 * Append store code to URL
 * @param {String} pathname the URL to append store code to
 * @namespace Util/Url/appendWithStoreCode
 */
 export const appendWithStoreCode = (pathname) => {
    const { ConfigReducer: { base_link_url = window.location.origin } = {} } = getStore().getState() || {};
    const { pathname: storePrefix } = new URL(base_link_url);
    const storePrefixFromUrl = window.location.pathname.split('/')[1];

    // ignore empty URLs
    if (!pathname) {
        return pathname;
    }

    /** checks the store code in URL
    as in store config it appears after the first render with query */
    if (
        storePrefixFromUrl
        && storeList.indexOf(storePrefixFromUrl) !== -1
        && pathname.indexOf(`/${ storePrefixFromUrl }/`) === -1
    ) {
        return `/${ storePrefixFromUrl }`.concat(pathname);
    }

    // match URLs which have the store code in pathname
    if (pathname.match(`/(${window.storeList.join('|')})`)) {
        return pathname;
    }

    if (!pathname.startsWith('/')) {
        // eslint-disable-next-line no-param-reassign
        pathname = `/${ pathname }`;
    }

    // trim the last slash from URL, and append it to pathname
    return storePrefix.slice(0, -1).concat(pathname);
};

export const urlHistory = (function getUrlHistory() {
    const urlHistory = [];

    function pushUrl(url) {
        if (
            typeof url !== "string" ||
            urlHistory[urlHistory.length - 1] === url
        ) {
            return;
        }

        urlHistory.push(url);
    }

    function getUrlHistory() {
        return urlHistory;
    }

    return {
        pushUrl,
        getUrlHistory,
    };
})();

export const getCurrentPage = (search, pageUrlKey) => search
    .substr(1)
    .split("&")
    .reduce((acc, part) => {
        const [key, value] = part.split("=");

        if (key === pageUrlKey) {
            acc = Number(value);
        }

        return acc;
    }, 1);

export const getSelectedFiltersFromUrl = (location) => {
    const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');

    return selectedFiltersString.reduce((acc, filter) => {
        if (!filter) {
            return acc;
        }
        const [key, value] = filter.split(':');

        if (!value) {
            return acc;
        }

        return { ...acc, [key]: value.split(',') };
    }, {});
}
