import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Html from 'Component/Html';
import './SliderHtml.style';

const initialState = {
    current: 0,
    dataSlider: []
}


export class SliderHtml extends PureComponent {
    static propTypes = {
        dataSlider: PropTypes.any,
        // current: PropTypes.number
    };
    
    state = {
        ...initialState
    }
    
    componentDidMount = async() => {
        this.arrayDataSlider();
        this.timerId = setInterval(() => {
            this.setState(state => ({
                ...state,
                current: (state.current + 1) % this.state.dataSlider.length,
            }));
        }, 3000);
    }

    arrayDataSlider() {
        const { cmsContent } = this.props;
        var arr = [];
        var dom = document.createElement('div');
        dom.innerHTML = cmsContent;
        for (var key in dom.childNodes) {
            if (dom.childNodes[key].nodeType !== 3 && dom.childNodes[key].parentElement === dom) {
                arr.push(dom.childNodes[key]);
            }
        }
        arr = arr.map(function(el){
            var wrap = document.createElement('div');
            wrap.appendChild(el);
            return wrap.innerHTML;
        });
        this.setState({ dataSlider: arr })
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    render() {
        return (
            <div block="container-slider">
                {this.state.dataSlider.map((obj, index) => {
                    return (
                        <div
                            key={index}
                            block="slide"
                            mix={ this.state.current === index ? { block: "active-anim" } : { block: "" } }
                        >
                            <Html content={ obj } />
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default SliderHtml;