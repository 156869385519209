import { Field } from 'SourceUtil/Query';

export class ContactFormQuery {
    submitContactForm(data) {
        return new Field('contactForm')
            .addArgument('contact', 'ContactForm!', data)
            .addField('message')
    }

}

export default new ContactFormQuery();
