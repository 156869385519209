import { PureComponent } from 'react';
import TinySlider from "tiny-slider-react";
import './TinySlider.style';
import './CmsContentSlider.style';


export class CmsContentSlider extends PureComponent {
    getSliderSettings() {
        const { settings } = this.props;
        
        if (settings === undefined) {
            return []
        }
        
        let jsonStr = settings.replace(/(\w+:)|(\w+ :)/g, function(s) {
            return '"' + s.substring(0, s.length-1) + '":';
        });

        return  JSON.parse(jsonStr);
    }
    
    render() {
        const {
            children,
            className
        } = this.props;
        
        return (
            <div block={className}>
                <TinySlider settings={this.getSliderSettings()}>
                    {children}
                </TinySlider>
            </div>
        );
    }
}

export default CmsContentSlider;
