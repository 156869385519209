import { PureComponent, createRef } from "react";
import { connect } from "react-redux";

import { BLOG_MOBILE_NAV_OVERLAY } from "Component/BlogMobileNav/BlogMobileNav.config";
import { toggleOverlayByKey } from "Store/Overlay/Overlay.action";
import { throttle } from "Util/Window";
import history from "Util/History";
import { appendWithStoreCode } from "Util/Url";
import { urlHistory } from "Util/Url";
import BlogMobileMenuButtonRow from "./BlogMobileMenuButtonRow.component";

import "./BlogMobileMenuButtonRow.style";

export const mapStateToProps = (state) => ({
    blogSettings: state.BlogReducer.blogSettings,
    isBlogLoading: state.BlogReducer.isBlogLoading,
    blogPosts: state.BlogReducer.blogPosts,
});

export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
});

export class BlogMobileMenuButtonRowContainer extends PureComponent {
    buttonWrapperRef = createRef();

    componentDidMount() {
        window.addEventListener(
            "scroll",
            throttle(this.onScroll.bind(this), 30)
        );
    }

    containerFunctions = {
        onClick: this.onClick.bind(this),
        onReturnClick: this.onReturnClick.bind(this),
    };

    containerProps = {
        buttonWrapperRef: this.buttonWrapperRef,
    };

    onReturnClick() {
        const {
            blogSettings: { search_engine_route },
        } = this.props;

        const lastUrls = urlHistory.getUrlHistory();
        const pathname =
            lastUrls[lastUrls.length - 2] ||
            appendWithStoreCode(`/${search_engine_route}`);

        if (!pathname) {
            return;
        }

        history.push({ pathname });
    }

    onScroll() {
        if (!this.buttonWrapperRef.current) {
            return null;
        }

        const btn = this.buttonWrapperRef.current.children[0],
            breakPoint = window.innerWidth <= 811 ? 110 : 175,
            fixedClass = "BlogMobileMenuButtonRow-OpenOverlayBtn_isFixed",
            topDistance =
                this.buttonWrapperRef.current.getBoundingClientRect().y;

        if (topDistance < breakPoint) {
            btn.classList.add(fixedClass);
        } else {
            btn.classList.remove(fixedClass);
        }
    }

    onClick() {
        const { showOverlay } = this.props;
        showOverlay(BLOG_MOBILE_NAV_OVERLAY);
    }

    render() {
        return (
            <BlogMobileMenuButtonRow
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlogMobileMenuButtonRowContainer);
