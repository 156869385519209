import PropTypes from 'prop-types';
import { createRef } from 'react';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import AddToCart from 'Component/AddToCart';
import ProductAvailability from 'Component/ProductAvailability';
import { PRICE_TYPE_KG } from 'Component/ProductPrice/ProductPrice.config';
import Field from 'SourceComponent/Field';
import Image from 'SourceComponent/Image';
import Link from 'SourceComponent/Link';
import Loader from 'SourceComponent/Loader';
import ProductPrice from 'SourceComponent/ProductPrice';
import ProductReviewRating from 'SourceComponent/ProductReviewRating';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import TierPrices from 'SourceComponent/TierPrices';
import { ProductType } from 'SourceType/ProductList';
import { CONFIGURABLE } from 'SourceUtil/Product';
import {ProductCard as SourceProductCard} from 'SourceComponent/ProductCard/ProductCard.component'
import { capitalizeBrand, cutBrandForMobile, formatWeight, reorganizeProductFormat } from 'Util/Product/Product';

import './ProductCard.style';

export class ProductCard extends SourceProductCard {
    static propTypes = {
        linkTo: PropTypes.shape({}),
        product: ProductType.isRequired,
        productOrVariant: ProductType.isRequired,
        handleRemoveItem: PropTypes.func.isRequired,
        thumbnail: PropTypes.string,
        availableVisualOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })).isRequired,
        getAttribute: PropTypes.func.isRequired,
        registerSharedElement: PropTypes.func.isRequired,
        children: PropTypes.element,
        isLoading: PropTypes.bool,
        mix: PropTypes.shape({}),
        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        isInWishList:PropTypes.bool
    };

    static defaultProps = {
        thumbnail: '',
        linkTo: {},
        children: null,
        isLoading: false,
        mix: {},
        renderContent: false,
        Cart:[]
    };

    contentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this)
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderProductPrice.bind(this),
            confOptions: this.renderVisualConfigurableOptions.bind(this),
            tierPrice: this.renderTierPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderAdditionalProductDetails.bind(this)
        }
    };

    imageRef = createRef();
    nameRef = createRef();
    brandRef = createRef();

    registerSharedElement = () => {
        const { registerSharedElement } = this.props;
        registerSharedElement(this.imageRef);
    };

    renderConfigurablePriceBadge() {
        const {
            product: { type_id }
        } = this.props;

        if (type_id !== CONFIGURABLE) {
            return null;
        }

        return (
            <p
              mix={ {
                  block: 'ProductCard',
                  elem: 'PriceBadge'
              } }
            >
                { __('As Low as') }
            </p>
        );
    }

    renderProductPrice() {
        const {
            product,
            isSearchProductSlider,
            product: {
                price_range,
                special_from_date,
                special_to_date
            }
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder length="short" />;
        }

        return (
            <>
                { this.renderConfigurablePriceBadge() }
                <ProductPrice
                  price={ price_range }
                  specialFromDate={ special_from_date }
                  specialToDate={ special_to_date }
                  product={ product }
                  mix={ { block: "ProductCard", elem: "Price" } }
                  isProductCard
                  isSearchProductSlider={ isSearchProductSlider }
                />
            </>
        );
    }

    renderTierPrice() {
        const { productOrVariant } = this.props;

        return (
            <TierPrices
              product={ productOrVariant }
              isLowestPrice
            />
        );
    }

    renderVisualConfigurableOptions() {
        const { availableVisualOptions } = this.props;

        return (
            <div block="ProductCard" elem="ConfigurableOptions">
                { availableVisualOptions.map(({ value, label }) => (
                    <span
                      block="ProductCard"
                      elem="Color"
                      key={ value }
                      style={ { backgroundColor: value } }
                      aria-label={ label }
                    />
                )) }
            </div>
        );
    }

    renderPicture(mix = {}) {
        const {
            device: {
                isMobile
            },
            product: {
                id,
                name
            },
            thumbnail
        } = this.props;

        const regexMediaCatalog = /\/media\/catalog\//;
        const regexCache = /\/cache\/[a-zA-Z0-9]+\//;
        const imageSize = isMobile ? 'width=178,height=178' : 'width=276,height=362';
        const adjustedThumbnail = thumbnail.replace(regexMediaCatalog, `/cdn-cgi/image/${imageSize},quality=80/media/catalog/`).replace(regexCache, '/');

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ adjustedThumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ adjustedThumbnail }
                />
            </>
        );
    }

    renderReviews() {
        const {
            product: {
                review_summary: {
                    rating_summary
                } = {}
            }
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="Reviews"
            >
                <ProductReviewRating summary={ rating_summary || 0 } />
            </div>
        );
    }

    renderAdditionalProductDetails() {
        const {
            product,
            isSearchProductSlider,
            isQueryFetching
        } = this.props;

        return (
            <>
                <div
                  block="ProductCard"
                  elem="Brand"
                  ref={this.brandRef}
                >
                    <div block="ProductCard" elem="BrandWrapper">
                        {this.renderBrand()}
                    </div>
                    { !isSearchProductSlider && <ProductAvailability
                      isQueryFetching={ isQueryFetching }
                      product={ product }
                      isProductCard
                    /> }
                </div>
            </>
        );
    }

    renderBrand(){
        const { product: { price_type_kg, brand, weight, size_label }, device: { isMobile }} = this.props;

        if (!brand) {
            return null;
        }

        const isTypeKg = price_type_kg === PRICE_TYPE_KG;
        const capitalizedBrand = capitalizeBrand(brand);
        const cuttedBrand = cutBrandForMobile(capitalizedBrand, isMobile);
        const reorganizedFormat = isTypeKg ? formatWeight(weight) : reorganizeProductFormat(size_label);

        return (
                <div
                  block="ProductCard"
                  elem="BrandName"
                  mix={ { block: "ProductAttributeValue" } }
                  mods={ { isLoaded: brand == null } }
                >
                    <span
                      block="ProductCard"
                      elem="BrandNameText"
                    >
                        <TextPlaceholder content={ cuttedBrand } length='custom' />
                    </span>
                    { !!reorganizedFormat && <span block="Brand" elem="Slash" > /</span> }
                    { !!reorganizedFormat &&
                        <>
                            {/* { brand.length > lineBreakAfter && <br /> } */}
                            <TextPlaceholder content={ ` ${ reorganizedFormat }` } length='custom' />
                        </>
                    }
                </div>
        );
    }

    renderMainDetails() {
        const { product: { name }, isMobileRecipeCard } = this.props;
        let productName = !!name ? name : "";

        return (
            <>
                <p
                    block="ProductCard"
                    elem="Name"
                    ref={this.nameRef}
                    mods={ { isLoaded: !name } }
                >
                    <TextPlaceholder content={ productName } length="medium" />
                </p>
                { !productName && isMobileRecipeCard && <TextPlaceholder content={ productName } length="short" /> }
            </>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (<div>{ children }</div>);
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }
    renderProductCardWishlistButton() {
        const {
            product,
            isInCart,
            isInWishList,
            isSearchProductSlider
        } = this.props;

        if(isInWishList || isSearchProductSlider){
            return null;
        }

        if(!!!Object.values(product).length){
            return null
        }

        return (
            <ProductWishlistButton
              product={ product }
              mix={{ block: 'ProductCard', elem:'ButtonProductCard', mods:{isInCart}}}
            />
        );
    }

    renderQuantityChangeField() {
        const {
            qty,
            handleChangeQuantity,
        } = this.props;

        return (
            <Field
              id="item_qty"
              name="item_qty"
              type="number"
              isControlled
              min={ 0 }
              mix={ { block: 'ProductCard', elem: 'Qty' } }
              value={ qty }
              onChange={ handleChangeQuantity }
            />
        );
    }

    renderAddToCart(mix = {}) {
        const {
            configurableVariantIndex,
            product,
            quantity,
            groupedProductQuantity,
            onProductValidationError,
            productOptionsData,
            setIsLoading,
            isInWishList,
            renderAddToCartButton,
            isSearchProductSlider
        } = this.props;

        if(!!!Object.values(product).length){
            return null
        }

        if (isInWishList) {
            return renderAddToCartButton();
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductCard', elem: 'AddToCart' } }
              wrapperMix={ mix }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              onProductValidationError={ onProductValidationError }
              productOptionsData={ productOptionsData }
              setIsLoading={ setIsLoading }
              isSearchProductSlider={ isSearchProductSlider }
            />
        );
    }


    renderProductLabel(){
        const {
            product: {
                product_label_text
            },
            isSearchWindow
        } = this.props;

        if (!product_label_text || isSearchWindow) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="labelTitle"
            >
                <p>{ product_label_text }</p>
            </div>
        );
    }

    renderAddChangeAction(isRenderedOnImg = false, mix) {
        const {
            isInCart,
            isInWishList,
            isSearchProductSlider,
        } = this.props;

        if (
            (!isSearchProductSlider && isRenderedOnImg)
            || (isSearchProductSlider && !isRenderedOnImg)
        ) {
            return null;
        }

        return isInCart && !isInWishList && !isRenderedOnImg
            ? this.renderQuantityChangeField()
            : this.renderAddToCart(mix);
    }

    renderActionsPlaceholder() {
        return <TextPlaceholder length="block" />;
    }

    renderActions() {
        return (
            <div
              block="ProductCard"
              elem="ActionsWrapper"
            >
                { this.renderActionsContent() }
            </div>
        );
    }

    renderActionsContent() {
        const {
            isQueryFetching,
            device: { isMobile }
        } = this.props;

        if (isQueryFetching) {
            return this.renderActionsPlaceholder();
        }

        return (
            <>
                { isMobile && this.renderProductCardWishlistButton()}
                { this.renderAddChangeAction() }
            </>
        );
    }

    renderCardContent() {
        const {
            renderContent,
            device: { isMobile }
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div
                      block="ProductCard"
                      elem="FigureAndInfoBlock"
                    >
                        <div
                          block="ProductCard"
                          elem="FigureContainer"
                        >
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                            { !isMobile && this.renderProductCardWishlistButton()}
                            { this.renderAddChangeAction(
                                true,
                                { block: "ProductCard", elem: "AddToCartOnImg" }
                            ) }
                        </div>
                        <div
                          block="ProductCard"
                          elem="CardDetailsWrapper"
                        >
                            { this.renderMainDetails() }
                            { this.renderAdditionalProductDetails() }
                        </div>
                    </div>
                    <div block="ProductCard" elem="LabelPriceWrapper">
                        { this.renderProductLabel() }
                        <div
                          block="ProductCard"
                          elem="PriceAndActionsWrapper"
                        >
                            { this.renderProductPrice() }
                            { this.renderActions() }
                        </div>
                        { this.renderReviews() }
                        { this.renderVisualConfigurableOptions() }
                    </div>
                </>
            ))
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            isInWishList,
            renderRemove
        } = this.props;

        if(isInWishList){

            return(
                <li
                  block="ProductCard"
                  mix={ mix }
                >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                { renderRemove() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
            )
        }
        return (
            <li
              block="ProductCard"
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCard;
