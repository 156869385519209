/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_CHECKOUT_ADDPAYMENTINFO } from '../../../util/Event';
import { roundPrice } from 'Util/Price';
import BaseEvent from './BaseEvent.event';
import ProductHelper from '../utils';

/**
 * On checkout
 */
export class checkoutAddPaymentInfo extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = 500;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_GTM_CHECKOUT_ADDPAYMENTINFO, ({ orderID: orderId, totals }) => {
            this.handle(
                orderId,
                totals
            );
        });
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    handler(orderId, totals) {
        if (this.spamProtection(100)) {
            return;
        }

        // Remove firstname, lastname, telephone fields
        const { firstname, lastname, telephone, ...newBillingAddress } = orderId.billing_address;
        const modifiedOrderId = {
            ...orderId,
            billing_address: newBillingAddress
        };

        this.pushEventData({
            ecommerce: {
                currency: this.getCurrencyCode(),
                ...this.getActionFields(modifiedOrderId, totals),
                items: this.getProducts(totals)
            }
        });
    }

    /**
     * Get order information
     *
     * @return {{revenue: number, coupon_discount_abs: string, coupon: string, shipping: number, affiliation: string, coupon_discount_amount: string, tax: number, id: *}}
     */
    getActionFields(orderId = '', {
        tax_amount, grand_total, shipping_amount, discount_amount, coupon_code = '', items = [], chosenTimeslot = []
    }) {
        const coupon_discount_amount = +roundPrice(discount_amount);
        const items_discount_sum = items?.reduce((sum, item) => sum + item.discount_amount, 0);
        const coupon_discount_total = +roundPrice(items_discount_sum);
        const store = chosenTimeslot?.store_code;
        const delivery = store ? 'ramassage en magasin' : 'livraison';

        return {
            id: orderId,
            tax: +roundPrice(tax_amount),
            coupon: coupon_code,
            revenue: +roundPrice(grand_total),
            shipping: +roundPrice(shipping_amount),
            coupon_discount_amount: coupon_discount_amount || coupon_discount_total || 0,
            delivery_method: delivery,
            magasin_selected: store || 'N/A'
        };
    }

    /**
     * Get product detail
     *
     * @param paymentTotals
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, category: string, brand: string, url: string}[]}
     */
    getProducts({ items }) {
        const products = Object.values(items).reduce((acc, item) => (
            [
                ...acc,
                {
                    ...ProductHelper.getItemData(item),
                    quantity: ProductHelper.getQuantity(item),
                    availability: true
                }
            ]), []);

        const groupedProducts = this.getGroupedProducts();
        Object.values(groupedProducts || {}).forEach(({ data }) => products.push(data));

        return products;
    }
}

export default checkoutAddPaymentInfo;
