export const SET_PROMOTION = 'SET_PROMOTION';

/**
 * Update promotion data
 * @param  {Object} avrilPromotion
 * @return {Object}
 * @namespace Store/Promotion/Action/setPromotion */
export const setPromotion = (avrilPromotion) => ({
    type: SET_PROMOTION,
    avrilPromotion,
});
