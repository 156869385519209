import ConfigQuery from 'Query/Config.query';
import MyAccountQuery from 'Query/MyAccount.query';

import { updateCheckoutFlow } from 'Store/Checkout/Checkout.action';
import { CLOSEST_STORE, PICKUP_LOCATIONS } from 'Store/Config/Config.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateClosestStore } from 'Store/Config/Config.action';
import {
    isSignedIn,
    isSessionOver,
    deleteAuthorizationToken,
    setAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { executePost, fetchMutation } from 'Util/Request';
import { prepareQuery } from 'Util/Query';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { updateRewardBonusCart } from 'Store/Cart/Cart.action';
import {
    updateCustomerDetails,
    updateCustomerPasswordResetStatus,
    updateCustomerIsAuthTokenExpired,
    updateIsLoading,
    updateIsCustomerClosestStoreResolved,
    updateCustomerSignInStatus
} from 'Store/MyAccount/MyAccount.action';
import { updateEmail } from 'Store/Checkout/Checkout.action';
import { ORDERS } from 'Store/Order/Order.reducer';

import { MyAccountDispatcher as SourceMyAccountDispatcher } from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { DEFAULT_LOCATION_CODE } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';
import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';

export const CUSTOMER = 'customer';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        return executePost(prepareQuery([query])).then(
            /** @namespace Store/MyAccount/Dispatcher/requestCustomerDataExecutePostThen */
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                
                // assign the new store after logging in on checkout
                if (!window.location.pathname.includes('/checkout')) {
                    this.queryClosestStore(dispatch);
                } else {
                    const closestStore = BrowserDatabase.getItem(CLOSEST_STORE);
                    this.assignStoreToCustomer(dispatch, closestStore, false);
                }
            },
            /** @namespace Store/MyAccount/Dispatcher/requestCustomerDataExecutePostError */
            (error) => dispatch(showNotification('error', error[0].message))
        );
    }

    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;

        const mutation = MyAccountQuery.getCreateAccountMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Store/MyAccount/Dispatcher/createAccountFetchMutationThen */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));
                    return 2;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace Store/MyAccount/Dispatcher/createAccountFetchMutationError */
            (error) => {
                if (error[0].message === 'The email you entered is invalid. Please try again.'){
                    dispatch(showNotification('error', "A customer with the same email address already exists in an associated website"));
                }else{
                    dispatch(showNotification('error', error[0].message));
                }
                Promise.reject();
                dispatch(updateIsLoading(false));

                return false;
            }
        );
    }

    setStoreInBrowser(dispatch, store) {
        BrowserDatabase.setItem(store, CLOSEST_STORE, ONE_MONTH_IN_SECONDS);
        dispatch(updateClosestStore(store));
    }

    async queryClosestStore(dispatch) {
        const { items } = BrowserDatabase.getItem(PICKUP_LOCATIONS) || {};

        const {
            resolveClosestStore: { pickup_location_code: assignedStoreCode }
        } = await fetchMutation(ConfigQuery.resolveClosestStore());

        if (!assignedStoreCode) {
            return;
        }

        if (items) {
            const assignedStore = items.filter(({ pickup_location_code }) => assignedStoreCode === pickup_location_code)[0];
            this.setStoreInBrowser(dispatch, assignedStore);
            dispatch(updateIsCustomerClosestStoreResolved(true));
        }

        if (assignedStoreCode !== DEFAULT_LOCATION_CODE) {
            dispatch(updateCheckoutFlow(''));
        }

        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.distributeCart(dispatch, assignedStoreCode)
        );
    }

    async assignStoreToCustomer(dispatch, storeOption, isReloadRequired = true) {
        const { pickup_location_code } = storeOption;
        const mutation = ConfigQuery.resolveClosestStore(pickup_location_code);

        if (isSessionOver()) {
            return;
        }

        try {
            if (isSignedIn()) {
                const { resolveClosestStore } = await fetchMutation(mutation);

                if (resolveClosestStore) {
                    this.setStoreInBrowser(dispatch, storeOption);
                }
            } else {
                this.setStoreInBrowser(dispatch, storeOption);
            }

            if (isReloadRequired) {
                window.location.reload();
            }
        } catch(error) {
            dispatch(showNotification('info', error[0].message));
        }
    }

      /**
     * Reset password action
     * @param {{token: String, password: String, password_confirmation: String}} [options={}]
     * @returns {Promise<{status: String}>} Reset password token
     * @memberof MyAccountDispatcher
     */
    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Store/MyAccount/Dispatcher/resetPasswordFetchMutationThen */
            ({ resetPasswordByToken: { status } }) => dispatch(updateCustomerPasswordResetStatus(status)),
            /** @namespace Store/MyAccount/Dispatcher/resetPasswordFetchMutationError */
            () => dispatch(updateCustomerPasswordResetStatus('error'))
        );
    }

    /**
     * Sign in action
     * @param {{email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        try {
            dispatch(updateRewardBonusCart({}));
            const result = await fetchMutation(mutation);
            const { generateCustomerToken: { token } } = result;

            BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
            setAuthorizationToken(token);
            dispatch(updateCustomerSignInStatus(true));
            CartDispatcher.then(
                ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
            );
            WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
            );
            dispatch(updateIsLoading(false));

            return true;
        } catch ([e]) {
            throw e;
        }
    }

    async logout(authTokenExpired = false, dispatch, needUpdateQuote = true) {
        if (authTokenExpired) {
            dispatch(updateCustomerIsAuthTokenExpired(true));
        } else {
            deleteAuthorizationToken();
        }
        dispatch(updateCustomerSignInStatus(false));

        if (needUpdateQuote) {
            try {
                const dispatcherModule = await CartDispatcher;
                const dispatcher = dispatcherModule.default;
                await Promise.all([
                    dispatcher.createGuestEmptyCart(dispatch),
                    dispatcher.updateInitialCartData(dispatch)
                ]);
            } catch (error) {
                console.error('Error logout: ', error);
            }
        }

        this.onLogout(dispatch);
    }

    onLogout(dispatch) {
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        dispatch(updateEmail(''));
        dispatch(updateCustomerDetails({}));
    }
}

export default new MyAccountDispatcher();
