import { PureComponent } from "react";
import { connect } from "react-redux";

import Html from "Component/Html";

import "Component/BlogCmsBanner/BlogCmsBanner.style";
import "./RecipeCmsBanner.style";

/** @namespace Component/RecipeCmsBanner/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    isVisible: state.RecipeReducer.isRecipeBannerVisible,
    currentCategoryData: state.RecipeReducer.currentCategoryData
});

export class RecipeCmsBanner extends PureComponent {
    render() {
        const {
            isVisible,
            isRenderedInRouter,
            mix,
            currentCategoryData: { description } = {}
        } = this.props;

        if (!isVisible || !description) {
            return null;
        }

        return (
            <div
                block="RecipeCmsBanner"
                mix={{ block: "BlogCmsBanner", mix }}
                mods={{
                    isRenderedInRouter
                }}
            >
                <Html content={description} />
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(RecipeCmsBanner);
