/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 export {
    UPDATE_CONFIG,
    UPDATE_CONFIG_DEVICE,
    updateConfig,
    updateConfigDevice
} from 'SourceStore/Config/Config.action';

export const UPDATE_CLOSEST_STORE = 'UPDATE_CLOSEST_STORE';
export const UPDATE_IS_WELCOME_OPEN = 'UPDATE_IS_WELCOME_OPEN';
export const UPDATE_IS_DISCLAIMER_HIDDEN = 'UPDATE_IS_DISCLAIMER_HIDDEN';
export const UPDATE_CUSTOMER_INTERESTS = 'UPDATE_CUSTOMER_INTERESTS';

/** @namespace Store/Config/Action/updateClosestStore */
export const updateClosestStore = (getClosestStore) => ({
    type: UPDATE_CLOSEST_STORE,
    getClosestStore
});

/** @namespace Store/Config/Action/updateIsDisclaimerHidden */
export const updateIsDisclaimerHidden = (isDisclaimerHidden) => ({
    type: UPDATE_IS_DISCLAIMER_HIDDEN,
    isDisclaimerHidden
});

/** @namespace Store/Config/Action/updateIsWelcomeOpen */
export const updateIsWelcomeOpen = (isWelcomeOpen) => ({
    type: UPDATE_IS_WELCOME_OPEN,
    isWelcomeOpen
});

export const updateCustomerInterests = (customerInterests) => ({
    type: UPDATE_CUSTOMER_INTERESTS,
    customerInterests
})