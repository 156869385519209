/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export {
    UPDATE_CUSTOMER_SIGN_IN_STATUS,
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_IS_AUTH_TOKEN_EXPIRED,
    updateCustomerSignInStatus,
    updateCustomerIsAuthTokenExpired,
    updateCustomerDetails,
    updateCustomerPasswordResetStatus,
    updateCustomerPasswordForgotStatus,
    updateIsLoading
} from 'SourceStore/MyAccount/MyAccount.action';

export const UPDATE_IS_CUSTOMER_CLOSEST_STORE_RESOLVED = 'UPDATE_IS_CUSTOMER_CLOSEST_STORE_RESOLVED';

/** @namespace Store/MyAccount/Action/updateIsCustomerClosestStoreResolved */
export const updateIsCustomerClosestStoreResolved = (isCustomerClosestStoreResolved) => ({
    type: UPDATE_IS_CUSTOMER_CLOSEST_STORE_RESOLVED,
    isCustomerClosestStoreResolved
});
