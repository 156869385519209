/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CartItem as SourceCartItem } from 'SourceComponent/CartItem/CartItem.component';
import Loader from 'Component/Loader';
import ProductPrice from 'Component/ProductPrice';
import Link from 'Component/Link';
import Switch from 'Component/Switch';
import { PRICE_TYPE_KG } from 'Component/ProductPrice/ProductPrice.config';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import { PICKUP_FLOW } from 'Route/Checkout/Checkout.config';
import { formatPrice, getisOldPriceRendered } from 'Util/Price';
import { getIsSubstitutable } from 'Util/Cart';
import { reorganizeProductFormat, formatWeight } from 'Util/Product';

import { ReactComponent as Alert } from './icons/alert.svg';

import { ReactComponent as Recycle } from 'Component/ProductActions/icon/recycle.svg';

import './CartItem.style';

export class CartItem extends SourceCartItem {
    state = { productBrands: "" }

    componentDidMount() {
        const {
            item: {
                product: {
                    attributes: {
                        brands: {
                            attribute_value,
                            attribute_options
                        } = {}
                    }
                }
            },
        } = this.props;

        if (!!attribute_options) {
            this.setState({ productBrands: attribute_options[attribute_value].label })
        }

    }

    componentDidUpdate() {
        const { productBrands } = this.state;
        const {
            item: {
                product: {
                    attributes: {
                        brands: {
                            attribute_value,
                            attribute_options
                        } = {}
                    }
                }
            },
        } = this.props;

        if (!!!productBrands) {
            if (!!attribute_options) {
                this.setState({ productBrands: attribute_options[attribute_value].label })
            }
        }
    }

    renderAdditionalProductDetails() {
        const {
            item: {
                product: {
                    weight,
                    price_type_kg,
                    attributes: {
                        brands,
                        size,
                        size_label
                    }
                }
            },
            isCheckout
        } = this.props;
        const { productBrands } = this.state
        let format = ""

        const brand = productBrands.toLowerCase();

        if (size) {
            format = Object.values(size.attribute_options)[0].label;
        }

        const isTypeKg = price_type_kg === PRICE_TYPE_KG;
        const reorganizedFormat = isTypeKg ? formatWeight(weight) : reorganizeProductFormat(size_label);

        return (
            <div
              block="CartItem"
              elem="Brand"
              mods={ { isLoaded: !!brands } }
            >
                <div block="ProductAttributeValue" >
                    { isCheckout ? brand : `${ brand }  ${ !!reorganizedFormat ? "/ " + reorganizedFormat: "" }` }
                </div>
            </div>
        );
    }

    renderQty() {
        const {
            item: { qty }
        } = this.props;

        return (
            <span
              block="CartItem"
              elem="QtyCheckout"
            >
                { `Qt. ${ qty }` }
            </span>
        );
    }

    renderUnitPrice() {
        const {
            storeCode,
            item: {
                product: {
                    price_range: {
                        minimum_price: {
                            final_price: {
                                value
                            }
                        }
                    }
                }
            }
        } = this.props;

        return (
            <div
              block="CartItem"
              elem="UnitPrice"
            >
                <div>
                    <span>
                        { __('Unit price : %s', formatPrice(value, storeCode)) }
                    </span>
                </div>
            </div>
        )
    }

    renderUnit() {
        return (
            <p
              block="CartItem"
              elem="Unit"
            >
                { __('price/kg') }
            </p>
        )
    }

    renderTotalPrice() {
        const {
            storeCode,
            item: { price, qty }
        } = this.props;

        const totalPrice = price * qty;

        return (
            <span>
                { formatPrice(totalPrice, storeCode) }
            </span>
        );
    }

    renderActions() {
        const {
            isEditing,
            isLikeTable,
            handleRemoveItem,
            checkoutFlow
        } = this.props;

        const isPickupFlow = checkoutFlow === PICKUP_FLOW;

        return (
            <div
              block="CartItem"
              elem="Actions"
              mods={ { isEditing, isLikeTable, isPickupFlow } }
            >
                <button
                  block="CartItem"
                  id="RemoveItem"
                  name="RemoveItem"
                  elem="Delete"
                  aria-label="Remove item from cart"
                  onClick={ handleRemoveItem }
                >
                    { __('Delete this item') }
                </button>
                { this.renderQuantityChangeField() }
            </div>
        );
    }

    renderProductName() {
        const {
            item: {
                product: {
                    name
                }
            }
        } = this.props;

        return (
            <p
              block="CartItem"
              elem="Heading"
            >
                { name }
            </p>
        );
    }

    renderProductPrice() {
        const {
            item: {
                qty,
                product: {
                    price_range,
                    special_from_date,
                    special_to_date
                }
            }
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        return (
            <>
                <div block="CartItem" elem="Price">
                    <ProductPrice
                      price={ price_range }
                      specialFromDate={ special_from_date }
                      specialToDate={ special_to_date }
                      qty = { qty }
                      mix={ {  } }
                    />
                    { this.renderFeesPrice() }
                </div>
                { this.renderUnitPrice() }
            </>
        );
    }

    renderFeesPrice() {
        const {
            storeCode,
            item: {
                qty,
                product: {
                    fee: { value: fee = 0 } = {}
                }
            }
        } = this.props;

        let displayedPrice = fee;

        if (!displayedPrice || !Number(displayedPrice)) {
            return null;
        }

        if (typeof displayedPrice === "string") {
            displayedPrice = parseFloat(displayedPrice.replace(/,/, "."));
        }

        return (
            <div block="CartItem" elem="FeesWrapper">
                <span>+</span>
                <span>
                    <span>{ formatPrice((displayedPrice * qty), storeCode) }</span>
                </span>
                <Recycle />
            </div>
        );
    }

    renderWrapperContent() {
        const {
            isCheckout,
            isProductInStock,
            checkoutFlow,
            item: {
                product: {
                    special_from_date,
                    special_to_date,
                    fee: { value: feeVal = 0 } = {},
                    price_range: {
                        minimum_price: {
                            discount: { percent_off }
                        }
                    }
                }
            }
        } = this.props;

        // these mods are to control the wrapper's height
        const isFeesRendered = feeVal > 0,
        isDiscountRendered = getisOldPriceRendered(special_from_date, special_to_date, percent_off),
        isAllRendered = isFeesRendered && isDiscountRendered,
        isPickupFlow = checkoutFlow === PICKUP_FLOW;

        return (
            <>
                { !isProductInStock && this.renderOutOfStockMessage() }
                <figure
                  block="CartItem"
                  elem="Wrapper"
                  mods={ {
                      isCheckout,
                      isPickupFlow,
                      isFeesRendered: isFeesRendered && !isAllRendered,
                      isAllRendered
                  } }
                >
                    { this.renderImage() }
                    { this.renderContent() }
                </figure>
            </>
        );
    }

    renderWrapper() {
        const { linkTo, isProductInStock, isCheckout,closeMiniCart } = this.props;

        if (!isProductInStock || Object.keys(linkTo).length === 0) {
            // If product is out of stock, or link is not set
            return (
                <span
                block="CartItem"
                elem="Link"
                onClick={ () => !!closeMiniCart ? closeMiniCart() : null }
                >
                    { this.renderWrapperContent() }
                </span>
            );
        }

        // CartItem is not clickable on checkout
        if (isCheckout) {
            return this.renderWrapperContent();
        }

        return (
            <Link to={ linkTo } block="CartItem" elem="Link"  onClick={()=>!!closeMiniCart? closeMiniCart(): null}>
                { this.renderWrapperContent() }
            </Link>
        );
    }

    renderContent() {
        const {
            isLikeTable,
            item: {
                customizable_options,
                bundle_options
            } = {},
            isCheckout
        } = this.props;


        if (isCheckout) {
            return (
                <figcaption
                  block="CartItem"
                  elem="Content"
                  mods={ { isLikeTable } }
                >
                    <div
                      block="CartItem"
                      elem="ProductInfoWrapper"
                    >
                        { this.renderProductName() }
                        { this.renderAdditionalProductDetails() }
                        { this.renderQty() }
                    </div>
                    <div>
                        { this.renderTotalPrice() }
                    </div>
                </figcaption>
            );
        }

        return (
            <figcaption
              block="CartItem"
              elem="Content"
              mods={ { isLikeTable } }
            >
                { this.renderProductName() }
                { this.renderAdditionalProductDetails() }
                { this.renderProductSubstitution() }
                { this.renderProductOptions(customizable_options) }
                { this.renderProductOptions(bundle_options) }
                { this.renderProductConfigurations() }
                { this.renderProductPrice() }
            </figcaption>
        );
    }

    renderProductSubstitution() {
        const {
            isCartPage,
            checkoutFlow,
            switchProductSubstitutability,
            substitutableProducts,
            item: {
                item_id,
                product: { options }
            },
            device: { isMobile }
        } = this.props;

        const isSubstitutable = getIsSubstitutable(options);

        if (!isSubstitutable || !isCartPage || checkoutFlow !== PICKUP_FLOW) {
            return null;
        }

        const mix = { block: "CartItem", elem: "Substitution" };
        const isActivated = substitutableProducts.includes(item_id);

        return (
            <div
              block="CartItem"
              elem="ProductSubstitution"
              onClick={ e => e.preventDefault() }
            >
                { isMobile && <p>{ __('Substitute') }</p> }
                <div block="CartItem" elem="TooltipAndSwitch">
                    { this.renderSubstitutionTooltip() }
                    <Switch
                      isActivated={ isActivated }
                      mix={ mix }
                      onClick={ () => switchProductSubstitutability(item_id) }
                    />
                </div>
            </div>
        );
    }

    renderProductOption = (option) => {
        const { label, values, id } = option;

        if (label == 'Substitution') {
            return null;
        }

        return (
            <div
                block="CartItem"
                elem="ItemOption"
                key={ id }
            >
                <div
                    block="CartItem"
                    elem="ItemOptionLabel"
                    key={ `label-${ id }` }
                >
                    { `${ label }:` }
                </div>
                <div block="CartItem" elem="ItemOptionValues">
                    { values.map(this.renderProductOptionValue) }
                </div>
            </div>
        );
    };

    renderSubstitutionTooltip() {
        const { device: { isMobile } } = this.props;

        return (
            <div block="CartItem" elem="ProductSubstitutionTooltip">
                <Alert />
                { !isMobile && <p>{ __('Produit de substitution') }</p> }
                <span block="CartItem" elem="SubstitutionTooltipText">
                    {
                        __('If the product you ordered is out of stock and the ' +
                        'substitution option is selected for that product, the ' +
                        'original choice will be replaced with a similar product.')
                    }
                </span>
            </div>
        );
    }

    renderMobileVersion() {
        const {
            handleRemoveItem,
            isCartPage,
            checkoutFlow
        } = this.props;

        const isSubstitutionRendered = !(!isCartPage || checkoutFlow !== PICKUP_FLOW);

        return (
            <div block="CartItem" elem="MobileWrapper">
                <div block="CartItem" elem="MobileNameAndImage">
                    { this.renderImage() }
                    <div>
                        { this.renderProductName() }
                        { this.renderAdditionalProductDetails() }
                    </div>
                </div>
                <div block="CartItem" elem="PriceAndActions">
                    <div block="CartItem" elem="Prices">
                        { this.renderProductPrice() }
                        { this.renderProductSubstitution() }
                    </div>
                    { this.renderQuantityChangeField() }
                </div>
                <div block="CartItem" elem="DeleteWrapper" mods={ { isSubstitutionRendered } }>
                    <button
                      block="CartItem"
                      elem="Delete"
                      id="RemoveItem"
                      name="RemoveItem"
                      aria-label="Remove item from cart"
                      onClick={ handleRemoveItem }
                    >
                        { __('Delete this item') }
                    </button>
                </div>
            </div>
        );
    }

    renderDesktopVersion() {
        return (
            <>
                { this.renderWrapper() }
                { this.renderActions() }
            </>
        );
    }

    render() {
        const {
            isProductInStock,
            isLoading,
            isCheckout = false,
            device: { isMobile }
        } = this.props;

        return (
            <li
              block="CartItem"
              mods={ {
                  isCheckout,
                  OutOfStock: !isProductInStock
              } }
            >
                <Loader isLoading={ isLoading } mix={ { block: "CartItem", elem: "ItemLoader" } } />
                {
                    isMobile && !isCheckout
                        ? this.renderMobileVersion()
                        // ? this.renderDesktopVersion()
                        : this.renderDesktopVersion()
                }
            </li>
        );
    }
}

export default CartItem;
