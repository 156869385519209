/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { addPostItemsData } from "Util/Blog";
import { setMainMarginTop } from "Util/Window";
import { getCurrentPage } from "Util/Url";
import {
    updateIsBlogBannerVisible,
    updatePageInfo,
    updateBlogPosts,
} from "Store/Blog/Blog.action";
import { updateMeta } from "Store/Meta/Meta.action";

import { CATEGORY, TAG, ALL } from "./BlogPage.config";

import BlogPage from "./BlogPage.component";

export const BlogDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/Blog/Blog.dispatcher"
);

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/Breadcrumbs/Breadcrumbs.dispatcher"
);

export const mapStateToProps = (state) => ({
    blogPosts: state.BlogReducer.blogPosts,
    blogTags: state.BlogReducer.blogTags,
    blogAuthors: state.BlogReducer.blogAuthors,
    blogCategories: state.BlogReducer.blogCategories,
    isBlogLoading: state.BlogReducer.isBlogLoading,
    blogSettings: state.BlogReducer.blogSettings,
    device: state.ConfigReducer.device,
});

export const mapDispatchToProps = (dispatch) => ({
    getPosts: (page, entityId, type) =>
        BlogDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getPostsByType(dispatch, page, entityId, type)
        ),
    updateBreadcrumbs: (breadcrumbs) =>
        BreadcrumbsDispatcher.then(({ default: dispatcher }) =>
            dispatcher.update(breadcrumbs, dispatch)
        ),
    updateIsBlogBannerVisible: (isVisible) =>
        dispatch(updateIsBlogBannerVisible(isVisible)),
    updatePageInfo: (pageType, entityId) =>
        dispatch(updatePageInfo(pageType, entityId)),
    updateBlogPosts: (blogPosts) => dispatch(updateBlogPosts(blogPosts)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
});

/** @namespace Route/BlogPage/Container */
export class BlogPageContainer extends PureComponent {
    constructor(props) {
        super(props);
        const { categoryId, tagId, updatePageInfo } = props;

        const pageType =
            typeof categoryId === "number"
                ? CATEGORY
                : typeof tagId === "number"
                ? TAG
                : ALL;
        const entityId = categoryId ?? tagId ?? null;

        updatePageInfo(pageType, entityId);

        this.state = {
            isBreadSet: false,
            isMetaSet: false,
            pageType,
            entityId,
        };
    }

    componentDidMount() {
        const {
            location: { search },
            updateIsBlogBannerVisible,
            updateBlogPosts
        } = this.props;

        const currentPage = getCurrentPage(search, 'page');

        this.setBreadcrumbs();
        this.setMetaTags();
        updateBlogPosts({});
        this.getPostsByPage(currentPage);
        updateIsBlogBannerVisible(true);
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
            device: { isMobile },
        } = this.props;

        const { location: { pathname: prevPathname } } = prevProps;

        this.setBreadcrumbs();
        this.setMetaTags();
        setMainMarginTop(isMobile);

        if (pathname !== prevPathname) {
            this.setState({ isMetaSet: false });
        }
    }

    componentWillUnmount() {
        const { updateIsBlogBannerVisible } = this.props;
        updateIsBlogBannerVisible(false);
        this.updateMeta("", "", "", "", "");
    }

    containerProps = () => {
        const {
            blogPosts: { all_post_size, items: posts = [] } = {},
            blogTags: { items: tags = [] } = {},
            blogAuthors: { items: authors = [] } = {},
            blogCategories: { items: categoriesItems = [] } = {},
            blogSettings,
            isBlogLoading,
            device,
        } = this.props;
        const { pageType, entityId } = this.state;

        if (!posts || !categoriesItems) {
            return {};
        }

        const postsItems = addPostItemsData(
            posts,
            tags,
            authors,
            categoriesItems
        );

        const currentCategory =
            pageType === CATEGORY
                ? categoriesItems.filter(
                      ({ category_id }) => category_id == entityId
                  )[0]
                : undefined;

        return {
            postsItems,
            pageType,
            currentCategory,
            blogSettings,
            isBlogLoading,
            device,
            all_post_size,
            categoriesItems,
        };
    };

    containerFunctions = {
        getPostsByPage: this.getPostsByPage.bind(this),
    };

    getPostsByPage(page) {
        const { entityId, pageType } = this.state;
        const { getPosts } = this.props;

        window.scrollTo(0, 0);
        return getPosts(page, entityId, pageType);
    }

    setMetaTags() {
        const { isMetaSet, pageType } = this.state;

        const {
            blogSettings: {
                search_engine_meta_title,
                search_engine_meta_keywords,
                search_engine_meta_description,
                search_engine_title_suffix,
                search_engine_title_prefix,
            } = {},
            blogTags: { items: tags } = {},
            blogCategories: { items: categoriesItems } = {},
        } = this.props;

        if (isMetaSet || tags === undefined) {
            return;
        }

        this.setState({ isMetaSet: true });

        switch (pageType) {
            case CATEGORY:
                if (!Array.isArray(categoriesItems)) {
                    break;
                }

                const currentCategory = this.getCurrentEntity(
                    "category_id",
                    categoriesItems
                );

                this.updateMeta(
                    currentCategory.meta_title,
                    currentCategory.meta_description,
                    currentCategory.meta_tags,
                    search_engine_title_prefix,
                    search_engine_title_suffix
                );
                break;
            case TAG:
                if (!Array.isArray(tags)) {
                    break;
                }

                const currentTag = this.getCurrentEntity("tag_id", tags);
                this.updateMeta(
                    currentTag.meta_title,
                    currentTag.meta_description,
                    currentTag.meta_tags,
                    search_engine_title_prefix,
                    search_engine_title_suffix
                );
                break;
            default:
                this.updateMeta(
                    search_engine_meta_title,
                    search_engine_meta_description,
                    search_engine_meta_keywords,
                    search_engine_title_prefix,
                    search_engine_title_suffix
                );
        }
    }

    setBreadcrumbs() {
        const { isBreadSet, pageType } = this.state;

        const {
            updateBreadcrumbs,
            blogSettings: {
                search_engine_bread,
                search_engine_route
            } = {},
            blogTags: { items: tags } = {},
            blogCategories: { items: categoriesItems } = {},
        } = this.props;

        // if variable tags is undefined, the blog wasn't initialized yet
        if (isBreadSet || !search_engine_bread || tags === undefined) {
            return;
        }

        const breadcrumbs = [
            { url: "/", name: __("Home") },
            {
                url: pageType !== ALL ? `/${search_engine_route}` : "",
                name: search_engine_bread,
            },
        ];
        switch (pageType) {
            case CATEGORY:
                if (!Array.isArray(categoriesItems)) {
                    break;
                }

                const currentCategory = this.getCurrentEntity(
                    "category_id",
                    categoriesItems
                );
                breadcrumbs.splice(1, 0, this.getDynamicBread(currentCategory));
                break;
            case TAG:
                if (!Array.isArray(tags)) {
                    break;
                }

                const currentTag = this.getCurrentEntity("tag_id", tags);
                breadcrumbs.splice(1, 0, this.getDynamicBread(currentTag));
                break;
        }

        this.setState({ isBreadSet: true });
        updateBreadcrumbs(breadcrumbs);
    }

    updateMeta(title, description, keywords, title_prefix, title_suffix) {
        const { updateMeta } = this.props;
        updateMeta({
            title,
            description,
            keywords,
            title_prefix,
            title_suffix,
        });
    }

    getCurrentEntity(key, items) {
        const { entityId } = this.state;
        return (
            items.filter(
                ({ [key]: currEntityKey }) => currEntityKey == entityId
            )[0] || {}
        );
    }

    getDynamicBread(currentEntity) {
        return {
            url: "",
            name: currentEntity.name,
        };
    }

    render() {
        return (
            <BlogPage
                {...this.state}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BlogPageContainer)
);
