/* eslint-disable max-lines */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright Â© Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { createRef } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import {
    DEFAULT_LOCATION_CODE,
    CLOSEST_STORE_INFO,
} from "Component/ClosestStoreOverlay/ClosestStoreOverlay.config";
import { CUSTOMER_ACCOUNT, SEARCH } from "Component/Header/Header.config";
import { HEADER_CLOSEST_STORE_POPUP } from "Component/HeaderClosestStore/HeaderClosestStore.config";
import { PRODUCTS_TYPE, BLOG_TYPE, RECIPE_TYPE } from "Component/SearchField/SearchField.config";
import Category from "Query/Category.query";
import UrlRewritesQuery from "Query/UrlRewrites.query";
import { CHECKOUT_URL } from "Route/Checkout/Checkout.config";
import {
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from "SourceComponent/Header/Header.container";
import { updateAllCategory } from "Store/Category/Category.action";
import { updateIsWelcomeOpen, updateIsDisclaimerHidden } from "Store/Config/Config.action";
import { TOP_NAVIGATION_TYPE } from "Store/Navigation/Navigation.reducer";
import { toggleOverlayByKey } from "Store/Overlay/Overlay.action";
import { showPopup } from "Store/Popup/Popup.action";
import { updateIsXSearchLoading } from "Store/Search/Search.action";
import { isSignedIn as isSignedInWithToken } from "Util/Auth";
import CSS from "Util/CSS";
import history from "Util/History";
import { fetchQuery, executeGet, debounce } from "Util/Request";
import { prepareQuery } from "Util/Query";
import { getCurrentUrl } from "Util/StoreSwitcher";
import { appendWithStoreCode } from "Util/Url";
import { freezeGlobalScrollModule } from "Util/Window";

import Header from "./Header.component";
import { CART_OVERLAY } from "./Header.config";
import { X_SEARCH_DELAY } from "./config";
import {ONE_MONTH_IN_SECONDS} from "Util/Request/QueryDispatcher";

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/MyAccount/MyAccount.dispatcher"
);

export const SearchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/Search/Search.dispatcher"
);

/** @namespace AvrilPwa-App/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    storeList: state.ConfigReducer.storeList,
    isWelcomeOpen: state.ConfigReducer.isWelcomeOpen,
    closestStore: state.ConfigReducer.getClosestStore,
    isDisclaimerHidden: state.ConfigReducer.isDisclaimerHidden,
    category: state.CategoryReducer,
    isSearchInputClicked: state.SearchReducer.isSearchInputClicked,
    navigation: state.NavigationReducer[TOP_NAVIGATION_TYPE],
    blogPosts: state.BlogReducer.blogPosts,
    storeCodeName: state.ConfigReducer.code,
    storeLanguage: state.ConfigReducer.locale,
});

/** @namespace AvrilPwa-App/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateAllCategory: (category) => dispatch(updateAllCategory(category)),
    logout: () =>
        MyAccountDispatcher.then(({ default: dispatcher }) =>
            dispatcher.logout(null, dispatch)
        ),
    getSearchResults: (searchCriteria) =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getSearchResults(dispatch, searchCriteria)
        ),
    getBlogSearchPosts: (searchCriteria) =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getBlogSearchPosts(dispatch, searchCriteria)
        ),
    getSearchRecipes: (searchCriteria) =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.getSearchRecipes(dispatch, searchCriteria)
        ),
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key)),
    onSearchProductsOpen: () =>
        SearchDispatcher.then(({ default: dispatcher }) =>
            dispatcher.onSearchProductsOpen(dispatch)
        ),
    showPopup: (id) => dispatch(showPopup(id, {})),
    updateIsDisclaimerHidden: (isDisclaimerHidden) => dispatch(updateIsDisclaimerHidden(isDisclaimerHidden)),
    updateIsWelcomeOpen: (isWelcomeOpen) =>
        dispatch(updateIsWelcomeOpen(isWelcomeOpen)),
    updateIsXSearchLoading: (isXSearchLoading) =>
        dispatch(updateIsXSearchLoading(isXSearchLoading)),
});

/** @namespace AvrilPwa-App/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends SourceHeaderContainer {
    menuPromotionRef = createRef();
    searchCategoriesSectionRef = createRef();
    searchProductsScrollableRef = createRef();
    searchWindowRef = createRef();

    searchTypes = [
        {
            type: PRODUCTS_TYPE,
            title: __("Products"),
        },
        {
            type: BLOG_TYPE,
            title: __("Blog"),
        },
        {
            type: RECIPE_TYPE,
            title: __("Recipes"),
        },
    ];

    __construct(props) {
        const { getSearchResults, getBlogSearchPosts, getSearchRecipes } = props;
        super.__construct(props);

        this.getSearchResultsQuery = debounce(
            (searchCriteria) => getSearchResults(searchCriteria),
            X_SEARCH_DELAY
        );

        this.getBlogSearchPostsQuery = debounce(
            (searchCriteria) => getBlogSearchPosts(searchCriteria),
            X_SEARCH_DELAY
        );

        this.getRecipesSearchPostsQuery = debounce(
            (searchCriteria) => getSearchRecipes(searchCriteria),
            X_SEARCH_DELAY
        );

        const hasCookie = new Cookies();

        this.state = {
            activeType: PRODUCTS_TYPE,
            showMobileMenu: false,
            isEcom: false,
            isStoresModalOpen: false,
            isLocationPopupOpen: false,
            isWelcomeCMSLoaded: false,
            shouldRenderCartOverlay: false,
            isSearchWindowOpened: false,
            searchCriteria: "",
            locations: "",
            isDisclaimerHidden: hasCookie.get('disclaimer') === 'hidden',
            storeLanguage: "",
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getAllCategory();
        document.addEventListener(
            "onFiltersCloseClick",
            this.onCloseButtonClick.bind(this)
        );

        // Checks the cookie and shows or hides the disclamer banner
        const { disclaimerClosedByUser } = this.state;
        if (disclaimerClosedByUser) {
            CSS.setVariable(this.menuPromotionRef, "menu-promotion-height", "0px");
            updateIsDisclaimerHidden(true);
        } else {
            this.openDisclaimer();
        }

        // Change search box type by url location
        const currentURL = location.pathname;
        const storeCode = currentURL.includes('/en/') ? 'en' : 'fr';
        this.setState({
            storeLanguage: storeCode
        });
        this.updateActiveType(currentURL);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isWelcomeOpen, onSearchProductsOpen } = this.props;
        const { searchCriteria, activeType, locations } = this.state;
        const { searchCriteria: prevSearchCriteria, activeType: prevActiveType, locations: prevLocations } = prevState;
        const { isWelcomeOpen: prevIsWelcomeOpen } = prevProps;

        super.componentDidUpdate(prevProps);

        if (!searchCriteria && prevSearchCriteria) {
            onSearchProductsOpen();
        }

        if (activeType !== prevActiveType) {
            this.setState({ searchCriteria: '' });
        }

        this.updateTypePage();
        this.closeModalOnMobile();
        // Preventing scroll if Welcome modal is opened.
        this.handleScrollForWelcome(isWelcomeOpen, prevIsWelcomeOpen);

        // Change search box type by url location
        if(locations !== prevLocations) {
            this.updateActiveType(locations);
        }

    }

    // Change search box type by url location
    updateActiveType(locations) {
        if (locations.includes('/recettes')) {
            this.setSearchType(RECIPE_TYPE);
        } else if (locations.includes('/magazine') || locations.includes('/blog')) {
            this.setSearchType(BLOG_TYPE);
        } else {
            this.setSearchType(PRODUCTS_TYPE);
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            "onFiltersCloseClick",
            this.onCloseButtonClick.bind(this)
        );
    }

    containerFunctions = {
        ...this.containerFunctions,
        openDisclaimer: this.openDisclaimer.bind(this),
        closeDisclaimer: this.closeDisclaimer.bind(this),
        handleMobileMenuButtonClick:
            this.handleMobileMenuButtonClick.bind(this),
        closeMobileMenu: this.closeMobileMenu.bind(this),
        onSignOut: this.onSignOut.bind(this),
        onLocationClick: this.onLocationClick.bind(this),
        setIsStoresModalOpen: this.setIsStoresModalOpen.bind(this),
        onLocationPopupAction: this.onLocationPopupAction.bind(this),
        setIsSearchWindowOpened: this.setIsSearchWindowOpened.bind(this),
        setIsWelcomeOpen: this.setIsWelcomeOpen.bind(this),
        setSearchCriteria: this.setSearchCriteria.bind(this),
        onWelcomeCmsLoad: this.onWelcomeCmsLoad.bind(this),
        setIsSearchWindowOpened: this.setIsSearchWindowOpened.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this),
        setSearchType: this.setSearchType.bind(this),
    };

    setSearchType(activeType) {
        this.setState({ activeType });
    }

    setIsSearchWindowOpened(nextIsSearchWindowOpened) {
        const {
            device: { isMobile },
        } = this.props;
        const { isSearchWindowOpened } = this.state;

        if (
            nextIsSearchWindowOpened &&
            nextIsSearchWindowOpened !== isSearchWindowOpened
        ) {
            setTimeout(() => {
                this.scrollElemToTop(this.searchCategoriesSectionRef);
                this.scrollElemToTop(this.searchWindowRef);
                this.scrollElemToTop(this.searchProductsScrollableRef);
            });
        }

        this.setState(
            { isSearchWindowOpened: nextIsSearchWindowOpened },
            () => {
                const pageBottomBlur = document.getElementById(
                    "RouterSearchInsentShadow"
                );

                if (!isMobile) {
                    return;
                }

                if (nextIsSearchWindowOpened) {
                    // show it after animation
                    setTimeout(
                        () => (pageBottomBlur.style.display = "block"),
                        200
                    );
                } else {
                    pageBottomBlur.style.display = "none";
                }
            }
        );
    }

    scrollElemToTop(ref) {
        if (ref.current) {
            ref.current.scrollTo(0, 0);
        }
    }

    handleScrollForWelcome(isWelcomeOpen, prevIsWelcomeOpen) {
        if (isWelcomeOpen && isWelcomeOpen !== prevIsWelcomeOpen) {
            document.querySelector("body").style.height = "100%";
            document.querySelector("body").style.overflow = "hidden";
        } else if (!isWelcomeOpen && isWelcomeOpen !== prevIsWelcomeOpen) {
            document.querySelector("body").style.height = "unset";
            document.querySelector("body").style.overflow = "auto";
        }
    }

    onWelcomeCmsLoad() {
        const { closestStore: { name = "Avril" } = {} } = this.props;

        const welcomeStoreNameElem = document.getElementById(
            "WelcomePopupStoreName"
        );
        const welcomeBtn = document.querySelector(".Header-WelcomeWrapper");

        welcomeBtn.addEventListener(
            "click",
            this.setIsWelcomeOpen.bind(this, false)
        );
        welcomeStoreNameElem && (welcomeStoreNameElem.innerHTML = name);

        this.setState({ isWelcomeCMSLoaded: true });
    }

    setIsWelcomeOpen(isWelcomeOpen) {
        const { updateIsWelcomeOpen } = this.props;
        updateIsWelcomeOpen(isWelcomeOpen);
    }

    setIsStoresModalOpen(isStoresModalOpen) {
        this.setState({ isStoresModalOpen });
    }

    closeModalOnMobile() {
        const {
            device: { isMobile },
        } = this.props;
        const { isStoresModalOpen } = this.state;

        if (isMobile && isStoresModalOpen) {
            this.setIsStoresModalOpen(false);
        }
    }

    hideSearchOnStateChange(prevProps) {
        const {
            navigationState: { name: prevName },
        } = prevProps;
        const {
            navigationState: { name },
        } = this.props;
        const { isSearchWindowOpened } = this.state;

        if (prevName === SEARCH && prevName !== name && !isSearchWindowOpened) {
            this.hideSearchOverlay();
        }
    }

    onLocationClick(isOpen) {
        const {
            showPopup,
            closestStore: { pickup_location_code } = {},
            device: { isMobile },
        } = this.props;

        const { isStoresModalOpen, isLocationPopupOpen } = this.state;

        const isDefaultStoreAssigned =
            pickup_location_code === DEFAULT_LOCATION_CODE;

        if (isDefaultStoreAssigned) {
            showPopup(CLOSEST_STORE_INFO);
            this.onLocationPopupAction(true);
            return;
        }

        if (isMobile) {
            showPopup(HEADER_CLOSEST_STORE_POPUP);
        }

        if (isLocationPopupOpen) {
            return;
        }

        if (isOpen) {
            this.setIsStoresModalOpen(isOpen);
            return;
        }

        this.setIsStoresModalOpen(!isStoresModalOpen);
    }

    onLocationPopupAction(isLocationPopupOpen) {
        this.setState({ isLocationPopupOpen });
    }

    onMinicartButtonClick() {
        const {
            showOverlay,
            navigationState: { name },
        } = this.props;

        if (name === CART_OVERLAY) {
            return;
        }

        freezeGlobalScrollModule.freezeScroll();
        this.setState({ shouldRenderCartOverlay: true });
        showOverlay(CART_OVERLAY);
    }

    updateTypePage = () => {
        const { locations } = this.state;
        const { storeList = [] } = this.props;

        if (storeList.length === 0) {
            return;
        }

        const storeCodes = storeList.map(({ code }) => code);
        const currentUrl = getCurrentUrl(storeCodes, location.pathname);

        if (location.pathname !== locations) {
            this.getTypePage(currentUrl);
            this.setState({ locations: location.pathname });
        }
    };

    getAllCategory() {
        const { updateAllCategory } = this.props;
        const options = {
            categoryIds: 2,
            isSearchPage: false,
        };

        executeGet(
            prepareQuery(Category.getAllCategories(options)),
            "Category",
            ONE_MONTH_IN_SECONDS
        ).then(
            /** @namespace AvrilPwa-App/Component/Header/Container/fetchQuery/then */
            (result) => {
                const { category: { children = [] } = {} } = result || {};

                const allCategory = children.sort(
                    (a, b) => a.position - b.position
                );
                updateAllCategory(allCategory);
            }
        );
    }

    getTypePage(urlParam) {
        fetchQuery(UrlRewritesQuery.isEcom({ urlParam })).then(
            /** @namespace AvrilPwa-App/Component/Header/Container/fetchQuery/then */
            (result) => {
                this.setState({
                    isEcom: !result.urlResolver
                        ? urlParam.search("search") === 1
                        : result.urlResolver.ecom,
                });
            }
        );
    }

    containerProps = () => {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            device,
        } = this.props;

        const { isClearEnabled, searchCriteria, showMyAccountLogin } =
            this.state;

        const {
            location: { pathname },
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            showMyAccountLogin,
            device,
            searchTypes: this.searchTypes,
            menuPromotionRef: this.menuPromotionRef,
            searchCategoriesSectionRef: this.searchCategoriesSectionRef,
            searchProductsScrollableRef: this.searchProductsScrollableRef,
            searchWindowRef: this.searchWindowRef,
        };
    };

    handleMobileMenuButtonClick() {
        const { device } = this.props;
        const { showMobileMenu } = this.state;
        if (device.isMobile) {
            if (!showMobileMenu) {
                freezeGlobalScrollModule.freezeScroll();
            } else {
                freezeGlobalScrollModule.unfreezeScroll();
            }
        }

        this.setState((prevState) => ({
            showMobileMenu: !prevState.showMobileMenu,
        }));
    }

    closeMobileMenu() {
        const { device } = this.props;
        if (device.isMobile) {
            freezeGlobalScrollModule.unfreezeScroll();
        }
        this.setState({ showMobileMenu: false });
    }

    closeDisclaimer() {
        const { updateIsDisclaimerHidden } = this.props;

        CSS.setVariable(this.menuPromotionRef, "menu-promotion-height", "0px");
        updateIsDisclaimerHidden(true);

        // Creates or updates the cookie with an expiration date of 5:00 AM EST the next day
        const cookies = new Cookies();
        // Gets the current date and time in UTC
        const now = new Date();
        // Sets the time zone to Canadian Standard Time (EST - Eastern Standard Time, UTC-5)
        now.setUTCHours(now.getUTCHours() - 5);
        const tomorrow5AM = new Date(now);
        tomorrow5AM.setDate(tomorrow5AM.getDate() + 1);
        tomorrow5AM.setHours(5, 0, 0, 0);
        cookies.set('disclaimer', 'hidden', { expires: tomorrow5AM });

    }

    openDisclaimer() {
        const { updateIsDisclaimerHidden } = this.props;

        CSS.setVariable(this.menuPromotionRef, "menu-promotion-height", "44px");
        updateIsDisclaimerHidden(false);
    }

    onSignOut() {
        const { toggleOverlayByKey, logout } = this.props;
        logout();
        toggleOverlayByKey(CUSTOMER_ACCOUNT);
    }

    onMyAccountButtonClick(url, toRegistration = false) {
        const { isSignedIn } = this.props;

        if (isSignedIn && !isSignedInWithToken()) {
            return;
        }
        if (isSignedInWithToken()) {
            history.push({
                pathname: appendWithStoreCode(`/my-account/${url}`),
            });
            return;
        }

        if (toRegistration) {
            history.push({ pathname: appendWithStoreCode("/registration") });
            return;
        }

        history.push({ pathname: appendWithStoreCode("/login") });
    }

    onMinicartOutsideClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay,
            navigationState: { name },
        } = this.props;

        const { shouldRenderCartOverlay } = this.state;

        if (name !== CART_OVERLAY && !shouldRenderCartOverlay) {
            return;
        }

        freezeGlobalScrollModule.unfreezeScroll();
        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay, isSearchInputClicked } =
            this.props;

        if (isSearchInputClicked) {
            return;
        }

        this.setSearchCriteria("");

        document.activeElement.blur();

        if (activeOverlay === SEARCH) {
            hideActiveOverlay();
        }
    }

    onBackButtonClick(e) {
        const {
            navigationState: { onBackClick },
        } = this.props;

        this.setSearchCriteria("");

        if (onBackClick) {
            onBackClick(e);
        }
    }

    onCloseButtonClick(e) {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;
        const {
            navigationState: { onCloseClick },
        } = this.props;

        this.setSearchCriteria("");

        if (onCloseClick) {
            onCloseClick(e);
        }

        hideActiveOverlay();
        goToPreviousNavigationState();
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setSearchCriteria(searchCriteria);
    }

    onClearSearchButtonClick() {
        this.setSearchCriteria("");
    }

    setSearchCriteria(searchCriteria) {
        this.setState({ searchCriteria });

        if (searchCriteria) {
            this.getSearchResults(searchCriteria);
        }
    }

    getSearchResults(searchCriteria) {
        const { activeType } = this.state;
        const { updateIsXSearchLoading } = this.props;

        if (!searchCriteria.trim()) {
            return;
        }

        updateIsXSearchLoading(true);

        switch(activeType) {
            case BLOG_TYPE:
                this.getBlogSearchPostsQuery(searchCriteria);
                break;
            case RECIPE_TYPE:
                this.getRecipesSearchPostsQuery(searchCriteria);
                break;
            default:
                this.getSearchResultsQuery(searchCriteria);
        }
    }



    render() {
        return (
            <Header
                {...this.props}
                {...this.state}
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
