/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-named-export */
/* eslint-disable curly */
/* eslint-disable prefer-object-spread */
/* eslint-disable simple-import-sort/sort */
/* eslint-disable no-restricted-globals */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { roundPrice } from 'Util/Price';
import GoogleTagManager, { EVENT_GENERAL } from '../GoogleTagManager.component';

export const PRODUCT_COLOR = 'item_color';
export const GROUPED_PRODUCT_PRICE = 'grouped_product_price';
export const PRODUCT_SIZE = 'item_variant';
export const PRODUCT_TYPE = 'item_type';
export const PRODUCT_VARIANT_SKU = 'product_variant_sku';

export const NOT_APPLICABLE = 'N/A';

/**
 * Product helper, contain all related to product data prepare methods
 */
export class Product {
    static DEFAULT_BRAND = 'Avril';

    /**
     * Get product listing category string
     *
     * @param product
     * @return {string|string}
     */
    // eslint-disable-next-line no-unused-vars
    static getList() {
        const meta = GoogleTagManager.getEvent(EVENT_GENERAL).currentMeta.metaObject || {};

        return meta.name
            || meta.title
            || document.title.split('|').pop()
            || '';
    }

    /**
     * Get product Quantity from product object
     *
     * @param product
     * @return {number|string}
     */
    static getQuantity({ qty }) {
        return parseInt(qty, 10) || 0;
    }

    /**
     * Get product brand from product object
     *
     * @return {string|string}
     * @param selectedVariant
     */
    static getBrand(selectedVariant) {
        const { attributes = {} } = selectedVariant;
        const brandId = attributes?.brands && attributes.brands?.attribute_value;
        const label = brandId && attributes.brands.attribute_options[brandId].label ? attributes.brands.attribute_options[brandId].label : 'null';
        return label;
    }

    static getSelectedVariant(product) {
        const { sku, variants } = product;
        return variants.find(({ sku: variantSku }) => sku === variantSku);
    }

    static getSelectedVariantIndex(product, sku) {
        const { variants = [] } = product;
        return variants.findIndex(({ sku: variantSku = '' }) => sku === variantSku);
    }

    /**
     * Get product sku
     *
     * @param product
     * @return {string}
     */
    static getSku(product) {
        const { variants = [], configurableVariantIndex = -1 } = product;
        const { sku = '' } = variants[configurableVariantIndex] || product;
        return sku;
    }

    /**
     * Get item data as object
     *
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, list: string, category: string, brand: string}}
     * @param item
     * @param isVariantPassed
     */
    static getItemData(item, isVariantPassed = false) {
        if (item && Object.values(item).length) {
            const { product = {}, sku = '' } = item;
            const configurableVariantIndex = this.getSelectedVariantIndex(product, sku);
            return this.getProductData({ ...product, configurableVariantIndex }, isVariantPassed);
        }

        return {};
    }

    static getPrice(variant, type_id) {
        const {
            price_range: {
                minimum_price: {
                    regular_price: {
                        value = 0
                    } = {}
                } = {},
            } = {}
        } = variant;

        return type_id !== 'grouped'
            ? +roundPrice(value) || 0
            : 0;
    }

    static getDiscount(variant, type_id) {
        const {
            price_range: {
                minimum_price: {
                    discount: {
                        amount_off = 0
                    } = {}
                } = {},
            } = {}
        } = variant;

        return type_id !== 'grouped'
            ? +roundPrice(amount_off) || 0
            : 0;
    }

    /**
     * @param groupedProductData
     * @param product
     * @param groupedProductPrice
     */
    static addGroupedProduct(groupedProductData, product, groupedProductPrice) {
        const GTMInstance = GoogleTagManager.getInstance();
        const groupedProducts = GTMInstance.getGroupedProducts();
        const { sku, items } = product;
        const existingGroupedProduct = groupedProducts[sku];

        if (existingGroupedProduct) {
            const { data: { [GROUPED_PRODUCT_PRICE]: oldPrice } } = existingGroupedProduct;
            groupedProducts[sku].data[GROUPED_PRODUCT_PRICE] = groupedProductPrice + oldPrice;
        } else {
            groupedProducts[sku] = {
                data: groupedProductData,
                items: this.getArrayOfGroupedProductChildrenSku(items)
            };
        }

        GTMInstance.setGroupedProducts(groupedProducts);
    }

    static getArrayOfGroupedProductChildrenSku(items) {
        return items.reduce((acc, { product: { sku } }) => [...acc, sku], []);
    }

    static updateGroupedProduct(childSku, price) {
        const GTMInstance = GoogleTagManager.getInstance();
        const groupedProducts = GTMInstance.getGroupedProducts();
        const skuOfProductToUpdate = Object.keys(groupedProducts).find((sku) => {
            const { items } = groupedProducts[sku];
            return items.includes(childSku);
        });

        if (skuOfProductToUpdate) {
            const { [GROUPED_PRODUCT_PRICE]: prevPrice } = groupedProducts[skuOfProductToUpdate].data;

            // 0 price metric form grouped product indicates that no more children products are left in cart
            if (prevPrice + price === 0) {
                const productToDelete = groupedProducts[skuOfProductToUpdate];
                // eslint-disable-next-line fp/no-delete
                delete groupedProducts[skuOfProductToUpdate];

                GTMInstance.setGroupedProducts(groupedProducts);
                return productToDelete;
            }

            groupedProducts[skuOfProductToUpdate].data[GROUPED_PRODUCT_PRICE] += price;
            GTMInstance.setGroupedProducts(groupedProducts);
        }

        return null;
    }

    static mergeGroupedProducts(groupedProducts1, groupedProducts2) {
        if (!groupedProducts1) {
            return groupedProducts2;
        }
        if (!groupedProducts2) {
            return groupedProducts1;
        }
        const result = { ...groupedProducts2 };
        Object.keys(groupedProducts1).forEach((key) => {
            if (groupedProducts2[key]) {
                result[key].data[GROUPED_PRODUCT_PRICE] += groupedProducts1[key].data[GROUPED_PRODUCT_PRICE];
            } else {
                result[key] = groupedProducts1[key];
            }
        });

        return result;
    }

    /**
     * varian: color
     * dimension1: size
     * dimension2: simple/grouped
     * dimension3: variantSKU
     * metric1: total for grouped product
     */

    static getVariantSku(sku, variantSku, isVariantPassed) {
        return (variantSku === sku && !isVariantPassed)
            ? NOT_APPLICABLE
            : variantSku;
    }

    static getGroupedProductPrice(product) {
        const { groupedProductPrice = 0 } = product;
        return groupedProductPrice;
    }

    static getAttribute(variant, parentAttributes, attributeName, isSimpleVariant = false) {
        let attribute_value = '';
        let attribute_options = {};
        if (isSimpleVariant) {
            attribute_value = variant[attributeName] || '';
            attribute_options = parentAttributes[attributeName] || {};
        } else {
          const attributeObj = variant?.attributes[attributeName];
          if (attributeObj) {
                attribute_value = attributeObj.attribute_value || '';
                attribute_options = attributeObj.attribute_options || {};
                if (attribute_options) {
                    for (const optionValue in attribute_options) {
                        if (optionValue === attribute_value) {
                            attribute_value = attribute_options[optionValue].label || '';
                            break;
                        }
                    }
                }
            }
        }
        return attribute_value || NOT_APPLICABLE;
    }

    /**
     * Get product data as object
     *
     * @param product
     *
     * @param isVariantPassed
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, list: string, category: string, brand: string, discount: number}}
     */
    static getProductData(product, isVariantPassed = false) {
        const {
            sku,
            name,
            brand,
            type_id,
            category = NOT_APPLICABLE,
            variants = [],
            categories = [],
            attributes = {},
            configurableVariantIndex = this.getSelectedVariantIndex(product, sku)
        } = product;
        const selectedVariant = variants[configurableVariantIndex] || product;
        const { sku: variantSku } = selectedVariant;

        const categoryNames = categories.map((category) => category.name);
        const categoryValues = categoryNames.slice(0, 4);
        const categoryKeys = ['item_category', 'item_category2', 'item_category3', 'item_category4'];

        const categoryObject = categoryKeys.reduce((acc, key, index) => {
            acc[key] = categoryValues[index] || NOT_APPLICABLE;
            return acc;
        }, {});

        return {
            item_id: sku,
            item_name: name,
            discount: this.getDiscount(selectedVariant, type_id),
            price: this.getPrice(selectedVariant, type_id),
            item_brand: brand || this.getBrand(selectedVariant) || this.DEFAULT_BRAND,
            ...categoryObject,
            [PRODUCT_COLOR]: this.getAttribute(selectedVariant, attributes, 'color', isVariantPassed),
            [PRODUCT_SIZE]: this.getAttribute(selectedVariant, attributes, 'size', isVariantPassed),
            [PRODUCT_TYPE]: type_id,
            [PRODUCT_VARIANT_SKU]: this.getVariantSku(sku, variantSku, isVariantPassed),
            [GROUPED_PRODUCT_PRICE]: this.getGroupedProductPrice(product)
        };
    }
}

export default Product;
