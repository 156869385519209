/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';

export class AmastyQuery {
    getAmastyAnalyticstColletField(telemetry) {
        const mutation = new Field('xsearchAnalyticsCollect')
            .addArgument('telemetry', 'String!', telemetry)

            if (!isSignedIn()) {
                const quoteId = BrowserDatabase.getItem(GUEST_QUOTE_ID);
                mutation.addArgument('guest_quote_id', 'String', quoteId);
            }

            return mutation;
    }
}

export default new AmastyQuery();
