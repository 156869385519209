/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getCheckoutFlowFromClosestStore } from 'Util/Checkout';

import {
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS,
    UPDATE_CHECKOUT_FLOW,
    UPDATE_SHIPPING_LOCATION,
    UPDATE_IS_TOO_FAR_FLOW,
    UPDATE_IS_EMAIL_AVAILABILITY_LOADING,
    UPDATE_LAST_ESTIMATE_SHIPPING_ADDRESS,
    UPDATE_ESTIMATE_SHIPPING_FIELDS,
} from './Checkout.action';

/** @namespace Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    shippingLocation: {},
    email: '',
    isEmailAvailable: true,
    isEmailAvailabilityLoading: false,
    checkoutFlow: getCheckoutFlowFromClosestStore(),
    // if the customer is 40 km far from closest store, or geolocation is off
    isTooFarFlow: false,
    lastShippingAddress: null,
    estimateShippingFields: {},
});

/** @namespace Store/Checkout/Reducer/checkoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };

    case UPDATE_EMAIL:
        const { email } = action;
        return {
            ...state,
            email
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;
        return {
            ...state,
            isEmailAvailable
        };

    case UPDATE_CHECKOUT_FLOW:
        const { checkoutFlow } = action;
        return {
            ...state,
            checkoutFlow
        };

    case UPDATE_SHIPPING_LOCATION:
        const { shippingLocation } = action;
        return {
            ...state,
            shippingLocation
        };

    case UPDATE_IS_TOO_FAR_FLOW:
        const { isTooFarFlow } = action;
        return {
            ...state,
            isTooFarFlow
        };

    case UPDATE_IS_EMAIL_AVAILABILITY_LOADING:
        const { isEmailAvailabilityLoading } = action;
        return {
            ...state,
            isEmailAvailabilityLoading
        };

    case UPDATE_LAST_ESTIMATE_SHIPPING_ADDRESS:
        const { lastShippingAddress } = action;
        return {
            ...state,
            lastShippingAddress
        };

    case UPDATE_ESTIMATE_SHIPPING_FIELDS:
        const { estimateShippingFields } = action;
        return {
            ...state,
            estimateShippingFields
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
