import { PureComponent } from "react";

import Overlay from "Component/Overlay";
import BlogCategoriesWidget from "Component/BlogCategoriesWidget";
import BlogTagsWidget from "Component/BlogTagsWidget";
import Link from "Component/Link";

import { appendWithStoreCode } from "Util/Url";

import { CategoryFilterOverlay as SourceCategoryFilterOverlay } from "Component/CategoryFilterOverlay/CategoryFilterOverlay.component";
import { ReactComponent as CloseIcon } from "Component/Header/icons/close.svg";
import { BLOG_MOBILE_NAV_OVERLAY } from "./BlogMobileNav.config";

import { isMobile as isMobileUtil } from "Util/Mobile";

import "Component/CategoryFilterOverlay/CategoryFilterOverlay.style";
import "./BlogMobileNav.style";

export class BlogMobileNav extends SourceCategoryFilterOverlay {
    renderFiltersMobileFooter() {
        const { isLandingLinkVisible, blogSettings: { search_engine_route } = {} } = this.props;

        if (!isLandingLinkVisible || !search_engine_route) {
            return null;
        }

        return (
            <div
                block="BlogMobileNav"
                elem="MobileFooter"
                mix={{ block: "CategoryFilterOverlay", elem: "MobileFooter" }}
            >
                <Link
                    mix={{ block: "BlogMobileNav", elem: "MobileFooterLink" }}
                    to={appendWithStoreCode(`/${ search_engine_route }`)}
                >
                    {__("View all")}
                </Link>
            </div>
        );
    }

    renderHeading() {
        const { onCloseButtonClick } = this.props;

        if (!(isMobileUtil.tablet() || isMobileUtil.any())) {
            return null;
        }

        return (
            <div block="CategoryFilterOverlay" elem="HeadingWrapper">
                <h2 block="CategoryFilterOverlay" elem="Heading">
                    {__("Categories")}
                </h2>
                <button
                    block="CategoryFilterOverlay"
                    elem="HeadingButton"
                    onClick={onCloseButtonClick}
                >
                    <CloseIcon />
                </button>
            </div>
        );
    }

    renderFiltersHeaderAndFooter() {
        return (
            <div>
                {this.renderHeading()}
                {this.renderFiltersMobileFooter()}
            </div>
        );
    }

    renderContent() {
        return (
            <div>
                <BlogCategoriesWidget />
                <BlogTagsWidget />
            </div>
        );
    }

    render() {
        const { isLandingLinkVisible } = this.props;

        if (!(isMobileUtil.tablet() || isMobileUtil.any())) {
            return null;
        }

        return (
            <>
                <Overlay
                    mix={{
                        block: "BlogMobileNav",
                        elem: "Overlay",
                        mods: { isWithoutFooter: !isLandingLinkVisible },
                        mix: { block: "CategoryFilterOverlay" },
                    }}
                    id={BLOG_MOBILE_NAV_OVERLAY}
                    isRenderInPortal={false}
                    isFrezeedOnTablet
                >
                    {this.renderFiltersHeaderAndFooter()}
                    <div
                        mix={{
                            block: "CategoryFilterOverlay",
                            elem: "Filters",
                        }}
                    >
                        {this.renderContent()}
                        {this.renderLoader()}
                    </div>
                </Overlay>
            </>
        );
    }
}

export default BlogMobileNav;
