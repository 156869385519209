import PropTypes from 'prop-types';
import { ChildrenType } from 'Type/Common';
import {CmsBlockContainer as SourceCmsBlockContainer } from 'SourceComponent/CmsBlock/CmsBlock.container';
import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import { loadCmsBlock } from 'Component/CmsBlock/CmsBlock.loader.js';

export class CmsBlockContainer extends SourceCmsBlockContainer {
    static propTypes = {
        identifier: PropTypes.string.isRequired,
        children : ChildrenType
    };

    static defaultProps = {
        children: []
    };

    componentDidUpdate(_, prevProps) {
        const { identifier } = this.props;
        const { identifier: prevIdentifier } = prevProps;

        if (prevIdentifier !== identifier) {
            this._getCmsBlock();
        }
    }

    _getCmsBlock() {
        const { identifier, setIsLoading, onLoad } = this.props;


        if (!!setIsLoading) {
            setIsLoading(true);
        }

        loadCmsBlock(this, identifier, (isComplete) => {
            if (!isComplete && !!setIsLoading) {
                setIsLoading(false);
            }

            if (isComplete && !!onLoad) {
                onLoad();
            }
        });
    }

    render() {
        const { children } = this.props;
        return (
            <CmsBlock
                { ...this.containerProps() }
                children={ children }
            />
        );
    }
}

export default CmsBlockContainer;
