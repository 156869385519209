import {
    TYPE_BLOG_TAG,
    TYPE_BLOG_POST,
    TYPE_BLOG_LANDING,
    TYPE_BLOG_CATEGORY,
    RECIPE,
    RECIPE_CATEGORY,
    RECIPE_HOME
} from 'Route/UrlRewrites/UrlRewrites.config';
import { Breadcrumbs as SourceBreadcrumbs } from 'SourceComponent/Breadcrumbs/Breadcrumbs.component'
import { breadcrumbsToLowerC } from 'Util/Breadcrumbs';
import { appendWithStoreCode } from 'Util/Url';
import { isMobile as isMobileUtil } from 'Util/Mobile';
import Breadcrumb from 'Component/Breadcrumb';
import ContentWrapper from 'Component/ContentWrapper';
import './Breadcrumbs.override.style';

export class Breadcrumbs extends SourceBreadcrumbs {
    state = {
        isFullListRendered: false
    };

    componentDidUpdate(prevProps) {
        const { product: { id: prevId } } = prevProps;
        const { product: { id } } = this.props;

        if (id !== prevId) {
            this.setIsFullListRendered(false);
        }
    }

    shouldComponentUpdate(nextProps){
        const { navigation:{ navigationState: name }} = nextProps;
        if(name.name == "cart_overlay" || name.name == "search" ){
            return false
        }
        return true
    }

    setIsFullListRendered(isFullListRendered) {
        this.setState({ isFullListRendered });
    }

    renderBreadcrumb({ url, name, isActive }, i) {
        const { breadcrumbs, typePage } = this.props;
        const isDisabled = !url || breadcrumbs.length - 1 === i;
        const mix = {
            block: "Breadcrumbs",
            elem: "Breadcrumb",
            mods: { isRecipePage: typePage === RECIPE }
        };

        return (
            <Breadcrumb
              name={ name }
              url={ url }
              index={ i }
              key={ i }
              breadcrumbsLength={breadcrumbs.length}
              isDisabled={ isDisabled }
              isActive={ isActive }
              mix={ mix }
            />
        );
    }

    renderBreadcrumbList(breadcrumbs) {
        return breadcrumbs.map((_, i) => this.renderBreadcrumb(
            breadcrumbs[breadcrumbs.length - 1 - i], i
        ));
    }

    renderPdpMobileBreadcrumbList(breadcrumbs) {
        const { isFullListRendered } = this.state;
        const { device: { isMobile } } = this.props;

        if (isFullListRendered || !isMobile) {
            return this.renderBreadcrumbList(breadcrumbs);
        }

        const lastTwoBreadcrumbs = breadcrumbs.slice(1, 3);

        return (
            <>
                { this.renderShowMore() }
                { this.renderBreadcrumbList(lastTwoBreadcrumbs) }
            </>
        );
    }

    renderShowMore() {
        const { device: { isMobile }, breadcrumbs } = this.props;
        const { isFullListRendered } = this.state;

        if (
            isFullListRendered
            || !isMobile
            || !breadcrumbs
            || breadcrumbs.length === 0
        ) {
            return null;
        }

        return (
            <button
                block="Breadcrumb"
                mix={ { block: "Breadcrumb", elem: "ShowMore", mods: { isFullListRendered } } }
                onClick={ this.setIsFullListRendered.bind(this, true) }
            >
                ...
            </button>
        );
    }

    renderBreadcrumbs(breadcrumbs) {
        if (!breadcrumbs.length) {
            return this.renderBreadcrumb({}, 0)
        }

        return this.renderBreadcrumbList(breadcrumbs);
    }

    renderHomeBreadcrumb() {
        const { breadcrumbs } = this.props;
        if (!breadcrumbs || breadcrumbs.length === 0) {
            return null;
        }

        return this.renderBreadcrumb({ name: __('Home'), url: '/' });
    }

    render() {
        const {
            breadcrumbs,
            areBreadcrumbsVisible,
            isForceRendered,
            navigation: { navigationState: name },
            device: { isMobile },
            typePage,
            mix,
        } = this.props;
        const {
            isFullListRendered
        } = this.state;

        let breadCrumbsToRender = [ ...breadcrumbs ];

        const isCheckout = location.pathname.includes('/checkout');
        const isShippingStep = location.pathname.includes('/checkout/shipping');
        const isRecipesPage = [RECIPE_CATEGORY, RECIPE].includes(typePage);
        const isRecipesCategoryPage = typePage === RECIPE_CATEGORY;
        const isRecipesLandingPage = typePage === RECIPE_HOME;
        const isPdp = typePage === "PRODUCT";
        const isBlog = [
            TYPE_BLOG_LANDING,
            TYPE_BLOG_TAG,
            TYPE_BLOG_CATEGORY,
            TYPE_BLOG_POST
        ].includes(typePage) ||
            location.pathname.includes('amblog/post/preview/amblog_key');
        const isMobileBlog = isBlog && (isMobileUtil.tablet() || isMobileUtil.any());

        // isForceRendered is used to override areBreadcrumbsVisible
        const isVisible = isForceRendered || areBreadcrumbsVisible;

        if (
            !isVisible
            || location.pathname === appendWithStoreCode('/')
            || location.pathname === '/'
            || location.pathname === appendWithStoreCode('/checkout/success')
            || location.pathname === appendWithStoreCode('/ecom-home')
            || location.pathname === appendWithStoreCode('/en')
            || location.pathname === appendWithStoreCode('/fr')
            || typePage == "CMS_PAGE"
            || isRecipesCategoryPage && isMobile
            || isRecipesLandingPage
        ) {
            return null;
        }

        if (isPdp) {
            breadCrumbsToRender = breadcrumbsToLowerC(breadCrumbsToRender);
        }

        const isHomeBreadRendered = isMobile
            ? isFullListRendered || breadCrumbsToRender.length < 2
            : !!breadCrumbsToRender.length

        return (
            <ContentWrapper
              mix={ {
                block: 'Breadcrumbs',
                mods: { isCheckout, isShippingStep, isPdp, isBlog, isMobileBlog, isRecipesPage },
                mix:{ block: 'Breadcrumbs', elem: name.name, mix }} }
              label={ __('Breadcrumbs (current location)...') }>
                <nav aria-label="Breadcrumbs navigation">
                    <ul
                      block="Breadcrumbs"
                      elem="List"
                      mods={ { isFullListRendered: isFullListRendered || !isMobile } }
                      itemScope
                      itemType="http://schema.org/BreadcrumbList"
                    >
                        { this.renderShowMore() }
                        { isHomeBreadRendered ? this.renderBreadcrumb({ name: __('Home'), url: '/' }) : null }
                        { this.renderBreadcrumbs(breadCrumbsToRender) }
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default Breadcrumbs;