import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query.js';

import { Field } from 'Util/Query';

export class MyAccountQuery extends SourceMyAccountQuery {
    _getBalanceFields() {
        return [
            'value',
            'currency'
        ]
    }

     /**
     * Get ResetPassword mutation
     * @param {{token: String, password: String, password_confirmation: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getResetPasswordMutation(options) {
        const { customerId, token, password, password_confirmation } = options;
        return new Field('resetPasswordByToken')
            .addArgument('token', 'String!', token)
            .addArgument('customer_id', 'Int!', customerId)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addField('status');
    }

    _getCreditFields() {
        return new Field('current_balance')
        .addFieldList(this._getBalanceFields());
    }

    getUpdateInformationMutation(options) {
        return new Field('updateCustomerV2')
            .addArgument('input', 'CustomerUpdateInput!', options)
            .addField(this._getCustomerField());
    }

    getUpdateCustomerProfileMutation(options) {
        return new Field('updateCustomerV2')
            .addArgument('input', 'CustomerUpdateInput!', options)
            .addField(this._getCustomerField());
    }

    getUpdateEmailMutation(options) {
        return new Field('updateCustomerEmail')
            .addArgument('email', 'String!', options.email)
            .addArgument('password', 'String!', options.password)
            .addField(this._getCustomerField())
    }

    _getStoreCredit() {
        return new Field('store_credit')
            .addField(this._getCreditFields());
    }

    _getMarguerite() {
        return new Field('marguerite')
            .addFieldList(this._getMargueritesFields());
    }
    
    _getTransactionsItemsFields() {
        return [
            'transaction_id',
            'creation_date',
            'merchant_name',
            'amount',
            'points',
            'operation_id',
            'invoice_num',
            'label',
            'image'
        ]
    }

    _getTransactionsItems() {
        return new Field('items')
        .addFieldList(this._getTransactionsItemsFields())
    }

    _getTransactionsFields() {
        return new Field('transactions')
        .addField('year')
        .addField(this._getTransactionsItems())
    }

    getTransactions() {
        return new Field('paystoneTransactions')
            .addField('total_accumulated_points')
            .addField(this._getTransactionsFields())
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            "datacandy_card_number",
            'date_of_birth',
            'loyalty_newsletter',
            'avril_newsletter',
            'popup_newsletter',
            'interests',
            'profile_completed',
            'profile_completed_date',
            'onboarding_seen',
            'avril_newsletter',
            'loyalty_newsletter',
            'interests',
            "profile_completed",
            "profile_completed_date",
            this._getStoreCredit(),
            this._getAddressesField(),
            this._getMarguerite(),
        ];
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'company',
            this._getRegionField()
        ];
    }

    _getMargueritesFields() {
        return [
            'code',
            'completion',
            'amount_left',
            'nbr_completion',
            'nbr_completion_display',
            'updated_at',
            'title',
            'short_text',
            'long_text',
        ];
    }
    
    getCustomerInterests() {
        return new Field('customAttributeMetadata')
            .addArgument('attributes', '[AttributeInput!]!', [
                { attribute_code: 'interests', entity_type: 1 }
            ])
            .addField(this._getInterestsItems());
    }
    _getInterestsItems() {
        return new Field('items')
            .addField(this._getAttrOpts());
    }
    _getAttrOpts() {
        return new Field('attribute_options')
            .addFieldList([
                'label',
                'value'
            ]);
    }

    getCreateAccountMutation(options) {
        const { customer, password } = options;
        const isMember = !!customer.datacandy_card_number;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer, password, member: isMember })
            .addField(this._getCustomerCreateField());
    }

    _getCustomerCreateField() {
        return new Field('customer')
            .addFieldList(this._getCustomerCreateFields());
    }

    _getCustomerCreateFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            "datacandy_card_number",
            'date_of_birth',
            'loyalty_newsletter',
            'avril_newsletter',
            'popup_newsletter',
            'interests',
            'profile_completed',
            'profile_completed_date',
            'onboarding_seen',
            this._getStoreCredit(),
            this._getAddressesField(),
        ];
    }

    getCreateAddressMutation(options) {
        return new Field('createCustomerAddress')
            .addArgument('input', 'CustomerAddressInput!', options)
            .addFieldList(this._getAddressFields());
    }

    getCustomerInterests() {
        return new Field('customAttributeMetadata')
            .addArgument('attributes', '[AttributeInput!]!', [
                { attribute_code: 'interests', entity_type: 1 }
            ])
            .addField(this._getInterestsItems());
    }
    _getInterestsItems() {
        return new Field('items')
            .addField(this._getAttrOpts());
    }
    _getAttrOpts() {
        return new Field('attribute_options')
            .addFieldList([
                'label',
                'value'
            ]);
    }
    
}

export default new MyAccountQuery();
