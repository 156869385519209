import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import { getRegionCode } from 'Util/Checkout';

import HeaderClosestStore from './HeaderClosestStore.component';

import { CLOSEST_STORE_INFO } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';

export const mapStateToProps = (state) => ({
    pickupLocations: state.ConfigReducer.pickupLocations,
    closestStore: state.ConfigReducer.getClosestStore,
    countries: state.ConfigReducer.countries,
    device: state.ConfigReducer.device,
    isLoading: state.ConfigReducer.isLoading,
    storeCode: state.ConfigReducer.code
});

export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(CLOSEST_STORE_INFO, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

export class HeaderClosestStoreContainer extends PureComponent {
    containerProps = () => {
        const {
            pickupLocations,
            closestStore = {},
            isStoresModalOpen,
            onLocationClick,
            hideActiveOverlay,
            onLocationPopupAction,
            countries,
            isLocationPopupOpen,
            isLoading,
            storeCode,
            device: { isMobile }
        } = this.props;

        const { country_id, region_id } = closestStore;
        const regionCode = getRegionCode(region_id, country_id, countries);

        return {
            pickupLocations,
            isStoresModalOpen,
            onLocationClick,
            onLocationPopupAction,
            hideActiveOverlay,
            isLocationPopupOpen,
            isMobile,
            isLoading,
            storeCode,
            closestStore: {
                ...closestStore,
                regionCode
            }
        };
    }

    containerFunctions = {
        onChangeStoreClick: this.onChangeStoreClick.bind(this)
    }

    onChangeStoreClick() {
        const { showPopup, onLocationPopupAction, setIsStoresModalOpen } = this.props;

        onLocationPopupAction(true);
        showPopup({});
        setIsStoresModalOpen(false);
    }

    render() {
        return <HeaderClosestStore
          { ...this.containerProps() }
          { ...this.containerFunctions }
        />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderClosestStoreContainer);
