import amastyCollector from '../util/AmastySearchCollector';

const componentDidMount = (args, callback, instance) => {
    const { product: { id } = {} } = instance.props;

    if (id) {
        amastyCollector.onPDPEnter(id);
    }

    return callback(...args);
};

const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const {
        areDetailsLoaded: prevAreDetailsLoaded,
        location: { pathname: prevPathname }
    } = prevProps;
    const {
        areDetailsLoaded,
        product: { id } = {},
        location: { pathname }
    } = instance.props;

    const shouldCollectData = areDetailsLoaded
        && (areDetailsLoaded !== prevAreDetailsLoaded)
        || (pathname !== prevPathname);

    if (shouldCollectData && id) {
        amastyCollector.onPDPEnter(id);
    }

    return callback(...args);
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            'componentDidMount': componentDidMount,
            'componentDidUpdate': componentDidUpdate
        }
    }
};
