import { PureComponent } from 'react';

import { getDistanceString } from 'Util/Stores';

import { ReactComponent as DistanceIcon } from './icons/distance_icon.svg';

import './PickupDistance.style';

export class PickupDistance extends PureComponent {
    render() {
        const {
            distance,
            mix
        } = this.props;

        if (!distance) {
            return null;
        }

        const distanceText = getDistanceString(distance);

        return (
            <div
              block="PickupDistance"
              mix={ mix }
            >
                <p>{ `${ distanceText } km` }</p>
                { <span><DistanceIcon /></span> }
            </div>
        );
    }
}

export default PickupDistance;
