/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { formatPrice } from 'Util/Price';
import {
    CheckoutDeliveryOption as SourceCheckoutDeliveryOption
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';

import './CheckoutDeliveryOption.extended.style';

export class CheckoutDeliveryOption extends SourceCheckoutDeliveryOption {
    renderDefaultRow() {
        const {
            option: {
                method_title,
                carrier_title
            },
            isSelected,
            isPickupOverlay
        } = this.props;

        return (
            <div block="CheckoutDeliveryOption" elem="Row" mods={ { isPickupOverlay } }>
                <div
                  block="CheckoutDeliveryOption"
                  elem="Radio"
                  mix={ { block: "Button", elem: "Radio", mods: { isSelected } } }
                />
                <div block="CheckoutDeliveryOption" elem="CarrierTitleWrapper">
                    <p
                        block="CheckoutDeliveryOption"
                        elem="CarrierTitle"
                    >
                        { carrier_title }
                    </p>
                    <span block="CheckoutDeliveryOption" elem="Colon" mods={ { isPickupOverlay } }>:</span>
                    <p
                        block="CheckoutDeliveryOption"
                        elem="CarrierMethod"
                    >
                        { method_title }
                    </p>
                </div>
                { this.renderFreeText() }
                { this.renderPrice() }
            </div>
        );
    }

    renderFreeText() {
        const { option: { price_incl_tax } } = this.props;

        const isFree = price_incl_tax == 0;

        if (!isFree) {
            return null;
        }

        return (
            <p
              block="CheckoutDeliveryOption"
              elem="FreeText"
            >
                <strong>
                    { __('Free') }
                </strong>
            </p>
        );
    }

    renderErrorRow() {
        const {
            option: {
                carrier_title,
                error_message
            }
        } = this.props;
        return (
            <li block="CheckoutDeliveryOption">
                <div block="CheckoutDeliveryOption" elem="Row">
                    <div block="CheckoutDeliveryOption" elem="CarrierTitleWrapper">
                        <p
                            block="CheckoutDeliveryOption"
                            elem="CarrierTitleError"
                        >
                            { carrier_title }
                        </p>
                        <p
                            block="CheckoutDeliveryOption"
                            elem="ErrorMessage"
                        >
                            { error_message }
                        </p>
                    </div>
                </div>
            </li>
        );
    }

    renderRow() {
        return this.renderDefaultRow();
    }

    renderPrice() {
        const {
            storeCode,
            option: { price_incl_tax }
        } = this.props;

        const formattedPrice = formatPrice(price_incl_tax, storeCode);
        const isFree = price_incl_tax == 0;

        if (isFree) {
            return null;
        }

        return (
            <div
              block="CheckoutDeliveryOption"
              elem="Price"
            >
                <strong>
                    { `${ formattedPrice }` }
                </strong>
            </div>
        );
    }

    render() {
        const {
            isSelected,
            option: {
                available
            }
        } = this.props;
        if (!available) return this.renderErrorRow();
        return (
            <li block="CheckoutDeliveryOption">
                <button
                  block="CheckoutDeliveryOption"
                  mods={ { isSelected } }
                  elem="Button"
                  onClick={ this.onClick }
                  type="button"
                >
                    { this.renderRow() }
                </button>
            </li>
        );
    }

}

export default CheckoutDeliveryOption;
