/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { formatPrice, roundPrice } from 'Util/Price';

import {
    CartItemPrice as SourceCartItemPrice
} from 'SourceComponent/CartItemPrice/CartItemPrice.component';

/** @namespace Component/CartItemPrice/Component */
export class CartItemPrice extends SourceCartItemPrice {
    render() {
        const { row_total, mix, storeCode } = this.props;
        const price = roundPrice(row_total);

        return (
            <p block="ProductPrice" aria-label={ __('Product Price') } mix={ mix }>
                <span aria-label={ __('Current product price') }>
                    <data value={ price }>{ `${formatPrice(row_total, storeCode)}` }</data>
                </span>
            </p>
        );
    }
}

export default CartItemPrice;
