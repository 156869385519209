import { connect } from 'react-redux';
import { PureComponent } from 'react';
import SitemapPage from './SitemapPage.component'
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

import Sitemap from 'Query/Sitemap.query';
import { fetchQuery } from 'Util/Request';

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => ({
    toggleBreadCrumbs: (areBreadcrumbsVisible) => dispatch(toggleBreadcrumbs(areBreadcrumbsVisible))
});

export class SitemapPageContainer extends PureComponent {

    state = { cms: [], category: [] }

    componentDidMount() {
        const { toggleBreadCrumbs } = this.props
        toggleBreadCrumbs(false)
        fetchQuery(Sitemap.getSitemap()).then(
            (result) => {
                const cmsData = result.getSitemap.cms
                const categoryData = result.getSitemap.category
                this.setState({ cms: cmsData, category: categoryData })
            },
            (err) => console.error(err)
        );
    }

    render() {
        return (
            <SitemapPage
                {...this.props}
                {...this.state}
            />
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SitemapPageContainer);
