/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';
import CartQuery from 'Query/Cart.query';
import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';

/** @namespace Query/Checkout */
export class CheckoutQuery extends SourceCheckoutQuery {
    getEstimateShippingField(countryID, regionID, postcode, cartId) {
        const query = new Field('estimateShipping')
            .addArgument('country_id', 'String!', countryID)
            .addArgument('region_id', 'String!', regionID)
            .addArgument('postcode', 'String!', postcode)
            .addArgument('cart_id', 'String!', cartId)
            .addFieldList(this._getEstimatedShippingFields());

        return query;
    }

    // get the timestamp for Global payments
    getRealexTimestamp() {
        return new Field('storeConfig')
            .addField(this.getRealexTimestampField());
    }

    getRealexTimestampField() {
        return new Field('realexpayments_hpp')
            .addField('timestamp');
    }

    getApplyRewardPointsMutation(cartId) {
        const mutation = new Field('applyRewardPointsToCart')
            .addArgument('cartId', 'ID!', cartId)
            .addField(this.getCartRewardField());

        return mutation;
    }

    getRemoveRewardPointsMutation(cartId) {
        const mutation = new Field('removeRewardPointsFromCart')
            .addArgument('cartId', 'ID!', cartId)
            .addField(this.getCartRewardField());

        return mutation;
    }

    getCartRewardField() {
        return new Field('cart')
            .addField(this.getAppliedRewardsField());
    }

    getAppliedRewardsField() {
        return new Field('applied_reward_points')
            .addFieldList(CartQuery._getRewardBalanceFields());
    }
}



export default new CheckoutQuery();
