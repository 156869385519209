import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEFAULT_LOCATION_CODE } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';
import ContactFormQuery from 'Query/ContactForm.query';
import ContactUsForm from './ContactUsForm.component';
import { EN_STORE } from 'Util/Price/Price.config';
import { fetchMutation } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';

export const mapStateToProps = (state) => ({
    countries: state.ConfigReducer.countries,
    pickupLocations: state.ConfigReducer.pickupLocations,
    storeCode: state.ConfigReducer.code
});

export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export class ContactUsFormContainer extends PureComponent {
    state = {
        isSend: false,
        reasonOptions: [
            { id: 1, label: __('Question about a product'), value: __('Question about a product') },
            { id: 2, label: __('Question about an online order'), value: __('Question about an online order') },
            { id: 3, label: __('Question about shipping'), value: __('Question about shipping') },
            { id: 4, label: __('Question about the Marguerite program'), value: __('Question about the Marguerite program') },
            { id: 6, label: __('General comment'), value: __('General comment') },
            { id: 7, label: __('Website issue'), value: __('Website issue') }
        ],
        storeOptions: []
    }

    componentDidMount(){
        this.setState({isSend:false})
    }

    static getDerivedStateFromProps(props) {
        const {
            storeCode,
            pickupLocations: { items } = {}
        } = props;

        if (!items) {
            return [];
        }

        function sortOptions(a) {
            if (a.pickup_location_code === DEFAULT_LOCATION_CODE) {
                return -1;
            }

            return 0;
        }

        function mapOptions({ name, pickup_location_code }, id) {
            return {
                id,
                pickup_location_code,
                label: pickup_location_code === DEFAULT_LOCATION_CODE
                    ? __("Website").toString()
                    : storeCode === EN_STORE ? name.replaceAll('é', 'e') : name,
                value: storeCode === EN_STORE ? name.replaceAll('é', 'e') : name,
                keepPosition: pickup_location_code === DEFAULT_LOCATION_CODE
            }
        }

        const storeOptions = items
            .map(mapOptions)
            .sort(sortOptions);

        return {
            storeOptions
        };
    }

    sendMessage(options){
        const { showNotification } = this.props

        fetchMutation(
            ContactFormQuery.submitContactForm(options)
        ).then(
                (data) => {
                    const { contactForm: { message } = {} } = data;
                    showNotification('success', message);
                    this.setState({ isSend: true });
                },
                (error) => {
                    showNotification('error', error[0].message);
                }
            );
    }

   render(){
      return(
        <ContactUsForm
            { ...this.props }
            {...this.state}
            sendMessage={this.sendMessage.bind(this)}
        />
      )
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsFormContainer);
