/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    mapStateToProps,
    mapDispatchToProps,
    NewVersionPopupContainer as SourceNewVersionPopupContainer
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';

/** @namespace Component/NewVersionPopup/Container */
export class NewVersionPopupContainer extends SourceNewVersionPopupContainer {
    // rewritten to avoid opeining popup
    componentDidMount() {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);
