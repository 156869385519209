import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateSelectedShippingMethod } from 'Store/Cart/Cart.action';

import { PICKUP_OVERLAY_KEY } from 'Component/PickupOverlay/PickupOverlay.config';

import PickupLocationForm from './PickupLocationForm.component';


export const mapStateToProps = (state) => ({
    countries: state.ConfigReducer.countries,
    default_country: state.ConfigReducer.default_country,
    addressLinesQty: state.ConfigReducer.address_lines_quantity,
    device: state.ConfigReducer.device,
    activeOverlay: state.OverlayReducer.activeOverlay,
    shippingLocation: state.CheckoutReducer.shippingLocation,
    cartTotals: state.CartReducer.cartTotals,
});

export const mapDispatchToProps = (dispatch) => ({
    updateSelectedShippingMethod: (selectedShippingMethod) => dispatch(updateSelectedShippingMethod(selectedShippingMethod)),
});

export class PickupLocationFormContainer extends PureComponent {
    componentDidMount() {
        window.addEventListener('keydown', this.onEnterPress.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onEnterPress.bind(this));
    }

    containerProps = () => {
        const {
            address,
            cartTotals,
            countries,
            isDelivery,
            default_country,
            addressLinesQty,
            onShippingEstimationFieldsChange,
            updateSelectedShippingMethod,
            isShippingAddressAllowed,
            isDeliveryOptionsLoading,
            shippingMethods,
            selectedShippingMethod,
            setLocationFormRegionId,
            updateEstimateShippingFields,
            shippingLocation,
            device: { isMobile }
        } = this.props;

        return {
            address,
            cartTotals,
            countries,
            isDelivery,
            isMobile,
            default_country,
            addressLinesQty,
            onShippingEstimationFieldsChange,
            updateSelectedShippingMethod,
            isShippingAddressAllowed,
            isDeliveryOptionsLoading,
            selectedShippingMethod,
            setLocationFormRegionId,
            updateEstimateShippingFields,
            shippingLocation,
            shippingMethods
        };
    };

    // rewrites 'enter' press behavior for pickup overlay.
    onEnterPress(e) {
        const { confirmButtonRef, activeOverlay } = this.props;
        const { keyCode } = e;

        if (activeOverlay !== PICKUP_OVERLAY_KEY) {
            return;
        }

        if (keyCode === 13 && confirmButtonRef.current) {
            e.preventDefault();
            if (confirmButtonRef.current) {
                confirmButtonRef.current.click();
            }
        }
    }

    render() {
        return (
            <PickupLocationForm
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupLocationFormContainer);
