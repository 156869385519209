/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ProductListQuery from "Query/ProductList.query";
import { Field } from "Util/Query";

/** @namespace AvrilPwa-App/Query/Blog/Query/BlogQuery */
export class BlogQuery {
    getAmBlogSettings() {
        return new Field("amBlogSetting").addFieldList([
            "list_count_per_page",
            "search_engine_bread",
            "search_engine_route",
            "search_engine_meta_title",
            "search_engine_meta_keywords",
            "search_engine_meta_description",
            "search_engine_title_suffix",
            "search_engine_title_prefix",
            "product_related_posts_tab_title",
            "post_related_products_block_title"
        ]);
    }

    getTotalPostsQuery(type = "ALL", entityId) {
        const blogPostsField = new Field("amBlogPosts")
            .addArgument("type", "AmBlogPageType", type)
            .addFieldList(["all_post_size"]);

        if (entityId) {
            blogPostsField.addArgument("entityId", "Int", entityId);
        }

        return blogPostsField;
    }

    getBlogPostsQuery(page, entityId, type = "ALL") {
        const blogPostsField = new Field("amBlogPosts")
            .addArgument("type", "AmBlogPageType", type)
            .addArgument("page", "Int", page)
            .addFieldList(["all_post_size", this._getPostItemsField()]);

        if (entityId) {
            blogPostsField.addArgument("entityId", "Int", entityId);
        }

        return blogPostsField;
    }

    getPostByIdQuery = (postId) => {
        return new Field("amBlogPost")
            .addArgument("id", "Int", postId)
            .addFieldList(this._getPostFieldList());
    };

    getPostsByIds(postIds) {
        return new Field("amBlogPostsByIds")
            .addArgument("ids", "[Int]", postIds)
            .addFieldList([this._getPostItemsField()]);
    }

    getPostsSearchQuery(query) {
        const blogPostsField = new Field("amBlogPostsSearch")
            .addArgument("query", "String", query)
            .addFieldList(["all_post_size", this._getPostItemsField()]);

        return blogPostsField;
    }

    getPostForPreviewQuery = (key) => {
        return new Field("amBlogPreview")
            .addArgument("amblog_key", "String", key)
            .addFieldList(this._getPostFieldList());
    };

    getBlogPostRelatedProducts(postId) {
        return new Field("amBlogPostRelatedProducts")
            .addArgument("postId", "Int!", postId)
            .addField(ProductListQuery._getItemsField());
    }

    getProductRelatedPosts(productId) {
        return new Field("amBlogRelatedPosts")
            .addArgument("productId", "Int!", productId)
            .addFieldList([this._getPostItemsField()]);
    }

    getBlogAuthorsQuery() {
        return new Field("amBlogAuthors").addField(
            this._getAuthorsItemsField()
        );
    }

    getBlogCategoriesQuery() {
        return new Field("amBlogCategories").addField(
            this._getBlogCategoriesItemsField()
        );
    }

    getBlogTagsWidgetQuery() {
        return new Field("amBlogTagsWidget").addFieldList([
            "title",
            this._getTagsItemsField(),
        ]);
    }

    _getBlogCategoriesItemsField() {
        return new Field("items").addFieldList([
            "category_id",
            "name",
            "level",
            "sort_order",
            "post_count",
            "parent_id",
            "status",
            "url",
            "description",
            "meta_tags",
            "meta_title",
            "meta_description",
        ]);
    }

    _getAuthorsItemsField() {
        return new Field("items").addFieldList(["author_id", "name"]);
    }

    _getTagsItemsField() {
        return new Field("items").addFieldList([
            "name",
            "tag_id",
            "url",
            "meta_tags",
            "meta_title",
            "meta_description",
        ]);
    }

    _getPostItemsField() {
        return new Field("items").addFieldList(this._getPostFieldList());
    }

    _getPostFieldList() {
        return [
            "post_id",
            "title",
            "author_id",
            "post_thumbnail",
            "post_thumbnail_alt",
            "post_thumbnail_mobile",
            "post_thumbnail_mobile_alt",
            "list_thumbnail",
            "list_thumbnail_alt",
            "categories",
            "full_content",
            "short_content",
            "tag_ids",
            "related_post_ids",
            "url",
            "published_at_text",
            "published_at",
            "meta_tags",
            "meta_title",
            "meta_description",
            "is_open_graph_enabled",
            "open_graph_meta_type",
            "open_graph_meta_title",
            "open_graph_meta_description",
        ];
    }
}

export default new BlogQuery();
