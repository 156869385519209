/* eslint-disable quote-props */
import Event, { EVENT_GTM_PRODUCT_DETAIL } from '../../util/Event';

import ProductListQuery from 'Query/ProductList.query';
import { fetchQuery } from 'Util/Request';

/** ProductPage */
export const _gtmProductDetail = (instance) => {
    const { product, location: { pathname }, configurableVariantIndex } = instance.props;
    if (product.sku) {
        const query = ProductListQuery.getGtmProductData(product.sku);
        fetchQuery(query).then(
            (product) => {
                const item = product?.products?.items[0];
                if(item) {
                    Event.dispatch(EVENT_GTM_PRODUCT_DETAIL, {
                        product: { ...item, configurableVariantIndex },
                        pathname
                    });
                }
            },
            (err) => console.log('err: ', err)
        );
    }
};

export const componentDidMount = (args, callback, instance) => {
    const { areDetailsLoaded } = instance.props;

    if (areDetailsLoaded) {
        _gtmProductDetail(instance);
    }

    return callback(...args);
};

export const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const shouldTriggerGtm = () => {
        const {
            areDetailsLoaded,
            location: { pathname }
        } = instance.props;

        const {
            areDetailsLoaded: prevAreDetailsLoaded,
            location: { pathname: prevPathname }
        } = prevProps;

        return areDetailsLoaded && (
            (areDetailsLoaded !== prevAreDetailsLoaded)
            || (pathname !== prevPathname)
        );
    };

    if (shouldTriggerGtm()) {
        _gtmProductDetail(instance);
    }

    return callback(...args);
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    }
};
