
export const PICKUP_OVERLAY_KEY = 'pickup_overlay';

export const HOME_DELIVERY_OPTION = 'home_delivery';
export const PICKUP_OPTION = 'pickup';

export const DELIVERY_STEP = 'deivery_step';
export const SCHEDULE_STEP = 'schedule_step';

export const TIMESLOT = 'timeslot';
export const CHECKOUT_FLOW = 'checkout_flow';

export const COMMENT_VALUE = 'comment_value';
