import { withRouter } from 'react-router-dom';
import ProductSlider from '../ProductSliders/ProductSlider.component'
import ProductCard from 'SourceComponent/ProductCard';
import { ProductListPage as SourceProductListPage } from "SourceComponent/ProductListPage/ProductListPage.component"
import { DEFAULT_PLACEHOLDER_COUNT } from './ProductListPage.config';
import './ProductListPage.style';

export class ProductListPage extends SourceProductListPage {
      static defaultProps = {
        numberOfPlaceholders: DEFAULT_PLACEHOLDER_COUNT,
        wrapperRef: () => {},
        selectedFilters: {},
        pageNumber: null,
        items: [],
        mix: {}
    };

    componentDidMount() {
        this.startObserving();
    }

    static getDerivedStateFromProps(props,state){
        const { items } = props;
       if(items.length !== state.items.length ){
           return {activeImage: 0,items:items}
       }
       return null
    }

    componentDidUpdate() {
        this.startObserving();
    }

    componentWillUnmount() {
        this.stopObserving();
    }
    onActiveImageChange = this.onActiveImageChange.bind(this);

    state = { activeImage: 0 , items:[]};

    onActiveImageChange(activeImage) {
        this.setState({ activeImage });
    }

    startObserving() {
        const {
            items,
            updatePages,
            isInfiniteLoaderEnabled
        } = this.props;

        if (!isInfiniteLoaderEnabled || items.length) {
            return;
        }

        if (this.node && !this.observer && 'IntersectionObserver' in window) {
            const options = {
                rootMargin: '0px',
                threshold: 0.1
            };

            this.observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                const { items, isLoading } = this.props;

                // must not be a product items list, and must not be loading
                if (intersectionRatio > 0 && !items.length && !isLoading) {
                    this.stopObserving();
                    updatePages();
                }
            }, options);

            this.observer.observe(this.node);
        }
    }

    stopObserving() {
        if (this.observer) {
            if (this.observer.unobserve && this.node) {
                this.observer.unobserve(this.node);
            }

            if (this.observer.disconnect) {
                this.observer.disconnect();
            }

            this.observer = null;
        }
    }

    renderPlaceholders() {
        const { numberOfPlaceholders } = this.props;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => (
                <ProductCard
                  key={ i }
                  product={ {} }
                />
            )
        );
    }

    getPlaceholderRef() {
        const { isVisible } = this.props;

        if (!isVisible) {
            return undefined;
        }

        return (node) => {
            this.node = node;
        };
    }

    renderPageItems() {
        const {
            items,
            selectedFilters,
            isQueryFetching
        } = this.props;
        return items.map((product, i) => (
            <ProductCard
              product={ product }
              isQueryFetching={ isQueryFetching }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
              selectedFilters={ selectedFilters }
            />
        ));
    }

    renderPlaceholderOffset() {
        const { isPlaceholderOffsetHidden = false } = this.props;

        if (isPlaceholderOffsetHidden) {
            return null;
        }

        return <li
          block="ProductListPage"
          elem="Offset"
          ref={ this.getPlaceholderRef() }
        />;
    }

    renderPlaceholderItems() {
        return (
            <>
                { this.renderPlaceholderOffset() }
                { this.renderPlaceholders() }
            </>
        );
    }

    renderItems() {
        const { items, isLoading } = this.props;

        if (!items.length || isLoading) {
            return this.renderPlaceholderItems();
        }

        return this.renderPageItems();
    }

    render() {
        const {
            pageNumber,
            wrapperRef,
            mix,
            items,
            isLoading
        } = this.props;
        const { activeImage } = this.state;
        if(mix.block === 'CategoryProductList'){
            if(!pageNumber && !isLoading){
                return null
            }
            return (
                <ul
                  block="ProductListPage"
                  mix={ { ...mix, elem: 'Page' } }
                  key={ pageNumber }
                  ref={ wrapperRef }
                > 
                { this.renderItems() }
                </ul>
            )
        }
        return (
            <ul
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              key={ pageNumber }
              ref={ wrapperRef }
            > 
            <ProductSlider
              activeImage={ activeImage }
              onActiveImageChange={ this.onActiveImageChange }
              slidesCount = {items.length}
            >
                    { this.renderItems() }
            </ProductSlider>  
            </ul>
        );
    }
}

export default withRouter(ProductListPage);
