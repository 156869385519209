import { PureComponent } from "react";

import Image from "Component/Image";
import Link from "Component/Link";
import TextPlaceholder from "Component/TextPlaceholder";
import BlogPostCategories from "Component/BlogPostCategories";

import "./BlogPost.style";

/** @namespace Component/BlogPost/Component */
export class BlogPost extends PureComponent {
	renderTextPlaceholder(length) {
		return <TextPlaceholder length={length} />;
	}

	renderListImage() {
		const { postData: { list_thumbnail, list_thumbnail_alt } = {} } =
			this.props;

		return (
			<Image
				src={list_thumbnail}
				alt={list_thumbnail_alt}
				mix={{ block: "BlogPost", elem: "Image" }}
			/>
		);
	}

	renderCategories() {
		const { postData: { categoriesData } = {} } = this.props;
		return <BlogPostCategories categories={categoriesData} />;
	}

	renderTitle() {
		const { postData: { title } = {} } = this.props;

		return (
			<h3 block="BlogPost" elem="PostTitle">
				{title}
			</h3>
		);
	}

	renderPublishDate() {
		const { postData: { published_at_text } = {} } = this.props;

		return (
			<div block="BlogPost" elem="PublishDate">
				<p>{published_at_text}</p>
			</div>
		);
	}

	renderPostCardPlaceholder() {
		return (
			<div block="BlogPost">
				<Image
					mix={{ block: "BlogPost", elem: "Image" }}
					isPlaceholder
				/>
				<div
					block="BlogPost"
					elem="PostData"
					mods={{ isPlaceholder: true }}
				>
					{new Array(3).fill(null).map(() => (
						<TextPlaceholder length="medium" />
					))}
				</div>
			</div>
		);
	}

	renderPost() {
		const {
			postData: { url },
		} = this.props;

		return (
			<Link to={url} mix={{ block: "BlogPost" }}>
				{this.renderListImage()}
				<div block="BlogPost" elem="PostData">
					<div>
						{this.renderCategories()}
						{this.renderTitle()}
					</div>
					{this.renderPublishDate()}
				</div>
			</Link>
		);
	}

	render() {
		const { isPlaceholder } = this.props;

		const renderFn = isPlaceholder
			? this.renderPostCardPlaceholder.bind(this)
			: this.renderPost.bind(this);

		return renderFn();
	}
}

export default BlogPost;
