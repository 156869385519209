/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import CheckoutDeliveryOption from './CheckoutDeliveryOption.component';
import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.container';

/** @namespace Component/CheckoutDeliveryOption/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    storeCode: state.ConfigReducer.code
});

/** @namespace Component/CheckoutDeliveryOption/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOption);
