/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import { store } from '../../store';

import {
    AUTH_TOKEN,
    deleteAuthorizationToken,
    getAuthorizationToken,
    isInitiallySignedIn,
} from 'SourceUtil/Auth/Token';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateCustomerIsAuthTokenExpired, updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';

import { debounce } from 'Util/Request';
import history from 'Util/History';
import { callOnceInTimeRange } from 'Util/Window';

export {
    AUTH_TOKEN,
    deleteAuthorizationToken,
    getAuthorizationToken,
    isInitiallySignedIn,
};

export const ONE_HOUR_IN_SECONDS = 3600;
export const ONE_HOUR = 1;
export const TOKEN_REFRESH_DELAY = 2000;

export const CUSTOMER = 'customer';

/** @namespace Util/Auth/setAuthorizationToken */
export const setAuthorizationToken = (token) => {
    if (!token) {
        return;
    }

    const state = store.getState();
    const {
        access_token_lifetime = ONE_HOUR
    } = state.ConfigReducer;

    BrowserDatabase.setItem(token, AUTH_TOKEN, access_token_lifetime * ONE_HOUR_IN_SECONDS);
};

/** @namespace Util/Auth/refreshAuthorizationToken */
export const refreshAuthorizationToken = debounce(
    () => setAuthorizationToken(getAuthorizationToken()),
    TOKEN_REFRESH_DELAY
);

/** @namespace Util/Auth/getInitialCustomer */
export const getInitialCustomer = () => BrowserDatabase.getItem(CUSTOMER) || {};

/** @namespace Util/Auth/onExpirationTokenLogout */
export const onExpirationTokenLogout = (() => {
    function onExpirationInnerFn() {
        history.push('/login');
        store.dispatch(
            updateCustomerIsAuthTokenExpired(false)
        );
        store.dispatch(
            showNotification('error', __('Your session is over, you are logged out!'))
        );
    }

    return callOnceInTimeRange(
        onExpirationInnerFn,
        5000
    );
})();

/** @namespace Util/Auth/asyncOnExpireTokenLogout */
export const asyncOnExpireTokenLogout = () => setTimeout(onExpirationTokenLogout);

/** @namespace Util/Auth/isSignedIn */
export const isSignedIn = (needUpdateQuote = true) => {
    const _isSignedIn = !!getAuthorizationToken();
    const {
        MyAccountReducer: {
            isSignedIn: isCustomerSignedIn = false,
        } = {},
    } = store.getState();

    if (!_isSignedIn && isCustomerSignedIn) {
        store.dispatch(updateCustomerSignInStatus(false));
        const MyAccountDispatcher = import('../../store/MyAccount/MyAccount.dispatcher');
        asyncOnExpireTokenLogout();
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.logout(true, store.dispatch, needUpdateQuote)
        );
    }

    return _isSignedIn;
}

/** @namespace Util/Auth/isSessionOver */
export const isSessionOver = () => {

    const {
        MyAccountReducer: {
            isSignedIn: isCustomerSignedIn
        } = {}
    } = store.getState();

    if (!isSignedIn() && isCustomerSignedIn) {
        asyncOnExpireTokenLogout();
        return true;
    }

    return false;
};
