/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    ADD_PRODUCT_TO_CART,
    addProductToCart,
    APPLY_COUPON_TO_CART,
    applyCouponToCart,
    REMOVE_COUPON_FROM_CART,
    REMOVE_PRODUCT_FROM_CART,
    removeCouponFromCart,
    removeProductFromCart,
    UPDATE_TOTALS
} from 'SourceStore/Cart/Cart.action';

export const UPDATE_CART_CATEGORIES = 'update_cart_categories';
export const UPDATE_UNREMOVABLE_CART_CATEGORIES = 'UPDATE_UNREMOVABLE_CART_CATEGORIES';
export const UPDATE_CHEAPEST_SHIPPING_AMOUNT = 'update_cheapest_shipping_amount';
export const UPDATE_REWARD_BONUS_CART = 'update_reward_bonus_cart';
export const UPDATE_APPLIED_REWARD_BONUS = 'update_applied_reward_bonus';
export const UPDATE_APPLIED_TAXES_DATA = 'update_applied_taxes_data';
export const UPDATE_IS_TOTALS_LOADING = 'update_is_totals_loading';
export const UPDATE_IS_CART_RESETING = 'UPDATE_IS_CART_RESETING';
export const UPDATE_IS_CART_PRODUCTS_ADDING = 'update_is_cart_products_adding';
export const UPDATE_SELECTED_SHIPPING_METHOD = 'update_selected_shipping_method';
export const UPDATE_STORE_TIMESLOTS = 'update_store_timeslots';
export const UPDATE_IS_PICKUP_OVERLAY_REQUIRED = 'update_is_pickup_overlay_required';
export const UPDATE_CHOSEN_TIMESLOT = 'update_chosen_timeslot';
export const UPDATE_IS_PICKUP_POPUP_CLOSE_VISIBLE = 'UPDATE_IS_PICKUP_POPUP_CLOSE_VISIBLE';
export const UPDATE_SUBSTITUTABLE_PRODUCTS = 'UPDATE_SUBSTITUTABLE_PRODUCTS';
export const UPDATE_EXCLUDED_ITEMS = 'update_excluded_items';
export const UPDATE_IS_SUBSTITUTION_CALL_ME_ACTIVE = 'update_is_substitution_call_me_active';
export const UPDATE_PICKUP_METHODS = 'update_pickup_methods';
export const UPDATE_IS_CART_DISTRIBUTED = 'UPDATE_IS_CART_DISTRIBUTED';
export const UPDATE_ADDITIONAL_CART_DATA_LOADING = 'UPDATE_ADDITIONAL_CART_DATA_LOADING';
export const UPDATE_COMMENT_VALUE = 'UPDATE_COMMENT_VALUE';
export const UPDATE_UNREMOVABLE_CHOSEN_TIMESLOT = 'UPDATE_UNREMOVABLE_CHOSEN_TIMESLOT';
export const UPDATE_SELECTED_SHIPPING_AMOUNT = 'UPDATE_SELECTED_SHIPPING_AMOUNT';
export const UPDATE_ITEMS_QTY = 'UPDATE_ITEMS_QTY';
export const UPDATE_FETCHING_ADD_PRODUCTS_SKUS = 'UPDATE_FETCHING_ADD_PRODUCTS_SKUS';
export const UPDATE_PRODUCTS_WITH_CHANGED_QTY = 'UPDATE_PRODUCTS_WITH_CHANGED_QTY';
export const CLEAR_PRODUCTS_WITH_CHANGED_QTY = 'CLEAR_PRODUCTS_WITH_CHANGED_QTY';
export const UPDATE_SINGLE_PRODUCT_DATA = 'UPDATE_SINGLE_PRODUCT_DATA';
export const UPDATE_DISCOUNT_DATA = 'UPDATE_DISCOUNT_DATA';

export {
    ADD_PRODUCT_TO_CART,
    REMOVE_PRODUCT_FROM_CART,
    UPDATE_TOTALS,
    APPLY_COUPON_TO_CART,
    REMOVE_COUPON_FROM_CART,
    addProductToCart,
    removeProductFromCart,
    applyCouponToCart,
    removeCouponFromCart
};

/**
 * Update pickup methods block
 * @param  {Object} pickupMethods Object of pickup methods
 * @return {void}
 * @namespace Store/Cart/Action/updatePickupMethods */
 export const updatePickupMethods = (pickupMethods) => ({
    type: UPDATE_PICKUP_METHODS,
    pickupMethods
});

/**
 * Update comment value
 * @param  {String} commentValue cart page comment value
 * @return {void}
 * @namespace Store/Cart/Action/updateCommentValue */
 export const updateCommentValue = (commentValue) => ({
    type: UPDATE_COMMENT_VALUE,
    commentValue
});

/**
 * Update totals block
 * @param  {Object} totals Object of calculated totals
 * @return {void}
 * @namespace Store/Cart/Action/updateTotals */
export const updateTotals = (cartData) => ({
    type: UPDATE_TOTALS,
    cartData
});

/**
 * Update totals block
 * @param  {Object} totals Object of calculated totals
 * @return {void}
 * @namespace Store/Cart/Action/updateTotals */
export const updateExcludedItems = (excludedItems) => ({
    type: UPDATE_EXCLUDED_ITEMS,
    excludedItems
});

/**
 * @param  {Boolean} isSubstitutionCallMeActive call me flag switc for a switch
 * @return {void}
 * @namespace Store/Cart/Action/updateIsSubstitutionCallMeActive */
export const updateIsSubstitutionCallMeActive = (isSubstitutionCallMeActive) => ({
    type: UPDATE_IS_SUBSTITUTION_CALL_ME_ACTIVE,
    isSubstitutionCallMeActive
});

/**
 * Update totals block
 * @param  {Boolean} isDeliveryPopupRequired Indicates if delivery pop
 * @return {void}
 * @namespace Store/Cart/Action/updateTotals */
export const updateIsPickupOverlayRequired = (isPickupOverlayRequired) => ({
    type: UPDATE_IS_PICKUP_OVERLAY_REQUIRED,
    isPickupOverlayRequired
});

/**
 * @param  {Boolean} isPickupPopupCloseVisible Indicates if close btn should be rendered in pickp overlay
 * @return {void}
 * @namespace Store/Cart/Action/toggleIsPickupPopupCloseVisible */
export const updateIsPickupPopupCloseVisible = (isPickupPopupCloseVisible) => ({
    type: UPDATE_IS_PICKUP_POPUP_CLOSE_VISIBLE,
    isPickupPopupCloseVisible
});

/**
 * @param  {String} substitutableProducts products, which are allowed to be substituted
 * @return {void}
 * @namespace Store/Cart/Action/updateSubstitutableProducts */
export const updateSubstitutableProducts = (substitutableProducts) => ({
    type: UPDATE_SUBSTITUTABLE_PRODUCTS,
    substitutableProducts
});

/**
 * Update selected shipping method
 * @param  {Object} selectedShippingMethod selected shipping method data object
 * @return {void}
 * @namespace Store/Cart/Action/updateSelectedShippingMethod */
export const updateSelectedShippingMethod = (selectedShippingMethod) => ({
    type: UPDATE_SELECTED_SHIPPING_METHOD,
    selectedShippingMethod
});

/**
 * Update chose store timeslots
 * @param  {Object} storeTimeslots timeslots for the chosen store
 * @return {void}
 * @namespace Store/Cart/Action/updateStoreTimeslots */
export const updateStoreTimeslots = (storeTimeslots) => ({
    type: UPDATE_STORE_TIMESLOTS,
    storeTimeslots
});

/**
 * Update chose store timeslots
 * @param  {Object} storeTimeslots timeslots for the chosen store
 * @return {void}
 * @namespace Store/Cart/Action/updateStoreTimeslots */
export const updateChosenTimeslot = (chosenTimeslot) => ({
    type: UPDATE_CHOSEN_TIMESLOT,
    chosenTimeslot
});

/**
 * Update information about applied taxes
 * @param {Array} appliedTaxes Array of taxes information
 * @return {void}
 * @namespace Store/Cart/Action/updateAppliedTaxesData
 */
export const updateAppliedTaxesData = (appliedTaxes) => ({
    type: UPDATE_APPLIED_TAXES_DATA,
    appliedTaxes
});

/**
 * @param {Boolean} isTotalsLoading specifying if totals are loading
 * @return {void}
 * @namespace Store/Cart/Action/updateIsTotalsLoading
 */
export const updateIsTotalsLoading = (isTotalsLoading) => ({
    type: UPDATE_IS_TOTALS_LOADING,
    isTotalsLoading
});

/**
 * @param {Boolean} isAdditionalCartDataLoading specifying if additional cart data is loading
 * @return {void}
 * @namespace Store/Cart/Action/updateIsAdditionalCartDataLoading
 */
export const updateIsAdditionalCartDataLoading = (isAdditionalCartDataLoading) => ({
    type: UPDATE_ADDITIONAL_CART_DATA_LOADING,
    isAdditionalCartDataLoading
});

/**
 * @param {Boolean} isCartReseting specifying if Cart is reseting
 * @return {void}
 * @namespace Store/Cart/Action/updateIsCartReseting
 */
export const updateIsCartReseting = (isCartReseting) => ({
    type: UPDATE_IS_CART_RESETING,
    isCartReseting
});

/**
 * @param {Boolean} isCartProductsAdding specifying if cart products are adding at the moment
 * @return {void}
 * @namespace Store/Cart/Action/updateIsCartProductsAdding
 */
export const updateIsCartProductsAdding = (isCartProductsAdding) => ({
    type: UPDATE_IS_CART_PRODUCTS_ADDING,
    isCartProductsAdding
});

/**
 * Update categories block
 * @param  {Object} categories Object of related to items in cart categories
 * @return {void}
 * @namespace Store/Cart/Action/updateCartCategories */
export const updateCartCategories = (categories) => ({
    type: UPDATE_CART_CATEGORIES,
    categories
});

/**
 * Update unremovable categories
 * @param  {Object} unremovableCategories Object of related to items in cart categories
 * @return {void}
 * @namespace Store/Cart/Action/updateUnremovableCartCategories */
export const updateUnremovableCartCategories = (unremovableCategories, cartData) => ({
    type: UPDATE_UNREMOVABLE_CART_CATEGORIES,
    unremovableCategories,
    cartData
});

/**
 * Update discount promo cart rules
 * @param  {Object} value Object of related to items in cart categories
 * @return {void}
 * @namespace Store/Cart/Action/updateDiscountData */
export const updateDiscountData = (value, label) => ({
    type: 'UPDATE_DISCOUNT_DATA',
    payload: { value, label }
});

/**
 * Update unremovable chosen timeslot
 * @param  {Object} unremovableChosenTimeslot Object of chosen timeslot
 * @return {void}
 * @namespace Store/Cart/Action/updateUnremovableChosenTimeslot */
export const updateUnremovableChosenTimeslot = (unremovableChosenTimeslot) => ({
    type: UPDATE_UNREMOVABLE_CHOSEN_TIMESLOT,
    unremovableChosenTimeslot
});

/**
 * Update isCartDistributed flag
 * @param  {Boolean} isCartDistributed indicates if the cart was distributed at least once in the session
 * @return {void}
 * @namespace Store/Cart/Action/updateIsCartDistributed */
export const updateIsCartDistributed = (isCartDistributed) => ({
    type: UPDATE_IS_CART_DISTRIBUTED,
    isCartDistributed
});

/**
 * Update reward bonus object
 * @param  {Object} balance Object Datacandy available bonus
 * @namespace Store/Cart/Action/updateRewardBonusCart */
export const updateRewardBonusCart = (bonus) => ({
    type: UPDATE_REWARD_BONUS_CART,
    bonus
});

/**
 * Update applied bonus object
 * @param  {Object} balance Object Datacandy applied bonus
 * @return {void}
 * @namespace Store/Cart/Action/updateAppliedRewardBonus */
export const updateAppliedRewardBonus = (appliedBonus) => ({
    type: UPDATE_APPLIED_REWARD_BONUS,
    appliedBonus
});

/**
 * Update cheapest shipping amount for cart
 * @param  {Number} cheapestShippingAmount
 * @return {void}
 * @namespace Store/Cart/Action/updateCheapestShippingAmount */
export const updateCheapestShippingAmount = (cheapestShippingAmount) => ({
    type: UPDATE_CHEAPEST_SHIPPING_AMOUNT,
    cheapestShippingAmount
});

/**
 * Update selected shipping amount for cart
 * @param  {Number} selectedShippingAmount
 * @return {void}
 * @namespace Store/Cart/Action/updateSelectedShippingAmount */
export const updateSelectedShippingAmount = (selectedShippingAmount) => ({
    type: UPDATE_SELECTED_SHIPPING_AMOUNT,
    selectedShippingAmount
});

/**
 * Update item in a cart on quantity change
 * @param  {String} sku
 * @param  {Number} qty
 * @return {Object}
 * @namespace Store/Cart/Action/updateItemsQty */
export const updateItemsQty = (sku, qty) => ({
    type: UPDATE_ITEMS_QTY,
    sku,
    qty
});

/**
 * Update currently adding products skus
 * These skus are used to identify, which product will be affected by changing its quantity
 * @param  {String} sku
 * @return {Object}
 * @namespace Store/Cart/Action/updateFetchingAddProductsSkus */
export const updateFetchingAddProductsSkus = (sku) => ({
    type: UPDATE_FETCHING_ADD_PRODUCTS_SKUS,
    sku
});

/**
 * Update the [{sku, qty}] array as a backup data
 * If product qty cannot be set to tje desired amount
 * the backup data is set back to avoid querying the cart
 * @param  {String} sku
 * @param  {Boolean} isRemoving
 * @return {Object}
 * @namespace Store/Cart/Action/updateProductsWithChangedQty */
export const updateProductsWithChangedQty = (sku, isRemoving = false) => ({
    type: UPDATE_PRODUCTS_WITH_CHANGED_QTY,
    sku,
    isRemoving
});

/**
 * Removes product sku from array after changing qty operation is accomplished
 * @param  {String} sku
 * @return {Object}
 * @namespace Store/Cart/Action/clearProductWithChangedQty */
export const clearProductWithChangedQty = (sku) => ({
    type: CLEAR_PRODUCTS_WITH_CHANGED_QTY,
    sku
});

/**
 * Update only a single item in cartTotals
 * @param  {String} sku
 * @param  {Object} cartData
 * @return {Object}
 * @namespace Store/Cart/Action/updateSingleProductData */
export const updateSingleProductData = (sku, cartData) => ({
    type: UPDATE_SINGLE_PRODUCT_DATA,
    sku,
    cartData
});
