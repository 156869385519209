/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { getIndexedProduct } from 'Util/Product';

import {
    UPDATE_PRODUCT_DETAILS,
    UPDATE_PRODUCT_AVAILABILITY,
    UPDATE_PRODUCT_AVAILABILITY_ACTIVE_POPUP_ID,
    UPDATE_PRODUCT_RELATED_BLOG_POSTS,
    UPDATE_PRODUCT_RELATED_RECIPES,
    UPDATE_IS_PRODUCT_LOADING
} from './Product.action';
import { PICKUP_INFO_CMS } from 'Component/ProductAvailability/ProductAvailability.config';

/** @namespace Store/Product/Reducer/getInitialState */
export const getInitialState = () => ({
    product: {},
    productAvailability: [],
    isProductLoading: false,
    isAvailableAtCurrentStore: false,
    formattedConfigurableOptions: {},
    productRelatedBlogPosts: [],
    productRelatedRecipes: [],
    productAvailabilityActivePopupId: PICKUP_INFO_CMS
});

/** @namespace Store/Product/Reducer/formatConfigurableOptions */
export const formatConfigurableOptions = (configurable_options) => configurable_options
    .reduce((prev, option) => {
        const {
            attribute_id,
            label,
            attribute_code
        } = option;

        return {
            ...prev,
            [attribute_code]: {
                attribute_id,
                label
            }
        };
    }, {});


/** @namespace Store/Product/Reducer */
export const ProductReducer = (
    state = getInitialState(),
    action
) => {

    switch (action.type) {
    case UPDATE_PRODUCT_DETAILS:
        const { product } = action;

        return {
            ...state,
            product: getIndexedProduct(product)
        };

    case UPDATE_IS_PRODUCT_LOADING:
        const { isProductLoading } = action;

        return {
            ...state,
            isProductLoading
        };

    case UPDATE_PRODUCT_AVAILABILITY:
        const { productAvailability, isAvailableAtCurrentStore } = action;

        return {
            ...state,
            productAvailability,
            isAvailableAtCurrentStore
        };

    case UPDATE_PRODUCT_AVAILABILITY_ACTIVE_POPUP_ID:
        const { productAvailabilityActivePopupId } = action;

        return {
            ...state,
            productAvailabilityActivePopupId
        };

    case UPDATE_PRODUCT_RELATED_BLOG_POSTS:
        const { productRelatedBlogPosts } = action;

        return {
            ...state,
            productRelatedBlogPosts
        };

    case UPDATE_PRODUCT_RELATED_RECIPES:
        const { productRelatedRecipes } = action;

        return {
            ...state,
            productRelatedRecipes
        };

    default:
        return state;
    }
};

export default ProductReducer;
