/* eslint-disable arrow-body-style */
/* eslint-disable quote-props */
/* eslint-disable no-unused-vars */
/* eslint-disable simple-import-sort/sort */
import Event, {
    EVENT_GTM_USER_REGISTER,
    EVENT_GTM_USER_LOGIN
} from '../../util/Event';

export const createAccount = (args, callback) => callback(...args)
    .then((signInPromise) => {
        Event.dispatch(EVENT_GTM_USER_REGISTER);

        return signInPromise;
    });

export const signIn = (args, callback) => callback(...args)
    .then((result) => {
        Event.dispatch(EVENT_GTM_USER_LOGIN);

        return result;
    });

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            createAccount,
            signIn
        }
    }
};
