/* eslint-disable react/no-unused-prop-types */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isMobile as isMobileUtil } from 'Util/Mobile';

import { Overlay as SourceOverlay } from 'SourceComponent/Overlay/Overlay.component';

import './Overlay.style';

/** @namespace Component/Overlay/Component */
export class Overlay extends SourceOverlay {
    onVisible() {
        const { onVisible, isStatic, device, isFrezeedOnTablet } = this.props;
        if (isStatic) {
            return;
        }
        if (
            device.isMobile
            || (isFrezeedOnTablet && isMobileUtil.tablet())
        ) {
            this.freezeScroll();
        }
        this.overlayRef.current.focus();
        onVisible();
    }

    onHide() {
        const { onHide, isStatic, device, isFrezeedOnTablet } = this.props;
        if (isStatic) {
            return;
        }
        if (
            device.isMobile
            || (isFrezeedOnTablet && isMobileUtil.tablet())
        ) {
            this.unfreezeScroll();
        }
        onHide();
    }
}

export default Overlay;
