/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    combineReducers,
    createStore
} from 'redux';

import {
    getStore,
    getStaticReducers as sourceGetStaticReducers
} from 'SourceStore';

import SearchReducer from 'Store/Search/Search.reducer';
import BlogReducer from 'Store/Blog/Blog.reducer';
import BlogPostReducer from 'Store/BlogPost/BlogPost.reducer';
import RecipeReducer from 'Store/Recipe/Recipe.reducer';
import ActitoReducer from 'Store/Actito/Actito.reducer';
import PromotionReducer from 'Store/Promotion/Promotion.reducer';

export { getStore };

/** @namespace Store/Index/getReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    SearchReducer,
    BlogReducer,
    BlogPostReducer,
    RecipeReducer,
    ActitoReducer,
    PromotionReducer
});

export function createReducer(asyncReducers) {
    return combineReducers({
        ...getStaticReducers(),
        ...asyncReducers
    });
}

export const store = createStore(
    createReducer(),
    ( // enable Redux dev-tools only in development
        process.env.NODE_ENV === 'development'
        && window.__REDUX_DEVTOOLS_EXTENSION__
    ) && window.__REDUX_DEVTOOLS_EXTENSION__({
        trace: true
    })
);

/**
 * Configure the store
 * @namespace Store/Index/configureStore
 * */
export default function configureStore() {
    // Add a dictionary to keep track of the registered async reducers
    store.asyncReducers = {};

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };

    // Return the modified store
    return store;
}

