/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Field from 'Component/Field';

import { setAddressesInFormObject, removeRecordInBookFromObject } from 'Util/Address';
import { rearrangeFieldMap } from 'Util/Checkout';
import { validateTelephone, getPostcodeMask, onPostcodeChange } from 'Util/Form';
import history from 'Util/History';

import { CANADA_ID, US_ID } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';

import { MyAccountAddressForm as SourceMyAccountAddressForm } from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.component';

import './MyAccountAddressForm.extended.style';

export class MyAccountAddressForm extends SourceMyAccountAddressForm {
    __construct(props) {
        super.__construct(props);
        const { address: { telephone, postcode, default_billing, default_shipping } } = props;
        const { countryId } = this.state;

        this.state = {
            ...this.state,
            record_address: false,
            telephone: telephone,
            postcode: postcode || '',
            default_billing,
            default_shipping,
            postcodeMask: getPostcodeMask(postcode, countryId)
        }
    }

    componentDidUpdate(_, prevState) {
        const { countryId, postcode, postcodeMask: prevPostcodeMask } = this.state;
        const { countryId: prevCountryId } = prevState;

        const postcodeMask = getPostcodeMask(postcode, countryId);

        if (countryId !== prevCountryId) {
            this.setState({
                postcodeMask: getPostcodeMask('', countryId),
                postcode: ''
            });
        }

        else if (postcodeMask !== prevPostcodeMask) {
            this.setState({ postcodeMask });
        }
    }

    onFormSuccess = (fields) => {
        const { isCheckoutPopupForm, setTemporaryAddress } = this.props;
        const { postcode } = this.state;
        const { record_in_book } = fields;
        const { location: { pathname } } = history;
        const isShippingStep = pathname.includes('/checkout/shipping');
        const fieldsToSend = removeRecordInBookFromObject(fields);
        fieldsToSend.postcode = postcode;
        this.checkFieldsToSend(fieldsToSend)
        const { onSave, addressLinesQty } = this.props;
        const { region_id, region_string: region, ...newAddress } = addressLinesQty > 1
            ? setAddressesInFormObject(fieldsToSend, addressLinesQty)
            : fieldsToSend;

        newAddress.region = { region_id, region };

        if (record_in_book || !isCheckoutPopupForm) {
            onSave(newAddress);
        } else if (isShippingStep) {
            setTemporaryAddress(newAddress, true);
        } else {
            setTemporaryAddress(newAddress, false);
        }
    };


    checkFieldsToSend(fieldsToSend) {
        const { default_billing, default_shipping } = this.state;

        fieldsToSend.company === undefined ?
            fieldsToSend.company = "" :
            fieldsToSend.company = fieldsToSend.company.trim();

        if (fieldsToSend.default_billing !== default_billing && default_billing) {
            fieldsToSend.default_billing = default_billing
        }

        if (fieldsToSend.default_shipping !== default_shipping && default_shipping) {
            fieldsToSend.default_shipping = default_shipping
        }
    }

    onRecordAddressChange = () => {
        const { record_address } = this.state;

        this.setState({ record_address: !record_address });
    }

    getStreetFields(label, index) {
        const { address: { street = [] } } = this.props;

        return {
            label,
            value: street[index],
            validation: index === 0 ? ['notEmpty'] : [],
            placeholder: label
        };
    }

    getRegionFields() {
        const { address: { region: { region } = {} } } = this.props;
        const { availableRegions, regionId } = this.state;

        if (!availableRegions || !availableRegions.length) {
            return {
                region_string: {
                    label: __('Province'),
                    value: region,
                    validation: ['notEmpty']
                }
            };
        }

        return {
            region_id: {
                label: __('Province'),
                type: 'select',
                validation: ['notEmpty'],
                selectOptions: availableRegions.map(({ id, name }) => ({ id, label: name, value: id })),
                onChange: (regionId) => this.setState({ regionId }),
                value: regionId ? regionId : -1,
                placeholder: __('Select option')
            }
        };
    }

    getRecordAddressField() {
        const { record_address } = this.state;

        const recordAddressProps = {
            record_in_book: {
                type: 'checkbox',
                label: __('Record the address in the notebook'),
                value: 'record_address',
                onChange: this.onRecordAddressChange,
                checked: record_address
            }
        }

        return Object.entries(recordAddressProps)[0]
    }

    // returns the address fields in quantity equal to BE
    getAddressFields() {
        const { addressLinesQty } = this.props;

        if (addressLinesQty === 1) {
            return {
                street: this.getStreetFields(
                    __('Address line 1'),
                    0
                )
            };
        }

        const streets = {};

        // eslint-disable-next-line fp/no-loops, fp/no-let
        for (let i = 0; i < addressLinesQty; i++) {
            const addressText = i === 0
                ? __('Address line 1')
                : __('Address line 2');

            streets[`street${i}`] = this.getStreetFields(
                addressText,
                i
            );
        }

        return streets;
    }

    get fieldMap() {
        const {
            countryId: defaultCountryId,
            telephone,
            postcode,
            default_billing,
            default_shipping,
            postcodeMask
        } = this.state;

        const {
            countries,
            isCheckoutPopupForm = false
        } = this.props;

        const addressFields = this.getAddressFields(),
        countryId = defaultCountryId ? defaultCountryId : 'CA';

        let postalValidation = '';

        if (countryId === CANADA_ID) {
            postalValidation = 'postal_CA';
        } else if (countryId === US_ID) {
            postalValidation = 'postal_US';
        }

        const defaultFieldMap = {
            firstname: {
                label: __('First name'),
                placeholder: __('Firstname'),
                validation: ['notEmpty']
            },
            lastname: {
                label: __('Last name'),
                placeholder: __('Lastname'),
                validation: ['notEmpty']
            },
            company: {
                label: __('Company'),
                placeholder: __('Company Name'),
                optional: true
            },
            ...addressFields,
            city: {
                label: __('City'),
                placeholder: __('City'),
                validation: ['notEmpty']
            },
            country_id: {
                type: 'select',
                label: __('Country'),
                validation: ['notEmpty'],
                value: countryId,
                selectOptions: countries.map(({ id, label }) => ({ id, label, value: id })),
                onChange: this.onCountryChange
            },
            ...this.getRegionFields(),
            postcode: {
                label: __('Postal code'),
                validation: ['notEmpty', postalValidation],
                placeholder: __('Postal code'),
                isControlled: true,
                mask: postcodeMask,
                value: postcode,
                onChange: onPostcodeChange.bind(this, countryId)
            },
            telephone: {
                label: __('Phone number'),
                validation: ['notEmpty', 'telephone'],
                placeholder: __('Phone'),
                isControlled: true,
                value: telephone,
                onChange: validateTelephone.bind(this)
            },
            default_billing: {
                type: 'checkbox',
                label: __('This is default Billing Address'),
                value: default_billing,
                checked: default_billing,
                onChange: ()=> this.setState({default_billing:!default_billing})
            },
            default_shipping: {
                type: 'checkbox',
                label: __('This is default Shipping Address'),
                value: default_shipping,
                checked: default_shipping,
                onChange: ()=> this.setState({default_shipping: !default_shipping})
            }
        };

        if (isCheckoutPopupForm) {
            const rearrangedFieldMap = rearrangeFieldMap(defaultFieldMap, this.getAddressFields());

            rearrangedFieldMap.telephone = {
                ...rearrangedFieldMap.telephone,
                isControlled: true,
                value: telephone,
                onChange: validateTelephone.bind(this)
            }

            rearrangedFieldMap.postcode = {
                ...rearrangedFieldMap.postcode,
                isControlled: true,
                value: postcode,
                mask: postcodeMask,
                onChange: onPostcodeChange.bind(this, countryId),
                //onChange: (postcode) => this.setState({ postcode }),
                validation: ['notEmpty', postalValidation]
            }

            return rearrangedFieldMap;
        }

        return defaultFieldMap;
    }

    renderSaveAddressButton() {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'MyAccountAddressForm', elem: 'SaveButton' } }
            >
                    { __('Save address') }
            </button>
        );
    }

    renderCancelButton() {
        const { hidePopup } = this.props;
        return (
            <button
              type="button"
              block="MyAccountAddressForm"
              elem="ClearButton"
              onClick={ hidePopup }
            >
                    { __('Cancel') }
            </button>
        );
    }

    getDefaultValues(fieldEntry) {
        const [key, { value, isControlled }] = fieldEntry;
        const { address, address: { [key]: addressValue } } = this.props;

        let addressValueToUse = addressValue;

        if (key === 'region_id' && Object.keys(address).length) {
            // address region is an object, not a string
            addressValueToUse = address.region[key];
        }

        if (isControlled) {
            // use the value from state always for controlled fields
            addressValueToUse = value;
        }

        return {
            ...super.getDefaultValues(fieldEntry),
            value: addressValueToUse !== undefined ? addressValueToUse : value
        };
    }

    renderActionsForCheckout() {
        return (
            <>
                <Field { ...this.getDefaultValues(this.getRecordAddressField()) } />
                <div
                block="MyAccountAddressForm"
                elem="ActionsContainer"
                >
                    { this.renderSaveAddressButton() }
                    { this.renderCancelButton() }
                </div>
            </>
        );
    }

    renderActions() {
        const { isCheckoutPopupForm } = this.props;

        if (isCheckoutPopupForm) {
            return this.renderActionsForCheckout();
        }

        return super.renderActions();
    }
};

export default MyAccountAddressForm;
