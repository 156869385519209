import { PureComponent } from "react";

import Image from "Component/Image";
import Link from "Component/Link";
import BlogPostCategories from "Component/BlogPostCategories";
import TextPlaceholder from "Component/TextPlaceholder";

import "./BlogSimilarPostCard.style.scss";

export class BlogSimilarPostCard extends PureComponent {
	renderImage() {
		const {
			post: { list_thumbnail, list_thumbnail_alt },
		} = this.props;

		return (
			<Image
				src={list_thumbnail}
                alt={list_thumbnail_alt}
				mix={{
					block: "BlogSimilarPostCard",
					elem: "Picture",
				}}
				ratio="custom"
			/>
		);
	}

	renderCategories() {
		const {
			post: { categories },
		} = this.props;

		return <BlogPostCategories categories={categories} />;
	}

	renderPostInfo() {
		const {
			post: { title, published_at_text },
		} = this.props;

		return (
			<div block="BlogSimilarPostCard" elem="PostInfo">
				{this.renderCategories()}
				<h2 block="BlogSimilarPostCard" elem="PostTitle">
					{title}
				</h2>
				<span block="BlogSimilarPostCard" elem="PublishDate">
					{published_at_text}
				</span>
			</div>
		);
	}

	renderContent = () => {
		return (
			<div block="BlogSimilarPostCard" elem="Container">
				{this.renderImage()}
				{this.renderPostInfo()}
			</div>
		);
	};

	renderPostCardPlaceholder() {
		return (
			<div block="BlogSimilarPostCard" elem="Container">
				<Image
					mix={{
						block: "BlogSimilarPostCard",
						elem: "Picture",
					}}
					isPlaceholder
				/>
				<div block="BlogSimilarPostCard" elem="PostInfo">
					{new Array(4).fill(null).map(() => (
						<TextPlaceholder />
					))}
				</div>
			</div>
		);
	}

	render() {
		const { post: { url } = {}, mix, isPlaceholder } = this.props;

		const renderContentFn = isPlaceholder
			? this.renderPostCardPlaceholder
			: this.renderContent;

		return (
			<Link
				to={url}
				mix={{ block: "BlogSimilarPostCard", elem: "Wrapper" }}
			>
				<div block="BlogSimilarPostCard" mix={mix}>
					{renderContentFn()}
				</div>
			</Link>
		);
	}
}

export default BlogSimilarPostCard;
