import { PureComponent } from "react";

import ClickOutside from "Component/ClickOutside";
import {
	FacebookShareButton,
	PinterestShareButton,
	TwitterShareButton,
} from "react-share";

import { ReactComponent as FacebookIcon } from "./icons/facebook_icon.svg";
import { ReactComponent as PinterestIcon } from "./icons/pinterest_icon.svg";
import { ReactComponent as ShareIcon } from "./icons/share_icon.svg";
import { ReactComponent as TwitterIcon } from "./icons/twitter_icon.svg";
import { ReactComponent as ExternalIcon } from "./icons/external_link_icon.svg";

import "./ShareDropdown.style";

export class ShareDropdown extends PureComponent {
	renderShareButton(Icon, onClick = () => {}) {
		return (
			<button block="ShareDropdown" elem="ShareButton" onClick={onClick}>
				<Icon />
			</button>
		);
	}

	renderShareButtons() {
		const {
			isDropdownActive,
			onCopyToClipboardClick,
			isCopiedTooltipVisible,
			copiedTooltipRef,
			recipeData: { thumbnail: { url = "" } = {} },
		} = this.props;

		const shareUrl = location.href;

		return (
			<div
				block="ShareDropdown"
				elem="ShareButtons"
				mods={{ isDropdownActive }}
			>
				<FacebookShareButton
					url={shareUrl}
					className="ShareDropdown-ShareLink"
				>
					{this.renderShareButton(FacebookIcon)}
				</FacebookShareButton>
				<TwitterShareButton
					url={shareUrl}
					className="ShareDropdown-ShareLink"
				>
					{this.renderShareButton(TwitterIcon)}
				</TwitterShareButton>
				<PinterestShareButton
					url={"https://avril.ca/"}
					media={url}
					className="ShareDropdown-ShareLink"
				>
					{this.renderShareButton(PinterestIcon)}
				</PinterestShareButton>
				<div block="ShareDropdown" elem="CopyButtonWrapper">
					<span
						block="ShareDropdown"
						elem="CopiedTooltip"
						mods={{ isVisible: isCopiedTooltipVisible }}
						ref={copiedTooltipRef}
					>
						{__("Copied to clipboard")}
					</span>
					{this.renderShareButton(
						ExternalIcon,
						onCopyToClipboardClick
					)}
				</div>
			</div>
		);
	}

	renderDropdownBtn() {
		const { toggleIsDropdownActive, isDropdownActive, dropdownBtnRef } =
			this.props;

		return (
			<button
				block="ShareDropdown"
				elem="DropdownBtn"
				mods={{ isDropdownActive }}
				ref={dropdownBtnRef}
				onClick={toggleIsDropdownActive}
			>
				<ShareIcon />
				{__("Share")}
			</button>
		);
	}

	renderContent() {
		return (
			<div block="ShareDropdown" elem="Content">
				{this.renderDropdownBtn()}
				{this.renderShareButtons()}
			</div>
		);
	}

	render() {
		const { setIsDropdownActive } = this.props;

		return (
			<div block="ShareDropdown">
				<ClickOutside onClick={() => setIsDropdownActive(false)}>
					{this.renderContent()}
				</ClickOutside>
			</div>
		);
	}
}

export default ShareDropdown;
