/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    TYPE_PRODUCT,
    TYPE_CMS_PAGE,
    TYPE_CATEGORY,
    TYPE_NOTFOUND,
} from "SourceRoute/UrlRewrites/UrlRewrites.config";
export { TYPE_PRODUCT, TYPE_CMS_PAGE, TYPE_CATEGORY, TYPE_NOTFOUND };

export const TYPE_BLOG_LANDING = "AM_BLOG";
export const TYPE_BLOG_POST = "AM_BLOG_POST";
export const TYPE_BLOG_CATEGORY = "AM_BLOG_CATEGORY";
export const TYPE_BLOG_TAG = "AM_BLOG_TAG";

export const RECIPE_HOME = "RECIPE_HOME";
export const RECIPE = "RECIPE";
export const RECIPE_CATEGORY = "RECIPE_CATEGORY";

export const FLIPP_PAGE = "FLIPP_PAGE"