import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Popup from 'Component/Popup';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import PickupDistance from 'Component/PickupDistance';

import { getRegionCode } from 'Util/Checkout';
import { FR_STORE } from 'Util/Price/Price.config';

import { ReactComponent as TruckIcon } from 'Component/ProductAvailability/icons/truck.svg';
import { ReactComponent as CarIcon } from './icons/car.svg';

import {
    CLOSEST_STORE_INFO,
    STORE_SELECTOR_CONFIRMATION_ID,
    STORE_SELECTOR_LIST_ID,
    STORE_SELECTOR_STEP,
    STORE_CONFIRMATION_STEP,
    DEFAULT_LOCATION_CODE
} from './ClosestStoreOverlay.config';

import './ClosestStoreOverlay.style';

export class ClosestStoreOverlay extends PureComponent {
    renderCmsBlock() {
        const { setIsLoading } = this.props;
        return <CmsBlock identifier={ STORE_SELECTOR_CONFIRMATION_ID } setIsLoading={ setIsLoading } />;
    }

    renderSelectorCMSBlock() {
        const { setIsLoading } = this.props;
        return <CmsBlock identifier={ STORE_SELECTOR_LIST_ID } setIsLoading={ setIsLoading } />;
    }

    renderActions() {
        const {
            storeOption,
            hideOverlayClick,
            onConfirmClick
        } = this.props;

        return (
            <div
              block="ClosestStoreOverlay"
              elem="BtnContainer"
            >
                <button
                  block="ClosestStoreOverlay"
                  elem="ConfirmBtn"
                  onClick={ () => onConfirmClick(storeOption) }
                >
                    { __('Confirm') }
                </button>
                <button
                  block="ClosestStoreOverlay"
                  elem="CancelBtn"
                  onClick={ hideOverlayClick }
                >
                    { __('Cancel') }
                </button>
            </div>
        );
    }

    renderOptionActions(storeOption) {
        const {
            onChooseStoreClick,
            closestStoreCode
        } = this.props;

        const { pickup_location_code } = storeOption;
        const isSelected = closestStoreCode === pickup_location_code;

        if (isSelected) {
            return (
                <p block="ClosestStoreOverlay" elem="SelectedText">{ __('Selected') }</p>
            );
        }

        return (
            <button
              block="ClosestStoreOverlay"
              elem="StoreChooseBtn"
              onClick={ () => onChooseStoreClick(storeOption) }
            >
                { __('Choose') }
            </button>
        );
    }

    renderDistance(distance) {
        return <PickupDistance
          distance={ distance }
        />;
    }

    renderStoresOption(storeOption) {
        const {
            countries,
            code,
            closestStoreCode
        } = this.props;

        const {
            name,
            street,
            city,
            region_id,
            country_id,
            postcode,
            cms_page_url_en,
            cms_page_url_fr,
            distance_value,
            pickup_location_code
        } = storeOption;

        const cmsUrlForCurrentStore = code === FR_STORE
            ? cms_page_url_fr
            : cms_page_url_en;

        const regionCode = getRegionCode(region_id, country_id, countries);
        const isColumnDirection = !!distance_value;
        const isSelected = closestStoreCode === pickup_location_code;

        return (
            <li
              block="ClosestStoreOverlay"
              elem="StoreListOption"
            >
                <p
                  block="ClosestStoreOverlay"
                  elem="StoreAddress"
                >
                    <span block="ClosestStoreOverlay" elem="StoreName">{ name }</span><br />
                    { street }<br />
                    { city }, { regionCode }, { postcode }<br />
                    <Link
                      mix={ { block: "ClosestStoreOverlay", elem: "StoreInfoLink" } }
                      to={ cmsUrlForCurrentStore || '/' }
                      target="_blank"
                    >
                        { __('More Info') }
                    </Link>
                </p>
                <div
                  block="ClosestStoreOverlay"
                  elem="ButtonAndDistance"
                  mods={ { isColumnDirection, isSelected } }
                >
                    { this.renderDistance(distance_value) }
                    { this.renderOptionActions(storeOption) }
                </div>
            </li>
        );
    }

    renderSelectorStepStoreList() {
        const { pickupLocations, isMobile } = this.props;

        const nonDefaultLocations = pickupLocations
            .filter((
                {
                    pickup_location_code,
                    is_pickup_location_active
                }
            ) => pickup_location_code !== DEFAULT_LOCATION_CODE && is_pickup_location_active);

        return isMobile
            ? this.renderSelectorStepStoreListMobile(nonDefaultLocations)
            : this.renderSelectorStepStoreListDesktop(nonDefaultLocations);
    }

    renderSelectorStepStoreListMobile(storeOptions) {
        return (
            <div block="ClosestStoreOverlay" elem="StoreListWrapperMobile">
                <div block="ClosestStoreOverlay" elem="PickupInfoWrapper">
                    <CarIcon />
                    <p block="ClosestStoreOverlay" elem="PickupLocationsText">
                        { __('Pick up in store') }
                    </p>
                </div>
                <div block="ClosestStoreOverlay" elem="PickupLocationsWrapper">
                    { this.renderStoreList(storeOptions) }
                </div>
            </div>
        );
    }

    renderSelectorStepStoreListDesktop(storeOptions) {
        return (
            <div block="ClosestStoreOverlay" elem="StoreListWrapper">
                <CarIcon />
                <div block="ClosestStoreOverlay" elem="PickupLocationsWrapper">
                    <p block="ClosestStoreOverlay" elem="PickupLocationsText">
                        { __('Pick up in store') }
                    </p>
                    { this.renderStoreList(storeOptions) }
                </div>
            </div>
        );
    }

    renderStoreList(storeOptions) {
        return (
            <div
              block="ClosestStoreOverlay"
              elem="StoreList"
            >
                { <ul
                    block="ClosestStoreOverlay"
                    elem="StoreListOptions"
                >
                    { storeOptions.map(this.renderStoresOption.bind(this)) }
                </ul> }
            </div>
        );
    }

    renderHomeDeliveryOption() {
        const { code, pickupLocations, isMobile } = this.props;

        const storeOption = pickupLocations
            .filter(({ pickup_location_code }) => pickup_location_code === DEFAULT_LOCATION_CODE)[0];

        if (!storeOption) {
            return null;
        }

        const { cms_page_url_fr, cms_page_url_en } = storeOption;

        const cmsUrlForCurrentStore = (code === FR_STORE
            ? cms_page_url_fr
            : cms_page_url_en) || '/';

        return (
            <div block="ClosestStoreOverlay" elem="HomeDeliveryBlock">
                {
                    isMobile
                        ? this.renderHomeDeliveryMobile(storeOption, cmsUrlForCurrentStore)
                        : this.renderHomeDeliveryDesktop(storeOption, cmsUrlForCurrentStore)
                }
            </div>
        )
    }

    renderHomeDeliveryMobile(storeOption, urlForCms) {
        const { closestStoreCode } = this.props;
        const { pickup_location_code } = storeOption;

        const isNotSelected = closestStoreCode !== pickup_location_code;

        return (
            <>
                <div block="ClosestStoreOverlay" elem="HomeDeliveryInfoWrapper">
                    <TruckIcon />
                    <div block="ClosestStoreOverlay" elem="HomeDeliveryInfo">
                        <p block="ClosestStoreOverlay" elem="PickupLocationsText" mods={ { isNotSelected } }>
                            { __('Home delivery') }
                        </p>
                        <Link
                          mix={ { block: "ClosestStoreOverlay", elem: "StoreInfoLink" } }
                          to={ urlForCms }
                          target="_blank"
                        >
                            { __('More Info') }
                        </Link>
                    </div>
                </div>
                <span block="ClosestStoreOverlay" elem="HomeDeliveryBtnWrapper">
                    { this.renderOptionActions(storeOption) }
                </span>
            </>
        );
    }

    renderHomeDeliveryDesktop(storeOption, urlForCms) {
        return (
            <>
                <div block="ClosestStoreOverlay" elem="HomeDeliveryInfoWrapper">
                    <TruckIcon />
                    <div block="ClosestStoreOverlay" elem="HomeDeliveryInfo">
                        <p block="ClosestStoreOverlay" elem="PickupLocationsText">
                            { __('Home delivery') }
                        </p>
                        <Link
                          mix={ { block: "ClosestStoreOverlay", elem: "StoreInfoLink" } }
                          to={ urlForCms }
                          target="_blank"
                        >
                            { __('More Info') }
                        </Link>
                    </div>
                </div>
                <span block="ClosestStoreOverlay" elem="HomeDeliveryBtnWrapper">
                    { this.renderOptionActions(storeOption) }
                </span>
            </>
        );
    }

    renderSelectorStep() {
        return (
            <>
                { this.renderSelectorCMSBlock() }
                { this.renderHomeDeliveryOption() }
                { this.renderSelectorStepStoreList() }
            </>
        );
    }

    renderConfirmationStep() {
        return (
            <>
                { this.renderCmsBlock() }
                { this.renderActions() }
            </>
        );
    }

    renderContent() {
        const { step } = this.props;

        switch (step) {
            case STORE_CONFIRMATION_STEP:
                return this.renderConfirmationStep();

            case STORE_SELECTOR_STEP:
                return this.renderSelectorStep();

            default:
                return null;
        }
    }

    render() {
        const {
            onPopupClose,
            isLocationPopupOpen,
            isLoading,
            step
        } = this.props;

        const isConfirmationStep = step === STORE_CONFIRMATION_STEP;

        return (
            <Popup
              id={ CLOSEST_STORE_INFO }
              onClose={ onPopupClose }
              mix={ { block: 'ClosestStoreOverlay', mods: { isConfirmationStep } } }
              clickOutside={ !isLocationPopupOpen }
            >
                <Loader isLoading={ isLoading } />
                <div
                  block="ClosestStoreOverlay"
                  elem="Wrapper"
                >
                    { this.renderContent() }
                </div>
            </Popup>
        );
    }
}

export default ClosestStoreOverlay;
