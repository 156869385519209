/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export {
    setAddressesInFormObject,
    getFormFields
} from 'SourceUtil/Address';

/** */
export const removeRecordInBookFromObject = (fieldsObj) => {
    if (!fieldsObj.hasOwnProperty('street1')) {
        fieldsObj.street1 = '';
    }
    return Object.entries(fieldsObj).reduce(
      (acc, [key, value]) => {
        if (key !== 'record_in_book') {
            acc[key] = value;
        }

        return acc;
    },
    {})
};

/** @namespace Util/Address/trimAddressFields */
export const trimAddressFields = (fields) => {
    const {
        region_string: region,
        street: [street1, street2 = ''],
        ...fieldsData
    } = fields;

    return { ...fieldsData, region, street: [street1, street2] };
};


/** @namespace Util/Address/trimCustomerAddress */
export const trimCustomerAddress = (customerAddress) => {
    const {
        city,
        company,
        country_id,
        email,
        firstname,
        lastname,
        method,
        postcode,
        street,
        telephone,
        region
    } = customerAddress;

    return {
        city,
        company,
        country_id,
        email,
        firstname,
        lastname,
        method,
        postcode,
        street,
        telephone,
        ...region
    };
};

/** @namespace Util/Address/trimCustomerAddress */
export const trimBillingCustomerAddress = (customerAddress) => {
    const {
        city,
        company,
        country_id,
        email,
        firstname,
        lastname,
        method,
        postcode,
        street,
        telephone,
        region: { region_code, region_id }
    } = customerAddress;

    return {
        city,
        company,
        country_code: country_id,
        email,
        firstname,
        lastname,
        method,
        postcode,
        street,
        telephone,
        region: region_code,
        region_id
    };
};
