export const getFractionsQty = (categories) => categories
    .reduce((acc, { include_in_menu }) => {
        if (include_in_menu) {
            acc++;
        }

        return acc;
    },
0);

export const getRefWidth = (ref) => ref.current ? ref.current.getBoundingClientRect().width : 0;

export const getPaddingLeft = (ref) => {
    const pLeft = ref.current
        ? window.getComputedStyle(ref.current).paddingLeft
        : 0;

    if (typeof pLeft === 'string' && pLeft.length > 1) {
        return pLeft.slice(0, pLeft.length - 2);
    }

    return pLeft;
}

export const sortCategoriesByPosition = (categories) => categories.sort((a, b) => a.position - b.position);

export const getIncludedCategories = (categories) => categories.filter(({ include_in_menu }) => include_in_menu === 1);

export const getNextActiveSubMenuKey = (secondLvlsubMenu) => {
    const {
        menu_1: { isActive: isFirstMenuActive },
        menu_2: { isActive: isSecondMenuActive }
    } = secondLvlsubMenu;

    if (isFirstMenuActive || !isFirstMenuActive && !isSecondMenuActive) {
        return 'menu_2'
    } else if (isSecondMenuActive) {
        return 'menu_1'
    };
}

export const getNextActiveSubMenu = (
    subMenuArr,
    nextActiveKey,
    newActiveSubLinkChildren
) => subMenuArr.reduce(
        (acc, [ key, { _, activeSubLinkChildren } ]
    ) => {
        if (key === nextActiveKey) {
            acc[key] = {
                isActive: true,
                activeSubLinkChildren: newActiveSubLinkChildren
            };
        } else {
            acc[key] = {
                isActive: false,
                activeSubLinkChildren
            }
        }

        return acc;
    },
{});

export const getSubmenuItemsByParts = (firstLevelItems, subLevelItems) => {
    const numberOfPortions = Math.ceil(subLevelItems.length / firstLevelItems.length);
    const subLevelByParts = [];

    for (let i = 0; i < numberOfPortions; i++) {
        const subLevelPart = subLevelItems.slice(
            i * firstLevelItems.length,
            (i + 1) * firstLevelItems.length
        );

        subLevelByParts.push(subLevelPart);
    }

    return subLevelByParts;
}

export const getFullFirstLvlMenuHeight = (submenuWrapper, ecomSubcategory) => {
    const paddingTop = getStyleInNumberForm(window.getComputedStyle(submenuWrapper).paddingTop)
        + getStyleInNumberForm(window.getComputedStyle(submenuWrapper).paddingBottom);
    const childrenHeight = Array.prototype
        .reduce
        .call(
            ecomSubcategory.children,
            (acc, elem) => {
                const marginBottom = window.getComputedStyle(elem).marginBottom;

                if (marginBottom) {
                    acc += getStyleInNumberForm(marginBottom);
                }

                if (!elem.children.length && elem.childNodes.length) {
                    acc += elem.getBoundingClientRect().height;
                } else if (elem.children.length) {
                    acc += elem.children[0].getBoundingClientRect().height * elem.children.length;
                }

                return acc;
            },
        0);

    return paddingTop + childrenHeight;
}

export function getStyleInNumberForm(paddingStr) {
    return Number(paddingStr.slice(0, paddingStr.length - 2));
}

export function getIsConsistsActiveChildren(children) {
    if (!children || !children.length) {
        return false;
    }

    return children.reduce((acc, { include_in_menu }) => {
        if (include_in_menu) {
            acc = true;
        }

        return acc;
    }, false);
}

export function resetActiveSubmenuWidth(ecomSubcategoryRef) {
    const rootElemStyleObj = document.querySelector(':root').style;
    const activeSubLevels = document.querySelectorAll('.subLevel_isSubActive');
    const firstLvlSubMenuWidth = ecomSubcategoryRef.current.offsetWidth;
    const widthsForLevels = [];
    const cssPropertiesForLevels = [
        '--first-level-translate',
        '--second-level-translate',
        '--third-level-translate',
        '--fourth-level-translate'
    ];

    for (let elem of activeSubLevels) {
        const { dataset: { level } = {} } = elem;

        if (level === 'undefined') {
            continue;
        }

        widthsForLevels[level] = elem.offsetWidth;
    }

    for (let elem of activeSubLevels) {
        const { dataset: { level } = {} } = elem;
        let widthToSet = 0;

        if (level == 0) {
            widthToSet = firstLvlSubMenuWidth;
        } else {
            widthToSet = widthsForLevels.reduce(
                (acc, width, idx) => {
                    if (idx >= level) {
                        return acc;
                    }

                    acc += width;
                    return acc;
                }
                , firstLvlSubMenuWidth
            )
        }

        rootElemStyleObj.setProperty(
            cssPropertiesForLevels[level],
            `${ widthToSet }px`
        );
    }
}
