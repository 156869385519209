import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceRoute/CmsPage/CmsPage.container'
import history from 'Util/History';

import { debounce } from 'SourceUtil/Request';
import CmsPageQuery from 'Query/CmsPage.query';

import CmsPage from 'Route/CmsPage/CmsPage.component';
import { LOADING_TIME } from 'SourceRoute/CmsPage/CmsPage.config';

export class CmsPageContainer extends SourceCmsPageContainer {
    static propTypes = {
        setHeaderState: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        toggleBreadcrumbs: PropTypes.func.isRequired,
        pageIds: PropTypes.number,
        pageIdentifiers: PropTypes.string,
        isOnlyPlaceholder: PropTypes.bool,
        isBreadcrumbsActive: PropTypes.bool,
        onLoad: PropTypes.func
    };

    state = {
        page: {},
        isLoading: true,
        default404pageId: 2
    };

    componentDidUpdate(prevProps) {
        const {
            pageIdentifiers,
            pageIds
        } = this.props;

        const {
            pageIdentifiers: prevPageIdentifiers,
            pageIds: prevPageIds
        } = prevProps;

        if ( pageIds !== prevPageIds
            || pageIdentifiers !== prevPageIdentifiers
        ) {
            this.requestPage();
        }
    }

    onPageLoad = ({ cmsPage: page }) => {
        const {
            updateMeta,
            updateBreadcrumbs,
            setHeaderState,
            onLoad
        } = this.props;
        
        const { meta_title, title, meta_description, meta_keywords, page_layout } = page;

        setHeaderState({
            name: 'cms-page',
            title: title,
            onBackClick: () => history.goBack()
        });

        const header = document.querySelector('.Header-Wrapper');
        const footer = document.getElementById('FooterWrapper');
        if (page_layout === "empty") {
            header.style.display = "none";
            footer.style.display = "none";
        }

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();
      
        updateBreadcrumbs(page);
        updateMeta({
            title: meta_title || title,
            canonical_url: window.location.href,
            description: meta_description,
            keywords: meta_keywords,
        });

        this.setState({ page, isLoading: false });      
        if (onLoad) {
            onLoad(page, false );
        }
    };

    requestPage() {
        const params = this.getRequestQueryParams();
        const { id, identifier } = params;
        const { onLoad } = this.props;
        if (!id && !identifier) {
            return;
        }

        this.setState({ isLoading: true });

        this.fetchData(
            [CmsPageQuery.getQuery(params)],
            this.onPageLoad,
            () => { 
                this.setState({ isLoading: false }); 
                onLoad({}, false );
            },
        );
    }

    getRequestQueryParams() {
        const {
            pageIdentifiers: identifier,
            pageIds: id
        } = this.props;

        const { pathname } = location;

        if (identifier) {
            return { identifier };
        }

        if (id !== -1) {
            return { id };
        }

        // IDs for 'home' cms-pages: 2 - 'en', 16 - 'fr'
        return {
            id: pathname.slice(0, 3).includes('en') ? 2 : 16
        };
    }

    render() {
        return (
            <CmsPage
                { ...this.props }
                { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
