/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    NewVersionPopup as SourceNewVersionPopup
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.component';

import './NewVersionPopup.extended.style';

/** @namespace Component/NewVersionPopup/Component */
export class NewVersionPopup extends SourceNewVersionPopup {
    renderReloadThePageButton() {
        const { toggleNewVersion } = this.props;

        return (
            <button
              block="NewVersionPopup"
              elem="ReloadButton"
              mix={ { block: 'Button', elem: "Important" } }
              onClick={ toggleNewVersion }
            >
                { __('Reload the page') }
            </button>
        );
    }
}

export default NewVersionPopup;
