/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ClickOutside as SourceClickOutside } from 'SourceComponent/ClickOutside/ClickOutside.component';

/** @namespace Component/ClickOutside/Component */
export class ClickOutside extends SourceClickOutside {
    handleClick = ({ target }) => {
        const { onClick, exceptionsElemsRefs = [] } = this.props;

        const additionalRefs = exceptionsElemsRefs.filter(({ current }) => !!current);
        if ([...this.childrenRefs, ...additionalRefs].every(
            ({ current }) => !current.contains(target)
        )) {
            onClick();
        }
    };
}

export default ClickOutside;
