import CategoryPagination from 'Component/CategoryPagination/CategoryPagination.component';

export class BlogPagination extends CategoryPagination {
    renderPreviousPageLink() {
        const {
            currentPage,
            totalPages
        } = this.props;

        if (!totalPages || currentPage <= 1) {
            return null;
        }

        return this.renderPageLink(
            currentPage - 1,
            __('Previous'),
            null,
            { block: "CategoryPagination", elem: "Arrow" },
            { ArrowLeft: true }
        );
    }
}

export default BlogPagination;
