import amastyCollector from '../util/AmastySearchCollector';

const componentDidMount = (args, callback) => {
    amastyCollector.addListeners();
    return callback(...args);
};

export default {
    'Component/SearchWindow/Container': {
        'member-function': {
            'componentDidMount': componentDidMount,
        }
    }
};
