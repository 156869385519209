/* eslint-disable simple-import-sort/sort */
/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BaseEvent from './BaseEvent.event';

import Event, { EVENT_GTM_NO_MATCH } from '../../../util/Event';

export const SPAM_PROTECTION_DELAY = 100;

/**
 * No Match
 */
export class NoMatchEvent extends BaseEvent {

    /**
     * Bind no match
     */
    bindEvent() {
        Event.observer(EVENT_GTM_NO_MATCH, () => {
            this.handle();
        });
    }

    /**
     * Handle
     */
    handler() {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }
        this.pushEventData({
            pageType: this.getPageType(),
            ecommerce: {
                event: 'pageNotFound'
            }
        });

    }

}

export default NoMatchEvent;
