import { isSessionOver } from 'Util/Auth';

export const AUTH_TOKEN_ERR_MESSAGE = 'customer_auth_token_expired';

/**
 * checks if the query must be blocked according to the auth token expiration
 * @param {String} query query in JSON format
 * @returns {Boolean}
 * @namespace Util/AuthCheckHelper/isBlockQueryNeeded
 */
export const isBlockQueryNeeded = (query) => {
    // createEmptyCart query is always allowed
    const _isSessionOver = isSessionOver();
    if (query.includes('createEmptyCart')) {
        return false;
    }

    return _isSessionOver;
}

/**
 * get rejected Promise with error message - null
 * @returns {Promise}
 * @namespace Util/AuthCheckHelper/getRejectedPromise
 */
export const getRejectedPromise = () => {
    const err = [{ message: AUTH_TOKEN_ERR_MESSAGE }];
    err.message = AUTH_TOKEN_ERR_MESSAGE;

    return Promise.reject(err);
}
