import CmsBlockQuery from "Query/CmsBlock.query";

const cmsBlocksQueue = [];
const cmsBlocksCache = new Map();

function processItem(item) {
    const block = cmsBlocksCache.get(item.identifier);
    item.onLoad(false);
    if (block) {
        return item.cmsBlock.setState({
            cmsBlock: block,
            isLoading: false,
        }, () => {
            item.onLoad(true);
        })
    }
}

function processCmsBlockQueue() {
    const blocksToLoad = cmsBlocksQueue.splice(0, cmsBlocksQueue.length);

    if (blocksToLoad.length === 0) {
        return;
    }

    const firstBlock = blocksToLoad[0].cmsBlock;

    const identifiers = blocksToLoad.map(info => info.identifier);

    firstBlock.fetchData(
        [CmsBlockQuery.getQuery({ identifiers })],
        ({ cmsBlocks: { items } }) => {
            items.forEach(block => cmsBlocksCache.set(block.identifier, block));
            blocksToLoad.forEach(processItem)
        },
        () => {
            blocksToLoad.forEach(item => item.onLoad(false));
        }
    );
}

export function loadCmsBlock(cmsBlock, identifier, onLoad) {
    if (cmsBlocksCache.has(identifier)) {
        processItem({ cmsBlock, identifier, onLoad });
        return;
    }

    if (cmsBlocksQueue.length === 0) {
        setTimeout(processCmsBlockQueue, 20);
    }

    cmsBlocksQueue.push({ cmsBlock, identifier, onLoad });
}
