/* eslint-disable quote-props */
/* eslint-disable simple-import-sort/sort */
import Event, {
    EVENT_GTM_CHECKOUT_BEGIN
} from '../../util/Event';

import { CHECKOUT_EVENT_DELAY } from '../../component/GoogleTagManager/events/Checkout.event';

export const sendOnCheckoutButtonClick = (args, callback, instance) => {
    const { totals = {} } = instance.props;
    setTimeout(
        () => Event.dispatch(EVENT_GTM_CHECKOUT_BEGIN, {
            totals,
        }),
        CHECKOUT_EVENT_DELAY
    );

    return callback(...args);
};

export default {
    'Route/CartPage/Container': {
        'member-function': {
            onCheckoutButtonClick: sendOnCheckoutButtonClick
        }
    }
};
