/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_RECIPES_LIST = 'UPDATE_RECIPES_LIST';
export const UPDATE_IS_RECIPES_LOADING = 'UPDATE_IS_RECIPES_LOADING';
export const UPDATE_IS_RECIPE_BANNER_VISIBLE = 'UPDATE_IS_RECIPE_BANNER_VISIBLE';
export const UPDATE_RECIPES_FILTERS = 'UPDATE_RECIPES_FILTERS';
export const UPDATE_RECIPE_DATA = 'UPDATE_RECIPE_DATA';
export const UPDATE_RECIPE_CATEGORIES = 'UPDATE_RECIPE_CATEGORIES';
export const UPDATE_CURRENT_CATEGORY_DATA = 'UPDATE_CURRENT_CATEGORY_DATA';
export const UPDATE_IS_CURRENT_RECIPE_CATEGORY_LOADING = 'UPDATE_IS_CURRENT_RECIPE_CATEGORY_LOADING';
export const UPDATE_IS_RECIPES_CATEGORIES_LOADING = 'UPDATE_IS_RECIPES_CATEGORIES_LOADING';
export const UPDATE_IS_RECIPE_INGREDIENTS_PRODUCTS_LOADING = 'UPDATE_IS_RECIPE_INGREDIENTS_PRODUCTS_LOADING';
export const UPDATE_RECIPE_INGREDIENT_PRODUCTS = 'UPDATE_RECIPE_INGREDIENT_PRODUCTS';
export const CLEAR_RECIPE_LIST_ITEMS = 'CLEAR_RECIPE_LIST_ITEMS';

/**
 * Update recipes list
 * @param  {Object} recipes
 * @return {Object}
 * @namespace Store/Recipe/Action/updateRecipesList */
export const updateRecipesList = (recipes) => ({
    type: UPDATE_RECIPES_LIST,
    recipes
});

/**
 * Update recipe categories
 * @param  {Object} recipeCategories
 * @return {Object}
 * @namespace Store/Recipe/Action/updateRecipeCategories */
export const updateRecipeCategories = (recipeCategories) => ({
    type: UPDATE_RECIPE_CATEGORIES,
    recipeCategories
});

/**
 * Update current recipes category data
 * @param  {Object} currentCategoryData
 * @return {Object}
 * @namespace Store/Recipe/Action/updateCurrentCategoryData */
export const updateCurrentCategoryData = (currentCategoryData) => ({
    type: UPDATE_CURRENT_CATEGORY_DATA,
    currentCategoryData
});

/**
 * Update recipe data
 * @param  {Object} recipeData
 * @return {Object}
 * @namespace Store/Recipe/Action/updateRecipeData */
export const updateRecipeData = (recipeData) => ({
    type: UPDATE_RECIPE_DATA,
    recipeData
});

/**
 * Update recipes filters
 * @param  {Object} recipeFilters
 * @return {Object}
 * @namespace Store/Recipe/Action/updateRecipesFilters */
export const updateRecipesFilters = (recipeFilters) => ({
    type: UPDATE_RECIPES_FILTERS,
    recipeFilters
});

/**
 * Update is recipes loading
 * @param  {Boolean} isRecipesLoading
 * @return {Object}
 * @namespace Store/Recipe/Action/updateIsRecipesLoading */
export const updateIsRecipesLoading = (isRecipesLoading) => ({
    type: UPDATE_IS_RECIPES_LOADING,
    isRecipesLoading
});

/**
 * Update is recipe banner visible
 * @param  {Boolean} isRecipeBannerVisible
 * @return {Object}
 * @namespace Store/Recipe/Action/updateIsRecipeBannerVisible */
export const updateIsRecipeBannerVisible = (isRecipeBannerVisible) => ({
    type: UPDATE_IS_RECIPE_BANNER_VISIBLE,
    isRecipeBannerVisible
});

/**
 * Update isCurrentCategoryLoading
 * @param  {Boolean} isCurrentCategoryLoading
 * @return {Object}
 * @namespace Store/Recipe/Action/updateIsCurrentRecipeCategoryLoading */
export const updateIsCurrentRecipeCategoryLoading = (isCurrentCategoryLoading) => ({
    type: UPDATE_IS_CURRENT_RECIPE_CATEGORY_LOADING,
    isCurrentCategoryLoading
});
/**
 * Update isRecipesCategoriesLoading
 * @param  {Boolean} isRecipesCategoriesLoading
 * @return {Object}
 * @namespace Store/Recipe/Action/updateIsRecipesCategoriesLoading */
export const updateIsRecipesCategoriesLoading = (isRecipesCategoriesLoading) => ({
    type: UPDATE_IS_RECIPES_CATEGORIES_LOADING,
    isRecipesCategoriesLoading
});

/**
 * Update isRecipeIngredientsProductsLoading
 * @param  {Boolean} isRecipeIngredientsProductsLoading
 * @return {Object}
 * @namespace Store/Recipe/Action/updateIsRecipeIngredientsProductsLoading */
export const updateIsRecipeIngredientsProductsLoading = (isRecipeIngredientsProductsLoading) => ({
    type: UPDATE_IS_RECIPE_INGREDIENTS_PRODUCTS_LOADING,
    isRecipeIngredientsProductsLoading
});

/**
 * Update the products for the current ingredient
 * @param  {Object} recipeIngredientProducts
 * @return {Object}
 * @namespace Store/Recipe/Action/updateRecipeIngredientProducts */
export const updateRecipeIngredientProducts = (recipeIngredientProducts) => ({
    type: UPDATE_RECIPE_INGREDIENT_PRODUCTS,
    recipeIngredientProducts
});

/**
 * clears items in the recipes list
 * @return {Object}
 * @namespace Store/Recipe/Action/clearRecipesListItems */
export const clearRecipesListItems = () => ({
    type: CLEAR_RECIPE_LIST_ITEMS
});
