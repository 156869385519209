import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    CategoryPaginationContainer,
} from "Component/CategoryPagination/CategoryPagination.container";

import { updateBlogPosts } from "Store/Blog/Blog.action";
import { getCurrentPage } from "Util/Url";

import BlogPagination from "./BlogPagination.component";

export const BlogDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/Blog/Blog.dispatcher"
);

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    totalBlogPages: state.BlogReducer.totalBlogPages,
    isLoading: state.BlogReducer.isBlogLoading,
    anchorTextNext: state.ConfigReducer.anchor_text_for_next,
});

export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateBlogPosts: (blogPosts) => dispatch(updateBlogPosts(blogPosts)),
});

export class BlogPaginationContainer extends CategoryPaginationContainer {
    constructor(props) {
        super(props);

        const {
            pageUrlKey,
            location: { search },
        } = props;

        const currentPage = getCurrentPage(search, pageUrlKey);

        this.state = {
            currentPage,
        };
    }

    onPageSelect(pageNumber) {
        const { getPosts, updateBlogPosts } = this.props;

        updateBlogPosts({});
        getPosts(pageNumber).then(() =>
            this.setState({ currentPage: pageNumber })
        );
    }

    containerProps = () => {
        const { totalBlogPages } = this.props;

        return {
            totalPages: totalBlogPages,
        };
    };

    containerFunctions = {
        ...this.containerFunctions(),
        onPageSelect: this.onPageSelect.bind(this),
    };

    render() {
        const {
            location: { pathname },
        } = this.props;

        return (
            <BlogPagination
                pathname={pathname}
                {...this.props}
                {...this.state}
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BlogPaginationContainer)
);
