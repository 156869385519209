/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright Â© Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Header as SourceHeader } from "SourceComponent/Header/Header.component";
import { createRef } from "react";
import CmsBlock from "Component/CmsBlock";
import { lazy, Suspense } from "react";
import OfflineNotice from "Component/OfflineNotice";
import SearchField from "Component/SearchField";
import ClickOutside from "Component/ClickOutside";
import Link from "Component/Link";
import history from "Util/History";
import { appendWithStoreCode } from "Util/Url";
import { isMobile } from "Util/Mobile";
import { componentLoader } from "Util/Component";
import Menu from "Component/Menu";
import EcomMenu from "Component/EcomMenu";
import HeaderClosestStore from "Component/HeaderClosestStore";
import { DEFAULT_LOCATION_CODE } from "Component/ClosestStoreOverlay/ClosestStoreOverlay.config";
import SliderHtml from "Component/SliderHtml";
import { setMainMarginTop } from "Util/Window";

import {
    HEADER_DISCLAIMER_CMS,
    WELCOME_POPUP_CMS,
    WELCOME_POPUP_DELIVERY_CMS,
    MENU_TAB,
} from "./config";

import { CART_OVERLAY, CUSTOMER_WISHLIST, SEARCH } from "./Header.config";

import "./Header.override.style.scss";
import { ReactComponent as CarIcon } from "./icons/car.svg";
import { ReactComponent as Logo } from "./icons/logo.svg";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as CardIcon } from "./icons/card.svg";
import { ReactComponent as FavListIcon } from "./icons/favlist.svg";
import { ReactComponent as AccountIcon } from "./icons/account.svg";
import { ReactComponent as CartIcon } from "./icons/cart.svg";
import { ReactComponent as OpenMenuIcon } from "./icons/menu.svg";
import { ReactComponent as TruckIcon } from "./icons/truck.svg";
import { DidomiSDK } from '@didomi/react';


export const CartOverlay = lazy(() =>
    componentLoader(
        () =>
            import(
                /* webpackMode: "lazy", webpackChunkName: "overlay" */ "Component/CartOverlay"
            ),
        "Component/CartOverlay"
    )
);
export const MyAccountOverlay = lazy(() =>
    componentLoader(
        () =>
            import(
                /* webpackMode: "lazy", webpackChunkName: "overlay" */ "Component/MyAccountOverlay"
            ),
        "Component/MyAccountOverlay"
    )
);
export class Header extends SourceHeader {
    renderMap = () => {
        const { isCheckout } = this.props;

        // Special header for checkout
        if (isCheckout) {
            return {
                logo: this.renderLogo.bind(this),
                securityPayments: this.renderSecurePayments.bind(this),
            };
        }

        return {
            cancel: this.renderCancelButton.bind(this),
            back: this.renderBackButton.bind(this),
            close: this.renderCloseButton.bind(this),
            title: this.renderTitle.bind(this),
            logo: this.renderLogo.bind(this),
            search: this.renderSearchField.bind(this),
            storeLocator: this.renderStoreLocator.bind(this),
            loyalty: this.renderLoyalty.bind(this),
            wishList: this.renderWishList.bind(this),
            account: this.renderAccount.bind(this),
            minicart: this.renderMinicart.bind(this),
            clear: this.renderClearButton.bind(this),
            edit: this.renderEditButton.bind(this),
            ok: this.renderOkButton.bind(this),
        };
    };

    state = {
        locations: "",
        showMinicart: false,
        showMyAccountMenu: false,
    };

    promotion = createRef();

    static defaultProps = {
        logo_alt: "AVRIL logo",
        logo_height: 25,
        logo_width: 200,
        showMyAccountLogin: false,
        header_logo_src: "",
        isLoading: true,
    };

    componentDidMount() {
        window.addEventListener("scroll", (e) => this.scrollHandler(e))
    }

    componentDidUpdate(prevProps) {
        const { device: { isMobile }, closeMobileMenu } = this.props;
        const { locations } = this.state;
        
        if (location.pathname !== locations) {
            this.setState({
                locations: location.pathname,
                showMyAccountMenu: false,
            });
            closeMobileMenu();
            if (isMobile) {
                window.scrollTo(0, 0);
            }
        }

        setMainMarginTop(isMobile);

    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollHandler);
    }

    scrollHandler(e) {
        if (!e.target.documentElement) {
            return;
        }

        const { device, isDisclaimerHidden } = this.props;
        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        const isScrollDisable = /scrollDisabled/.test(
            e.target.documentElement?.className
        );

        if (isScrollDisable) {
            return;
        }

        if (scrollTop < 10 || (isDisclaimerHidden && this.logoRef.current)) {
            !!this.logoRef.current
                ? (this.logoRef.current.style.transform = "translateY(0px)")
                : null;
        } else if (this.logoRef.current) {
            if (device.isMobile) {
                !!this.logoRef.current
                    ? (this.logoRef.current.style.transform = `translateY(-46px)`)
                    : null;
            } else {
                !!this.logoRef.current
                    ? (this.logoRef.current.style.transform = `translateY(-35px)`)
                    : null;
            }
        }
    }

    /**
     * Called once we have the callback from the SDK informing that Didoni is loaded and ready
     */
    onDidomiReady(didomi) {
        this.didomiObject = didomi;
    }

    renderConsent() {

        const { storeLanguage } = this.props;

        if (!storeLanguage) {
            return null;
        }

        const didomiConfig = {
            app: {
                apiKey: "4623bffb-f415-4420-a8cf-1a9be35d640c",
            },
            languages: {
                enabled: [storeLanguage],
                default: storeLanguage
            }
        };

        const consentButton = document.getElementById("consentButton");
        if (consentButton)
            consentButton.addEventListener("click", () => {
                Didomi.preferences.show();
            })

        return (
            <DidomiSDK
                config={didomiConfig}
                iabVersion={2} 
                noticeId="mxT37DwD" 
                gdprAppliesGlobally={true}
                sdkPath="https://sdk.privacy-center.org/"
                embedTCFStub={true}
                onReady={didomi => console.log('Didomi SDK is loaded and ready', didomi)}
                onConsentChanged={cwtToken => console.log('A consent has been given/withdrawn', cwtToken)}
                onNoticeShown={() => console.log('Didomi Notice Shown')}
                onNoticeHidden={() => console.log('Didomi Notice Hidden')}
                onNoticeBackdropclick={() => console.log('Didomi Notice Backdrop Click')}
                onNoticeClickAgree={() => console.log('Didomi Notice Click Agree')}
                onNoticeClickMoreInfo={() => console.log('Didomi Notice Click More Info')}
                onPreferencesClickAgreeToAll={() => console.log('Didomi Preferences Click Agree to All')}
                onPreferencesClickDisagreeToAll={() => console.log('Didomi Preferences Click Disagree to All')}
                onPreferencesClickPurposeAgree={purposeId => console.log('Didomi Preferences Click Purpose Agree', purposeId)}
                onPreferencesClickPurposeDisagree={purposeId => console.log('Didomi Preferences Click Purpose Disagree', purposeId)}
                onPreferencesClickViewVendors={() => console.log('Didomi Preferences Click View Vendors')}
                onPreferencesClickSaveChoices={() => console.log('Didomi Preferences Click Save Choices')}
                onPreferencesClickVendorAgree={vendorId => console.log('Didomi Preferences Click Vendor Agree', vendorId)}
                onPreferencesClickVendorDisagree={vendorId => console.log('Didomi Preferences Click Vendor Disagree', vendorId)}
                onPreferencesClickVendorSaveChoices={() => console.log('Didomi Preferences Click Vendor Save Choices')}
            />
        )
    }

    renderLogo(isVisible = false) {
        return (
            <Link
                to="/"
                aria-label="Go to homepage by clicking on AVRIL logo"
                aria-hidden={!isVisible}
                tabIndex={isVisible ? 0 : -1}
                block="Header"
                elem="LogoWrapper"
                mods={{ isVisible }}
                key="logo"
            >
                <Logo />
            </Link>
        );
    }

    renderNavigationState() {
        const {
            navigationState: { name, hiddenElements = [] },
        } = this.props;

        // Get current page/state render methods
        const source = this.stateMap[name]
            ? this.stateMap[name]
            : this.stateMap[this.defaultStateName];

        // Return defined render methods for current page/state
        // * Dont render methods which id's are passed inside hiddenElements
        return Object.entries(this.renderMap()).map(([key, renderFunction]) =>
            renderFunction(source[key] && !hiddenElements.includes(key), key)
        );
    }

    renderSecurePayments() {
        return (
            <div block="Header" elem="SecurePayments">
                <p>{__("Secure Payment")}</p>
            </div>
        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
            setIsSearchWindowOpened,
            isSearchWindowOpened,
            device,
            searchCategoriesSectionRef,
            searchProductsScrollableRef,
            searchWindowRef,
            activeType,
            searchTypes,
            setSearchType
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        if (isCheckout) {
            return null;
        }

        return (
            <SearchField
                key="search"
                searchCriteria={searchCriteria}
                onSearchOutsideClick={onSearchOutsideClick}
                onSearchBarFocus={onSearchBarFocus}
                onSearchBarChange={onSearchBarChange}
                onClearSearchButtonClick={onClearSearchButtonClick}
                setIsSearchWindowOpened={setIsSearchWindowOpened}
                isSearchWindowOpened={isSearchWindowOpened}
                isVisible={isVisible}
                isActive={name === SEARCH}
                hideActiveOverlay={hideActiveOverlay}
                searchCategoriesSectionRef={searchCategoriesSectionRef}
                searchWindowRef={searchWindowRef}
                searchProductsScrollableRef={searchProductsScrollableRef}
                activeType={ activeType }
                searchTypes={ searchTypes }
                setSearchType={ setSearchType }
            />
        );
    }

    renderSearchFieldMobile(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            isCheckout,
            hideActiveOverlay,
            isSearchWindowOpened,
            setIsSearchWindowOpened,
            searchCategoriesSectionRef,
            searchWindowRef,
            searchProductsScrollableRef,
            activeType,
            searchTypes,
            setSearchType
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <SearchField
                key="search"
                isVisible
                isActive
                searchCriteria={searchCriteria}
                onSearchOutsideClick={onSearchOutsideClick}
                onSearchBarFocus={onSearchBarFocus}
                onSearchBarChange={onSearchBarChange}
                setIsSearchWindowOpened={setIsSearchWindowOpened}
                searchCategoriesSectionRef={searchCategoriesSectionRef}
                searchWindowRef={searchWindowRef}
                isSearchWindowOpened={isSearchWindowOpened}
                onClearSearchButtonClick={onClearSearchButtonClick}
                searchProductsScrollableRef={searchProductsScrollableRef}
                hideActiveOverlay={hideActiveOverlay}
                activeType={ activeType }
                searchTypes={ searchTypes }
                setSearchType={ setSearchType }
            />
        );
    }

    handleRedirectToLoginPage() {
        history.push({ pathname: appendWithStoreCode("/login") });
    }

    renderAccountButton(isVisible) {
        const { onMyAccountButtonClick, isSignedIn } = this.props;
        const { showMyAccountMenu } = this.state;

        return (
            <>
            <ClickOutside
                onClick={() => !!showMyAccountMenu ? this.setState({ showMyAccountMenu: false }) : null}
                key={'MyAccountMenu'}
            >
                <button
                    block="Header"
                    elem={`MyAccountWrapper ${isSignedIn ? "UserLogged" : ""}`}
                    tabIndex="0"
                    onClick={() => isSignedIn ? this.setState({ showMyAccountMenu: true }) : onMyAccountButtonClick("dashboard")}
                    aria-label="Open my account"
                    id="myAccount"
                    style={{ marginRight: "10px" }}
                >
                    <AccountIcon />
                </button >
                {this.renderMyAccountMenu()}
            </ClickOutside>
            <div block="DarkOverlay" mods={{isOpen:!!showMyAccountMenu}}></div>
            </>
        );
    }


    renderAccountOverlay() {
        const { isCheckout, showMyAccountLogin, onSignIn } = this.props;

        // This is here to prevent the popup-suspense from rendering
        if (!showMyAccountLogin) {
            return null;
        }

        return (
            <Suspense fallback={this.renderAccountOverlayFallback()}>
                <MyAccountOverlay onSignIn={onSignIn} isCheckout={isCheckout} />
            </Suspense>
        );
    }

    renderMyAccountMenu() {
        const { showMyAccountMenu } = this.state;
        const { onSignOut } = this.props;
        return (
            <div block="MyAccountMenu" mods={{ isOpen: showMyAccountMenu }}>
                <ul>
                    <li block="MyAccountMenu" elem="Item">
                        <Link to={appendWithStoreCode("/my-account/")}>
                            {__("Dashboard")}
                        </Link>
                    </li>
                    <li block="MyAccountMenu" elem="Item">
                        <Link to={appendWithStoreCode("/my-account/my-orders")}>
                            {__("My orders")}
                        </Link>
                    </li>
                    <li block="MyAccountMenu" elem="Item">
                        <Link
                            to={appendWithStoreCode("/my-account/my-wishlist")}
                        >
                            {__('My wishlist')}
                        </Link>
                    </li>
                    <li block="MyAccountMenu" elem="Item">
                        <Link
                            to={appendWithStoreCode("/my-account/loyalty-card")}
                        >
                            {__('My card')}
                        </Link>
                    </li>
                    <li block="MyAccountMenu" elem="Item">
                        <Link
                            to={appendWithStoreCode("/my-account/my-marguerites")}
                        >
                            {__('My Marguerites')}
                        </Link>
                    </li>
                    <li block="MyAccountMenu" elem="Item">
                        <Link
                            to={appendWithStoreCode("/")}
                            onClick={() => {
                                onSignOut();
                                this.setState({ showMyAccountMenu: false });
                            }}
                        >
                            {__("Logout")}
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }

    renderTitle(isVisible = false) {
        const {
            navigationState: { title },
            device,
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <h2 key="title" block="Header" elem="Title" mods={{ isVisible }}>
                {title}
            </h2>
        );
    }

    renderBackButton(isVisible = false) {
        const { onBackButtonClick, device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <button
                key="back"
                block="Header"
                elem="Button"
                mods={{ type: "back", isVisible }}
                onClick={onBackButtonClick}
                aria-label="Go back"
                aria-hidden={!isVisible}
                tabIndex={isVisible ? 0 : -1}
            />
        );
    }

    renderStoreLocatorModal() {
        const {
            isStoresModalOpen,
            onLocationClick,
            onLocationPopupAction,
            isLocationPopupOpen,
            setIsStoresModalOpen,
        } = this.props;

        return (
            <HeaderClosestStore
                isStoresModalOpen={isStoresModalOpen}
                onLocationClick={onLocationClick}
                onLocationPopupAction={onLocationPopupAction}
                isLocationPopupOpen={isLocationPopupOpen}
                setIsStoresModalOpen={setIsStoresModalOpen}
            />
        );
    }

    renderStoreLocatorPickup() {
        const { closestStore: { pickup_location_code } = {} } = this.props;

        const isDefaultStore = pickup_location_code === DEFAULT_LOCATION_CODE;

        if (isDefaultStore || !pickup_location_code) {
            return null;
        }

        return (
            <span block="Header" elem="LinkPickupText">
                {__("Pick-up")} :
            </span>
        );
    }

    renderStoreLocator() {
        const {
            onLocationClick,
            closestStore: { name, pickup_location_code } = {},
        } = this.props;

        const isDefaultStore = pickup_location_code === DEFAULT_LOCATION_CODE;

        const Location = isDefaultStore ? TruckIcon : CarIcon;
        const text = isDefaultStore ? __("Currently shopping for home delivery, ") : __(`Currently shopping for pick up %s, `, name);
        const textClickable = isDefaultStore ? __("switch to pick up") : __("modify");
        return (
            <div
                block="Header"
                elem="LinkIcon"
                mods={{ LocationText: true, isDefaultStore }}
            >
                {this.renderWelcomeStoreOverlay()}
                <div block="Header" elem="DeliveryMethod">
                    <Location onClick={onLocationClick} />
                    <span block="Header" elem="LinkText">
                        {text}
                    </span>
                    <button onClick={onLocationClick}>
                        <span block="Header" elem="LinkText">
                            {textClickable}
                        </span>
                    </button>
                </div>
                {this.renderStoreLocatorModal()}
            </div>
        );
    }

    renderWelcomeStoreOverlay() {
        const {
            isWelcomeOpen,
            onWelcomeCmsLoad,
            isWelcomeCMSLoaded,
            closestStore: { pickup_location_code } = {},
        } = this.props;

        if (!isWelcomeOpen) {
            return null;
        }

        const isDefaultStore = pickup_location_code === DEFAULT_LOCATION_CODE;

        const id = isDefaultStore
            ? WELCOME_POPUP_DELIVERY_CMS
            : WELCOME_POPUP_CMS;

        return (
            <>
                {/**
                 * WindoweOverlay is done with box-shadow and doesn't covers the whole page content.
                 * InvisibleOverlay is used for this intent.
                 */}
                <div
                    block="Header"
                    elem="WindowedOverlay"
                    mods={{ isWelcomeCMSLoaded, isDefaultStore }}
                />
                <div block="Header" elem="InvisibleOverlay" />
                <CmsBlock identifier={id} onLoad={onWelcomeCmsLoad} />
            </>
        );
    }

    renderLoyalty() {
        const { device, onMyAccountButtonClick, isSignedIn } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <div block="Header" elem="LinkIcon">
                <a
                    block="loyalty-card"
                    onClick={() => onMyAccountButtonClick("loyalty-card", true)}
                    title={__(
                        isSignedIn
                            ? "Ma carte Privilège"
                            : "Activer ma carte Privilège"
                    )}
                >
                    <CardIcon />
                </a>
            </div>
        );
    }

    renderWishList() {
        const { device, onMyAccountButtonClick } = this.props;
        if (device.isMobile) {
            return null;
        }
        return (
            <div block="Header" elem="LinkIcon">
                <a onClick={() => onMyAccountButtonClick("my-wishlist")}>
                    <FavListIcon />
                </a>
            </div>
        );
    }

    renderMinicartButton() {
        const { onMinicartButtonClick } = this.props;

        return (
            <button
                block="Header"
                elem="MinicartButtonWrapper"
                tabIndex="0"
                onClick={() => {
                    this.setState({ showMinicart: true });
                    onMinicartButtonClick();
                }}
            >
                <CartIcon />
                {this.renderMinicartItemsQty()}
            </button>
        );
    }

    renderMobileMiniCart() {
        const { device, cartTotals } = this.props;        
        const disabled = cartTotals?.items?.length === 0;

        if (!device.isMobile) {
            return null;
        }

        return (
            <button
                block="Header"
                elem="Minicart"
                onClick={() => {
                    history.push({
                        pathname: appendWithStoreCode('/cart')
                    });
                }}
                disabled={ disabled }
            >
                <CartIcon />
                {this.renderMinicartItemsQty()}
            </button>
        );
    }

    renderMinicart(isVisible = false) {
        const { isCheckout, device } = this.props;

        if (device.isMobile || isCheckout) {
            return null;
        }

        return (
            <div
                block="Header"
                elem="Button"
                mods={{ isVisible, type: "minicart" }}
            >
                {this.renderMinicartButton()}
            </div>
        );
    }

    renderMinicartOverlay() {
        const { shouldRenderCartOverlay, onMinicartOutsideClick } = this.props;

        if (!shouldRenderCartOverlay) {
            onMinicartOutsideClick();
        }

        return (
            <div
                onClick={(e) => {
                    e.target === e.currentTarget.children[0]
                        ? onMinicartOutsideClick()
                        : null;
                }}
            >
                <Suspense fallback={this.renderMinicartOverlayFallback()}>
                    <CartOverlay
                        closeMiniCart={onMinicartOutsideClick}
                        shouldRenderCartOverlay={shouldRenderCartOverlay}
                    />
                </Suspense>
            </div>
        );
    }

    renderTopMenu() {
        return null;
    }

    renderDisclaimerCms() {
        const { closeDisclaimer, isDisclaimerHidden, isCheckout } = this.props;

        return (
            <div
                block="Menu"
                elem="Promotion"
                mods={{ isDisclaimerHidden, isCheckout }}
                ref={this.promotion}
            >
                {<SliderHtml identifier={HEADER_DISCLAIMER_CMS} />}
                <button type="button" onClick={closeDisclaimer}>
                    <CloseIcon />
                </button>
            </div>
        );
    }

    onMenuButtonClick() {
        const {
            navigationState: { name },
        } = this.props;

        if (name === MENU_TAB) {
            // if we already are in menu
            browserHistory.push(appendWithStoreCode("/menu"));
        } else if (this.lastSeenMenu <= 0) {
            // if we have not yet seen menu
            browserHistory.push(appendWithStoreCode("/menu"));
        } else {
            // otherwise go to last remembered category
            browserHistory.go(-this.lastSeenMenu);
        }

        this.lastSeenMenu = 0;
    }

    renderMobileMenuButton() {
        const { device, handleMobileMenuButtonClick, showMobileMenu } =
            this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <button
                type="button"
                onClick={handleMobileMenuButtonClick}
                block="Header"
                elem="menuButton"
                mods={{ showMobileMenu }}
                style={{ margin: "0" }}
            >
                <OpenMenuIcon class="Header-MenuIcon" />
            </button>
        );
    }

    renderMenu() {
        const { isCheckout, device } = this.props;
        const { locations } = this.state;
        if (device.isMobile || isCheckout) {
            return null;
        }

        return <Menu locations={locations} />;
    }

    renderHeaderForFiltersPortalSlot() {
        return <div id="HeaderForFiltersPortalSlot"></div>;
    }

    render() {
        const { locations } = this.state;
        const { stateMap } = this;
        const { isEcom, closeMobileMenu, storeLanguage } = this.props;
        const isCart = locations.includes("cart");
        const {
            navigationState: { name },
            isCheckout,
            device,
            isDisclaimerHidden,
            showMobileMenu,
            menuPromotionRef,
            category: { allCategory },
        } = this.props;
        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <>
                <section block="Header" elem="Wrapper" ref={menuPromotionRef}>
                    <header
                        block="Header"
                        mods={{ name, isCheckout, isDisclaimerHidden, isCart }}
                        mix={{ block: "FixedElement", elem: "Top" }}
                        ref={this.logoRef}
                    >
                        {window.innerWidth < 811
                            ? this.renderDisclaimerCms()
                            : null}
                        {window.innerWidth > 811
                            ? this.renderDisclaimerCms()
                            : null}
                        <nav block="Header" elem="Nav" mods={{ isCheckout }}>
                            {this.renderNavigationState()}
                            {this.renderMobileMiniCart()}
                            {this.renderMobileMenuButton()}
                        </nav>
                        {isMobile.any() && this.renderSearchFieldMobile()}
                        {this.renderMenu()}
                        <OfflineNotice />
                        {device.isMobile || isCheckout ? null : (
                            <EcomMenu
                                allCategory={allCategory}
                                locations={locations}
                                isEcom={JSON.parse(isEcom)}
                                headerRef={this.logoRef}
                            />
                        )}
                    </header>
                    {/* {isMobile.any() && this.renderSearchFieldMobile()} */}
                </section>
                {
                    device.isMobile
                        ? <Menu
                            showMobileMenu={showMobileMenu}
                            allCategory={allCategory}
                            closeMobileMenu={closeMobileMenu}
                            isEcom={JSON.parse(isEcom)}
                        />
                        : null
                }
                { this.renderHeaderForFiltersPortalSlot() }
                { this.renderMinicartOverlay() }
                { storeLanguage && this.renderConsent() }
            </>
        );
    }
}

export default Header;
