/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_POST_DATA = 'UPDATE_POST_DATA';
export const UPDATE_IS_POST_LOADING = 'UPDATE_IS_POST_LOADING';
export const UPDATE_IS_SIMILAR_POSTS_LOADING = 'UPDATE_IS_SIMILAR_POSTS_LOADING';
export const UPDATE_ARE_RELATED_PRODUCTS_LOADING = 'UPDATE_ARE_RELATED_PRODUCTS_LOADING';
export const UPDATE_POST_RELATED_PRODUCTS = 'UPDATE_POST_RELATED_PRODUCTS';
export const UPDATE_SIMILAR_POSTS = 'UPDATE_SIMILAR_POSTS';

/**
 * Update post data
 * @param  {Object} postData
 * @return {void}
 * @namespace Store/Cart/Action/updatePostData */
export const updatePostData = (postData) => ({
    type: UPDATE_POST_DATA,
    postData
});

/**
 * @param  {Boolean} isPostLoading
 * @return {void}
 * @namespace Store/Cart/Action/updateIsPostLoading */
export const updateIsPostLoading = (isPostLoading) => ({
    type: UPDATE_IS_POST_LOADING,
    isPostLoading
});

/**
 * @param  {Boolean} isSimilarPostsLoading
 * @return {void}
 * @namespace Store/Cart/Action/updateIsPostLoading */
export const updateIsSimilarPostLoading = (isSimilarPostsLoading) => ({
    type: UPDATE_IS_SIMILAR_POSTS_LOADING,
    isSimilarPostsLoading
});

/**
 * @param  {Boolean} areRelatedProductsLoading
 * @return {void}
 * @namespace Store/Cart/Action/updatePostData */
export const updateAreRelatedProductsLoading = (areRelatedProductsLoading) => ({
    type: UPDATE_ARE_RELATED_PRODUCTS_LOADING,
    areRelatedProductsLoading
});

/**
 * Update post related products
 * @param  {Array} postRelatedProducts
 * @return {void}
 * @namespace Store/Cart/Action/updatePostRelatedProducts */
export const updatePostRelatedProducts = (postRelatedProducts) => ({
    type: UPDATE_POST_RELATED_PRODUCTS,
    postRelatedProducts
});

/**
 * Update similar posts
 * @param  {Array} similarPosts
 * @return {void}
 * @namespace Store/Cart/Action/updateSimilarPosts */
export const updateSimilarPosts = (similarPosts) => ({
    type: UPDATE_SIMILAR_POSTS,
    similarPosts
});
