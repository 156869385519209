import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Image from "Component/Image";
import { ReactComponent as FlipIcon } from './icons/flip.svg';

import './Promotion.mock';
import './Promotion.style';

class Promotion extends PureComponent {
    static propTypes = {
        promotion: PropTypes.shape({
            name: PropTypes.string,
            createdAt: PropTypes.string,
            cards: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number,
                    title: PropTypes.string,
                    description: PropTypes.string,
                    date: PropTypes.string,
                    cardType: PropTypes.string,
                    status: PropTypes.string,
                    imageFront: PropTypes.string,
                    imageBack: PropTypes.string,
                    link: PropTypes.string,
                    specialLink: PropTypes.string,
                    imageSpecial: PropTypes.string,
                })
            ),
        }),
        id: PropTypes.number.isRequired
    };

    state = {
        showAllCards: false
    };

    handleFlipToggle = (event, card) => {
        const { isDesktop } = this.props;
        if (event.target.closest('[class="buyCTA"]')) {
            const formattedUrl = this.addHttps(card.link);
            window.open(formattedUrl, '_self');
            return;
        }
        if(card.cardType !== 'holiday' && !isDesktop) {
            const cardParent = event.currentTarget.closest('li');
            if (cardParent.getAttribute('data-flip') === 'active') {
                cardParent.removeAttribute('data-flip');
            } else {
                cardParent.setAttribute('data-flip', 'active');
            }
        }
        return
    }
      
    handleDocumentClick(event) {
        const parents = document.querySelectorAll('.PromotionCalendar li');
        parents.forEach((parent) => {
            if (parent.contains(event.target)) {
                return;
            }
            parent.removeAttribute('data-flip');
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick);
    } 

    addHttps(url) {
        const pattern = /^www\./i;
        if (pattern.test(url)) {
          return "https://" + url;
        }
        return url;
    }

    handleCardClick = (card) => {
        const formattedUrl = this.addHttps(card.link);
        if (card.status === 'active') {
            window.open(formattedUrl, '_self');
        }
    }

    handleSpecialFutureCardClick = (card) => {
        const formattedUrl = this.addHttps(card.specialLink);
        window.open(formattedUrl, '_self');
    }

    getDayCards = (cardDate) => {
        if(!cardDate) return;
        const day = parseInt(cardDate.split("-")[2]);
        return day
    }

    getMonthFromDate(date) {
        if(!date) return;
        return parseInt(date.split("-")[1]);
    }

    description(card) {
        const description = card.description;
        const maxCharacters = 50;
        if(!description) return;
        if (description.length > maxCharacters) {
            const cutText = `${description.slice(0, maxCharacters)}...`;
            return <span block="Description">{cutText}</span>;
        } else {
            return <span block="Description">{description}</span>;
        }
    }

    renderCardFront(card) {
        return (
            <div block="front" onClick={(event) => this.handleFlipToggle(event, card)}>
                <span block="Day">{this.getDayCards(card.date)}</span>
                {card.cardType !== 'holiday' && card.cardType !== 'special' && card.cardType !== 'empty' && <FlipIcon />}
                {(card.cardType === 'special' && card.status === 'active') && <FlipIcon />}
                {card.cardType === 'holiday' && (
                    <>
                        <p block="Title">{card.title}</p>
                        {card.link && <button block="buyCTA" onClick={this.handleCardClick(card)}>{__("Shop")}</button>}
                    </>
                )}
                {card.imageFront &&
                    <Image
                        src={`/media/avril/promotion/card/${card.imageFront}`}
                        alt={card.title}
                        mix={{
                            block: "Promotion",
                            elem: "ImageFront",
                            mix: { block: "Promotion", elem: "Image" },
                        }}
                    />
                }
            </div>
        );
    }

    renderCardBack(card) {
        const tamanhoTexto = card.title.length;
        let estiloTexto = {};
        if (tamanhoTexto <= 8) {
            estiloTexto = { fontSize: '20px' };
        } else if (tamanhoTexto <= 18) {
            estiloTexto = { fontSize: '18px' };
        } else if (tamanhoTexto <= 28) {
            estiloTexto = { fontSize: '16px' };
        } else {
            estiloTexto = { fontSize: '14px' };
        }
        return (
            <div block="back">
                <span block="Day">{this.getDayCards(card.date)}</span>
                {card.cardType != 'holiday' && card.cardType != 'special' && card.cardType != 'empty' && <FlipIcon onClick={(event) => this.handleFlipToggle(event, card)}/>}
                {(card.cardType === 'special' && card.status === 'active') && <FlipIcon onClick={(event) => this.handleFlipToggle(event, card)}/>}
                {card.imageBack &&
                    <Image
                        src={`/media/avril/promotion/card/${card.imageBack}`}
                        alt={card.title}
                        ratio="16x9"
                        mix={{
                            block: "Promotion",
                            elem: "ImageBack",
                            mix: { block: "Promotion", elem: "Image" },
                        }}
                    />
                }
                <p block="Title" style={estiloTexto}>{card.title}</p>
                {this.description(card)}
                {(card.status === 'active') && <button block="buyCTA" onClick={() => this.handleCardClick(card)}>{__("Shop")}</button>}
            </div>
        );
    }

    renderSpecialCardFuture(card) {
        return (
            <li
                key={card.id}
                mix={{
                    block: "Promotion",
                    elem: "Card",
                    mix: { block: "card", elem: `type--${card.cardType}` }
                }}
                title={card.name}
                data-status={card.status}
                data-date={card.date}>
                <div
                    block="front"
                    onClick={card.specialLink ? () => this.handleSpecialFutureCardClick(card) : null}
                    style={ card.specialLink && { cursor: 'pointer' } }>
                    <span block="Day">{this.getDayCards(card.date)}</span>
                    {card.imageSpecial &&
                        <Image
                            src={`/media/avril/promotion/card/${card.imageSpecial}`}
                            alt={card.title}
                            mix={{
                                block: "Promotion",
                                elem: "ImageSpecial",
                                mix: { block: "Promotion", elem: "Image" },
                            }}
                        />
                    }
                </div>
            </li>
        );
    }

    handleShowAllCards = () => {
        this.setState((prevState) => ({ showAllCards: !prevState.showAllCards }));
    };

    renderCardsMobile(){
        const { cards, note, isTablet } = this.props;
        // const cards = cardsMock;
        const activeCards = cards.filter(card => card.status === 'active');
        const upcomingCards = cards.filter(card => card.status === 'upcoming');
        const { showAllCards } = this.state;
        const qtyCards = isTablet ? 6 : 2;
        const visibleCards = showAllCards ? upcomingCards : upcomingCards.slice(0, qtyCards);
        return (
            <>
                {(activeCards.length > 0) && (
                    <>
                        <ul
                            mix={{
                                block: "PromotionCalendar",
                                mix: { block: "Mobile--active" }
                            }}
                            data-title={__("Promo of the day")}>
                            {activeCards.map((card) => (
                                <li
                                    key={card.id}
                                    mix={{
                                        block: "Promotion",
                                        elem: "Card",
                                        mix: { block: "card", elem: `type--${card.cardType}` }
                                    }}
                                    title={card.name}
                                    data-status={card.status}>
                                        {this.renderCardFront(card)}
                                        {this.renderCardBack(card)}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {(upcomingCards.length > 0) && (
                    <>
                        <ul
                            mix={{
                                block: "PromotionCalendar",
                                mix: { block: "Mobile--upcoming" }
                            }}
                            data-title={__("Promo to come")}
                            data-qty={upcomingCards.length}>                                
                            {visibleCards.map((card) => (
                                <li
                                    key={card.id}
                                    mix={{
                                        block: "Promotion",
                                        elem: "Card",
                                        mix: { block: "card", elem: `type--${card.cardType}` }
                                    }}
                                    title={card.name}
                                    data-status={card.status}>
                                        {this.renderCardFront(card)}
                                        {this.renderCardBack(card)}
                                </li>
                            ))}
                            {(upcomingCards.length === 1) && <li key="nextPromo" mix={{ block: "Promotion", elem: "Card", mix: { block: "card", elem: "type--next" }}}><div block="front"><p>{__("Unveiling this monday")}</p></div></li>}
                        </ul>
                        {(upcomingCards.length > qtyCards) &&<button block="showAllCardsCTA" onClick={this.handleShowAllCards}>{!showAllCards ? __("Show more") : __("Show less")}</button>}
                    </>
                )}                
                {note && <p block="noteMobile">{note}</p>}
            </>
        )
    }

    renderCards() {
        const { cards, note } = this.props;
        // const cards = cardsMock;
        // extract month and year from the first card
        const firstCard = cards[0];
        const [year, month, day] = firstCard.date.split('-');
        const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const firstDayOfMonth = new Date(year, month - 1, day);
        const firstDayIndex = firstDayOfMonth.getDay();
        const weekDaysOrdered = [...weekDays.slice(firstDayIndex), ...weekDays.slice(0, firstDayIndex)];
        
        return (
            <>
                <ul block="WeekDays">
                    {weekDaysOrdered.map((day) => (
                        <li key={day}>
                            {__(day)}
                        </li>
                    ))}
                </ul>
                <ul block="PromotionCalendar">
                    {cards.map((card) => (
                        <>
                        {(card.cardType === 'special') ? (
                            <>
                                {(card.status === 'future') ? (
                                    <>{this.renderSpecialCardFuture(card)}</>
                                ) : (
                                    <li
                                        key={card.id}
                                        mix={{
                                            block: "Promotion",
                                            elem: "Card",
                                            mix: { block: "card", elem: `type--${card.cardType}` }
                                        }}
                                        title={card.name}
                                        data-status={card.status}
                                    >
                                        {this.renderCardFront(card)}
                                        {this.renderCardBack(card)}
                                    </li>
                                )}
                            </>
                        ) : (
                            <>
                                {(card.cardType !== 'holiday' && card.status === 'future' ) || card.status === 'empty' ? (
                                    <li
                                        key={card.id}
                                        mix={{
                                            block: "Promotion",
                                            elem: "Card",
                                            mix: { block: "card", elem: `type--${card.cardType}` }
                                        }}
                                        title={card.name}
                                        data-status={card.status}
                                        data-date={card.date}>
                                            <div block="front">
                                                <span block="Day">{this.getDayCards(card.date)}</span>
                                            </div>
                                        </li>
                                ):(
                                    <li
                                        key={card.id}
                                        mix={{
                                            block: "Promotion",
                                            elem: "Card",
                                            mix: { block: "card", elem: `type--${card.cardType}` }
                                        }}
                                        title={card.name}
                                        data-status={card.status}
                                    >
                                        {this.renderCardFront(card)}
                                        {this.renderCardBack(card)}
                                    </li>
                                )}
                            </>
                        )}
                        </>
                    ))}
                    {note && <p block="note">{note}</p>}
                </ul>
            </>
        );
    }

    render() {
        const { title, description, isDesktop } = this.props;
        return (
            <section block="Promotion">
                <div block="centerContainer" >
                    <h3>{title}</h3>
                    <p block="subtitle">{description}</p>
                    {!isDesktop ? this.renderCardsMobile() : this.renderCards()}
                </div>
            </section>
        );
    }
}

export default Promotion;
