/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Field from 'Component/Field';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import {
    CANADA_ID,
    US_ID
} from './MyAccountCreateAccount.config';
import { getPostcodeMask, onPostcodeChange, validateBirthday } from 'Util/Form';
import { appendWithStoreCode } from 'Util/Url';
import Link from 'Component/Link';
import {
    MyAccountCreateAccount as SourceMyAccountCreateAccount,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';

import './MyAccountCreateAccount.override.style';
import { ReactComponent as InfoIcon } from './icon/info.svg';

export class MyAccountCreateAccount extends SourceMyAccountCreateAccount {
    __construct(props) {
        super.__construct(props);

        const {
            countries,
            default_country
        } = props;

        const countryId = default_country;
        const country = countries.find(({ id }) => id === countryId);
        const { available_regions: availableRegions } = country || {};
        const regionId = 'EMPTY';

        this.state = {
            countryId,
            availableRegions,
            regionId,
            first: true,
            two: false,
            telephone: '',
            postcodeMask: getPostcodeMask('', countryId),
            birthdayError: false,
            passwordStrength: {
                length: false,
                upperAndSpecial: false,
            },
            createAccountTermsChecked: false
        };
    }

    componentDidMount(){
        window.scrollTo({top:0})
    }

    componentDidUpdate(_, prevState) {
        const { countryId } = this.state;
        const { countryId: prevCountryId } = prevState;

        if (countryId !== prevCountryId) {
            this.setState({
                postcodeMask: getPostcodeMask('', countryId),
                postcode: ''
            });
        }
    }

    validatePassword = (password) => {
        const length = password.length >= 8;
        const upperAndSpecial =
            /[A-Z]/.test(password) &&
            /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
        this.setState({
            passwordStrength: {
                length,
                upperAndSpecial
            }
        });
    };

    getPasswordStrengthColor = (isStrengthValid) => {
        return isStrengthValid ? 'green' : 'black';
    };

    handleCreateAccountTermsChange = (event) => {
        const { checked } = event.target;
        this.setState({ createAccountTermsChecked: checked });
    };

    onCountryChange = (countryId) => {
        const { countries } = this.props;
        const country = countries.find(({ id }) => id === countryId);
        const { available_regions } = country;

        this.setState({
            countryId,
            availableRegions: available_regions || []
        });
    };

    renderRegionField() {
        const { availableRegions, regionId } = this.state;

        const options = [{ id: '', label: ' ', value:'' }];

        // avoiding error.
        if (Array.isArray(availableRegions)) {
            const regions = availableRegions
                .map(({ id, name }) => ({ id, label: name, value: id }));
            options.push(...regions);
        }

        return (
            <Field
                type="select"
                label={__('Province')}
                id="region_id"
                name="region_id"
                autocomplete="address-level1"
                validation={['notEmpty']}
                selectOptions={options}
                onChange={(regionId) => this.setState({ regionId })}
                value={regionId}
                isRequiredWithStar
            />
        );
    }

    renderPostCodeField() {
        const { countryId, postcode, postcodeMask } = this.state;

        const validations = ['notEmpty'];

        if (countryId === CANADA_ID) {
            validations.push('postal_CA');
        } else if (countryId === US_ID) {
            validations.push('postal_US');
        }

        return (
            <Field
                type="text"
                label={ __('Zip/Postal Code') }
                placeHolder={ __('Zip/Postal Code') }
                mask={ postcodeMask }
                id="postcode"
                name="postcode"
                autocomplete="postal-code"
                validation={ validations }
                isRequiredWithStar
                isControlled
                value={ postcode }
                onChange={ onPostcodeChange.bind(this, countryId) }
            />
        )
    }

    renderLoyaltyField() {
        const {
            onCartFieldChange,
            cardNumberValue,
            isLoyaltyFieldShow
        } = this.props;

        // Data Candy bonus card number.
        const mask = "1111 1111 1111 1111";

        if (!isLoyaltyFieldShow) {
            return null;
        }

        return (
            <div
                block="MyAccountCreateAccount"
                elem="LoyaltyWrapper"
            >
                <span
                    block="MyAccountCreateAccount"
                    elem="LoyaltyText"
                >
                    { __("If you own a Privilege card, please fill the required field. Please select 'No' if you did not receive a Privilege card in store") }
                </span>
                <Field
                    type="text"
                    label={__('Card number')}
                    isControlled
                    mask={ mask }
                    placeHolder={__('Enter card number')}
                    id="datacandy_card_number"
                    name="datacandy_card_number"
                    validation={['cardNumberLength', 'cardNumber', 'notEmpty']}
                    onChange={ onCartFieldChange }
                    value={ cardNumberValue }
                    isRequiredWithStar
                    autocomplete="off"
                />
            </div>
        )
    }

    renderLoyalty() {
        const { changeLoyaltyShowState, isLoyaltyFieldShow } = this.props;

        const isCheckedYes = isLoyaltyFieldShow === true; // 'Yes' radio button
        const isCheckedNo = isLoyaltyFieldShow === false; // 'No' radio button

        return (
            <>
                <span
                    block="MyAccountCreateAccount"
                    elem="SelectLoyaltyHeading"
                >
                    {__('Do you have an Avril Privilege card?')}
                </span>
                <div
                    block="MyAccountCreateAccount"
                    elem="SelectLoyalty"
                >
                    <div
                        block="Field"
                        elem="Radio"
                        id="no"
                        onClick={() => changeLoyaltyShowState(false)}
                        mods={isCheckedNo ? { isChecked: true } : {}}
                    />
                    <span>{ __('No') }</span>
                    <div
                        block="Field"
                        elem="Radio"
                        id="yes"
                        onClick={() => changeLoyaltyShowState(true)}
                        mods={isCheckedYes ? { isChecked: true } : {}}
                    />
                    <span>{ __('Yes') }</span>
                </div>
            </>
        );
    }

    renderCreateAccountPersonalInfoFields() {
        const currentDate = new Date();
        const minYear = currentDate.getFullYear() - 100;
        const minMonth = currentDate.getMonth() + 1;
        const minDay = currentDate.getDate();
        const minDateFormatted = `${minYear}-${minMonth < 10 ? '0' + minMonth : minMonth}-${minDay < 10 ? '0' + minDay : minDay}`;
        const maxYear = currentDate.getFullYear() - 14;
        const maxMonth = currentDate.getMonth() + 1;
        const maxDay = currentDate.getDate() - 1;
        const maxDateFormatted = `${maxYear}-${maxMonth < 10 ? '0' + maxMonth : maxMonth}-${maxDay < 10 ? '0' + maxDay : maxDay}`;

        return (
            <>
                <Field
                    type="text"
                    label={__('First Name')}
                    placeHolder={__('First Name')}
                    id="firstname"
                    name="firstname"
                    autocomplete="given-name"
                    validation={['notEmpty']}
                    isRequiredWithStar
                />
                <Field
                    type="text"
                    label={__('Last Name')}
                    placeHolder={__('Last Name')}
                    id="lastname"
                    name="lastname"
                    autocomplete="family-name"
                    validation={['notEmpty']}
                    isRequiredWithStar
                />
                <Field
                    type="date"
                    label={__('Birthday')}
                    id="date_of_birth"
                    name="date_of_birth"
                    autocomplete="bday"
                    validation={['notEmpty', 'birthdate']}
                    isRequiredWithStar
                    min={minDateFormatted}
                    max={maxDateFormatted}
                    onBlur={(e) => {
                        const input = e.target.value;
                        const validationError = validateBirthday(input);
                        if (validationError) {
                            this.setState({ birthdayError: true });
                        }
                    }}
                />
                <span
                    block="MyAccountCreateAccount"
                    elem="AdditionalText"
                >
                    {__('Receive a gift when you make a transaction during the month of your birthday!')}
                </span>
                <Field
                    type="text"
                    label={__('Email')}
                    placeHolder={__('Email')}
                    id="email"
                    name="email"
                    autocomplete="email"
                    validation={['notEmpty', 'email']}
                    isRequiredWithStar
                />
                
            </>
        )
    }

    renderCreateAccountAddressFields() {
        const { countries } = this.props;

        const mappedCountries = Array.isArray(countries)
            ? countries.map(({ id, label }) => ({ id, label, value: id }))
            : [];

        return (
            <>
                <Field
                    type="text"
                    label={__('Address line 1')}
                    placeHolder={__('Address line 1')}
                    id="street0"
                    name="street0"
                    autocomplete="address-line1"
                    validation={['notEmpty']}
                    isRequiredWithStar
                />
                <Field
                    type="text"
                    label={__('Address line 2')}
                    placeHolder={__('Address line 2')}
                    id="street1"
                    name="street1"
                    autocomplete="address-line2"
                />
                <Field
                    type="text"
                    label={__('City')}
                    placeHolder={__('City')}
                    id="city"
                    name="city"
                    autocomplete="City"
                    validation={['notEmpty']}
                    isRequiredWithStar
                />
                <Field
                    type="select"
                    label={__('Country')}
                    id="country_id"
                    name="country_id"
                    autocomplete="country"
                    selectOptions={ mappedCountries }
                    validation={['notEmpty']}
                    onChange={this.onCountryChange}
                    isRequiredWithStar
                />
                { this.renderRegionField()}
                { this.renderPostCodeField()}
            </>
        )
    }

    renderCreateAccountNewsletterFields() {
        const { storeCode } = this.props;
        const termsLink = storeCode === 'en' ? "/en/terms-and-conditions" : "/fr/termes-et-conditions";
        const privacyLink = storeCode === 'en' ? "/en/security-and-confidentiality" : "/fr/securite-et-confidentialite";
        return (
            <div block="MyAccountCreateAccount" elem="NewsletterCheckbox">
                <div block="MyAccountCreateAccount" elem="CheckboxCustom">
                    <input type='checkbox' 
                            id="create_account_terms" 
                            name="create_account_terms" 
                            title={__('You must agree to the terms and conditions and the privacy policy before signing up.')} 
                            onChange={(e) => this.handleCreateAccountTermsChange(e)} required 
                    />
                    <label htmlFor="create_account_terms">
                        {__('I accept the ')}
                        <a href={termsLink} target='_blank'>{__('terms and conditions ')}</a>
                        {__('and read the ')}
                        <a href={privacyLink}>{__('privacy\n policy ')}</a>
                        {__('of Marguerite')}
                    </label>
                </div>

                <Field
                    type="checkbox"
                    value="loyalty_newsletter"
                    label={__('I wish to receive information, offers and\n promotions related to the program Marguerite.')}
                    id="loyalty_newsletter"
                    name="loyalty_newsletter"
                />
                <Field
                    type="checkbox"
                    value="avril_newsletter"
                    label={__('I wish to receive the April newsletter that includes promotions and information on products and services.')}
                    id="avril_newsletter"
                    name="avril_newsletter"
                />
            </div>
        )
    }

    renderCreateAccountPassword() {
        const { passwordStrength } = this.state;
        return (
            <>
                <Field
                    type="password"
                    label={__('Password')}
                    placeHolder={__('Password')}
                    id="password"
                    name="password"
                    autocomplete="new-password"
                    validation={['notEmpty', 'password']}
                    isRequiredWithStar
                    canShowPassword
                    onChange={(password) => this.validatePassword(password)}
                />                
                
                <div block="PasswordValidation">
                    <InfoIcon />
                    <div>
                        <div style={{color: this.getPasswordStrengthColor(passwordStrength.length)}}>
                            {passwordStrength.length ? '✓' : '•'} {__('8 characters')}
                        </div>
                        <div style={{color: this.getPasswordStrengthColor(passwordStrength.upperAndSpecial)}}>
                            {passwordStrength.upperAndSpecial ? '✓' : '•'}  {__('At least 1 uppercase and 1 special character')}
                        </div>
                    </div>
                </div>

                <Field
                    type="password"
                    label={__('Confirm password')}
                    id="confirm_password"
                    name="confirm_password"
                    autocomplete="new-password"
                    validation={['notEmpty', 'password', 'password_match']}
                    isRequiredWithStar
                    canShowPassword
                />
            </>
        )
    }

    renderSubmitButton() {
        return (
            <div block="MyAccountCreateAccount" elem="Buttons">
                <button
                    block="Button-Important"
                    type="submit"
                >
                    {__('Sign up')}
                </button>
            </div>
        );
    }

    renderBackToLoginButton() {
        return (
            <div block="MyAccountCreateAccount" elem="ButtonLogin">
                <span>{__('Already a member ?')}</span>
                <Link to={appendWithStoreCode('/login')}>
                    <button
                        block="Button"
                        mods={{ likeLink: true, darkLink: true }}
                    >
                        {__('Login')}
                    </button>
                </Link>
            </div>
        )
    }

    renderCreateAccountFields() {
        return (
            <fieldset block="MyAccountCreateAccount" elem="Legend">
                <legend>{__('Create Account')}</legend>
                { this.renderCreateAccountPersonalInfoFields()}
                { this.renderCreateAccountPassword()}
                { this.renderLoyalty()}
                { this.renderLoyaltyField()}
                { this.renderCreateAccountNewsletterFields()}
            </fieldset>
        );
    }

    render() {
        const { onCreateAccountAttempt, onCreateAccountSuccess, isLoadingForm } = this.props;

        return (
            <div
                block="MyAccountCreateAccount"
                elem="FormWrapper"
            >
                <Loader isLoading={ isLoadingForm } />
                <Form
                    key="create-account"
                    onSubmit={onCreateAccountAttempt}
                    onSubmitSuccess={onCreateAccountSuccess}
                    onSubmitError={onCreateAccountAttempt}
                >
                    {this.renderCreateAccountFields()}
                    <div block="MyAccountCreateAccount" elem="Actions">
                        {this.renderSubmitButton()}
                        {this.renderBackToLoginButton()}
                    </div>
                </Form>
            </div>
        );
    }
};

export default MyAccountCreateAccount;
