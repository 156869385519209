import { PureComponent } from "react";

import TextPlaceholder from "Component/TextPlaceholder";
import BlogWidget from "Component/BlogWidget";
import Link from "Component/Link";

import { isMobile as isMobileUtil } from "Util/Mobile";

import "./BlogCategoriesWidget.style";
import { appendWithStoreCode } from "Util/Url";

export class BlogCategoriesWidget extends PureComponent {
    renderPlaceholder() {
        return (
            <div block="BlogCategoriesWidget" elem="CategoryLink">
                <TextPlaceholder length="short" />
                <TextPlaceholder
                    length="custom"
                    mix={{
                        block: "BlogCategoriesWidget",
                        elem: "Placeholder",
                        mods: { mini: true },
                    }}
                />
            </div>
        );
    }

    renderNoCategoriesPlaceholders() {
        return new Array(3).fill(null).map(this.renderPlaceholder);
    }

    renderToLandingPageLink() {
        const {
            isLandingLinkVisible,
            device: { isMobile },
            blogSettings: { search_engine_route } = {},
        } = this.props;

        if (isMobile || !isLandingLinkVisible) {
            return null;
        }

        return (
            <Link
                to={appendWithStoreCode(`/${ search_engine_route }`)}
                mix={{ block: "BlogCategoriesWidget", elem: "LandingLink" }}
            >
                {__("View all")}
            </Link>
        );
    }

    renderCategories() {
        const { blogCategories: { items } = {} } = this.props;

        const renderMethod = items?.length
            ? () => items.map(this.renderCategory)
            : this.renderNoCategoriesPlaceholders.bind(this);

        return (
            <div block="BlogCategoriesWidget" elem="CategoriesWrapper">
                {renderMethod()}
                {this.renderToLandingPageLink()}
            </div>
        );
    }

    renderCategory = ({ url, name, category_id, post_count }) => {
        const { activeCategoryId } = this.props;
        return (
            <Link
                block="BlogCategoriesWidget"
                elem="CategoryLink"
                mods={{ isActive: category_id === activeCategoryId }}
                to={url}
            >
                <p block="BlogCategoriesWidget" elem="CategoryName">
                    {name}
                </p>
                <span block="BlogCategoriesWidget" elem="CategoryPostsCount">
                    {post_count}
                </span>
            </Link>
        );
    };

    render() {
        const { blogCategories: { items } = {}, isBlogLoading } = this.props;

        if (!items && !isBlogLoading) {
            return null;
        }

        const title = !(isMobileUtil.tablet() || isMobileUtil.any())
            ? __("Categories")
            : null;

        return (
            <BlogWidget mix={{ block: "BlogCategoriesWidget" }} title={title}>
                {this.renderCategories()}
            </BlogWidget>
        );
    }
}

export default BlogCategoriesWidget;
