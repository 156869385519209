/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getProductsWithIdKeys } from "Util/Product";

import {
	UPDATE_RECIPES_LIST,
	UPDATE_IS_RECIPES_LOADING,
	UPDATE_IS_RECIPE_BANNER_VISIBLE,
	UPDATE_RECIPES_FILTERS,
	UPDATE_RECIPE_DATA,
	UPDATE_RECIPE_CATEGORIES,
	UPDATE_CURRENT_CATEGORY_DATA,
	UPDATE_IS_CURRENT_RECIPE_CATEGORY_LOADING,
	UPDATE_IS_RECIPES_CATEGORIES_LOADING,
	UPDATE_IS_RECIPE_INGREDIENTS_PRODUCTS_LOADING,
	UPDATE_RECIPE_INGREDIENT_PRODUCTS,
	CLEAR_RECIPE_LIST_ITEMS,
} from "./Recipe.action";

export const formatRecipeFilters = (action, state) => {
	const { recipeFilters: unformattedRecipeFilters } = action;

	function formOptions(acc, { title, value }) {
		if (!value) {
			return acc;
		}

		acc[value] = {
			label: title,
			swatch_data: null,
			value_string: value,
		};

		return acc;
	}

	const recipeFilters = unformattedRecipeFilters.reduce(
		(acc, { title, code, values }) => {
			if (!code) {
				return acc;
			}

			acc[code] = {
				attribute_code: code,
				attribute_label: title,
				attribute_type: "select",
				attribute_options: values.reduce(formOptions, {}),
				attribute_values: values
					.map(({ value }) => value)
					.filter((value) => !!value),
			};

			return acc;
		},
		{}
	);

	return {
		...state,
		recipeFilters,
	};
};

export const clearRecipesListItems = (state) => {
    const { recipes: { total_count } = {} } = state;

    return {
        ...state,
        recipes: { total_count }
    }
}

/** @namespace Store/Blog/Reducer/getInitialState */
export const getInitialState = () => {
	return {
		recipes: {},
		recipeData: {},
		recipeFilters: {},
		recipeCategories: [],
		currentCategoryData: {},
		recipeIngredientProducts: {},
		isRecipesLoading: false,
		isCurrentCategoryLoading: false,
		isRecipesCategoriesLoading: false,
		isRecipeIngredientsProductsLoading: false,
		isRecipeBannerVisible: false,
	};
};

export const RecipeReducer = (state = getInitialState(), action) => {
	const {
		type,
		recipes,
		recipeData,
		isRecipesLoading,
		isRecipeBannerVisible,
		currentCategoryData,
		recipeCategories,
		recipeIngredientProducts,
		isCurrentCategoryLoading,
		isRecipesCategoriesLoading,
		isRecipeIngredientsProductsLoading,
	} = action;

	switch (type) {
		case UPDATE_RECIPES_LIST:
			return {
				...state,
				recipes,
			};

		case UPDATE_RECIPE_DATA:
			return {
				...state,
				recipeData,
			};

		case UPDATE_RECIPE_CATEGORIES:
			return {
				...state,
				recipeCategories,
			};

		case UPDATE_CURRENT_CATEGORY_DATA:
			return {
				...state,
				currentCategoryData,
			};

		case UPDATE_RECIPES_FILTERS:
			return formatRecipeFilters(action, state);

		case UPDATE_IS_RECIPES_LOADING:
			return {
				...state,
				isRecipesLoading,
			};

		case UPDATE_IS_CURRENT_RECIPE_CATEGORY_LOADING:
			return {
				...state,
				isCurrentCategoryLoading,
			};

		case UPDATE_IS_RECIPES_CATEGORIES_LOADING:
			return {
				...state,
				isRecipesCategoriesLoading,
			};

		case UPDATE_IS_RECIPE_BANNER_VISIBLE:
			return {
				...state,
				isRecipeBannerVisible,
			};

		case UPDATE_RECIPE_INGREDIENT_PRODUCTS:
			return {
				...state,
				recipeIngredientProducts,
			};

		case UPDATE_IS_RECIPE_INGREDIENTS_PRODUCTS_LOADING:
			return {
				...state,
				isRecipeIngredientsProductsLoading,
			};

		case CLEAR_RECIPE_LIST_ITEMS:
			return clearRecipesListItems(state);

		default:
			return state;
	}
};

export default RecipeReducer;
