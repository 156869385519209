/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_TOP_CATEGORIES,
    UPDATE_TOP_PRODUCTS,
    UPDATE_TOP_CATEGORY_FOR_PRODUCT,
    UPDATE_IS_TOP_CATEGORIES_LOADING,
    UPDATE_IS_TOP_PRODUCTS_LOADING,
    UPDATE_IS_TOP_CATEGORY_FOR_PRODUCT_LOADING,
    UPDATE_X_SEARCH_SUGGESTIONS,
    UPDATE_IS_X_SEARCH_LOADING,
    UPDATE_IS_SEARCH_INPUT_CLICKED,
    UPDATE_IS_SEARCH_PRODUCTS_OPENING,
    UPDATE_BLOG_POSTS_SEARCH_RESULT,
    UPDATE_LAST_BLOG_POSTS,
    UPDATE_LAST_RECIPES,
    UPDATE_RECIPES_SEARCH_RESULT
} from './Search.action';

/** */
export const getInitialState = () => ({
    topCategories: [],
    topProducts: [],
    topCategoryForTopProducts: {},
    xSearchSuggestions: {},
    lastBlogPosts: {},
    blogPostsSearchResult: {},
    lastRecipes: {},
    recipesSearchResult: {},
    isXSearchLoading: false,
    isTopCategoriesLoading: false,
    isTopProductsLoading: false,
    isTopCategoryForTopProductsLoading: false,
    isSearchInputClicked: false,
    isSearchProductsOpening: false
});

/** @namespace Store/Category/Reducer */
export const SearchReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        topCategories,
        topProducts,
        topCategoryForTopProducts,
        isTopCategoriesLoading,
        isTopProductsLoading,
        isTopCategoryForTopProductsLoading,
        xSearchSuggestions,
        lastBlogPosts,
        lastRecipes,
        recipesSearchResult,
        blogPostsSearchResult,
        isXSearchLoading,
        isSearchInputClicked,
        isSearchProductsOpening
    } = action;

    switch (type) {

        case UPDATE_LAST_BLOG_POSTS:
			return {
                ...state,
                lastBlogPosts
            };

        case UPDATE_LAST_RECIPES:
			return {
                ...state,
                lastRecipes
            };

        case UPDATE_BLOG_POSTS_SEARCH_RESULT:
			return {
                ...state,
                blogPostsSearchResult
            };

        case UPDATE_RECIPES_SEARCH_RESULT:
			return {
                ...state,
                recipesSearchResult
            };

        case UPDATE_TOP_CATEGORIES:
            return {
                ...state,
                topCategories
            };

        case UPDATE_TOP_PRODUCTS:
            return {
                ...state,
                topProducts
            };

        case UPDATE_TOP_CATEGORY_FOR_PRODUCT:
            return {
                ...state,
                topCategoryForTopProducts
            };

        case UPDATE_IS_TOP_CATEGORIES_LOADING:
            return {
                ...state,
                isTopCategoriesLoading
            };

        case UPDATE_IS_TOP_PRODUCTS_LOADING:
            return {
                ...state,
                isTopProductsLoading
            };

        case UPDATE_IS_TOP_CATEGORY_FOR_PRODUCT_LOADING:
            return {
                ...state,
                isTopCategoryForTopProductsLoading
            };

        case UPDATE_X_SEARCH_SUGGESTIONS:
            return {
                ...state,
                xSearchSuggestions
            };

        case UPDATE_IS_X_SEARCH_LOADING:
            return {
                ...state,
                isXSearchLoading
            };

        case UPDATE_IS_SEARCH_INPUT_CLICKED:
            return {
                ...state,
                isSearchInputClicked
            };

        case UPDATE_IS_SEARCH_PRODUCTS_OPENING:
            return {
                ...state,
                isSearchProductsOpening
            };

        default:
            return state;
        }
};

export default SearchReducer;
