/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import './TotalsFees.style';

export class TotalsFees extends PureComponent {
    renderExtraFeesBtn() {
        const {
            isExtraFeesOpen,
            toggleIsExtraFeesOpen,
            isNotOpenable,
            isOrderDetails,
            isMyAccountFees
        } = this.props;

        const btnText = isOrderDetails ? __('Instructions:') : __('Instructions');

        return (
            <dt
              block="TotalsFees"
              elem="ExtraFeeBtnWrapper"
            >
                <span
                  block="TotalsFees"
                  elem="ExtraFeeBtn"
                  mods={ {
                    isExtraFeesOpen,
                    isNotOpenable,
                    isMyAccountFees
                  } }
                  onClick={ !isNotOpenable && toggleIsExtraFeesOpen }
                >
                    { btnText }
                </span>
            </dt>
        );
    }

    renderExtraFeesItems() {
        const {
            isExtraFeesOpen,
            isOrderDetails,
        } = this.props;


        if (isOrderDetails) {
            if (!isExtraFeesOpen) {
                return null;
            }

            return this.renderOrderDetailsExtraFees();
        }

        return this.renderDefaultExtraFees();
    }

    renderOrderDetailsExtraFees() {
        const {
            renderPriceLine,
            feeList,
            isExtraFeesOpen,
            mix
        } = this.props;

        if (!isExtraFeesOpen) {
            return null;
        }

        return (
            <>
                { feeList && !!feeList.length ?
                    feeList.map(({ name, price: { value } }) => {
                        return (
                            <>
                                <dt
                                  block="TotalsFees"
                                  elem="ExtraFeeUnit"
                                  mix={ mix }
                                >
                                    { name }
                                </dt>
                                <dd>
                                    { renderPriceLine(value) }
                                </dd>
                            </>
                        );
                    })
                    : null
                }
            </>
        );
    }

    renderDefaultExtraFees() {
        const {
            isExtraFeesOpen,
            renderPriceLine,
            feeList,
            mix
        } = this.props;

        const isHide = !Array.isArray(feeList) || !feeList.length;


        return (
            <div
              block="TotalsFees"
              elem="AppliedExtraFeesWrapper"
              mods= { { isExtraFeesOpen, isHide } }
              mix={ mix }
            >
                { feeList && !!feeList.length ?
                    feeList.map(({ name, price: { value } }) => {
                        return (
                            <p
                                block="TotalsFees"
                                elem="ExtraFeeUnit"
                            >
                                { name }
                                <span>
                                    { renderPriceLine(value) }
                                </span>
                            </p>
                        );
                    })
                    : null
                }
            </div>
        );
    }

    renderContent() {
        const {
            renderPriceLine,
            mix,
            isOrderDetails,
            fee: { value }
        } = this.props;

        // for order details we don't need a wrapper
        if (isOrderDetails) {
            return (
                <>
                    { this.renderExtraFeesBtn() }
                    <dd>
                        { renderPriceLine(value) }
                    </dd>
                    { this.renderExtraFeesItems() }
                </>
            );
        }

        return (
            <dl
              block="TotalsFees"
              elem="ExtraFee"
              mix={ mix }
              aria-label={ __('Cart total extra fees') }
            >
                { this.renderExtraFeesBtn() }
                <dd>
                    { renderPriceLine(value) }
                </dd>
                { this.renderExtraFeesItems() }
            </dl>
        );
    }

    render() {
        const { fee: { value } = {} } = this.props;

        if (!value) {
            return null;
        }

        return this.renderContent();
    }
}

export default TotalsFees;
