import { PureComponent } from 'react';

import GoogleMap from 'Component/GoogleMap';
import Loader from 'Component/Loader';
import PickupDistance from 'Component/PickupDistance';

import { getRegionCode } from 'Util/Checkout';

import { ReactComponent as MobileCheckIcon } from './icons/mobile_check.svg';

import './PickupStoreSelector.style';

export class PickupStoreSelector extends PureComponent {
    renderLoader(isLoading) {
        return <Loader isLoading={ isLoading } />;
    }

    renderMap() {
        const {
            mapLanguage,
            setIsMapLoading,
            isMapLoading,
            storeToRender
        } = this.props;

        return <GoogleMap
          storeData={ storeToRender }
          language={ mapLanguage }
          setIsMapLoading={ setIsMapLoading }
          isMapLoading={ isMapLoading }
        />;
    }

    renderDistance(distance) {
        return <PickupDistance
          distance={ distance }
        />;
    }

    renderStoreData(storeData) {
        const { countries, isMobile, selectedStoreCode } = this.props;
        const { name, street, city, region_id, country_id, postcode, distance_value, pickup_location_code } = storeData;

        const isSelected = selectedStoreCode === pickup_location_code;
        const region = getRegionCode(region_id, country_id, countries);

        return (
            <div block="PickupStoreSelector" elem="Left">
                <div
                  block="PickupStoreSelector"
                  elem="DistanceWrapper"
                >
                    <h3
                      block="PickupStoreSelector"
                      elem="SelectedAddressName"
                    >
                        { name }
                    </h3>

                    { isMobile && this.renderDistance(distance_value) }

                </div>
                <div
                  block="PickupStoreSelector"
                  elem="AddressWrapper"
                >
                    <p block="PickupStoreSelector" elem="AddressDetails">
                        { street }<br /> { city }, { region }, { postcode }
                    </p>
                    { (isMobile && isSelected)
                        && <span
                            block="PickupStoreSelector"
                            elem="SelectedIcon"
                          >
                              <MobileCheckIcon />
                          </span> }
                </div>
            </div>
        );
    }

    renderHeading() {
        return (
            <p block="PickupStoreSelector" elem="Heading">
                { __('1. List of available stores for pick up') }
            </p>
        );
    }

    renderSelectedOption() {
        const {
            storeToRender,
            isMobile
        } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <div
              block="PickupStoreSelector"
              elem="SelectedAddress"
            >
                { this.renderLoader(!storeToRender) }
                { this.renderSelectedData(storeToRender) }
            </div>
        );
    }

    renderSelectedData(selectedStore) {
        const { isMobile } = this.props;

        if (!selectedStore) {
            return null;
        }

        const { distance_value } = selectedStore;

        return (
            <>
                { this.renderStoreData(selectedStore) }
                <div block="PickupStoreSelector" elem="Right">
                    <p  block="PickupStoreSelector" elem="Selected">
                        { __('Selected') }
                    </p>
                    { !isMobile && this.renderDistance(distance_value) }
                </div>
            </>
        );
    }

    renderOtherStores() {
        const {
            isOtherStoresOpened,
            isMobile
        } = this.props;

        return (
            <div
              block="PickupStoreSelector"
              elem="OtherStoresWrapper"
              mods={ { isOtherStoresOpened } }
            >
                { isMobile
                    ? this.renderStoresMobileSelector()
                    : this.renderStoresDropDown() }
            </div>
        );
    }

    renderStoreOption(storeOption) {
        const { onStoreOptionClick } = this.props;
        const { pickup_location_code } = storeOption;

        return (
            <div
              block="PickupStoreSelector"
              elem="AddressOption"
              onClick={ () => onStoreOptionClick(pickup_location_code) }
            >
                { this.renderStoreData(storeOption) }
            </div>
        );
    }

    renderStoresMobileSelector() {
        const { pickupLocations } = this.props;

        const pickupLocToRender = pickupLocations.filter(({ is_pickup_location_active }) => is_pickup_location_active);

        return (
            <div
              block="PickupStoreSelector"
              elem="MobileSelectorWrapper"
            >
                <ul
                  block="PickupStoreSelector"
                  elem="MobileSelectorList"
                >
                    { pickupLocToRender.map(this.renderDropDownOption.bind(this)) }
                </ul>
            </div>
        );
    }

    renderStoresDropDown() {
        const {
            openStoreSelector,
            isOtherStoresOpened,
            otherStoresOptions
        } = this.props;

        return (
            <div
              block="PickupStoreSelector"
              elem="DropDown"
              onClick={ openStoreSelector }
            >
                <div
                  block="PickupStoreSelector"
                  elem="DropDownBtn"
                  mods={ { isOtherStoresOpened } }
                >
                    { __('Other stores') }
                </div>
                <div
                  block="PickupStoreSelector"
                  elem="DropDownListWrapper"
                  mods={ { isOtherStoresOpened } }
                >
                    <ul
                      block="PickupStoreSelector"
                      elem="DropDownList"
                    >
                        { otherStoresOptions.map(this.renderDropDownOption.bind(this)) }
                    </ul>
                </div>
            </div>
        );
    }

    renderDropDownChoose(storeOption) {
        const { isMobile } = this.props;
        const { distance_value } = storeOption;

        return (
            <div
              block="PickupStoreSelector"
              elem="DropDownChooseWrapper"
            >
                <div
                    block="PickupStoreSelector"
                    elem="DropDownChooseBtn"
                >
                    { __('Choose') }
                </div>
                { !isMobile && this.renderDistance(distance_value) }
            </div>
        );
    }

    renderDropDownOption(storeOption) {
        const { onStoreOptionClick, isMobile, selectedStoreCode } = this.props;
        const { pickup_location_code } = storeOption;

        const isSelected = storeOption?.pickup_location_code === selectedStoreCode;

        return (
            <li
              block="PickupStoreSelector"
              elem="DropDownItem"
              mods={ { isSelected } }
              mix={ { block:"PickupStoreSelector", elem: "AddressOption" } }
              onClick={ () => onStoreOptionClick(pickup_location_code) }
            >
                { this.renderStoreData(storeOption) }
                { !isMobile && this.renderDropDownChoose(storeOption) }
            </li>
        );
    }

    render() {
        return (
            <section
              block="PickupStoreSelector"
              elem="Wrapper"
            >
                <div
                  block="PickupStoreSelector"
                  elem="Left"
                >
                    { this.renderHeading() }
                    { this.renderSelectedOption() }
                    { this.renderOtherStores() }
                </div>
                <div
                  block="PickupStoreSelector"
                  elem="Right"
                >
                    { this.renderMap() }
                </div>
            </section>
        );
    }
}

export default PickupStoreSelector;
