/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import Field from 'Component/Field';
import Form from 'Component/Form';
import {
    MyAccountForgotPassword as SourceMyAccountForgotPassword
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';
import Link from 'Component/Link';
import { appendWithStoreCode } from 'Util/Url';

import './MyAccountForgotPassword.style';

export class MyAccountForgotPasswordComponent extends SourceMyAccountForgotPassword {

    renderForgotPasswordText() {
      return (
        <div block="MyAccountForgotPassword" elem="TextWrapper">
          <h2 block="MyAccountForgotPassword" elem="TextHeading">
            { __('Forgot your password') }
          </h2>
          <p block="MyAccountForgotPassword" elem="SubText">
            { __("Enter your email below and we'll send you a link to reset your password.") }
          </p>
        </div>
      );
    }

    renderForgotPasswordForm() {
        const {
            onForgotPasswordAttempt,
            onForgotPasswordSuccess,
            onFormError,
            isCheckout
        } = this.props;

        return (
      <div
        block="MyAccountForgotPassword"
        elem="Wrapper"
      >
        { !isCheckout && this.renderForgotPasswordText() }
        <Form
          key="forgot-password"
          onSubmit={ onForgotPasswordAttempt }
          onSubmitSuccess={ onForgotPasswordSuccess }
          onSubmitError={ onFormError }
        >
          <Field
            type="text"
            id="email"
            name="email"
            label={ __('Email') }
            placeholder={ __('Enter your email') }
            autocomplete="email"
            validation={ ['notEmpty', 'email'] }
          />
          <div block="MyAccountOverlay" elem="Buttons">
            <button
              block="Button"
              elem="Important"
              type="submit"
            >
              { __('Send reset link') }
            </button>
             { this.renderBackToLoginButton() }
          </div>
        </Form>
      </div>
        );
    }

    renderBackToLoginButton() {
          return (
            <Link to={appendWithStoreCode('/login')}>
              <button
                block="Button"
                mods={{ likeLink: true, darkLink: true }}
              >
                 {__('Back to login')}
              </button>
            </Link>
          )
        }
 
    render() {
        return this.renderForgotPasswordForm();
    }
}

export default MyAccountForgotPasswordComponent;
