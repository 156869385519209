/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isSignedIn } from 'Util/Auth';
import { getClosestStore } from 'Util/Stores';
import BrowserDatabase from 'Util/BrowserDatabase';
import { PRODUCT_OUT_OF_STOCK } from 'Component/CartItem/CartItem.config';
import { COMMENT_VALUE } from 'Component/PickupOverlay/PickupOverlay.config';
import { DEFAULT_LOCATION_CODE } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';
import { NOT_DELIVERABLE_REASON } from 'Component/ExcludedProducts/ExcludedProducts.config';
import { GUEST_QUOTE_ID, CUSTOMER_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';
import { NOT_IN_STOCK_REASON, NOT_ENOUGH_QUANTITY_REASON } from 'Component/ExcludedProducts/ExcludedProducts.config';
import { CLOSEST_STORE } from 'Store/Config/Config.dispatcher';
import { getIndexedProduct } from 'Util/Product';

export const SUBSTITUTION_OPTION_TITLE = 'Substitution';

/**
 * returns commentValue if saved in BrowserDatabase
 * @namespace Util/Cart/getCommentValue
 */
export const getCommentValue = () => {
  const commentValue = BrowserDatabase.getItem(COMMENT_VALUE);

  setTimeout(() => {
    BrowserDatabase.deleteItem(COMMENT_VALUE);
  }, 1000);

  return typeof commentValue === 'string'
    ? commentValue
    : '';
}

/**
 * returns isPickupOverlayRequired depending on closest store
 * @namespace Util/Cart/getIsPickupOverlayRequired
 */
 export const getIsPickupOverlayRequired = () => {
  const closestStore = BrowserDatabase.getItem(CLOSEST_STORE);

  if (!closestStore) {
    return true;
  }

  const { pickup_location_code } = closestStore;

  return pickup_location_code === DEFAULT_LOCATION_CODE;
}

/**
 * Checks whether item in cart are out of stock
 * @namespace Util/Cart/itemIsOutOfStock
 *
 */
export const itemIsOutOfStock = (item) => {
  const {
      product: {
          variants,
          type_id
      },
      sku: itemSku
  } = item;

  if (type_id !== 'configurable') {
      // item is not configurable => previous check is sufficient
      return false;
  }

  if (
      variants.some(({ sku }) => sku === itemSku)
      && (variants.find(({ sku }) => sku === itemSku).stock_status !== PRODUCT_OUT_OF_STOCK
      || variants.find(({ sku }) => sku === itemSku).warehouse_stock_status !== PRODUCT_OUT_OF_STOCK)
  ) {
      // item added to cart is present in variants and it stock status is IN_STOCK
      return false;
  }

  return true;
};

/**
 * Checks whether some items in cart are out of stock
 * @param {Array} items cartTotals items
 * @namespace Util/Cart/hasOutOfStockProductsInCartItems */
 export const hasOutOfStockProductsInCartItems = (items = []) => items.some(itemIsOutOfStock);

/**
 * gets id of the first region in the list of regions for default country
 * @namespace Util/Cart/getFirstDefaultCountryRegionId
 */
export const getFirstDefaultCountryRegionId = (countries, defaultCountry) => countries.reduce(
  (acc, { available_regions, id }) => {
    if (id = defaultCountry) {
        acc = available_regions[0].id;
    }

    return acc;
  },
0);

/**
 * gets the price amount for the cheapest existing shipping method
 * @namespace Util/Cart/getCheapestShippingPrice
 */
export const getCheapestShippingPrice = (shippingMethods) => shippingMethods.reduce(
  (acc, { price_incl_tax: amount }) => {
    if (acc === 0 || acc > amount) {
      acc = amount;
    }

    return acc;
  },
0);

/**
 * gets the subtotal amount giving free shipping
 * @namespace Util/Cart/getFreeShippingAfter
 */
export const getFreeShippingAfter = (freeShippingTotalAfter, subtotal = 0) => (freeShippingTotalAfter - subtotal).toFixed(2);


/**
 * returns relevant quote ID
 * @namespace Util/Cart/getAppropriateQuoteId
 */
export const getAppropriateQuoteId = () => {
  if (!isSignedIn()) {
      return BrowserDatabase.getItem(GUEST_QUOTE_ID)
  }

  return BrowserDatabase.getItem(CUSTOMER_QUOTE_ID);
}

/**
 * rearranges excludedItems into correct structure
 * @namespace Util/Cart/rearrangeExcludedeItems
 */
export const rearrangeExcludedeItems = (excludedItems) => {
  const rearrangedItems = excludedItems.length
    ? excludedItems
      .reduce((acc, { reason, product }) => {
        const isNotInStock = reason === NOT_IN_STOCK_REASON || reason === NOT_ENOUGH_QUANTITY_REASON;

        if (!acc[reason]) {
          if (isNotInStock && !acc[NOT_IN_STOCK_REASON]) {
            acc[NOT_IN_STOCK_REASON] = [product];
            return acc;
          }

          acc[reason] = [product];
        } else {
          if (isNotInStock) {
            acc[NOT_IN_STOCK_REASON].push(product);
            return acc;
          }

          acc[reason].push(product);
        }

        return acc;
        },
      {})
    : {};

    // sorting obj, as not deliverable items should be rendered first
     return (function() {
        const sortedItemsArr = Object.entries(rearrangedItems).sort(([key, value]) => {
          if (key === NOT_DELIVERABLE_REASON) {
            return -1;
          }

          return 1;
        });

        return Object.fromEntries(sortedItemsArr);
      })();
}

/**
 * returns areExcludedUpdated
 * @namespace Util/Cart/getAreExcludedUpdated
 */
export const getAreExcludedUpdated = (excludedProducts, prevExcludedProducts) => {
  let areProductsChanged = false;
  const keys = Object.keys(excludedProducts);

  // if products array length changes, the excludedProducts are got updated
  if (keys.length !== Object.keys(prevExcludedProducts).length) {
    return true;
  }

  return !keys.every((key) => {
    const skuArr = excludedProducts[key] ? excludedProducts[key].map(({ sku }) => sku) : null;
    const prevSkuArr = prevExcludedProducts[key] ? prevExcludedProducts[key].map(({ sku }) => sku) : null;

    if (!skuArr || !prevSkuArr) {
      return false;
    }

    if (skuArr.length !== prevSkuArr.length || areProductsChanged) {
      // if any reason array is changed, always returning false,
      // in such a case, the function will return true
      areProductsChanged = true;
      return false;
    }

    return !skuArr.some((sku) => !prevSkuArr.includes(sku));
  });
}

/**
 * returns isSubstitutable flag
 * @namespace Util/Cart/getIsSubstitutable
 */
export const getIsSubstitutable = (productOptions) => {
  if (!Array.isArray(productOptions) || !productOptions.length) {
    return false;
  }

  return productOptions.some(({ title }) => title === SUBSTITUTION_OPTION_TITLE);
}

/**
 * returns isSubstitutable flag
 * @namespace Util/Cart/getIsSubstitutable
 */
export const getIsAnySubstitutableInCart = (items) => {
  if (!Array.isArray(items) || !items.length) {
    return false;
  }

  return items.some(({ product: { options } }) => {
    if (!options) {
      return false;
    }

    return options.some(({ title }) => title === SUBSTITUTION_OPTION_TITLE);
  });
}

/**
 * returns options for getApplyCustomizableOptionsFields mutation
 * @namespace Util/Cart/getSubstitutionOptions
 */
export const getSubstitutionOptions = (cartItems, substitutableProducts) => {
  if (!cartItems || !cartItems.length) {
    return null;
  }

  const allSubstitutableProducs = cartItems
    .filter(({ product: { options } }) => getIsSubstitutable(options))
    .map(({ item_id, product: { options } }) => {
      const optionId = options.filter(({ title }) => title === SUBSTITUTION_OPTION_TITLE)[0].option_id;

      return [ item_id, optionId ];
    });

  const rejectedOptions = allSubstitutableProducs.map(([ item_id, optionId ]) => {
      const option = {
          options: [{
              id: optionId,
              value_string: 'No'
          }],
          quote_item_id: item_id
      };

      return option;
  });

  const allOptions = rejectedOptions.map((option) => {
    if (substitutableProducts.includes(option.quote_item_id)) {
      option.options[0].value_string = 'Yes';
    }

      return option;
  });

  return allOptions;
}

/**
 * returns selected store, or the first store in a list if warehouse is selected
 * @namespace Util/Cart/getSelectedLocation
 */
 export const getSelectedLocation = (locations, selectedLocationCode) => {
  if (!locations) {
    return [];
  }

  if (selectedLocationCode === DEFAULT_LOCATION_CODE) {
    const closestStore = getClosestStore(locations, false);
    closestStore.then((data) => {
      return data
    });
  }

  return locations.filter(({ pickup_location_code }) => selectedLocationCode === pickup_location_code)[0];
}

/**
 * returns appropriate reason text for excluded items depending on qty and reason
 * @namespace Util/Cart/getExcludedItemsReasonTxt
 */
export const getExcludedItemsReasonTxt = (reason, qty) => {
  switch(reason) {
    case NOT_ENOUGH_QUANTITY_REASON:
    case NOT_IN_STOCK_REASON:
      if (qty === 1) {
        return __('%s product is currently out of stock', qty)
      } else {
        return __('%s produits sont actuellement hors stock', qty)
      }

    default:
      if (qty === 1) {
        return __('%s fresh products are unavailable for delivery', qty)
      } else {
        return __('%s products are not available for delivery', qty)
      }
  }
}

/**
 * @namespace Util/Cart/normalizeCartData
 */
export const normalizeCartData = (cartData) => {
  const dataToNormalize = { ...cartData };

  if (Object.hasOwnProperty.call(dataToNormalize, 'items')) {
    const normalizedItemsProduct = dataToNormalize.items.map((item) => {
      const normalizedItem = item;

      normalizedItem.product = getIndexedProduct(item.product);

      return normalizedItem;
    });

    dataToNormalize.items = normalizedItemsProduct;
  }

  return dataToNormalize;
}
