import { PureComponent, createRef } from "react";
import { connect } from "react-redux";

import { updateMeta } from "Store/Meta/Meta.action";

import { RECIPE_INGREDIENT_PRODUCTS_OVERLAY_ID } from "Component/RecipeIngredientProductsOverlay/RecipeIngredientProductsOverlay.config";
import { updateIsRecipeBannerVisible } from "Store/Recipe/Recipe.action";
import { toggleBreadcrumbs } from "Store/Breadcrumbs/Breadcrumbs.action";
import { toggleOverlayByKey } from "Store/Overlay/Overlay.action";
import { ogTagsModule } from "Util/Blog";
import { appendWithStoreCode } from "Util/Url";
import { setMainMarginTop } from "Util/Window";
import { toggleGoogleRecipeScript } from "Util/Recipe";

import { RecipePage } from "./RecipePage.component";

export const RecipeDispatcher = import(
	/* webpackMode: "lazy", webpackChunkName: "dispatchers" */
	"Store/Recipe/Recipe.dispatcher"
);

export const BreadcrumbsDispatcher = import(
	/* webpackMode: "lazy", webpackChunkName: "dispatchers" */
	"Store/Breadcrumbs/Breadcrumbs.dispatcher"
);

export const mapStateToProps = (state) => ({
	device: state.ConfigReducer.device,
	mageside_recipe_seo_route: state.ConfigReducer.mageside_recipe_seo_route,
	mageside_recipe_seo_title: state.ConfigReducer.mageside_recipe_seo_title,
	isDisclaimerHidden: state.ConfigReducer.isDisclaimerHidden,
	recipeData: state.RecipeReducer.recipeData,
	isRecipesLoading: state.RecipeReducer.isRecipesLoading,
    isRecipeIngredientsProductsLoading: state.RecipeReducer.isRecipeIngredientsProductsLoading,
});

export const mapDispatchToProps = (dispatch) => ({
	getRecipeData: (filter) =>
		RecipeDispatcher.then(({ default: dispatcher }) =>
			dispatcher.getRecipeData(dispatch, filter)
		),
    getIngredientProducts: (skuArr) =>
		RecipeDispatcher.then(({ default: dispatcher }) =>
			dispatcher.getIngredientProducts(dispatch, skuArr)
		),
	updateMeta: (meta) => dispatch(updateMeta(meta)),
	updateIsRecipeBannerVisible: (isRecipeBannerVisible) =>
		dispatch(updateIsRecipeBannerVisible(isRecipeBannerVisible)),
	updateBreadcrumbs: (breadcrumbs) =>
		BreadcrumbsDispatcher.then(({ default: dispatcher }) =>
			dispatcher.update(breadcrumbs, dispatch)
		),
	toggleBreadCrumbs: (areBreadcrumbsVisible) =>
		dispatch(toggleBreadcrumbs(areBreadcrumbsVisible)),
	showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
});

/** @namespace Route/RecipePage/Container */
export class RecipePageContainer extends PureComponent {
	relatedRecipesScrollableRef = createRef();

	constructor(props) {
		super(props);

		this.state = {
			isBreadSet: false,
			activeRecipe: 0,
		    productsSkusForIngredient: '',
		};
	}

	componentDidMount() {
		const { recipe_id, getRecipeData, toggleBreadCrumbs } = this.props;

		setMainMarginTop();
		toggleBreadCrumbs(false);
		getRecipeData({ recipe_id });
        this.setOgMetaTags();
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			toggleBreadCrumbs,
			recipeData,
			recipeData: { recipe_id },
		} = this.props;
		const {
			recipeData: { recipe_id: prevId },
		} = prevProps;

        const { productsSkusForIngredient } = this.state;
        const { productsSkusForIngredient: prevProductsSkusForIngredient } = prevState;

		this.setBreadcrumbs();
		setMainMarginTop();
		// Disabling breadcrumbs in Router
		setTimeout(() => toggleBreadCrumbs(false));
		this.setMetaTags(prevProps);

		if (recipe_id !== prevId) {
			this.setState({ isBreadSet: false });
			toggleGoogleRecipeScript.createScript(recipeData);
            this.setOgMetaTags();
		}

        if (
            productsSkusForIngredient &&
            productsSkusForIngredient
            !== prevProductsSkusForIngredient
        ) {
            this.fetchIngredientProducts();
        }
	}

	componentWillUnmount() {
		const { updateIsRecipeBannerVisible, toggleBreadCrumbs } = this.props;

		toggleBreadCrumbs(true);
		updateIsRecipeBannerVisible(false);
		toggleGoogleRecipeScript.removeScript();
        ogTagsModule.removeOgTags();
	}

	containerProps = () => {
		return {
			...this.props,
		};
	};

	containerFunctions = {
		updateActiveRecipe: this.updateActiveRecipe.bind(this),
		onAddIngredientProductClick:
			this.onAddIngredientProductClick.bind(this),
		onPrintClick: this.onPrintClick.bind(this),
	};

    fetchIngredientProducts() {
        const { productsSkusForIngredient } = this.state;
        const { getIngredientProducts } = this.props;

        const skuArr = productsSkusForIngredient.split(';');
        getIngredientProducts(skuArr);
    }

    setOgMetaTags() {
        const {
            recipeData: {
                open_graph_title = '',
                open_graph_description = '',
                thumbnail: { url } = {}
            } = {},
        } = this.props;

        if (!open_graph_title && !open_graph_description) {
            return;
        }

        // ogTagsModule.setOgTags({
        //     "og:title": open_graph_title,
        //     "og:type": "website",
        //     "og:image": url,
        //     "og:url": location.href,
        //     "og:description": open_graph_description
        // });
    }

	updateActiveRecipe(activeRecipe) {
		this.setState({ activeRecipe });
	}

	setMetaTags(prevProps) {
		const {
			updateMeta,
			recipeData: {
				recipe_id,
				meta_title,
				meta_keywords,
				meta_description,
			},
		} = this.props;
		const {
			recipeData: { recipe_id: prevId },
		} = prevProps;

		if (recipe_id !== prevId) {
			updateMeta({
				title: meta_title,
				description: meta_description,
				keywords: meta_keywords,
			});
		}
	}

	onPrintClick() {
		window.print();
	}

	onAddIngredientProductClick(skusStr) {
		const { showOverlay } = this.props;

        this.setState({ productsSkusForIngredient: skusStr });
		setTimeout(() => showOverlay(RECIPE_INGREDIENT_PRODUCTS_OVERLAY_ID));
	}

	setBreadcrumbs() {
		const { isBreadSet } = this.state;
		const {
			recipeData: {
                title,
                categories: { items = [] } = {}
            },
			mageside_recipe_seo_route,
			mageside_recipe_seo_title,
			updateBreadcrumbs,
		} = this.props;

		if (isBreadSet || !mageside_recipe_seo_route || !title) {
			return;
		}

        const { name, url } = items[0] || {};

		const breadcrumbs = [
			{
				url,
				name,
			},
			{
				url: `/${mageside_recipe_seo_route}`,
				name: mageside_recipe_seo_title,
			},
		];

		this.setState({ isBreadSet: true });
		updateBreadcrumbs(breadcrumbs);
	}

	render() {
		return (
			<RecipePage
				{...this.state}
				{...this.containerFunctions}
				{...this.containerProps()}
			/>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RecipePageContainer);
