/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export * from 'SourceStore/Product/Product.action';

export const UPDATE_PRODUCT_AVAILABILITY = 'UPDATE_PRODUCT_AVAILABILITY';
export const UPDATE_PRODUCT_AVAILABILITY_ACTIVE_POPUP_ID = 'UPDATE_PRODUCT_AVAILABILITY_ACTIVE_POPUP_ID';
export const UPDATE_PRODUCT_RELATED_BLOG_POSTS = 'UPDATE_PRODUCT_RELATED_BLOG_POSTS';
export const UPDATE_PRODUCT_RELATED_RECIPES = 'UPDATE_PRODUCT_RELATED_RECIPES';
export const UPDATE_IS_PRODUCT_LOADING = 'UPDATE_IS_PRODUCT_LOADING';


/**
 * Update the availability array for the curent product (rewrites if it's set).
 * @param  {Array} productAvailability Total number of products in this filter
 * @return {Object}
 * @namespace Store/Product/Action/updateProductAvailability
 */
export const updateProductAvailability = (productAvailability, isAvailableAtCurrentStore) => ({
    type: UPDATE_PRODUCT_AVAILABILITY,
    productAvailability,
    isAvailableAtCurrentStore
});

/**
 * Update isProductLoading flag
 * @param  {Boolean} isProductLoading
 * @return {Object}
 * @namespace Store/Product/Action/updateIsProductLoading
 */
export const updateIsProductLoading = (isProductLoading) => ({
    type: UPDATE_IS_PRODUCT_LOADING,
    isProductLoading
});

/**
 * @namespace Store/Product/Action/updateProductAvailabilityActivePopupId
 */
export const updateProductAvailabilityActivePopupId = (productAvailabilityActivePopupId) => ({
    type: UPDATE_PRODUCT_AVAILABILITY_ACTIVE_POPUP_ID,
    productAvailabilityActivePopupId
});

/**
 * @namespace Store/Product/Action/updateProductRelatedBlogPosts
 */
export const updateProductRelatedBlogPosts = (productRelatedBlogPosts) => ({
    type: UPDATE_PRODUCT_RELATED_BLOG_POSTS,
    productRelatedBlogPosts
});

/**
 * @namespace Store/Product/Action/updateProductRelatedRecipes
 */
export const updateProductRelatedRecipes = (productRelatedRecipes) => ({
    type: UPDATE_PRODUCT_RELATED_RECIPES,
    productRelatedRecipes
});
