/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ALL } from "Route/BlogPage/BlogPage.config";

export const UPDATE_BLOG_POSTS = 'UPDATE_BLOG_POSTS';
export const UPDATE_BLOG_TAGS = 'UPDATE_BLOG_TAGS';
export const UPDATE_BLOG_AUTHORS = 'UPDATE_BLOG_AUTHORS';
export const UPDATE_IS_BLOG_LOADING = 'UPDATE_IS_BLOG_LOADING';
export const UPDATE_BLOG_SETTINGS = 'UPDATE_BLOG_SETTINGS';
export const UPDATE_TOTAL_BLOG_PAGES = 'UPDATE_TOTAL_BLOG_PAGES';
export const UPDATE_BLOG_CATEGORIES = 'UPDATE_BLOG_CATEGORIES';
export const UPDATE_BLOG_CATEGORIES_TREE = 'UPDATE_BLOG_CATEGORIES_TREE';
export const UPDATE_IS_BLOG_BANNER_VISIBLE = 'UPDATE_IS_BLOG_BANNER_VISIBLE';
export const UPDATE_PAGE_INFO = 'UPDATE_PAGE_INFO';

/**
 * Update blog settings
 * @param  {Object} blogSettings
 * @return {void}
 * @namespace Store/Cart/Action/updateBlogSettings */
export const updateBlogSettings = (blogSettings) => ({
    type: UPDATE_BLOG_SETTINGS,
    blogSettings
});

/**
 * Update total blog pages
 * @param  {Number} totalBlogPages
 * @return {void}
 * @namespace Store/Cart/Action/updateTotalBlogPages */
export const updateTotalBlogPages = (totalBlogPages) => ({
    type: UPDATE_TOTAL_BLOG_PAGES,
    totalBlogPages
});

/**
 * Update blog posts
 * @param  {Object} blogPosts
 * @return {void}
 * @namespace Store/Cart/Action/updateBlogPosts */
export const updateBlogPosts = (blogPosts) => ({
    type: UPDATE_BLOG_POSTS,
    blogPosts
});

/**
 * Update blog tags
 * @param  {Object} blogTags
 * @return {void}
 * @namespace Store/Cart/Action/updateBlogTags */
export const updateBlogTags = (blogTags) => ({
    type: UPDATE_BLOG_TAGS,
    blogTags
});

/**
 * Update blog categories
 * @param  {Object} blogCategories
 * @return {void}
 * @namespace Store/Cart/Action/updateBlogCategories */
export const updateBlogCategories = (blogCategories) => ({
    type: UPDATE_BLOG_CATEGORIES,
    blogCategories
});

/**
 * Update blog categories tree
 * @param  {Object} categoriesTree
 * @return {void}
 * @namespace Store/Cart/Action/updateCategoriesTree */
export const updateCategoriesTree = (categoriesTree) => ({
    type: UPDATE_BLOG_CATEGORIES_TREE,
    categoriesTree
});

/**
 * Update blogs authors
 * @param  {Object} blogAuthors
 * @return {void}
 * @namespace Store/Cart/Action/updateBlogAuthors */
export const updateBlogAuthors = (blogAuthors) => ({
    type: UPDATE_BLOG_AUTHORS,
    blogAuthors
});

/**
 * Update is blog loading flag
 * @param  {Boolean} isBlogLoading
 * @return {void}
 * @namespace Store/Cart/Action/updateIsBlogLoading */
export const updateIsBlogLoading = (isBlogLoading) => ({
    type: UPDATE_IS_BLOG_LOADING,
    isBlogLoading
});

/**
 * Update is blog banner visible
 * @param  {Boolean} isBlogBannerVisible
 * @return {void}
 * @namespace Store/Cart/Action/updateIsBlogBannerVisible */
export const updateIsBlogBannerVisible = (isBlogBannerVisible) => ({
    type: UPDATE_IS_BLOG_BANNER_VISIBLE,
    isBlogBannerVisible
});

/**
 * Update page info
 * @param  {String} pageType
 * @param  {Number} entityId
 * @return {void}
 * @namespace Store/Cart/Action/updatePageInfo */
export const updatePageInfo = (pageType = ALL, entityId = 1) => ({
    type: UPDATE_PAGE_INFO,
    pageInfo: {
        pageType,
        entityId
    }
});
