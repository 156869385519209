/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import { deleteAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { showNotification } from 'Store/Notification/Notification.action';

export const UNAUTHORIZED = 'UNAUTHORIZED';

/**
 * handles 'The current customer isn't authorized' error
 * @namespace Util/Customer/handleError
 */
export const handleError = (e, dispatch, isNotificationShown = false) => {
    const error = getError(e);
    const { code, message } = error;

    if (!code && !!message && isNotificationShown) {
        dispatch(showNotification('error', message));
    } else if (code === UNAUTHORIZED) {
        const { storeConfig: { storeCode } } = BrowserDatabase.getItem('config');
        updateSignInOnAuthExpire(dispatch);
        history.push(`/${ storeCode }/login`);
    } else if (typeof error === 'string' && isNotificationShown) {
        dispatch(showNotification('error', error));
    } else if (isNotificationShown) {
        dispatch(showNotification('error', 'Something went wrong.'));
    }
}

/**
 * gets error depending on the one in the query response
 * @namespace Util/Customer/getError
 */
export const getError = (err) => {
    if (Array.isArray(err)) {
        return err[0];
    } else {
        return err;
    }
}

/**
 * changes sign in status and deletes authorization token
 * @namespace Util/Customer/updateSignInOnAuthExpire
 */
export const updateSignInOnAuthExpire = (dispatch) => {
    dispatch(updateCustomerSignInStatus(false));
    deleteAuthorizationToken();
}

/**
 * inserts spaces into DataCandy card number
 * @namespace Util/Customer/getDataCandyNumToRender
 */
export const getDataCandyNumToRender = (dataCandyBonus) => {
    if (
        typeof dataCandyBonus !== 'string'
        && typeof dataCandyBonus !== 'number'
    ) {
        return dataCandyBonus;
    }

    return dataCandyBonus.toString().replace(/\d{4}(?=.)/g, '$& ');
}

const currentDate = new Date();

const minDateNow = new Date(currentDate);
minDateNow.setFullYear(currentDate.getFullYear() - 100);
const minDateFormatted = minDateNow.toISOString().split('T')[0];

const maxDateNow = new Date(currentDate);
maxDateNow.setFullYear(currentDate.getFullYear() - 14);
const maxDateFormatted = maxDateNow.toISOString().split('T')[0];

export const maxDate = maxDateFormatted;
export const minDate = minDateFormatted;