
import { Field } from 'Util/Query';

export class CategoryQuery {
    __construct() {
        super.__construct();
        this.options = {};
    }

    getQuery(options = {}) {
        this.options = options;
        return new Field('category')
            .addArgument(...this._getConditionalArguments())
            .addFieldList(this._getDefaultFields())
            .addField(this._getChildrenFields())
    }

    getAllCategories(options = {}) {
        this.options = options;
       
        return new Field('category')
            .addArgument(...this._getConditionalArguments())
            .addField(this._getChildren())
    }

    _getConditionalArguments() {
        const { categoryIds } = this.options;
        if (categoryIds) {
            return ['id', 'Int!',categoryIds];
        }

        throw new Error(__('There was an error requesting the category'));
    }

    _getAllCategories() {
        const { categoryIds } = this.options;
        if (categoryIds) {
            return ['id', 'Int!',2];
        }

        throw new Error(__('There was an error requesting the category'));
    }

    _getChildrenFields() {
        return new Field('children')
            .addFieldList(this._getDefaultFields());
    }

    _getChildren() {
        return new Field('children')
            .addFieldList(this._getALLFields());
    }

    _getBreadcrumbsField() {
        return new Field('breadcrumbs')
            .addFieldList(this._getBreadcrumbFields());
    }

    _getBreadcrumbFields() {
        return [
            'category_name',
            'category_level',
            'category_url',
            'category_is_active',
            'category_id'
        ];
    }

    _getCmsBlockFields() {
        return [
            'content',
            'disabled',
            'title',
            'identifier'
        ];
    }

    _getCmsBlockField() {
        return new Field('cms_block')
            .addFieldList(this._getCmsBlockFields());
    }

    _getDefaultFields() {

        return [
            'id',
            'url',
            'name',
            'image',
            'url_key',
            'url_path',
            'is_active',
            'meta_title',
            'description',
            'canonical_url',
            'product_count',
            'meta_keywords',
            'default_sort_by',
            'meta_description',
            'landing_page',
            'include_in_menu',
            'navigation_by_filter',
            this._getCmsBlockField(),
            this._getBreadcrumbsField(),
        ];
    }

    _getALLFields() {
        return [
            ...this._getChildrenAllFields(),
            new Field('children').addFieldList([
                    ...this._getChildrenAllFields(),
                    new Field('children')
                        .addFieldList(this._getChildrenAllFields())
                ])
        ];
    }

    _getChildrenAllFields() {
        return [
            'id',
            'url',
            'name',
            'include_in_menu',
            'position',
            'level',
        ];
    }

}

export default new CategoryQuery();

