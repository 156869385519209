/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export {
    SHIPPING_STEP,
    BILLING_STEP,
    DETAILS_STEP,
    CHECKOUT_URL,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY
} from 'SourceRoute/Checkout/Checkout.config';

export const ORDER_ID = 'order_id';

export const MOBILE_CHECKOUT_SUMMARY_POPUP = 'mobile_checkout_summary';

export const PICKUP_FLOW = 'pickup_flow';
export const DELIVERY_FLOW = 'delivery_flow';
