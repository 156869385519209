import { PureComponent } from "react";
import { connect } from "react-redux";

import SearchTypeDropdown from "./SearchTypeDropdown.component";
import { getStyleInNumberForm } from 'Util/Ecom';
import { throttle } from "Util/Window";

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => ({});

export class SearchTypeDropdownContainer extends PureComponent {
    static getDerivedStateFromProps(props, state) {
        const { isSearchWindowOpened } = props;
        const { isOpened } = state;

        if (isSearchWindowOpened && isOpened) {
            return {
                isOpened: false,
            };
        }

        return null;
    }

    state = {
        isOpened: false,
    };

    componentDidMount() {
        this.setSelectButtonWidth();
        window.addEventListener('resize', throttle(this.setSelectButtonWidth.bind(this), 200));
    }

    componentDidUpdate(prevProps) {
        const { activeType } = this.props;
        const { activeType: prevActiveType } = prevProps;

        if (activeType !== prevActiveType) {
            this.setSelectButtonWidth();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', throttle(this.setSelectButtonWidth.bind(this), 200));
    }

    setSelectButtonWidth() {
        const button = document.getElementById("SearchTypeDropdownButton");

        if (!button) {
            return;
        }

        const padding = 22;
        const childrenWidth = Array.prototype.reduce.call(
            button.children,
            (acc, child) => {
                const width = Math.ceil(child.getBoundingClientRect().width);
                const marginRight = getStyleInNumberForm(window.getComputedStyle(child).marginRight);

                acc += width + marginRight;
                return acc;
            },
            0
        );

        const nextWidth = childrenWidth + padding;

        button.style.maxWidth = `${nextWidth + padding}px`;
        button.style.minWidth = `${nextWidth}px`;
    }

    getCurrentTitle(searchTypes) {
        const { activeType } = this.props;

        return searchTypes
            .filter(({ type: searchType }) => searchType === activeType)[0]
            .title.toString();
    }

    setIsOpened() {
        this.setState(({ isOpened }) => ({ isOpened: !isOpened }));
    }

    onDropdownOpen() {
        const { closeSearchWindow } = this.props;

        closeSearchWindow();
        this.setIsOpened();
    }

    closeDrowpdown() {
        this.setState({ isOpened: false });
    }

    onSearchTypeSelect(type) {
        const { setSearchType } = this.props;

        setSearchType(type);
        this.closeDrowpdown();
    }

    containerProps = () => ({
        ...this.props,
        ...this.state,
        selectButtonRef: this.selectButtonRef,
    });

    containerFunctions = {
        setIsOpened: this.setIsOpened.bind(this),
        onDropdownOpen: this.onDropdownOpen.bind(this),
        closeDrowpdown: this.closeDrowpdown.bind(this),
        onSearchTypeSelect: this.onSearchTypeSelect.bind(this),
    };

    render() {
        return (
            <SearchTypeDropdown
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchTypeDropdownContainer);
