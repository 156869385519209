import { PureComponent } from 'react';

import Switch from './Switch.component';

export class SwitchContainer extends PureComponent {
    containerProps = () => {
        const {
            isActivated,
            mix,
            onClick
        } = this.props;

        return {
            isActivated,
            mix,
            onClick
        };
    };

    render() {
        return <Switch
            { ...this.containerProps() }
            { ...this.containerFunctions }
        />;
    }
}

export default SwitchContainer;
