import { PureComponent } from "react";

import TextPlaceholder from "Component/TextPlaceholder";

import "./BlogPostCategories.style";

export class BlogPostCategories extends PureComponent {
    renderTextPlaceholder(length) {
        return <TextPlaceholder length={length} />;
    }

    renderCategory({ name }) {
        return (
            <span block="BlogPostCategories" elem="CategoryName">
                {name}
            </span>
        );
    }

    renderCategories() {
        const { categories } = this.props;

        if (!categories?.length) {
            return null;
        }

        return categories.map(this.renderCategory);
    }

    render() {
        const { categories, mix } = this.props;

        if (!categories?.length) {
            return this.renderTextPlaceholder();
        }

        return (
            <div block="BlogPostCategories" elem="Categories" mix={mix}>
                {this.renderCategories()}
            </div>
        );
    }
}

export default BlogPostCategories;
