
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Notification from 'Component/Notification';
import { NotificationListType } from 'Type/NotificationList';

import './NotificationList.style';

export class NotificationList extends PureComponent {
    static propTypes = {
        notifications: NotificationListType.isRequired,
        onHideNotification: PropTypes.func.isRequired
    };

    render() {
        const { onHideNotification, notifications } = this.props;

        return (
            <div block="NotificationList">
                { Object.keys(notifications).map((id) => (
                    <Notification
                      key={ id }
                      notificationId={ id }
                      notification={ notifications[id] }
                      onHideNotification={ onHideNotification }
                    />
                )) }
            </div>
        );
    }
}

export default NotificationList;
