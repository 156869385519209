/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ConfigQuery from 'Query/Config.query';
import RegionQuery from 'Query/Region.query';
import ReviewQuery from 'Query/Review.query';
import { updateConfig, updateIsWelcomeOpen, updateClosestStore, updateCustomerInterests } from 'Store/Config/Config.action';
import { updateCheckoutFlow } from 'Store/Checkout/Checkout.action';
import { DELIVERY_FLOW } from 'Route/Checkout/Checkout.config';
import { ONE_HOUR } from 'Util/Auth'
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { fetchQuery } from 'Util/Request';
import { getClosestStore } from 'Util/Stores';

import { ConfigDispatcher as SourceConfigDispatcher } from 'SourceStore/Config/Config.dispatcher';
import MyAccountQuery from 'Query/MyAccount.query';

export const SearchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Search/Search.dispatcher'
);

export const CLOSEST_STORE = 'closest_store';
export const DEFAULT_LOCATION_CODE = 'default';
export const PICKUP_LOCATIONS = 'pickup_locations';

/** @namespace Store/Config/Dispatcher */
export class ConfigDispatcher extends SourceConfigDispatcher {
    async onSuccess(data, dispatch) {
        if (data) {
            const { pickupLocations, customAttributeMetadata } = data;
            const closestStore = BrowserDatabase.getItem(CLOSEST_STORE)
            this.resetAbsentClosestStore(dispatch, closestStore, pickupLocations);
            
            if(customAttributeMetadata) {
                this.setCustomerInterests(dispatch, customAttributeMetadata.items[0].attribute_options);
            }

            if (!!pickupLocations) {
                BrowserDatabase.setItem(pickupLocations, PICKUP_LOCATIONS, ONE_HOUR);

                if (!closestStore) {
                    const closestStore = await getClosestStore(pickupLocations);
                    this.setClosestStore(dispatch, closestStore);
                }
            }

            BrowserDatabase.setItem(data, 'config', ONE_MONTH_IN_SECONDS);
            dispatch(updateConfig(data));

            SearchDispatcher.then(
                ({ default: dispatcher }) => dispatcher.getTopSearchProducts(dispatch)
            );
        }
    }

    async resetAbsentClosestStore(dispatch, closestStore, pickupLocations) {
        const isObj = closestStore && typeof closestStore === 'object';
        const isEmpty = isObj && Object.values(closestStore).every(x => x === null || x === '');
        const isDataAbsent = isObj
            && closestStore.expiration
            && closestStore.createdAt
            && !closestStore.data;

        if (!closestStore || isDataAbsent || isEmpty) {
            const storedPickupLocations = BrowserDatabase.getItem(PICKUP_LOCATIONS);
            const locations = pickupLocations || storedPickupLocations;

            const newClosestStore = await getClosestStore(locations);
            this.setClosestStore(dispatch, newClosestStore);
        }
    }

    setClosestStore(dispatch, closestStore) {
        if (closestStore?.pickup_location_code === DEFAULT_LOCATION_CODE) {
            dispatch(updateCheckoutFlow(DELIVERY_FLOW));
        }

        dispatch(updateIsWelcomeOpen(true));
        dispatch(updateClosestStore(closestStore));
        BrowserDatabase.setItem(closestStore, CLOSEST_STORE, ONE_MONTH_IN_SECONDS);
    }

    setCustomerInterests(dispatch, customerInterests) {
        dispatch(updateCustomerInterests(customerInterests));
        BrowserDatabase.setItem(customerInterests, 'customerInterests', ONE_MONTH_IN_SECONDS);
    }

    async getConfig() {
        const query = [
            RegionQuery.getCountriesQuery(),
            ReviewQuery.getRatingQuery(),
            ConfigQuery.getQuery(),
            ConfigQuery.getCheckoutAgreements(),
            ConfigQuery.getStoreListField(),
            ConfigQuery.getPickupLocations(),
            await ConfigQuery.getClosestStore(),
            MyAccountQuery.getCustomerInterests()
        ];

        fetchQuery(query);
    }

    prepareRequest() {
        const pickupLocations = BrowserDatabase.getItem(PICKUP_LOCATIONS);
        const query = [
            RegionQuery.getCountriesQuery(),
            ReviewQuery.getRatingQuery(),
            ConfigQuery.getQuery(),
            ConfigQuery.getCheckoutAgreements(),
            ConfigQuery.getStoreListField(),
            ConfigQuery.getPickupLocations(),
            MyAccountQuery.getCustomerInterests()
        ];

        // If typeof name === "string", closestStore is set in BrowserDB.
        // Avoiding querying it again.
        if (Array.isArray(pickupLocations?.items)) {
            return query.filter(({ name }) => name !== 'pickupLocations');
        }

        return query;
    }
}

export default new ConfigDispatcher();
