import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DEFAULT_LOCATION_CODE } from 'Component/ClosestStoreOverlay/ClosestStoreOverlay.config';
import { PICKUP_FLOW, DELIVERY_FLOW } from 'Route/Checkout/Checkout.config';
import CheckoutQuery from 'Query/Checkout.query';
import ConfigQuery from 'Query/Config.query';
import { updateClosestStore } from 'Store/Config/Config.action';
import { CLOSEST_STORE } from 'Store/Config/Config.dispatcher';
import {
    updateSelectedShippingMethod,
    updateIsPickupPopupCloseVisible,
    updateIsPickupOverlayRequired
} from 'Store/Cart/Cart.action';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    updateCheckoutFlow,
    updateShippingLocation,
    updateEstimateShippingFields,
    updateLastEstimateShippingAddress
} from 'Store/Checkout/Checkout.action';

import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { fetchMutation } from 'Util/Request';
import {
    getAppropriateQuoteId,
    getSelectedLocation
} from 'Util/Cart';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import PickupOverlay from './PickupOverlay.component';

import {
    HOME_DELIVERY_OPTION,
    PICKUP_OPTION,
    DELIVERY_STEP,
    SCHEDULE_STEP,
    CHECKOUT_FLOW,
    COMMENT_VALUE
} from './PickupOverlay.config';
import { STORE_PICKUP_METHOD } from "Component/CheckoutPickupShipping/CheckoutPickupShipping.config";

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const CheckoutDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Checkout/Checkout.dispatcher'
);

export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    closestStore: state.ConfigReducer.getClosestStore,
    storeTimeslots: state.CartReducer.storeTimeslots,
    pickupMethods: state.CartReducer.pickupMethods,
    isPickupPopupCloseVisible: state.CartReducer.isPickupPopupCloseVisible,
    activeOverlay: state.OverlayReducer.activeOverlay,
    pickupLocations: state.ConfigReducer.pickupLocations.items,
    isCartProductsAdding: state.CartReducer.isCartProductsAdding,
    checkoutFlow: state.CheckoutReducer.checkoutFlow,
    isCustomerClosestStoreResolved: state.MyAccountReducer.isCustomerClosestStoreResolved,
    commentValue: state.CartReducer.commentValue,
    shippingLocation: state.CheckoutReducer.shippingLocation,
    excludedItems: state.CartReducer.excludedItems,
    lastShippingAddress: state.CheckoutReducer.lastShippingAddress,
});

export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    updateSelectedShippingMethod: (selectedShippingMethod) => dispatch(updateSelectedShippingMethod(selectedShippingMethod)),
    updateIsPickupOverlayRequired: (isRequired) => dispatch(updateIsPickupOverlayRequired(isRequired)),
    getStoreTimeSlots: (storeCode) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getStoreTimeSlots(dispatch, storeCode)
    ),
    distributeCart: (storeCode, isPickupConfirm) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.distributeCart(dispatch, storeCode, isPickupConfirm)
    ),
    setDeliveryTimeslotToCart: (slotData) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setDeliveryTimeslotToCart(dispatch, slotData)
    ),
    saveAddressInformation: (addressInformation, errHandler) => CheckoutDispatcher.then(
        ({ default: dispatcher }) => dispatcher.saveAddressInformation(dispatch, addressInformation, errHandler)
    ),
    updateEstimateShippingFields: (estimateShippingFields) => dispatch(updateEstimateShippingFields(estimateShippingFields)),
    updateLastEstimateShippingAddress: (address) => dispatch(updateLastEstimateShippingAddress(address)),
    updateCheckoutFlow: (checkoutFlow) => dispatch(updateCheckoutFlow(checkoutFlow)),
    hidePopup: () => dispatch(showPopup('', {})),
    updateClosestStore: (closestStore) => dispatch(updateClosestStore(closestStore)),
    updateShippingLocation: (shippingLocation) => dispatch(updateShippingLocation(shippingLocation)),
    updateIsPickupPopupCloseVisible: (isVisible) => dispatch(updateIsPickupPopupCloseVisible(isVisible))
});

export class PickupOverlayContainer extends PureComponent {
    confirmButtonRef = createRef();

    static propTypes = {
        onShippingEstimationFieldsChange: PropTypes.func.isRequired
    };

    static defaultProps = {

    };

    constructor(props) {
        super(props);

        const {
            pickupLocations,
            closestStore: {
                pickup_location_code
            } = {},
            checkoutFlow
        } = props;

        const selectedStoreCode = getSelectedLocation(pickupLocations, pickup_location_code)?.pickup_location_code;
        const buttonId = this.getButtonId(checkoutFlow, pickup_location_code);

        this.state = {
            isLoading: false,
            buttonId,
            overlayStep: DELIVERY_STEP,
            isShippingAddressAllowed: null,
            isDeliveryOptionsLoading: true,
            shippingMethods: [],
            selectedShippingMethod: {},
            selectedStoreCode,
            requestsSent: 0,
            selectedTimeslot: {},
            // date in date format.
            selectedDate: '',
            // date text.
            selectedDay: '',
            locationFormRegionId: -1
        }
    }

    componentDidUpdate(prevProps) {
        const {
            checkoutFlow,
            pickupLocations,
            isCustomerClosestStoreResolved,
            closestStore: {
                pickup_location_code
            } = {}
        } = this.props;

        const { selectedStoreCode } = this.state;

        const {
            checkoutFlow: prevCheckoutFlow,
            isCustomerClosestStoreResolved: prevIsCustomerClosestStoreResolved,
            closestStore: {
                // null by default, so, it's not getting overwritten by true in getButtonId function
                pickup_location_code: prevLocationCode = null
            } = {}
        } = prevProps;

        const buttonId = this.getButtonId(checkoutFlow, pickup_location_code, prevLocationCode);

        // we don't rely on closest store code in this case, as it can be 'default', which has to be filtered out
        const currentLocationCode = getSelectedLocation(pickupLocations, selectedStoreCode)?.pickup_location_code
            || getSelectedLocation(pickupLocations, pickup_location_code)?.pickup_location_code;

        if (checkoutFlow !== prevCheckoutFlow || pickup_location_code !== prevLocationCode) {
            this.setState({ buttonId });
        }

        if (currentLocationCode !== selectedStoreCode) {
            this.setSelectedStoreCode(currentLocationCode);
        }

        if (isCustomerClosestStoreResolved !== prevIsCustomerClosestStoreResolved) {
            const currentLocationCode = getSelectedLocation(pickupLocations, pickup_location_code)?.pickup_location_code;
            this.setSelectedStoreCode(currentLocationCode);
        }
    }

    getButtonId = (checkoutFlow, pickup_location_code, prevLocationCode = true) => {
        // this method is called in constructor, before state initialization
        const prevBtnId = this.state && this.state.buttonId;

        if (prevBtnId && !(pickup_location_code && !prevLocationCode)) {
            return prevBtnId;
        }

        if (checkoutFlow === '') {
            return pickup_location_code === DEFAULT_LOCATION_CODE
                ? HOME_DELIVERY_OPTION
                : PICKUP_OPTION
        }

        return checkoutFlow === PICKUP_FLOW
            ? PICKUP_OPTION
            : HOME_DELIVERY_OPTION;
    }

    hideAndReset() {
        const { hidePopup } = this.props;
        this.toInitialState();
        hidePopup();
    }

    containerProps = () => {
        const {
            buttonId,
            overlayStep,
            isShippingAddressAllowed,
            isDeliveryOptionsLoading,
            shippingMethods,
            selectedStoreCode,
            selectedTimeslot,
            selectedDate,
            selectedShippingMethod,
            isLoading
        } = this.state;

        const {
            device: { isMobile },
            pickupLocations,
            isPickupPopupCloseVisible,
            checkoutFlow,
            isCartProductsAdding
        } = this.props;

        const isDelivery = buttonId === HOME_DELIVERY_OPTION;

        return {
            buttonId,
            overlayStep,
            isDelivery,
            isMobile,
            isShippingAddressAllowed,
            isDeliveryOptionsLoading,
            isPickupPopupCloseVisible,
            selectedStoreCode,
            shippingMethods,
            selectedTimeslot,
            selectedShippingMethod,
            selectedDate,
            isLoading,
            pickupLocations,
            checkoutFlow,
            isCartProductsAdding,
            confirmButtonRef: this.confirmButtonRef
        };
    };

    containerFunctions = {
        onDeliveryOptionClick: this.onDeliveryOptionClick.bind(this),
        onBackClick: this.onBackClick.bind(this),
        onConfirmClick: this.onConfirmClick.bind(this),
        onShippingEstimationFieldsChange: this.onShippingEstimationFieldsChange.bind(this),
        setSelectedStoreCode: this.setSelectedStoreCode.bind(this),
        onTimeSlotClick: this.onTimeSlotClick.bind(this),
        onDayClick: this.onDayClick.bind(this),
        setIsLoading: this.setIsLoading.bind(this),
        hidePopup: this.hideAndReset.bind(this),
        setLocationFormRegionId: this.setLocationFormRegionId.bind(this)
    };

    setLocationFormRegionId(locationFormRegionId) {
        this.setState({ locationFormRegionId });
    }

    setIsLoading(isLoading) {
        this.setState({ isLoading });
    }

    setSelectedStoreCode(selectedStoreCode) {
        this.setState({ selectedStoreCode });
    }

    toInitialState() {
        const { selectedStoreCode: currentlySelectedStoreCode } = this.state;
        const {
            pickupLocations,
            closestStore: { pickup_location_code } = {},
            checkoutFlow
        } = this.props;

        const selectedStoreCode = currentlySelectedStoreCode
            || getSelectedLocation(pickupLocations, pickup_location_code)?.pickup_location_code;
        const buttonId = this.getButtonId(checkoutFlow, pickup_location_code);

        this.setState({
            buttonId,
            overlayStep: DELIVERY_STEP,
            selectedStoreCode,
            selectedTimeslot: {},
            selectedDate: ''
        });
    }

    onDeliveryOptionClick(buttonId) {
        this.setState({ buttonId });
    }

    onBackClick() {
        this.toInitialState();
        this.setState({ overlayStep: DELIVERY_STEP });
    }

    onDayClick(selectedDate, selectedDay, isAvailable) {
        if (!isAvailable) {
            return;
        }

        this.setState({ selectedDate, selectedDay, selectedTimeslot: {} });
    }

    onTimeSlotClick(selectedTimeslot, isAvailable) {
        if (!isAvailable) {
            return;
        }

        this.setState({ selectedTimeslot });
    }

    hidePopupAndRedirect() {
        this.hideAndReset();
        history.push({
            pathname: appendWithStoreCode('/cart')
        });
    }

    async onPickupConfirm() {
        const {
            setDeliveryTimeslotToCart,
            updateCheckoutFlow,
            pickupLocations,
            getStoreTimeSlots,
            distributeCart,
            updateIsPickupPopupCloseVisible,
            updateIsPickupOverlayRequired,
            commentValue,
            closestStore: {
                pickup_location_code
            } = {}
        } = this.props;

        const {
            selectedTimeslot: {
                id: slot_id,
                time,
                time_value
            },
            selectedDate: date,
            selectedDay: day,
            selectedStoreCode: store_code
        } = this.state;

        const slotToSet = {
            slot_id,
            store_code,
            date,
            time,
            time_value,
            day
        };

        const storeToAssign = pickupLocations
            .filter(({ pickup_location_code }) => pickup_location_code === store_code)[0];

        try {
            this.setIsLoading(true);

            const pickupMethods = await setDeliveryTimeslotToCart(slotToSet);

            if (!pickupMethods) {
                this.setState({ selectedTimeslot: {} });
                await getStoreTimeSlots(store_code);
                this.setIsLoading(false);
                return;
            } else {
                await distributeCart(store_code, true);
                await this.updateTaxes(pickupMethods);
            }

            if (storeToAssign) {
                await this.updateClosestStore(storeToAssign);
            }

            updateIsPickupOverlayRequired(false);
            updateCheckoutFlow(PICKUP_FLOW)
            BrowserDatabase.setItem(PICKUP_FLOW, CHECKOUT_FLOW, 10);
            BrowserDatabase.setItem(true, 'user_changed_location', 86400);
            this.setIsLoading(false);
            this.hidePopupAndRedirect();
            updateIsPickupPopupCloseVisible(true);

            if (pickup_location_code !== store_code) {
                this.hidePopupAndRedirect();
                BrowserDatabase.setItem(commentValue, COMMENT_VALUE)
                window.location.reload();
            }
        } catch(err) {
            // TODO: handle exceptions
            this.setIsLoading(false);
        }
    }

    async updateTaxes(pickupMethods) {
        const { closestStore } = this.props;

        if (pickupMethods[0]) {
            await this.saveAddressAndPayment(closestStore, pickupMethods[0]);
        }
    }

    async updateClosestStore(store) {
        const { updateClosestStore } = this.props;

        const { pickup_location_code } = store;
        const mutation = ConfigQuery.resolveClosestStore(pickup_location_code);

        try {
            let isStoreAssigned = true;

            if (isSignedIn()) {
                const { resolveClosestStore } = await fetchMutation(mutation);
                isStoreAssigned = !!resolveClosestStore;
            }

            if (isStoreAssigned) {
                BrowserDatabase.setItem(store, CLOSEST_STORE, ONE_MONTH_IN_SECONDS);
                updateClosestStore(store);
            }

        } catch(e) {
            this._handleError(e);
        }
    }

    async onConfirmClick() {
        const {
            buttonId,
            overlayStep,
            selectedStoreCode,
            address,
            selectedShippingMethod,
        } = this.state;

        const {
            getStoreTimeSlots,
            updateCheckoutFlow,
            distributeCart,
            updateShippingLocation,
            pickupLocations,
            updateIsPickupPopupCloseVisible,
            updateEstimateShippingFields,
            lastShippingAddress,
            excludedItems
        } = this.props;

        const isPickupPressed = buttonId === PICKUP_OPTION,
        isDeliveryStep = overlayStep === DELIVERY_STEP,
        isScheduleStep = overlayStep === SCHEDULE_STEP;

        if (isPickupPressed && isDeliveryStep) {
            try {
                this.setIsLoading(true);
                const isScheduleRecieved = await getStoreTimeSlots(selectedStoreCode);
                this.setIsLoading(false);

                if (isScheduleRecieved) {
                    this.setState({ overlayStep: SCHEDULE_STEP });
                }
            } catch(err) {
                // TODO: handle exceptions
                this.setIsLoading(false);
            }
        } else if (isScheduleStep) {
            this.onPickupConfirm();
        } else {
            try {
                this.setIsLoading(true);
                // 'default' code is used for shipping checkout flow.
                updateShippingLocation(address);
                const currentExcludedItems = [ ...excludedItems ];
                const { excluded_items, items_qty } = await distributeCart('default');
                await this.onDistributeCart(currentExcludedItems, excluded_items);

                // if (items_qty) {
                //     await this.saveAddressAndPayment(address, selectedShippingMethod);
                // }
                this.hidePopupAndRedirect();
                this.setIsLoading(false);
                updateCheckoutFlow(DELIVERY_FLOW);
                BrowserDatabase.setItem(DELIVERY_FLOW, CHECKOUT_FLOW, 10);
                BrowserDatabase.setItem(true, 'user_changed_location', 86400);
                updateIsPickupPopupCloseVisible(true);

                if (lastShippingAddress) {
                    updateEstimateShippingFields(lastShippingAddress);
                }
            } catch(err) {
                console.error('onConfirmClick checkout error: ', err);
            }
        }

        if (buttonId === HOME_DELIVERY_OPTION) {
            const storeOption = pickupLocations
                .filter(({ pickup_location_code }) => pickup_location_code === DEFAULT_LOCATION_CODE)[0];
            await this.updateClosestStore(storeOption);
        }
    }

    async onDistributeCart(currentExcludedItems, nextExcludedItems) {
        const { shippingLocation } = this.props;

        if (
            JSON.stringify(currentExcludedItems)
            !== JSON.stringify(nextExcludedItems)
        ) {
            await this.onShippingEstimationFieldsChange(shippingLocation, true);
        }

    }

    async onShippingEstimationFieldsChange(address, isOnDistribute = false) {
        const { requestsSent } = this.state;
        const {
            updateSelectedShippingMethod,
            updateShippingLocation,
            updateLastEstimateShippingAddress
        } = this.props;

        if (!address) return;
        
        const { country_id, region_id, postcode } = address;
        if (!country_id || !region_id || !postcode) {
            return;
        }

        this.setState({
            isDeliveryOptionsLoading: true,
            requestsSent: requestsSent + 1,
            address
        });

        this.setIsLoading(true);
        await fetchMutation(CheckoutQuery.getEstimateShippingCosts(
            {
                country_id,
                region_id,
                postcode
            },
            getAppropriateQuoteId()
        )).then(
            // TODO: finish this saving logic.
            /** @namespace Route/Checkout/Container/onShippingEstimationFieldsChangeFetchMutationThen */
            ({ estimateShippingCosts }) => {
                const { requestsSent } = this.state;

                const shippingMethods = estimateShippingCosts
                    .filter(({ method_code }) => method_code !== STORE_PICKUP_METHOD );

                const selectedShippingMethod = this.getSelectedShippingMethod(
                    shippingMethods,
                    isOnDistribute
                );

                updateShippingLocation(address);
                updateSelectedShippingMethod(selectedShippingMethod);
                updateLastEstimateShippingAddress(address);

                this.setState({
                    shippingMethods,
                    selectedShippingMethod,
                    isShippingAddressAllowed: !!shippingMethods.length,
                    isDeliveryOptionsLoading: requestsSent > 1,
                    requestsSent: requestsSent - 1
                });

                if (!isOnDistribute) {
                    this.setIsLoading(false);
                }
            },
            this._handleError
        );
    }

    getSelectedShippingMethod(shippingMethods, isOnDistribute) {
        const { selectedShippingMethod } = this.state;

        const isPreferSelected = isOnDistribute
            && selectedShippingMethod
            && Object.keys(selectedShippingMethod)
            && shippingMethods.length
            && shippingMethods.some(
                ({ method_code }) => method_code === selectedShippingMethod.method_code);

        return isPreferSelected
            ? shippingMethods.filter(({ method_code }) => method_code === selectedShippingMethod.method_code)[0]
            : shippingMethods[0] || {};
    }

    async saveAddressAndPayment(address, selectedShippingMethod) {
        const { saveAddressInformation } = this.props;

        const {
            country_id,
            postcode,
            region_id
        } = address;

        const {
            carrier_code,
            method_code
        } = selectedShippingMethod;

        if (postcode) {
            const address = {
                billing_address: {
                    country_id,
                    postcode,
                    region_id
                },
                shipping_address: {
                    country_id,
                    postcode,
                    region_id
                },
                shipping_carrier_code: carrier_code,
                shipping_method_code: method_code
            }

            await saveAddressInformation(address, this._handleError.bind(this));
        }
    }

    _handleError = (error) => {
        const { showErrorNotification } = this.props;
        const [{ message, debugMessage }] = error;

        this.setState({
            isDeliveryOptionsLoading: false,
            isLoading: false
        }, () => {
            showErrorNotification(debugMessage || message);
        });

        return false;
    };

    render() {
        return (
            <PickupOverlay
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupOverlayContainer);
