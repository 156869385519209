import { PureComponent } from 'react';

import { ReactComponent as MoreFiltersIcon } from "./icons/more_filter_icon.svg";

import './MoreFiltersBtn.style';

export class MoreFiltersBtn extends PureComponent {
    render() {
        const {
            isMoreFiltersActive = true,
            onFiltersOverlayOpen,
            moreFiltersBtnRef,
            isMobileType,
            mix,
            buttonText
        } = this.props;

        const text = buttonText || __("+ filters");

        return (
            <button
                block="MoreFiltersBtn"
                elem="ShowMoreButton"
                mods={{ isMoreFiltersActive, isMobileType }}
                mix={{ block: "ExpandableContent", elem: "Button", mix }}
                onClick={onFiltersOverlayOpen}
                ref={moreFiltersBtnRef}
            >
                <span block="MoreFiltersBtn" elem="ShowMoreText">
                    {text}
                </span>
                {!isMobileType && <MoreFiltersIcon />}
            </button>
        );
    }
}

export default MoreFiltersBtn;