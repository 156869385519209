/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright Â© Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getQueryParam, setQueryParams } from "Util/Url";
import { toggleOverlayByKey } from "Store/Overlay/Overlay.action";
import { freezeGlobalScrollModule } from "Util/Window";

import {
	CategoryFilterOverlayContainer as SourceCategoryFilterOverlayContainer,
	mapStateToProps as sourceMapStateToProps,
	mapDispatchToProps as sourceMapDispatchToProps,
} from "SourceComponent/CategoryFilterOverlay/CategoryFilterOverlay.container";

import CategoryFilterOverlay from "./CategoryFilterOverlay.component";
import {
	CATEGORY_FILTER_OVERLAY_ID,
	VISIBLE_FILTER_CODES,
	HIDDEN_POPUP_FILTER_CODES,
	IS_DELIVERABLE_FILTER_KEY,
} from "./CategoryFilterOverlay.config";

/** @namespace Component/CategoryFilterOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
	...sourceMapStateToProps(state),
	device: state.ConfigReducer.device,
	activeOverlay: state.OverlayReducer.activeOverlay,
	closestStore: state.ConfigReducer.getClosestStore,
    isNavigationInFilter: state.CategoryReducer.isNavigationInFilter
});

/** @namespace Component/CategoryFilterOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
	...sourceMapDispatchToProps(dispatch),
	showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
});

export class CategoryFilterOverlayContainer extends SourceCategoryFilterOverlayContainer {
    constructor(props) {
		super(props);

		// Retrieve the isDeliverable filter value from localStorage if it exists, otherwise, set the default value as false.
		const localIsDeliverableSwitched = localStorage.getItem(IS_DELIVERABLE_FILTER_KEY) === "1";

		this.state = {
			isDeliverableSwitched: localIsDeliverableSwitched,
			isDeliverableSwitchedToggled: false,
			isUpdatingFilter: false,
		};
	}

	containerFunctions = {
		onSeeResultsClick: this.onSeeResultsClick.bind(this),
		toggleCustomFilter: this.toggleCustomFilter.bind(this),
		getFilterUrl: this.getCustomFilterUrl.bind(this),
		onVisible: this.onVisible.bind(this),
		onFiltersOverlayOpen: this.onFiltersOverlayOpen.bind(this),
		closeOverlay: this.closeOverlay.bind(this),
		onHide: this.onHide.bind(this),
		resetFilters: this.resetFilters.bind(this),
		getVisibleFilters: this.getVisibleFilters.bind(this),
		getPopupVisibleFilters: this.getPopupVisibleFilters.bind(this),
		toggleIsDeliverableSwitched:
			this.toggleIsDeliverableSwitched.bind(this),
		onCloseButtonClick: this.onCloseButtonClick.bind(this),
	};

	componentDidUpdate(prevProps) {
		const { isDeliverableSwitched, isDeliverableSwitchedToggled } = this.state;
		const { activeOverlay } = this.props;
		const { activeOverlay: prevActiveOverlay } = prevProps;

		if (activeOverlay !== prevActiveOverlay) {
			if (activeOverlay) {
				freezeGlobalScrollModule.freezeScroll();
			} else {
				freezeGlobalScrollModule.unfreezeScroll();
			}
		}

		const selectedFiltersString = this._getSelectedFiltersFromUrl();
		const hasDeliverableSwitched = IS_DELIVERABLE_FILTER_KEY in selectedFiltersString;
		if(isDeliverableSwitched && !hasDeliverableSwitched && !isDeliverableSwitchedToggled) {
			this.updateIsDeliverableFilter();
		}
	}

	updateIsDeliverableFilter() {
		const { isUpdatingFilter } = this.state;
	
		if (!isUpdatingFilter) {
			this.toggleCustomFilter(IS_DELIVERABLE_FILTER_KEY, "1");
			localStorage.setItem(IS_DELIVERABLE_FILTER_KEY, "1");
			this.setState({ isDeliverableSwitchedToggled: true, isUpdatingFilter: false });
		}
	}

	onCloseButtonClick() {
		const { onMoreFiltersClick } = this.props;

		onMoreFiltersClick();
		document.dispatchEvent(new CustomEvent("onFiltersCloseClick"));
	}

	onFiltersOverlayOpen() {
		const { showOverlay, setIsSortOverlayOpened } = this.props;

		showOverlay(CATEGORY_FILTER_OVERLAY_ID);
		setIsSortOverlayOpened(false);
	}

	onSeeResultsClick() {
		const { goToPreviousHeaderState, goToPreviousNavigationState } =
			this.props;

		goToPreviousHeaderState();
		goToPreviousNavigationState();
	}

	closeOverlay() {
		const { hideActiveOverlay, activeOverlay, setIsSortOverlayOpened } =
			this.props;

		if (activeOverlay !== CATEGORY_FILTER_OVERLAY_ID) {
			return;
		}

		setIsSortOverlayOpened(false);
		hideActiveOverlay();
	}

    getPopupVisibleFilters() {
        const { availableFilters } = this.props;
        const filters = { ...availableFilters };

        for (const filterCode in availableFilters) {
            if (!HIDDEN_POPUP_FILTER_CODES.includes(filterCode)) {
                continue;
            }

            delete filters[filterCode];
        }

        return filters;
    }

	getVisibleFilters() {
		const { availableFilters } = this.props;

		let visibleFilters = {};

		for (let key in availableFilters) {
			if (VISIBLE_FILTER_CODES.includes(key)) {
				visibleFilters[key] = availableFilters[key];
			}
		}

		return visibleFilters;
	}

	toggleIsDeliverableSwitched() {
		const { isDeliverableSwitched, isUpdatingFilter } = this.state;
	
		if (!isUpdatingFilter) {
			this.setState({ isDeliverableSwitched: !isDeliverableSwitched, isUpdatingFilter: true },
			() => {
				this.toggleCustomFilter(IS_DELIVERABLE_FILTER_KEY, "1");
				localStorage.setItem(IS_DELIVERABLE_FILTER_KEY, !isDeliverableSwitched ? "1" : "");
				this.setState({
					isDeliverableSwitchedToggled: true,
					isUpdatingFilter: false,
				});
			});
		}
	}

	isContentFiltered() {
		const { customFilters, priceMin, priceMax } = this.urlStringToObject();
		return !!(customFilters || priceMin || priceMax);
	}

	urlStringToObject() {
		const {
			location: { search = "" },
		} = this.props;
		return search
			.substr(1)
			.split("&")
			.reduce((acc, part) => {
				const [key, value] = part.split("=");
				return { ...acc, [key]: value };
			}, {});
	}

	updateFilter(filterName, filterArray) {
		const { location, history } = this.props;
		setQueryParams(
			{
				customFilters: this.getFilterUrl(
					filterName,
					filterArray,
					false
				),
				page: "",
			},
			location,
			history
		);
	}

	toggleCustomFilter(requestVar, value) {
		this.updateFilter(
			requestVar,
			this._getNewFilterArray(requestVar, value)
		);
	}

	_getNewFilterArray(filterKey, value) {
		const { customFiltersValues } = this.props;
		const newFilterArray =
			customFiltersValues[filterKey] !== undefined
				? Array.from(customFiltersValues[filterKey])
				: [];

		const filterValueIndex = newFilterArray.indexOf(value);

		if (filterValueIndex === -1) {
			newFilterArray.push(value);
		} else {
			newFilterArray.splice(filterValueIndex, 1);
		}

		return newFilterArray;
	}

	_getSelectedFiltersFromUrl() {
		const { location } = this.props;
		const selectedFiltersString = (
			getQueryParam("customFilters", location) || ""
		).split(";");

		return selectedFiltersString.reduce((acc, filter) => {
			if (!filter) {
				return acc;
			}
			const [key, value] = filter.split(":");

			if (!value) {
				return acc;
			}

			return { ...acc, [key]: value.split(",") };
		}, {});
	}

	resetFilters() {
		const { location, history } = this.props;

		this.setState({ isDeliverableSwitched: false, isDeliverableSwitchedToggled: false });
		localStorage.setItem(IS_DELIVERABLE_FILTER_KEY, "");

		setQueryParams(
			{
				customFilters: "",
				priceMin: "",
				priceMax: "",
				page: "",
			},
			location,
			history
		);
	}

	render() {
		return (
			<CategoryFilterOverlay
				{...this.state}
				{...this.props}
				{...this.containerFunctions}
				{...this.containerProps()}
			/>
		);
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CategoryFilterOverlayContainer)
);
