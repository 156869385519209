import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock';
import { DeviceType } from 'SourceType/Device';
import Link from 'Component/Link';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import StoreSwitcher from 'SourceComponent/StoreSwitcher';
import {Footer as SourceFooter} from 'SourceComponent/Footer/Footer.component';
import ExpandableContent from 'SourceComponent/ExpandableContent/ExpandableContent.component';
import history from 'Util/History';

import isMobile from 'SourceUtil/Mobile';

import './Footer.style';
import {
    COLUMN_MAP,
    RENDER_NEWSLETTER,
    RENDER_STORE_SWITCHER,
    RENDER_FOOTER_LINKS,
    FOOTER_LINKS_MAP
} from './Footer.config';

export class Footer extends SourceFooter {
    static propTypes = {
        copyright: PropTypes.string,
        isVisibleOnMobile: PropTypes.bool,
        device: DeviceType
    };

    static defaultProps = {
        copyright: '',
        isVisibleOnMobile: true,
        device: {
            isMobile: false,
            android: false,
            ios: false,
            blackberry: false,
            opera: false,
            windows: true,
            standaloneMode: false
        }
    };

    renderMap = {
        [RENDER_NEWSLETTER]: {
            render: this.renderNewsletterSubscriptionBlock
        },
        [RENDER_STORE_SWITCHER]: {
            render: this.renderStoreSwitcher
        },
        [RENDER_FOOTER_LINKS]: {
            render: this.renderFooterLinks
        }
    };

    renderFooterLinks([key, value]) {
        return this.renderFooterColumn(value.items.block, value.items.collapsable, value.items.heading);
    }

    renderFooterColumn(block, collapsable, heading) {
        if(collapsable && isMobile) {
            return (
                <ExpandableContent
                    key={ block }
                    heading={heading}
                    mix={ {
                        block: 'LinksColumn',
                        elem: 'Expandable'
                    } }
                >
                    <CmsBlock identifier={block} />
                </ExpandableContent>
            );
        }

        return (
            <CmsBlock identifier={block} />
        )

    }


    renderStoreSwitcher() {
        return (
            <div block="footer" elem="Switcher">
                <StoreSwitcher />
            </div>
        );
    }

    renderNewsletterSubscriptionBlock() {
        return <NewsletterSubscription />;
    }

    renderColumns() {
        return Object.entries(COLUMN_MAP).map(this.renderBlocks.bind(this));
    }

    renderBlocks([key, value]) {
        if(value.items.addition === RENDER_FOOTER_LINKS){
            return (
                <div block="FooterLinks-Content">
                    <div block="FooterLinks">
                        { Object.entries(FOOTER_LINKS_MAP).map(this.renderFooterLinks.bind(this)) }
                    </div>
                </div>
            );
        }

        return(
            <div
                block={value.items.block}
                key={key}
            >
                <CmsBlock identifier={value.items.block}>
                    {this.renderAddition(value.items.addition)}
                </CmsBlock>

            </div>
        );
    }

    renderAddition(render) {
        return render !== '' ? this.renderMap[render].render() : '';
    }

    renderContent() {
        return (
            <div block="Footer" elem="Content">
                { this.renderColumns() }
            </div>
        );
    }

    // for its otherStoresUrl logic
    renderHiddenStoreSwitcher() {
        return (
            <StoreSwitcher mix={ {
                block: "Footer",
                elem: "StoreSwitcher",
                mods:{ isHidden: true }
              } }
            />
        );
    }

    renderCheckoutFooter() {
        const { storeCode } = this.props;
        const { location: { pathname } } = history;

        const isShippingStep = pathname.includes('/checkout/shipping')
        const checkoutFooterItems = [
            {
                name: __('Shipping Policy'),
                to: {
                    en: '/en/shipping-policy',
                    fr: '/fr/politique-de-livraison'
                }
            },
            {
                name: __('Terms and conditions'),
                to: {
                    en: '/en/terms-and-conditions',
                    fr: '/fr/termes-et-conditions'
                }
            },
            {
                name: __('Security and confidentiality'),
                to: {
                    en: '/en/security-and-confidentiality',
                    fr: '/fr/securite-et-confidentialite'
                }
            }
        ];

        return (
            <footer
              block="Footer"
              mods={ { isCheckout: true, isShippingStep } }
              aria-label="Footer"
            >
                <div
                  block="Footer"
                  elem="CheckoutItemsWrapper"
                >
                    <div
                      block="Footer"
                      elem="CheckoutItems"
                    >
                        <div
                          block="Footer"
                          elem="CheckoutItemsInnerWrapper"
                        >
                            { checkoutFooterItems.map(({ name, to }) => {
                                return(
                                    <Link
                                      block="Footer"
                                      elem="CheckoutItem"
                                      to={ to[storeCode] }
                                      target="_blank"
                                      mods={ { isCheckout: true } }
                                    >
                                        { name }
                                    </Link>
                                );
                            })}
                            {this.renderHiddenStoreSwitcher()}
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    render() {
        const { isVisibleOnMobile, device } = this.props;
        const { location: { pathname } } = history;

        const isCheckout = pathname.includes('/checkout');
        const isRegistration = pathname.includes('/registration');

        if (!isVisibleOnMobile && device.isMobile) {
            return this.renderHiddenStoreSwitcher();
        }

        if (isCheckout) {
            return this.renderCheckoutFooter();
        }

        return (
            <footer block="Footer" aria-label="Footer" mods={{isRegistration}}>
                { this.renderContent() }
            </footer>
        );
    }
}

export default Footer;
