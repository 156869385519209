import RO from 'resize-observer-polyfill';
import 'array-flat-polyfill';

export function addPolyfills() {
    // ResizeObserver polyfill
    if (!window.ResizeObserver) {
        window.ResizeObserver = RO;
    }

    // Object.entries polyfill
    if (!Object.fromEntries) {
        Object.fromEntries = function (entries){
            if (!entries || !entries[Symbol.iterator]) {
                throw new Error('Object.fromEntries() requires a single iterable argument');
            }

            let obj = {};

            for (let [key, value] of entries) {
                obj[key] = value;
            }

            return obj;
        };
    }

    // replaceAll polyfill
    if (!String.prototype.replaceAll) {
        String.prototype.replaceAll = function(str, newStr){

            // If a regex pattern
            if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
                return this.replace(str, newStr);
            }

            // If a string
            return this.replace(new RegExp(str, 'g'), newStr);
        };
    }
};
