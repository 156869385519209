import {
	BLOG_TYPE,
	RECIPE_TYPE,
} from "Component/SearchField/SearchField.config";
import { appendWithStoreCode } from "Util/Url";

export const getMoreProductsBtnText = (isMobile, activeType) => {
	if (isMobile) {
		return __("View all");
	}

	switch (activeType) {
		case BLOG_TYPE:
			return __("View all posts");
		case RECIPE_TYPE:
			return __("View more recipes");
		default:
			return __("View more products");
	}
};

export const getMoreProductsBtnUrl = (
    activeType,
    search_engine_route,
    mageside_recipe_seo_route,
    recipeCategoryUrlKey,
    productUrl
) => {
	switch (activeType) {
		case BLOG_TYPE:
			return appendWithStoreCode(`/${search_engine_route}`);
		case RECIPE_TYPE:
			return appendWithStoreCode(
				`/${mageside_recipe_seo_route}/${recipeCategoryUrlKey}`
			);
		default:
			return productUrl;
	}
};

export const getSearchCardItems = (
    activeType,
    searchedPosts,
    searchedRecipes,
    xsearchProducts
) => {
	switch (activeType) {
		case BLOG_TYPE:
			return searchedPosts || [];
		case RECIPE_TYPE:
			return searchedRecipes || [];
		default:
			return xsearchProducts || [];
	}
};

export const getTopProductsSectionHeader = (activeType, searchCriteria) => {
		switch (activeType) {
			case BLOG_TYPE:
				return !!searchCriteria.trim()
					? __("Search results")
					: __("Latest posts");
			case RECIPE_TYPE:
				return !!searchCriteria.trim()
					? __("Search results")
					: __("Recent recipes");
            default:
                return __("Top products");
		}
}
