/* eslint-disable fp/no-let, fp/no-loops */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CategoryPaginationLink from 'Component/CategoryPaginationLink';
import TextPlaceholder from 'Component/TextPlaceholder';

import './CategoryPagination.style';

export class CategoryPagination extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        pathname: PropTypes.string.isRequired,
        onPageSelect: PropTypes.func.isRequired,
        totalPages: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        getSearchQuery: PropTypes.func.isRequired,
        paginationFrame: PropTypes.number,
        paginationFrameSkip: PropTypes.number,
        anchorTextPrevious: PropTypes.string,
        anchorTextNext: PropTypes.string
    };

    static defaultProps = {
        isLoading: false,
        paginationFrame: 5,
        paginationFrameSkip: 4,
        anchorTextPrevious: '',
        anchorTextNext: ''
    };

    renderPreviousPageLink() {
        const {
            currentPage
        } = this.props;

        if (currentPage <= 1) {
            return (
                <li block="CategoryPagination" elem="ListItem" />
            );
        }

        return this.renderPageLink(
            currentPage - 1,
            __('Previous'),
            null,
            { block: "CategoryPagination", elem: "Arrow" },
            { ArrowLeft: true }
        );
    }

    renderNextPageLink() {
        const {
            currentPage,
            totalPages
        } = this.props;

        if (currentPage > totalPages - 1) {
            return (
                <li block="CategoryPagination" elem="ListItem" />
            );
        }

        return this.renderPageLink(
            currentPage + 1,
            __('Next'),
            null,
            { block: "CategoryPagination", elem: "Arrow" },
            { ArrowRight: true }
        );
    }

    // the first or the last page link
    renderLinkEdge(edgePageNumber) {
        return (
            <>
                { this.renderPageLink(
                    edgePageNumber,
                    __('Page %s', edgePageNumber),
                    edgePageNumber.toString()
                ) }
            </>
        );
    }

    renderDotsInPagination() {
        return(
            <span
              block="CategoryPagination"
              elem="Dots"
            >...</span>
        );
    }

    renderLinksWithEdges() {
        const {
            totalPages,
            currentPage,
            device: { isMobile }
        } = this.props;

        const isLeftEdgeRendered = isMobile
            ? currentPage > 2 && totalPages > 3
            : currentPage > 3 && totalPages > 5;
        const isLeftEllipsisRendered = isMobile
            ? currentPage > 3 && totalPages > 4
            : currentPage > 3 && totalPages > 6;
        const isRightEdgeRendered = isMobile
            ? currentPage < totalPages - 1 && totalPages > 3
            : currentPage < totalPages - 2 && totalPages > 5;
        const isRightEllipsisRendered = isMobile
            ? currentPage < totalPages - 2 && totalPages > 4
            : currentPage < totalPages - 2 && totalPages > 6;

        return (
            <>
                { isLeftEdgeRendered && this.renderLinkEdge(1) }
                { isLeftEllipsisRendered && this.renderDotsInPagination() }
                { this.renderLinksMiddleGroup() }
                { isRightEllipsisRendered && this.renderDotsInPagination() }
                { isRightEdgeRendered && this.renderLinkEdge(totalPages) }
            </>
        );
    }

    pushInPagesArray(array, loopFrom, loopTo) {
        const {
            currentPage
        } = this.props;

        let i;

        for (i = loopFrom; i <= loopTo; i++) {
            array.push(this.renderPageLink(
                i,
                __('Page %s', i),
                i.toString(),
                null,
                null,
                i === currentPage
            ))
        }
    }

    pushPagesMobile(target) {
        const { totalPages, currentPage } = this.props;

        const FIRST_GROUP = currentPage === 1;
        const MIDDLE_GROUP = currentPage > 1 && currentPage < totalPages;
        const LAST_GROUP = currentPage === totalPages;

        switch(true) {
            case FIRST_GROUP && totalPages > 2:
                this.pushInPagesArray(target, currentPage, 3);
                break;
            case FIRST_GROUP:
                this.pushInPagesArray(target, currentPage, totalPages);
                break;
            case MIDDLE_GROUP:
                this.pushInPagesArray(target, currentPage - 1, currentPage + 1);
                break;
            case LAST_GROUP && totalPages > 2:
                this.pushInPagesArray(target, currentPage - 2, totalPages);
                break;
            case LAST_GROUP:
                this.pushInPagesArray(target, currentPage - 1, totalPages);
                break;
        }
    }

    pushPagesDesktop(target) {
        const { totalPages, currentPage } = this.props;

        const FIRST_GROUP = currentPage === 1;
        const MIDDLE_GROUP = currentPage > 1 && currentPage < totalPages;
        const LAST_GROUP = [
            totalPages,
            totalPages - 1,
            totalPages - 2
        ].includes(currentPage) && totalPages > 4;

        switch(true) {
            case [1, 2, 3].includes(currentPage) && totalPages > 4:
                this.pushInPagesArray(target, 1, 5);
                break;
            case FIRST_GROUP:
                this.pushInPagesArray(target, currentPage, totalPages);
                break;
            case LAST_GROUP:
                this.pushInPagesArray(target, totalPages - 4, totalPages);
                break;
            case totalPages < 5:
                this.pushInPagesArray(target, 1, totalPages);
                break;
            case MIDDLE_GROUP:
                this.pushInPagesArray(target, currentPage - 1, currentPage + 1);
                break;
        }
    }

    renderLinksMiddleGroup() {
        const { device: { isMobile } } = this.props;

        const pages = [];

        if (isMobile) {
            this.pushPagesMobile(pages);
        } else {
            this.pushPagesDesktop(pages);
        }

        return pages;
    }

    renderPageIcon(isNext = false) {
        return (
            <span
              block="CategoryPagination"
              elem="Icon"
              mods={ { isNext } }
            />
        );
    }

    renderPageLink(
        pageNumber,
        label,
        children,
        arrowMix,
        arrowMods,
        isCurrent = false
    ) {
        const {
            pathname,
            onPageSelect,
            getSearchQuery,
            device: { isMobile }
        } = this.props;

        // verifications for pagination arrow buttons
        const ArrowLeft = arrowMods && arrowMods.ArrowLeft;
        const ArrowRight = arrowMods && arrowMods.ArrowRight;
        const isTextRendered = !(ArrowLeft || ArrowRight) || !isMobile ;

        return (
            <li
              key={ pageNumber }
              block="CategoryPagination"
              elem="ListItem"
              mix={ arrowMix }
            >
                <CategoryPaginationLink
                  label={ label }
                  url_path={ pathname }
                  getPage={ onPageSelect }
                  isCurrent={ isCurrent }
                  pageNumber={ pageNumber }
                  getSearchQueryForPage={ getSearchQuery }
                >
                    { ArrowLeft && <div block="CategoryPagination" elem="ArrowIcon" mods={ arrowMods } /> }
                    { isTextRendered && <div>
                            { children || label }
                        </div>
                    }
                    { ArrowRight && <div block="CategoryPagination" elem="ArrowIcon" mods={ arrowMods } /> }
                </CategoryPaginationLink>
            </li>
        );
    }

    renderPlaceholder() {
        return (
            <ul block="CategoryPagination" mods={ { isLoading: true } }>
                { Array.from({ length: 4 }, (_, i) => (
                    <li
                      key={ i }
                      block="CategoryPagination"
                      elem="ListItem"
                    >
                        <TextPlaceholder length="block" />
                    </li>
                )) }
            </ul>
        );
    }

    render() {
        const { isLoading, totalPages } = this.props;

        if (totalPages === 1) { // do not show pagination, if there are less then one page
            return <ul block="CategoryPagination" />;
        }

        if (isLoading) {
            return this.renderPlaceholder();
        }

        return (
            <nav aria-label={ __('Product list navigation') }>
                <ul block="CategoryPagination">
                    { this.renderPreviousPageLink() }
                    { this.renderLinksWithEdges() }
                    { this.renderNextPageLink() }
                </ul>
            </nav>
        );
    }
}

export default CategoryPagination;
