import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm/FieldForm.component';
import Form from 'Component/Form';

import './NewsletterSubscription.style';

export class NewsletterSubscription extends FieldForm {
    static propTypes = {
        onFormSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    get fieldMap() {
        const { emailValue, onEmailChange: onChange } = this.props;

        const fields = {
            email: {
                validation: ['notEmpty', 'email'],
                value: emailValue,
                placeholder: __('Enter your email address'),
                isControlled: true,
                onChange
            }
        };

        return fields;
    }

    renderActions() {
        return (
            <button
                type="submit"
                block="Button"
                mix={ { block: 'FieldForm', elem: 'Button' } }
                aria-label={ __('Submit') }
            />
        );
    }

    render() {
        const { isLoading, onFormSubmit } = this.props;
        return (
            <div block="NewsletterSubscription">
                <Form
                    onSubmitSuccess={ onFormSubmit }
                    mix={ { block: 'FieldForm' } }
                >
                    <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                        { this.renderFields() }
                        { this.renderActions() }
                    </div>
                </Form>
            </div>
        );
    }
}

export default NewsletterSubscription;
