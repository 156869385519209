import { lazy, createRef } from 'react';

import { ReactComponent as SearchIcon } from './icons/search.svg';
import { appendWithStoreCode } from 'Util/Url';
import history from 'Util/History';
import SearchWindow from 'Component/SearchWindow';
import ClickOutside from 'Component/ClickOutside';
import SearchTypeDropdown from 'Component/SearchTypeDropdown';
import { BLOG_TYPE, PRODUCTS_TYPE, RECIPE_TYPE } from 'Component/SearchField/SearchField.config';

import { SearchField as SourceSearchField } from 'SourceComponent/SearchField/SearchField.component';

export const SearchOverlay = lazy(
  () => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */
    'Component/SearchOverlay'
  )
);

import './SearchField.override.style.scss';

export class SearchField extends SourceSearchField {
  searchBarRef = createRef();
  searchBarWrapperRef = createRef();
  mobileCloseBtnRef = createRef();

  state = {
    isPlaceholderVisible: true,
    showSearch: false,
    isInFocus:false,
    isSearchWindowOpened: false
  };

  componentDidMount() {
    window.addEventListener('mouseup', this.onMouseUp.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onMouseUp.bind(this));
  }

  onClearSearchButtonClick(isFocusOnSearchBar = true) {
    const { onClearSearchButtonClick } = this.props;

    if (isFocusOnSearchBar) {
        this.searchBarRef.current.focus();
    }
    onClearSearchButtonClick();
  }

  closeSearchWindow() {
    const { setIsSearchWindowOpened } = this.props;
    setIsSearchWindowOpened(false);
  }

  setIsInFocus(isInFocus) {
    this.setState({ isInFocus });
  }

  onSearchFocus = () => {
    const { onSearchBarFocus, setIsSearchWindowOpened } = this.props;

    onSearchBarFocus();
    this.setIsInFocus(true);
    setIsSearchWindowOpened(true);
  }

  onSearchBlur = () => {
    this.setIsInFocus(false);
  }

  onMouseDown = (e) => {
    const { updateIsSearchInputClicked } = this.props;

    if (this.searchBarWrapperRef.current.contains(e.target)) {
        updateIsSearchInputClicked(true);
    }
  }

  onMouseUp = () => {
    const { updateIsSearchInputClicked } = this.props;
    // need this setState running at the last step
    setTimeout(() => {
        updateIsSearchInputClicked(false);
    });
  }

  clearSearch = () => {
    this.onClearSearchButtonClick(true);
  };

  onSearchEnterPress = (e) => {
    const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
    const search = encodeURIComponent(searchCriteria.trim().replace(/\s\s+/g, '%20'));
    const trimmedSearch = searchCriteria.trim();

    if (e.key === 'Enter' && trimmedSearch !== '') {
        history.push(appendWithStoreCode(`/search/${ search }`));
        hideActiveOverlay();
        onSearchBarChange({ target: { value: '' } });
        this.searchBarRef.current.blur();
        this.closeSearch();
    }
  };

  onSearchStart = (e, isIconPress = false) => {
    const { activeType } = this.props;

    const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
    const search = encodeURIComponent(searchCriteria.trim().replace(/\s\s+/g, '%20'));
    const trimmedSearch = searchCriteria.trim();

    if (!search.length || activeType !== PRODUCTS_TYPE) {
        return;
    }

    if (e.key === 'Enter' && trimmedSearch !== '' || isIconPress) {
      history.push(appendWithStoreCode(`/search/${search}`));
      hideActiveOverlay();
      onSearchBarChange({ target: { value: '' } });
      this.searchBarRef.current.blur();
      this.closeSearch();
    }
  };

  onIconClick = () => {
    this.searchBarRef.current.focus();
  };
  
  setIsSearchWindowOpened = (isSearchWindowOpened, target = null) => {
    const { setIsSearchWindowOpened, isSearchInputClicked } = this.props;

    if (
        isSearchInputClicked
        && target !== this.mobileCloseBtnRef.current
    ) {
        return;
    }

    setIsSearchWindowOpened(isSearchWindowOpened);

    if (!isSearchWindowOpened) {
      this.setState({ isInFocus: false });
    }
  }

  getInputPlaceholder() {
    const { activeType } = this.props;

    switch(activeType) {
        case BLOG_TYPE:
            return __('Search blog posts');
        case RECIPE_TYPE:
            return __('Search for recipes');
        default:
            return __('Rechercher des produits');
    }
  }

  renderSearchButton() {
    const { activeType, device: { isMobile } } = this.props;

    if (activeType !== PRODUCTS_TYPE) {
        return null;
    }

    if (isMobile) {
        return (
            <SearchIcon onClick={(e) => this.onSearchStart(e, true)} />
        );
    }

    return (
        <div
            block="SearchField"
            elem="SearchIcon"
            role="button"
            tabIndex="0"
            onClick={(e) => this.onSearchStart(e, true)}
            aria-label={__('Search')}
        >
            <SearchIcon />
        </div>
    );
  }

  renderSearch() {
    const {
      searchCriteria,
      isActive,
      isSearchWindowOpened,
      onClearSearchButtonClick,
      searchCategoriesSectionRef,
      searchProductsScrollableRef,
      searchWindowRef,
      activeType
    } = this.props;

    const { isInFocus } = this.state;

    return (
        <>
            <ClickOutside onClick={ this.closeSearchWindow.bind(this) }>
                {/* refs don't work for first level child for ClickOutside component */}
                <div>
                    <div
                      block="SearchField"
                      elem="SearchInnerWrapper"
                      ref={ this.searchBarWrapperRef }
                      onMouseDown={ this.onMouseDown }
                    >
                        {/* { this.renderSearchTypeDropdown() } */}
                        <input
                          id="search-field"
                            ref={this.searchBarRef}
                          block="SearchField"
                          elem="Input"
                          mods={ { isProductType: activeType === PRODUCTS_TYPE } }
                          onFocus={ this.onSearchFocus }
                          onBlur={ this.onSearchBlur }
                          onChange={this.handleChange}
                          onKeyDown={this.onSearchStart}
                          value={searchCriteria}
                          mods={{ isActive }}
                          autoComplete="off"
                          placeholder={ this.getInputPlaceholder() }
                        />
                        {/* <div
                          block="SearchField"
                          elem="resetButton"
                          mods={{
                              isShow: !!searchCriteria,
                              isProductType: activeType === PRODUCTS_TYPE
                          }}
                          onClick={this.clearSearch}>
                        </div> */}
                        { this.renderSearchButton() }
                        <SearchWindow
                          activeType={ activeType }
                          searchCriteria={ searchCriteria }
                          mobileCloseBtnRef={ this.mobileCloseBtnRef }
                          searchInputRef={ this.searchBarRef }
                          isActive={ isSearchWindowOpened }
                          onClearSearchButtonClick={ onClearSearchButtonClick }
                          setIsSearchWindowOpened={ this.setIsSearchWindowOpened }
                          searchCategoriesSectionRef={ searchCategoriesSectionRef }
                          searchProductsScrollableRef={ searchProductsScrollableRef }
                          searchWindowRef={ searchWindowRef }
                          handleSearchValChange={ this.handleChange }
                        />
                    </div>
                </div>
            </ClickOutside>
            { this.renderOverlay() }
        </>
    );
  }

  renderOverlay() {
    const { isSearchWindowOpened } = this.props;

    return (
        <div
          block="SearchField"
          elem="WindowOverlayShadow"
          mods={ { isActive: isSearchWindowOpened } }
        />
    );
  }

  renderMobileContent() {
    const {
      searchCriteria,
      isActive,
      onClearSearchButtonClick,
      device,
      isSearchWindowOpened,
      searchCategoriesSectionRef,
      searchProductsScrollableRef,
      searchWindowRef,
      activeType
    } = this.props;

    const { isInFocus } = this.state;

    if (!device.isMobile) {
      return null;
    }

    return (
        <>
             <ClickOutside onClick={ this.closeSearchWindow.bind(this) }>
                <div>
                    <div
                      block="SearchField"
                      elem="MobileWrapper"
                      ref={ this.searchBarWrapperRef }
                      onMouseDown={ this.onMouseDown }
                      onMouseUp={ this.onMouseUp }
                    >
                        {/* { this.renderSearchTypeDropdown() } */}
                        <div
                          block="SearchField"
                          elem="MobileInputWrapper"
                        >
                            <input
                              id="search-field"
                              ref={this.searchBarRef}
                              block="SearchField"
                              elem="Input"
                              mods={ { isProductType: activeType === PRODUCTS_TYPE } }
                              onFocus={ this.onSearchFocus }
                              onBlur={ this.onSearchBlur }
                              onChange={this.handleChange}
                              onKeyDown={this.onSearchStart}
                              value={searchCriteria}
                              mods={{ isActive }}
                              autoComplete="off"
                              placeholder={ this.getInputPlaceholder() }
                            />
                            {/* <div
                              block="SearchField"
                              elem="resetButton"

                              mods={{
                                  isShow: !!searchCriteria,
                                  isProductType: activeType === PRODUCTS_TYPE
                              }}
                              onClick={this.clearSearch}>
                            </div> */}
                            { this.renderSearchButton() }
                        </div>
                        <SearchWindow
                          activeType={ activeType }
                          searchCriteria={ searchCriteria }
                          mobileCloseBtnRef={ this.mobileCloseBtnRef }
                          searchInputRef={ this.searchBarRef }
                          isActive={ isSearchWindowOpened }
                          onOutsideClick={ this.onSearchBlur }
                          onClearSearchButtonClick={ onClearSearchButtonClick }
                          setIsSearchWindowOpened={ this.setIsSearchWindowOpened }
                          searchCategoriesSectionRef={ searchCategoriesSectionRef }
                          searchProductsScrollableRef={ searchProductsScrollableRef }
                          searchWindowRef={ searchWindowRef }
                          handleSearchValChange={ this.handleChange }
                        />
                    </div>
                </div>
            </ClickOutside>
            { this.renderOverlay() }
      </>
    );
  }

  renderSearchIcon() {
    return null;
  }

  renderSearchTypeDropdown() {
    const {
      setSearchType,
      searchTypes,
      activeType,
      isSearchWindowOpened
    } = this.props;

    return <SearchTypeDropdown
      searchTypes={ searchTypes }
      setSearchType={ setSearchType }
      activeType={ activeType }
      isSearchWindowOpened={ isSearchWindowOpened }
      closeSearchWindow={ this.closeSearchWindow.bind(this) }
    />;
  }

  render() {
    const {
        isVisible,
        isActive
    } = this.props;

    return (
        <div block="SearchField" mods={ { isVisible, isActive } }>
            <ClickOutside onClick={ this.closeSearch }>
                <div block="SearchField" elem="Wrapper">
                    { this.renderMobileContent() }
                    { this.renderDesktopContent() }
                </div>
            </ClickOutside>
        </div>
    );
  }
}

export default SearchField;