/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_CURRENT_CATEGORY = 'UPDATE_CURRENT_CATEGORY';
export const UPDATE_ALL_CATEGORY = 'UPDATE_ALL_CATEGORY';
export const UPDATE_IS_NAVIGATION_IN_FILTER = 'UPDATE_IS_NAVIGATION_IN_FILTER';
export const UPDATE_CURRENT_CATEGORY_ALLEYS = 'UPDATE_CURRENT_CATEGORY_ALLEYS';

/**
 * Update Current Category
 * @param {String} categoryUrlPath url path Main Category object
 * @return {void}
 * 
 */
export const updateCurrentCategory = (category) => ({
    type: UPDATE_CURRENT_CATEGORY,
    category
});

export const updateAllCategory = (category) => ({
    type: UPDATE_ALL_CATEGORY,
    category
});

export const updateIsNavigationInFilter = (isNavigationInFilter) => ({
    type: UPDATE_IS_NAVIGATION_IN_FILTER,
    isNavigationInFilter
});

export const updateCurrentCategoryAlleys = (currentCategoryAlleys) => ({
    type: UPDATE_CURRENT_CATEGORY_ALLEYS,
    currentCategoryAlleys
});
