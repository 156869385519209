import { connect } from 'react-redux';

import {
    STATE_CREATE_ACCOUNT,
    STATE_FORGOT_PASSWORD,
    STATE_SIGN_IN,
    STATE_LOGGED_IN
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import {
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer,
    mapStateToProps,
    mapDispatchToProps
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';

export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {
    componentDidUpdate(prevProps, prevState) {
        const { isSignedIn: prevIsSignedIn } = prevProps;
        const { state: oldMyAccountState } = prevState;
        const { state: newMyAccountState } = this.state;
        const { location: { pathname } } = history;

        const {
            isSignedIn,
            hideActiveOverlay,
            isCheckout,
            goToPreviousHeaderState,
            showNotification,
            isAuthTokenExpired,
            visibleForm,
        } = this.props;

        if (oldMyAccountState === newMyAccountState) {
            return;
        }

        if (isSignedIn !== prevIsSignedIn) {
            if (isSignedIn) {
                showNotification('success', __('You are successfully logged in!'));
            } else {
                if (!isAuthTokenExpired) {
                    showNotification('success', __('You are successfully logged out!'));
                }
            }

            hideActiveOverlay();

            if (isCheckout) {
                goToPreviousHeaderState();
            }
        }

        if (!pathname.includes(CHECKOUT_URL) && newMyAccountState === STATE_LOGGED_IN) {
            if(visibleForm === 'signIn') {
                history.push({ pathname: appendWithStoreCode('/request-birthday') });
            } else if(visibleForm === 'createAccount') {
                history.push({ pathname: appendWithStoreCode('/completion') });
            } else {
                history.push({ pathname: appendWithStoreCode('/my-account/dashboard') });
            }
        }
    }

    handleCreateAccount(e) {
        const { setLoginPageVisibleForm } = this.props;
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ state: STATE_CREATE_ACCOUNT });
        setLoginPageVisibleForm(STATE_CREATE_ACCOUNT);
    }

    handleForgotPassword(e) {
        const { setLoginPageVisibleForm } = this.props;
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ state: STATE_FORGOT_PASSWORD });
        setLoginPageVisibleForm(STATE_FORGOT_PASSWORD);
    }

    handleSignIn(e) {
        const { setLoginPageVisibleForm } = this.props;
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ state: STATE_SIGN_IN });
        setLoginPageVisibleForm(STATE_SIGN_IN);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOverlayContainer);