import { PureComponent } from "react";

import TextPlaceholder from "Component/TextPlaceholder";
import { ReactComponent as ArrowLeft } from "./icons/arrow_left.svg";

import "./BlogMobileMenuButtonRow.style";

export class BlogMobileMenuButtonRow extends PureComponent {
    renderPostsCount() {
        const { postsCount, blogPosts: { items } } = this.props;

        const count = postsCount && items
            ? postsCount
            : 0;

        return (
            <span
                block="BlogMobileMenuButtonRow"
                elem="PostsCount"
            >{`${count} ${__("posts")}`}</span>
        );
    }

    renderBackBtn() {
        const { onReturnClick } = this.props;

        return (
            <button
                block="BlogMobileMenuButtonRow"
                elem="ReturnBtn"
                onClick={onReturnClick}
            >
                <span>
                    <ArrowLeft />
                </span>
                {__("back")}
            </button>
        );
    }

    renderLeftSide() {
        const { isPostPage } = this.props;

        if (isPostPage) {
            return this.renderBackBtn();
        }

        return this.renderPostsCount();
    }

    render() {
        const { buttonWrapperRef, onClick, mix } = this.props;

        return (
            <div block="BlogMobileMenuButtonRow" mix={mix}>
                {this.renderLeftSide()}
                <div
                    block="BlogMobileMenuButtonRow"
                    elem="OpenOverlayBtnWrapper"
                    ref={buttonWrapperRef}
                >
                    <button
                        block="BlogMobileMenuButtonRow"
                        elem="OpenOverlayBtn"
                        onClick={onClick}
                    >
                        <p>{__("Categories")}</p>
                    </button>
                </div>
            </div>
        );
    }
}

export default BlogMobileMenuButtonRow;
