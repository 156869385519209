import { PureComponent } from 'react'

import TextPlaceholder from 'Component/TextPlaceholder';
import SearchSuggestionRow from 'Component/SearchSuggestionRow';

import './SearchSuggestions.style.scss';

export class SearchSuggestions extends PureComponent {
    renderPlaceholders() {
        const numberOfPlaceholders = 4;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => <div block="SearchWindow" elem="CategoriesNavItem">
                <TextPlaceholder content={ '' } />
            </div>
        );
    }

    renderLink = ({ name, url, brand = null }, mods) => {
        const { searchedTxt } = this.props;

        let linkUrl = url
            ? url
            : `/search/${ name }`;

        return <SearchSuggestionRow
          isLoading={ true }
          searchedTxt={ searchedTxt }
          linkTxt={ name }
          brand={ brand }
          linkUrl={ linkUrl }
          mods={ mods }
        />;
    }

    renderLinksBlock = () => {
        return (
            <div block="SearchSuggestions" elem="LinksBlock">
                { this.renderProductsBlock() }
                { this.renderCategoriesBlock() }
                { this.renderRelatedTermsBlock() }
            </div>
        );
    }

    renderProductsBlock() {
        const {
            xSearchSuggestions: {
                xsearchProducts
            }
        } = this.props;


        if (
            !xsearchProducts
            || xsearchProducts && !xsearchProducts.length
        ) {
            return null;
        }

        const header = __('Products');
        const children = xsearchProducts
            .slice(0, 7)
            .map(({ name, product: { url, brand } }) => this.renderLink({ name, url, brand }, { isProduct: true }));

        return this.renderBlock(children, header);
    }

    renderCategoriesBlock() {
        const {
            xSearchSuggestions: {
                xsearchCategories: {
                    items
                } = {}
            } = {}
        } = this.props;


        if (!items || !items.length) {
            return null;
        }

        const header = __('Departments');
        const children = items.slice(0, 7).map((child) => this.renderLink(child, { isDepartment: true }));

        return this.renderBlock(children, header);
    }

    renderRelatedTermsBlock() {
        const {
            xSearchSuggestions: {
                xsearchRelatedTerms: {
                    items = []
                } = {}
            } = {}
        } = this.props;

        if (!items.length) {
            return null;
        }

        const header = __('Related search terms')
        const children = items.slice(0, 7).map((child) => this.renderLink(child, { isRelatedTerm: true }));

        return this.renderBlock(children, header);
    }

    renderBlock(children, header) {
        return (
            <div block="SearchSuggestions" elem="LinksBlock">
                { this.renderBlockHeader(header) }
                <ul>
                    { children }
                </ul>
            </div>
        );
    }

    renderBlockHeader(header) {
        return <h3
          block="SearchSuggestions"
          elem="BlockHeader"
          mix={ {
            block:'SearchWindow',
            elem: 'SectionHeader'
          } }
        >
            { header }
        </h3>;
    }

    render() {
        const { isLoading, searchSuggestionsRef } = this.props;

        if (isLoading) {
            return this.renderPlaceholders()
        }

        return (
            <section
                block="SearchSuggestions"
                ref={ searchSuggestionsRef }
            >
                { this.renderLinksBlock() }
            </section>
        );
    }
}

export default SearchSuggestions;
