import KeyValueTable from 'SourceComponent/KeyValueTable';
import './MyAccountInfoList.style';
import { ReactComponent as AlertIcon } from '../../../public/assets/images/icons/alert.svg'
import { REQUEST_BIRTHDAY } from 'Route/MyAccount/MyAccount.config';
import Link from 'Component/Link';

/** @namespace AvrilPwa-App/Component/MyAccountInfoList/Component/MyAccountInfoListComponent */
export class MyAccountInfoListComponent extends KeyValueTable {
    renderListItem = (data) => {
        const { key } = data;
        const value = this.getValueFromSource(data);

        if (!value) {
            return null;
        }

        if (value) {
            return (
                <li
                key={ key }
                block="MyAccountInfoList"
                elem="ListItem"
                mix={ { block: 'MyAccountInfoList', elem: key } }
                >
                    { value }
                </li>
            );
        }
    };

    hasDob = (data) => {
        const { key } = data;
        const value = this.getValueFromSource(data);
        
        if (key === "dob" && !value) {
            return ( 
                <li
                key={ key }
                block="MyAccountInfoList"
                elem="ListItem"
                mix={ { block: 'MyAccountInfoList', elem: 'Empty' } }
                >
                    <Link to={ `${REQUEST_BIRTHDAY}` }>
                        { __('Fill in my date of birth') } 
                        <div block="MyAccountInfoList" elem="Tooltip">
                            <span block="MyAccountInfoList" elem="TooltipText">
                                {__('Receive a bonnie discount when you make a transaction during your birthday month!')}
                            </span>
                            <AlertIcon />
                        </div>
                    </Link>
                </li>
            );
        }
    };

    renderListHeading() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h3
              block="MyAccountInfoList"
              elem="Heading"
            >
                    { title }
            </h3>
        );
    }

    renderList() {
        return (
            <div block="MyAccountInfoList" elem="Wrapper">
                <div block="MyAccountInfoList">
                    { this.renderListHeading() }
                    <ul block="MyAccountInfoList" elem="List">
                        { this.dataPairArray.map(this.renderListItem) }
                        { this.dataPairArray.map(this.hasDob) }
                    </ul>
                </div>
            </div>
        );
    }

    render() {
        return this.renderList();
    }
}

export default MyAccountInfoListComponent;
