import PropTypes from 'prop-types';
import MyAccountAddressForm from 'Component/MyAccountAddressForm/MyAccountAddressForm.component';
import CheckoutDeliveryOptions from 'Component/CheckoutDeliveryOptions';
import Form from 'Component/Form';

import { validatePostalCA, validatePostalUS } from 'Component/Form/Form.config';

import { getPostcodeMask, onPostcodeChange } from 'Util/Form';

import {
    CANADA_ID,
    US_ID
} from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';

import './PickupLocationForm.style';

export class PickupLocationForm extends MyAccountAddressForm {
    static propTypes = {
        address: PropTypes.object.isRequired,
        addressLinesQty: PropTypes.number.isRequired,
        countries: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                label: PropTypes.string,
                available_regions: PropTypes.arrayOf(PropTypes.shape({
                    code: PropTypes.string,
                    name: PropTypes.string,
                    id: PropTypes.number
                }))
            })
        ).isRequired,
        default_country: PropTypes.string.isRequired,
        isShippingAddressAllowed: PropTypes.oneOfType([
            PropTypes.bool,
            null
        ]).isRequired
    };

    __construct(props) {
        super.__construct(props);

        const { countryId } = this.state;
        const {
            shippingLocation: {
                country_id = countryId,
                region_id = '',
                postcode = ''
            }
        } = this.props;

        this.state = {
            ...this.state,
            // regionId is not set initially
            regionId: region_id,
            region: '',
            city: '',
            postcode: postcode,
            countryId: country_id,
            postcodeMask: getPostcodeMask('', country_id)
        };
    }

    componentDidUpdate(_, prevState) {
        const {
            regionId,
            countryId,
            postcode
        } = this.state;

        const isPostcodeValidated = countryId === CANADA_ID
            ? validatePostalCA({ value: postcode })
            : validatePostalUS({ value: postcode });

        const {
            countryId: prevCountryId,
            regionId: prevRegionId,
            postcode: prevpostcode
        } = prevState;

        if (countryId !== prevCountryId) {
            this.setState({
                postcodeMask: getPostcodeMask('', countryId),
                postcode: '',
                regionId: null
            });

            return;
        }

        if (
            postcode && regionId
            && (countryId !== prevCountryId
            || regionId !== prevRegionId && isPostcodeValidated
            || postcode !== prevpostcode && isPostcodeValidated)
        ) {
            this.estimateShipping();
        }
    }

    estimateShipping() {
        const { onShippingEstimationFieldsChange } = this.props;
        const { countryId, regionId, postcode } = this.state;

        onShippingEstimationFieldsChange({
            country_id: countryId,
            region_id: regionId,
            postcode
        });
    }

    get fieldMap() {
        const {
            countryId,
            postcode,
            postcodeMask
        } = this.state;

        const {
            setLocationFormRegionId,
            isShippingAddressAllowed,
            isDelivery
        } = this.props;

        const isReject = isShippingAddressAllowed === false && isDelivery;

        const {
            region_id,
            postcode: postcodeField,
            country_id
        } = super.fieldMap;

        let postalValidation = '';

        if (countryId === CANADA_ID) {
            postalValidation = 'postal_CA';
        } else if (countryId === US_ID) {
            postalValidation = 'postal_US';
        }

        const extendedRegionId = {
            ...region_id,
            onChange: (regionId) => {
                region_id.onChange(regionId);
                setLocationFormRegionId(regionId);
            },
            label: __('Province'),
            placeholder: ' ',
            validation: ['notEmpty']
        }

        const extendedPostcodeField = {
            ...postcodeField,
            isControlled: true,
            label: __('Postal code'),
            placeholder: ' ',
            value: postcode,
            mask: postcodeMask,
            onChange: onPostcodeChange.bind(this, countryId),
            validation: [postalValidation, 'notEmpty'],
            mix: {
                block: "PickupLocationForm",
                elem: "PostcodeField",
                mods: { isReject }
            }
        }

        return {
            country_id,
            region_id: extendedRegionId,
            postcode: extendedPostcodeField
        };
    }

    renderRejectedNote() {
        const isReject = true;

        return (
            <div
              block="PickupLocationForm"
              elem="AddressFormNote"
            >
                <span
                  block="PickupLocationForm"
                  elem="AddressFormNoteIcon"
                  mods={ { isReject } }
                />
                <p
                  block="PickupLocationForm"
                  elem="AddressFormNoteText"
                  mods={ { isReject } }
                >{ __('Sorry, we cannot deliver to this area, please choose another area or use the in-store pickup option.') }</p>
            </div>
        );
    }

    renderNote() {
        const { isShippingAddressAllowed } = this.props;

        if (isShippingAddressAllowed !== false) {
            return null;
        }

        return this.renderRejectedNote();
    }

    renderShippingMethods() {
        const {
            isDelivery,
            shippingMethods,
            updateSelectedShippingMethod,
            selectedShippingMethod: { carrier_code }
        } = this.props;

        if (!isDelivery || !shippingMethods.length) {
            return null;
        }

        return <CheckoutDeliveryOptions
          shippingMethods={ shippingMethods }
          selectedShippingMethodCode={ carrier_code }
          onShippingMethodSelect={ updateSelectedShippingMethod }
          isPickupOverlay
          mix={ { block: "PickupLocationForm", elem: "DeliveryOptions" } }
        />;
    }

    render() {
        return (
            <Form
              onSubmitSuccess={ this.onFormSuccess }
              mix={ { block: 'PickupLocationForm' } }
            >
                { this.renderFields() }
                { this.renderNote() }
                { this.renderShippingMethods() }
            </Form>
        );
    }
}

export default PickupLocationForm;
