/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const CATEGORY_FILTER_OVERLAY_ID = 'category-filter';
export const IS_DELIVERABLE_FILTER_KEY = 'is_deliverable';

export const VISIBLE_FILTER_CODES = [
    'brands',
    'diet_type',
];

export const HIDDEN_POPUP_FILTER_CODES = [
    IS_DELIVERABLE_FILTER_KEY
];
