/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';

import { componentLoader } from 'Util/Component';

import NoMatch from 'Route/NoMatch';
import BlogPage from 'Route/BlogPage';
import RecipePage from 'Route/RecipePage';
import RecipeLandingPage from 'Route/RecipeLandingPage';
import RecipeCategoryPage from 'Route/RecipeCategoryPage';
import BlogPostPage from 'Route/BlogPostPage';
import FlippPage from 'Route/FlippPage';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT,
    TYPE_BLOG_POST,
    TYPE_BLOG_CATEGORY,
    TYPE_BLOG_TAG,
    TYPE_BLOG_LANDING,
    RECIPE_HOME,
    RECIPE_CATEGORY,
    RECIPE,
    FLIPP_PAGE
} from './UrlRewrites.config';
import { UrlRewrites as SourceUrlRewrites } from 'SourceRoute/UrlRewrites/UrlRewrites.component';

export const ProductPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "product" */ 'Route/ProductPage'), 'Route/ProductPage'));
export const CategoryPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CategoryPage'), 'Route/CategoryPage'));
export const CmsPage = lazy(() => componentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'), 'Route/CmsPage'));

/**
 * Additional types possible:
 * const TYPE_PWA = 'PWA_ROUTER';
 * const TYPE_CUSTOM = 'CUSTOM';
 * @namespace Route/UrlRewrites/Component
 */
export class UrlRewrites extends SourceUrlRewrites {
    renderContent() {
        const { props, type, updateNoMatch, recipeLandingPageId } = this.props;

        switch (type) {
        case TYPE_PRODUCT:
            return <ProductPage { ...props } />;
        case TYPE_CMS_PAGE:
            return <CmsPage { ...props } />;
        case TYPE_CATEGORY:
            return <CategoryPage { ...props } />;
        case TYPE_BLOG_LANDING:
        case TYPE_BLOG_CATEGORY:
        case TYPE_BLOG_TAG:
            return <BlogPage { ...props } />;
        case RECIPE_HOME:
            return <CmsPage pageIds={ recipeLandingPageId } />;
        case RECIPE_CATEGORY:
            return <RecipeCategoryPage { ...props } />;
        case RECIPE:
            return <RecipePage { ...props } />;
        case TYPE_BLOG_POST:
            return <BlogPostPage { ...props } />;
        case TYPE_NOTFOUND:
            updateNoMatch({ noMatch: true });
            return <NoMatch { ...props } />;
        case FLIPP_PAGE:
            return <FlippPage { ...props } />
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewrites;
