import React, { PureComponent, useEffect } from 'react';

export class FlippPage extends PureComponent {
    componentDidMount() {
        // Load the Flipp Storefront script dynamically
        const script = document.createElement('script');
        script.src = 'https://aq.flippenterprise.net/3940/iframe.js';
        script.id = 'flipp-storefront-script';
        script.onload = this.initializeFlippStorefront;
        document.body.appendChild(script);
    }

    initializeFlippStorefront = () => {
        const { match } = this.props;
        const languageParam = match?.params[0];
        try {
            const flippContainer = document.querySelector('#flipp-container');
            const storefront = new Flipp.Storefront({
                accessToken: '726443ce549fe72361670ac04e881d37',
                merchantId: '3940',
                merchantNameIdentifier: 'AvrilSupermarcheSante',
                locale: languageParam === "en" ? "en-CA" : "fr-CA",
            });
            storefront.renderStorefront(flippContainer);
        } catch (error) {
            console.error('Error initializing Flipp storefront:', error);
        }
    };

    render() {
        return (
            <>
                <div id="flipp-container"></div>
            </>
        );
    }
}

export default FlippPage;