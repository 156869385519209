import { PureComponent } from 'react';
import { connect } from 'react-redux';

import PickupSchedule from './PickupSchedule.component';

export const mapStateToProps = (state) => ({
    storeTimeslots: state.CartReducer.storeTimeslots,
    device: state.ConfigReducer.device
});

export const mapDispatchToProps = (dispatch) => ({});

export class PickupScheduleContainer extends PureComponent {
    containerProps = () => {
        const {
            storeTimeslots,
            selectedTimeslot,
            selectedDate,
            onTimeSlotClick,
            onDayClick,
            device: { isMobile }
        } = this.props;

        const isAllDaysUnavailable = storeTimeslots.every(({ slots }) => {
            return slots.every(({ is_available }) => !is_available);
        });

        return {
            storeTimeslots,
            isAllDaysUnavailable,
            selectedTimeslot,
            selectedDate,
            onTimeSlotClick,
            onDayClick,
            isMobile
        };
    };

    render() {
        return (
            <PickupSchedule
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupScheduleContainer);
