import { PureComponent, createRef } from "react";
import { connect } from "react-redux";

import ShareDropdown from "./ShareDropdown.component";
import CSS from "Util/CSS";

export const mapStateToProps = (state) => ({
	recipeData: state.RecipeReducer.recipeData,
});

export const mapDispatchToProps = (dispatch) => ({});

export class ShareDropdownContainer extends PureComponent {
	dropdownBtnRef = createRef();
	copiedTooltipRef = createRef();
	isDropdownBlocked = false;
    closeDropdownOnScroll = this.closeDropdown.bind(this)

	state = {
		isDropdownActive: false,
		isCopiedTooltipVisible: false,
	};

    componentDidMount() {
        window.addEventListener(
            'scroll',
            this.closeDropdownOnScroll
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'scroll',
            this.closeDropdownOnScroll
        );
    }

	containerProps() {
		return {
			...this.props,
			...this.state,
			dropdownBtnRef: this.dropdownBtnRef,
			copiedTooltipRef: this.copiedTooltipRef,
		};
	}

	containerFunctions = {
		setIsDropdownActive: this.setIsDropdownActive.bind(this),
		toggleIsDropdownActive: this.toggleIsDropdownActive.bind(this),
		onCopyToClipboardClick: this.onCopyToClipboardClick.bind(this),
	};

    setDropdownBottomBorder(borderBottom, botLeftRad, botRightRad) {
        const { current } = this.dropdownBtnRef;

        current.style.borderBottom = borderBottom;
        current.style.borderBottomLeftRadius = botLeftRad;
        current.style.borderBottomRightRadius = botRightRad;
        this.isDropdownBlocked = false;
    }

	setBorderWithDelay() {
		this.isDropdownBlocked = true;
		setTimeout(
			() =>
				this.setDropdownBottomBorder(
					"1px solid #373d49",
					"10px",
					"10px"
				),
			100
		);
	}

	toggleIsDropdownActive() {
        if (this.isDropdownBlocked) {
            return;
        }

		this.setState(({ isDropdownActive }) => {
            if (isDropdownActive) {
                this.setBorderWithDelay();
            } else {
                this.setDropdownBottomBorder("none", "0", "0");
            }

            return {
                isDropdownActive: !isDropdownActive,
            };
        });
	}

    closeDropdown() {
        const { isDropdownActive } = this.state;

        if (isDropdownActive) {
            this.setBorderWithDelay();
            this.setState({ isDropdownActive: false }, () => {});
        }
    }

    setIsDropdownActive(isDropdownActive) {
        if (this.isDropdownBlocked) {
            return;
        }

        this.setBorderWithDelay();
        this.setState({ isDropdownActive });
    }

	afterTooltipHiding() {
		setTimeout(() => {
			CSS.setVariable(
				this.copiedTooltipRef,
				"copied-tooltip-visibility",
				"hidden"
			);
		}, 100);
	}

	hideTooltipWithDelay() {
		setTimeout(() => {
			this.setState({ isCopiedTooltipVisible: false });
			this.afterTooltipHiding();
		}, 500);
	}

	onCopyToClipboardClick() {
		navigator.clipboard.writeText(location.href);
		CSS.setVariable(
			this.copiedTooltipRef,
			"copied-tooltip-visibility",
			"visible"
		);

		this.setState(
			{ isCopiedTooltipVisible: true },
			this.hideTooltipWithDelay.bind(this)
		);
    }

	render() {
		return (
			<ShareDropdown
				{...this.containerProps()}
				{...this.containerFunctions}
			/>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ShareDropdownContainer);
