/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    UrlRewritesContainer as SourceUrlRewritesContainer,
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT,
    TYPE_BLOG_POST,
    TYPE_BLOG_CATEGORY,
    TYPE_BLOG_TAG,
    TYPE_BLOG_LANDING,
    RECIPE_CATEGORY,
    RECIPE
} from './UrlRewrites.config';

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    recipeLandingPageId: state.ConfigReducer.mageside_recipe_seo_route_cms_id
});

/** @namespace Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    containerProps = () => {
        const { updateNoMatch, recipeLandingPageId } = this.props;

        return {
            type: this.getType(),
            props: this.getProps(),
            updateNoMatch,
            recipeLandingPageId
        };
    };

    getTypeSpecificProps() {
        const {
            urlRewrite: {
                id,
                sku
            }
        } = this.props;

        const isLoading = this.getIsLoading();

        switch (this.getType()) {
        case TYPE_PRODUCT:
            /**
             * In case we are not yet sure what product ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const product = history?.state?.state?.product;

                if (product) {
                    const { sku: historySKU, id } = product;

                    return { productSKU: historySKU, id };
                }

                return {};
            }

            return { productSKU: sku, id };
        case TYPE_CMS_PAGE:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { pageIds: id };
        case TYPE_CATEGORY:
            /**
             * In case we are not yet sure what category ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const category = history?.state?.state?.category;

                if (category && category !== true) {
                    return { categoryIds: category };
                }

                return {};
            }

            return { categoryIds: id };

        case TYPE_BLOG_LANDING:
            return {};

        case TYPE_BLOG_POST:
            return { postId: id };

        case TYPE_BLOG_CATEGORY:
            return { categoryId: id };

        case TYPE_BLOG_TAG:
            return { tagId: id };

        case RECIPE_CATEGORY:
            return { category_id: id };

        case RECIPE:
            return { recipe_id: id };

        case TYPE_NOTFOUND:
        default:
            return {};
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
