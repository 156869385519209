import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Order from 'Query/Order.query';
import TrackOrderForm from './TrackOrderForm.component';
import { fetchQuery } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';

export const mapStateToProps = (state) => ({
    countries: state.ConfigReducer.countries,
    storeCode: state.ConfigReducer.code
});

export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export class TrackOrderFormContainer extends PureComponent {
    state = {
        isSend: false,
        orderData: {},
        isLoading: false
    }

    containerFunctions = {
        sendMessage: this.sendMessage.bind(this)
    }

    componentDidMount(){
        this.setState({isSend:false})
    }

    sendMessage(options){
        const { showNotification } = this.props;
        this.setState({ isLoading: true });

        fetchQuery(Order.getGuestCustomerOrder(options)).then(
                (data) => {
                    if(data.getGuestCustomerOrder.order === null) {
                        showNotification('error', __(`Something went wrong!`));
                        this.setState({ isLoading: false });
                        return;
                    }

                    this.setState({
                        isSend: true,
                        isLoading: false,
                        orderData: data.getGuestCustomerOrder.order
                    });
                },
                (error) => {
                    console.log(error);
                    this.setState({ isLoading:false });
                    showNotification('error', __(`${ error[0].message }`));
                }
            );
    }

   render(){
      return(
        <TrackOrderForm
            { ...this.props }
            { ...this.state }
            { ...this.containerFunctions }
        />
      )
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackOrderFormContainer);
