/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import TotalsFees from './TotalsFees.component';

export class TotalsFeesContainer extends PureComponent {
    state = {
        isExtraFeesOpen: false
    };

    toggleIsExtraFeesOpen() {
        const { isExtraFeesOpen } = this.state;
        this.setState({ isExtraFeesOpen: !isExtraFeesOpen });
    }

    containerFunctions = {
        toggleIsExtraFeesOpen: this.toggleIsExtraFeesOpen.bind(this)
    };

    render() {
        return (
            <TotalsFees
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default TotalsFeesContainer;
